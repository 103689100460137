import Feature from '../Feature';
import AllCriteria from '../criteria/all';
import DateTimeCriteria from '../criteria/dateTime';
import FreeUserCriteria from '../criteria/free_user';

export default class TeamsPromoFeature extends Feature {
  constructor(user) {
    const teamsPromo = new DateTimeCriteria();
    const freeUser = new FreeUserCriteria(user);
    const all = new AllCriteria(freeUser, teamsPromo);

    super(all);
  }
}
