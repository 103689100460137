import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Context } from '../../../context/ContextProvider';

const Row = ({ label, children }) => (
  <tr>
    <th className="w-3/12 p-2 text-right align-top border min-w-[12rem]">{label}:</th>
    <td className="w-9/12 p-2 border">{children}</td>
  </tr>
);

Row.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const emailActivationLink = token => `${process.env.SITE_URL}/trials/${token}/verify`;
const acceptInvitationLink = token => `${process.env.SITE_URL}/try/invitations/${token}/accept`;

const Trials = () => {
  const [trials, setTrials] = useState([]);

  const { apiService } = useContext(Context);

  useEffect(() => {
    async function getTrialData() {
      const data = await apiService.get('trials/links');
      setTrials(data.trials);
    }
    getTrialData();
  }, []);

  return (
    <>
      <h2 className="mb-1 text-navy">Trial Activation Links</h2>
      <div>
        {trials.map(t => (
          <table className="w-full m-10 border border-collapse" key={t.trial_id}>
            <tbody>
              <Row label="Trial ID">{t.trial_id}</Row>
              <Row label="Date Created">{new Date(t.trial_created_at).toLocaleString()} </Row>
              <Row label="Owner">{t.trial_owner}</Row>
              <Row label="Owner Activiation Link">
                <a
                  target="_blank"
                  href={emailActivationLink(t.owner_email_activation_token)}
                  rel="noreferrer"
                >
                  {emailActivationLink(t.owner_email_activation_token)}
                </a>
              </Row>
              <Row label="Invitees">
                <table className="w-full border-collapse">
                  <tbody>
                    {t.invitees.map(invite => (
                      <tr key={invite.token}>
                        <td className="border-b border-r">{invite.recipient_email}</td>
                        <td className="px-2 border-b">
                          <a
                            target="_blank"
                            href={acceptInvitationLink(invite.token)}
                            rel="noreferrer"
                          >
                            {acceptInvitationLink(invite.token)}
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Row>
            </tbody>
          </table>
        ))}
      </div>
    </>
  );
};

export default Trials;
