import React from 'react';
import { propTypes } from './types';

const CookieNotificationContent = props => (
  <div className="box-border fixed bottom-0 left-0 right-0 z-[1100] flex flex-col items-end w-full p-4 md:p-7 bg-[linear-gradient(to_bottom,rgba(0,0,0,0),rgba(0,0,0,0.3))]">
    <div className="grid float-right max-w-md grid-flow-col mx-4 bg-white bg-no-repeat rounded shadow auto-cols-auto font-sm my-9 bg-cookie-policy border-3 border-charcoal-900">
      <div className="p-3 m-0 text-xs font-normal leading-normal">
        <strong>Aperian Cookie Policy: </strong>
        This site uses cookies to give you the best possible browsing experience.
        Full details are available on our <a className="no-underline text-rust-500" href="https://www.aperian.com/cookie-policy" target="_blank" rel="noopener noreferrer">Cookie Policy</a>.
      </div>
      <div className="flex flex-col">
        <button
          type="button"
          className="h-full p-3 m-0 text-center border-none min-w-[3.75rem] bg-ivory-400 hover:bg-rust-500 transition duration-300 hover:text-white"
          onClick={props.onClick}
        >
          OK
        </button>
      </div>
    </div>
  </div>
);

CookieNotificationContent.propTypes = propTypes;

export default CookieNotificationContent;
