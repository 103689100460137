import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { format } from 'date-fns';
import { ModalContext } from '../../../context/Modal';

import Button from '../../common/Button';
import AperianWordmark from '../../AperianWordmark';
import Icon from '../../common/Icon';

const CohortEnrollmentSuccessMessage = ({
  displayName,
  redirectUrl,
  isAlreadyEnrolled,
  meetingDateTime,
}) => {
  const { handleCloseDialog } = useContext(ModalContext);

  useEffect(() => {
    const displayTimer = setTimeout(() => {
      handleCloseDialog();
    }, 5000);
    return () => clearTimeout(displayTimer);
  }, []);

  const handleButtonClick = () => handleCloseDialog();

  const alreadyEnrolledMessage = (
    <span>
      <b>You have already enrolled in {displayName}.</b><br />
      You can access GlobeSmart anytime at <a href={process.env.SITE_URL}>{process.env.SITE_URL}</a>
    </span>
  );

  const enrollMessage = (
    <span className="text-lg">
      You have successfully enrolled in <br />{displayName}
      {meetingDateTime && <> <br />on {format(new Date(meetingDateTime), 'dd MMM yyyy')} at {format(new Date(meetingDateTime), 'hh:mm a')}</>}.<br />
      {redirectUrl && <p className="mt-2">You will be redirected to your first step.</p>}
    </span>
  );

  return (
    <>
      <div className="flex justify-center">
        <AperianWordmark />
      </div>
      <hr className="my-4" />
      <div className="flex flex-col gap-4 text-center">
        <div className="flex items-center justify-center w-16 h-16 m-auto border rounded-full shadow-md">
          <Icon icon="check" iconColor="green" className="w-12 h-12" />
        </div>
        <h2 className="m-0">Success!</h2>
        { isAlreadyEnrolled ? alreadyEnrolledMessage : enrollMessage }
        <Button className="ml-auto" filledColor="primary" onClick={handleButtonClick}>
          Okay
        </Button>
      </div>
    </>
  );
};

CohortEnrollmentSuccessMessage.propTypes = {
  displayName: PropTypes.string.isRequired,
  redirectUrl: PropTypes.string,
  isAlreadyEnrolled: PropTypes.bool,
  meetingDateTime: PropTypes.string,
};

CohortEnrollmentSuccessMessage.defaultProps = {
  redirectUrl: null,
  isAlreadyEnrolled: false,
  meetingDateTime: null,
};

export default CohortEnrollmentSuccessMessage;
