import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Card from '../../../../Card';
import ProfileCardTextWithButton from '../../../../ProfileCards/ProfileCardTextWithButton';
import Button from '../../../../common/Button';

const GroupMemberCard = ({
  id,
  avatarUrl,
  displayName,
  displayDescription,
  token,
  onLeave,
}) => {
  const [confirmLeave, setConfirmLeave] = useState(false);
  const [groupId, setGroupId] = useState(id);

  useEffect(() => {
    setGroupId(id);
    setConfirmLeave(false);
  }, [id, onLeave]);

  const handleNoLeave = () => setConfirmLeave(false);

  const completeLeave = () => {
    onLeave(groupId);
  };

  const handleConfirmLeave = () => setConfirmLeave(true);

  function renderConfirmLeave() {
    const options = {
      messageText: 'Are you sure you want to leave this group?',
      confirmText: 'Yes, Leave',
      confirmButton: {
        isWarning: true,
        isNormalCase: true,
        onClick: completeLeave,
      },
      cancelText: 'No, Stay',
      cancelButton: {
        variant: 'secondary',
        onClick: handleNoLeave,
      },
    };

    return <ProfileCardTextWithButton {...options} token={token} />;
  }

  function renderGroupMemberCard() {
    return (
      <Card
        displayName={displayName}
        displayDescription={displayDescription}
        avatarUrl={avatarUrl}
        isIcon
      >
        <Button
          isWarning
          isNormalCase
          isFullWidth
          onClick={handleConfirmLeave}
        >
          Leave
        </Button>
      </Card>
    );
  }
  return confirmLeave ? renderConfirmLeave() : renderGroupMemberCard();
};

GroupMemberCard.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  avatarUrl: PropTypes.string,
  displayName: PropTypes.string.isRequired,
  displayDescription: PropTypes.shape({}),
};

GroupMemberCard.defaultProps = {
  avatarUrl: '',
  displayDescription: null,
};

export default GroupMemberCard;
