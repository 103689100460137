import React, { useEffect, useRef, useState } from 'react';
import { addMinutes, minutesToMilliseconds, differenceInMinutes } from 'date-fns';
import PropTypes from 'prop-types';
import { atcb_action as addToCalendar } from 'add-to-calendar-button';

import Badges from '../../common/Badges';
import Icon from '../../common/Icon';
import Button from '../../common/Button';
import PopoverComponent from '../../common/Popover';
import ShareSessionPopover from '../ShareSessionPopover';
import Avatar from '../../common/Avatars/Avatar';

import { trackAddSessionToCalendar } from '../../../lib/tracker/aperian-live';
import { formatCalendarData } from '../../../lib/aperianLive';

const RegisteredSessionContainer = ({
  name,
  nameInternal,
  date,
  time,
  dateTime,
  facilitator,
  facilitatorAvatarUrl,
  duration,
  tags,
  userJoinUrl,
  cohortCode,
  handleShowDetails,
  join,
  organizations,
  userId,
  createShareableLink,
}) => {
  const [isJoinEnabled, setIsJoinEnabled] = useState(false);
  const [isHappeningNow, setIsHappeningNow] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const buttonRef = useRef(null);

  useEffect(() => {
    let timeout;
    if (isCopied) {
      timeout = setTimeout(() => {
        setIsCopied(false);
      }, 6000);
    }
    return () => clearTimeout(timeout);
  }, [isCopied]);

  useEffect(() => {
    let joinTimer;
    const now = new Date();
    const startTime = new Date(dateTime);
    const diffToStartTime = differenceInMinutes(startTime, now);
    const meetingStartsInLessThanTen = diffToStartTime <= 10;
    const meetingStartsInMoreThanTen = diffToStartTime > 10;
    const meetingStartsInLessThanThirty = diffToStartTime <= 30;

    if (meetingStartsInLessThanTen) {
      setIsJoinEnabled(true);
    }

    if (meetingStartsInMoreThanTen && meetingStartsInLessThanThirty) {
      joinTimer = setTimeout(() => {
        setIsJoinEnabled(true);
      }, minutesToMilliseconds(diffToStartTime - 10));
    }

    return () => {
      if (joinTimer) {
        clearTimeout(joinTimer);
      }
    };
  }, []);

  useEffect(() => {
    let happeningTimer;
    const now = new Date();
    const startTime = new Date(dateTime);
    const endTime = addMinutes(startTime, duration);
    const diffToStartTime = differenceInMinutes(startTime, now);
    const diffToEndTime = differenceInMinutes(endTime, now);
    const meetingStarted = diffToStartTime <= 0;
    const meetingStartsInLessThanTen = diffToStartTime <= 10;
    const meetingIsNotOver = diffToEndTime > 0;

    if (isJoinEnabled && meetingStarted && meetingIsNotOver) {
      setIsHappeningNow(true);
    }

    if (isJoinEnabled && meetingStartsInLessThanTen && meetingIsNotOver) {
      happeningTimer = setTimeout(() => {
        setIsHappeningNow(true);
      }, minutesToMilliseconds(diffToStartTime));
    }

    return () => {
      if (happeningTimer) {
        clearTimeout(happeningTimer);
      }
    };
  }, [isJoinEnabled]);

  const copyShareableLink = async () => {
    await createShareableLink(
      cohortCode,
      name,
      'my sessions',
    );
    setIsCopied(true);
  };

  const sessionShareOptions = [
    {
      id: 1,
      name: 'Copy Link',
      onClick: copyShareableLink,
      icon: 'link',
      isCopy: true,
    },
  ];

  const now = new Date();
  const isPast = differenceInMinutes(new Date(dateTime), now) < -40;

  const calendarConfig = formatCalendarData(dateTime, duration, userJoinUrl, name);

  return (
    <div className="bg-white rounded-lg">
      <div className="flex flex-col gap-2 px-8 py-8 md:flex-row md:justify-between md:px-10">
        <div className="flex flex-col items-start w-full gap-4 mb-4 md:w-[55%] md:w-max-content">
          <div className="flex flex-col gap-4">
            <h4 className="mb-0">
              {name}
            </h4>
            {isHappeningNow
              ? (
                <div className="px-2 py-0.5 text-sm font-medium tracking-widest text-white bg-green-300 rounded-md w-max">
                  HAPPENING NOW
                </div>
              ) : null}
            {tags.length > 0 ? (<Badges badges={tags} color="green" />) : null}
            <div className="flex items-center gap-2">
              <Avatar
                className="w-12 h-12 rounded-full bg-gray-50"
                avatarImgAlt={facilitator}
                avatarUrl={facilitatorAvatarUrl}
                isNavigation
              />
              <p className="mb-0 ml-0 text-base italic md:ml-1 lg:ml-0">
                {facilitator}
              </p>
            </div>
          </div>
          <div className="flex items-center gap-1 italic">
            <Icon icon="calendar" iconColor="dark-gray" />
            <p className="mb-0 text-sm font-medium text-slate-900 lg:text-base">
              {date}
            </p>
            <p className="mb-0 text-sm font-medium text-slate-900 lg:text-base">
              {time}
            </p>
            <div className="flex items-center gap-1 ml-2 italic">
              <Icon icon="clock" iconColor="dark-gray" />
              <p className="mb-0 text-sm font-medium text-slate-900 lg:text-base">
                {duration} minutes
              </p>
            </div>
          </div>
        </div>
        {isPast ? (
          <div className="flex flex-col justify-end">
            <p className="w-full mb-0 text-xs italic text-center md:text-right">
              The join time has passed.
            </p>
          </div>
        ) : (
          <div className="flex flex-col flex-wrap items-end justify-end gap-4 mb-2 md:flex-nowrap">
            <div className="flex flex-wrap w-full gap-2 md:w-[45%] lg:w-auto lg:flex-nowrap md:justify-end md:items-end">
              <PopoverComponent
                position="bottom"
                content={(
                  <ShareSessionPopover
                    isCopied={isCopied}
                    profileShareOptions={sessionShareOptions}
                  />
              )}
                className="flex-grow w-full md:w-max"
                singleLineClassName="px-4 py-2"
                fullWidthAtMobile
                isStatic
              >
                <Button
                  isExtraSmall
                  variant="secondary"
                  trailingButtonIcon="share"
                  iconColor="primary"
                  className="w-full md:w-auto"
                >
                  Share
                </Button>
              </PopoverComponent>
              <Button
                isExtraSmall
                ref={buttonRef}
                trailingButtonIcon="calendar-plus"
                iconColor="primary"
                variant="secondary"
                className="w-full md:w-auto"
                onClick={() => addToCalendar(calendarConfig, buttonRef.current).then(
                  trackAddSessionToCalendar(
                    userId,
                    organizations,
                    name,
                    nameInternal,
                    cohortCode,
                    dateTime,
                    duration,
                    facilitator,
                    'Registered Session',
                  ),
                )}
              >
                Add to Cal
              </Button>
              <div className="flex flex-wrap w-full gap-2 md:w-auto lg:flex-nowrap md:justify-end md:items-end">
                <Button
                  isExtraSmall
                  variant="secondary"
                  className="w-full md:w-auto"
                  onClick={handleShowDetails}
                >
                  Details
                </Button>
                <Button
                  isExtraSmall
                  onClick={() => join(
                    cohortCode,
                    userJoinUrl,
                    'my sessions',
                  )}
                  className="w-full md:w-auto"
                  variant="primary"
                  disabled={!isJoinEnabled}
                >
                  Join
                </Button>
              </div>
            </div>
            {!isJoinEnabled ? (<p className="w-full mb-0 text-xs italic text-center md:text-right">You&apos;ll be able to join your session 10 min before it starts.</p>) : null}
          </div>
        )}
      </div>
    </div>
  );
};

RegisteredSessionContainer.propTypes = {
  name: PropTypes.string.isRequired,
  nameInternal: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  dateTime: PropTypes.string.isRequired,
  facilitator: PropTypes.string.isRequired,
  facilitatorAvatarUrl: PropTypes.string,
  duration: PropTypes.number.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  userJoinUrl: PropTypes.string,
  cohortCode: PropTypes.string.isRequired,
  handleShowDetails: PropTypes.func.isRequired,
  join: PropTypes.func.isRequired,
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  userId: PropTypes.string.isRequired,
  createShareableLink: PropTypes.func,
};

RegisteredSessionContainer.defaultProps = {
  tags: [],
  facilitatorAvatarUrl: '',
  userJoinUrl: '',
  organizations: [],
  createShareableLink: () => { },
};

export default RegisteredSessionContainer;
