class GetCouponAction {
  constructor(apiService) {
    this.apiService = apiService;
  }

  execute(id) {
    return this.apiService.get(`coupon/${id}`);
  }
}

export default GetCouponAction;
