import React from 'react';

const TeamInvitationFormHeader = () => (
  <div className="mb-5 md:mb-10 xl:mb-12">
    <div className="px-4 py-6 bg-white rounded-2xl">
      <p className="max-w-3xl m-0 mx-auto text-center ">A team allows a group of people to share profiles with each other and learn how best to collaborate. View the <a href="https://aperian.zendesk.com/hc/en-us/articles/360029877693-Creating-GlobeSmart-Profile-Teams" target="_blank" rel="noopener noreferrer">Aperian Help Center</a> for more information.
      </p>
    </div>
  </div>
);

export default TeamInvitationFormHeader;
