const reasons = {
  no_seats: 'Error: Sorry, but the access code you were sent has no remaining seats. You may wish to contact the person who shared it with you for an update.',
  expired: 'Error: This access code is expired. If you believe this to be in error, please contact technical support at <a href="mailto:support@aperian.com">support@aperian.com</a> for assistance.',
  not_active: 'Error: This access code has been deactivated. If you think this is an error, please contact technical support at <a href="mailto:support@aperian.com">support@aperian.com</a>.',
  already_redeemed: 'Sorry, but you have already redeemed this access code. It cannot be redeemed twice. Please contact <a href="mailto:support@aperian.com">support@aperian.com</a> for further guidance.',
};
const otherErrorMessage = 'Unknown Error: Please try following the access link again later or contact technical support at <a href="mailto:support@aperian.com">support@aperian.com</a> for assistance.';
const getSuccessMessage = planName => `You are now using ${planName}.`;

const accessCodeResultsMeta = response => {
  if (!response.meta || !response.meta.accessCodeResults) {
    return null;
  }

  const { accessCodeResults } = response.meta;

  if (accessCodeResults.alreadyRedeemed || accessCodeResults.orgHasAccess) {
    return null;
  }

  const notification = {
    type: accessCodeResults.accepted ? 'success' : 'failure',
  };

  notification.redirectUrl = accessCodeResults.redirectUrl || null;

  if (accessCodeResults.accepted) {
    notification.message = getSuccessMessage(accessCodeResults.planInfo.name);
    return notification;
  }

  notification.message = reasons[accessCodeResults.reason] || otherErrorMessage;

  return notification;
};

export default accessCodeResultsMeta;
