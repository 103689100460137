import React from 'react';

import PropTypes from 'prop-types';

const HalfCirclesIcon = ({ className1, className2 }) => (
  <svg className="max-w-full max-h-full" width="94" height="120" viewBox="0 0 94 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.85834 1.56543L32.8508 1.56543C48.0593 1.56543 60.3916 13.8978 60.3916 29.1066C60.3916 44.3154 48.0593 56.6478 32.8508 56.6478L1.85834 56.6478C1.37296 56.6478 0.98346 56.2583 0.98346 55.7729L0.983462 2.44032C0.983462 1.95493 1.37296 1.56543 1.85834 1.56543Z" className={className1} />
    <path d="M93.8184 28.8632C93.8184 13.1837 81.3163 0.417824 65.7381 0.000349404C65.3031 -0.012834 64.9471 0.347512 64.9471 0.782563L64.9471 56.9481C64.9471 57.3832 65.3031 57.7391 65.7381 57.7304C81.3163 57.3085 93.8184 44.547 93.8184 28.8632Z" className={className2} />
    <path d="M92.96 118.435L61.9676 118.435C46.759 118.435 34.4268 106.102 34.4268 90.8934C34.4268 75.6846 46.759 63.3522 61.9676 63.3522L92.96 63.3522C93.4454 63.3522 93.8349 63.7417 93.8349 64.2271L93.8349 117.56C93.8349 118.045 93.4454 118.435 92.96 118.435Z" className={className1} />
    <path d="M1 91.1327C1 75.4533 13.5021 62.6874 29.0803 62.2699C29.5153 62.2567 29.8712 62.617 29.8712 63.0521L29.8712 119.218C29.8712 119.653 29.5153 120.009 29.0803 120C13.5021 119.578 1 106.817 1 91.1327Z" className={className2} />
  </svg>
);

HalfCirclesIcon.propTypes = {
  className1: PropTypes.string,
  className2: PropTypes.string,
};

HalfCirclesIcon.defaultProps = {
  className1: '',
  className2: '',
};

export default HalfCirclesIcon;
