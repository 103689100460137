import React, { useContext, useState } from 'react';

import { Context } from '../../../../context/ContextProvider';

import LabelInput from '../../../common/LabelInput';
import Button from '../../../common/Button';

import LookupAccessCodeAction from '../../../../actions/accessCodes/lookupAccessCode';
import { renderNotifications, getErrorMessage } from '../../RenderNotification';

const AccessCodeLookup = () => {
  const { apiService, router } = useContext(Context);
  const [accessCode, setAccessCode] = useState('');
  const [notification, setNotification] = useState();

  const onSearchParamChange = ({ target: { value } }) => {
    setAccessCode(value);
  };

  const lookup = e => {
    e.preventDefault();
    new LookupAccessCodeAction(apiService)
      .execute({ accesscode: accessCode })
      .then(data => {
        if (data) {
          router.push(`/administration/access-codes/${accessCode}/edit`);
        }
      })
      .catch(err => {
        const lookupError = err;
        if (err.status === 404) {
          lookupError.error = 'Access Code not found';
        }
        setNotification({
          notification: {
            type: 'warning',
            message: `Error searching for access code: ${getErrorMessage(err)}`,
          },
        });
      });
  };

  return (
    <div>
      {renderNotifications([notification])}
      <form onSubmit={lookup}>
        <LabelInput
          id="accessCode"
          name="Access Code"
          labelText="Access Code"
          labelType="text"
          value={accessCode}
          onChangeValue={onSearchParamChange}
        />
        <Button className="float-right m-1" isSmall filledColor="secondary" type="submit">Lookup Code</Button>
      </form>
    </div>
  );
};

export default AccessCodeLookup;
