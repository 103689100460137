import PropTypes from 'prop-types';
import React from 'react';
import AperianWordmark from '../../../components/AperianWordmark';
import ProfileChartTransformer from '../../../components/common/ProfileChartTransformer';

const ProfileToPdf = props => {
  const tendencyList = tendencies => {
    if (!tendencies) return null;

    return (
      <ul className="pl-6">
        {tendencies.map((tendency, i) => (
          <li key={tendency} className={`text-base ${i !== tendencies.length - 1 ? 'pb-1' : ''}`}>
            {tendency}
          </li>
        ))}
      </ul>
    );
  };
  const renderDimensions = dimensions => {
    if (!dimensions) return null;

    return dimensions.map(dimension =>
      (
        <div
          className="mb-4 font serif text-charcoal-900"
          key={dimension.conflictVideoID}
        >
          <h3 className="text-2xl">{dimension.you.positioningStatement}</h3>
          <p className="text-base font-semibold">{dimension.you.tendencyHeading}</p>
          {tendencyList(dimension.you.tendencies)}
        </div>
      ));
  };

  const { profileDetails: { dimensions } } = props;
  return (
    <div
      className="fixed left-[9999px] top-[9999px] h-[1250px] flex flex-col w-[1000px] print:hidden"
      ref={props.printRef}
    >
      <div className="flex items-center justify-between px-12 py-4">
        <AperianWordmark />
        <span className="mb-12 font-serif text-3xl text-charcoal-900">
          GlobeSmart Profile
        </span>
      </div>
      <div className="pb-8 m-auto mx-16 mb-10 text-center border-3 border-rust-500">
        <h1 className="mb-4">{props.firstName} {props.lastName}</h1>
        <span className="text-2xl italic">Profile Summary</span>
      </div>
      <div className="flex flex-grow gap-2">
        <div className="flex flex-col justify-around w-full">
          <div className="pt-24 profile-dimension__chart">
            <ProfileChartTransformer isMe isProfile {...props} />
          </div>
          <div className="pl-16">
            {dimensions.length > 3 && renderDimensions(dimensions.slice(0, 1))}
          </div>
        </div>
        <div className="flex flex-col w-full h-full pr-16 overflow-visible justify-evenly">
          {renderDimensions(dimensions.length > 3 ? dimensions.slice(1) : dimensions) }
        </div>
      </div>
    </div>
  );
};

ProfileToPdf.propTypes = {
  profileDetails: PropTypes.shape({
    dimensions: PropTypes.arrayOf(
      PropTypes.shape({
        conflictVideoID: PropTypes.string,
        conflictVideoSentence: PropTypes.string,
      })),
  }).isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  printRef: PropTypes.shape({}),
  displayDimensionLink: PropTypes.bool,
};
ProfileToPdf.defaultProps = {
  printRef: undefined,
  displayDimensionLink: false,
};
export default ProfileToPdf;
