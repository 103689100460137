import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { trackOnboardingCompletion } from '../../../../lib/tracker/onboarding';
import { IS_DEVELOPMENT } from '../../../../lib/constants';

import EmailVerificationIcon from '../icons/EmailVerificationIcon';

const VerifyLink = ({ link, text }) => (
  <p>
    {text}:
    <a
      rel="noopener noreferrer"
      target="_blank"
      href={link}
    >{link}
    </a>
  </p>
);

const EmailVerification = ({
  formData: {
    track,
    orgName,
    userToken,
    inviteeTokens,
  },
  tracks,
}) => {
  useEffect(() => {
    const selectedTrack = tracks.find(({ id }) => track === id);
    const trackName = selectedTrack?.name || 'Other';
    async function trackUserOnboardingCompletion() {
      await trackOnboardingCompletion({
        organizationName: orgName,
        track: trackName,
      });
    }
    trackUserOnboardingCompletion();
  }, []);

  return IS_DEVELOPMENT
    ? (
      <>
        <VerifyLink
          link={`${process.env.SITE_URL}/trials/${userToken}/verify`}
          text="Trial Owner Email Verification Link"
        />
        {inviteeTokens.map(({ token, recipientEmail }) => (
          <VerifyLink
            key={token}
            link={`${process.env.SITE_URL}/try/invitations/${token}/accept`}
            text={`Trial Invitee User: ${recipientEmail}`}
          />
        ),
        )}
      </>
    )
    : (
      <>
        <EmailVerificationIcon />
        <h1 className="m-0 mt-5 font-serif text-4xl tracking-wider text-charcoal-900 md:text-6xl md:mt-10">We&apos;ve sent you an email to verify your account.</h1>
      </>
    );
};

EmailVerification.propTypes = {
  formData: PropTypes.shape({
    track: PropTypes.number,
    orgName: PropTypes.string,
    userToken: PropTypes.string,
    inviteeTokens: PropTypes.arrayOf(
      PropTypes.shape({
        token: PropTypes.string,
        recipientEmail: PropTypes.string,
      }),
    ),
  }).isRequired,
  tracks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      icon: PropTypes.string,
      text: PropTypes.string,
      message: PropTypes.string,
    }),
  ).isRequired,
};

VerifyLink.propTypes = {
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default EmailVerification;
