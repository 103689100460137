class DeactivateAccessCodeAction {
  constructor(apiService) {
    this.apiService = apiService;
  }

  execute(code) {
    return this.apiService.patch(`access-codes/${code}`, { active: false });
  }
}

export default DeactivateAccessCodeAction;
