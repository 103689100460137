/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Video from '../../../Video';
import Icon from '../../../common/Icon';

const ProfileVideoCarousel = ({ videos, videoTrackingId }) => {
  const lastUnwatched = videos.findIndex(video => !video?.watched);
  const allWatched = lastUnwatched === -1;
  const initialVideo = allWatched ? 0 : lastUnwatched;
  const matchedVideoIndex = videos.findIndex(video => video.trackingId === videoTrackingId);

  const [activeVideo, setActiveVideo] = useState(
    matchedVideoIndex !== -1 ? videos[matchedVideoIndex] : videos[initialVideo],
  );
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const handleDropdown = () => setIsOpen(!isOpen);

  useEffect(() => {
    function handleClickOutside(e) {
      if ((dropdownRef?.current && !dropdownRef.current.contains(e.target))
        && (buttonRef?.current && !buttonRef.current.contains(e.target))) {
        buttonRef.current.blur();
        handleDropdown();
      }
    }
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside, { passive: true });
    } else {
      document.removeEventListener('mousedown', handleClickOutside, { passive: true });
    }
  }, [isOpen]);

  const handleTabChange = wistiaId => {
    const newActive = videos.find(video => video.wistiaId === wistiaId);
    setActiveVideo(newActive);
  };

  const iconClasses = classNames({
    'ml-4 w-3 h-3': true,
    '-rotate-180 transition-transform duration-500': isOpen,
    'rotate-0 transition-transform duration-500': !isOpen,
  });

  const dropdownClasses = classNames({
    'absolute right-0 z-10 w-full origin-top-right bg-white rounded-md shadow-lg border-1 focus:outline-none': true,
    'py-1 mt-2 px-4': true,
    'transition-opacity duration-300 ease-in-out': true,
    'opacity-100': isOpen,
    'opacity-0 pointer-events-none': !isOpen,
  });

  return (
    <div className="p-4 mt-8 overflow-hidden bg-white max-w-72 xs:max-w-96 md:max-w-full md:px-8 lg:px-12 rounded-3xl print:hidden" id="profile-video">
      <div className="w-full">
        {/* mobile top dropdown navigation */}
        <div className="relative sm:hidden">
          <label htmlFor="menu-button" className="block mb-2 text-sm font-medium leading-6 text-left text-gray-700">
            Select a Dimension
          </label>
          <button
            type="button"
            className="flex justify-between items-center px-4 py-2 my-1 w-full gap-x-1.5 rounded-md bg-white ring-1 ring-inset ring-gray-300 hover:bg-stone-25 focus:ring-inset focus:ring-rust-500 focus:shadow-input"
            id="menu-button"
            onClick={handleDropdown}
            ref={buttonRef}
          >
            <span className="flex-grow text-base font-medium leading-6 text-left text-gray-700">
              {activeVideo?.dimension}
            </span>
            <Icon
              icon="chevron-down"
              iconColor="gray"
              className={iconClasses}
            />
          </button>
          <ul
            className={dropdownClasses}
            aria-labelledby="dropdown-list"
            tabIndex="-1"
            ref={dropdownRef}
          >
            {videos.map(video => {
              const isActiveTab = activeVideo.wistiaId === video.wistiaId;
              return (
                <li key={`${video.wistiaId}-dropdown-option`} className="flex items-center justify-between ">
                  <a
                    href={`#wistia_${video.wistiaId}?transition=slide&transitionTime=300&autoPlay=false`}
                    onClick={() => {
                      handleDropdown();
                      handleTabChange(video.wistiaId);
                    }}
                    className="flex-grow px-1 py-4 text-sm font-medium border-b-2 border-transparent text-charcoal-500 hover:border-gray-300 hover:text-gray-700 whitespace-nowrap"
                    role="tab"
                    aria-selected={isActiveTab}
                    aria-disabled="false"
                    aria-controls={video.dimension}
                  >
                    {video.dimension}
                  </a>
                  {isActiveTab ? (
                    <Icon
                      icon="check"
                      iconColor="secondary"
                      iconHoverColor="white"
                      className="w-6 h-6 group stroke-rust-500 group-hover:stroke-white"
                    />
                  ) : null}
                </li>
              );
            })}
          </ul>
        </div>
        {/* desktop top tab navigation */}
        <div className="hidden mb-4 border-b border-gray-200 sm:block">
          <nav className="flex -mb-px space-x-8 overflow-auto justify-evenly" aria-label="Tabs">
            {videos.map(video => {
              const isActiveTab = activeVideo.wistiaId === video.wistiaId;
              const classes = classNames({
                'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium': true,
                'border-rust-500 text-charcoal-900': isActiveTab,
                'border-transparent text-charcoal-500 hover:border-gray-300 hover:text-gray-700': !isActiveTab,
              });
              return (
                <a
                  key={`${video.wistiaId}-navigation-tab`}
                  href={`#wistia_${video.wistiaId}?transition=slide&transitionTime=300&autoPlay=false`}
                  onClick={() => handleTabChange(video.wistiaId)}
                  className={classes}
                  role="tab"
                  aria-selected={isActiveTab}
                  aria-disabled="false"
                  aria-controls={video.dimension}
                >
                  {video.dimension}
                </a>
              );
            })}
          </nav>
        </div>
      </div>
      {/* carousel */}
      <div className="grid items-center gap-2 m-auto md:grid-cols-4">
        <div className="col-span-1 my-2 md:col-span-3 md:col-start-1">
          <Video
            trackingId={activeVideo.trackingId}
            wistiaId={activeVideo.wistiaId}
            playlistId={
              videos[matchedVideoIndex !== -1 ? matchedVideoIndex : initialVideo].wistiaId
            }
            pageTitle="Profile Dimension"
            recordWatchHistory
          />
        </div>
        {/* mobile bottom navigation / desktop side navigation */}
        <div className="flex items-center gap-2 md:flex-col">
          {videos.map(({ dimension, wistiaId }) => {
            const isActiveVideo = activeVideo.wistiaId === wistiaId;
            const classes = classNames({
              'w-4/6 object-contain': true,
              'rounded-sm border border-rust-500 ring-inset ring-1 ring-rust-500 shadow-input': isActiveVideo,
            });
            return (
              <a
                key={`${wistiaId}-thumbnail`}
                href={`#wistia_${wistiaId}?transition=slide&transitionTime=300&autoPlay=false`}
                className={classes}
                onClick={() => handleTabChange(wistiaId)}
              >
                <img
                  src={`https://fast.wistia.com/embed/medias/${wistiaId}/swatch`}
                  className="w-full"
                  alt={`${dimension} video thumbnail`}
                  aria-hidden="true"
                />
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
};

ProfileVideoCarousel.propTypes = {
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      wistiaId: PropTypes.string,
      trackingId: PropTypes.string,
    }),
  ).isRequired,
  videoTrackingId: PropTypes.string,
};

ProfileVideoCarousel.defaultProps = {
  videoTrackingId: '',
};

export default ProfileVideoCarousel;
