import React from 'react';
import PropTypes from 'prop-types';

import AsmtTeamMemberCard from './AsmtTeamMemberCard';
import ReceivedInvitationCard from '../../../Invitations/PendingInvitations/ReceivedInvitations/ReceivedInvitationCard';

import formatDate from '../../../../lib/dateFormatter';

const TeamsYouAreIn = ({
  firstName,
  lastName,
  asmtType,
  groupsYouAreIn,
  groupInvitations,
  onAccept,
  onReject,
}) => {
  if (!groupInvitations.length && !groupsYouAreIn.length) return null;

  const renderPendingInvitations = () =>
    groupInvitations.map(card => (
      <li key={card.token} className="profile-group__item">
        <ReceivedInvitationCard
          {...card}
          onAccept={onAccept}
          onReject={onReject}
          paymentUrl={`assessments/${asmtType}`}
          isAuthorized
          isAssessmentPage
          acceptButtonText="Accept"
        />
      </li>
    ));
  const renderGroupsJoined = () =>
    groupsYouAreIn.map(({
      id,
      name,
      avatarUrl,
      createdAt,
      surveyStatus,
      completed,
      userSurvey,
      owners,
      joinedAt,
    }) => {
      const multipleOwners = owners.split(',').length > 1;
      const displayDescription = (
        <>
          <b>Team Owner{multipleOwners && 's'}: </b> {owners} <br />
          <b>Created: </b> {formatDate(createdAt)} <br />
          <b>Joined: </b> {formatDate(joinedAt)} <br />
          {surveyStatus === 'finished'
            ? (
              <>
                <b>Survey Completed: </b> {formatDate(completed)} <br />
              </>
            ) : null}
        </>
      );

      return (
        <li key={id}>
          <AsmtTeamMemberCard
            teamId={id}
            firstName={firstName}
            lastName={lastName}
            asmtType={asmtType}
            userSurvey={userSurvey}
            avatarUrl={avatarUrl}
            displayName={name}
            surveyStatus={surveyStatus}
            displayDescription={displayDescription}
          />
        </li>
      );
    });

  return (
    <section className="profile-group js-member-teams">
      <h3 className="text-left profile-group__header font-heading-5">Teams you have joined</h3>
      <ul className="profile-group__list">
        {renderPendingInvitations()}
        {renderGroupsJoined()}
      </ul>
    </section>
  );
};

TeamsYouAreIn.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  asmtType: PropTypes.string.isRequired,
  groupsYouAreIn: PropTypes.arrayOf(PropTypes.shape({})),
  groupInvitations: PropTypes.arrayOf(PropTypes.shape({})),
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
};

TeamsYouAreIn.defaultProps = {
  groupsYouAreIn: [],
  groupInvitations: [],
};

export default TeamsYouAreIn;
