import tracker from '../index';

const {
  eventNames, resolveAfterTimeout,
} = tracker;

const trackBeginNewComparison = () => {
  // Track appcues 'Begin New Comparison' event
  window?.Appcues?.track(eventNames.BEGIN_NEW_COMPARISON);

  return resolveAfterTimeout;
};

export {
  trackBeginNewComparison,
};
