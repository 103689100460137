import React from 'react';
import PropTypes from 'prop-types';

import OwnedTeamCard from './OwnedTeamCard';
import Button from '../../../common/Button';

const TeamsYouOwn = ({ teamsYouOwn }) =>
  ((teamsYouOwn.length > 0)
    ? (
      <section className="p-4 px-2 mx-auto mb-2 lg:py-10 md:mb-5 md:px-8 lg:mb-10 bg-ivory-400 rounded-xl">
        <div className="inline-flex items-center justify-between w-full">
          <h4>
            Teams You Own
          </h4>
          <Button
            to="/profile/teams/new"
            className="block mb-4 ml-auto w-fit"
            variant="primary"
            trailingButtonIcon="arrow-right"
          >
            Create Team
          </Button>
        </div>
        <ul className="p-0 m-0 list-none">
          {teamsYouOwn.map(({ id, name, avatarUrl }) => (
            <li key={name}>
              <OwnedTeamCard
                id={id}
                avatarUrl={avatarUrl}
                displayName={name}
              />
            </li>
          ))}
        </ul>
      </section>
    ) : null);

TeamsYouOwn.propTypes = {
  teamsYouOwn: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      customMessage: PropTypes.string,
      isOwner: PropTypes.bool,
      avatarUrl: PropTypes.string,
    }),
  ),
};

TeamsYouOwn.defaultProps = {
  teamsYouOwn: [],
};

export default TeamsYouOwn;
