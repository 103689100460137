class GenerateNewAccessCodeAction {
  constructor(apiService) {
    this.apiService = apiService;
  }

  execute() {
    return this.apiService.get('access-codes/new');
  }
}

export default GenerateNewAccessCodeAction;
