import React from 'react';

const IBIDescription = () => (
  <div>
    <p className="text-sm">Purchase the IBI for full access to:</p>
    <ul className="mx-0 my-4 text-sm font-normal list-none ps-2">
      <li>The IBI survey</li>
      <li>Personal results and action plan</li>
      <li>Detailed advice on improving your score</li>
      <li>Create and Join unlimited groups</li>
    </ul>
  </div>
);

export default IBIDescription;
