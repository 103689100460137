import React from 'react';

import formatDate from '../../../../../../lib/dateFormatter';

import SentInvitationCard from '../../../../../Invitations/PendingInvitations/SentInvitations/SentInvitationCard';

const PendingRaterCards = ({ raters, onCancelRater, onResend }) =>
  raters
    .sort((a, b) => a.email.localeCompare(b.email))
    .map(({
      token,
      email: raterEmail,
      invitationSentDate,
    }) => {
      const raterDescription = (
        <b>Invitation Sent: {(invitationSentDate) ? formatDate(invitationSentDate) : '-'}</b>
      );
      return (
        <SentInvitationCard
          key={token}
          displayName={raterEmail}
          sentAt={(invitationSentDate) ? formatDate(invitationSentDate) : '-'}
          invitationSent={(invitationSentDate) ? formatDate(invitationSentDate) : '-'}
          displayDescription={raterDescription}
          token={token}
          onResend={onResend}
          onCancel={onCancelRater}
          isInviter
        />
      );
    });

export default PendingRaterCards;
