import React from 'react';
import PropTypes from 'prop-types';

const OrgDisclaimer = ({ hasOrg, profiles }) => {
  if (hasOrg) {
    const orgs = profiles.filter(profile => profile.type === 'org');
    return orgs.map(org => (
      <div key={org.id} className="flex justify-center px-4 text-center disclaimer md:px-0">
        <p className="font-sans font-light text-sm md:text-md text-charcoal-900">
          The {org.shortDisplayName} is built from&nbsp;
          {org.profileCount} profiles in the organization.
        </p>
      </div>
    ));
  }
  return null;
};

OrgDisclaimer.propTypes = {
  hasOrg: PropTypes.bool,
  profiles: PropTypes.arrayOf(PropTypes.shape({})),
};

OrgDisclaimer.defaultProps = {
  hasOrg: false,
  profiles: [],
};

export default OrgDisclaimer;
