import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import DropdownSelect from '../../../../components/common/Dropdowns/DropdownSelect';
import LabelInput from '../../../../components/common/LabelInput';

import { incrementsObject } from '../../../../lib/increments';

const ExternalForm = ({
  completionRangeStart,
  completionRangeEnd,
  directUrl,
  handleChange,
  errorMessage,
}) => {
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (completionRangeStart) {
      setIsDisabled(false);
    }
  }, [completionRangeStart]);

  return (
    <>
      <LabelInput
        id="directUrl"
        name="directUrl"
        labelType="text"
        labelText="Direct URL"
        value={directUrl}
        onChangeValue={handleChange}
        helperText="Enter the URL where the launch button should direct the user."
      />
      <div>
        <h3 className="mb-2 text-left font-heading-5">Set Time Range (Minutes)</h3>
        <div className="grid md:grid-cols-6">
          <div className="p-1 md:col-span-2">
            <DropdownSelect
              id="completionRangeStart"
              name="completionRangeStart"
              labelType="text"
              labelText="Completion Range Start"
              options={incrementsObject(0, 120, 5)}
              value={completionRangeStart}
              onChangeValue={handleChange}
              helperText="Completion time displays as a range. Enter the min. estimated time here."
            />
          </div>
          <div className="p-1 md:col-start-4 md:col-end-6">
            <DropdownSelect
              id="completionRangeEnd"
              name="completionRangeEnd"
              labelType="text"
              labelText="Completion Range End"
              options={incrementsObject(0, 120, 5)}
              value={completionRangeEnd}
              onChangeValue={handleChange}
              helperText="Enter the max. estimated time here."
              errorMessage={errorMessage}
              isDisabled={isDisabled}
            />
          </div>
        </div>
      </div>
    </>
  );
};

ExternalForm.propTypes = {
  completionRangeStart: PropTypes.string,
  completionRangeEnd: PropTypes.string,
  directUrl: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

ExternalForm.defaultProps = {
  completionRangeStart: '',
  completionRangeEnd: '',
  directUrl: '',
  errorMessage: '',
};

export default ExternalForm;
