class RedeemAccessCodeAction {
  constructor(apiService) {
    this.apiService = apiService;
  }

  execute(code) {
    return this.apiService.post(`access-codes/${code}/redeem`);
  }
}

export default RedeemAccessCodeAction;
