import tracker from '../index';

const {
  eventNames, eventProps, peopleProps, resolveAfterTimeout,
} = tracker;

const trackPaymentFormSubmit = ({
  userid,
  planName,
  planId,
  amount,
  quantity,
  forOthers,
  couponCode,
}) => {
  tracker.identify(userid);

  tracker.track(eventNames.SUBMIT_PURCHASE_FORM, {
    [eventProps.PLAN_NICKNAME]: planName,
    [eventProps.PLAN_ID]: planId,
    [eventProps.AMOUNT]: amount,
    [eventProps.QUANTITY]: quantity,
    [eventProps.PURCHASING_FOR_OTHERS]: forOthers,
    [eventProps.COUPON_CODE]: couponCode,
    [eventProps.SUCCESS]: true,
  });

  tracker.people.union(peopleProps.PURCHASES, planName);

  // Track appcues 'Submit Purchase Form' event
  window?.Appcues?.track(eventNames.SUBMIT_PURCHASE_FORM);

  return resolveAfterTimeout;
};

const trackPaymentFormFailure = ({
  planName,
  planId,
  amount,
  quantity,
  forOthers,
  couponCode,
}, reason) => {
  tracker.track(eventNames.SUBMIT_PURCHASE_FORM, {
    [eventProps.PLAN_NICKNAME]: planName,
    [eventProps.PLAN_ID]: planId,
    [eventProps.AMOUNT]: amount,
    [eventProps.QUANTITY]: quantity,
    [eventProps.PURCHASING_FOR_OTHERS]: forOthers,
    [eventProps.COUPON_CODE]: couponCode,
    [eventProps.SUCCESS]: false,
    [eventProps.FAILURE_REASON]: reason,
  });
  return resolveAfterTimeout;
};

export { trackPaymentFormSubmit, trackPaymentFormFailure };
