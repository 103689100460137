import React from 'react';
import { propTypes, defaultProps } from './types';

const SurveyQuestionNumber = props => (
  <p className="py-4 m-0 font-serif text-3xl italic leading-9 text-center text-gray-500 md:py-8 md:text-4xl">
    {props.currentQuestionNumber} of {props.totalQuestionCount}
  </p>
);

SurveyQuestionNumber.propTypes = propTypes;
SurveyQuestionNumber.defaultProps = defaultProps;

export default SurveyQuestionNumber;
