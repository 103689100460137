import React from 'react';
import { Link } from 'react-router';
import currentUrl from '../../../../lib/urls/currentUrl';

import { propTypes } from './types';

const emailNeedsVerified = (currentUser, location) => {
  const isVerified = currentUser.verifiedEmail;
  // Don't display message unless flag is explicitly set to false
  if (isVerified !== false) return null;

  const redirectUrl = currentUrl(location);
  const resendUrl = `/emails/resend-verification?redirect_to=${encodeURIComponent(redirectUrl)}`;

  return (
    <div className="notification-message">
      <p className="notification-message__text">
        Verify your email address to complete your account activation.
        (<Link to={resendUrl}>Resend verification email</Link>)
      </p>
    </div>
  );
};

emailNeedsVerified.propTypes = propTypes;

export default emailNeedsVerified;
