import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import CultureCards from '../CultureCards';
import LabelInput from '../../common/LabelInput';

import { filterItems } from '../../../lib/filter-items';
import Icon from '../../common/Icon';

const sortCultures = cultures => cultures
  .map(culture => ({
    id: culture.id,
    name: culture.shortDisplayName,
    value: culture.cultureGuideUrl,
    flagIcon: culture.flagIcon,
  }))
  .sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));

const Cultures = ({ cultures }) => {
  const [initialCultures, setIntialCultures] = useState(cultures);
  const [filteredArray, setFilteredArray] = useState(cultures);
  const [filterValue, setFilterValue] = useState('');

  const onSubmit = e => e.preventDefault();

  useEffect(() => {
    setIntialCultures(cultures);
    setFilteredArray(cultures);
  }, [cultures]);

  const handleFilterCultures = e => {
    const { value } = e.target;
    setFilterValue(value);

    if (value) {
      const culturesNamesMatch = filterItems(value, 'shortDisplayName', initialCultures);

      const filtered = [...new Set([...culturesNamesMatch])];

      return setFilteredArray(filtered);
    }
    return setFilteredArray(initialCultures);
  };

  return cultures
    ? (
      <>
        <div className="px-6 py-4 pb-8 bg-white rounded-lg md:pl-10 md:pb-10">
          <div className="flex flex-wrap items-center justify-between gap-4">
            <div className="flex items-center justify-between gap-4">
              <div className="rounded-full bg-ivory-400">
                <Icon icon="culture-guide" className="m-2.5 w-4 h-4" iconColor="gray" />
              </div>
              <h2 className="my-2 font-serif text-2xl antialiased font-thin tracking-wide sm:my-4 text-charcoal-500">GlobeSmart Guides</h2>
            </div>
            <form onSubmit={onSubmit} className="flex-grow max-w-xs border rounded-md border-gray-50 lg:ml-10">
              <LabelInput
                id="cultures"
                name="cultures"
                labelType="text"
                value={filterValue}
                onChangeValue={handleFilterCultures}
                type="text"
                placeholderText="Search Cultures"
                leadingIcon="search"
              />
            </form>
          </div>
        </div>
        <CultureCards
          cultures={sortCultures(filteredArray)}
          launchedFrom="Dashboard"
        />
      </>
    ) : null;
};

Cultures.propTypes = {
  cultures: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    shortDisplayName: PropTypes.string.isRequired,
    cultureGuideUrl: PropTypes.string.isRequired,
    flagIcon: PropTypes.string.isRequired,
  })).isRequired,
};

export default Cultures;
