import React from 'react';
import PropTypes from 'prop-types';

import AdviceContent from '../AdviceContent';
import BestPracticeAdvice from '../BestPracticeAdvice';

const AdviceItem = props => (
  <>
    <h4 className="px-4 py-2 my-8 font-sans text-sm tracking-widest uppercase rounded-full lg:text-base bg-ivory-400 text-charcoal-900 w-fit">
      Areas with {props.header}
    </h4>
    <ul className="list-none">
      {props.adviceItems.map(item =>
        <AdviceContent key={item.title} {...item} />)}
      {props.bestPracticeAdvice?.length
        ? <BestPracticeAdvice bestPractices={props.bestPracticeAdvice} />
        : null}
    </ul>
  </>
);

AdviceItem.propTypes = {
  header: PropTypes.string.isRequired,
  adviceItems: PropTypes.arrayOf(PropTypes.shape({
    explanation: PropTypes.string,
    title: PropTypes.string,
  })).isRequired,
  bestPracticeAdvice: PropTypes.arrayOf(
    PropTypes.shape(PropTypes.string),
  ),
};
AdviceItem.defaultProps = {
  bestPracticeAdvice: [],
};

export default AdviceItem;
