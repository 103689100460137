import tracker from '../index';

const { eventNames, eventProps, resolveAfterTimeout } = tracker;

const trackPaymentModalOpen = ({ planName, planId }) => {
  tracker.track(eventNames.OPEN_PURCHASE_WINDOW, {
    [eventProps.PLAN_NICKNAME]: planName,
    [eventProps.PLAN_ID]: planId,
  });

  // Track appcues 'Open Purchase Window' event
  window?.Appcues?.track(eventNames.OPEN_PURCHASE_WINDOW);

  return resolveAfterTimeout;
};

export { trackPaymentModalOpen };
