import PropTypes from 'prop-types';

const propTypes = {
  isComparisonItem: PropTypes.bool,
  isInvitation: PropTypes.bool,
  isVerifyAction: PropTypes.bool,
  inputRef: PropTypes.func,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  type: PropTypes.string,
  shortDisplayName: PropTypes.string,
  displayName: PropTypes.string,
  displayDescription: PropTypes.string,
  avatarUrl: PropTypes.string,
  cultureGuideUrl: PropTypes.string,
  avatarImgAlt: PropTypes.string,
  department: PropTypes.string,
  displayStatus: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.string,
};

const defaultProps = {
  isComparisonItem: false,
  isInvitation: false,
  isVerifyAction: false,
  inputRef: () => { },
  id: null,
  type: null,
  shortDisplayName: null,
  displayName: null,
  displayDescription: null,
  avatarUrl: null,
  cultureGuideUrl: null,
  avatarImgAlt: null,
  department: null,
  displayStatus: null,
  children: null,
  color: ' ',
};

export {
  propTypes,
  defaultProps,
};
