import { eventNames, customAttributes } from '../index';

const brazeAccessCodeRedeem = (name, accessCode) => {
  window?.appboy?.logCustomEvent(eventNames.REDEEM_ACCESS_CODE, { planName: name, accessCode });
  window?.appboy?.requestImmediateDataFlush();
};

const brazeProductUpdatesOptIn = optIn => {
  window?.appboy?.getUser().setCustomUserAttribute(customAttributes.PRODUCT_UPDATES, optIn);
  if (optIn) {
    window?.appboy?.logCustomEvent(eventNames.SUBSCRIBE_TO_PRODUCT_UPDATES);
  }
  window?.appboy?.requestImmediateDataFlush();
};

const brazeCreateGroupSuccess = ({
  asmtType,
  groupName,
  groupSize,
  memberEmails,
}) => {
  window?.appboy?.logCustomEvent(eventNames.CREATE_GROUP, {
    Assessment: asmtType,
    'Group Name': groupName,
    'Group Size': groupSize,
    'Group Members': memberEmails,
  });
  window?.appboy?.requestImmediateDataFlush();
};

const brazeCreateTeamSuccess = ({ teamName, teamMembers, teamSize }) => {
  window?.appboy?.logCustomEvent(eventNames.CREATE_TEAM,
    {
      'Team Name': teamName,
      'Team Size': teamSize,
      'Team Members': teamMembers,
    });
  window?.appboy?.requestImmediateDataFlush();
};

export {
  brazeAccessCodeRedeem,
  brazeProductUpdatesOptIn,
  brazeCreateGroupSuccess,
  brazeCreateTeamSuccess,
};
