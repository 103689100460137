import React from 'react';
import PropTypes from 'prop-types';

import LabelInput from '../../../common/LabelInput';

const UserSearchForm = props => {
  const onChange = ({ target: { value, id } }) => {
    props.onChange({ [id]: value });
  };

  return (
    <form>
      {props.heading && <h2>{props.heading}</h2>}
      <LabelInput
        id="lastName"
        name="Last Name"
        labelText="Last Name"
        labelType="text"
        value={props.lastName}
        onChangeValue={onChange}
      />
      <LabelInput
        id="firstName"
        name="First Name"
        labelText="First Name"
        labelType="text"
        value={props.firstName}
        onChangeValue={onChange}
      />
      <LabelInput
        id="email"
        name="Email"
        labelText="Email"
        labelType="text"
        value={props.email}
        onChangeValue={onChange}
      />
    </form>
  );
};

UserSearchForm.propTypes = {
  heading: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

UserSearchForm.defaultProps = {
  heading: null,
};

export default UserSearchForm;
