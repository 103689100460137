import React from 'react';
import PropTypes from 'prop-types';

const Left = ({ bgColor, isChecked, isHovered }) => (
  <svg className={`w-full md:max-w-[4rem] lg:max-w-[5rem] h-auto ${isChecked || isHovered ? 'fill-rust-500' : bgColor}`} viewBox="0 0 78 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M27.6332 25.7667C27.6332 39.5718 38.8279 50.7665 52.633 50.7665C66.4381 50.7665 77.6328 39.5718 77.6328 25.7667C77.6328 11.9615 66.4423 0.766846 52.633 0.766846C38.8237 0.766846 27.6332 11.9573 27.6332 25.7667Z" />
    <path d="M0.601564 25.9983C0.601563 12.8022 11.1237 2.05819 24.2347 1.70684C24.6009 1.69574 24.9004 1.99902 24.9004 2.36516L24.9004 49.6352C24.9004 50.0013 24.6009 50.3009 24.2347 50.2935C11.1237 49.9385 0.601564 39.1981 0.601564 25.9983Z" />
  </svg>
);

Left.propTypes = {
  bgColor: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  isHovered: PropTypes.bool.isRequired,
};

const Right = ({ bgColor, isChecked, isHovered }) => (
  <svg className={`w-full md:max-w-[4rem] lg:max-w-[5rem] h-auto ${isChecked || isHovered ? 'fill-rust-500' : bgColor}`} viewBox="0 0 78 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M50.3668 25.7667C50.3668 39.5718 39.1721 50.7665 25.367 50.7665C11.5619 50.7665 0.367188 39.5718 0.367188 25.7667C0.367188 11.9615 11.5577 0.766846 25.367 0.766846C39.1763 0.766846 50.3668 11.9573 50.3668 25.7667Z" />
    <path d="M77.3984 25.9983C77.3984 12.8022 66.8763 2.05819 53.7653 1.70684C53.3991 1.69574 53.0996 1.99902 53.0996 2.36516L53.0996 49.6352C53.0996 50.0013 53.3991 50.3009 53.7653 50.2935C66.8763 49.9385 77.3984 39.1981 77.3984 25.9983Z" />
  </svg>
);

Right.propTypes = {
  bgColor: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  isHovered: PropTypes.bool.isRequired,
};

const Neutral = ({ bgColor, isChecked, isHovered }) => (
  <svg className={`w-full max-w-[2rem] md:max-w-[3rem] lg:max-w-[3.75rem] h-auto ${isChecked || isHovered ? 'fill-rust-500' : bgColor}`} viewBox="0 0 56 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M55.3008 24.292C55.3008 11.0959 44.7787 0.351893 31.6676 0.000538206C31.3015 -0.0105572 31.0019 0.292717 31.0019 0.658864L31.0019 47.9289C31.0019 48.295 31.3015 48.5946 31.6676 48.5872C44.7787 48.2322 55.3008 37.4918 55.3008 24.292Z" />
    <path d="M0.703126 24.292C0.703125 11.0959 11.2252 0.351893 24.3363 0.000538206C24.7024 -0.0105572 25.002 0.292717 25.002 0.658864L25.002 47.9289C25.002 48.295 24.7024 48.5946 24.3363 48.5872C11.2252 48.2322 0.703127 37.4918 0.703126 24.292Z" />
  </svg>
);

Neutral.propTypes = {
  bgColor: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  isHovered: PropTypes.bool.isRequired,
};

const icons = {
  left: Left,
  right: Right,
  neutral: Neutral,
};

const RadioIcons = ({ bgColor, icon, isChecked, isHovered }) => {
  const Component = icons[icon];
  return (<Component bgColor={bgColor} isChecked={isChecked} isHovered={isHovered} />);
};

RadioIcons.propTypes = {
  bgColor: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  isHovered: PropTypes.bool.isRequired,
};

export default RadioIcons;
