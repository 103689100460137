import React from 'react';
import { iconPropTypes, defaultIconProps } from './types';

import { strokeColorMap } from '../index.js';

const Passcode = ({ className, iconColor }) => (
  <svg className={`${className} ${strokeColorMap[iconColor]}`} viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 6H11.005M16 6H16.005M6 6H6.005M4.2 1H17.8C18.9201 1 19.4802 1 19.908 1.21799C20.2843 1.40973 20.5903 1.71569 20.782 2.09202C21 2.51984 21 3.0799 21 4.2V7.8C21 8.9201 21 9.48016 20.782 9.90798C20.5903 10.2843 20.2843 10.5903 19.908 10.782C19.4802 11 18.9201 11 17.8 11H4.2C3.0799 11 2.51984 11 2.09202 10.782C1.71569 10.5903 1.40973 10.2843 1.21799 9.90798C1 9.48016 1 8.9201 1 7.8V4.2C1 3.0799 1 2.51984 1.21799 2.09202C1.40973 1.71569 1.71569 1.40973 2.09202 1.21799C2.51984 1 3.0799 1 4.2 1ZM11.25 6C11.25 6.13807 11.1381 6.25 11 6.25C10.8619 6.25 10.75 6.13807 10.75 6C10.75 5.86193 10.8619 5.75 11 5.75C11.1381 5.75 11.25 5.86193 11.25 6ZM16.25 6C16.25 6.13807 16.1381 6.25 16 6.25C15.8619 6.25 15.75 6.13807 15.75 6C15.75 5.86193 15.8619 5.75 16 5.75C16.1381 5.75 16.25 5.86193 16.25 6ZM6.25 6C6.25 6.13807 6.13807 6.25 6 6.25C5.86193 6.25 5.75 6.13807 5.75 6C5.75 5.86193 5.86193 5.75 6 5.75C6.13807 5.75 6.25 5.86193 6.25 6Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

Passcode.propTypes = iconPropTypes;
Passcode.defaultProps = defaultIconProps;

export default Passcode;
