import PropTypes from 'prop-types';

const propTypes = {
  currentUser: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export {
  propTypes,
};
