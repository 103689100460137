import React from 'react';
import { Link } from 'react-router';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

const renderInfo = orgs => orgs.map(org => (
  <tr key={org.id} className="even:bg-ivory-500">
    <td className="py-4 pl-0 pr-3 md:pl-4 whitespace-nowrap" data-label="Organization">
      <Link to={`/administration/organizations/${org.id}`}>{org.name}</Link>
    </td>
    <td className="py-4 pl-0 pr-3 md:pl-4 whitespace-nowrap" data-label="Display Name">
      {org.displayName}
    </td>
    <td className="py-4 pl-0 pr-3 md:pl-4 whitespace-nowrap" data-label="Created">
      {format(new Date(org.created), 'MM/dd/yyyy')}
    </td>
    <td className="py-4 pl-0 pr-3 md:pl-4 whitespace-nowrap" data-label="Members">
      {org.memberCount}
    </td>
  </tr>
));

const OrganizationList = ({ organizations }) => {
  if (!organizations || !organizations.length) return null;

  return (
    <div className="mt-5 overflow-x-auto">
      <table className="w-full text-sm divide-y divide-gray-300 text-charcoal-900">
        <thead>
          <tr>
            <th className="py-3.5 pl-0 md:pl-4 pr-3 text-left font-semibold">
              Organization
            </th>
            <th className="py-3.5 pl-0 md:pl-4 pr-3 text-left font-semibold">
              Display Name
            </th>
            <th className="py-3.5 pl-0 md:pl-4 pr-3 text-left font-semibold">
              Created
            </th>
            <th className="py-3.5 pl-0 md:pl-4 pr-3 text-left font-semibold">
              Members
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {renderInfo(organizations)}
        </tbody>
      </table>
    </div>
  );
};

OrganizationList.propTypes = {
  organizations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    created: PropTypes.string.isRequired,
    memberCount: PropTypes.number.isRequired,
  })).isRequired,
};

export default OrganizationList;
