import React from 'react';
import PropTypes from 'prop-types';

import GroupOwnerCard from './GroupOwnerCard';
import Button from '../../../common/Button';

import formatDate from '../../../../lib/dateFormatter';
import SummaryBlock from './SummaryBlock';

const GroupsYouOwn = ({
  asmtType,
  groupsOwned,
  emailAssessmentGroupReport,
  seatsRemaining,
  hasOrg,
  refreshData,
  isIBIAdmin,
  groupOrTeam,
}) => (((isIBIAdmin || groupsOwned.length > 0) || !hasOrg || asmtType !== 'ibi')
  ? (
    <section className="py-8 overflow-y-auto bg-ivory-400">
      <div className="inline-flex items-center justify-between w-full">
        <h4>{groupOrTeam}s you own</h4>
        {!hasOrg
          ? (
            <SummaryBlock
              seatsRemaining={seatsRemaining}
              asmtType={asmtType}
              refreshData={refreshData}
            />
          )
          : null}
        {isIBIAdmin || asmtType !== 'ibi'
          ? (
            <Button
              className="block mb-4 ml-auto w-fit"
              isSmall
              isNormalCase
              variant="primary"
              to={`/assessments/${asmtType}/group/new`}
            >
              {`Create ${groupOrTeam}`}
            </Button>
          )
          : null}
      </div>
      <ul className="profile-group__list">
        {groupsOwned.map(({
          id,
          name,
          avatarUrl,
          createdAt,
          status,
        }) => {
          const displayDescription = (
            <>
              <b>Created: </b> {formatDate(createdAt)} <br />
              {status}
            </>
          );
          return (
            <li key={id}>
              <GroupOwnerCard
                avatarUrl={avatarUrl}
                displayName={name}
                displayDescription={displayDescription}
                assessment={asmtType}
                groupId={id}
                emailAssessmentGroupReport={emailAssessmentGroupReport}
                groupOrTeam={groupOrTeam}
              />
            </li>
          );
        })}
      </ul>
    </section>
  ) : null);

GroupsYouOwn.propTypes = {
  asmtType: PropTypes.string.isRequired,
  groupsOwned: PropTypes.arrayOf(PropTypes.shape({})),
  emailAssessmentGroupReport: PropTypes.func.isRequired,
  seatsRemaining: PropTypes.number,
  hasOrg: PropTypes.bool,
  refreshData: PropTypes.func.isRequired,
  isIBIAdmin: PropTypes.bool,
  groupOrTeam: PropTypes.string.isRequired,
};

GroupsYouOwn.defaultProps = {
  groupsOwned: [],
  seatsRemaining: null,
  hasOrg: false,
  isIBIAdmin: false,
};

export default GroupsYouOwn;
