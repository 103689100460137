import React from 'react';
import PropTypes from 'prop-types';

import ProfileItem from '../../ProfileItem';

const determineProfileItemClass = index => {
  let classIndex;
  switch (index) {
    case 0:
      classIndex = 'one';
      break;
    case 1:
      classIndex = 'two';
      break;
    case 2:
      classIndex = 'three';
      break;
    case 3:
      classIndex = 'four';
      break;
    case 4:
      classIndex = 'five';
      break;
    default:
      classIndex = 'hide';
  }
  return `profile__item profile__item--${classIndex}`;
};

const ProfileItems = ({ dimensionLabelGroups, isDashboard }) => (
  dimensionLabelGroups
    ? dimensionLabelGroups.map((group, index) => (
      <ProfileItem
        key={`${group.left}/${group.right}`}
        classNames={determineProfileItemClass(index)}
        left={group.left}
        right={group.right}
        isDashboard={isDashboard}
      />
    ))
    : null
);

ProfileItems.propTypes = {
  dimensionLabelGroups: PropTypes.arrayOf(
    PropTypes.shape({
      left: PropTypes.string,
      right: PropTypes.string,
    }),
  ),
  isDashboard: PropTypes.bool,
};

ProfileItems.defaultProps = {
  dimensionLabelGroups: [],
  isDashboard: false,
};

export default ProfileItems;
