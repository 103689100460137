import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router';
import PageHeader from '../../components/PageHeader';
import withServerSideData from '../../HOC/withServerSideData';
import Icon from '../../components/common/Icon';

import CircularProgress from '../../components/common/CircularProgress';
import { VIEW_THE_GSP_TEAM_PROFILE_ACTIVITY } from './activityConstants';
import { trackActivityCompletion } from '../../actions/skills';
import { APIContext } from '../../context/API';
import { trackTeamProfileActivityViewed } from '../../lib/tracker/teams';
import Badges from '../../components/common/Badges';
import { CurrentUserContext } from '../../context/CurrentUser';
import { trackSkillActivityClick } from '../../lib/tracker/skills';

const CustomLink = ({
  activityName,
  activityLink,
  activityCode,
  className,
  isTrial,
  skillName,
  children,
}) => {
  const { apiService } = useContext(APIContext);
  const isExternal = activityLink?.startsWith('http') || activityLink?.startsWith('https');
  const isGSLearning = isExternal && activityLink.includes('gslearning');
  const isCulture = activityLink === '/guides';
  const isVideo = activityLink === '/profile';

  const trackActivity = code => {
    if (code === VIEW_THE_GSP_TEAM_PROFILE_ACTIVITY) {
      trackActivityCompletion(apiService, code);
      trackTeamProfileActivityViewed();
    }
    trackSkillActivityClick(apiService, activityName, skillName);
  };

  if (isExternal || (isVideo && activityCode.includes('video'))) {
    if (isTrial && isGSLearning) {
      return (<span className={`text-gray-700 cursor-default ${isTrial && 'm-[0.35rem]'}`}>{children}</span>);
    }
    return (
      <a
        onClick={() => trackActivity(activityCode)}
        href={isVideo ? `${activityLink}?videoTrackingId=${activityCode.replace(/watch_|_video/g, '')}#profile-video` : activityLink}
        className={`${className} ${isTrial && 'm-[0.35rem]'}`}
        key={activityCode}
        target={!isVideo ? '_blank' : ''}
        rel="noopener noreferrer"
      >
        {children}
      </a>
    );
  }
  return (
    <Link to={isCulture ? `${activityLink}?activity=${activityCode}` : activityLink} key={activityCode} className={`${className} ${isTrial && 'm-[0.35rem]'}`} onClick={() => trackActivity(activityCode)}>
      {children}
    </Link>
  );
};

CustomLink.propTypes = {
  activityName: PropTypes.string.isRequired,
  activityLink: PropTypes.string.isRequired,
  activityCode: PropTypes.string.isRequired,
  isTrial: PropTypes.bool.isRequired,
  skillName: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const MySkills = ({ initialData: { skills } }) => {
  const [updatedSkills, setUpdatedSkills] = useState(skills);

  useEffect(() => {
    const newSkills = skills.map(skill => ({
      ...skill,
      paragraphOne: skill.description.split('\n\n').shift(),
      paragraphTwo: skill.description.split('\n\n').pop(),
    }));
    setUpdatedSkills(newSkills);
  }, []);

  const { currentUser: { isTrial } } = useContext(CurrentUserContext);

  const isNewActivity = completionDate => {
    const now = moment();
    const completion = moment(completionDate);
    return now.diff(completion, 'hours') < 48;
  };

  return (
    <div className="h-full min-h-full full-width">
      <PageHeader
        pageTitle="My Skills"
        icon="award"
        skipTarget="#my-skills"
      />
      <div>
        <div className="mb-6 -mt-4 md:mb-10 lg:mb-14">
          <p className="w-full mb-0 text-sm lg:w-3/4">
            Enhance your professional capabilities and foster
            a more productive work environment
            by earning essential workplace skills.
          </p>
        </div>
        <section className="flex flex-col w-full m-auto xl:my-8 rounded-3xl">
          {updatedSkills?.map(skill => (
            <div key={skill.id} className="flex flex-wrap mb-4 bg-white rounded-lg" id={skill.name.toLowerCase().split(' ').join('-')}>
              <div className="w-full p-2 pb-0">
                <h3 className="pt-4 pl-4">{skill.name}</h3>
              </div>
              <div className="p-3 space-x-3 md:space-x-1 lg:space-x-3 md:flex">
                <div className="flex w-full p-2 md:w-[22%]">
                  <div className="relative flex flex-col items-center justify-center w-56 h-56 mx-auto mb-4 md:w-24">
                    <CircularProgress
                      total={skill.activities.length}
                      completed={skill.activities.filter(a => a.isCompleted).length}
                      className="w-40 mb-2 text-2xl md:w-36 lg:w-40"
                    />
                  </div>
                </div>
                <div className="flex w-full p-2 md:w-[52%] lg:w-[50%] md:order-last" key={skill.id}>
                  <ul className="grid grid-cols-1 gap-2 p-0 list-none lg:col-span-2 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2">
                    {skill.activities.map(activity => (
                      <CustomLink
                        key={activity.activityCode}
                        activityLink={activity.activityLink}
                        activityName={activity.name}
                        activityCode={activity.activityCode}
                        className="no-underline"
                        isTrial={isTrial}
                        skillName={skill.name}
                      >
                        <li key={activity.activityCode} className="relative flex items-center space-x-4 hover:bg-stone-50">
                          <div className="flex items-center space-x-4">
                            <div className="relative flex flex-col items-center flex-shrink-0">
                              <img src={activity.isCompleted ? activity.completedIconImage : activity.iconImage} alt={activity.name} className={`w-12 mt-1 mb-1 ${isTrial && activity.activityLink.includes('gslearning') && 'grayscale'}`} />
                              {activity.isCompleted && isNewActivity(activity.completionDate)
                                && (
                                  <span className="h-6 -mt-3">
                                    <Badges
                                      color="green"
                                      badges={['NEW']}
                                      size="small"
                                    />
                                  </span>
                                )}
                              {activity.isCompleted ? (
                                <div className="absolute top-[-4px] right-[-4px] bg-green-500 border border-green-500 rounded-full w-4 h-4 flex items-center justify-center">
                                  <Icon className="w-3 h-3" icon="check" iconColor="white" />
                                </div>
                              ) : null}
                            </div>
                            <span className="flex text-sm leading-5">{activity.name}</span>
                          </div>
                          {(activity.activityLink.includes('gslearning') && isTrial) && (
                            <span className="bottom-0 px-2 py-1 mt-1 -mb-5 text-sm leading-5 rounded-lg md:absolute text-charcoal-700 bg-ivory-400">
                              Not available during the trial
                            </span>
                          )}
                        </li>
                      </CustomLink>
                    ))}
                  </ul>
                </div>
                <div className="flex w-full p-2 2xl:w-1/4 xl:w-1/4 md:w-[35%] md:order-last">
                  <div className="flex justify-center">
                    <div className="flex flex-col items-center p-3 m-1 text-left text-gray-700 border rounded-2xl bg-ivory-400 min-w-[200px] min-h-[200px]">
                      <div className={`relative flex items-center justify-center md:w-32 md:h-32 lg:w-48 lg:h-48 w-48 h-48 mx-auto mb-4 ${skill.isAchieved !== 'complete' ? 'grayscale' : ''}`}>
                        <img src={skill.iconImage} alt={skill.name} className="max-w-full max-h-full" />
                      </div>
                      {skill.skillAchievedAt && (
                        <span className="mb-2 text-sm font-semibold leading-6 text-charcoal-900">
                          Achieved on {moment(skill.skillAchievedAt).format('MMMM Do, YYYY')}
                        </span>
                      )}
                      <span className="mb-4 text-sm font-normal leading-5">{skill.paragraphOne}</span>
                      <span className="text-sm font-normal leading-5">{skill.paragraphTwo}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </section>
      </div>
    </div>
  );
};

MySkills.getAPIDataKey = () => 'skills';
MySkills.getData = apiService =>
  apiService.get('skills').then(data => ({ skills: data }));

MySkills.propTypes = {
  initialData: PropTypes.shape({
    skills: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        iconImage: PropTypes.string,
        isAchieved: PropTypes.string,
        description: PropTypes.string,
        activities: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            iconImage: PropTypes.string,
            isCompleted: PropTypes.bool,
            activityCode: PropTypes.string,
            completionDate: PropTypes.string,
          }),
        ),
      }),
    ),
  }).isRequired,
};

export default withServerSideData(MySkills);
