import React from 'react';
import { iconPropTypes, defaultIconProps } from './types';

import { fillColorMap, strokeColorMap } from '../index.js';

const BellIcon = ({ className, iconColor }) => (
  <svg className={`${className} ${fillColorMap[iconColor]} ${strokeColorMap[iconColor]} `} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M23.3333 34.9999H16.6667M30 13.3333C30 10.6811 28.9464 8.13755 27.0711 6.26218C25.1957 4.38682 22.6522 3.33325 20 3.33325C17.3478 3.33325 14.8043 4.38682 12.9289 6.26218C11.0536 8.13755 10 10.6811 10 13.3333C10 18.4836 8.70078 22.0098 7.24944 24.3423C6.02521 26.3097 5.4131 27.2934 5.43554 27.5678C5.46039 27.8717 5.52476 27.9876 5.76962 28.1692C5.99076 28.3333 6.98765 28.3333 8.98142 28.3333H31.0186C33.0123 28.3333 34.0092 28.3333 34.2304 28.1692C34.4752 27.9876 34.5396 27.8717 34.5645 27.5678C34.5869 27.2934 33.9748 26.3097 32.7506 24.3423C31.2992 22.0098 30 18.4836 30 13.3333Z" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" /></svg>
);

BellIcon.propTypes = iconPropTypes;
BellIcon.defaultProps = defaultIconProps;

export default BellIcon;
