import React from 'react';

import { iconPropTypes, defaultIconProps } from './types';
import { strokeColorMap } from '../index.js';

const ShareIcon = ({ className, iconColor }) => (
  <svg className={`${className} ${strokeColorMap[iconColor]}`} id="share" data-name="share" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.62 18.18">
    <path strokeWidth="1" d="m15.54,16.9c-.06,0-.11-.01-.17-.04-.14-.07-.23-.21-.23-.36v-4.4c-4.94.09-9.81,1.75-13.76,4.72-.14.1-.33.11-.47,0-.14-.1-.2-.28-.15-.45,2.07-6.26,7.82-10.5,14.38-10.67V1.3c0-.16.09-.3.23-.36.14-.06.31-.04.43.06l8.8,7.6c.09.08.14.19.14.3s-.05.23-.14.3l-8.8,7.6c-.07.06-.17.1-.26.1Zm0-5.6c.22,0,.4.18.4.4v3.93l7.79-6.73-7.79-6.73v3.93c0,.22-.18.4-.4.4-5.95,0-11.24,3.53-13.56,8.92,3.99-2.66,8.76-4.12,13.56-4.12Z" />
  </svg>
);

ShareIcon.propTypes = iconPropTypes;

ShareIcon.defaultProps = defaultIconProps;

export default ShareIcon;
