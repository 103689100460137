import React, { useContext, useEffect, useReducer, useRef } from 'react';
import PropTypes from 'prop-types';

import { APIContext } from '../../../../context/API';
import withServerSideData from '../../../../HOC/withServerSideData';

import ProfileChartTransformer from '../../../common/ProfileChartTransformer';
import AdviceGroup from '../AdviceGroup';
import BackArrowLink from '../../../common/BackArrowLink';

import comparableIDandType from '../../../../lib/comparableIDandType';
import AdviceTopicsListing from '../AdviceTopicsListing';
import PremiumFeature from '../../../../lib/features/Premium';
import { trackAdviceViewed } from '../../../../lib/tracker/advice';
import { CurrentUserContext } from '../../../../context/CurrentUser';

const getTopicalAdvice = (apiService, topicId, type, id, language) => apiService
  .get(`profile/compare/advice/${topicId}?${type}=${id}&language=${language}`);

const TopicalAdvice = props => {
  const { currentUser } = useContext(CurrentUserContext);
  const { apiService } = useContext(APIContext);
  const adviceGroupItemsRef = useRef(null);

  const { id, type } = comparableIDandType(props);
  const { topicId } = props;
  const [state, setState] = useReducer((data, newData) =>
    ({ ...data, ...newData }), {
    ...props.initialData,
    topicId,
    language: props.selectedLanguage,
    shortDisplayName: props.shortDisplayName,
  });

  const hasPremium = () => new PremiumFeature(currentUser)
    .positive(() => true)
    .negative(() => false)
    .execute();

  const trackAdvice = async e => {
    let topic = e.target.getAttribute('data-topic');

    if (!topic) {
      const parentWithDataTopic = e.target.closest('[data-topic]');
      if (parentWithDataTopic) {
        topic = parentWithDataTopic.getAttribute('data-topic');
      }
    }
    await trackAdviceViewed(apiService, topic, props.shortDisplayName);
  };

  useEffect(() => {
    const fetchadviceTopicsData = async () => {
      await apiService.get(`profile/compare?${type}=${id}&language=${props.selectedLanguage}`)
        .then(data => {
          const { adviceTopics, topAdvice } = data;
          setState({
            ...state,
            adviceTopics,
            topAdvice,
            language: props.selectedLanguage,
          });
          adviceGroupItemsRef.current.scrollIntoView({ behavior: 'smooth' });
        });
    };
    fetchadviceTopicsData()
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (topicId || props.selectedLanguage !== state.language) {
      const fetchData = async () => {
        await getTopicalAdvice(apiService, topicId, type, id, props.selectedLanguage)
          .then(({ topic, adviceGroupItems }) => {
            setState({
              topic,
              adviceGroupItems,
              language: props.selectedLanguage,
            });
            adviceGroupItemsRef.current.scrollIntoView({ behavior: 'smooth' });
          });
      };
      fetchData()
        .catch(console.error);
    }
  }, [topicId, props.selectedLanguage]);

  return (
    <div>
      <div className="relative grid grid-cols-1 gap-4 md:pt-8 md:grid-cols-2">
        <div className="hidden mx-auto lg:w-5/6 md:block">
          <div className="sticky px-6 py-4 rounded-lg top-8 bg-slate-25">
            <ProfileChartTransformer
              {...props}
              isAdviceComparison
              withBorder
              bgColor="bg-slate-25"
            />
          </div>
        </div>
        <div>
          {state.adviceGroupItems
            ? (
              <div ref={adviceGroupItemsRef}>
                <BackArrowLink to={`/profile/comparison${props.location.search}`}>
                  Back to Top Advice
                </BackArrowLink>
                <h3>{state.topic}</h3>
                <AdviceGroup adviceGroupItems={state.adviceGroupItems} />
                <BackArrowLink to={`/profile/comparison${props.location.search}`}>
                  Back to Top Advice
                </BackArrowLink>
              </div>
            )
            : null}
        </div>
      </div>
      <AdviceTopicsListing
        {...state}
        onClick={trackAdvice}
        isAuthorized={hasPremium()}
        searchParams={props.location.search}
      />

    </div>
  );
};

TopicalAdvice.getAPIDataKey = ({ org, teams_average: team, culture, person, topicId }) =>
  `topicalAdvice_${topicId}_${person || team || culture || org}`;

TopicalAdvice.getData = (apiService, params) => {
  const { id, type } = comparableIDandType(params);
  const { topicId, language } = params;
  return getTopicalAdvice(apiService, topicId, type, id, language)
    .then(data => ({ [`topicalAdvice_${topicId}_${id}`]: data }));
};

TopicalAdvice.propTypes = {
  topicId: PropTypes.string.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  selectedLanguage: PropTypes.string.isRequired,
  initialData: PropTypes.shape({
    topicalAdvice: PropTypes.shape({
      topic: PropTypes.string,
      adviceGroupItems: PropTypes.arrayOf(
        PropTypes.shape({
          header: PropTypes.string.isRequired,
          adviceItems: PropTypes.arrayOf(
            PropTypes.shape({
              title: PropTypes.string.isRequired,
              explanation: PropTypes.string.isRequired,
            })),
          bestPracticeAdvice: PropTypes.arrayOf(PropTypes.string),
        })),
    }),
  }),
  shortDisplayName: PropTypes.string,
};
TopicalAdvice.defaultProps = {
  initialData: {
    topicalAdvice: {
      topic: null,
      adviceGroupItems: [],
    },
  },
  shortDisplayName: '',
};

export default withServerSideData(TopicalAdvice);
