import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { NotificationContext } from '../../../context/Notification';
import { APIContext } from '../../../context/API';

import withServerSideData from '../../../HOC/withServerSideData';

import { updateblockAction } from '../../../actions/blocks';

import BlockForm from '../BlocksNew/BlockForm';

const BlocksEdit = ({ initialData, params: { id } }) => {
  const { apiService } = useContext(APIContext);

  const {
    notificationMessage,
    removeNotification,
    addNotification,
  } = useContext(NotificationContext);

  useEffect(() => {
    let timeout;
    if (notificationMessage) {
      timeout = window.setTimeout(() => {
        removeNotification();
      }, 5000);
    }
    return () => {
      window.clearTimeout(timeout);
      if (notificationMessage) removeNotification();
    };
  }, [notificationMessage]);

  const formSubmit = async blockData => {
    try {
      await updateblockAction(apiService, id, blockData);
      addNotification({
        type: 'success',
        message: 'Block updated',
      });
    } catch (err) {
      addNotification({
        type: 'failure',
        message: `Error: ${err.message}`,
      });
    }
  };

  return (
    <BlockForm
      formData={initialData}
      handleFormSubmit={formSubmit}
      buttonText="Update Block"
      subHeader="Edit the Block details"
    />
  );
};

BlocksEdit.getAPIDataKey = () => 'blockDetails';
BlocksEdit.getData = (apiService, { id }) => apiService.get(`blocks/${id}`)
  .then(blockDetails => ({ blockDetails }));

BlocksEdit.propTypes = {
  initialData: PropTypes.shape({
    blockDetails: PropTypes.shape({
      name: PropTypes.string,
      title: PropTypes.string,
      type: PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.number,
      }),
      description: PropTypes.string,
      blockImage: PropTypes.string,
      tags: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          value: PropTypes.number,
        }),
      ),
      stripePlans: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          value: PropTypes.string,
        }),
      ),
    }),
    plans: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    types: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.number,
      })),
  }).isRequired,
  params: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

export default withServerSideData(BlocksEdit);
