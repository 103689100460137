import React, { useContext, useEffect, useState } from 'react';

import { APIContext } from '../../../context/API';
import { NotificationContext } from '../../../context/Notification';

import CohortsList from '../../../components/Cohorts/CohortsList';
import LabelInput from '../../../components/common/LabelInput';
import Notification from '../../../components/Notifications/Notification';

import { searchCohortsAction } from '../../../actions/cohorts';

const CohortSearch = () => {
  const { apiService } = useContext(APIContext);
  const {
    addNotification,
    notificationMessage,
    removeNotification,
  } = useContext(NotificationContext);

  const [cohortName, setCohortName] = useState('');
  const [cohorts, setCohorts] = useState([]);

  useEffect(() => {
    let timeout;
    if (notificationMessage) {
      timeout = window.setTimeout(() => {
        removeNotification();
      }, 5000);
    }
    return () => {
      window.clearTimeout(timeout);
      if (notificationMessage) removeNotification();
    };
  }, [notificationMessage]);

  useEffect(() => {
    let timer;
    if (!cohortName) return setCohorts([]);
    if (timer) window.clearTimeout(timer);
    timer = setTimeout(() => searchCohortsAction(apiService, cohortName)
      .then(data => setCohorts(data.cohorts))
      .catch(err => {
        addNotification({ type: 'warning', message: `Error searching for organization: ${err.message}` });
      }), 250);
    return () => clearTimeout(timer);
  }, [cohortName]);

  const onSearchParamChange = ({ target: { value } }) => {
    setCohortName(value);
  };

  const onSubmit = e => e.preventDefault();

  return (
    <>
      {notificationMessage && <Notification {...notificationMessage} />}
      <div className="p-1">
        <form onSubmit={onSubmit}>
          <LabelInput
            id="cohortName"
            name="Cohort Name"
            labelText="Search Term"
            labelType="text"
            value={cohortName}
            onChangeValue={onSearchParamChange}
            helperText="Search by Cohort Name, Cohort Code, SF Job Code, or Organization Name."
          />
        </form>
      </div>
      <CohortsList cohorts={cohorts} />
    </>
  );
};

export default CohortSearch;
