import queryString from 'query-string';
import { GS_STRIPE_PLAN_PARAMS } from '../../constants';

export const trimPaymentUrlParams = (location, router) => {
  const newQuery = { ...location.query };
  GS_STRIPE_PLAN_PARAMS.forEach(param => delete newQuery[param]);
  router.replace({
    pathname: location.pathname,
    search: `?${queryString.stringify(newQuery)}`,
  });
};
