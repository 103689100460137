import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Option = ({ item, selected, onSelect, children, className }) => {
  const itemClasses = classNames({
    'block w-full text-sm px-4 py-2 hover:bg-rust-500 hover:text-white text-gray-700 group stroke-rust-500 group-hover:stroke-white': true,
    'bg-gray-25': selected,
    'text-gray-100': item?.disabled,
    'cursor-pointer ': !item?.disabled,
    [className]: className,
  });

  return (
    <>
      <li
        className={itemClasses}
        role="option"
        tabIndex="-1"
        onClick={() => onSelect(item)}
        onKeyDown={() => onSelect(item)}
        aria-selected={selected}
      >
        {children}
      </li>
      {item?.divide ? <hr className="w-full h-px border-0 bg-gray-50" /> : null}
    </>
  );
};

Option.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    disabled: PropTypes.bool,
    divide: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Option.defaultProps = {
  className: null,
};

export default Option;
