import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Icon from '../common/Icon';

import { rotate } from '../../../styles/theme';

const Toggle = props => {
  const btnClasses = `${props.btnClasses || ''}`;
  const [expanded, setExpanded] = useState(props.expanded || false);

  // Solution for No JS users
  useEffect(() => {
    setExpanded(false);
  }, []);

  const toggleContent = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <button
        type="button"
        onClick={toggleContent}
        className={btnClasses}
        aria-expanded={expanded}
      >
        <div className="grid grid-cols-10 gap-2 px-6 lg:gap-0">
          <div className="col-span-9">
            {props.buttonText}
          </div>
          <div className="flex items-center justify-center">
            <Icon
              icon="chevron-down"
              iconColor="white"
              className={`w-4 h-4 md:w-5 md:h-5 lg:ml-2 ${rotate(expanded)}`}
            />
          </div>
        </div>
      </button>
      <div
        className={`m-auto overflow-hidden transition-all duration-500 ${expanded ? 'max-h-screen' : 'max-h-0'}`}
        aria-label="Expanded Content"
      >
        {props.children}
      </div>
    </>
  );
};

Toggle.propTypes = {
  expanded: PropTypes.bool,
  buttonText: PropTypes.string,
  btnClasses: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

Toggle.defaultProps = {
  expanded: false,
  buttonText: '',
};

export default Toggle;
