import tracker from '../index';

const {
  eventNames,
  eventProps,
  resolveAfterTimeout,
} = tracker;

const trackAddRaters = ({
  groupName,
  asmtName,
  asmtType,
  raterEmails,
  buildMethod,
  memberToRate,
}) => {
  tracker.track(eventNames.ADD_RATERS, {
    [eventProps.ASSESSMENT]: `${asmtName} (${asmtType.toUpperCase()})`,
    [eventProps.GROUP_NAME]: groupName,
    [eventProps.MEMBER_TO_RATE]: memberToRate,
    [eventProps.RATER_EMAILS]: raterEmails.map(group => group.email).join(', '),
    [eventProps.BUILD_METHOD]: buildMethod,
  });

  // Track appcues 'Create Assessment Raters' event
  window?.Appcues?.track(eventNames.ADD_RATERS);
  return resolveAfterTimeout;
};

export {
  trackAddRaters,
};
