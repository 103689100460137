import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  AddressElement,
} from '@stripe/react-stripe-js';

import { GOOGLE_MAPS_API_KEY } from '../../../lib/constants';

const PaymentShippingAddressForm = ({ handleFormChange, formData }) => {
  const [address] = useState(formData.shipping_address);
  return (
    <div className="mt-6 mb-8 overflow-auto text-left min-h-[20rem] h-auto">
      <AddressElement
        options={{
          mode: 'shipping',
          autocomplete: {
            mode: 'google_maps_api',
            apiKey: GOOGLE_MAPS_API_KEY,
          },
          defaultValues: {
            name: formData.name,
            address,
          },
        }}
        onChange={event => {
          handleFormChange(event);
        }}
      />
    </div>
  );
};

PaymentShippingAddressForm.propTypes = {
  handleFormChange: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    name: PropTypes.string,
    shipping_address: PropTypes.shape({}),
  }).isRequired,
};

export default PaymentShippingAddressForm;
