import React, { forwardRef, useRef, useImperativeHandle } from 'react';

import ProfileChartTransformer from '../../../components/common/ProfileChartTransformer';
import AperianLockup from '../../../components/AperianLockup';

const ProfileShare = forwardRef(
  // eslint-disable-next-line prefer-arrow-callback
  function ProfileShare(props, ref) {
    const thumbNailRef = useRef();
    const profileChartRef = useRef();

    useImperativeHandle(ref, () => ({
      get thumbNail() {
        return thumbNailRef.current;
      },
      setThumbNail(value) {
        thumbNailRef.current = value;
      },
      get profileChart() {
        return profileChartRef.current;
      },
      setProfileChart(value) {
        profileChartRef.current = value;
      },
    }));

    return (
      <div
        className="bg-charcoal-900 fixed w-[1200px] h-[630px] print:hidden left-[9999px]"
        ref={thumbNailRef}
      >
        <div className="relative flex items-center justify-center h-full">
          <div id="profile-share" className="px-6 bg-white rounded-xl w-[400px] h-[510px]" ref={profileChartRef}>
            <ProfileChartTransformer
              isMe
              isProfile
              displayDimensionLink={false}
              {...props}
            />
          </div>
          <div className="absolute w-24 h-24 bottom-4 right-4">
            <AperianLockup color="ivory" />
          </div>
        </div>
      </div>
    );
  });

export default ProfileShare;
