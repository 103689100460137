import { BadRequestError } from '../../../lib/errors';

class TeamAddMember {
  constructor(apiService) {
    this.apiService = apiService;
  }

  execute(teamId, data) {
    return this.apiService.post(`teams/${teamId}/members`, data)
      .catch(err => {
        if (err instanceof BadRequestError) { return err.reason; }
        throw err;
      });
  }
}

export default TeamAddMember;
