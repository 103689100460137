import React from 'react';

import PropTypes from 'prop-types';

const CohortFormHeader = ({ formData }) => (
  <div className="md:mb-10 xl:mb-12">
    <div className="bg-white md:px-4 rounded-2xl">
      <h3 className="mb-4 text-center text-charcoal-900">Code: <span className="text-rust-500">{formData.code}</span></h3>
      <div className="max-w-full">
        <p className="m-auto mb-0 font-sans text-sm font-medium leading-6 text-center md:text-base md:max-w-sm">
          Create a cohort by filling out the details below.
        </p>
        <p className="m-auto mb-0 font-sans text-sm font-medium leading-6 text-center md:text-base md:max-w-sm">
          The Cohort Code above will be the cohort&apos;s reference in GlobeSmart and other systems.
        </p>
      </div>
    </div>
  </div>
);

CohortFormHeader.propTypes = {
  formData: PropTypes.shape({
    code: PropTypes.string,
  }).isRequired,
};

export default CohortFormHeader;
