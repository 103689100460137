export const tracks = [{
  id: 1,
  text: 'Setting a Foundation for DEI',
  name: 'Setting a Foundation for DEI',
  message: 'Great! Let\'s start your organization\'s DEI learning journey.',
  hidden: false,
},
{
  id: 2,
  text: 'Diving Deeper into DEI',
  name: 'DEI Strategy Concepts',
  message: 'Let\'s explore how you can dive deeeper to create a more inclusive workplace.',
  hidden: true,
},
{
  id: 3,
  text: 'Increasing Cultural Competence',
  name: 'Increasing Cultural Competence',
  message: 'Excellent! All of our learning experiences are rooted in culture.',
  hidden: false,
},
{
  id: 4,
  text: 'Supporting International Assignees',
  name: 'Supporting International Assignees',
  message: 'Let\'s ensure success for your expatriates.',
  hidden: false,
},
{
  id: 5,
  text: 'I\'m just exploring',
  name: 'I\'m just exploring',
  hidden: false,
}].filter(({ hidden }) => (!hidden));
