import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';

import { Context } from '../../../context/ContextProvider';
import withServerSideData from '../../../HOC/withServerSideData';

import InvitationForm from '../InvitationForm';
import PageHeader from '../../PageHeader';

import InvitationSendAction from '../../../actions/invitationSend';
import needsAuthentication from '../../../lib/needsAuthentication';
import NeedsCompletedSurvey from '../../../lib/NeedsCompletedSurvey';
import { trackInvitationSent } from '../../../lib/tracker/invite';
import { accessCodeList } from '../../../lib/accessCodeList';

const IndividualInvitation = ({ initialData: { accessCodes: initialAccessCodes } }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const { currentUser, apiService, router } = useContext(Context);
  const { accessCodes, defaultAccessCode } = accessCodeList({
    codes: initialAccessCodes, onlyPremium: true,
  });

  useEffect(() => {
    needsAuthentication(router, currentUser);
    NeedsCompletedSurvey.apply(router, currentUser);
  }, []);

  const handleFormSubmit = ({ invitations }) => {
    const payload = { invitations };
    new InvitationSendAction(apiService).execute(payload).then(response => {
      if (response.message) {
        const readableErrorMessage = response.message.includes('E_VALIDATION')
          ? 'An email you submitted was invalid.'
          : 'Something went wrong. Please try again later.';
        setErrorMessage({ errorMessage: readableErrorMessage });
      } else {
        const numberInvitationsSent = invitations.length;
        const hasCustomMessage = invitations.some(invitation =>
          invitation.customMessage.length > 0);
        trackInvitationSent({ userId: currentUser.userid, numberInvitationsSent, hasCustomMessage })
          .then(() =>
            router.push('/invitations/success'));
      }
    });
  };

  const renderErrorMessage = () => {
    if (!errorMessage) return null;
    return (
      <p className="label__message label__message--error js-label__message--error">
        {errorMessage}
      </p>
    );
  };

  return (
    <>
      <PageHeader
        pageTitle="Invite Others"
        bgColor="White"
        icon="profile"
        skipTarget="#invite-others"
      />
      <div id="invite-others" className="px-4 py-6 my-2 mb-6 md:p-8 text-charcoal-900 bg-ivory-400 rounded-2xl narrow-text-max-width">
        <p className="mb-8 text-base text-center">
          Invite others to connect and compare with you. Send individual
          invitations here or <Link to="/profile/teams/new">create a new team</Link>
          .
        </p>
        {renderErrorMessage()}
        <InvitationForm
          sendMessage="Send Invitation"
          accessCodes={accessCodes}
          handleFormSubmit={handleFormSubmit}
          isPersonalInvitation
          defaultAccessCode={defaultAccessCode}
        />
      </div>
    </>
  );
};
IndividualInvitation.getAPIDataKey = () => 'IndividualInvitation';
IndividualInvitation.getData = apiService =>
  apiService.get('access-codes').then(data => ({ IndividualInvitation: data }));

IndividualInvitation.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  initialData: PropTypes.shape({
    accessCodes: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        description: PropTypes.string,
        internalNotes: PropTypes.string,
        quantity: PropTypes.number,
        active: PropTypes.bool,
        seatsRemaining: PropTypes.number,
        stripePlanId: PropTypes.string,
        created: PropTypes.string,
        expirationDate: PropTypes.string,
        planName: PropTypes.string,
        redirectUrl: PropTypes.string,
      }),
    ),
  }),
};

IndividualInvitation.defaultProps = {
  initialData: {
    accessCodes: [],
  },
};

export default withServerSideData(IndividualInvitation);
