/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useReducer, useState } from 'react';
import fetch from 'isomorphic-fetch';
import { pick } from 'lodash';

import { RouterContext } from '../../context/Router';
import { APIContext } from '../../context/API';

import {
  MiniComparison,
  OnboardingForm,
  Snapshot,
} from '../../components/custom/Onboarding';

import { tracks } from './tracks';
import { trialCreator, trialInvitee } from './paths';

import { retrieveTokenMetaData } from '../../actions/onboarding';

import logger from '../../lib/logger';
import { trackMemberCompleteOnboarding, trackOnboardingLoginRedirect } from '../../lib/tracker/onboarding';
import Toast from '../../components/Toast';
import { useToast } from '../../hooks';
import Icon from '../../components/common/Icon';

const handleAutoLogin = async (endpoint, data) => {
  const response = await fetch(
    `${process.env.SITE_URL}/${endpoint}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  return response.json();
};

const Step = props => {
  const { step, path } = props;
  const Component = path[step];
  return <Component {...props} requiredStep={step} />;
};

const Onboarding = ({ location: { pathname }, params: { token } }) => {
  const { apiService } = useContext(APIContext);
  const { router } = useContext(RouterContext);

  const [path, setPath] = useState(trialCreator);

  const [step, setStep] = useState(1);
  const [currentStep, setCurrentStep] = useState(0);
  const [hasError, setHasError] = useState(false);
  const { showToast, toggleToast } = useToast(false);

  const determineCustomTrack = () => {
    if (pathname.endsWith('ls')) {
      return '6';
    }
    if (pathname.endsWith('al')) {
      return '7';
    }
    return '';
  };

  const [formData, setFormData] = useReducer((data, newData) =>
    ({ ...data, ...newData }), {
    firstName: null,
    lastName: null,
    email: null,
    splitHeader: false,
    invitationToken: token || null,
    isTokenVerified: false,
    orgName: null,
    trackMessage: null,
    track: determineCustomTrack(),
    toggleData: {
      text: '',
    },
  });

  useEffect(() => {
    if (token) {
      setPath(trialInvitee);
      retrieveTokenMetaData(apiService, token)
        .then(({ email, orgName, trialOwner, isTokenVerified, invitationState }) => {
          if (isTokenVerified && invitationState === 'accepted') {
            router.push('/login');
          } else {
            setFormData({ email, orgName, trialOwner, isTokenVerified });
          }
        });
    }
  }, []);

  useEffect(() => {
    if (step !== currentStep) {
      setCurrentStep(step);
    }
  }, [step]);

  const handleNotification = (toggleData, redirect) => {
    let timeout;
    if (toggleData?.text) {
      setFormData({ toggleData });
      toggleToast(true);
      timeout = window.setTimeout(() => {
        router.push(redirect || '/login');
      }, 8000);
    }
    return () => window.clearTimeout(timeout);
  };

  const nextStep = () => setStep(step + 1);

  useEffect(() => {
    setFormData({ ...formData, adData: router.location.query });
  }, [router.location.query]);

  const handleSubmit = async e => {
    e.preventDefault();
    setHasError(false);

    if (step !== Object.keys(path).length) {
      nextStep();
    }

    if ((path === trialInvitee && formData.invitationToken && formData.isTokenVerified)) {
      const { invitationToken } = formData;
      const loginData = pick(formData, ['email', 'firstName', 'lastName', 'password']);
      try {
        const response = await handleAutoLogin(`trials/${invitationToken}/accept`, loginData);
        await trackMemberCompleteOnboarding('Invitee');
        router.push(response.redirect);
      } catch (err) {
        logger.error(err);
        router.push(err.redirect);
      }
    } else {
      const userData = pick(formData, ['email', 'orgName', 'firstName', 'lastName', 'password', 'track', 'adData']);
      const {
        notification,
        redirect,
        type,
        isOrgExists,
        isEmailInvalidated,
      } = await handleAutoLogin('trials/user/create', userData);
      if (isOrgExists) {
        setFormData({ toggleData: { text: notification } });
        toggleToast(true);
        setHasError(true);
      } else if (isEmailInvalidated) {
        setFormData({ toggleData: { text: notification } });
        toggleToast(true);
        setHasError(true);
      } else if (notification) {
        handleNotification({ text: notification, type }, redirect);
      } else {
        router.push(redirect);
      }
    }
  };

  const handleUpdateForm = newData => setFormData(newData);

  const splitScreen = [MiniComparison, Snapshot].includes(path[step]);

  const handleRedirectToLogin = async stepName => {
    await trackOnboardingLoginRedirect(stepName);
    router.push('/login');
  };

  return (
    <OnboardingForm
      path={path}
      step={step}
      currentStep={currentStep}
      splitScreen={splitScreen}
      handleSubmit={handleSubmit}
      handleRedirectLogin={handleRedirectToLogin}
      formData={formData}
    >
      <Toast
        bgColor="ivory-400 z-[100]"
        textColor="charcoal-900"
        toastPosition="top-right"
        showToast={showToast}
        toggleToast={toggleToast}
        isDismissable
      >
        <div className="flex items-start max-w-xs gap-4 p-2 text-left md:max-w-sm">
          <div>
            <Icon icon="info" iconColor={formData.toggleData.type === 'success' ? 'green' : 'red'} className="mt-px w-7 h-7" />
          </div>
          <p className="mb-0 mr-4">{formData.toggleData?.text}</p>
        </div>
      </Toast>
      <Step
        path={path}
        step={step}
        formData={formData}
        handleUpdateForm={handleUpdateForm}
        nextStep={nextStep}
        onSubmit={handleSubmit}
        tracks={tracks}
        hasError={hasError}
      />
    </OnboardingForm>
  );
};

export default Onboarding;
