import React from 'react';
import PropTypes from 'prop-types';

import DropdownSelect from '../../common/Dropdowns/DropdownSelect';

import { translations } from '../../../lib/constants';

const SurveyQuestionLanguage = props => {
  const options = translations
    .filter(t => t.enableFor.includes('survey'))
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(({ name, value }) => ({ name, value }));

  return (
    <div className="flex justify-center w-full m-auto mb-6 font-sans text-center">
      <DropdownSelect
        id="surveyLanguages"
        name="surveyLanguages"
        labelText="Languages"
        value={props.selectedLanguage || 'en'}
        options={options}
        onChangeValue={props.onLanguageChange}
      />
    </div>
  );
};

SurveyQuestionLanguage.propTypes = {
  selectedLanguage: PropTypes.string,
  onLanguageChange: PropTypes.func.isRequired,
};

SurveyQuestionLanguage.defaultProps = {
  selectedLanguage: 'en',
};

export default SurveyQuestionLanguage;
