import React, { useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

import { APIContext } from '../../../../context/API';

import LabelInput from '../../../common/LabelInput';
import AutoSuggest from '../../../AutoSuggest';
import Button from '../../../common/Button';
import Notification from '../../../Notifications/Notification';

import logger from '../../../../lib/logger';
import GetPlansAction from '../../../../actions/getPlans';
import CreateAccessCodeAction from '../../../../actions/accessCodes/createAccessCode';
import GenerateNewAccessCodeAction from '../../../../actions/accessCodes/generateNewAccessCode';

const CreateAccessCode = props => {
  const { apiService } = useContext(APIContext);
  const [state, setState] = useReducer((data, newData) =>
    ({ ...data, ...newData }), {
    code: props.code,
    description: '',
    internalNotes: '',
    redirectUrl: '',
    expiration: '30',
    quantity: 1,
    userId: props.id,
    plans: [],
  });

  const getAccessCode = () =>
    new GenerateNewAccessCodeAction(apiService)
      .execute()
      .then(response => {
        setState(response);
      });

  const getPlans = () =>
    new GetPlansAction(apiService)
      .execute()
      .then(response =>
        setState({
          plans: response.plans
            .map(plan => ({
              name: `${plan.name} (${plan.id})`,
              value: plan.id,
            }))
            .sort((a, b) => (a.value > b.value ? 1 : -1)),
        }));

  useEffect(() => {
    getAccessCode();
    getPlans();
  }, []);

  function errorMessage(error) {
    logger.error(JSON.stringify(error.reason.errors));
    let message = 'Sorry, there was an error creating the access code.';
    if (error.reason.message) {
      message = error.reason.message;
    } else if (error.name === 'BadRequestError') {
      const accessCodeNotUnique = error.reason.errors.find(nextErr => nextErr.title === 'Invalid Subscription_access_codes_code_unique');
      if (accessCodeNotUnique) {
        message = 'Sorry, This access code already exists.';
      }
    }
    return message;
  }

  const handleChange = e => setState({ [e.target.name]: e.target.value });

  const formSubmit = e => {
    e.preventDefault();
    const { redirectUrl } = state;
    if (redirectUrl && !(redirectUrl.startsWith('/') || redirectUrl.includes('https'))) {
      return setState({
        notification: {
          type: 'failure',
          message: 'Enter valid Redirect URL',
        },
      });
    }

    return new CreateAccessCodeAction(apiService)
      .execute({
        code: state.code,
        description: state.description,
        internalNotes: state.internalNotes,
        redirectUrl: state.redirectUrl,
        expiration: state.expiration,
        quantity: state.quantity,
        stripePlan: state.stripePlan,
        userId: state.userId,
      })
      .then(() => {
        const { code } = state;
        setState({
          notification: {
            type: 'success',
            message: `${process.env.SITE_URL}/access-codes/${code}/redeem`,
          },
        });
        props.onCreate();
      })
      .catch(err => {
        setState({
          notification: {
            type: 'failure',
            message: errorMessage(err),
          },
        });
      });
  };

  return (
    <form action="/access-codes" method="POST" onSubmit={formSubmit}>
      <Notification {...state.notification} />
      <div className="mx-0.5 md:mx-0">
        <div className="flex flex-col gap-1 p-1 mb-4 bg-transparent round-corners">
          <LabelInput
            id="accessCode"
            labelType="text"
            labelText="Access Code"
            value={state.code}
            isDisabled
          />
          <LabelInput
            id="description"
            name="description"
            labelType="text"
            labelText="Description"
            value={state.description}
            onChangeValue={handleChange}
          />
          <LabelInput
            id="internalNotes"
            name="internalNotes"
            labelType="text"
            labelText="Internal Notes"
            value={state.internalNotes}
            onChangeValue={handleChange}
          />
        </div>
        <div className="grid grid-cols-1 gap-4 mb-1 md:grid-cols-3">
          <LabelInput
            id="redirectUrl"
            name="redirectUrl"
            labelType="text"
            labelText="Redirect URL"
            value={state.redirectUrl}
            onChangeValue={handleChange}
          />
          <LabelInput
            id="expiration"
            name="expiration"
            labelType="text"
            labelText="Expires in # of days"
            value={state.expiration}
            onChangeValue={handleChange}
          />
          <LabelInput
            id="quantity"
            name="quantity"
            labelType="text"
            labelText="Quantity"
            value={state.quantity}
            onChangeValue={handleChange}
          />
        </div>
        <div className="flex flex-col justify-center p-1 bg-transparent round-corners">
          {state.plans.length
            ? (
              <div className="my-4">
                <AutoSuggest
                  name="stripePlan"
                  labelText="Associated Stripe Plan"
                  placeholder="Search or View All Plans"
                  options={state.plans}
                  onSelectOption={handleChange}
                  isRequired
                />

              </div>
            ) : null}
        </div>
        <div className="flex justify-end">
          <Button filledColor="secondary" type="submit">Submit</Button>
        </div>
      </div>
    </form>
  );
};

CreateAccessCode.propTypes = {
  id: PropTypes.string.isRequired,
  code: PropTypes.string,
  onCreate: PropTypes.func.isRequired,
};

CreateAccessCode.defaultProps = {
  code: '',
};

export default CreateAccessCode;
