import React, { Component, createRef } from 'react';
import { cloneDeep, map, sortBy } from 'lodash';

import { propTypes } from './types';

import { Context } from '../../../context/ContextProvider';

import ProfileConnectingLine from '../ProfileConnectingLine';

class ProfileConnectingLinesContainer extends Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.svgContainer = createRef();
  }

  componentDidMount() {
    this.forceUpdate(); // force a re-render after the svgContainer has been created
  }

  renderCurrentUserLines() {
    const currentUserLine = this.props.positions[this.context.currentUser.userid];
    const hasVisibility = (this.props.compareProfile ? this.props.myProfileVisibility : null);

    if (!currentUserLine?.traitLine || !currentUserLine?.avatar) return null;

    return (
      <ProfileConnectingLine
        key={this.context.currentUser.userid}
        id={this.context.currentUser.userid}
        avatarPosition={currentUserLine.avatar}
        traitLinePosition={currentUserLine.traitLine}
        svgContainer={this.svgContainer.current}
        myProfileVisibility={hasVisibility}
      />
    );
  }

  renderOtherLines() {
    const positions = cloneDeep(this.props.positions);
    delete positions[this.context.currentUser.userid];

    const hasNeededProperties = Object.values(positions)
      .every(position => 'traitLine' in position && 'avatar' in position);

    if (!hasNeededProperties) return null;

    const { isHistoryProfile } = this.props;

    return map(sortBy(positions, ['avatar']), (position, id) => (
      <ProfileConnectingLine
        key={id}
        id={id}
        avatarPosition={position.avatar}
        traitLinePosition={position.traitLine}
        svgContainer={this.svgContainer.current}
        isHistoryProfile={isHistoryProfile}
      />
    ));
  }

  render() {
    return (
      <div className="avatar-container__connector-lines">
        <svg
          className="js-connector-lines-svg"
          height="100%"
          width="100%"
          preserveAspectRatio="none"
          ref={this.svgContainer}
        >
          <g>
            {this.renderCurrentUserLines()}
            {this.renderOtherLines()}
          </g>
        </svg>
      </div>
    );
  }
}

ProfileConnectingLinesContainer.propTypes = propTypes;

export default ProfileConnectingLinesContainer;
