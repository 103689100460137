import React, { useContext, useEffect, useReducer, useState } from 'react';
import ElementQuery from 'react-eq';
import PropTypes from 'prop-types';

import { Link } from 'react-router';
import { CurrentUserContext } from '../../../context/CurrentUser';
import { APIContext } from '../../../context/API';
import { RouterContext } from '../../../context/Router';
import { ToastContext } from '../../../context/Toast';
import withServerSideData from '../../../HOC/withServerSideData';

import { useConstructor } from '../../../hooks';

import PageHeader from '../../PageHeader';
import SentInvitations from './SentInvitations';
import ReceivedInvitations from './ReceivedInvitations';
import PaymentViaUrl from '../../PaymentViaUrl';
import Button from '../../common/Button';
import Notification from '../../Notifications/Notification';
import Icon from '../../common/Icon';

import InvitationAcceptAction from '../../../actions/invitationAccept';
import InvitationRejectAction from '../../../actions/invitationReject';
import InvitationResendAction from '../../../actions/invitationResend';
import InvitationCancelAction from '../../../actions/invitationCancel';

import needsAuthentication from '../../../lib/needsAuthentication';
import PremiumFeature from '../../../lib/features/Premium';
import { handleInvitationAccept, handleInvitationAcceptError } from '../../../lib/invitations';
import { GS_PREMIUM_STRIPE_PLAN } from '../../../lib/constants';
import determinePlanByType from '../../../lib/assessments/determinePlanByType';
import logger from '../../../lib/logger';
import ServerError from '../../ServerError';
import { NotificationContext } from '../../../context/Notification';
import { LanguageContext } from '../../../context/Language';

const queries = { 'card-layout': 400 };
const paymentUrl = `/invitations?purchase&plan=${GS_PREMIUM_STRIPE_PLAN}`;

const CompareLink = ({ linkURL }) => (
  <>
    <Link to={`${linkURL}`}>Click here</Link> to compare profiles.
  </>
);

const HandleInvitationAccepted = ({ invitationData }) => {
  const { sender, team, group, type } = invitationData;
  const { selectedLanguage } = useContext(LanguageContext);
  let linkURL = `/profile/comparison?language=${selectedLanguage}`;
  let userName = '';
  switch (type) {
    case 'person':
      userName = `${sender.firstName} ${sender.lastName}`;
      linkURL = `${linkURL}&person=${sender.id}`;
      break;
    case 'team':
      userName = `${team.name}`;
      linkURL = `${linkURL}&teams_average=${team.id}&teams_members=${team.id}`;
      break;
    case 'group':
      userName = `${group.name}`;
      break;
    default:
      break;
  }

  return (
    <div className="flex gap-4">
      <Icon icon="success" iconColor="green" className="w-6 h-6" />
      <div className="mr-4">
        You have successfully accepted <br />
        the connection to {userName}. <br />
        {type === 'group' ? null : <CompareLink linkURL={linkURL} />}
      </div>
    </div>
  );
};

const PendingInvitations = props => {
  const { initialData: { sent, received, error } } = props;
  const { currentUser } = useContext(CurrentUserContext);
  const { apiService } = useContext(APIContext);
  const { router } = useContext(RouterContext);
  const {
    notificationMessage,
    removeNotification,
    addNotification,
  } = useContext(NotificationContext);
  const { addToast } = useContext(ToastContext);
  const [invitationData, setInvitationData] = useState({});

  const [state, setState] = useReducer((data, newData) =>
    ({ ...data, ...newData }), {
    sent,
    received,
  });

  useConstructor(() => {
    needsAuthentication(router, currentUser);
  });

  useEffect(() => {
    let timeout;
    if (notificationMessage) {
      timeout = window.setTimeout(() => {
        removeNotification();
      }, 5000);
    }
    return () => {
      window.clearTimeout(timeout);
      if (notificationMessage) removeNotification();
    };
  }, [notificationMessage]);

  /**
   * Get latest invitation data from the API and save it to state
   * @type {Promise}  Wil resolve with updated `pendinginvitations` object
   */
  const refreshData = () =>
    PendingInvitations.getData(apiService)
      .then(data => setState(data[PendingInvitations.getAPIDataKey()]));

  /**
   * Mark the card in the list that matches `token` with `resent`
   * @param  {String} token The invitation token from the API
   * @param  {String} sentAt The updated time that the invite was resent
   * @return {Array}        The modified invitation list
   */
  const markInviteResent = (token, sentAt) => state.sent
    .map(invitation => {
      if (invitation.token === token) {
        Object.assign(invitation, { sentAt });
      }
      return invitation;
    });

  /**
   * Call the action to resend the invitation and then mark that
   * invitation in the UI as resent
   * @param  {String} token We use the token from the API as the ID
   * @return {undefined}
   */
  const onResend = token => new InvitationResendAction(apiService)
    .execute(token)
    .then(data => {
      const resentInvitations = markInviteResent(token, data.sentAt);
      setState({ sent: resentInvitations });
    })
    .catch(() => router.replace('/error'));

  /**
   * Call the action to accept the invitation and then remove it from the UI
   * @param  {String} id      We use the token from the API as the ID
   * @return {Promise}
   */
  const onAccept = token => new InvitationAcceptAction(apiService)
    .execute(token)
    .then(async data => {
      const acceptMethod = 'Interface';
      const response = await handleInvitationAccept({
        currentUser,
        invitation: data,
        acceptMethod,
      });
      setInvitationData(response);
      addToast({
        content: <HandleInvitationAccepted invitationData={invitationData} />,
        autoDismiss: 5000,
      });
    })
    .then(() => {
      const removeAccepted = state.received.filter(r => r.token !== token);
      setState({ received: removeAccepted });
    })
    .catch(err => {
      if (err?.resource === 'assessment') {
        const asmtType = determinePlanByType(err.assessmentType);
        router.push(`/dashboard?purchase&plan=${asmtType}&redirect_to=/invitations/${token}/accept`);
      } else {
        logger.error(err);
        const errorResponse = handleInvitationAcceptError(err, props);
        if (err.status === 410) {
          const removeCanceled = state.received.filter(invitation => invitation.token !== token);
          setState({ received: removeCanceled });
        }
        addNotification({ type: 'failure', message: errorResponse });
      }
    });

  /**
   * Call the action to accept the invitation and then remove it from the UI
   * @param  {String} id      We use the token from the API as the ID
   * @return {Promise}
   */
  const onReject = token => new InvitationRejectAction(apiService)
    .execute(token)
    .then(() => {
      const removeRejected = state.received.filter(r => r.token !== token);
      setState({ received: removeRejected });
    })
    .catch(() => router.replace('/error'));

  /**
   * Call the action to cancel the invitation and then remove it from the UI
   * @param  {String} id      We use the token from the API as the ID
   * @return {Promise}
   */
  const onCancel = id => new InvitationCancelAction(apiService)
    .execute(id)
    .then(refreshData)
    .catch(() => router.replace('/error'));

  function hasPremium() {
    return new PremiumFeature(currentUser)
      .positive(() => true)
      .negative(() => false)
      .execute();
  }

  function sendInvitationsLink() {
    return hasPremium() ? '/invitations/new' : paymentUrl;
  }

  if (error) return <ServerError pageTitle="Pending Invitations" error={error} />;

  // prevent initial render without data
  if (!currentUser.token || !state.sent) { return null; }

  return (
    <>
      <PageHeader
        pageTitle="Pending Invitations"
        icon="profile"
        skipTarget="#invitations"
      />
      <PaymentViaUrl {...props} />
      <div className="flex flex-col">
        <Notification {...notificationMessage} />
        <ElementQuery queries={queries}>
          <div id="invitations" className="box-border">
            <ReceivedInvitations
              received={state.received}
              onAccept={onAccept}
              onReject={onReject}
              isAuthorized={hasPremium()}
              paymentUrl={paymentUrl}
            />
            <SentInvitations
              heading="Sent Invitations"
              sent={state.sent}
              onResend={onResend}
              onCancel={onCancel}
            >
              <div className="text-right">
                <Button
                  className="inline-block float-right mb-4"
                  isSmall
                  variant="primary"
                  to="/invitations/new"
                >
                  Send More
                </Button>
              </div>
            </SentInvitations>

            <div className="pb-8">
              <p className="text-center md:mb-8">
                You have no additional invitations. <br />You can send more now or
                view a comparison with your existing connections.
              </p>
              <div className="flex flex-wrap gap-4 justify-evenly">
                <Button
                  variant="primary"
                  onClick={() => router.replace(sendInvitationsLink())}
                  className="m-0.5"
                  leadingButtonIcon={hasPremium() ? null : 'lock'}
                >
                  Invite Colleagues
                </Button>
                <Button
                  variant="primary"
                  to="/profile/connections"
                  className="m-0.5"
                >
                  Manage Connections
                </Button>
                <Button
                  variant="primary"
                  to="/profile/comparison/new"
                  className="m-0.5"
                >
                  New Comparison
                </Button>
              </div>
            </div>
          </div>
        </ElementQuery>
      </div>
    </>

  );
};

PendingInvitations.getAPIDataKey = () => 'pendinginvitations';

PendingInvitations.getData = apiService =>
  apiService.get('invitations').then(data => ({ [PendingInvitations.getAPIDataKey()]: data }));

PendingInvitations.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  initialData: PropTypes.shape({
    sent: PropTypes.arrayOf(
      PropTypes.shape({
        accessCode: PropTypes.string,
        name: PropTypes.string,
        sentAt: PropTypes.string,
        token: PropTypes.string,
        type: PropTypes.string,
      })),
    received: PropTypes.arrayOf(
      PropTypes.shape({
        accessCode: PropTypes.string,
        name: PropTypes.string,
        sentAt: PropTypes.string,
        token: PropTypes.string,
        type: PropTypes.string,
      })),
    error: PropTypes.shape({
      message: PropTypes.string,
    }),
  }).isRequired,
};

HandleInvitationAccepted.propTypes = {
  invitationData: PropTypes.shape({
    sender: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      id: PropTypes.string,
    }),
    team: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    group: PropTypes.shape({
      name: PropTypes.string,
    }),
    type: PropTypes.string,
  }).isRequired,
};

CompareLink.propTypes = {
  linkURL: PropTypes.string.isRequired,
};

export default withServerSideData(PendingInvitations);
