const setAttendedAction = (apiService, code, data) => apiService.put(`cohort-events/${code}/registrant-attendance`, data);

const saveSessionTopicAction = (apiService, data) => apiService.post('cohort-events/session-topic', data);

const updateSessionTopicAction = (apiService, id, data) => apiService.put(`cohort-events/${id}/update-session-topic`, data);

export {
  setAttendedAction,
  saveSessionTopicAction,
  updateSessionTopicAction,
};
