import PropTypes from 'prop-types';

const propTypes = {
  avatarUrl: PropTypes.string,
  displayName: PropTypes.string.isRequired,
  displayDescription: PropTypes.node,
};

const defaultProps = {
  avatarUrl: '',
  displayDescription: null,
};

export {
  propTypes,
  defaultProps,
};
