import React from 'react';

import SurveyResponse from '../SurveyResponse';

const SurveyResponsesList = props => (
  <ul className="flex flex-col items-start justify-center gap-2 pl-4 my-4 md:pl-0 lg:items-center md:flex-row">
    <SurveyResponse
      {...props}
      responseValue={1}
      responseText="Strongly Disagree"
      icon="left"
      bgColor="fill-gray-500"
    />
    <SurveyResponse
      {...props}
      responseValue={2}
      responseText="Disagree"
      icon="left"
      bgColor="fill-gray-500"
    />
    <SurveyResponse
      {...props}
      responseValue={3}
      responseText="Somewhat Disagree"
      icon="left"
      bgColor="fill-gray-500"
    />
    <SurveyResponse
      {...props}
      responseValue={4}
      responseText="Neutral"
      icon="neutral"
      bgColor="fill-gray-500"
    />
    <SurveyResponse
      {...props}
      responseValue={5}
      responseText="Somewhat Agree"
      icon="right"
      bgColor="fill-gray-500"
    />
    <SurveyResponse
      {...props}
      responseValue={6}
      responseText="Agree"
      icon="right"
      bgColor="fill-gray-500"
    />
    <SurveyResponse
      {...props}
      responseValue={7}
      responseText="Strongly Agree"
      icon="right"
      bgColor="fill-gray-500"
    />
  </ul>
);

export default SurveyResponsesList;
