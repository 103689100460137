import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../common/Button';

const PlanDetails = ({ planDateRange, atuPlan, hasAtu, handleOpenModal }) => (
  <div className="p-8 bg-white rounded-lg w-100 text-charcoal-900">
    <h3 className="mb-1 font-serif text-2xl semibold"><b>Plan Details</b></h3>
    <p className="mb-8 font-sans text-sm">{hasAtu ? 'Plans' : 'New Subscription plans'} are based on&nbsp;
      <a
        href="https://aperian.zendesk.com/hc/en-us/articles/5331084627475"
        rel="noopener noreferrer"
        target="_blank"
      >Annual Tracked Users (ATUs)
      </a>.
    </p>
    {hasAtu
      ? (
        <div className="mb-12">
          <div className="my-1 font-sans text-xl"><b>{atuPlan} ATU Plan</b></div>
          <p className="font-sans text-sm">{planDateRange}</p>
        </div>
      )
      : <div className="mt-1 mb-12 font-sans"><b>Current Plan: Enterprise</b></div>}
    <Button
      variant="primary"
      leadingButtonIcon="invite"
      onClick={handleOpenModal}
    >Invite to Aperian
    </Button>
  </div>
);

PlanDetails.propTypes = {
  planDateRange: PropTypes.string,
  atuPlan: PropTypes.number,
  hasAtu: PropTypes.bool,
  handleOpenModal: PropTypes.func,
};

PlanDetails.defaultProps = {
  planDateRange: '',
  atuPlan: 0,
  hasAtu: false,
  handleOpenModal: () => { },
};

export default PlanDetails;
