import React, { forwardRef } from 'react';
import Parser from 'html-react-parser';
import DOMPurify from 'isomorphic-dompurify';
import PropTypes from 'prop-types';

const types = {
  info: 'info',
  failure: 'error',
  warning: 'warning',
  success: 'success',
};

const getType = type => types[type] || type;

const Notification = forwardRef(
  // eslint-disable-next-line prefer-arrow-callback
  function NotificationRef(props, ref) {
    const { message, type } = props;
    if (!message) return null;

    return (
      <div
        ref={ref}
        className={`notification-message notification-message--${getType(type)}`}
      >
        <div className="notification-message__text">
          {typeof message === 'string'
            // eslint-disable-next-line new-cap
            ? Parser(DOMPurify.sanitize(message, { ADD_ATTR: ['target'] }))
            : message}
        </div>
      </div>
    );
  });

Notification.propTypes = {
  type: PropTypes.string,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
};
Notification.defaultProps = {
  type: null,
  message: null,
};

export default Notification;
