import React from 'react';
import { iconPropTypes, defaultIconProps } from './types';

import { strokeColorMap } from '../index.js';

const ArrowRight = ({ className, iconColor }) => (
  <svg className={`${className} ${strokeColorMap[iconColor]}`} viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 7H17M17 7L11 1M17 7L11 13" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

ArrowRight.propTypes = iconPropTypes;
ArrowRight.defaultProps = defaultIconProps;

export default ArrowRight;
