class EditAccessCodeAction {
  constructor(apiService) {
    this.apiService = apiService;
  }

  execute(currentCode, data) {
    return this.apiService.post(`access-codes/${currentCode}`, data);
  }
}

export default EditAccessCodeAction;
