import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../../../common/Button';

import formatDate from '../../../../../../lib/dateFormatter';

const MemberDescription = ({
  id,
  email,
  dateJoined,
  surveyStatus,
  version,
  userRole,
  isIbi,
  inviteToUpgrade,
  remindedAt,
}) => (
  <>
    <b>Email: </b> {email} <br />
    <b>Date Joined: </b> {formatDate(dateJoined)}<br />
    <b>Survey Status: </b> {surveyStatus}
    {(isIbi && surveyStatus === 'Finished')
                && <><br /><b>Version: </b> {parseFloat(version).toFixed(1)} </>}
    {userRole === 'leader' && <><br /><b>Leadership Indicator</b></>}
    {(isIbi && version === 2 && surveyStatus === 'Finished')
              && (
              <Button
                onClick={() => inviteToUpgrade(id, email)}
                isAnchorTag
              >
                <b>Invite to upgrade</b>
              </Button>
              )}
    {(remindedAt && surveyStatus !== 'Finished') ? <><br /><b>Reminded at</b>:&nbsp;{formatDate(remindedAt, true)} </> : ''}
  </>
);

MemberDescription.propTypes = {
  id: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  dateJoined: PropTypes.string.isRequired,
  surveyStatus: PropTypes.string.isRequired,
  remindedAt: PropTypes.string,
  version: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  userRole: PropTypes.string,
  isIbi: PropTypes.bool.isRequired,
  inviteToUpgrade: PropTypes.func.isRequired,
};

MemberDescription.defaultProps = {
  userRole: 'user',
  remindedAt: '',
};

export default MemberDescription;
