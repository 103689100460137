import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../common/Icon';

const SidebarToggle = ({ handleSidebarOpen }) => (
  <button
    type="button"
    className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
    onClick={() => handleSidebarOpen(true)}
  >
    <span className="sr-only">Open sidebar</span>
    <Icon icon="bars3" iconColor="gray" className="w-6 h-6" aria-hidden="true" />
  </button>
);

SidebarToggle.propTypes = {
  handleSidebarOpen: PropTypes.func,
};

SidebarToggle.defaultProps = {
  handleSidebarOpen: null,
};

export default SidebarToggle;
