import React from 'react';
import { propTypes, defaultProps } from './types';

import Icon from '../common/Icon';

const ButtonIconCheck = props => {
  if (props.leadingButtonIcon && props.trailingButtonIcon) {
    return (
      <span className="flex items-center justify-center">
        <div className="flex items-center w-5 bg-center">
          <Icon className="w-5 h-5" icon={props.leadingButtonIcon} iconColor={props.iconColor} />
        </div>
        <span className="flex-shrink mx-4">
          {props.children}
        </span>
        <div className="flex items-center w-5">
          <Icon className="w-5 h-5" icon={props.trailingButtonIcon} iconColor={props.iconColor} />
        </div>
      </span>
    );
  }
  if (props.leadingButtonIcon && props.isCircle) {
    return (
      <div className="flex items-center justify-center hover:rounded-full">
        <Icon className="w-6 h-6" icon={props.leadingButtonIcon} iconColor={props.iconColor} />
      </div>
    );
  }
  if (props.leadingButtonIcon) {
    return (
      <span className="flex items-center justify-center">
        <div className="flex items-center w-5">
          <Icon className="w-5 h-5" icon={props.leadingButtonIcon} iconColor={props.iconColor} />
        </div>
        {props.children
          ? (
            <span className="flex-shrink ml-4">
              {props.children}
            </span>
          ) : null}
      </span>
    );
  }
  if (props.trailingButtonIcon) {
    return (
      <span className="flex items-center justify-center">
        {props.children
          ? (
            <span className="flex-shrink mr-3">
              {props.children}
            </span>
          ) : null}
        <div className="flex items-center w-5">
          <Icon className="w-5 h-5" icon={props.trailingButtonIcon} iconColor={props.iconColor} />
        </div>
      </span>
    );
  }
  return (
    <span className="no-pointer-events">
      {props.children}
    </span>
  );
};

ButtonIconCheck.propTypes = propTypes;
ButtonIconCheck.defaultProps = defaultProps;

export default ButtonIconCheck;
