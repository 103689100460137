import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { Context } from '../../context/ContextProvider';

import { STRIPE_PUBLIC_API_KEY } from '../../lib/constants';
import needsAuthentication from '../../lib/needsAuthentication';
import PaymentForm from './form';

const stripePromise = loadStripe(STRIPE_PUBLIC_API_KEY);
const Payment = ({
  coupon,
  planInfo,
  planId,
  forOthers,
}) => {
  const { apiService, currentUser, router } = useContext(Context);
  needsAuthentication(router, currentUser);
  const [stripe, setStripe] = useState(null);
  const [paymentPlanInfo, setPaymentPlanInfo] = useState(null);

  const getPlanInfo = () => apiService.get(`plan/${planId}`)
    .then(info => {
      setPaymentPlanInfo(info);
    });

  useEffect(() => {
    if (planInfo && Object.entries(planInfo).length > 0) {
      setPaymentPlanInfo(planInfo);
    } else if (planId) {
      getPlanInfo(planId);
    }
    setStripe(stripePromise);
  }, [planId]);

  const options = {
    appearance: {
      variables: {
        colorBackground: '#ffffff',
        colorText: '#1E1E28',
        colorDanger: '#F04438',
        spacingUnit: '4px',
        fontSizeBase: '1rem',
        borderRadius: '0.375rem',
        fontFamily: 'Roboto, Arial, sans-serif',
        gridRowSpacing: '1.5rem',
        fontWeightNormal: '500',
        border: '1px solid #d1d5db',
        boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
      },
      rules: {
        '.Input:focus': {
          borderColor: '#FF532A',
          boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #FFE4E1',
        },
      },
    },
  };

  return (
    paymentPlanInfo && (
      <Elements stripe={stripe} options={options}>
        <PaymentForm
          isForOthers={forOthers}
          planInfo={paymentPlanInfo}
          coupon={coupon}
        />
      </Elements>
    )
  );
};

Payment.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  planInfo: PropTypes.object,
  planId: PropTypes.string,
  coupon: PropTypes.string,
  forOthers: PropTypes.bool,
};

Payment.defaultProps = {
  planInfo: {},
  planId: '',
  coupon: null,
  forOthers: false,
};

export default Payment;
