/* eslint-disable max-len */
import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import queryString from 'query-string';
import PropTypes from 'prop-types';

import { Context } from '../../context/ContextProvider';

import { hasDataFor, noDataFor } from '../../lib/server-data';
import { TokenError, AuthError } from '../../lib/errors';
import logger from '../../lib/logger';
import getRedirectUrl from '../../lib/urls/loginUrl';

import Spinner from '../../components/Spinner';
import ServerError from '../../components/ServerError';

const withServerSideData = WrappedComponent => {
  class ServerSideData extends Component {
    static contextType = Context;

    static needsCompletedSurvey(router, currentUser) {
      if (WrappedComponent.needsCompletedSurvey) {
        return WrappedComponent.needsCompletedSurvey(router, currentUser);
      }
      return null;
    }

    static getAPIDataKey(props = {}) {
      if (WrappedComponent.getAPIDataKey) {
        return WrappedComponent.getAPIDataKey(props);
      }
      throw new Error(`'getAPIDataKey' not implemented for ${this.name}`);
    }

    static getData(apiService, params) {
      if (WrappedComponent.getData) {
        return WrappedComponent.getData(apiService, params);
      }
      throw new Error(`'getData' not implemented for ${this.name}`);
    }

    static getQuery(props) {
      if (typeof location !== 'undefined') return location.search;
      if (typeof props.location !== 'undefined') return props.location.search;
      return '';
    }

    constructor(props, context) {
      super(props);
      this.needsCompletedSurvey = this.constructor.needsCompletedSurvey(context.router, context.currentUser);

      this.key = this.constructor.getAPIDataKey(props);
      this.hasRequiredData = true;
      this.isHydrated = typeof window === 'object';
      if (this.isHydrated && noDataFor(this.key)) {
        this.hasRequiredData = false;
      }
      const apiData = hasDataFor(this.key)
        ? { ...window.apgServerData[this.key] }
        : { ...this.props[this.key] };
      const search = this.constructor.getQuery(props);
      this.state = Object.assign(apiData || {}, queryString.parse(search));
    }

    componentDidMount() {
      if (this.context.currentUser && !this.context.currentUser.isLoggedIn()) {
        const loginUrl = getRedirectUrl(this.props.location);
        this.context.router.push(loginUrl);
        return new Promise(resolve => resolve());
      }

      if (this.needsCompletedSurvey) {
        return new Promise(resolve => resolve());
      }

      if (noDataFor(this.key)) {
        const search = typeof location !== 'undefined'
          ? queryString.parse(location.search)
          : null;
        const params = Object.assign(this.props.params || {}, search);

        return this.constructor.getData(this.context.apiService, params)
          .then(data => {
            if (data) {
              this.hasRequiredData = true;
              this.setState({ ...data[this.key] });
            }
          })
          .catch(err => this.handleServerErrors(err));
      }
      this.removeServerRenderedDataAfterUse();
      return new Promise(resolve => resolve());
    }

    handleServerErrors(err) {
      if (err instanceof TokenError) {
        browserHistory.push('/expired');
      }
      if (err instanceof AuthError) {
        browserHistory.push(`/logout?redirect_to=${browserHistory.getCurrentLocation().pathname}`);
      }

      logger.error(err);
      this.setState({ error: err });
    }

    /**
     * We want to remove the server data so that we don't cache it forever
     * @return {undefined}
     */
    removeServerRenderedDataAfterUse() {
      delete window.apgServerData[this.key];
    }

    render() {
      if (this.state.error) {
        return <ServerError />;
      }
      if (this.hasRequiredData) {
        return <WrappedComponent {...this.props} initialData={this.state} />;
      }
      return (
        <div className="grid w-full h-[calc(100vh-8rem)] p-6 rounded-lg place-items-center">
          <Spinner />
        </div>
      );
    }
  }
  ServerSideData.propTypes = {
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
    params: PropTypes.shape({}),
  };

  ServerSideData.defaultProps = {
    location: {},
    params: {},
  };

  return ServerSideData;
};

export default withServerSideData;
