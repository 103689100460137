import tracker from '../index';

const {
  resolveAfterTimeout,
} = tracker;

const trackLogout = () => {
  tracker.reset();

  return resolveAfterTimeout;
};

export { trackLogout };
