export const clientSuccessTrack = ({
  user: {
    organizations,
    userid,
    firstName,
    lastName,
    email,
  }, eventId,
}) => {
  organizations.map(organization => {
    window?.csTrack?.identify({
      organization,
      user: {
        id: userid,
        name: `${firstName} ${lastName}`,
        email,
      },
    });
    window?.csTrack?.trackEvent(eventId, 1);
    return null;
  });
};
