import React, { createContext } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

export const RouterContext = createContext();

const RouterProvider = ({ children, router }) => (
  <RouterContext.Provider
    value={{
      router: {
        ...router,
      },
    }}
  >
    {children}
  </RouterContext.Provider>
);

export default withRouter(RouterProvider);

RouterProvider.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  router: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export const RouterConsumer = RouterContext.Consumer;
