import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import AperianMonogram from '../../AperianMonogram';

export const footerLinks = [
  {
    label: 'Help Center',
    href: 'https://aperian.zendesk.com',
  },
  {
    label: 'Terms of Use',
    href: '/content/terms-of-use',
  },
  {
    label: 'Privacy Policy',
    href: '/content/privacy-policy',
  },
  {
    label: 'Provide Feedback',
    href: 'https://globesmart.typeform.com/to/apUX3v',
  },
];

const Footer = ({ hideOnMobile }) => {
  const footerClass = classNames({
    'font-sans py-2 px-2 lg:p-2 md:flex flex-wrap border-solid border-charcoal-700 justify-start md:justify-evenly items-center text-sm text-center antialiased text-white bg-charcoal-900 md:m-0 md:text-left': true,
    hidden: hideOnMobile,
    flex: !hideOnMobile,
  });

  return (
    <footer className={footerClass}>
      <div className="flex items-center w-full md:w-auto">
        <a
          className="float-left -mr-8 md:mr-4 lg:mr-0 lg:px-4 lg:py-2 focus:border focus:border-white"
          href="https://aperian.com"
          target="_blank"
          rel="noreferrer noopener"
        >
          <AperianMonogram />
        </a>
        <a
          className="mx-auto text-center no-underline hover:underline hover:text-white focus:border focus:border-white"
          href="https://aperian.com"
          target="_blank"
          rel="noreferrer noopener"
        >
          &#169; {new Date().getFullYear()} Aperian Global, Inc.
        </a>
      </div>
      <nav className="flex-grow">
        <ul className="flex flex-col items-center gap-2 list-none lg:w-4/5 md:flex-row md:justify-center md:gap-12 lg:gap-16 ">
          {footerLinks.map(link => (
            <li key={link.label}>
              <a
                className="no-underline text-rust-500 hover:underline hover:text-white focus:border focus:border-rust-500"
                href={link.href}
                target="_blank"
                rel="noreferrer noopener"
              >
                {link.label}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </footer>
  );
};

Footer.propTypes = {
  hideOnMobile: PropTypes.bool,
};

Footer.defaultProps = {
  hideOnMobile: false,
};

export default Footer;
