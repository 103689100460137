export const extractEmails = emails => {
  const emailsArray = emails.split(/[\s,]+/).filter(entry => entry.trim() !== '');
  const dedupedEmails = [...new Set(emailsArray)];
  const validEmails = dedupedEmails.filter(email => email.match(/[^\s@]+@[^\s@]+\.[^\s@]+/gi));
  const cleanedEmails = validEmails.map(email => email.replace(/[^a-zA-Z]*$/, ''));
  const invalidEmails = emailsArray.filter(email => !validEmails.includes(email));
  return invalidEmails.length > 0
    ? new Error(`There is an error in one or more of your emails: ${invalidEmails.join(', ')}`)
    : cleanedEmails;
};
