import React from 'react';
import PropTypes from 'prop-types';

import { footerLinks } from '../../common/Footer';

const SidebarFooter = ({ element }) => (
  <nav ref={element} className="p-4 text-center full-width bg-charcoal-900 print:hidden">
    <ul className="flex flex-col items-center gap-2 list-none md:flex-row md:justify-center md:gap-12 lg:gap-16 ">
      {footerLinks.map(link => (
        <li key={link.label}>
          <a
            className="no-underline text-rust-500 hover:underline hover:text-white focus:border focus:border-rust-500"
            href={link.href}
            target="_blank"
            rel="noreferrer noopener"
          >
            {link.label}
          </a>
        </li>
      ))}
    </ul>
  </nav>
);

SidebarFooter.propTypes = {
  element: PropTypes.shape({
    current: PropTypes.shape({}),
  }).isRequired,
};

export default SidebarFooter;
