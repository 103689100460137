import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../common/Icon';
import Button from '../../common/Button';

const DashboardIBI = ({ hasCompletedIBISurvey, firstName }) => (
  <div className="px-6 py-4 pb-8 bg-white rounded-lg md:pl-10 md:pb-10">
    <div className="flex items-center gap-4">
      <div className="flex items-center justify-center p-2 rounded-full w-9 h-9 bg-ivory-400">
        <Icon icon="ibi-symbol" iconColor="gray" />
      </div>
      <h2 className="my-2 font-serif text-2xl antialiased font-thin tracking-wide sm:my-4 text-charcoal-500">
        Inclusive Behaviors Inventory (IBI)
      </h2>
    </div>
    <div className="grid items-center grid-cols-1 py-2 md:grid-cols-2 md:py-0">
      {hasCompletedIBISurvey
        ? (
          <div>
            <h3 className="mb-8 font-serif text-2xl font-semibold leading-snug tracking-wider text-charcoal-900">
              Congratulations, {firstName}.
              <br />
              You have completed the Inclusive Behaviors Inventory.
            </h3>
            <p className="mb-6 font-sans text-base font-light md:text-sm">
              The IBI is designed to measure your inclusive behaviors and drive inclusion through
              targeted, tangible, and iterative behavioral change. Review your personalized results.
            </p>
            <div className="flex items-center justify-center gap-3 mb-3 md:justify-start">
              <Button
                to="/assessments/ibi"
                isSmall
                variant="secondary"
                trailingButtonIcon="home"
              >
                IBI Home
              </Button>
              <Button
                to="/assessments/ibi/results"
                isSmall
                variant="primary"
                trailingButtonIcon="file"
              >
                My Report
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <h3 className="mb-8 font-serif text-2xl font-semibold leading-snug tracking-wider text-charcoal-900">
              Take a step towards global inclusion.
            </h3>
            <p className="py-4 mb-6 font-sans text-base font-light md:text-sm">
              The IBI is designed to measure your inclusive behaviors and drive inclusion
              through targeted, tangible, and iterative behavioral change. Take the survey.
            </p>
            <Button
              to="/assessments/ibi/survey"
              className="max-w-[15rem] mb-3"
              variant="primary"
              trailingButtonIcon="arrow-right"
            >
              Take the IBI Survey
            </Button>
          </div>
        )}
      <div className="p-4">
        <Icon icon="ibi-product" className="max-w-[24rem] m-auto" />
      </div>
    </div>
  </div>
);

DashboardIBI.propTypes = {
  hasCompletedIBISurvey: PropTypes.bool.isRequired,
  firstName: PropTypes.string,
};

DashboardIBI.defaultProps = {
  firstName: 'User',
};

export default DashboardIBI;
