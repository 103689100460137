import React from 'react';
import { Link } from 'react-router';
import ElementQuery from 'react-eq';
import PropTypes from 'prop-types';

const BlocksData = ({ blocks }) => blocks.map(({
  id,
  name,
  title,
  description,
}) => (
  <tr key={id} className="admin-list__item">
    <td className="admin-list__cell admin-list__cell--name" data-label="Name">
      <Link to={`/administration/blocks/${id}/edit`}>{name}</Link>
    </td>
    <td className="admin-list__cell admin-list__cell--name" data-label="Title">
      {title}
    </td>
    <td className="admin-list__cell admin-list__cell--name" data-label="Description">
      {description}
    </td>
  </tr>
));

const BlocksList = ({ blocks }) => {
  if (!blocks || !blocks.length) return null;

  return (
    <ElementQuery queries={{ 'table-view': 700 }}>
      <table className="admin-list bg-white">
        <thead className="admin-list__header">
          <tr>
            <th className="admin-list__cell admin-list__cell--first-name">
              Block Name
            </th>
            <th className="admin-list__cell admin-list__cell--last-name">
              Title
            </th>
            <th className="admin-list__cell">
              Description
            </th>
          </tr>
        </thead>
        <tbody className="admin-list__container">
          <BlocksData blocks={blocks} />
        </tbody>
      </table>
    </ElementQuery>
  );
};

BlocksList.propTypes = {
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
    })).isRequired,
};

export default BlocksList;
