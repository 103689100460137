import React from 'react';
import PropTypes from 'prop-types';

import AccessCodeUseCard from '../../../../AccessCodes/AccessCodeUseCard';

const AccessCodeUses = ({ accessCodeUses }) => accessCodeUses.map(({
  user: {
    id,
    firstName,
    lastName,
    email,
  },
  redeemed,
  stripePlanId,
  surveyResponse,
  assessmentResponse,
}) => (
  <li className="profile-group__item" key={id}>
    <AccessCodeUseCard
      firstName={firstName}
      lastName={lastName}
      email={email}
      redeemed={redeemed}
      stripePlanId={stripePlanId}
      surveyResponse={surveyResponse}
      assessmentResponse={assessmentResponse}
    />
  </li>
));

AccessCodeUses.propTypes = {
  accessCodeUses: PropTypes.arrayOf(
    PropTypes.shape({
      accessCode: PropTypes.string,
      redeemed: PropTypes.string,
      surveyResponse: PropTypes.shape({
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string,
        id: PropTypes.number,
        state: PropTypes.string,
        user: PropTypes.string,
      }),
      stripePlanId: PropTypes.string.isRequired,
      assessmentResponse: PropTypes.shape({
        state: PropTypes.string.isRequired,
      }),
      user: PropTypes.shape({
        id: PropTypes.string,
        email: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
    }),
  ),
};

AccessCodeUses.defaultProps = {
  accessCodeUses: [],
};

export default AccessCodeUses;
