/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router';

const SingleTeamLink = ({ teams }) => (
  <>
    <Link to={`/profile/teams/${teams[0].id}`}>{teams[0].name}</Link>. Please click the team link if you wish to remove yourself.
  </>
);

const MultiTeamLink = ({ teams }) => {
  /**
   * The Intl.ListFormat allows us to format the teams name list.
   * In this example, it puts an `and` before the last item in the list
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/ListFormat
   */
  const formatter = new Intl.ListFormat('en', { style: 'long', type: 'conjunction' });
  const teamNames = teams.map(({ name }) => name);
  return (
    <>
      {formatter.format(teamNames)}. To leave these teams, go to <Link to="/profile/teams">My Teams</Link>
    </>
  );
};

const ConnectionWithTeamsMessage = ({ connectionName, teams }) => (
  <>
    You have successfully removed the individual connection to { connectionName }.
    You are still connected via&nbsp;
    {teams.length === 1 ? (<SingleTeamLink teams={teams} />) : (<MultiTeamLink teams={teams} />)}
  </>
);

const ConnectionRemovalSuccess = ({ connectionName }) => `You have successfully removed the individual connection to ${connectionName}.`;

const ConnectionRemovalError = ({ err }) => `Could not remove this connection: ${err}`;

export {
  ConnectionWithTeamsMessage,
  ConnectionRemovalSuccess,
  ConnectionRemovalError,

};
