import React from 'react';
import PropTypes from 'prop-types';
import AdviceContent from '../AdviceContent';

const BestPracticeAdvice = ({ bestPractices }) => {
  const title = 'Pay attention to subtle differences.';
  const explanation = '<p>You both fall close to the middle on one or more dimensions. While this may mean that your preferences here are similar, it will be wise to consider the following:</p>';

  return <AdviceContent title={title} explanation={explanation} subHeadings={bestPractices} />;
};

BestPracticeAdvice.propTypes = {
  bestPractices: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default BestPracticeAdvice;
