/* eslint-disable react/prop-types */
import React from 'react';

const IbiAvatar = ({ className }) => (
  <svg className={className} viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="2" y="2" width="64" height="64" rx="32" fill="#FFE3E0" />
    <path d="M32.2544 32.2857H19V19.0315C26.0964 19.5217 31.7664 25.1921 32.2544 32.2857Z" stroke="#E64833" strokeWidth="2" />
    <path d="M32.2857 42.3571C32.2857 46.0259 29.3116 49 25.6429 49C21.9741 49 19 46.0259 19 42.3571C19 38.6884 21.9741 35.7143 25.6429 35.7143C29.3116 35.7143 32.2857 38.6884 32.2857 42.3571Z" stroke="#E64833" strokeWidth="2" />
    <path d="M49 25.6429C49 29.3116 46.0259 32.2857 42.3571 32.2857C38.6884 32.2857 35.7143 29.3116 35.7143 25.6429C35.7143 21.9741 38.6884 19 42.3571 19C46.0259 19 49 21.9741 49 25.6429Z" stroke="#E64833" strokeWidth="2" />
    <path d="M35.7456 35.7143L49 35.7143L49 48.9685C41.9036 48.4783 36.2336 42.8079 35.7456 35.7143Z" stroke="#E64833" strokeWidth="2" />
    <rect x="2" y="2" width="64" height="64" rx="32" stroke="#FFC9C2" strokeWidth="4" />
  </svg>
);

export default IbiAvatar;
