import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import { APIContext } from '../../../context/API';
import { RouterContext } from '../../../context/Router';
import withServerSideData from '../../../HOC/withServerSideData';

import PageHeader from '../../PageHeader';
import Notification from '../../Notifications/Notification';
import InvitationForm from '../../Invitations/InvitationForm';

import groupAddRaterAction from '../../../actions/assessments/groups/groupAddRater';
import { trackAddRaters } from '../../../lib/tracker/rater';

const AddGroupRaters = ({ params: { assessment, id, memberId }, initialData }) => {
  const { apiService } = useContext(APIContext);
  const { router } = useContext(RouterContext);
  const {
    asmtData: { displayName },
    asmtGroupData: {
      id: groupId,
      name: groupName,
    },
    memberData: {
      name: memberName,
      memberUserId,
      raterCustomMessage,
    },
  } = initialData;
  const [errorMessage, setErrorMessage] = useState(null);
  const handleFormSubmit = ({ invitations, customMessage }) => {
    groupAddRaterAction(
      apiService,
      groupId,
      memberUserId,
      { invitations, raterCustomMessage: customMessage },
    )
      .then(response => {
        if (response.message) {
          const readableErrorMessage = response.message.includes('E_VALIDATION')
            ? 'An email you submitted was invalid.'
            : 'Something went wrong. Please try again later.';
          window.scroll(0, 0);
          setErrorMessage(readableErrorMessage);
        } else {
          trackAddRaters({
            groupName,
            asmtName: displayName,
            asmtType: assessment,
            raterEmails: invitations,
            buildMethod: 'One-by-One',
            memberToRate: memberName,
          });
          router.push({ pathname: `/assessments/${assessment}/group/${id}`, state: { memberId } });
        }
      })
      .catch(() => setErrorMessage('Something went wrong. Please try again later.'));
  };

  return (
    <>

      <PageHeader
        pageTitle={`${displayName} - Add Raters`}
        icon="ibi-symbol"
        backLink={{ to: `/assessments/${assessment}/group/${groupId}`, text: `Back to ${groupName}` }}
        skipTarget="#add-raters"
      />
      <div id="add-raters" className="mb-8 text-charcoal-900 bg-ivory-400 rounded-2xl">
        <div>
          <h3>{groupName}</h3>
          <p className="max-w-3xl m-0 mb-6">Use this form to invite Raters for <span className="font-normal">{memberName}</span>. We recommend notifying raters about the 360 process prior to sending invitations. Read more and see an email template in our <a href="https://aperian.zendesk.com/hc/en-us/articles/4415922920083" target="_blank" rel="noopener noreferrer">Help Center</a>.</p>
        </div>
        {errorMessage && <Notification type="failure" message={errorMessage} />}
        <InvitationForm
          sendMessage="Send Invitation"
          invitationType="Rater invitation"
          handleFormSubmit={handleFormSubmit}
          customMessage={raterCustomMessage}
          isCustomMessageVisible
        />
      </div>
    </>
  );
};

AddGroupRaters.getAPIDataKey = () => 'asmtRaterInviteData';

AddGroupRaters.getData = (apiService, { assessment, id, memberId }) =>
  apiService.get(`invitations/assessments/${assessment}?group=${id}&memberId=${memberId}`)
    .then(data => ({ asmtRaterInviteData: data }));

AddGroupRaters.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
    assessment: PropTypes.string.isRequired,
    memberId: PropTypes.string.isRequired,
  }).isRequired,
  initialData: PropTypes.shape({
    asmtData: PropTypes.shape({
      displayName: PropTypes.string,
    }),
    asmtGroupData: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      assessment: PropTypes.number,
      deleted: PropTypes.bool,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
    }),
    memberData: PropTypes.shape({
      name: PropTypes.string,
      memberUserId: PropTypes.string,
      raterCustomMessage: PropTypes.string,
    }),

  }).isRequired,
};

export default withServerSideData(AddGroupRaters);
