import React from 'react';
import { Link } from 'react-router';

import { trackInvitationAccepted } from '../tracker/invite';
import { trackAccessCodeRedeem, trackAccessCodeRedeemFailure } from '../tracker/access-codes';
import { trackJoinAssessmentGroup } from '../tracker/assessments/group';

const handleInvitationAccept = ({ currentUser, invitation, acceptMethod }) => {
  const { userid: userId } = currentUser;
  if (invitation.group) {
    const { group: { name, assessment: { displayName, type } } } = invitation;
    const payload = {
      userId,
      groupName: name,
      asmtName: displayName,
      asmtType: type,
      acceptMethod,
    };
    return trackJoinAssessmentGroup(payload)
      .then(() => ({ ...invitation, type: 'group' }));
  }
  let invitationType = 'person';
  let teamName;
  if (invitation.team) {
    invitationType = 'team';
    teamName = invitation.team.name;
  } else if (invitation.isRater) {
    invitationType = 'rater';
  } else if (invitation.org) {
    invitationType = 'org';
  }

  return trackInvitationAccepted({
    userId,
    acceptMethod: acceptMethod || 'Email',
    invitationType,
    teamName,
  }).then(() => {
    if (invitation.accessCode) {
      const { meta: { accessCodeResults: { accepted: accessCodeRedeemed } } } = invitation;

      if (accessCodeRedeemed) {
        const {
          accessCode,
          recipient,
          meta: { accessCodeResults: { planInfo: { name } } },
        } = invitation;
        const { accessLevel } = currentUser;

        return trackAccessCodeRedeem({
          userid: recipient,
          accessLevel,
          accessCode,
          planName: name,
        }).then(() => invitation);
      }
      const { meta: { accessCodeResults: { reason } } } = invitation;
      return trackAccessCodeRedeemFailure(reason);
    }
    return { ...invitation, type: invitationType };
  });
};

const handleInvitationAcceptError = (err, { onError }) => {
  if (err.status === 403) {
    onError((
      <div>
        <h1 className="visually-hidden">You must have Premium access to join a team.</h1>
        <p className="my-1 font-heading-2 text-orange">Trying to connect with colleagues?</p>
        <p>
          <Link
            to="https://aperian.zendesk.com/hc/en-us/articles/18925799320851"
            target="_blank"
            rel="noopener noreferrer"
          >Unlock teams features by upgrading your account.
          </Link>
        </p>
      </div>
    ));
    return null;
  }

  let message = 'There was an issue accepting the invitation. Please try again later.';

  if (err.status === 410) {
    return 'This invitation was deleted by its creator. Please contact the person who sent you the invitation with any questions.';
  }

  if (err.status === 409) {
    const { error } = err;
    const contactMessage = 'Please contact the person who invited you or <a href="mailto:support@aperian.com">support@aperian.com</a> with any questions.';

    switch (error) {
      case 'no_seats':
        message = `This access code has no seats remaining. ${contactMessage}`;
        break;
      case 'expired':
        message = `This access code has expired. ${contactMessage}`;
        break;
      case 'not_active':
        message = `The access code associated with this invitation is not longer active. ${contactMessage}`;
        break;
      default:
    }
  }
  if (err.message === '404 - undefined') {
    message = "We're sorry, but this invitation is invalid.";
  } else if (err.reason && err.reason.errMessage) {
    message = err.reason.errMessage;
  }

  return message;
};

export {
  handleInvitationAccept,
  handleInvitationAcceptError,
};
