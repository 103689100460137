import tracker from '..';

const {
  eventNames,
  eventProps,
  peopleProps,
  resolveAfterTimeout,
} = tracker;

const trackProductSorted = ({ sortedProducts, orgName, sortedBy }) => {
  const activeBlocks = sortedProducts.filter(({ isActive }) => isActive)
    .map(({ internalName }) => internalName);
  const inactiveBlocks = sortedProducts.filter(({ isActive }) => !isActive)
    .map(({ internalName }) => internalName);

  tracker.track(eventNames.REORDER_DASHBOARD_BLOCKS, {
    [eventProps.ACTIVE_BLOCKS]: activeBlocks.length ? activeBlocks.join(', ') : 'none',
    [eventProps.INACTIVE_BLOCKS]: inactiveBlocks.length ? inactiveBlocks.join(', ') : 'none',
    [eventProps.ORGANIZATION_NAME]: orgName,
    [eventProps.SORTED_BY]: sortedBy,
  });
  return resolveAfterTimeout;
};

const trackBlockButtonClick = ({ userId, internalName, title, launchedFrom }) => {
  tracker.identify(userId);

  tracker.people.union(peopleProps.BLOCK_BUTTONS_CLICKED, internalName);

  tracker.track(eventNames.BLOCK_BUTTON_CLICKED, {
    [eventProps.BLOCK_INTERNAL_NAME]: internalName,
    [eventProps.BLOCK_TITLE]: title,
    [eventProps.BLOCK_LAUNCHED_FROM_PAGE]: launchedFrom,
  });

  return resolveAfterTimeout;
};

export {
  trackProductSorted,
  trackBlockButtonClick,
};
