import React, { useContext, useReducer, useState } from 'react';
import Parser from 'html-react-parser';
import DOMPurify from 'isomorphic-dompurify';

import { Context } from '../../../../context/ContextProvider';

import LabelInput from '../../../common/LabelInput';
import LabelTextarea from '../../../common/LabelTextarea';
import Button from '../../../common/Button';

import CreateOrgAction from '../../../../actions/organizations/createOrganization';
import { BadRequestError } from '../../../../lib/errors';

const OrganizationAdd = () => {
  const { apiService, router } = useContext(Context);

  const [organization, setOrganization] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      name: '',
      displayName: '',
      note: '',
    },
  );
  const [notification, setNotification] = useState({});

  const handleOnChange = ({ target: { value, id } }) => {
    setOrganization({ [id]: value });
  };

  const save = e => {
    e.preventDefault();
    new CreateOrgAction(apiService)
      .execute(organization)
      .then(data => router.push(`/administration/organizations/${data.organization.id}`))
      .catch(err => {
        let { message } = err;
        if (err instanceof BadRequestError) {
          const detail = err.reason.error.details[0];
          const key = Object.keys(detail)[0];
          message = detail[key];
        }
        setNotification({
          type: 'warning',
          message: `There was an error creating the organization:<br>${message}`,
        });
      });
  };

  function renderNotification() {
    return notification.message && (
      <div className={`notification-message notification-message--${notification.type}`}>
        <div className="notification-message__text">
          {/* eslint-disable-next-line new-cap */}
          {Parser(DOMPurify.sanitize(notification.message, { ADD_ATTR: ['target'] }))}
        </div>
      </div>
    );
  }

  return (
    <div>
      {renderNotification()}
      <form>
        <LabelInput
          id="name"
          name="name"
          labelText="Name"
          labelType="text"
          value={organization.name}
          isRequired
          onChangeValue={handleOnChange}
        />
        <LabelInput
          id="displayName"
          name="displayName"
          labelText="Display Name"
          labelType="text"
          value={organization.displayName}
          isRequired
          onChangeValue={handleOnChange}
        />
        <LabelTextarea
          id="note"
          name="note"
          labelText="Notes (optional)"
          value={organization.note}
          required
          onChangeValue={handleOnChange}
        />
        <div className="mt-1">
          <Button
            className="float-right"
            key="submit"
            isSmall
            filledColor="secondary"
            type="submit"
            onClick={save}
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

export default OrganizationAdd;
