class SearchUsersAction {
  constructor(apiService) {
    this.apiService = apiService;
  }

  execute({ firstName, lastName, email }) {
    return this.apiService.get(`users/search?first=${firstName}&last=${lastName}&email=${email}`);
  }
}

export default SearchUsersAction;
