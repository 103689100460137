import React from 'react';
import PropTypes from 'prop-types';

const SkipTrigger = props => (
  <a className="skip-trigger" href={props.target}>{props.text}</a>
);

SkipTrigger.propTypes = {
  target: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default SkipTrigger;
