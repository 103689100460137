import React from 'react';
import { iconPropTypes, defaultIconProps } from './types';

import { strokeColorMap } from '../index.js';

const CloseIcon = ({ className, iconColor }) => (
  <svg className={`${className} ${strokeColorMap[iconColor]}`} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 1L1 13M1 1L13 13" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

CloseIcon.propTypes = iconPropTypes;
CloseIcon.defaultProps = defaultIconProps;

export default CloseIcon;
