import { useState } from 'react';

export const useSlideOver = () => {
  const [isSlideOverOpen, setIsSlideOverOpen] = useState(false);

  const openSlideOver = () => setIsSlideOverOpen(true);
  const closeSlideOver = () => setIsSlideOverOpen(false);

  return { isSlideOverOpen, openSlideOver, closeSlideOver };
};
