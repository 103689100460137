import React, { Component } from 'react';
import { withCookies } from 'react-cookie';

import { propTypes } from './types';

import { Context } from '../../../context/ContextProvider';

import CookieNotificationContent from '../CookieNotificationContent';

import { IS_PRODUCTION } from '../../../lib/constants';

class CookieNotificationOverlay extends Component {
  static contextType = Context;

  constructor(props) {
    super(props);

    this.cookies = this.props.cookies;
    const cookiePolicyAccepted = this.cookies.get('cookiePolicy');
    this.state = {
      showNotification: !cookiePolicyAccepted || false,
    };
  }

  dismissNotification = () => {
    this.cookies.set('cookiePolicy', 'accepted', { path: '/', secure: IS_PRODUCTION });
    this.setState({
      showNotification: false,
    });
  };

  renderNotification = () => (
    this.state.showNotification
      ? (<CookieNotificationContent onClick={this.dismissNotification} />)
      : null
  );

  render() {
    return (
      <>
        {this.renderNotification()}
      </>
    );
  }
}

CookieNotificationOverlay.propTypes = propTypes;

export default withCookies(CookieNotificationOverlay);
