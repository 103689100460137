class UpdateUserAction {
  constructor(apiService) {
    this.apiService = apiService;
  }

  execute(newUserData) {
    return this.apiService
      .patch('users/me', { user: newUserData });
  }
}

export default UpdateUserAction;
