class TotalTaxAmountAction {
  constructor(apiService) {
    this.apiService = apiService;
  }

  execute({
    formData,
    lines,
    amount,
    discount,
    currencyCode,
    description,
    isSameShippingAddress,
  }) {
    return this.apiService.post('payment/calculate-tax', {
      formData,
      lines,
      amount,
      discount,
      currencyCode,
      description,
      isSameShippingAddress,
    });
  }
}

export default TotalTaxAmountAction;
