export default class AnyCriteria {
  constructor(...criteria) {
    this.criteria = criteria;
  }

  test() {
    return this.criteria
      .map(criterion => criterion.test())
      .every(criteria => criteria === true);
  }
}
