import React, { useContext, useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';

import { CurrentUserContext } from '../../../context/CurrentUser';

import { useIntersectionObserver } from '../../../hooks';

import DashboardAnnouncement from '../../Dashboard/DashboardAnnouncement';
import MobileSidebar from './MobileSidebar';
import DesktopSidebar from './DesktopSidebar';
import NotificationBell from '../../SiteHeader/NotificationBell';
import AccountMenu from '../../SiteHeader/AccountMenu';
import BackToTopButton from '../../ButtonBackToTop';
import SidebarToggle from './SidebarToggle';
import SidebarFooter from './SidebarFooter';

import PremiumFeature from '../../../lib/features/Premium';
import CalendlyWidget from '../../CalendlyWidget';
import SkillsNotification from '../../SiteHeader/SkillsNotification';

const bgColors = {
  white: 'bg-white',
  ivory: 'bg-ivory-400',
  slate: 'bg-slate-50',
};

const layoutWidths = {
  sm: 'layout-sm',
  lg: 'layout-lg',
  xl: 'layout-xl',
  '2xl': 'layout-2xl',
  '3xl': 'layout-3xl',
  full: 'layout-full',
};

const SidebarLayout = ({
  location,
  children,
  bgColor,
  backToTop,
  layoutWidth,
}) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [premiumUser, setPremiumUser] = useState(false);

  const scrollRef = useRef();

  const handleSidebarOpen = value => setSidebarOpen(value);

  const {
    currentUser,
    currentUser: {
      firstName,
      lastName,
      avatarUrl,
      accessLevel,
      organizationsOwned,
      hasCompletedIBI,
      hasPendingInvitations,
      isTrial,
      trialEndDate,
      allowSkillsTracking,
      allowGSPShowing,
    },
  } = useContext(CurrentUserContext);

  useEffect(() => {
    new PremiumFeature(currentUser)
      .positive(() => setPremiumUser(true))
      .negative(() => setPremiumUser(false))
      .execute();
  }, [currentUser.accessLevel]);

  const { element, isIntersecting } = useIntersectionObserver({
    root: null,
    threshold: 0.25,
  });

  const layoutClasses = classNames({
    [layoutWidths[layoutWidth]]: layoutWidth,
    [bgColors[bgColor]]: bgColor,
  });

  return (
    <div className="h-screen overflow-hidden">
      <MobileSidebar
        sidebarOpen={sidebarOpen}
        handleSidebarOpen={handleSidebarOpen}
        location={location}
        accessLevel={accessLevel}
        premiumUser={premiumUser}
        hasCompletedIBI={hasCompletedIBI}
        allowGSPShowing={allowGSPShowing}
      />
      <DesktopSidebar
        location={location}
        accessLevel={accessLevel}
        premiumUser={premiumUser}
        hasCompletedIBI={hasCompletedIBI}
        allowGSPShowing={allowGSPShowing}
      />
      <div className="lg:pl-72">
        <div className="sticky top-0 z-[500] flex items-center h-16 px-4 bg-white shadow-sm shrink-0 gap-x-4 sm:gap-x-6 sm:px-6 lg:px-8 print:hidden">
          <SidebarToggle handleSidebarOpen={handleSidebarOpen} />
          <div className="w-px h-6 bg-charcoal-900/10 lg:hidden" aria-hidden="true" />
          <div className="flex justify-end flex-1 gap-x-4 lg:gap-x-6">
            <div className="flex items-center gap-x-4">
              {allowSkillsTracking && <SkillsNotification />}
              <NotificationBell hasPendingInvitations={hasPendingInvitations} />
              <CalendlyWidget />
              <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-charcoal-900/10" aria-hidden="true" />
              <AccountMenu
                firstName={firstName}
                lastName={lastName}
                avatarUrl={avatarUrl}
                accessLevel={accessLevel}
                organizationsOwned={organizationsOwned}
              />
            </div>
          </div>
        </div>
        <main ref={scrollRef} className="flex flex-col h-[calc(100vh-4rem)] overflow-auto">
          <div className="flex flex-col flex-grow">
            <div className={twMerge('main-layout', layoutClasses)}>
              {(isTrial && location.pathname.includes('/dashboard'))
                ? <DashboardAnnouncement trialEndDate={trialEndDate} />
                : null}
              {children}
            </div>

            {backToTop ? <BackToTopButton ref={scrollRef} isIntersecting={isIntersecting} /> : null}
            <SidebarFooter element={element} />
          </div>
        </main>
      </div>
    </div>
  );
};

SidebarLayout.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  children: PropTypes.node.isRequired,
  bgColor: PropTypes.string,
  backToTop: PropTypes.bool,
  layoutWidth: PropTypes.string,
};

SidebarLayout.defaultProps = {
  bgColor: 'ivory',
  backToTop: false,
  layoutWidth: '',
};

export default SidebarLayout;
