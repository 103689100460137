import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'isomorphic-dompurify';

import { Context } from '../../../context/ContextProvider';

import withServerSideData from '../../../HOC/withServerSideData';

import { useConstructor } from '../../../hooks';

import PageHeader from '../../PageHeader';
import LabelTextarea from '../../common/LabelTextarea';
import DropdownSelect from '../../common/Dropdowns/DropdownSelect';
import LabelInput from '../../common/LabelInput';
import Notification from '../../Notifications/Notification';
import Button from '../../common/Button';
import { Form } from '../../common/Form';

import createAssessmentGroupAction from '../../../actions/assessments/createGroup';

import needsAuthentication from '../../../lib/needsAuthentication';
import { accessCodeList } from '../../../lib/accessCodeList';
import { trackCreateGroupSuccess } from '../../../lib/tracker/assessments/group';
import { extractEmails } from '../../../lib/inputValidation/extractEmail';
import logger from '../../../lib/logger';

const AssessmentInvitation = ({ params: { assessment }, initialData }) => {
  const { router, currentUser, apiService } = useContext(Context);
  const { asmtData: { displayName }, accessCodes: initialAccessCodes } = initialData;
  const { accessCodes, defaultAccessCode } = accessCodeList({ codes: initialAccessCodes });

  const [formData, setFormData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      groupName: '',
      ownerEmails: '',
      customMessage: '',
      memberEmails: '',
      selectedAccessCode: defaultAccessCode,
    });

  const [validatedEmails, setValidatedEmails] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      ownerEmails: [],
      memberEmails: [],
    });

  const [formError, setFormError] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      groupName: null,
      ownerEmails: null,
      memberEmails: null,
    });

  const [notification, setNotification] = useState(null);
  const groupNameRef = useRef(null);
  const ownerEmailRef = useRef(null);
  const memberEmailRef = useRef(null);

  const pageTitle = displayName;
  const isGta = assessment === 'gta';
  const groupOrTeam = isGta ? 'Team' : 'Group';

  useConstructor(() => {
    needsAuthentication(router, currentUser, `redirect_to=/assessments/${assessment}/group/new`);
  }, []);

  useEffect(() => {
    if (notification) {
      window.scroll(0, 0);
    }
  }, [notification]);

  useEffect(() => {
    if (formError.groupName) {
      groupNameRef.current.focus();
      return;
    }
    if (formError.ownerEmails) {
      ownerEmailRef.current.focus();
      return;
    }
    if (formError.memberEmails) {
      memberEmailRef.current.focus();
    }
  }, [formError]);

  const updateAccessCode = e => {
    const accessCodeSet = e.target.value;
    setFormData({ selectedAccessCode: accessCodeSet });
  };

  const handleOnChange = ({ target: { name, value } }) => {
    setFormData({ [name]: value });
    if (formError[name]) setFormError({ [name]: null });
  };

  const validateEmails = ({ target: { name, value } }) => {
    const extractedEmails = extractEmails(value);
    if (extractedEmails instanceof Error) {
      setFormError({ [name]: extractedEmails.message });
    } else {
      setFormData({ [name]: value });
      setValidatedEmails({ [name]: extractedEmails });
      setFormError({ [name]: null });
    }
  };

  async function handleFormSubmit(e) {
    e.preventDefault();

    if (!formData.groupName) {
      setFormError({ groupName: 'Please enter a name for the group.' });
      return;
    }

    if (!formData.memberEmails) {
      setFormError({ memberEmails: 'You must invite at least one person.' });
      return;
    }

    if (formError.groupName || formError.memberEmails) {
      return;
    }

    const { groupName, customMessage, selectedAccessCode } = formData;
    const { ownerEmails, memberEmails } = validatedEmails;

    const ownerInvitationEmails = ownerEmails.map(email =>
      ({ email, accessCode: '' }));
    const memberInvitationEmails = memberEmails.map(email =>
      ({ email, accessCode: selectedAccessCode }));

    const payload = {
      asmtName: displayName,
      asmtType: assessment,
      groupName,
      customMessage,
      ownerInvitationEmails,
      memberInvitationEmails,
    };
    const genericErrorMessage = 'Something went wrong. Please try again later.';

    try {
      const response = await createAssessmentGroupAction(apiService, payload);
      if (response && response.message) {
        const readableErrorMessage = response.message.includes('E_VALIDATION')
          ? 'An email you submitted was invalid.'
          : genericErrorMessage;
        setNotification({ type: 'warning', message: readableErrorMessage });
      } else {
        const trackData = {
          ...payload,
          hasCustomMessage: !!customMessage,
          memberEmails: formData.memberEmails,
          groupSize: memberInvitationEmails.length,
          userId: currentUser.userid,
        };
        await trackCreateGroupSuccess(trackData);
        router.push(`/assessments/${assessment}/group/${response.id}`);
      }
    } catch (err) {
      logger.error(`Error creating assessment group.
        Payload: ${JSON.stringify(payload, null, 2)}
        Error: ${err}`);
      setNotification({ type: 'warning', message: genericErrorMessage });
    }
  }

  const legalNotice = `I understand that by administering this survey, I will receive personally identifiable
  information (i.e. name and email address) from the survey participants. I understand and agree that I will not
  share the participants' personally identifiable data with anyone else, and will use such data only as necessary
  for the lawful purposes of this assessment.`;

  return pageTitle && (
    <>
      <PageHeader
        pageTitle={`${pageTitle} - Create ${groupOrTeam}`}
        icon="ibi-symbol"
        backLink={{ to: `/assessments/${assessment}`, text: `Back to ${pageTitle} Home` }}
        bgColor="white"
        skipTarget={`#create-${groupOrTeam.toLowerCase()}`}
      />
      <Form className="[&>:nth-child(2)]:mt-16" bgColor="ivory" handleFormSubmit={handleFormSubmit}>
        {notification && <Notification {...notification} />}
        <div id={`create-${groupOrTeam.toLowerCase()}`} className="px-4 py-6 mb-5 text-center bg-white rounded-2xl md:mb-12 xl:mb-16">
          <p className="max-w-3xl m-0">Additional instructions are available in the <a href="https://aperian.zendesk.com/hc/en-us/sections/360011317934-Managing-IBI-Groups" target="_blank" rel="noopener noreferrer">Aperian Help Center</a>.</p>
        </div>
        <LabelInput
          ref={groupNameRef}
          id="groupname"
          name="groupName"
          labelText={`${groupOrTeam} Name`}
          labelType="text"
          value={formData.groupName}
          onChangeValue={handleOnChange}
          helperText="Enter a friendly name that will appear to your participants."
          errorMessage={formError.groupName}
          isRequired
        />
        <LabelInput
          ref={ownerEmailRef}
          id="additionalowner"
          name="ownerEmails"
          labelText="Additional Group Owners"
          labelType="text"
          helperText="Invite someone to help you manage the group."
          value={formData.ownerEmails}
          onChangeValue={handleOnChange}
          onBlur={validateEmails}
          errorMessage={formError.ownerEmails}
        />
        <LabelTextarea
          id="customMessage"
          name="customMessage"
          labelText="Custom Message"
          placeholder="You might consider including:&#10;- Please complete the survey by DATE...&#10;- If you have questions, reach out to EMAIL..."
          customClass="textarea__custom-message"
          onChangeValue={handleOnChange}
          value={DOMPurify.sanitize(formData.customMessage, { USE_PROFILES: [] })}
          helperText="Enter your message that will appear within the standard invitation."
        />
        <LabelTextarea
          ref={memberEmailRef}
          id="groupemailinvitations"
          name="memberEmails"
          labelText="Participants"
          placeholder="johndoe@example.com, janedoe@example.com"
          onChangeValue={handleOnChange}
          errorMessage={formError.memberEmails}
          value={formData.memberEmails}
          onBlur={validateEmails}
          helperText="Enter comma separated email addresses or paste a spreadsheet column."
        />
        {accessCodes.length > 0
          && (
            <DropdownSelect
              id="accessCode"
              name="selectedAccessCodes"
              labelText="Access Code"
              value={formData.selectedAccessCode}
              options={accessCodes}
              onChangeValue={updateAccessCode}
              helperText="Select a code to provide access to participants."
            />
          )}
        <div className="mt-3 text-max-width">
          <p className="disclaimer__text--italic">{legalNotice}</p>
        </div>
        <Button
          variant="primary"
          className="ml-auto"
          type="submit"
          onClick={handleFormSubmit}
        >
          Create {groupOrTeam}
        </Button>
      </Form>
    </>
  );
};

AssessmentInvitation.getAPIDataKey = () => 'asmtInviteData';

AssessmentInvitation.getData = (apiService, { assessment }) =>
  apiService.get(`invitations/assessments/${assessment}`)
    .then(data => ({ asmtInviteData: data }));

AssessmentInvitation.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  params: PropTypes.shape({
    assessment: PropTypes.string.isRequired,
  }).isRequired,
  initialData: PropTypes.shape({
    asmtData: PropTypes.shape({
      displayName: PropTypes.string,
    }),
    accessCodes: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        description: PropTypes.string,
        internalNotes: PropTypes.string,
        quantity: PropTypes.number,
        active: PropTypes.bool,
        seatsRemaining: PropTypes.number,
        stripePlanId: PropTypes.string,
        created: PropTypes.string,
        expirationDate: PropTypes.string,
        planName: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default withServerSideData(AssessmentInvitation);
