import accessCodeResultsMeta from '../accessCodeResultsMeta';

const determineInvitationType = data => {
  let connectionName;
  let connectionId;
  let connectionType;

  if (data.team) {
    connectionName = data.team.name;
    connectionId = data.team.id;
    connectionType = 'team';
  } else if (data.group) {
    connectionName = data.group.name;
    connectionId = data.group.id;
    connectionType = 'group';
  } else if (data.org) {
    connectionName = data.org.name;
    connectionId = data.org.id;
    connectionType = 'org';
  } else {
    connectionName = data.sender.firstName;
    connectionId = data.sender.id;
    connectionType = 'person';
  }

  return {
    connectionName,
    connectionId,
    connectionType,
  };
};

const buildInvitationRedirectUrl = data => {
  if (data.isRater) {
    return `/survey/rater/${data.raterId}`;
  }

  const { connectionName, connectionId, connectionType } = determineInvitationType(data);
  const notification = accessCodeResultsMeta(data);
  const messageParam = notification ? `${notification.type}=${notification.message}&` : '';

  let redirectUrl = `/dashboard?${messageParam}`
    + `connectionName=${connectionName}`
    + `&connectionId=${connectionId}`
    + `&connectionType=${connectionType}`;

  if (data.group) {
    const asmtName = `&assessment=${data.group.assessment.displayName}`;
    const asmtType = `&assessmentType=${data.group.assessment.type}`;
    redirectUrl += `${asmtName}${asmtType}`;
  }

  return redirectUrl;
};

export { buildInvitationRedirectUrl };
