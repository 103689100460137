import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { isEmpty } from 'lodash';

import { propTypes, defaultProps } from './types';

import { CurrentUserContext } from '../../context/CurrentUser';
import { APIContext } from '../../context/API';
import { RouterContext } from '../../context/Router';
import { ToastContext } from '../../context/Toast';

import withServerSideData from '../../HOC/withServerSideData';

import PageHeader from '../../components/PageHeader';
import ProfileDimensionGroup from '../../components/custom/Profile/ProfileDimensionGroup';
import ProfileHeader from '../../components/custom/Profile/ProfileHeader';
import ProfileChartTransformer from '../../components/common/ProfileChartTransformer';
import ProfileCTA from './ProfileCTA';
import ProfileSummary from '../../components/custom/Profile/ProfileSummary';
import ProfileVideoCarousel from '../../components/custom/Profile/ProfileVideoCarousel';
import ProfileHistorySummary from '../../components/custom/Profile/ProfileHistorySummary';
import ProfileHistory from '../../components/custom/Profile/ProfileHistory';
import PrintLayoutHeader from './PrintLayout/PrintLayoutHeader';
import PrintLayoutSummary from './PrintLayout/PrintLayoutSummary';
import ProfileToPdf from './ProfileToPdf';
import ProfileShare from './ProfileShare';
import Spinner from '../../components/Spinner';

import NeedsCompletedSurvey from '../../lib/NeedsCompletedSurvey';
import { COOKIE_LANGUAGE, translations, IS_PRODUCTION, GS_PREMIUM_STRIPE_PLAN } from '../../lib/constants';
import validateLanguageForProfile from '../../lib/language';
import { createProfileShareImages, openLinkedInShareWindow } from '../../lib/profile-utils/create-profile-share';
import { createProfileDownload } from '../../lib/profile-utils/create-profile-download';
import { trackProfileDownload, trackProfileShare } from '../../lib/tracker/profile';

import ServerError from '../../components/ServerError';
import logger from '../../lib/logger';

import PremiumFeature from '../../lib/features/Premium';
import getLoginUrl from '../../lib/urls/loginUrl';

const Profile = props => {
  const { initialData } = props;
  const { currentUser } = useContext(CurrentUserContext);
  const { apiService } = useContext(APIContext);
  const { router } = useContext(RouterContext);
  const { addToast, removeToast } = useContext(ToastContext);

  const listRef = useRef(null);
  const shareButtonRef = useRef(null);
  const printRef = useRef();
  const shareRef = useRef();
  const fullScreenRef = useRef(null);

  const [state, setState] = useReducer((data, newData) =>
    ({ ...data, ...newData }), {
    ...initialData,
    location: props.location,
    hideProfileToggleText: 'Hide My Profile',
    myProfileVisibility: 'visible',
    error: null,
    profileLanguage: apiService.cookies.get(COOKIE_LANGUAGE) || 'en',
  });

  useEffect(() => {
    new PremiumFeature(currentUser)
      .positive(() => true)
      .negative(() => {
        if (currentUser.isLoggedIn()) {
          router.replace(`/dashboard?purchase&plan=${GS_PREMIUM_STRIPE_PLAN}`);
        } else {
          const loginUrl = getLoginUrl(location);
          router.replace(loginUrl);
        }
        return null;
      })
      .execute();
  }, []);

  useEffect(() => {
    let timeout;
    if (state.isCopied) {
      timeout = setTimeout(() => {
        setState({ isCopied: false });
      }, 6000);
    }
    return () => clearTimeout(timeout);
  }, [state.isCopied]);

  const retrieveTranslations = () => {
    if (!apiService.cookies.get(COOKIE_LANGUAGE)) {
      apiService.cookies.set(
        COOKIE_LANGUAGE, 'en', { path: '/', secure: IS_PRODUCTION },
      );
    }

    if (state.profileLanguage !== apiService.cookies.get(COOKIE_LANGUAGE)) {
      return Profile.getData(apiService)
        .then(data => {
          const { profile: { dimensionLabelGroups, profileDetails, grid } } = data;
          setState({
            grid,
            dimensionLabelGroups,
            profileDetails,
            profileLanguage: apiService.cookies.get(COOKIE_LANGUAGE),
          });
        });
    }
    return Promise.resolve();
  };

  async function handleDownloadPdf() {
    window.scroll(0, 0);
    const toastId = uuidv4();
    addToast({
      id: toastId,
      content: (
        <div className="flex items-center justify-between">
          <Spinner isSmall />
          <p className="m-0 ml-4">Your profile is downloading.</p>
        </div>
      ),
    });
    const profileChartRef = printRef.current;
    await trackProfileDownload(apiService, state.profileLanguage);
    await createProfileDownload(profileChartRef, `${state.firstName}_${state.lastName}_Profile.pdf`);
    removeToast(toastId);
  }

  const createShareableImages = async () => {
    const thumbNailRef = shareRef.current.thumbNail;
    const profileChartRef = shareRef.current.profileChart;
    const formData = await createProfileShareImages(thumbNailRef, profileChartRef);
    const response = await apiService.upload('profile/image', formData);
    return response;
  };

  const shareToLinkedIn = async () => {
    try {
      if (state.shareId) {
        openLinkedInShareWindow(state.shareId);
        await trackProfileShare('LinkedIn');
      } else {
        const response = await createShareableImages();
        if (response.status === 200) {
          openLinkedInShareWindow(response.shareId);
          await trackProfileShare('LinkedIn');
          setState({ shareId: response.shareId });
        }
      }
    } catch (err) {
      logger.error('Error sharing profile', err);
    }
  };

  const createShareableLink = async () => {
    let shareableLink = `${process.env.SITE_URL}/share/profile/`;
    try {
      if (state.shareId) {
        await trackProfileShare('Shareable Link');
        shareableLink += state.shareId;
      } else {
        const response = await createShareableImages();
        if (response.status === 200) {
          await trackProfileShare('Shareable Link');
          shareableLink += response.shareId;
          setState({ shareId: response.shareId });
        }
      }

      try {
        await navigator.clipboard.writeText(shareableLink);
        setState({ isCopied: true });
      } catch (err) {
        logger.error('Error copying link', err);
      }
    } catch (err) {
      logger.error('Error creating shareable link for profile', err);
    }
  };

  const buttons = [
    {
      icon: 'download',
      iconColor: 'white',
      onClick: handleDownloadPdf,
      tooltip: 'Download Profile',
    },
    {
      icon: 'retake',
      iconColor: 'white',
      to: '/profile/survey',
      tooltip: 'Retake Survey',
    },
    {
      icon: 'compare',
      iconColor: 'white',
      to: '/profile/comparison/new',
      tooltip: 'Compare Profile',
    },
    {
      icon: 'group-plus',
      iconColor: 'white',
      to: '/profile/teams/new',
      tooltip: 'Create a Team',
    },
  ];

  if (initialData.allowShareProfileLinkedIn || initialData.allowShareProfileLink) {
    buttons.push({
      icon: 'share',
      iconColor: 'white',
      tooltip: 'Share Profile',
      ref: shareButtonRef,
      listRef,
      isShare: true,
    });
  }

  if (isEmpty(state.profileDetails)) return null;

  const {
    firstName,
    lastName,
    avatarUrl,
    profileDetails: { summaryContent, dimensions },
    error,
    dimensionLabelGroups,
    grid,
    isCopied,
  } = state;

  const { hasMultipleProfiles } = currentUser;

  if (error) return <ServerError pageTitle="My Profile" error={error} />;

  return (
    <>
      <PageHeader
        pageTitle="GlobeSmart Profile"
        icon="profile"
        skipTarget="#profile"
      />
      <div className="relative flex flex-col min-h-screen print:bg-ivory-400">
        <div className="flex-grow profile">
          <PrintLayoutHeader />
          <PrintLayoutSummary firstName={firstName} lastName={lastName} />
          <div id="profile" className="lg:py-8 print:grid print:grid-cols-2 print:relative">
            <ProfileHeader
              firstName={firstName}
              lastName={lastName}
              avatarUrl={avatarUrl}
              retrieveTranslations={retrieveTranslations}
            />
            <h2 className="visually-hidden">My Profile Summary</h2>
            <ProfileSummary
              {...state}
              firstName={firstName}
              summaryContent={summaryContent}
              hasMultipleProfiles={hasMultipleProfiles}
              buttons={buttons}
              shareToLinkedIn={shareToLinkedIn}
              createShareableLink={createShareableLink}
              listRef={listRef}
              isCopied={isCopied}
              allowLink={initialData.allowShareProfileLink}
              allowLinkedIn={initialData.allowShareProfileLinkedIn}
            />
            <ProfileVideoCarousel
              videos={state.profileVideos}
              videoTrackingId={state.location.query.videoTrackingId}
            />
            <div className="box-border py-8 flex flex-col items-center gap-8 print:pt-4 print:justify-between print:[&>*:nth-child(5)]:absolute print:[&>*:nth-child(5)]:w-[48%] print:[&>*:nth-child(5)]:max-w-md print:[&>*:nth-child(5)]:left-2 print:[&>*:nth-child(5)]:bottom-0 print:[&>*:nth-child(5)]:mb-2">
              <ProfileDimensionGroup
                dimensions={dimensions}
                dimensionLabelGroups={dimensionLabelGroups}
                scores={grid}
              />
              <div className="w-full px-4 py-6 bg-white md:px-6 md:py-10 lg:px-10 lg:py-16 rounded-3xl print:hidden" ref={fullScreenRef}>
                {currentUser.hasMultipleProfiles
                  ? (
                    <>
                      <ProfileHistorySummary />
                      <ProfileHistory
                        {...state.profileHistory}
                        dimensionLabelGroups={state.dimensionLabelGroups}
                        fullScreenRef={fullScreenRef}
                      />
                    </>
                  ) : (
                    <div className="bottom-chart">
                      <div className="profile-divider" />
                      <ProfileChartTransformer
                        isMe
                        {...state}
                      />
                    </div>
                  )}
                <ProfileCTA />
              </div>
            </div>
          </div>
          <ProfileToPdf {...state} printRef={printRef} />
          <ProfileShare {...state} ref={shareRef} />
        </div>
      </div>
    </>
  );
};

Profile.needsCompletedSurvey = (router, currentUser) =>
  NeedsCompletedSurvey.apply(router, currentUser);

Profile.getAPIDataKey = () => 'profile';

Profile.getData = apiService => {
  const language = validateLanguageForProfile(
    translations,
    apiService.cookies.get(COOKIE_LANGUAGE),
    'profile',
  );
  return apiService.get(`detail?language=${language}`);
};

Profile.propTypes = propTypes;
Profile.defaultProps = defaultProps;

const profilePropTypes = propTypes.profile;

export default withServerSideData(Profile);
export { propTypes, defaultProps, profilePropTypes };
