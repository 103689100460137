import React, { useEffect, useState } from 'react';

import ActiveMemberCard from './ActiveMemberCard';
import ConfirmLeave from './ConfirmLeave';

import { propTypes, defaultProps } from '../../cardPropTypes';

const MemberTeamCard = ({
  id,
  avatarUrl,
  onLeave,
  displayName,
  displayDescription,
}) => {
  const [teamId, setTeamId] = useState(id);
  const [confirmLeave, setConfirmLeave] = useState(false);

  useEffect(() => {
    setTeamId(id);
    setConfirmLeave(false);
  }, [id]);

  const handleConfirmLeave = () => setConfirmLeave(true);

  const handleNoLeave = () => setConfirmLeave(false);

  const handleCompleteLeave = () => onLeave(teamId);

  return confirmLeave
    ? (
      <ConfirmLeave
        token={teamId}
        completeLeave={handleCompleteLeave}
        noLeave={handleNoLeave}
      />
    )
    : (
      <ActiveMemberCard
        id={teamId}
        displayName={displayName}
        displayDescription={displayDescription}
        avatarUrl={avatarUrl}
        confirmLeave={handleConfirmLeave}
      />
    );
};

MemberTeamCard.propTypes = propTypes;
MemberTeamCard.defaultProps = defaultProps;

export default MemberTeamCard;
