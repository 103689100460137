/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Tabs = ({ tabs, label, location, isButton, activeTab, onClick, spaceEvenly }) => (
  <div className="mb-6 h-fit">
    {isButton ? (
      <>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            {label}
          </label>
          <select
            id="tabs"
            name="tabs"
            onChange={e => onClick(e.target.value)}
            className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 shadow-sm text-gray-900 ring-1 hover:bg-stone-25 focus:ring-inset focus:ring-rust-500 focus:shadow-input ring-rust-500 ring-opacity-5 focus:outline-none sm:text-sm sm:leading-6"
            value={activeTab}
          >
            {tabs.map(tab => (
              <option key={tab.id} value={tab.id}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden border-b border-gray-200 sm:block">
          <nav className={`flex -mb-px space-x-8 overflow-auto${spaceEvenly ? ' justify-evenly' : ''}`} aria-label="Tabs">
            {tabs.map(tab => {
              const selected = tab.id === activeTab;
              return (
                <button
                  key={tab.id}
                  onClick={() => onClick(tab.id)}
                  className={classNames(
                    selected
                      ? 'border-rust-500 text-charcoal-900 font-bold'
                      : 'border-transparent text-charcoal-400 hover:border-gray-300 hover:text-gray-700 font-medium',
                    'whitespace-nowrap border-b-2 py-4 px-1 text-sm',
                  )}
                  role="tab"
                  aria-selected={selected}
                  aria-disabled="false"
                >
                  {tab.name}
                </button>
              );
            })}
          </nav>
        </div>
      </>
    ) : (
      <div className="border-b border-gray-200">
        <nav className={`flex -mb-px space-x-8 overflow-auto${spaceEvenly ? ' justify-evenly' : ''}`} aria-label="Tabs">
          {tabs.map(tab => {
            const selected = tab.link === location.pathname;
            return (
              <Link
                key={tab.link}
                to={tab.link}
                className={classNames(
                  selected
                    ? 'border-rust-500 text-charcoal-900'
                    : 'border-transparent text-charcoal-500 hover:border-gray-300 hover:text-gray-700',
                  'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium',
                )}
                role="tab"
                aria-selected={selected}
                aria-disabled="false"
                aria-controls={tab.link.split('/').pop()}
              >
                {tab.name}
              </Link>
            );
          })}
        </nav>
      </div>
    )}
  </div>
);

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    link: PropTypes.string,
  })).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  label: PropTypes.string,
  isButton: PropTypes.bool,
  activeTab: PropTypes.string,
  onClick: PropTypes.func,
  spaceEvenly: PropTypes.bool,
};

Tabs.defaultProps = {
  label: null,
  location: null,
  isButton: false,
  activeTab: null,
  onClick: null,
  spaceEvenly: false,
};

export default Tabs;
