import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import CopyText from '../../CopyText';

const renderInfo = user => user.map(col => (
  <tr key={col.id} className="even:bg-ivory-500">
    <td className="py-4 pl-0 pr-3 text-sm font-medium text-gray-900 md:pl-4 break-word" data-label="URL">
      {process.env.SITE_URL}/reset-password?token={col.token}
    </td>
    <td className="px-3 py-4 text-sm font-medium text-gray-900 whitespace-nowrap" data-label="Expires">
      {format(new Date(col.expiresAt), 'yyyy-MM-dd')}
    </td>
    <td className="px-3 py-4 text-sm font-medium text-gray-900 whitespace-nowrap" data-label="Copy">
      <CopyText text={`${process.env.SITE_URL}/reset-password?token=${col.token}`} />
    </td>
  </tr>
));

const PasswordResetTable = ({ passwordResetUrls }) => {
  if (!passwordResetUrls || !passwordResetUrls.length) {
    return (
      <p className="text-center">User does not have any reset requests at this time.</p>
    );
  }

  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead>
        <tr>
          <th scope="col" className="py-3.5 pl-0 md:pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
            URL
          </th>
          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
            Expires
          </th>
          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
            Copy
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {renderInfo(passwordResetUrls)}
      </tbody>
    </table>
  );
};

PasswordResetTable.propTypes = {
  passwordResetUrls: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.string,
      expiresAt: PropTypes.string,
      id: PropTypes.number,
      state: PropTypes.string,
      token: PropTypes.string,
      updatedAt: PropTypes.string,
      user: PropTypes.string,
    }),
  ),
};

PasswordResetTable.defaultProps = {
  passwordResetUrls: [],
};

export default PasswordResetTable;
