import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Context } from '../../../context/ContextProvider';

import PageHeader from '../../../components/PageHeader';

import AgCohortAdminFeature from '../../../lib/features/AgCohortAdmin';
import needsAuthentication from '../../../lib/needsAuthentication';
import Tabs from '../../../components/Tabs';

const tabList = [
  {
    name: 'Search Cohorts',
    link: '/cohorts/search',
  },
  {
    name: 'Create Cohort',
    link: '/cohorts/new',
  },
];

const getTitle = pathname => {
  let title;
  if (pathname.includes('search')) {
    title = 'Search';
  } else if (pathname.includes('new')) {
    title = 'Create';
  } else if (pathname.includes('edit')) {
    title = 'Edit';
  } else {
    title = 'Overview';
  }
  return title;
};

const isSearchOrNew = pathname => ['search', 'new'].some(path => pathname.includes(path));

const CohortsAuth = ({ children, location }) => {
  const { currentUser, router } = useContext(Context);
  useEffect(() => {
    needsAuthentication(router, currentUser);
    if (!children) {
      router.push('/cohorts/search');
    }
  });

  return new AgCohortAdminFeature(currentUser)
    .positive(() => (
      <>
        <PageHeader
          skipTarget="#cohorts-tabs"
          pageTitle={`Cohort | ${getTitle(location.pathname)}`}
        />
        {isSearchOrNew(location.pathname)
          ? (
            <div>
              <Tabs tabs={tabList} location={location} />
              <div id="cohorts-tabs">
                {children}
              </div>
            </div>
          )
          : children}
      </>
    ))
    .negative(() => {
      router.replace('/404');
      return null;
    })
    .execute();
};

CohortsAuth.propTypes = {
  children: PropTypes.node,
};

CohortsAuth.defaultProps = {
  children: null,
};

export default CohortsAuth;
