import { twMerge } from 'tailwind-merge';

const filledColors = {
  primary: 'text-white shadow-sm bg-charcoal-900 hover:bg-charcoal-600 active:ring-charcoal-50 active:bg-charcoal-600',
  secondary: 'text-white shadow-sm bg-rust-500 hover:bg-rust-600 active:ring-rust-50 disabled:bg-rust-50 active:bg-rust-600',
  red: 'text-white shadow-sm bg-red-500 hover:bg-red-600 active:ring-red-100 active:bg-red-600 disabled:bg-red-200',
  green: 'text-white shadow-sm bg-green-500 hover:bg-green-600 active:ring-green-100 active:bg-green-600 disabled:bg-green-50',
  blue: 'text-white shadow-sm bg-blue-400 hover:bg-blue-500 active:ring-blue-50 active:bg-blue-600 disabled:bg-blue-50',
  violet: 'text-white shadow-sm bg-violet-400 hover:bg-violet-500 active:ring-violet-50 active:bg-violet-600 disabled:bg-violet-50',
  white: 'text-charcoal-900 shadow-sm bg-white hover:bg-slate-50 active:ring-slate-100 active:bg-slate-50 disabled:bg-slate-400 ',
};

const outlinedColors = {
  primary: 'text-charcoal-800 border-charcoal-500 hover:bg-charcoal-50 active:bg-charcoal-25 active:ring-charcoal-50',
  secondary: 'text-rust-900 border-rust-200 active:ring-rust-50 active:bg-rust-25 hover:bg-rust-50',
  red: 'text-red-500 border-red-200 active:ring-red-50 active:bg-red-25 hover:bg-red-50 disabled:bg-red-50',
  green: 'text-green-500 border-green-200 active:ring-green-50 active:bg-green-25 hover:bg-green-50',
  blue: 'text-blue-500 border-blue-200 active:ring-blue-50 active:bg-blue-25 hover:bg-blue-50',
  violet: 'text-violet-500 border-violet-200 active:ring-violet-50 active:bg-violet-25 hover:bg-violet-50',
  white: 'border-white focus:border-white active:ring-white',
};

const variants = {
  primary: 'text-white shadow-sm bg-charcoal-900 border-0 active:ring-charcoal-50 hover:bg-charcoal-600 active:bg-charcoal-600',
  secondary: 'text-charcoal-800 border-1 border-charcoal-500 active:ring-charcoal-50 hover:bg-charcoal-50 active:bg-charcoal-25',
  tertiary: 'text-gray-500 underline border-0 underline-offset-8 hover:text-rust-500 hover:bg-transparent decoration-rust-500 active:ring-0',
};

const warningColors = 'text-red-600 border-red-600 hover:bg-red-25 shadow-none active:ring-red-100 hover:text-red-700 hover:border-red-700';
const cancelColors = 'bg-transparent shadow-none text-slate-800 active:ring-slate-100 hover:bg-slate-50 hover:text-slate-800';
const circleClasses = 'inline-flex items-center justify-center px-2 py-2 border-0 shadow-none';
const anchorTagClasses = 'inline-flex items-center p-0 text-base font-normal text-charcoal-900 underline break-words bg-transparent border-0 rounded appearance-none cursor-pointer hover:text-rust-500 decoration-rust-500';

function ButtonClasses(props) {
  let className = 'block px-5 py-2 font-sans text-base font-semibold tracking-wider text-center transition-all duration-300 bg-white rounded-full cursor-pointer group active:ring-4 text-slate-900 border-1 border-slate-400 active:ring-offset hover:bg-slate-50 active:ring-slate-50 active:bg-white disabled:pointer-events-none';

  if (props.variant) className = twMerge(className, variants[props.variant]);
  if (props.filledColor) className = twMerge(className, `border-0 ${filledColors[props.filledColor]}`);
  if (props.outlinedColor) className = twMerge(className, outlinedColors[props.outlinedColor]);
  if (props.isExtraSmall) className = twMerge(className, 'px-3.5 py-2 text-sm');
  if (props.isSmall) className = twMerge(className, 'px-4 py-2.5 text-sm');
  if (props.isLarge) className = twMerge(className, 'px-6 py-3');
  if (props.isExtraLarge) className = twMerge(className, 'px-6 py-4');
  if (props.isFullWidth) className = twMerge(className, 'w-full');
  if (props.isWarning) className = twMerge(className, warningColors);
  if (props.isCancel) className = twMerge(className, cancelColors);
  if (props.isNormalCase) className = twMerge(className, 'tracking-normal normal-case');
  if (props.isCircle) className = twMerge(className, circleClasses);
  if (props.disabled) className = twMerge(className, 'pointer-events-none opacity-50');
  if (props.isAnchorTag) className = twMerge(className, anchorTagClasses);
  if (props.className) className = twMerge(className, props.className);

  return className;
}

export default ButtonClasses;
