import tracker from '../index';

const {
  eventNames,
  eventProps,
  resolveAfterTimeout,
  checkOrgNames,
} = tracker;

const trackViewDashboard = orgs => {
  tracker.track(eventNames.VIEW_DASHBOARD, {
    [eventProps.SUCCESS]: true,
    [eventProps.ORGANIZATION_NAME]: checkOrgNames(orgs),
  });

  return resolveAfterTimeout;
};

const trackViewBannerResource = name => {
  tracker.track(eventNames.VIEW_BANNER_RESOURCE, {
    [eventProps.RESOURCE_NAME]: name,
  });

  return resolveAfterTimeout;
};

const trackDashboardBlocksSearch = searchTerm => {
  tracker.track(eventNames.DASHBOARD_SEARCH, {
    [eventProps.SEARCH_TERM]: searchTerm,
  });

  return resolveAfterTimeout;
};

export {
  trackViewDashboard,
  trackViewBannerResource,
  trackDashboardBlocksSearch,
};
