import qs from 'query-string';
import removeObjectProperties from '../../removeObjectProperties';

function pruneQueryParams(url, keysToRemove = []) {
  // note: doesn't support hashes in url, but we can add later if needed
  let urlPath = url;
  let incomingQueryString = '';
  if (url.includes('?')) {
    [urlPath, incomingQueryString] = url.split('?');
  }

  let newQueryString = incomingQueryString;
  if (incomingQueryString.length > 0 && keysToRemove.length > 0) {
    const queryParamsObj = qs.parse(incomingQueryString);
    const newQueryParamsObj = removeObjectProperties(queryParamsObj, keysToRemove);
    newQueryString = qs.stringify(newQueryParamsObj);
  }

  return `${urlPath}${newQueryString ? `?${newQueryString}` : ''}`;
}

export default pruneQueryParams;
