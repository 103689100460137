import PropTypes from 'prop-types';
import { propTypes as buttonPropTypes, defaultProps as defaultButtonProps } from '../../common/Button';

const propTypes = {
  displayName: PropTypes.string.isRequired,
  displayDescription: PropTypes.shape({}),
  confirmText: PropTypes.string,
  confirmId: PropTypes.string,
  confirmButton: PropTypes.shape(buttonPropTypes),
  cancelText: PropTypes.string,
  cancelId: PropTypes.string,
  cancelButton: PropTypes.shape(buttonPropTypes),
  token: PropTypes.string,
  isInviter: PropTypes.bool,
};

const defaultProps = {
  displayDescription: null,
  confirmButton: defaultButtonProps,
  confirmText: '',
  confirmId: 'confirm',
  cancelButton: defaultButtonProps,
  cancelText: '',
  cancelId: 'cancel',
  token: null,
  isInviter: false,
};

export {
  propTypes,
  defaultProps,
};
