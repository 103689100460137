import React from 'react';

import { propTypes, defaultProps } from './types';
import SectionContainer from '../../../common/Containers/Section';
import SentInvitationCard from './SentInvitationCard';

import formatDate from '../../../../lib/dateFormatter';

const SentInvitations = props => {
  const renderCards = () => props.sent.map(card => {
    const thisCard = { ...card };
    const isPerson = thisCard.type === 'person';
    const isTeam = thisCard.type === 'team';
    const isGroup = thisCard.type === 'group';
    thisCard.displayDescription = (
      <>
        {(isPerson || isTeam)
          && <><b>GlobeSmart Profile</b><br /></>}
        {isTeam
          && <><b>Team Name: </b>{thisCard.team}<br /></>}
        {isGroup && (
          <>
            <b>{thisCard.asmtName}</b><br />
            <b>Group Name: </b>{thisCard.group}<br />
          </>
        )}
        {thisCard.accessCode
          && <><b>Access Code: </b>{thisCard.accessCode} <br /></>}
        <b>Invitation Sent: </b> {formatDate(thisCard.sentAt, true)}
      </>
    );
    return (
      <li key={thisCard.token}>
        <SentInvitationCard
          displayName={thisCard.name || thisCard.email}
          sentAt={thisCard.sentAt}
          invitationSent={thisCard.invitationSent}
          accessCode={thisCard.accessCode}
          displayDescription={props.isInviter ? thisCard.displayDescription : null}
          token={thisCard.token}
          onResend={props.onResend}
          onCancel={props.onCancel}
          isInviter={props.isInviter}
        />
      </li>
    );
  });

  if (!props.sent || !props.sent.length) return null;

  return (
    <SectionContainer title={props.heading}>
      {props.children}
      <ul className="list-none">
        {renderCards()}
      </ul>
    </SectionContainer>
  );
};

SentInvitations.propTypes = propTypes;
SentInvitations.defaultProps = defaultProps;

export default SentInvitations;
