import React from 'react';
import { iconPropTypes, defaultIconProps } from './types';

import { strokeColorMap } from '../index.js';

const CreditCard = ({ className, iconColor }) => (
  <svg className={`${className} ${strokeColorMap[iconColor]}`} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="2" y="2" width="44" height="44" rx="22" fill="#FFE3E0" />
    <rect x="2" y="2" width="44" height="44" rx="22" stroke="#FFC9C2" strokeWidth="4" />
    <path d="M34 22H14M14 20.2L14 27.8C14 28.9201 14 29.4802 14.218 29.908C14.4097 30.2843 14.7157 30.5903 15.092 30.782C15.5198 31 16.0799 31 17.2 31L30.8 31C31.9201 31 32.4802 31 32.908 30.782C33.2843 30.5903 33.5903 30.2843 33.782 29.908C34 29.4802 34 28.9201 34 27.8V20.2C34 19.0799 34 18.5198 33.782 18.092C33.5903 17.7157 33.2843 17.4097 32.908 17.218C32.4802 17 31.9201 17 30.8 17L17.2 17C16.0799 17 15.5198 17 15.092 17.218C14.7157 17.4097 14.4097 17.7157 14.218 18.092C14 18.5198 14 19.0799 14 20.2Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

CreditCard.propTypes = iconPropTypes;
CreditCard.defaultProps = defaultIconProps;

export default CreditCard;
