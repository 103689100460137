import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../common/Button';
import PaymentShippingAddressForm from '../PaymentShippingAddressForm';
import Icon from '../../common/Icon';

const PaymentShippingForm = ({
  currentStep,
  handleNext,
  handleFormChange,
  handlePrevious,
  handleNotification,
  shippingAddressError,
  stripe,
  formData,
}) => {
  if (currentStep !== 2) return null;

  const validateAddressForm = () => {
    handleNotification({});
    if (!stripe) {
      return;
    }
    handleNext();
  };

  return (
    <>
      <div>
        { shippingAddressError ? (
          <div className="flex items-center gap-2 mb-0">
            <Icon icon="info" iconColor="red" />
            <p className="h-4 mt-2 font-sans text-base text-left text-red-500">
              {shippingAddressError}
            </p>
          </div>
        ) : null}
        <p className="mt-4 mb-0 font-medium text-left">Shipping Address</p>
        <PaymentShippingAddressForm
          handleFormChange={handleFormChange}
          formData={formData}
        />
      </div>
      <div className="flex flex-wrap w-full gap-2 m-auto justify-evenly">
        <Button
          className="flex-grow"
          type="button"
          onClick={handlePrevious}
          isSmall
        >
          Back
        </Button>
        <Button
          className="flex-grow"
          type="button"
          variant="primary"
          onClick={validateAddressForm}
        >
          Next
        </Button>
      </div>
    </>
  );
};

PaymentShippingForm.propTypes = {
  currentStep: PropTypes.number.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  handleNotification: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
  shippingAddressError: PropTypes.string,
  stripe: PropTypes.shape({}),
  formData: PropTypes.shape({}).isRequired,
};

PaymentShippingForm.defaultProps = {
  shippingAddressError: '',
  stripe: null,
};

export default PaymentShippingForm;
