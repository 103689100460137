import React from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

const HideTeamsToggle = ({ teamProfiles, location, toggleTeamDisplay }) => {
  if (
    !teamProfiles
    || isEmpty(location)
    || location?.hash.includes('advice')
    || location?.pathname.includes('advice')
  ) {
    return null;
  }

  return teamProfiles.map((team, index) => {
    const { id, areMembersShowing, isVisible } = teamProfiles[index];
    return (
      <div key={`hide-${team.id}-toggle`} className="hide-profile__team">
        <button
          type="button"
          className="flex items-center mr-4 font-light leading-7 text-md text-charcoal-900 hide-profile--team-icon hide-profile--team-profile hide-profile__button"
          onClick={() =>
            toggleTeamDisplay(
              index,
              'areMembersShowing',
              id.toString(),
              'teams_members',
            )}
        >
          {`${areMembersShowing ? 'Hide' : 'Show'} Members:
            ${team.shortDisplayName.replace(' average', '')}`}
        </button>
        <button
          type="button"
          className="font-sans font-light leading-7 text-md text-charcoal-900 hide-profile--team-icon hide-profile--team-profile hide-profile__button "
          onClick={() =>
            toggleTeamDisplay(
              index,
              'isVisible',
              id.toString(),
              'teams_average',
            )}
        >
          {`${isVisible ? 'Hide' : 'Show'} Average:
            ${team.shortDisplayName.replace(' average', '')}`}
        </button>
      </div>
    );
  });
};

HideTeamsToggle.propTypes = {
  teamProfiles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    areMembersShowing: PropTypes.bool,
    isVisible: PropTypes.bool,
  })),
  location: PropTypes.shape({
    hash: PropTypes.string,
    pathname: PropTypes.string,
  }),
  toggleTeamDisplay: PropTypes.func,
};

HideTeamsToggle.defaultProps = {
  teamProfiles: [],
  location: {},
  toggleTeamDisplay: undefined,
};

export default HideTeamsToggle;
