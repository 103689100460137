import React, { useContext, useEffect, useState } from 'react';
import { browserHistory } from 'react-router';

import { ModalContext } from '../../../context/Modal';

import AperianWordmark from '../../AperianWordmark';
import Button from '../../common/Button';

import { SESSION_ALERT_TIMEOUT } from '../../../lib/constants';

const RefreshMessage = () => {
  const { handleCloseDialog } = useContext(ModalContext);
  const [timeLeft, setTimeLeft] = useState(SESSION_ALERT_TIMEOUT);
  let displayTimer;

  useEffect(() => () => clearTimeout(displayTimer), []);

  useEffect(() => {
    displayTimer = setTimeout(() => {
      setTimeLeft(timeLeft - 1);
      if (timeLeft === 0) {
        const currentPath = browserHistory.getCurrentLocation().pathname;
        browserHistory.push(`/logout?redirect_to=${currentPath}`);
      }
    }, 1000);
  }, [timeLeft]);

  const handleButtonClick = () => {
    clearTimeout(displayTimer);
    handleCloseDialog();
  };
  return (
    <>
      <div className="text-center globe-smart-logo">
        <AperianWordmark />
      </div>
      <hr />
      <h3 className="mt-1">
        Your session is set to expire in {timeLeft} seconds
      </h3>
      <Button filledColor="green" onClick={handleButtonClick}>
        Refresh Session
      </Button>
    </>
  );
};

export default RefreshMessage;
