import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'isomorphic-dompurify';

const LabelTextArea = forwardRef(
  // eslint-disable-next-line prefer-arrow-callback
  function LabelTextArea({
    id,
    name,
    value,
    labelText,
    onChangeValue,
    isDisabled,
    isRequired,
    placeholder,
    errorMessage,
    onBlur,
    helperText,
  }, ref) {
    return (
      <div id="labeltextarea">
        <label htmlFor={`${id}`}>
          <span
            aria-hidden={labelText ? 'true' : 'false'}
            className={`font-sans block text-sm text-left font-medium leading-6 text-gray-700 ${labelText ? 'visible mb-2' : 'invisible'}`}
          >
            {labelText}
          </span>
          <span className="relative block rounded-lg shadow-sm">
            <textarea
              id={id}
              name={name}
              className="font-sans box-border block w-full rounded-md border-0 py-1.5 ring-1 ring-inset ring-gray-50 placeholder:text-gray-500 focus:ring-1 focus:ring-inset focus:ring-rust-500  focus:shadow-input text-sm leading-6 pl-3 resize-y min-h-[150px]"
              onChange={onChangeValue}
              disabled={isDisabled}
              required={isRequired}
              ref={ref}
              value={DOMPurify.sanitize(value)}
              placeholder={placeholder}
              onBlur={onBlur}
            />
          </span>
        </label>
        {errorMessage ? (
          <p className="flex items-center gap-2 mt-2 font-sans text-sm text-left text-red-500">
            {errorMessage}
          </p>
        ) : null}
        {helperText && !errorMessage ? <p className="mt-2 font-sans text-sm text-left text-gray-500 ">{helperText}</p> : null}
      </div>
    );
  });

LabelTextArea.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  placeholder: PropTypes.string,
  onChangeValue: PropTypes.func,
  errorMessage: PropTypes.string,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  onBlur: PropTypes.func,
  helperText: PropTypes.string,
};

LabelTextArea.defaultProps = {
  name: '',
  value: '',
  placeholder: '',
  helperText: '',
  onChangeValue: () => { },
  onBlur: () => { },
  errorMessage: '',
  isDisabled: false,
  isRequired: false,
};

export default LabelTextArea;
