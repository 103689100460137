import React, { useContext, useEffect, useState } from 'react';
import ElementQuery from 'react-eq';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

import { CurrentUserContext } from '../../context/CurrentUser';
import { APIContext } from '../../context/API';
import { RouterContext } from '../../context/Router';

import { ToastContext } from '../../context/Toast';

import withServerSideData from '../../HOC/withServerSideData';

import PageHeader from '../../components/PageHeader';
import Header from '../../components/custom/Teams/Header';
import PreFooter from '../../components/custom/Teams/PreFooter';
import TeamsYouOwn from '../../components/custom/Teams/TeamsYouOwn';
import TeamsYouAreIn from '../../components/custom/Teams/TeamsYouAreIn';

import TeamRemoveMemberAction from '../../actions/teams/teamRemoveMember';

import { trackRemoveSelfFromTeam, trackViewMyTeam } from '../../lib/tracker/team';
import { trackTeamProfileActivityViewed } from '../../lib/tracker/teams';
import { trackActivityCompletion } from '../../actions/skills';
import { VIEW_THE_GSP_TEAM_PROFILE_ACTIVITY } from '../MySkills/activityConstants';

const queries = {
  'card-layout': 400,
};

const Teams = ({ initialData }) => {
  const { currentUser } = useContext(CurrentUserContext);
  const { apiService } = useContext(APIContext);
  const { router } = useContext(RouterContext);
  const { addToast, removeToast } = useContext(ToastContext);
  const [teams, setTeams] = useState(initialData);

  useEffect(() => {
    trackViewMyTeam(currentUser.userid);
  }, [currentUser.userid]);

  /**
   * Get latest invitation data from the API and save it to state
   * @type {Promise}  Will resolve with updated `teams` object
   */
  const refreshData = () => apiService.get('teams')
    .then(data => setTeams({ ...data }));

  /**
   * Call the action to leave the team and then remove it from the UI
   * @param  {String} id      Team ID
   * @return {Promise}
   */
  const onLeave = id => new TeamRemoveMemberAction(apiService)
    .execute(id, currentUser.userid)
    .then(trackRemoveSelfFromTeam)
    .then(refreshData)
    .catch(() => router.replace('/error'));

  const trackActivity = async () => {
    await trackActivityCompletion(apiService, VIEW_THE_GSP_TEAM_PROFILE_ACTIVITY);
    await trackTeamProfileActivityViewed();
  };

  const toastContent = (
    <div className="flex flex-wrap">
      <p className="p-2 m-0 text-base">
        After setting up your team, promote <br />
        more inclusive, collaborative team cultures<br />
        with our&nbsp;
        <a
          onClick={trackActivity}
          href="https://resources.aperian.com/mod/help/GlobeSmart_Team_Profile_Activity.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >Team Profile Activity
        </a>.
      </p>
    </div>
  );

  useEffect(() => {
    const toastId = uuidv4();
    addToast({
      id: toastId,
      bgColor: 'bg-ivory-400',
      toastPosition: 'top-right',
      isDismissable: true,
      content: toastContent,
    });

    return () => removeToast(toastId);
  }, []);

  return (
    <>
      <PageHeader
        pageTitle="My Teams"
        icon="profile"
        skipTarget="#my-teams"
      />
      <ElementQuery queries={queries}>
        <div>
          <div className="box-border">
            <Header pendingTeamInvitations={initialData.pendingTeamInvitations} />
            <TeamsYouOwn teamsYouOwn={teams.teamsYouOwn} />
            <TeamsYouAreIn teamsYouAreIn={teams.teamsYouAreIn} onLeave={onLeave} />
            <PreFooter />
          </div>
        </div>
      </ElementQuery>
    </>
  );
};

Teams.getAPIDataKey = () => 'teams';
Teams.getData = apiService =>
  apiService.get('teams').then(data => ({ teams: data }));

Teams.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  initialData: PropTypes.shape({
    pendingTeamInvitations: PropTypes.arrayOf(PropTypes.shape({
      accessCode: PropTypes.string,
      createdAt: PropTypes.string,
      id: PropTypes.number,
      recipient: PropTypes.string,
      recipientEmail: PropTypes.string,
      sender: PropTypes.shape({
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string,
        id: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
      sentAt: PropTypes.string,
      state: PropTypes.string,
      team: PropTypes.shape({
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string,
        id: PropTypes.number,
        name: PropTypes.string,
        customMessage: PropTypes.string,
      }),
      token: PropTypes.string,
      updatedAt: PropTypes.string,
    })).isRequired,
    teams: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        customMessage: PropTypes.string,
        isOwner: PropTypes.bool,
        avatarUrl: PropTypes.string,
      }),
    ),
  }).isRequired,

};

export default withServerSideData(Teams);
