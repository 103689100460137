import React, { useContext, useReducer } from 'react';

import { APIContext } from '../../../context/API';

import UserSearch from './UserSearch';
import UserSearchForm from './UserSearchForm';
import UserList from './UserList';

import UpdateUserAdminAction from '../../../actions/user/updateAdmin';
import { renderNotifications, getErrorMessage } from '../RenderNotification';

const Users = () => {
  const { apiService } = useContext(APIContext);

  const [state, setState] = useReducer((data, newData) =>
    ({ ...data, ...newData }), {
    notification: null,
  });

  const displayNotification = notification => {
    let timeout;
    setState({ notification });

    if (notification) {
      timeout = setTimeout(() => {
        setState({
          notification: null,
        });
      }, 3000);
    }
    return () => window.clearTimeout(timeout);
  };

  const updateAdmin = (id, adminStatus) => new UpdateUserAdminAction(apiService)
    .execute(id, adminStatus)
    .then(() => {
      displayNotification({
        type: 'confirm',
        message: 'Admin status updated.',
      });
    })
    .catch(err => {
      displayNotification({
        type: 'warning',
        message: `Error updating admin status: ${getErrorMessage(err)}`,
      });
    });

  return (
    <UserSearch
      render={({
        firstName,
        lastName,
        email,
        onSearchParamChange,
        users,
        search,
        notification,
      }) => {
        const updateAdminWithSearch = (e, id) => {
          e.stopPropagation();
          updateAdmin(id, { [e.target.value]: e.target.checked }).then(search);
        };
        return (
          <div className="box-border">
            {renderNotifications([notification, state.notification])}
            <UserSearchForm
              firstName={firstName}
              lastName={lastName}
              email={email}
              onChange={onSearchParamChange}
            />
            <UserList users={users} onUpdate={updateAdminWithSearch} />
          </div>
        );
      }}
    />
  );
};

export default Users;
