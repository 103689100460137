import qs from 'query-string';
import currentUrl from '../currentUrl';

function getLoginUrl(location) {
  let redirectUrl;
  let loginUrl;
  const queryParams = qs.parse(location.search);
  if (queryParams.sp) {
    redirectUrl = currentUrl(location, { removeQueryParams: ['sp'] });
    loginUrl = `/login?sp=${queryParams.sp}&redirect_to=${encodeURIComponent(redirectUrl)}`;
  } else {
    redirectUrl = currentUrl(location);
    loginUrl = `/login?redirect_to=${encodeURIComponent(redirectUrl)}`;
  }
  return loginUrl;
}

export default getLoginUrl;
