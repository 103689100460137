/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable camelcase */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

import Button from '../../common/Button';
import LabelInput from '../../common/LabelInput';
import PaymentLabelInput from '../PaymentLabelInput';
import Checkbox from '../../common/Checkbox';
import Icon from '../../common/Icon';

const PaymentPurchaseForm = ({
  currentStep,
  formData,
  handleFormChange,
  acceptPurchaseAgreement,
  handleAcceptPurchaseAgreement,
  handleSetStripeToken,
  handleChargeErrors,
  handlePrevious,
  paymentError,
}) => {
  if (currentStep !== 3) return null;

  const stripe = useStripe();
  const elements = useElements();

  const [invalidFields, setInvalidFields] = useState([]);
  const [checkboxError, setCheckboxError] = useState(null);

  const cvcElementRef = useRef(null);
  const numberElementRef = useRef(null);
  const expiryElementRef = useRef(null);

  const handleStripeToken = async () => {
    setCheckboxError(null);

    const {
      name,
      billing_address,
    } = formData;

    const paymentDetails = {
      name,
      address_line1: billing_address.line1,
      address_city: billing_address.city,
      address_state: billing_address.state,
      address_zip: billing_address.postal_code,
      address_country: billing_address.country,
    };

    const cardElement = elements.getElement(CardNumberElement);

    const { error, token } = await stripe.createToken(cardElement, paymentDetails);

    if (error) {
      setInvalidFields([error.code]);
      handleChargeErrors(error);
      return;
    }

    if (!acceptPurchaseAgreement) {
      setCheckboxError('Please accept the Purchase Agreement to continue.');
      return;
    }

    handleSetStripeToken(token);
  };

  return (
    <>
      { checkboxError || paymentError ? (
        <div className="flex items-center gap-2 pb-2 mb-4">
          <Icon icon="info" iconColor="red" />
          <p className="h-4 font-sans text-base text-left text-red-500">
            {checkboxError || paymentError}
          </p>
        </div>
      ) : null}
      <div className="mb-8 form-group">
        <PaymentLabelInput
          id="card-number"
          labelText="Card Number"
          labelType="text"
          isRequired
          iconClass="icon-credit-card"
          isStripe
          errorMessage={invalidFields.some(field => field === 'incomplete_number')}
          ref={numberElementRef}
        >
          <CardNumberElement
            className="StripeElement"
            placeholder=""
          />
        </PaymentLabelInput>
        <div className="flex justify-between mt-3">
          <div className="w-[45%]">
            <PaymentLabelInput
              id="expiration"
              labelText="Expiration"
              labelType="text"
              isRequired
              isStripe
              errorMessage={invalidFields.some(field => field === 'incomplete_expiry')}
              ref={expiryElementRef}
            >
              <CardExpiryElement
                className="StripeElement"
                placeholder=""
              />
            </PaymentLabelInput>
          </div>
          <div className="w-[45%]">
            <PaymentLabelInput
              id="cvc"
              labelText="CVC"
              labelType="text"
              isRequired
              isStripe
              errorMessage={invalidFields.some(field => field === 'incomplete_cvc')}
              ref={cvcElementRef}
            >
              <CardCvcElement
                className="StripeElement"
                placeholder=""
              />
            </PaymentLabelInput>
          </div>
        </div>
        <LabelInput
          id="org"
          name="org"
          labelText="Organization / University"
          labelType="text"
          value={formData.org}
          onChangeValue={handleFormChange}
        />
      </div>
      <div className="flex justify-center mb-6 text-base">
        <Checkbox
          id="payment-agreement"
          name="agreement"
          labelText={['I accept the  ',
            <a
              key="purchase-agreement"
              href="/content/purchase-agreement"
              target="_blank"
              rel="noopener noreferrer"
            >
              Purchase Agreement
            </a>,
          ]}
          checked={acceptPurchaseAgreement}
          onChange={handleAcceptPurchaseAgreement}
          isRequired
        />
      </div>
      <div className="flex flex-wrap w-full gap-2 m-auto justify-evenly">
        <Button
          className="flex-grow"
          type="button"
          onClick={handlePrevious}
          isSmall
        >
          Back
        </Button>
        <Button
          className="flex-grow"
          variant="primary"
          type="button"
          onClick={handleStripeToken}
        >
          Purchase
        </Button>
      </div>
    </>
  );
};

PaymentPurchaseForm.propTypes = {
  currentStep: PropTypes.number.isRequired,
  formData: PropTypes.shape({
    name: PropTypes.string,
    org: PropTypes.string,
    billing_address: PropTypes.shape({
      line1: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      postal_code: PropTypes.string,
      country: PropTypes.string,
    }),
  }).isRequired,
  handleFormChange: PropTypes.func.isRequired,
  acceptPurchaseAgreement: PropTypes.bool.isRequired,
  handleAcceptPurchaseAgreement: PropTypes.func.isRequired,
  handleSetStripeToken: PropTypes.func.isRequired,
  handleChargeErrors: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
  paymentError: PropTypes.string,
};

PaymentPurchaseForm.defaultProps = {
  paymentError: '',
};

export default PaymentPurchaseForm;
