import tracker from '../index';

const {
  eventNames,
  eventProps,
  peopleProps,
  peopleProfileProps,
  resolveAfterTimeout,
  superProps,
} = tracker;

const trackOnboardingStart = () => {
  tracker.track(eventNames.START_ONBOARDING);
};

const trackViewOnboardingSignupForm = () => {
  tracker.track(eventNames.VIEW_TRIAL_SIGNUP_FORM);
};

const trackCompleteOnboardingStep = stepName => {
  tracker.track(`${eventNames.COMPLETE_ONBOARDING_STEP}: ${stepName}`);

  return resolveAfterTimeout;
};

const trackMemberCompleteOnboarding = userType => {
  tracker.register({
    [superProps.TRIAL_USER_TYPE]: userType,
  });
  return resolveAfterTimeout;
};

const trackOnboardingStepCompletionIdentify = ({
  userId,
  email,
  firstName,
  lastName,
}) => {
  tracker.identify(userId);

  tracker.people.set({
    [peopleProfileProps.EMAIL]: email,
    [peopleProfileProps.FIRST_NAME]: firstName,
    [peopleProfileProps.LAST_NAME]: lastName,
  });
  tracker.track(`${eventNames.COMPLETE_ONBOARDING_STEP}: ${eventNames.EMAIL_VERIFICATION_SENT}`);

  return resolveAfterTimeout;
};

const trackOnboardingStepCompletionName = name => {
  tracker.track(`${eventNames.COMPLETE_ONBOARDING_STEP}: ${eventNames.NAME}`, {
    [eventProps.NAME]: name,
  });

  return resolveAfterTimeout;
};

const trackOnboardingStepCompletionEmail = email => {
  tracker.track(`${eventNames.COMPLETE_ONBOARDING_STEP}: ${eventNames.EMAIL}`, {
    [eventProps.EMAIL]: email,
  });

  return resolveAfterTimeout;
};

const trackOnboardingStepCompletionTrack = track => {
  tracker.track(`${eventNames.COMPLETE_ONBOARDING_STEP}: ${eventNames.PRODUCT_TRACK}`, {
    [eventProps.PRODUCT_TRACK_SELECTED]: track,
  });

  return resolveAfterTimeout;
};

const trackTrialInvitationCompletion = ({
  userId,
  emails,
  numberInvitationsSent,
  organizationName,
  invitationMethod,
}) => {
  tracker.identify(userId);

  tracker.people.set({
    [peopleProps.ORGANIZATION_NAME]: organizationName,
  });

  tracker.register({
    [superProps.TRIAL_USER_TYPE]: 'Owner',
  });

  tracker.track(eventNames.INVITE_TRIAL_PARTICIPANTS, {
    [eventProps.TRIAL_INVITATION_PARTICIPANTS]: emails,
    [eventProps.NUMBER_INVITATIONS_SENT]: numberInvitationsSent,
    [eventProps.TRIAL_INVITEE_METHOD]: invitationMethod,
    [eventProps.ORGANIZATION_NAME]: organizationName,
  });

  return resolveAfterTimeout;
};

const trackOnboardingCompletion = ({ organizationName, track }) => {
  tracker.track(eventNames.COMPLETE_ONBOARDING, {
    [eventProps.ORGANIZATION_NAME]: organizationName,
    [eventProps.PRODUCT_TRACK_SELECTED]: track,
  });
};

const trackOnboardingSelectUseCase = selection => {
  tracker.track(eventNames.PERSON_TYPE_SELECTION, {
    [eventProps.SELECTION]: selection,
  });

  return resolveAfterTimeout;
};

const trackOnboardingLoginRedirect = stepName => {
  tracker.track(eventNames.LOGIN_REDIRECT_FROM_ONBOARDING, {
    [eventProps.ONBOARDING_STEP]: stepName,
  });
};

export {
  trackOnboardingStart,
  trackOnboardingStepCompletionIdentify,
  trackOnboardingStepCompletionName,
  trackOnboardingStepCompletionEmail,
  trackOnboardingStepCompletionTrack,
  trackTrialInvitationCompletion,
  trackOnboardingCompletion,
  trackCompleteOnboardingStep,
  trackMemberCompleteOnboarding,
  trackOnboardingSelectUseCase,
  trackOnboardingLoginRedirect,
  trackViewOnboardingSignupForm,
};
