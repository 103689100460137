import React, { useContext, useEffect, useReducer, useRef } from 'react';
import PropTypes from 'prop-types';

import { NotificationContext } from '../../../../context/Notification';
import { RouterContext } from '../../../../context/Router';
import { APIContext } from '../../../../context/API';
import { ModalContext } from '../../../../context/Modal';

import LabelTextarea from '../../../common/LabelTextarea';
import Button from '../../../common/Button';

import Notification from '../../../Notifications/Notification';
import { extractEmails } from '../../../../lib/inputValidation/extractEmail';
import organizationConfirmUsersAction from '../../../../actions/organizations/confirmUsers';
import deleteOrganizationAction from '../../../../actions/organizations/deleteOrganization';
import ConfirmModal from '../../../common/Modal/ConfirmModal';
import DeleteOrganizationModal from '../../../DeleteModals/DeleteOrganizationModal';

const OrganizationDeleteUsers = ({ params: { id }, orgName }) => {
  const { notificationMessage,
    addNotification,
    removeNotification } = useContext(NotificationContext);
  const { apiService } = useContext(APIContext);
  const { router } = useContext(RouterContext);
  const { handleOpenModal, handleCloseModal } = useContext(ModalContext);

  const [state, setState] = useReducer((data, newData) =>
    ({ ...data, ...newData }), {
    emails: '',
    emailList: [],
    emailError: null,
  });

  const emailsValidateRef = useRef(null);

  useEffect(() => {
    if (state.emailError) {
      emailsValidateRef.current.focus();
    }
    return () => removeNotification();
  }, [state.emailError]);

  const handleEmailChange = e => {
    const enteredEmails = e.target.value;
    setState({ emails: enteredEmails });
    if (state.emailError) { setState({ emailError: null }); }
  };

  const validateEmails = e => {
    const enteredEmails = e.target.value;
    const extractedEmails = extractEmails(enteredEmails);
    if (extractedEmails instanceof Error) {
      setState({ emailError: extractedEmails.message });
    } else {
      setState({
        emailList: extractedEmails,
        emails: enteredEmails,
        emailError: null,
      });
    }
  };

  const deleteOrganization = () =>
    deleteOrganizationAction(apiService, id)
      .then(() => {
        handleCloseModal();
        router.push('/administration/organizations/search');
        addNotification({
          type: 'success',
          message: `${orgName} organization is deleted successfully.`,
        });
      })
      .catch(err => {
        handleCloseModal();
        addNotification({
          type: 'failure',
          message: `Could not delete the organization: ${err}`,
        });
      });

  const handleOpenConfirmModal = () => {
    handleCloseModal();
    handleOpenModal({
      content: (
        <ConfirmModal
          confirmButtonText="Yes, Delete Organization"
          onCancel={handleCloseModal}
          onConfirm={deleteOrganization}
          title="Confirm Deletion"
          confirmText="DELETE"
        >
          <p className="text-base">You are about to delete the entire <b>{orgName}</b> organization and all related data. Confirm deletion by typing <b>DELETE</b> in the box.</p>
        </ConfirmModal>),
    });
  };

  const handleOpenDeleteModal = e => {
    e.currentTarget.blur();
    handleOpenModal({
      content: <DeleteOrganizationModal
        onCancel={handleCloseModal}
        onConfirm={handleOpenConfirmModal}
      />,
      modalSize: 'large',
    });
  };

  const confirmUsers = async e => {
    e.preventDefault();
    const payload = {
      // dedupe the emailList array
      emails: [...new Set(state.emailList)],
      orgId: id,
    };
    if (state.emailError || !payload.emails.length) {
      setState({ emailError: state.emailError || 'Please enter at least 1 correct email to proceed further' });
      return;
    }
    try {
      const response = await organizationConfirmUsersAction(apiService, payload);
      router.push({
        pathname: `/administration/organizations/${id}/delete-users/confirm`,
        state: response.organization,
      });
    } catch (err) {
      let message = 'Error: Unable to retrieve the members';
      if (err.status === 409) {
        message = err.message;
      }
      if (err.status === 404) {
        if (state.emailList.length > 1) {
          message = 'Error: The users listed are not found in this organization.';
        } else {
          message = 'Error: The user listed was not found in this organization.';
        }
      }
      setState({ emailError: message });
    }
  };

  return (
    <>
      <Notification {...notificationMessage} />
      <div className="grid gap-2 mt-6 md:grid-cols-6">
        <div className="md:col-span-2">
          <h5 className="mb-4 font-serif text-xl">Delete Users</h5>
        </div>
        <div className="flex flex-col gap-1 md:col-span-4">
          <LabelTextarea
            ref={emailsValidateRef}
            id="deleteUsers"
            labelText="Enter Email Addresses for Deletion"
            placeholder="Separate addresses by commas, new lines, or paste a spreadsheet column..."
            onChangeValue={handleEmailChange}
            onBlur={validateEmails}
            errorMessage={state.emailError}
            name="emails"
            value={state.emails}
          />
          <div className="flex justify-end">
            <Button
              key="submit"
              isSmall
              filledColor="secondary"
              onClick={confirmUsers}
            >
              Confirm Users
            </Button>
          </div>
        </div>
      </div>
      <hr className="my-8 border border-stone-400" />
      <div className="grid gap-2 mt-6 md:grid-cols-6">
        <div className="md:col-span-2">
          <h5 className="mb-3 font-serif text-xl">Delete Organization</h5>
          <p className="text-sm">Once deleted, the organization and its users&#39;
            data cannot be restored. Do only if you&#39;re serious.
          </p>
        </div>
        <div className="flex flex-col md:col-span-4">
          <div title="Oh really?" className="py-2">
            <Button
              className="float-right"
              onClick={handleOpenDeleteModal}
              filledColor="red"
            >Delete Organization Account
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

OrganizationDeleteUsers.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  orgName: PropTypes.string,
};

OrganizationDeleteUsers.defaultProps = {
  orgName: '',
};

export default OrganizationDeleteUsers;
