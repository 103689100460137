import React from 'react';
import PropTypes from 'prop-types';

import DashboardHeading from '../../Dashboard/DashboardHeading';
import Card from '../../Card';
import Button from '../../common/Button';

import { MIN_SURVEYS_FOR_ORG_REPORT } from '../../../lib/constants';

const Reports = ({ assessmentsData, handleReportClick }) => (
  <>
    <div className="my-1">
      <DashboardHeading
        headingText="Your Organization's Assessment Reports"
        subHeader="There must be at least 25 completed assessments to create the Organization Report."
      />
    </div>

    {(assessmentsData && assessmentsData.length > 0)
      ? (
        <>
          {assessmentsData.map(({ assessments, orgId, orgName }) => (
            assessments.map(({
              assessmentType,
              assessmentDisplayName,
              completedCount,
              version,
            }) => (
              <Card
                key={`${orgId}-${assessmentType}-v${version}`}
                displayName={`${assessmentDisplayName} ${version ? `${version}.0` : ''}`}
                displayDescription={(
                  <>
                    <b>{orgName}</b><br />
                    {completedCount} completed assessments
                  </>
                )}
              >
                <Button
                  isSmall
                  isNormalCase
                  isFullWidth
                  leadingButtonIcon="invite"
                  iconColor="dark-gray"
                  disabled={completedCount < MIN_SURVEYS_FOR_ORG_REPORT}
                  onClick={() =>
                    handleReportClick(
                      orgId,
                      orgName,
                      assessmentType,
                      assessmentDisplayName,
                      version,
                    )}
                >
                  Email {assessmentType} {version && `${version}.0`} Report
                </Button>
              </Card>
            ),
            )))}
        </>
      )
      : (
        <div className="m-0 mx-1 notification-message notification-message--upgrade">
          <div className="banner__announcement-cta">
            <i>Your organization does not currently license any Assessments.&nbsp;
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://globesmart.com/contact"
              >
                <b>Contact us</b>
              </a>
              &nbsp;to learn more about Assessments.
            </i>
          </div>
        </div>
      )}
  </>
);

Reports.propTypes = {
  assessmentsData: PropTypes.arrayOf(
    PropTypes.shape({
      orgId: PropTypes.string,
      orgName: PropTypes.string,
      assessments: PropTypes.arrayOf(
        PropTypes.shape({
          completedCount: PropTypes.number,
          assessmentType: PropTypes.string,
          assessmentDisplayName: PropTypes.string,
          version: PropTypes.number,
        }),
      ),
    }),
  ),
  handleReportClick: PropTypes.func.isRequired,
};

Reports.defaultProps = {
  assessmentsData: [],
};

export default Reports;
