class TeamDeleteAction {
  constructor(apiService) {
    this.apiService = apiService;
  }

  execute(teamId) {
    return this.apiService.delete(`teams/${teamId}`);
  }
}

export default TeamDeleteAction;
