import React from 'react';
import { Link } from 'react-router';
import ElementQuery from 'react-eq';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

const CohortsData = ({ cohorts }) => cohorts.map(({
  id,
  displayName,
  name,
  code,
  sfJobCode,
  createdAt,
  memberCount,
  organizationName,
}) => (
  <tr key={id} className="admin-list__item">
    <td className="admin-list__cell admin-list__cell--name" data-label="Cohort Display Name">
      <Link to={`/cohorts/${code}`}>{displayName}</Link>
    </td>
    <td className="admin-list__cell admin-list__cell--name" data-label="Cohort Internal Name">
      <Link to={`/cohorts/${code}`}>{name}</Link>
    </td>
    <td className="admin-list__cell admin-list__cell--name" data-label="Cohort Code">
      {code}
    </td>
    <td className="admin-list__cell admin-list__cell--name" data-label="Cohort sfJobCode">
      {sfJobCode}
    </td>
    <td className="admin-list__cell" data-label="Created At Date">
      {format(new Date(createdAt), 'MM-dd-yyyy')}
    </td>
    <td className="admin-list__cell admin-list__cell--admin" data-label="Cohort Members">
      {memberCount}
    </td>
    <td className="admin-list__cell admin-list__cell--admin" data-label="Oraganization Name">
      {organizationName}
    </td>
  </tr>
));

const CohortsList = ({ cohorts }) => {
  if (!cohorts || !cohorts.length) return null;

  return (
    <ElementQuery queries={{ 'table-view': 700 }}>
      <table className="bg-white admin-list">
        <thead className="admin-list__header">
          <tr>
            <th className="admin-list__cell admin-list__cell--first-name">
              Display Name
            </th>
            <th className="admin-list__cell admin-list__cell--first-name">
              Name (Internal)
            </th>
            <th className="admin-list__cell admin-list__cell--last-name">
              Code
            </th>
            <th className="admin-list__cell">
              SF Job Code
            </th>
            <th className="admin-list__cell">
              Created
            </th>
            <th className="admin-list__cell">
              Members
            </th>
            <th className="admin-list__cell">
              Organization Name
            </th>
          </tr>
        </thead>
        <tbody className="admin-list__container">
          <CohortsData cohorts={cohorts} />
        </tbody>
      </table>
    </ElementQuery>
  );
};

CohortsList.propTypes = {
  cohorts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      displayName: PropTypes.string.isRequired,
      name: PropTypes.string,
      code: PropTypes.string.isRequired,
      sfJobCode: PropTypes.string,
      createdAt: PropTypes.string.isRequired,
      memberCount: PropTypes.number.isRequired,
      organizationName: PropTypes.string,
    })).isRequired,
};

export default CohortsList;
