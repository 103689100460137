import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../common/Button';
import deepAdviceLinkForTopic from '../../../../lib/deepAdviceLinkForTopic';

const AdviceTopic = ({
  topic_id: topic, linkText, onClick, isAuthorized, searchParams,
}) => (
  <Button
    to={deepAdviceLinkForTopic(topic, isAuthorized, searchParams)}
    onClick={onClick}
    isSmall
    className="w-fit"
    variant="secondary"
    trailingButtonIcon="chevron-right"
    dataTopic={linkText}
  >
    {linkText}
    {isAuthorized ? null : <span className="payment__icon-upgrade-lock">&nbsp;</span>}
  </Button>
);

AdviceTopic.propTypes = {
  linkText: PropTypes.string.isRequired,
  topic_id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isAuthorized: PropTypes.bool.isRequired,
  searchParams: PropTypes.string.isRequired,
};

export default AdviceTopic;
