import React, { useContext, useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import ElementQuery from 'react-eq';
import { addDays, format } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

import { CurrentUserContext } from '../../../context/CurrentUser';
import { APIContext } from '../../../context/API';
import { ModalContext } from '../../../context/Modal';
import { ToastContext } from '../../../context/Toast';

import withServerSideData from '../../../HOC/withServerSideData';

import PlanDetails from '../../../components/AdminCenter/PlanDetails';
import Usage from '../../../components/AdminCenter/Usage';
import OrganizationYouOwn from '../../../components/AdminCenter/OrganizationYouOwn';
import Reports from '../../../components/AdminCenter/Reports';
import Notification from '../../../components/Notifications/Notification';
import DashboardHeading from '../../../components/Dashboard/DashboardHeading';
import GlobeSmartModal from '../../../components/GlobeSmartModal';
import AdminCenterInviteModal from '../../../components/AdminCenter/PlanDetails/InviteModal';

import emailOrgAsmtReport from '../../../actions/assessments/emailOrganizationAssessmentReport';
import OrgDirectoryDownloadAction from '../../../actions/organizations/orgDirectory';
import getDownloadFileName from '../../../lib/fileDownload';
import { trackEmailOrgReport } from '../../../lib/tracker/assessments/email-org-report';

const queries = { 'card-layout': 400 };

function getDateRange(licenseStartDate, isTrial) {
  if (licenseStartDate) {
    if (isTrial) {
      const trialEndDate = addDays(new Date(licenseStartDate), 6);
      return `${format(new Date(licenseStartDate), 'MMM dd yyyy')} - ${format(new Date(trialEndDate), 'MMM dd yyyy')}`;
    }
    const endDate = addDays(new Date(licenseStartDate), 364);
    return `${format(new Date(licenseStartDate), 'MMM dd yyyy')} - ${format(new Date(endDate), 'MMM dd yyyy')}`;
  }
  return null;
}

const AdminCenterDetail = ({
  initialData: {
    assessmentsData,
  },
  currentOrg: {
    name: organizationName,
    licenseStartDate,
    atuPlan,
  },
  params: { orgId },
}) => {
  const { atuSeatsUsed, contentControllerAtuExistence } = assessmentsData
    .find(org => org.orgId === orgId);

  const { apiService } = useContext(APIContext);
  const { handleOpenModal } = useContext(ModalContext);
  const { currentUser: { isTrial } } = useContext(CurrentUserContext);
  const { addToast, removeToast } = useContext(ToastContext);

  useEffect(() => {
    let toastId;
    if (isTrial) {
      toastId = uuidv4();
      addToast({
        id: toastId,
        content: (
          <div className="w-full">
            <p className="w-full p-2 m-0 text-base text-charcoal-900">
              This section shows your
              organization&apos;s
              usage based on <br />
              <a
                href="https://aperian.zendesk.com/hc/en-us/articles/5331084627475"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'charcoal-900', textDecoration: 'underline' }}
              >Annual Tracked Users
              </a>&nbsp;(ATU).
            </p>
          </div>
        ),
      });
    }
    return () => {
      if (toastId) removeToast(toastId);
    };
  }, []);

  const planDateRange = getDateRange(licenseStartDate, isTrial);

  const [notification, setNotification] = useState(null);

  const exportOrgDirectory = e => {
    const { dataset: { id } } = e.currentTarget;
    return new OrgDirectoryDownloadAction(apiService)
      .execute(id)
      .then(async response => ({
        filename: getDownloadFileName(response),
        blob: await response.blob(),
      }))
      .then(({ blob, filename }) => saveAs(blob, filename))
      .catch(err => {
        setNotification({
          type: 'warning',
          message: `Could not download organization directory: ${err}`,
        });
      });
  };

  const handleReportClick = (id, orgName, type, asmtName, version) => {
    emailOrgAsmtReport(apiService, type.toLowerCase(), id, version)
      .then(async () => {
        trackEmailOrgReport(true, asmtName, type, orgName);
        setNotification({
          type: 'success',
          message: 'Your IBI organization report is being calculated! Depending on the size of your organization, this might take 10-15 minutes for the completed report to arrive in your inbox. Thank you for your patience!',
        });
      }).catch(err => {
        setNotification({
          type: 'warning',
          message: err.message
            ? err.message
            : 'There was an error sending the report. Please try again later.',
        });
      });
  };

  return (
    <div className="mt-4 md:mt-0">
      <Notification {...notification} />
      <GlobeSmartModal>
        <AdminCenterInviteModal
          orgId={orgId}
          orgName={organizationName}
        />
      </GlobeSmartModal>
      <ElementQuery queries={queries}>
        <div className="bg-ivory-400">
          <DashboardHeading headingText="Your People and Usage" />
          <div className="grid grid-cols-1 gap-4 py-4 md:grid-cols-2">
            <PlanDetails
              atuPlan={atuPlan}
              planDateRange={planDateRange}
              hasAtu={!!(atuPlan && licenseStartDate)}
              handleOpenModal={handleOpenModal}
            />
            <Usage
              atuPlan={atuPlan}
              planDateRange={planDateRange}
              atuSeatsUsed={atuSeatsUsed}
              hasAtu={!!(atuPlan && licenseStartDate)}
              hasContentControllerUsage={contentControllerAtuExistence}
            />
          </div>
          <div className="flex flex-col gap-4">
            <OrganizationYouOwn
              orgId={orgId}
              orgName={organizationName}
              exportOrgDirectory={exportOrgDirectory}
            />
            <Reports
              assessmentsData={assessmentsData}
              handleReportClick={handleReportClick}
            />
          </div>
        </div>
      </ElementQuery>
    </div>
  );
};

AdminCenterDetail.getAPIDataKey = () => 'AdminCenterDetail';

AdminCenterDetail.getData = (apiService, { orgId }) => apiService.get(`admin-center/${orgId}`).then(data => ({ AdminCenterDetail: data }));

AdminCenterDetail.propTypes = {
  initialData: PropTypes.shape({
    organizationsOwned: PropTypes.arrayOf(
      PropTypes.shape({
        orgId: PropTypes.string,
        orgName: PropTypes.string,
      }),
    ),
    assessmentsData: PropTypes.arrayOf(
      PropTypes.shape({
        orgId: PropTypes.string,
        orgName: PropTypes.string,
      }),
    ),
  }).isRequired,
  params: PropTypes.shape({
    orgId: PropTypes.string,
  }).isRequired,
  currentOrg: PropTypes.shape({
    name: PropTypes.string,
    atuPlan: PropTypes.number,
    licenseStartDate: PropTypes.string,
  }).isRequired,
};

export default withServerSideData(AdminCenterDetail);
