import React from 'react';
import PropTypes from 'prop-types';

import SidebarLayout from '../SidebarLayout';

const MainLayout = ({
  location,
  children,
  bgColor,
  layoutWidth,
  backToTop,
}) => (
  <section className="flex flex-col min-h-screen">
    <SidebarLayout
      location={location}
      bgColor={bgColor}
      layoutWidth={layoutWidth}
      backToTop={backToTop}
    >
      {children}
    </SidebarLayout>
  </section>
);

MainLayout.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  children: PropTypes.node.isRequired,
  bgColor: PropTypes.string,
  layoutWidth: PropTypes.string,
  backToTop: PropTypes.bool,
};
MainLayout.defaultProps = {
  bgColor: 'ivory',
  layoutWidth: '',
  backToTop: false,
};

export default MainLayout;
