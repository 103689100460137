import React from 'react';

import PropTypes from 'prop-types';

const StudentIcon = ({ className1, className2 }) => (
  <svg className="max-w-full max-h-full" width="120" height="120" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 117.4 91.54">
    <defs>
      <clipPath id="clippath">
        <rect className="fill-none" x="70.19" y="3.02" width="45" height="87" />
      </clipPath>
    </defs>
    <path className={className1} d="m9.79,83.42v-23.6c0-11.8,9.6-21.3,21.5-21.3s21.5,9.5,21.5,21.3v23.6c0,.6-.5,1.1-1.1,1.1H10.89c-.6,0-1.1-.5-1.1-1.1h0Z" />
    <path className={className2} d="m31.29,1.52C14.79,1.52,1.39,14.32.79,30.42c0,.6.5,1.1,1.1,1.1h58.8c.6,0,1.1-.5,1.1-1.1C61.19,14.32,47.79,1.52,31.29,1.52Z" />
    <g>
      <g>
        <path className={className2} d="m89.89,52.32l-19.2,32.6c-1.3,2.2.3,5.1,2.9,5.1h38.4c2.6,0,4.2-2.8,2.9-5.1l-19.2-32.6c-1.4-2.2-4.5-2.2-5.8,0h0Z" />
        <path className={className1} d="m111.09,42.22h-36.8c-.5,0-1-.5-1-1V4.12c0-.6.4-1,1-1,20.9,0,37.8,17.1,37.8,38.2,0,.4-.4.9-1,.9h0Z" />
      </g>
    </g>
  </svg>
);

StudentIcon.propTypes = {
  className1: PropTypes.string,
  className2: PropTypes.string,
};

StudentIcon.defaultProps = {
  className1: '',
  className2: '',
};

export default StudentIcon;
