import tracker from '../index';

const { eventNames, eventProps, resolveAfterTimeout } = tracker;

const trackViewGuides = () => {
  tracker.track(eventNames.VIEW_GUIDES_HOME);
  return resolveAfterTimeout;
};

const trackCultureClicked = (launchedFrom, cultureName) => {
  tracker.track(eventNames.CULTURE_CLICKED, {
    [eventProps.FROM]: launchedFrom,
    [eventProps.CULTURE_NAME]: cultureName,
  });
  return resolveAfterTimeout;
};

const trackGuidesHeaderImageClicked = cultureName => {
  tracker.track(eventNames.HEADER_IMAGE_CLICKED, {
    [eventProps.CULTURE_NAME]: cultureName,
  });
  trackCultureClicked('Guides Home', cultureName);
};

export { trackViewGuides, trackGuidesHeaderImageClicked, trackCultureClicked };
