import React from 'react';
import PropTypes from 'prop-types';

import CompleteSurvey from '../CompleteSurvey';
import Profile from './Profile';
import Icon from '../../common/Icon';

const DashboardProfile = props => {
  const { hasCompletedSurvey, accessLevel } = props;
  const hasPremiumAccess = accessLevel.some(level => level.startsWith('premium'));

  return (
    <div className="px-6 py-4 pb-8 bg-white rounded-lg md:pl-10 md:pb-10">
      <div className="flex items-center gap-4">
        <div className="flex items-center justify-center p-2 rounded-full w-9 h-9 bg-ivory-400">
          <Icon icon="profile" iconColor="gray" />
        </div>
        <h2 className="my-2 font-serif text-2xl antialiased font-thin tracking-wide sm:my-4 text-charcoal-500">
          GlobeSmart Profile
        </h2>
      </div>
      {hasCompletedSurvey && hasPremiumAccess
        ? <Profile {...props} />
        : <CompleteSurvey />}
    </div>
  );
};

DashboardProfile.propTypes = {
  hasCompletedSurvey: PropTypes.bool.isRequired,
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })),
  profile: PropTypes.oneOfType([
    PropTypes.shape({
      avatarUrl: PropTypes.string.isRequired,
      grid: PropTypes.shape({
        direct_indirect: PropTypes.number.isRequired,
        egalitarianism_status: PropTypes.number.isRequired,
        independent_interdependent: PropTypes.number.isRequired,
        risk_certainty: PropTypes.number.isRequired,
        task_relationship: PropTypes.number.isRequired,
      }).isRequired,
      dimensionLabelGroups: PropTypes.arrayOf(
        PropTypes.shape({
          left: PropTypes.string.isRequired,
          right: PropTypes.string.isRequired,
        }),
      ).isRequired,
      id: PropTypes.string.isRequired,
      shortDisplayName: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
    PropTypes.shape({}),
  ]),
  accessLevel: PropTypes.arrayOf(PropTypes.string),
};

DashboardProfile.defaultProps = {
  profile: {},
  organizations: [],
  accessLevel: [],
};

export default DashboardProfile;
