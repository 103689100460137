import html2canvas from 'html2canvas';
import JsPdf from 'jspdf';

import imageToDataUrl from '../imageToDataUrl';

export const createProfileDownload = async (targetElem, fileName) => {
  const options = {
    allowTaint: true,
    removeContainer: true,
    backgroundColor: null,
    logging: true,
    useCORS: true,
  };

  const imageElement = targetElem.getElementsByTagName('img');
  if (imageElement?.length > 1) {
    await imageToDataUrl(imageElement[1]);
  }

  const polyLine = targetElem.getElementsByTagName('polyline');
  const polyLinePoints = polyLine[0].getAttribute('points');
  // Split the points attribute into an array of points
  const pointsArray = polyLinePoints.split(', ');
  // Modify the y-coordinate of each point to move the polyline up by 10 pixels
  const updatedPoints = pointsArray.map(point => {
    const [x, y] = point.split(' ').map(parseFloat);
    return `${x} ${y - 10},`;
  });
    // Join the updated points back into a string and slice off the final comma
  const newPointsAttribute = updatedPoints.join(' ').slice(0, -1);
  // Set the new 'points' attribute on the polyline element
  polyLine[0].setAttribute('points', newPointsAttribute);

  const canvas = await html2canvas(targetElem, options);
  const data = canvas.toDataURL('image/png');
  const pdf = new JsPdf('p', 'pt', 'a4', true);
  const imgProperties = pdf.getImageProperties(data);
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
  pdf.addImage(data, 'PNG', 0, 15, pdfWidth, pdfHeight);
  pdf.save(fileName);
};
