import React from 'react';

import { propTypes } from './types';

import { Context } from '../../../context/ContextProvider';

import EmailValidateAction from '../../../actions/emailValidate';
import { COOKIE_NOTIFICATION, IS_PRODUCTION } from '../../../lib/constants';

// This is a non-visual component. It only exists to
// make API requests and redirect people as needed.

// PureComponent to prevent re-render and second API call
class ValidateEmail extends React.PureComponent {
  static contextType = Context;

  render() {
    const { params } = this.props;
    const { currentUser, router } = this.context;

    // If user is authenticated show message on the dashboard page
    let redirectRoute = '/dashboard';
    // If user is not authenticated show message on the login page
    // TODO: this line is from `needsAuthentication/index.js`.
    // Maybe refactor that helper to optionally NOT redirect.
    if (!currentUser || !currentUser.isLoggedIn()) {
      redirectRoute = '/login';
    }

    new EmailValidateAction(this.context.apiService)
      .execute(params.token)
      .then(() => {
        const message = 'Your email has been verified.';
        this.context.cookies.set(
          COOKIE_NOTIFICATION,
          { type: 'success', message },
          { path: '/', secure: IS_PRODUCTION },
        );

        router.push(redirectRoute);
      })
      .catch(err => {
        let message = 'There was an issue validating your email address. Please try again later.';

        if (err.reason && err.reason.status === 400) {
          message = "We're sorry, but your email validation token was not found.";
        }

        this.context.cookies.set(
          COOKIE_NOTIFICATION,
          { type: 'failure', message },
          { path: '/', secure: IS_PRODUCTION },
        );
        router.push(redirectRoute);
      });

    return null;
  }
}

ValidateEmail.propTypes = propTypes;

export default ValidateEmail;
export {
  ValidateEmail as Component,
};
