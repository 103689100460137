import { BadRequestError } from '../../../../lib/errors';

function groupAddRaterAction(apiService, groupId, memberId, data) {
  return apiService.post(`assessments/raters/group/${groupId}/member/${memberId}`, data)
    .catch(err => {
      if (err instanceof BadRequestError) { return err.reason; }
      throw err;
    });
}

export default groupAddRaterAction;
