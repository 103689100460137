import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { NotificationContext } from '../../../context/Notification';
import { APIContext } from '../../../context/API';

import withServerSideData from '../../../HOC/withServerSideData';

import { updateCohortAction } from '../../../actions/cohorts';

import CohortForm from '../../../components/Cohorts/CohortForm';
import BackArrowLink from '../../../components/common/BackArrowLink';

const CohortsEdit = ({ initialData, params: { code } }) => {
  const { apiService } = useContext(APIContext);

  const {
    notificationMessage,
    removeNotification,
    addNotification,
  } = useContext(NotificationContext);

  useEffect(() => {
    let timeout;
    if (notificationMessage) {
      timeout = window.setTimeout(() => {
        removeNotification();
      }, 5000);
    }
    return () => {
      window.clearTimeout(timeout);
      if (notificationMessage) removeNotification();
    };
  }, [notificationMessage]);

  const formSubmit = async cohortData => {
    try {
      await updateCohortAction(apiService, code, cohortData);
      addNotification({
        type: 'success',
        message: 'Cohort updated',
      });
    } catch (err) {
      addNotification({
        type: 'failure',
        message: `Error: ${err.message}`,
      });
    }
  };

  return (
    <>
      <div className="flex mx-0 mt-0 mb-3 text-center align-self-start text-uppercase text-md">
        <BackArrowLink to={`/cohorts/${code}`}>
          Back to the Cohort Overview
        </BackArrowLink>
      </div>
      <CohortForm
        cohortFormData={initialData}
        handleFormSubmit={formSubmit}
        buttonText="Update Cohort"
        maxWidth
      />
    </>
  );
};

CohortsEdit.getAPIDataKey = () => 'cohortDetails';
CohortsEdit.getData = (apiService, { code }) => apiService.get(`cohorts/${code}`)
  .then(cohortDetails => ({ cohortDetails }));

CohortsEdit.propTypes = {
  initialData: PropTypes.shape({
    cohort: PropTypes.shape({
      name: PropTypes.string,
      code: PropTypes.string,
      type: PropTypes.number,
      org: PropTypes.string,
      orgName: PropTypes.string,
      sfJobCode: PropTypes.string,
      sfLink: PropTypes.string,
      redirectUrl: PropTypes.string,
      linkExpiryDate: PropTypes.string,
      stripePlans: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          value: PropTypes.string,
        }),
      ),
    }),
    plans: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    types: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.number,
      })),
  }).isRequired,
  params: PropTypes.shape({
    code: PropTypes.string,
  }).isRequired,
};

export default withServerSideData(CohortsEdit);
