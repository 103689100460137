import tracker from '../index';

const { eventNames, eventProps, resolveAfterTimeout } = tracker;

const trackLearningModuleHomeViewed = () => {
  tracker.track(eventNames.VIEW_LEARNING_MODULE_HOME);

  return resolveAfterTimeout;
};

const trackLMBlocksSearch = searchTerm => {
  tracker.track(eventNames.LM_SEARCH, {
    [eventProps.SEARCH_TERM]: searchTerm,
  });

  return resolveAfterTimeout;
};

export { trackLearningModuleHomeViewed, trackLMBlocksSearch };
