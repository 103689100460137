/* eslint-disable react/function-component-definition */
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

export const NotificationContext = React.createContext({
  notificationMessage: null,
  addNotification: () => { },
  removeNotification: () => { },
});

export default function NotificationProvider({ children }) {
  const [notificationMessage, setNotificationMessage] = useState(null);

  const removeNotification = () => setNotificationMessage(null);

  const addNotification = ({ message, type }) => setNotificationMessage({ message, type });

  const contextValue = {
    notificationMessage,
    addNotification: useCallback(({ message, type }) => addNotification({ message, type }), []),
    removeNotification: useCallback(() => removeNotification(), []),
  };

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  );
}

NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const NotificationConsumer = NotificationContext.Consumer;
