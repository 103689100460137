import React from 'react';
import { iconPropTypes, defaultIconProps } from './types';

import { fillColorMap, strokeColorMap } from '../index.js';

const Bars3Icon = ({ className, iconColor }) => (
  <svg className={`${className} ${fillColorMap[iconColor]} ${strokeColorMap[iconColor]}`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 12H15M3 6H21M3 18H21" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

Bars3Icon.propTypes = iconPropTypes;
Bars3Icon.defaultProps = defaultIconProps;

export default Bars3Icon;
