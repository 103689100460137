import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import CurrentTeamOwnerCard from './CurrentTeamOwnerCard';
import ConfirmRemove from './ConfirmRemove';

const TeamOwnerCard = (
  {
    ownerId,
    ownerUserId,
    displayName,
    displayDescription,
    ownerLength,
    removeOwner,
    avatarUrl,
  }) => {
  const [confirmRemove, setConfirmRemove] = useState(false);

  useEffect(() => {
    setConfirmRemove(false);
  }, [removeOwner]);

  const handleConfirmRemove = (shouldRemove => setConfirmRemove(shouldRemove));

  return confirmRemove
    ? (
      <ConfirmRemove
        ownerId={ownerId}
        ownerUserId={ownerUserId}
        confirmRemove={handleConfirmRemove}
        removeOwner={removeOwner}
      />
    ) : (
      <CurrentTeamOwnerCard
        displayName={displayName}
        displayDescription={displayDescription}
        ownerLength={ownerLength}
        setRemove={handleConfirmRemove}
        ownerUserId={ownerUserId}
        avatarUrl={avatarUrl}
      />
    );
};

TeamOwnerCard.propTypes = {
  ownerId: PropTypes.number.isRequired,
  ownerUserId: PropTypes.string,
  displayName: PropTypes.string.isRequired,
  displayDescription: PropTypes.shape({}),
  ownerLength: PropTypes.number,
  removeOwner: PropTypes.func.isRequired,
  avatarUrl: PropTypes.string.isRequired,
};

TeamOwnerCard.defaultProps = {
  ownerUserId: null,
  displayDescription: null,
  ownerLength: 1,
};

export default TeamOwnerCard;
