class AgCohortAdminUserCriteria {
  constructor(user) {
    this.user = user;
  }

  test() {
    return !!(this.user.accessLevel.indexOf('ag-cohort-admin') >= 0);
  }
}

export default AgCohortAdminUserCriteria;
