import PropTypes from 'prop-types';

export const skillPropTypes = {
  id: PropTypes.number.isRequired,
  skillName: PropTypes.string.isRequired,
  iconImage: PropTypes.string.isRequired,
};

export const activityPropTypes = {
  id: PropTypes.number.isRequired,
  activityName: PropTypes.string.isRequired,
  iconImage: PropTypes.string.isRequired,
};

export const progressPropTypes = {
  id: PropTypes.number.isRequired,
  skillName: PropTypes.string.isRequired,
  completedActivities: PropTypes.number.isRequired,
  totalActivities: PropTypes.number.isRequired,
};

export const getStartedSkillPropTypes = {
  name: PropTypes.string.isRequired,
  iconImage: PropTypes.string.isRequired,
};
