import { isWithinInterval } from 'date-fns';

export default class DateTimeCriteria {
  constructor(startTime = '2017-12-06T00:00:00.000Z', endTime = '2018-02-01T00:00:00.000Z') {
    this.startTime = startTime;
    this.endTime = endTime;
  }

  test() {
    if (!this.startTime || !this.endTime) return false;
    return isWithinInterval(
      new Date(),
      {
        start: new Date(this.startTime),
        end: new Date(this.endTime),
      });
  }
}
