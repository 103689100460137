import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import AdviceItem from '../AdviceItem';

const AdviceGroup = ({ adviceGroupItems }) => (
  adviceGroupItems
    .filter(item => (item.adviceItems.length > 0 || get(item, 'bestPracticeAdvice.length', 0) > 0))
    .map(item => <AdviceItem key={item.header} {...item} />)
);

AdviceGroup.propTypes = {
  adviceGroupItems: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string,
      adviceItems: PropTypes.arrayOf(
        PropTypes.shape({
          explanation: PropTypes.string,
          title: PropTypes.string,
        })),
      bestPracticeAdvice: PropTypes.arrayOf(
        PropTypes.shape(PropTypes.string),
      ),
    }),
  ),
};

AdviceGroup.defaultProps = {
  adviceGroupItems: [],
};

export default AdviceGroup;
