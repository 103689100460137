import React from 'react';
import PropTypes from 'prop-types';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  useSensor,
  useSensors,
  TouchSensor,
  MouseSensor,
} from '@dnd-kit/core';

import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import SortableItem from './SortableItem';

const DraggableCardList = ({ list, updateList, renderItem }) => {
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = event => {
    const { active, over } = event;
    if (over && (active.id !== over.id)) {
      const oldIndex = list.findIndex(c => c.id === active.id);
      const newIndex = list.findIndex(c => c.id === over.id);
      const newCards = arrayMove(list, oldIndex, newIndex);
      updateList(newCards);
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      id="0"
    >
      <SortableContext
        items={list}
        strategy={verticalListSortingStrategy}
      >
        {list.map(item => (
          <SortableItem key={item.id} id={item.id}>
            {renderItem(item)}
          </SortableItem>
        ))}
      </SortableContext>
    </DndContext>
  );
};

DraggableCardList.propTypes = {
  updateList: PropTypes.func.isRequired,
  renderItem: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({}),
  ).isRequired,
};

export default DraggableCardList;
