import React from 'react';
import PropTypes from 'prop-types';

import TrianglesIcon from './TrianglesIcon';
import HalfCirclesIcon from './HalfCirclesIcon';
import CircleHalfCirclesIcon from './CircleHalfCirclesIcon';
import CirclesQuarterCirclesIcon from './CirclesQuarterCirclesIcon';

const TrackIcon = ({ trackId, isSelected }) => {
  switch (trackId) {
    case 1:
      return (
        <TrianglesIcon
          className1={`fill-[#757575] group-hover:fill-rust-500${isSelected ? ' !fill-rust-500' : ''}`}
          className2={`fill-[#A9A9A9] group-hover:fill-rust-100${isSelected ? ' !fill-rust-100' : ''}`}
        />
      );
    case 2:
      return (
        <HalfCirclesIcon
          className1={`fill-[#868686] group-hover:fill-rust-500${isSelected ? ' !fill-rust-500' : ''}`}
          className2={`fill-[#C7C7C7] group-hover:fill-rust-100${isSelected ? ' !fill-rust-100' : ''}`}
        />
      );
    case 3:
      return (
        <CircleHalfCirclesIcon
          className1={`fill-[#757575] group-hover:fill-rust-500${isSelected ? ' !fill-rust-500' : ''}`}
          className2={`fill-[#868686] group-hover:fill-rust-300${isSelected ? ' !fill-rust-300' : ''}`}
          className3={`fill-[#979797] group-hover:fill-rust-100${isSelected ? ' !fill-rust-100' : ''}`}
        />
      );
    case 4:
      return (
        <CirclesQuarterCirclesIcon
          className1={`fill-[#757575] group-hover:fill-rust-500${isSelected ? ' !fill-rust-500' : ''}`}
          className2={`fill-[#A9A9A9] group-hover:fill-rust-100${isSelected ? ' !fill-rust-100' : ''}`}
        />
      );
    default:
      return (
        <HalfCirclesIcon
          className1={`fill-[#868686] group-hover:fill-rust-500${isSelected ? ' !fill-rust-500' : ''}`}
          className2={`fill-[#C7C7C7] group-hover:fill-rust-100${isSelected ? ' !fill-rust-100' : ''}`}
        />
      );
  }
};

TrackIcon.propTypes = {
  trackId: PropTypes.number.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default TrackIcon;
