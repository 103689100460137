import React from 'react';
import PropTypes from 'prop-types';

const AccessCodeUseCard = props => {
  const {
    firstName,
    lastName,
    email,
    redeemed,
    stripePlanId,
    surveyResponse,
    assessmentResponse,
  } = props;

  let surveyStatus;
  if (stripePlanId.includes('a-ibi')) {
    surveyStatus = `IBI Status: ${assessmentResponse ? assessmentResponse.state : 'not started'}`;
  } else {
    surveyStatus = `GSP Status: ${surveyResponse ? surveyResponse.state : 'not started'}`;
  }

  return (
    <div className="card">
      <div className="card__container card__container--confirm">
        <div className="card__content">
          <div className="card__heading">{firstName} {lastName}</div>
          <div>{email}</div>
          <div>{surveyStatus}</div>
        </div>
        <div className="card__actions">
          {redeemed}
        </div>
      </div>
    </div>
  );
};

AccessCodeUseCard.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  redeemed: PropTypes.string.isRequired,
  stripePlanId: PropTypes.string.isRequired,
  surveyResponse: PropTypes.shape({
    state: PropTypes.string.isRequired,
  }),
  assessmentResponse: PropTypes.shape({
    state: PropTypes.string.isRequired,
  }),
};

AccessCodeUseCard.defaultProps = {
  surveyResponse: {},
  assessmentResponse: {},
};

export default AccessCodeUseCard;
