import React from 'react';
import ElementQuery from 'react-eq';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const BodyWrapper = props => (
  <div className="h-full">
    <Helmet>
      <title>{props.documentTitle}</title>
      <body className="bg-ivory-400" />
    </Helmet>
    <div className="flex items-center justify-center min-h-screen py-8 m-auto">
      <ElementQuery
        queries={{
          'container-medium': 360,
          'container-large': 460,
        }}
      >
        <div className={`px-6 py-10 md:px-10 bg-white rounded-2xl ${!props.containerClasses ? 'flex flex-col gap-4 w-[90%] max-w-md' : props.containerClasses}`}>
          {props.children}
        </div>
      </ElementQuery>
    </div>
  </div>
);

BodyWrapper.propTypes = {
  containerClasses: PropTypes.string,
  children: PropTypes.node,
  documentTitle: PropTypes.string,
};
BodyWrapper.defaultProps = {
  containerClasses: null,
  children: null,
  documentTitle: 'Aperian Global',
};

export default BodyWrapper;
