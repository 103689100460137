import React from 'react';
import { Link } from 'react-router';
import { capitalize } from 'lodash';

import { propTypes } from './types';

// eslint-disable-next-line react/prop-types
const Message = ({ type, id, name, assessment, asmtType }) => {
  switch (type) {
    case 'group':
      return (
        <>
          You are now part of the {assessment} Group, {name}.&nbsp;
          <Link className="text-rust-500" to={`/assessments/${asmtType}`}>Go to the {assessment} page.</Link>
        </>
      );
    case 'org':
      return (
        <>
          You are now the member of {name}. If you are new to the GlobeSmart Profile, learn about <Link className="text-rust-500" to="/profile"> your own Profile first</Link>.
        </>
      );
    default: {
      const query = type === 'team' ? `teams_average=${id}&teams_members=${id}` : `person=${id}`;
      return (
        <>
          You are now connected to {name}.&nbsp;
          <Link
            className="text-rust-500"
            to={`/profile/comparison?${query}`}
          >View this {capitalize(type)} comparison
          </Link>. If you are new to the GlobeSmart Profile, learn about
          <Link to="/profile" className="text-rust-500"> your own Profile first</Link>.
        </>
      );
    }
  }
};

const NewConnection = ({ location: { query } }) => {
  const name = query.connectionName;
  const id = query.connectionId;
  const type = query.connectionType;
  const isComplete = () => !!(name && id && type);

  if (!isComplete()) return null;

  return (
    <div className="notification-message">
      <p className="notification-message__text">
        <Message
          type={type}
          id={id}
          name={name}
          assessment={query.assessment || ''}
          asmtType={query.assessmentType || ''}
        />
      </p>
    </div>
  );
};

NewConnection.propTypes = propTypes;

export default NewConnection;
