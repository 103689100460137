class MySkillsCriteria {
  constructor(user) {
    this.user = user;
  }

  test() {
    return this.user?.allowSkillsTracking === true;
  }
}

export default MySkillsCriteria;
