class CompleteRegistrationAction {
  constructor(apiService) {
    this.apiService = apiService;
  }

  execute(user) {
    return this.apiService.patch('users/me', { user });
  }
}

export default CompleteRegistrationAction;
