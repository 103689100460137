import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node,
  leadingButtonIcon: PropTypes.string,
  trailingButtonIcon: PropTypes.string,
  iconColor: PropTypes.string,
  disabledIconColor: PropTypes.string,
};

const defaultProps = {
  children: null,
  leadingButtonIcon: '',
  trailingButtonIcon: '',
  iconColor: 'black',
  disabledIconColor: 'gray',
};

export {
  propTypes,
  defaultProps,
};
