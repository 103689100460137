import React from 'react';
import { Helmet } from 'react-helmet';
import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import SkipTrigger from '../SkipTrigger';
import BackArrowLink from '../common/BackArrowLink';
import Icon from '../common/Icon';

const iconSize = icon => {
  switch (icon) {
    case 'aperian-live': return 'w-12 h-8 lg:w-14 lg:h-10';
    default: return 'w-8 h-8 lg:h-10 lg:w-10';
  }
};

const PageHeader = ({
  bgColor,
  pageTitle,
  backLink,
  icon,
  skipTarget,
  hideHeading,
  isBreakout,
  isFullWidth,
}) => {
  const titleWidth = classNames({
    'py-6 lg:py-8 print:hidden': true,
    breakout: isBreakout,
    'full-width print:block print:hidden': isFullWidth,
  });

  return (
    <div className={`full-width ${bgColor}`}>
      <Helmet>
        <title>{pageTitle} | Aperian</title>
      </Helmet>
      {skipTarget && <SkipTrigger target={skipTarget} text={`Skip to ${pageTitle}`} />}
      {hideHeading
        ? null
        : (
          <div className={twMerge(titleWidth)}>
            {backLink && <BackArrowLink to={backLink.to}>{backLink.text}</BackArrowLink>}
            <div className="inline-flex gap-4">
              {icon && <Icon icon={icon} iconColor="gray" className={iconSize(icon)} />}
              <h1 className="m-0 font-serif text-2xl md:text-3xl lg:text-4xl text-charcoal-900">
                {pageTitle}
              </h1>
            </div>
          </div>
        )}
    </div>
  );
};

PageHeader.propTypes = {
  bgColor: PropTypes.string,
  pageTitle: PropTypes.string.isRequired,
  skipTarget: PropTypes.string,
  icon: PropTypes.string,
  backLink: PropTypes.shape({
    to: PropTypes.string,
    text: PropTypes.string,
  }),
  hideHeading: PropTypes.bool,
  isBreakout: PropTypes.bool,
  isFullWidth: PropTypes.bool,
};

PageHeader.defaultProps = {
  bgColor: null,
  icon: null,
  skipTarget: null,
  backLink: null,
  hideHeading: false,
  isBreakout: false,
  isFullWidth: false,
};

export default PageHeader;
