class GetPlansAction {
  constructor(apiService) {
    this.apiService = apiService;
  }

  execute() {
    return this.apiService.get('plans');
  }
}

export default GetPlansAction;
