import tracker from '..';

const {
  eventNames,
  eventProps,
  resolveAfterTimeout,
} = tracker;

const trackViewManageConnections = () => {
  tracker.track(eventNames.VIEW_MANAGE_CONNECTIONS);

  return resolveAfterTimeout;
};

const trackRemoveConnection = ({ email, teammate }) => {
  tracker.track(eventNames.REMOVE_CONNECTION, {
    [eventProps.EMAIL_OF_USER_REMOVED]: email,
    [eventProps.TEAMMATE]: teammate,
  });

  return resolveAfterTimeout;
};

export {
  trackViewManageConnections,
  trackRemoveConnection,
};
