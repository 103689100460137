import React from 'react';
import Button from '../common/Button';
import BodyWrapper from '../BodyWrapper';
import AperianWordmark from '../AperianWordmark';
import RustDividingLine from '../common/RustDividingLine';

const NotFound = () => (
  <BodyWrapper documentTitle="Not Found | Aperian">
    <div className="flex items-center justify-center mb-6">
      <AperianWordmark />
    </div>
    <div>
      <h1 className="mb-2 text-2xl font-normal font-headline">That&apos;s not right.</h1>
      <RustDividingLine />
      <p className="my-4 font-sans text-xs font-normal">It looks like the page you are trying to access does not exist.</p>
    </div>
    <Button to="/dashboard" variant="primary">Return to Dashboard</Button>
  </BodyWrapper>
);

export default NotFound;
