class SearchOrganizationsAction {
  constructor(apiService) {
    this.apiService = apiService;
  }

  execute({ name }) {
    return this.apiService.get(`organizations?term=${name}`);
  }
}

export default SearchOrganizationsAction;
