import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { CurrentUserContext } from '../../../context/CurrentUser';
import { ModalContext } from '../../../context/Modal';

import Payment from '../../Payment';
import Button from '../../common/Button';

import PremiumFeature from '../../../lib/features/Premium';
import { GS_PREMIUM_STRIPE_PLAN } from '../../../lib/constants';
import { prefixHostname } from '../../../lib/cdn';

const SurveyLink = () => (
  <Button
    onClick={() => { window.location.href = '/profile/survey'; }}
    trailingButtonIcon="arrow-right"
    variant="primary"
  >
    Take the GlobeSmart Profile Survey
  </Button>
);

const UpgradeButton = ({ handleOpenModal, handleCloseModal }) => (
  <Button
    onClick={() => handleOpenModal({
      content: <Payment
        planId={GS_PREMIUM_STRIPE_PLAN}
        handleCloseModal={handleCloseModal}
      />,
    })}
    trailingButtonIcon="arrow-right"
    variant="primary"
    className="mx-auto lg:mx-0"
  >Upgrade to Take the Survey
  </Button>
);

const CompleteSurvey = () => {
  const { currentUser } = useContext(CurrentUserContext);
  const { handleOpenModal, handleCloseModal } = useContext(ModalContext);
  return (
    <div className="flex flex-col items-center justify-between md:flex-row">
      <div className="w-full pt-4">
        <img
          className="w-full h-auto m-auto md:w-auto"
          src={prefixHostname('/images/default-profile-chart.gif')}
          alt="Culture Guide Screenshot"
        />
      </div>
      <div className="flex-grow">
        <div className="px-4 mb-8 ">
          <h3 className="mb-4 font-serif text-4xl font-semibold leading-snug tracking-wider text-charcoal-900">
            It starts with you.
          </h3>
          <p className="font-sans text-xl font-normal md:text-lg">
            No matter what brings you here,
            we always recommend to start learning by creating self-awareness.
          </p>
        </div>
        {new PremiumFeature(currentUser)
          .positive(() => <SurveyLink />)
          .negative(() => (
            <UpgradeButton
              handleOpenModal={handleOpenModal}
              handleCloseModal={handleCloseModal}
            />
          ))
          .execute()}
      </div>
    </div>
  );
};

UpgradeButton.propTypes = {
  handleOpenModal: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};

export default CompleteSurvey;
