import tracker from '..';

const {
  eventNames,
  eventProps,
  resolveAfterTimeout,
} = tracker;

const trackAvatarType = avatarType => {
  tracker.track(eventNames.CHANGE_USER_AVATAR, {
    [eventProps.AVATAR_TYPE]: avatarType,
  });

  return resolveAfterTimeout;
};

export {
  trackAvatarType,
};
