import React from 'react';
import PropTypes from 'prop-types';

import { prefixHostname } from '../../lib/cdn';

const AperianMonogram = props => (
  <img
    className={props.className}
    src={prefixHostname('/images/aperian-monogram-ivory.svg')}
    srcSet={prefixHostname('/images/aperian-monogram-ivory.svg')}
    alt="Aperian&reg;"
  />
);

AperianMonogram.propTypes = {
  className: PropTypes.string,
};
AperianMonogram.defaultProps = {
  className: '',
};

export default AperianMonogram;
