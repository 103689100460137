import React, { Component } from 'react';
import { Link } from 'react-router';

import { propTypes } from './types';
import LabelInput from '../../common/LabelInput';
import Button from '../../common/Button';

class ForgotPasswordForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      errors: {},
    };
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = event => {
    event.preventDefault();

    const { email } = this.state;
    const errors = {};

    if (email.trim() === '') {
      errors.email = 'Email is required';
    }

    this.setState({ errors });

    if (!errors.email) {
      this.props.handleFormSubmit(email);
    }
  };

  renderErrorMessages() {
    const messages = [this.state.errors.email]
      .filter(error => error !== undefined)
      .map(error => <div key={error}>{error}</div>);

    if (messages.length === 0) {
      return null;
    }
    return (
      <p className="label__message label__message--error js-label__message--error">
        {messages}
      </p>
    );
  }

  render() {
    return (
      <div>
        {this.renderErrorMessages()}
        <form
          action="/forgot-password"
          method="POST"
          onSubmit={this.handleSubmit}
        >
          <LabelInput
            id="email"
            name="email"
            iconClass="user"
            labelText="Email"
            labelType="email"
            errorMessage={this.state.errors.email}
            value={this.state.email}
            onChangeValue={this.handleChange}
          />
          <div className="my-4">
            <Button
              variant="primary"
              type="submit"
              isFullWidth
            >
              Continue
            </Button>
          </div>
          <p className="mt-4 mb-0 font-sans text-xs font-normal text-center underline text-rust-500"><Link to="/login">Return to Login</Link>.</p>
        </form>
      </div>
    );
  }
}

ForgotPasswordForm.propTypes = propTypes;

export default ForgotPasswordForm;
