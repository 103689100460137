import PropTypes from 'prop-types';

export const iconPropTypes = {
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  className: PropTypes.string,
};

export const defaultIconProps = {
  icon: 'profile',
  iconColor: 'black',
  className: '',
};
