import React from 'react';
import PropTypes from 'prop-types';

import { membersTypes } from '../../types';

import TeamMemberCard from '../TeamMemberCard';

import formatDate from '../../../../../lib/dateFormatter';

const TeamMemberCards = ({
  isOwner,
  members,
  onRemind,
  onRemove,
  currentUserId,
}) => members.map(({
  id,
  name,
  email,
  dateJoined,
  surveyStatus,
  remindedAt,
  accessCode,
  completionDate,
  avatarUrl,
}) => {
  const description = (isOwner ? (
    <>
      <b>Email: </b> {email} <br />
      <b>Date Joined: </b> {formatDate(dateJoined)} <br />
      <b>Survey Status: </b> {surveyStatus} {completionDate ? `(${formatDate(completionDate)})` : ''} <br />
      {(remindedAt)
        ? (<><b>Reminder Sent: </b> {formatDate(remindedAt, true)}</>)
        : null}
      {(remindedAt && accessCode) ? <br /> : null}
      {(accessCode)
        ? (<><b>Access Code: </b> {accessCode}</>)
        : null}
    </>
  )
    : (<><b>Email: </b> {email} <br /></>));
  return (
    <li key={id} className="profile-group__item">
      <TeamMemberCard
        id={id}
        isOwner={isOwner}
        surveyStatus={surveyStatus}
        displayName={name}
        displayDescription={description}
        onRemind={onRemind}
        onRemove={onRemove}
        onLeaveEnabled={(!isOwner && currentUserId === id)}
        avatarUrl={avatarUrl}
      />
    </li>
  );
});

TeamMemberCards.propTypes = {
  isOwner: PropTypes.bool.isRequired,
  members: membersTypes.isRequired,
  onRemind: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  currentUserId: PropTypes.string.isRequired,
};

export default TeamMemberCards;
