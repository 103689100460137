export const menuItems = [
  {
    to: '/dashboard',
    name: 'Dashboard',
    criteria: 'all',
  },
  {
    to: '/account-settings/summary',
    name: 'Access',
    criteria: 'all',
  },
  {
    to: '/account-settings/personal',
    name: 'Account Settings',
    criteria: 'all',
  },
  {
    to: '/admin-center',
    name: 'Admin Center',
    criteria: 'organizationsOwned',
  },
  {
    to: '/cohorts/search',
    name: 'Cohorts',
    criteria: 'ag-cohort-admin',
  },
  {
    to: '/administration/access-codes/lookup',
    name: 'Access Codes',
    criteria: 'admin',
  },
  {
    to: '/administration/users',
    name: 'Users',
    criteria: 'admin',
  },
  {
    to: '/administration/organizations',
    name: 'Organizations',
    criteria: 'admin',
  },
  {
    to: '/administration/blocks',
    name: 'Blocks',
    criteria: 'admin',
  },
  {
    to: '/administration/aperian-live',
    name: 'Aperian Live Admin',
    criteria: 'admin',
  },
  {
    to: '/administration/trials',
    name: 'Trials',
    criteria: 'admin',
  },
  {
    to: '/logout',
    name: 'Logout',
    criteria: 'all',
  },
];
