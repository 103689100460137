import React from 'react';
import PropTypes from 'prop-types';

import ProfileCardTextWithButton from '../../../../../../ProfileCards/ProfileCardTextWithButton';

const ConfirmDeactivate = ({ completeDeactivate, noDeactivate }) => {
  const options = {
    messageText: 'Are you sure you want to deactivate this access code?',
    confirmText: 'Yes, Deactivate',
    confirmButton: {
      filledColor: 'red',
      isNormalCase: true,
      onClick: completeDeactivate,
    },
    cancelText: 'No',
    cancelButton: {
      isNormalCase: true,
      isCancel: true,
      onClick: noDeactivate,
    },
  };

  return <ProfileCardTextWithButton {...options} />;
};

ConfirmDeactivate.propTypes = {
  completeDeactivate: PropTypes.func.isRequired,
  noDeactivate: PropTypes.func.isRequired,
};

export default ConfirmDeactivate;
