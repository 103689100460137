class OrganizationRemoveOwnerAction {
  constructor(apiService) {
    this.apiService = apiService;
  }

  execute(orgId, userId) {
    return this.apiService.delete(`organizations/${orgId}/owners/${userId}`);
  }
}

export default OrganizationRemoveOwnerAction;
