import React from 'react';

const Checkmark = () => (
  <svg
    className="w-12 h-12 rounded-full animate-popout"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
  >
    <circle
      className="[stroke-dasharray:300] [stroke-dashoffset:300] stroke-[5] stroke-[#5F9B5F] fill-none animate-circle"
      stroke="currentColor"
      cx="25"
      cy="25"
      r="24"
    />
    <polyline
      className="[stroke-dasharray:300] [stroke-dashoffset:-100] stroke-[3] stroke-white fill-none  animate-checkmark"
      points="16,26 22,32 36,18"
    />
  </svg>
);

export default Checkmark;
