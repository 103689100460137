import PropTypes from 'prop-types';

const propTypes = {
  questionText: PropTypes.string.isRequired,
};

const defaultProps = {
  questionText: '',
};

export {
  propTypes,
  defaultProps,
};
