import PropTypes from 'prop-types';

const propTypes = {
  numHidden: PropTypes.number,
  link: PropTypes.string,
};

const defaultProps = {
  numHidden: null,
  link: '',
};

export {
  propTypes,
  defaultProps,
};
