import React from 'react';
import PropTypes from 'prop-types';

const OrgUsersDetails = ({ membersFound, membersNotFound, usersNotInOrg }) => (
  <table className="admin-dashboard-layout__confirm-delete-details">
    <tbody>
      <tr>
        <td className="py-025">
          <b>{`${membersFound.length} users ready to be deleted.`}</b>
        </td>
      </tr>
      {usersNotInOrg.length > 0 && (
        <>
          <tr>
            <td className="py-025">
              <b>{`${usersNotInOrg.length} user${usersNotInOrg.length > 1 ? 's' : ''} not part of this organization:`}</b>
            </td>
          </tr>
          {usersNotInOrg.map(email => (
            <tr key={email}>
              <td className="py-025">
                &emsp;- {email}
              </td>
            </tr>
          ))}
        </>
      )}
      {membersNotFound.length > 0 && (
        <>
          <tr>
            <td>
              <b>{`${membersNotFound.length} email${membersNotFound.length > 1 ? 's' : ''} not found:`}</b>
            </td>
          </tr>
          {membersNotFound.map(email => (
            <tr key={email}>
              <td className="py-025">
                &emsp;- {email}
              </td>
            </tr>
          ))}
        </>
      )}
    </tbody>
  </table>
);

OrgUsersDetails.propTypes = {
  membersFound: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      userId: PropTypes.string,
      email: PropTypes.string,
    }),
  ),
  membersNotFound: PropTypes.arrayOf(PropTypes.string),
  usersNotInOrg: PropTypes.arrayOf(PropTypes.string),
};

OrgUsersDetails.defaultProps = {
  membersFound: [],
  membersNotFound: [],
  usersNotInOrg: [],
};

export default OrgUsersDetails;
