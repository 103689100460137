import React from 'react';
import ProfileChartAvatars from '../ProfileChartAvatars';
import ProfileConnectingLinesContainer from '../ProfileConnectingLinesContainer';
import { defaultTypes, propTypes } from './types';

const ProfileChartAvatarContainer = props => {
  if (typeof props.profiles[0] === 'undefined' || !props.profiles[0].avatarUrl) {
    return null;
  }

  return (
    <div className="avatar-container" ref={props.avatarContainerRef}>
      <ProfileChartAvatars {...props} />
      <ProfileConnectingLinesContainer
        isHistoryProfile={props.isHistoryProfile}
        positions={props.positions}
        myProfileVisibility={props.myProfileVisibility}
        compareProfile={props.compareProfile}
      />
    </div>
  );
};

ProfileChartAvatarContainer.propTypes = propTypes;
ProfileChartAvatarContainer.defaultTypes = defaultTypes;

export default ProfileChartAvatarContainer;
