import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { APIContext } from '../../../../context/API';

import Button from '../../../common/Button';

import { getTrialSurveyQuestions } from '../../../../actions/onboarding';

const BeforeSurveyMessage = ({ handleSetQuestions, nextStep, questions }) => {
  const { apiService } = useContext(APIContext);

  useEffect(() => {
    if (!questions.length) {
      getTrialSurveyQuestions(apiService)
        .then(data => {
          handleSetQuestions(data.questions);
        });
    }
  }, []);

  return (
    <div className="flex flex-col items-center justify-center gap-4 px-2 md:px-8 lg:px-12 md:max-w-[90%]">
      <h1 className="font-serif mb-0 md:mb-2 lg:mb-4 text-4xl tracking-wider md:!leading-[3.5rem] lg:!leading-[4rem] text-charcoal-900 md:text-5xl lg:text-6xl">
        Let&apos;s begin with
        <span className="italic text-rust-500">
          &nbsp;your communication style
        </span>
        &nbsp;so you can see how your learners will begin their journey.
      </h1>
      <p className="mb-0 font-sans text-base leading-8 text-gray-800 md:mb-2 lg:mb-4 md:text-xl">
        It only takes a minute.
      </p>
      <Button
        variant="primary"
        trailingButtonIcon="arrow-right"
        onClick={nextStep}
      >Continue
      </Button>
    </div>
  );
};

BeforeSurveyMessage.propTypes = {
  handleSetQuestions: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      text: PropTypes.string,
    }),
  ),
};

BeforeSurveyMessage.defaultProps = {
  questions: [],
};

export default BeforeSurveyMessage;
