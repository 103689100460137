import React from 'react';
import PropTypes from 'prop-types';
import parser from 'html-react-parser';
import DOMPurify from 'isomorphic-dompurify';

import Icon from '../Icon';

const getIconSize = size => {
  switch (size) {
    case 'xs':
      return 'w-2 h-2';
    case 'md':
      return 'w-3 h-3';
    default:
      return 'w-2.5 h-2.5';
  }
};

const Tag = ({ text, onIconClick, size }) => (
  <li
    key={text}
    className={`flex items-center text-${size} px-2 py-1 font-sans font-medium text-gray-700 border border-gray-300 rounded-md bg-white max-h-7`}
  >
    {parser(DOMPurify.sanitize(text, { ADD_ATTR: ['target'] }))}
    <button
      type="button"
      className="p-1 ml-2 text-gray-400 bg-transparent rounded-sm hover:bg-gray-200 hover:text-gray-900"
      onClick={onIconClick}
    >
      <Icon icon="x-close" iconColor="gray" className={getIconSize(size)} />
    </button>
  </li>
);

Tag.propTypes = {
  text: PropTypes.string.isRequired,
  onIconClick: PropTypes.func.isRequired,
  size: PropTypes.string,
};

Tag.defaultProps = {
  size: 'sm',
};
export default Tag;
