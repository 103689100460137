import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../../common/Button';

const isOwner = (user, owners) => owners.find(owner => owner.id === user.id);

const renderInfo = (users, owners, onAdd, onRemove) => users.map(user => (
  <tr key={user.id} className="even:bg-ivory-500">
    <td className="py-4 pl-0 pr-3 text-sm font-medium text-gray-900 md:pl-4 break-word" data-label="Last Name">
      {user.lastName}
    </td>
    <td className="px-3 py-4 text-sm font-medium text-gray-900 break-word" data-label="First Name">
      {user.firstName}
    </td>
    <td className="px-3 py-4 text-sm font-medium text-gray-900 whitespace-nowrap" data-label="Email Address">
      {user.email}
    </td>
    {isOwner(user, owners) ? (
      <>
        <td className="px-3 py-4 text-sm font-medium text-gray-900 whitespace-nowrap" data-label="Date Added">
          {user.addedDate}
        </td>
        <td className="px-3 py-4 text-sm font-medium text-gray-900 whitespace-nowrap" data-label="Remove Owner">
          <Button isSmall isWarning onClick={e => onRemove(user.id, e)}>Remove</Button>
        </td>
      </>
    ) : (
      <td className="px-3 py-4 text-sm font-medium text-gray-900 whitespace-nowrap" data-label="Add Owner">
        <Button isSmall filledColor="secondary" onClick={e => onAdd(user.id, e)}>Add</Button>
      </td>
    )}
  </tr>
));

const OrganizationUserList = ({
  users,
  owners,
  isOwnersList,
  onAdd,
  onRemove,
}) => {
  if (!users || !users.length) return null;

  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead>
        <tr>
          <th scope="col" className="py-3.5 pl-0 md:pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
            Last Name
          </th>
          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
            First Name
          </th>
          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
            Email
          </th>
          {isOwnersList ? (
            <>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Date Added
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Remove
              </th>
            </>
          ) : (
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Add
            </th>
          )}
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {renderInfo(users, owners, onAdd, onRemove)}
      </tbody>
    </table>
  );
};

OrganizationUserList.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  })),
  owners: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
  })),
  isOwnersList: PropTypes.bool,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
};

OrganizationUserList.defaultProps = {
  users: [],
  owners: [],
  isOwnersList: false,
  onAdd: () => {},
  onRemove: () => {},
};

export default OrganizationUserList;
