import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../common/Icon';

const ShareSessionPopover = props => (
  <div className="divide-y divide-charcoal-900/10 text-charcoal-900">
    {props.profileShareOptions.map(option => (
      <div key={option.id} className="flex justify-start align-center">
        <button
          type="button"
          className="flex items-center w-full p-2 text-sm group hover:bg-charcoal-25 active:bg-charcoal-25 text-charcoal-800"
          onClick={option.onClick}
        >
          <Icon
            icon={option.icon}
            iconColor="primary"
            className="w-5 h-5 mr-2"
          />
          <p className="mb-0 text-sm font-medium">{option.name}</p>
          {option.isCopy && props.isCopied ? (
            <Icon
              icon="check"
              iconColor="green"
              className="w-5 h-5 ml-2"
            />
          ) : null}
        </button>
      </div>
    ))}
  </div>
);

ShareSessionPopover.propTypes = {
  profileShareOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      onClick: PropTypes.func,
      icon: PropTypes.string,
    }),
  ).isRequired,
  isCopied: PropTypes.bool,
};

ShareSessionPopover.defaultProps = {
  isCopied: false,
};

export default ShareSessionPopover;
