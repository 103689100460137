/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'isomorphic-dompurify';

import { extractEmails } from '../../../lib/inputValidation/extractEmail';

import Button from '../../common/Button';
import LabelTextarea from '../../common/LabelTextarea';
import SectionContainer from '../../common/Containers/Section';
import DropdownSelect from '../../common/Dropdowns/DropdownSelect';

const InvitationForm = (
  {
    sendMessage,
    handleFormSubmit,
    legalNotice,
    customMessage: cusMsg,
    isCustomMessageVisible,
    isPersonalInvitation,
    invitationType,
    accessCodes,
    children,
    defaultAccessCode,
    handleCloseModal,
    hasCancel,
  },
) => {
  const [textareaError, setTextareaError] = useState('');
  const [customMessage, setCustomMessage] = useState(cusMsg);
  const [personalCustomMessage, setPersonalCustomMessage] = useState('');
  const [showPersonalCustomMessage, setShowPersonalCustomMessage] = useState(false);
  const [emails, setEmails] = useState('');
  const [selectedAccessCode, setSelectedAccessCode] = useState(defaultAccessCode);

  // updating value functionality
  const handleCustomMessageChange = e => {
    setCustomMessage(e.target.value);
  };

  const handleInvitationChange = e => {
    setTextareaError('');

    const enteredEmails = e.target.value;
    setEmails(enteredEmails);
  };

  const updateAccessCode = e => {
    setSelectedAccessCode(e.target.value);
  };

  const updatePersonalCustomMessage = e => setPersonalCustomMessage(e.target.value);

  // show/render functionality
  const renderSendButtonText = () => {
    let buttonText = sendMessage;
    if (emails.split(',').length > 1 || emails.split('\n').length > 1) {
      buttonText = `${buttonText}${'s'}`;
    }

    return buttonText;
  };

  useEffect(() => {
    renderSendButtonText();
  }, [emails]);

  const determineLabelAction = () => {
    if (!showPersonalCustomMessage && !personalCustomMessage.length) return 'Add';
    if (!showPersonalCustomMessage && personalCustomMessage.length) return 'View';
    if (showPersonalCustomMessage) return 'Hide';
    return null;
  };
  const toggleShowCustomMessage = () => setShowPersonalCustomMessage(!showPersonalCustomMessage);

  // submit
  const handleSubmit = e => {
    e.preventDefault();

    const extractedEmails = extractEmails(emails);
    if (extractedEmails instanceof Error) {
      setTextareaError(extractedEmails.message);
      return;
    }
    const newInvitations = extractedEmails.map(email => ({
      email,
      accessCode: selectedAccessCode,
      customMessage: isPersonalInvitation
        ? DOMPurify.sanitize(personalCustomMessage, { USE_PROFILES: [] })
        : null,
    }));
    setTextareaError('');
    handleFormSubmit({
      invitations: newInvitations,
      customMessage: DOMPurify.sanitize(customMessage, { USE_PROFILES: [] }),
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      {children}
      {isCustomMessageVisible
        && (
          <SectionContainer className="mb-4" title="Custom Message" isFloating>
            <LabelTextarea
              id="customMessage"
              labelText="Add a Custom Message to Your Invitations"
              placeholder="Add Your Own Message..."
              customClass="textarea__custom-message"
              onChangeValue={handleCustomMessageChange}
              value={DOMPurify.sanitize(customMessage, { USE_PROFILES: [] })}
            />
          </SectionContainer>
        )}
      <SectionContainer className="mb-4" title={`${invitationType}`} isFloating>
        <LabelTextarea
          id="emailinvitations"
          labelText="Enter Email Addresses"
          placeholder="Separate addresses by commas, new lines, or paste a spreadsheet column..."
          onChangeValue={handleInvitationChange}
          value={emails}
          errorMessage={textareaError}
        />
        {accessCodes.length > 0
        && (
          <DropdownSelect
            id="accessCode"
            name="accessCode"
            labelText="Select Access Code"
            value={selectedAccessCode}
            options={accessCodes}
            onChangeValue={updateAccessCode}
          />
        )}
        {
          (isPersonalInvitation)
            ? (
              <div className="pt-1 mb-1">
                <div className="py-1 profile-group__heading">
                  <button
                    type="button"
                    className="profile-dimension__toggle"
                    onClick={toggleShowCustomMessage}
                  >
                    {determineLabelAction()} Custom Message
                  </button>
                </div>
                {(showPersonalCustomMessage)
                  && (
                    <LabelTextarea
                      id="customMessage"
                      labelText="Add a Custom Message to Your Invitation"
                      placeholder="Add Your Own Message..."
                      customClass="textarea__custom-message"
                      onChangeValue={updatePersonalCustomMessage}
                      value={personalCustomMessage}
                    />
                  )}
              </div>
            )
            : null
        }
      </SectionContainer>
      {legalNotice && (
        <p className="my-4 text-sm italic font-normal text-center text-charcoal-500 text-max-width">
          {legalNotice}
        </p>
      )}
      <div className="flex flex-wrap-reverse items-center justify-end gap-2 pt-3 md:flex-nowrap">
        {hasCancel ? (
          <Button
            onClick={handleCloseModal}
            isSmall
            isNormalCase
            isWarning
          >
            Cancel
          </Button>
        ) : null}
        <Button
          variant="primary"
          type="submit"
          isSmall
          isNormalCase
          disabled={emails.length === 0}
        >
          {renderSendButtonText()}
        </Button>
      </div>
    </form>
  );
};

InvitationForm.propTypes = {
  sendMessage: PropTypes.string,
  handleFormSubmit: PropTypes.func.isRequired,
  legalNotice: PropTypes.string,
  customMessage: PropTypes.string,
  isCustomMessageVisible: PropTypes.bool,
  isPersonalInvitation: PropTypes.bool,
  invitationType: PropTypes.string,
  accessCodes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  children: PropTypes.node,
  defaultAccessCode: PropTypes.string,
  handleCloseModal: PropTypes.func,
  hasCancel: PropTypes.bool,
};

InvitationForm.defaultProps = {
  sendMessage: 'Submit',
  legalNotice: null,
  customMessage: '',
  isCustomMessageVisible: false,
  isPersonalInvitation: false,
  accessCodes: [],
  invitationType: 'Invitation',
  children: '',
  defaultAccessCode: '',
  handleCloseModal: () => {},
  hasCancel: false,
};

export default InvitationForm;
