import React from 'react';
import PropTypes from 'prop-types';

import ProfileCardTextWithButtons from '../../../../../../../ProfileCards/ProfileCardTextWithButton';

const ConfirmRemove = ({
  raterId,
  raterName,
  handleRemoveRater,
  handleConfirmRemove,
  token,
  raterSurveyStatus,
}) => {
  const options = {
    type: 'button',
    messageText: `Are you sure you want to remove ${raterName}? ${raterSurveyStatus === 'finished'
      ? ' This will remove this person\'s scores from the overall Rater scores.'
      : ''}`,
    confirmText: 'Yes, Remove',
    confirmButton: {
      isWarning: true,
      isNormalCase: true,
      onClick: () => handleRemoveRater(raterId),
    },
    cancelText: 'No, Keep',
    cancelButton: {
      isNormalCase: true,
      variant: 'secondary',
      onClick: () => handleConfirmRemove(false),
    },
  };

  return <ProfileCardTextWithButtons {...options} token={token} withDrawer />;
};

ConfirmRemove.propTypes = {
  raterName: PropTypes.string.isRequired,
  raterId: PropTypes.number.isRequired,
  handleRemoveRater: PropTypes.func.isRequired,
  handleConfirmRemove: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  raterSurveyStatus: PropTypes.string,
};

ConfirmRemove.defaultProps = {
  raterSurveyStatus: null,
};

export default ConfirmRemove;
