import React from 'react';
import PropTypes from 'prop-types';

import ProfileCardTextWithButton from '../../../../../ProfileCards/ProfileCardTextWithButton';

const ConfirmLeave = (
  {
    token,
    id,
    onLeaveEnabled,
    displayName,
    handleOnRemove,
    handleConfirmRemove,
  }) => {
  let options = {
    messageText: `Are you sure you want to remove ${displayName}?`,
    confirmText: 'Yes, Remove',
    confirmButton: {
      isWarning: true,
      isNormalCase: true,
      onClick: () => handleOnRemove({ memberId: id, redirect: onLeaveEnabled }),
    },
    cancelText: 'No, Keep',
    cancelButton: {
      isNormalCase: true,
      variant: 'secondary',
      onClick: () => handleConfirmRemove(false),
    },
  };
  if (onLeaveEnabled) {
    options = {
      ...options,
      messageText: 'Are you sure you want to leave this team?',
      confirmText: 'Yes, Leave',
      cancelText: 'No, Stay',
    };
  }

  return <ProfileCardTextWithButton {...options} token={token} />;
};

ConfirmLeave.propTypes = {
  token: PropTypes.string,
  id: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  handleOnRemove: PropTypes.func.isRequired,
  handleConfirmRemove: PropTypes.func.isRequired,
  onLeaveEnabled: PropTypes.bool,
};

ConfirmLeave.defaultProps = {
  token: null,
  onLeaveEnabled: false,
};

export default ConfirmLeave;
