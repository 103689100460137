import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';

import { prefixHostname } from '../../../lib/cdn';

import NavigationLinks from './NavigationLinks';
import Icon from '../../common/Icon';

const MobileSidebar = (
  {
    sidebarOpen,
    handleSidebarOpen,
    location,
    accessLevel,
    premiumUser,
    hasCompletedIBI,
    allowGSPShowing,
  },
) => (
  <Transition.Root show={sidebarOpen} as={Fragment}>
    <Dialog as="div" className="relative z-[500] lg:hidden" onClose={handleSidebarOpen}>
      <Transition.Child
        as={Fragment}
        enter="transition-opacity ease-linear duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-charcoal-900/80" />
      </Transition.Child>

      <div className="fixed inset-0 flex">
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <Dialog.Panel className="relative flex flex-1 w-full max-w-xs mr-16">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute top-0 flex justify-center w-16 pt-5 left-full">
                <button
                  type="button"
                  className="-m-2.5 p-2.5"
                  onClick={() => handleSidebarOpen(false)}
                >
                  <span className="sr-only">Close sidebar</span>
                  <Icon icon="x-close" iconColor="gray" className="w-4 h-4 text-white" aria-hidden="true" />
                </button>
              </div>
            </Transition.Child>
            <div className="flex flex-col pb-4 pr-6 overflow-y-auto bg-gray-900 grow gap-y-5 ring-1 ring-white/10">
              <Link to="/dashboard" onClick={() => handleSidebarOpen(false)} className="flex items-center h-16 pl-6 shrink-0">
                <img
                  className="w-auto h-6"
                  src={prefixHostname('/images/aperian-wordmark-ivory.svg')}
                  srcSet={prefixHostname('/images/aperian-wordmark-ivory.svg')}
                  alt="Aperian&reg;"
                  width={50}
                  height={50}
                />
              </Link>
              <nav className="flex flex-col flex-1">
                <ul className="flex flex-col flex-1 gap-y-7">
                  <li>
                    <ul className="-mx-2 space-y-1">
                      <NavigationLinks
                        accessLevel={accessLevel}
                        location={location}
                        premiumUser={premiumUser}
                        hasCompletedIBI={hasCompletedIBI}
                        handleSidebarOpen={handleSidebarOpen}
                        allowGSPShowing={allowGSPShowing}
                      />
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>
          </Dialog.Panel>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition.Root>
);

MobileSidebar.propTypes = {
  sidebarOpen: PropTypes.bool.isRequired,
  handleSidebarOpen: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  accessLevel: PropTypes.arrayOf(PropTypes.string).isRequired,
  premiumUser: PropTypes.bool.isRequired,
  hasCompletedIBI: PropTypes.bool.isRequired,
  allowGSPShowing: PropTypes.bool,
};

MobileSidebar.defaultProps = {
  handleSidebarOpen: null,
  allowGSPShowing: true,
};

export default MobileSidebar;
