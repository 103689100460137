import React, { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { findIndex, cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

import withServerSideData from '../../../HOC/withServerSideData';

import { useConstructor } from '../../../hooks';

import { CurrentUserContext } from '../../../context/CurrentUser';
import { APIContext } from '../../../context/API';
import { RouterContext } from '../../../context/Router';
import { LanguageContext } from '../../../context/Language';
import { ToastContext } from '../../../context/Toast';

import SurveyQuestionLayout from '../SurveyQuestionLayout';
import Footer from '../../common/Footer';

import SurveyQuestionResponseAction from '../../../actions/surveyQuestionResponse';
import SurveyCompleteAction from '../../../actions/surveyComplete';

import { COOKIE_LANGUAGE, translations } from '../../../lib/constants';
import validateLanguageForSurvey from '../../../lib/language';
import { clientSuccessTrack } from '../../../lib/clientSuccess';

import Icon from '../../common/Icon';

const SurveyState = ({ params: { question }, initialData, location }) => {
  const { currentUser } = useContext(CurrentUserContext);
  const { apiService } = useContext(APIContext);
  const { router } = useContext(RouterContext);
  const { onChangeLanguage } = useContext(LanguageContext);
  const { addToast, removeToast } = useContext(ToastContext);

  const [cookies] = useCookies();
  const targetQuestion = parseInt(question, 10);

  useConstructor(() => {
    if (currentUser.surveyStatus !== 'started') {
      router.push('/profile/survey');
    }

    if (initialData.questions) {
      const checkProgress = () =>
        findIndex(initialData.questions, q => typeof q.response === 'undefined') + 1 || 1;

      const firstUnansweredQuestion = checkProgress();
      const questionNotAValidNumber = isNaN(targetQuestion);
      const questionNotInRange = (targetQuestion < 1 || targetQuestion > firstUnansweredQuestion);

      if (questionNotAValidNumber || questionNotInRange) {
        router.push(`/profile/survey/${firstUnansweredQuestion}`);
      }
    }
  });

  const [questions, setQuestions] = useState(initialData.questions);
  const [currentQuestionOrdinal, setCurrentQuestionOrdinal] = useState(targetQuestion);
  const [pendingAnswerSubmission, setPendingAnswerSubmission] = useState(false);

  const [selectedLanguage, setSelectedLanguage] = useState(validateLanguageForSurvey(
    translations,
    cookies[COOKIE_LANGUAGE],
    'survey',
  ));

  useEffect(() => {
    const intQuestion = parseInt(question, 10);
    setCurrentQuestionOrdinal(intQuestion);
    const { isTrial, organizationsOwned } = currentUser;
    let toastId;
    if (isTrial && organizationsOwned) {
      const { dimension, response } = questions[intQuestion - 1];

      if (['direct', 'indirect'].includes(dimension) && response) {
        toastId = uuidv4();
        addToast({
          id: toastId,
          content: (
            <div className="flex items-center gap-3">
              <Icon icon="info" iconColor="medium-primary" className="w-10 h-10" />
              <p className="m-0 text-sm md:text-base md:max-w-md max-w-[16rem]">
                The seven items you answered during onboarding are pre-selected throughout
                the survey. You can change your previous response or click Next Question.
              </p>
            </div>
          ),
        });
      }
    }
    return () => {
      if (toastId) removeToast(toastId);
    };
  }, [question]);
  /**
   *  Pick up at the first unanswered question
   *  @return Number The ordinal value of the first unanswered question
   */

  async function onLanguageChange(event) {
    const { value: newLanguage } = event.target;
    onChangeLanguage(newLanguage);
    const data = await apiService.get(`profile/survey?language=${newLanguage}`);
    setSelectedLanguage(newLanguage);
    setQuestions(data.questions);
  }

  const findCurrentQuestion = () => (questions ? questions[currentQuestionOrdinal - 1] : null);

  const isLast = () => currentQuestionOrdinal === questions.length;

  async function onAnswer(response) {
    const newQuestions = cloneDeep(questions);
    newQuestions[currentQuestionOrdinal - 1].response = response;

    setQuestions(newQuestions);

    if (!pendingAnswerSubmission) {
      setPendingAnswerSubmission(true);
      try {
        await new SurveyQuestionResponseAction(apiService)
          .execute({
            questionId: findCurrentQuestion().id,
            response,
          });
        setPendingAnswerSubmission(false);
        if (isLast()) {
          await new SurveyCompleteAction(apiService)
            .execute({
              language: selectedLanguage,
            });
          window?.Appcues?.track('Complete Profile');

          // Track clientSuccess 'Complete Profile' event
          clientSuccessTrack({ user: currentUser, eventId: 'profiles_completed' });
          router.push('/profile');
        } else {
          router.push(`/profile/survey/${currentQuestionOrdinal + 1}`);
        }
      } catch (e) {
        router.replace('/error');
      }
    }
  }

  const currentQuestion = findCurrentQuestion();
  if (!currentQuestion) return null;
  return (
    <div className="flex flex-col min-h-screen">
      <SurveyQuestionLayout
        currentQuestionNumber={currentQuestionOrdinal}
        totalQuestionCount={questions.length}
        questionId={currentQuestion.id}
        questionText={currentQuestion.text}
        previousAnswer={currentQuestion.response}
        onBack={null}
        onAnswer={onAnswer}
        isLast={isLast()}
        languageSelected={selectedLanguage}
        location={location}
        onLanguageChange={onLanguageChange}
      />
      <Footer />
    </div>
  );
};

SurveyState.getAPIDataKey = () => 'survey';
SurveyState.getData = apiService => {
  const language = validateLanguageForSurvey(
    translations,
    apiService.cookies.get(COOKIE_LANGUAGE),
    'survey',
  );
  return apiService.get(`profile/survey?language=${language}`).then(data => ({ survey: data }));
};

SurveyState.propTypes = {
  params: PropTypes.shape({
    question: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  initialData: PropTypes.shape({
    questions: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      text: PropTypes.string,
      sortOrder: PropTypes.number,
      dimension: PropTypes.string,
      excludeFromProfile: PropTypes.bool,
      deleted: PropTypes.bool,
    })),
  }).isRequired,
};

export default withServerSideData(SurveyState);
