class AdminUserCriteria {
  constructor(user) {
    this.user = user;
  }

  test() {
    return !!(this.user.accessLevel.indexOf('admin') >= 0);
  }
}

export default AdminUserCriteria;
