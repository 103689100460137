import React, { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

import { throttle } from 'lodash';
import PropTypes from 'prop-types';

import Spinner from '../Spinner';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFViewer = ({ pdf }) => {
  const [numPages, setNumPages] = useState(null);
  const [showPDF, setShowPDF] = useState(false);
  const [width, setWidth] = useState(null);

  const pageRefs = useRef([]);
  const pdfRef = useRef(null);

  const setPdfSize = () => {
    if (pdfRef?.current) {
      setWidth(pdfRef.current.getBoundingClientRect().width);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', throttle(setPdfSize, 500));
    setPdfSize();
    return () => window.removeEventListener('resize', throttle(setPdfSize, 500));
  }, []);

  useEffect(() => {
    setNumPages(null);
  }, [pdf]);

  const onItemClick = ({ pageNumber }) => pageRefs.current[pageNumber - 1].scrollIntoView({ behavior: 'smooth' });

  const onDocumentLoadSuccess = ({ numPages: totalPages }) => {
    setNumPages(totalPages);
    setPdfSize();
    setShowPDF(true);
  };

  return (
    <>
      {pdf ? (
        <div
          ref={pdfRef}
          data-showpdf={showPDF}
          className="transition-opacity duration-700 opacity-0 data-[showpdf=true]:opacity-100"
        >
          <Document
            file={pdf}
            onLoadSuccess={onDocumentLoadSuccess}
            onItemClick={onItemClick}
          >
            {numPages && Array(numPages).fill().map((el, index) => (
              <div key={`page-${index + 1}`} ref={ref => { pageRefs.current[index] = ref; }}>
                <Page
                  className="mb-6 shadow-md"
                  pageNumber={index + 1}
                  width={width}
                />
              </div>
            ))}
          </Document>
        </div>
      ) : null}

      <div
        data-showpdf={showPDF}
        className="h-40 flex flex-col items-center justify-start transition-opacity duration-700 opacity-100 data-[showpdf=true]:opacity-0"
      ><Spinner />
      </div>
    </>
  );
};

PDFViewer.propTypes = {
  pdf: PropTypes.string,
};

PDFViewer.defaultProps = {
  pdf: null,
};

export default PDFViewer;
