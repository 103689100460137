export const accessCodeList = ({ codes, onlyPremium = false }) => {
  if (!codes || codes.length === 0) {
    return { accessCodes: [], defaultAccessCode: '' };
  }

  let accessCodes = codes.filter(accessCode => !accessCode.isArchived);

  if (onlyPremium) {
    accessCodes = accessCodes.filter(accessCode => accessCode.stripePlanId.includes('premium') || accessCode.stripePlanId.includes('starter') || accessCode.stripePlanId.includes('growth'));
  }

  accessCodes = accessCodes
    .map(({
      code,
      seatsRemaining,
      quantity,
      planName,
      description,
    }) => ({
      value: code,
      name: description
        ? `(${code}) ${seatsRemaining}/${quantity} remaining: ${description}, ${planName}`
        : `(${code}) ${seatsRemaining}/${quantity} remaining: ${planName}`,
    }));

  const noCodeOption = { name: 'Don\'t use a code', value: '' };
  if (accessCodes.length) {
    accessCodes.push(noCodeOption);
    return { accessCodes, defaultAccessCode: accessCodes[0].value };
  }
  return { accessCodes, defaultAccessCode: '' };
};
