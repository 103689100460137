import { useEffect, useRef, useState } from 'react';

export const useIntersectionObserver = options => {
  const [isIntersecting, setIntersecting] = useState(false);
  const element = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIntersecting(entry.isIntersecting),
      options,
    );

    if (element.current) {
      observer.observe(element.current);
    }

    return () => {
      if (element.current) {
        observer.unobserve(element.current);
      }
    };
  }, [element, options]);

  return { element, isIntersecting };
};
