import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const CopyText = ({ text, showText, isBold }) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }, [copied]);

  function getStyles() {
    return copied ? 'copy-text copy-text--copied' : 'copy-text';
  }

  return (
    <CopyToClipboard
      text={text}
      onCopy={() => setCopied(true)}
    >
      <span className={`${getStyles()} full-width`}>
        {showText ? <span className="btn--anchor">{isBold ? <b>{text}</b> : text }</span> : null}
        <svg className="copy-text__icon" xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16">
          <path fillRule="evenodd" d="M6.6 3.1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1m4 8.3h-8a.3.3 0 0 0 0 .5h8a.3.3 0 0 0 0-.5m0-2h-8a.3.3 0 0 0 0 .5h8a.3.3 0 0 0 0-.5m0-2h-8a.3.3 0 0 0 0 .5h8a.3.3 0 0 0 0-.5M6.6 1c1 0 1.7.7 1.7 1.6 0 .2.1.4.3.4h.7l.1.1.6 1.1H3.2L4 3.1V3h.7c.2 0 .4-.2.4-.4C5 1.7 5.7 1 6.6 1m4.5 13.3h-9c-.6 0-1.1-.6-1.1-1.2v-9C1 3.5 1.5 3 2.1 3h1l-.8 1.4a.4.4 0 0 0 .3.6h8c.2 0 .3 0 .3-.2.1 0 .1-.2 0-.4L10.3 3h1c.5 0 1 .5 1 1.1v9c0 .6-.5 1.2-1 1.2m0-12.3h-2C8.9.8 7.8 0 6.6 0 5.4 0 4.4.8 4.1 2H2C1 2 0 3 0 4.1v9c0 1.2 1 2.2 2.1 2.2h9c1.2 0 2.2-1 2.2-2.2v-9C13.3 3 12.3 2 11 2" />
        </svg>
      </span>

    </CopyToClipboard>
  );
};

CopyText.propTypes = {
  text: PropTypes.string.isRequired,
  showText: PropTypes.bool,
  isBold: PropTypes.bool,
};

CopyText.defaultProps = {
  showText: false,
  isBold: false,
};

export default CopyText;
