/* eslint-disable react/function-component-definition */
import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

import { CurrentUserContext } from '../CurrentUser';
import { APIContext } from '../API';
import { NotificationContext } from '../Notification';
import { RouterContext } from '../Router';
import { LanguageContext } from '../Language';
import { ModalContext } from '../Modal';
import { ToastContext } from '../Toast';

export const Context = createContext();

export default function ContextProvider({ children }) {
  const { router } = useContext(RouterContext);
  const { currentUser, cookies } = useContext(CurrentUserContext);
  const { apiService } = useContext(APIContext);
  const {
    notificationMessage,
    addNotification,
    removeNotification,
  } = useContext(NotificationContext);
  const { selectedLanguage, onChangeLanguage } = useContext(LanguageContext);
  const {
    modalClassName,
    modalContent,
    showModal,
    handleOpenModal,
    handleCloseModal,
    dialogClassName,
    dialogContent,
    showDialog,
    handleOpenDialog,
    handleCloseDialog,
  } = useContext(ModalContext);

  const { toasts, addToast, removeToast, clearToasts } = useContext(ToastContext);

  return (
    <Context.Provider
      value={{
        router,
        currentUser,
        apiService,
        cookies,
        notificationMessage,
        addNotification,
        removeNotification,
        selectedLanguage,
        onChangeLanguage,
        modalContent,
        showModal,
        handleOpenModal,
        handleCloseModal,
        modalClassName,
        dialogClassName,
        dialogContent,
        showDialog,
        handleOpenDialog,
        handleCloseDialog,
        toasts,
        addToast,
        removeToast,
        clearToasts,
      }}
    >
      {children}
    </Context.Provider>
  );
}

ContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const ContextConsumer = Context.Consumer;
