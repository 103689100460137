import React from 'react';
import { iconPropTypes, defaultIconProps } from './types';

import { fillColorMap } from '../index.js';

const LearningModuleIcon = ({ className, iconColor }) => (
  <svg className={`${className} ${fillColorMap[iconColor]}`} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.65657 0.462769H8.28625C8.32637 0.462769 8.35889 0.495858 8.35889 0.536675V4.23004C8.35889 4.27086 8.32637 4.30395 8.28625 4.30395H4.65657C4.61645 4.30395 4.58394 4.27086 4.58394 4.23004V0.536675C4.58394 0.495858 4.61645 0.462769 4.65657 0.462769Z" />
    <path d="M0.616651 4.56885H4.24634C4.28645 4.56885 4.31897 4.60194 4.31897 4.64275V8.33612C4.31897 8.37694 4.28645 8.41003 4.24634 8.41003H0.616651C0.576537 8.41003 0.544019 8.37694 0.544019 8.33612V4.64275C0.544019 4.60194 0.576537 4.56885 0.616651 4.56885Z" />
    <path d="M4.24639 16.8871H0.6167C0.576586 16.8871 0.544067 16.854 0.544067 16.8132V13.1198C0.544067 13.079 0.576586 13.0459 0.6167 13.0459H4.24639C4.2865 13.0459 4.31902 13.079 4.31902 13.1198V16.8132C4.31902 16.854 4.2865 16.8871 4.24639 16.8871Z" />
    <path d="M8.28618 12.781H4.65649C4.61638 12.781 4.58386 12.7479 4.58386 12.7071V9.01373C4.58386 8.97292 4.61638 8.93983 4.65649 8.93983H8.28618C8.32629 8.93983 8.35881 8.97292 8.35881 9.01373V12.7071C8.35881 12.7479 8.32629 12.781 8.28618 12.781Z" />
    <path d="M12.4319 8.40998C10.4751 8.40998 8.88879 6.82366 8.88879 4.86683C8.88879 2.90999 10.4751 1.32367 12.4319 1.32367C14.3888 1.32367 15.9751 2.90999 15.9751 4.86683C15.9751 6.82366 14.3888 8.40998 12.4319 8.40998Z" />
    <path d="M15.9751 9.07755V15.8896C15.9751 15.9657 15.9135 16.0261 15.8386 16.0261H9.02652C8.95041 16.0261 8.88759 15.9633 8.8888 15.886C8.96128 12.0831 12.0309 9.01353 15.835 8.93984C15.9123 8.93863 15.9751 9.00145 15.9751 9.07755Z" />
  </svg>
);

LearningModuleIcon.propTypes = iconPropTypes;

LearningModuleIcon.defaultProps = defaultIconProps;

export default LearningModuleIcon;
