import React, { useEffect, useState } from 'react';
import { Link } from 'react-router';

import Button from '../../common/Button';

import { propTypes, defaultProps } from './types';

const SurveyNavigation = ({
  currentQuestion,
  selectedOption,
  onBack,
  onNext,
  isLast,
  isOnboarding,
}) => {
  const [isDisabled, setIsDisabled] = useState(selectedOption === null);

  useEffect(() => {
    setIsDisabled(selectedOption === null);
  }, [currentQuestion, selectedOption]);

  useEffect(() => {
    if (isDisabled && selectedOption !== null) {
      onNext(selectedOption);
    }
  }, [isDisabled]);

  const onNextHandler = e => {
    e.preventDefault();
    setIsDisabled(true);
  };

  function nextButtonText() {
    return (isLast ? 'Complete Survey' : 'Next Question');
  }

  function backLink() {
    let element = null;

    if (currentQuestion !== 1 && isOnboarding) {
      element = <Button onClick={onBack} className="text-base btn md:text-xl">Back</Button>;
    }

    if (currentQuestion !== 1 && !isOnboarding) {
      const previousQuestion = currentQuestion - 1;
      const backLinkUrl = `/profile/survey/${previousQuestion}`;
      element = <Link to={backLinkUrl} onClick={onBack} className="font-sans text-base btn md:text-xl">Back</Link>;
    }
    return element;
  }

  return (
    <div className="flex justify-center w-full max-w-2xl gap-4 m-auto text-center">
      {isOnboarding ? null : backLink()}
      <Button
        variant="primary"
        onClick={onNextHandler}
        disabled={isDisabled}
      >
        {nextButtonText()}
      </Button>
    </div>
  );
};

SurveyNavigation.propTypes = propTypes;
SurveyNavigation.defaultProps = defaultProps;

export default SurveyNavigation;
