export default class Feature {
  constructor(criteria) {
    this.criteria = criteria;
    this.positiveCallback = () => { };
    this.negativeCallback = () => { };
  }

  positive(callback) {
    this.positiveCallback = callback;
    return this;
  }

  negative(callback) {
    this.negativeCallback = callback;
    return this;
  }

  execute() {
    if (this.criteria.test()) {
      return this.positiveCallback();
    }

    return this.negativeCallback();
  }
}
