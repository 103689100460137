import React from 'react';
import PropTypes from 'prop-types';

const OnboardingFooter = ({ step }) => {
  const stepsWithLinks = [1, 2, 3, 4, 9];
  const hideLinks = !stepsWithLinks.includes(step);
  return (
    <div className="flex w-full pb-4 md:flex-wrap">
      <div className="md:flex md:justify-start">
        <svg className="h-10 ml-2 md:ml-0 md:h-16" width="100%" height="100%" viewBox="0 0 122 113" version="1.1">
          <g>
            <g>
              <g id="Layer_1">
                <g>
                  <g>
                    <path d="M55.32,24.39C54.29,24.39 53.37,25.02 53,25.97L32.95,77.02C30.86,82.21 28.67,84.58 25.39,85.21L24.39,85.39L24.39,88.21L43.78,88.21L43.78,85.39L42.6,85.21C38.05,84.48 35.86,83.02 37.87,77.84L40.97,69.65L63.46,69.65L66.65,77.94C68.38,82.58 66.74,84.5 61.55,85.22L60.28,85.4L60.28,88.22L95.08,88.22C96.84,88.22 98.04,86.45 97.41,84.82L74.5,25.98C74.13,25.02 73.2,24.39 72.17,24.39L55.32,24.39ZM42.51,65.64L52.07,40.33L61.9,65.64L42.51,65.64Z" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div className={`font-sans text-charcoal-900 text-xs md:text-lg inline-flex justify-center items-center w-full${hideLinks ? ' md:hidden' : ''}`}>
        <a className="no-underline text-inherit" href="https://aperian.com" target="_blank" rel="noopener noreferrer">Aperian Global, Inc.</a>
        <svg className="mx-2" width="1" height="32" viewBox="0 0 1 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="1" height="32" fill="#1E1E28" />
        </svg>
        <a className="no-underline text-inherit" href="/content/terms-of-use">Terms of Service</a>
        <svg className="mx-2" width="1" height="32" viewBox="0 0 1 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="1" height="32" fill="#1E1E28" />
        </svg>
        <a className="no-underline text-inherit" href="/content/privacy-policy">Privacy Policy</a>
      </div>
    </div>
  );
};

OnboardingFooter.propTypes = {
  step: PropTypes.number.isRequired,
};

export default OnboardingFooter;
