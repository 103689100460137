import React from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';

import Button from '../../../../../common/Button';
import Avatar from '../../../../../common/Avatars/Avatar';

import { buildCompareUrl } from '../../../buildCompareUrl';

const ActiveMemberCard = ({
  id,
  displayName,
  displayDescription,
  avatarUrl,
  confirmLeave,
}) => (
  <div className="card">
    <div className="card__container">
      <Avatar avatarUrl={avatarUrl} avatarImgAlt={displayName} />
      <div className="card__content">
        <div className="card__heading">
          <Link to={`/profile/teams/${id}`}>
            {displayName}
          </Link>
        </div>
        {displayDescription ? (
          <div className="card__description">
            {displayDescription}
          </div>
        ) : null}
      </div>
      <div className="card__actions">
        <Button to={buildCompareUrl(id)} variant="primary" isNormalCase className="card__button">
          Compare
        </Button>
        <Button
          className="card__button"
          isWarning
          isNormalCase
          onClick={confirmLeave}
        >
          Leave
        </Button>
      </div>
    </div>
  </div>
);

ActiveMemberCard.propTypes = {
  id: PropTypes.number.isRequired,
  displayName: PropTypes.string.isRequired,
  displayDescription: PropTypes.node,
  avatarUrl: PropTypes.string.isRequired,
  confirmLeave: PropTypes.func.isRequired,
};

ActiveMemberCard.defaultProps = {
  displayDescription: null,
};

export default ActiveMemberCard;
