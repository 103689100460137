/* eslint-disable no-param-reassign */
import React, { useRef, useContext, useEffect, useReducer } from 'react';
import qs from 'query-string';
import PropTypes from 'prop-types';

import { APIContext } from '../../context/API';
import { CurrentUserContext } from '../../context/CurrentUser';
import { RouterContext } from '../../context/Router';
import { LanguageContext } from '../../context/Language';

import withServerSideData from '../../HOC/withServerSideData';
import getQuery from '../../lib/urls/getQuery';

import PageHeader from '../../components/PageHeader';
import CompareDetails from '../../components/custom/Compare/CompareDetails';
import ProfileChartTransformer from '../../components/common/ProfileChartTransformer';
import ProfileCompareCTA from '../../components/custom/Profile/ProfileCompareCTA';
import PrintLayoutHeader from '../Profile/PrintLayout/PrintLayoutHeader';
import ComparisonHeader from '../../components/custom/Compare/ComparisonHeader';

import NeedsCompletedSurvey from '../../lib/NeedsCompletedSurvey';
import ServerError from '../../components/ServerError';

import { COOKIE_LANGUAGE, translations } from '../../lib/constants';
import validateLanguageForCompare from '../../lib/language';

const Compare = props => {
  const { currentUser } = useContext(CurrentUserContext);
  const { selectedLanguage, onChangeLanguage } = useContext(LanguageContext);
  const { apiService } = useContext(APIContext);
  const { router } = useContext(RouterContext);

  const needsCompletedSurvey = NeedsCompletedSurvey.apply(router, currentUser);

  const [state, setState] = useReducer((data, newData) =>
    ({ ...data, ...newData }), {
    ...props.initialData,
    location: props.location,
    hideProfileToggleText: 'Hide My Profile',
    myProfileVisibility: 'visible',
    teamProfiles: [],
  });

  useEffect(() => {
    const { params: { language } } = props;
    if (language !== selectedLanguage) {
      const newParams = {
        ...props.location.query,
        language: selectedLanguage,
      };
      const queryString = qs.stringify(newParams);
      router.replace(`${props.location.pathname}?${queryString}`);

      Compare.getData(apiService, newParams)
        .then(data => {
          const compareDetails = data[Compare.getAPIDataKey(props)];
          setState({ ...compareDetails });
        });
    }
  }, [selectedLanguage]);

  const setTeamProfiles = () => {
    const { initialProfiles } = state;
    const teamProfiles = initialProfiles
      .filter(profile => profile.type === 'team')
      .map(profile => ({ ...profile, areMembersShowing: true }));
    setState({ teamProfiles });
  };

  useEffect(() => {
    if (!needsCompletedSurvey) {
      setTeamProfiles();
    }
  }, []);

  useEffect(() => {
    // To trigger an update to the ProfileTraitLine and ProfileChartAvatars to reposition
    // the ProfileConnectingLines to the new profiles we pass a resize event
    // IE11 doesn't support window.dispatch(newEvent('resize')), so this is a solution
    if (typeof (Event) === 'function') {
      window.dispatchEvent(new Event('resize'));
    } else {
      const event = window.document.createEvent('UIEvents');
      event.initUIEvent('resize', true, false, window, 0);
      window.dispatchEvent(event);
    }
  }, [state.profiles, state.teamProfiles]);

  const fullScreenRef = useRef(null);

  const hideProfileToggle = () => {
    if (state.myProfileVisibility === 'hidden') {
      setState({ hideProfileToggleText: 'Hide My Profile', myProfileVisibility: 'visible', isHistoryHidden: false });
      return;
    }
    setState({ hideProfileToggleText: 'Show My Profile', myProfileVisibility: 'hidden', isHistoryHidden: true });
  };

  const updateProfiles = (params, teamProfiles) =>
    Compare.getData(apiService, params)
      .then(data => {
        const dataKey = Object.keys(data).shift();
        const { profiles } = data[dataKey];
        setState({ profiles, teamProfiles });
      });

  if (state.error) return <ServerError pageTitle="Compare Profiles" error={state.error} />;
  if (needsCompletedSurvey) return null;
  return (
    <div className="full-width">
      <PageHeader
        pageTitle="Compare Profile"
        icon="profile"
        bgColor={(state.initialProfiles.length === 2) ? 'bg-slate-50' : null}
        isBreakout
      />
      <section className="!block full-width">
        <PrintLayoutHeader />
        {(state.initialProfiles.length === 2) ? (
          <>
            <CompareDetails
              {...state}
              {...props}
              selectedLanguage={selectedLanguage}
              onChangeLanguage={onChangeLanguage}
            >
              {props.children}
            </CompareDetails>
            <div ref={fullScreenRef} className="overflow-auto bg-white">
              <ProfileChartTransformer
                {...state}
                hasResizeSupport
                showControls
                onClick={hideProfileToggle}
                compareProfile
                updateProfiles={updateProfiles}
                withBorder
                fullScreenRef={fullScreenRef}
              />
            </div>
          </>
        ) : (
          <div ref={fullScreenRef} className="overflow-auto bg-white">
            <ComparisonHeader />
            <ProfileChartTransformer
              {...state}
              hasResizeSupport
              showControls
              onClick={hideProfileToggle}
              compareProfile
              updateProfiles={updateProfiles}
              withBorder
              fullScreenRef={fullScreenRef}
              downloadLocation="Comparison Chart"
            />
            <CompareDetails
              {...state}
              {...props}
              selectedLanguage={selectedLanguage}
              onChangeLanguage={onChangeLanguage}
            >
              {props.children}
            </CompareDetails>
          </div>
        )}
        <div className="print:hidden">
          <ProfileCompareCTA />
        </div>
      </section>
    </div>
  );
};

Compare.needsCompletedSurvey = (router, currentUser) =>
  NeedsCompletedSurvey.apply(router, currentUser);

Compare.getAPIDataKey = props => {
  const person = getQuery('person', props) || '';
  const teamsMembers = getQuery('teams_members', props) || '';
  const org = getQuery('org', props) || '';
  return `compare${person}${teamsMembers}${org}`;
};

Compare.getData = (apiService, params) => {
  if (!params.language) {
    params.language = validateLanguageForCompare(
      translations,
      apiService.cookies.get(COOKIE_LANGUAGE),
      'compare',
    );
  }
  const queryString = qs.stringify(params).replace(/team=/, 'teams_average=');

  return apiService
    .get(`profile?${queryString}`)
    .then(data => {
      // Modify the compareTarget Objects (everybody else)
      // eslint-disable-next-line no-param-reassign
      data.compareTargets = data.compareTargets.map(profile => {
        profile.isVisible = true; // eslint-disable-line no-param-reassign
        return profile;
      });
      return data;
    })
    .then(data => {
      // Modify the main compareObject
      data.profile.isMe = true; // eslint-disable-line no-param-reassign
      data.profile.isVisible = true; // eslint-disable-line no-param-reassign
      return data;
    })
    .then(data => ({
      initialProfiles: [data.profile].concat(data.compareTargets),
      profiles: [data.profile].concat(data.compareTargets),
      teamInformation: data.teamInformation,
      dimensionLabelGroups: data.dimensionLabelGroups,
    }))
    .then(consolidatedProfiles => {
      const key = Compare.getAPIDataKey({ params, location: { query: {} } });
      return ({
        [key]: {
          ...consolidatedProfiles,
        },
      });
    });
};

Compare.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    query: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
  }).isRequired,
  params: PropTypes.shape({
    language: PropTypes.string,
  }),
  initialData: PropTypes.shape({}).isRequired,
  children: PropTypes.node.isRequired,
};

Compare.defaultProps = {
  params: {},
};

export default withServerSideData(Compare);
