class UpdateUserAdminAction {
  constructor(apiService) {
    this.apiService = apiService;
  }

  execute(id, userData) {
    return this.apiService
      .put(`users/${id}`, { user: userData });
  }
}

export default UpdateUserAdminAction;
