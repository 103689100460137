import tracker from '../index';

const {
  eventNames, resolveAfterTimeout, eventProps, peopleProps,
} = tracker;

const trackAdviceViewed = (apiService, topic, comparisonObject) => {
  apiService.post('trackers/mixpanel', {
    eventName: eventNames.VIEW_ADVICE,
    properties: {
      [eventProps.ADVICE_TOPIC]: topic,
      [eventProps.COMPARISON_OBJECT]: comparisonObject,
    },
    peopleProperties: {
      increment: peopleProps.NUMBER_OF_ADVICE_TOPICS_VIEWED,
    },
  },
  );
  // Track appcues 'View Advice' event
  window?.Appcues?.track(eventNames.VIEW_ADVICE);

  return resolveAfterTimeout;
};

export { trackAdviceViewed };
