import { BadRequestError } from '../../../lib/errors';

class TeamRemoveMemberAction {
  constructor(apiService) {
    this.apiService = apiService;
  }

  execute(teamId, memberId) {
    return this.apiService.delete(`teams/${teamId}/members/${memberId}`)
      .catch(err => {
        if (err instanceof BadRequestError) {
          return err.reason;
        }
        throw err;
      });
  }
}

export default TeamRemoveMemberAction;
