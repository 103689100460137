import React from 'react';
import { iconPropTypes, defaultIconProps } from './types';

const UserAvatar = className => (
  <svg className={className} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" labelledby="user_avatar">
    <title id="user_avatar">User Avatar</title>
    <rect x="2" y="2" width="60" height="60" rx="30" fill="#FFE3E0" />
    <path d="M45.3333 47V43.6667C45.3333 41.8986 44.6309 40.2029 43.3807 38.9526C42.1305 37.7024 40.4348 37 38.6667 37H25.3333C23.5652 37 21.8695 37.7024 20.6193 38.9526C19.369 40.2029 18.6667 41.8986 18.6667 43.6667V47M38.6667 23.6667C38.6667 27.3486 35.6819 30.3333 32 30.3333C28.3181 30.3333 25.3333 27.3486 25.3333 23.6667C25.3333 19.9848 28.3181 17 32 17C35.6819 17 38.6667 19.9848 38.6667 23.6667Z" stroke="#E64833" strokeWidth="2.66667" strokeLinecap="round" strokeLinejoin="round" />
    <rect x="2" y="2" width="60" height="60" rx="30" stroke="#FFC9C2" strokeWidth="4" />
  </svg>
);

UserAvatar.propTypes = iconPropTypes;
UserAvatar.defaultProps = defaultIconProps;

export default UserAvatar;
