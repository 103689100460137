import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import withServerSideData from '../../../HOC/withServerSideData';

import { Context } from '../../../context/ContextProvider';
import { APIContext } from '../../../context/API';
import { NotificationContext } from '../../../context/Notification';
import { CurrentUserContext } from '../../../context/CurrentUser';
import { RouterContext } from '../../../context/Router';

import PageHeader from '../../../components/PageHeader';
import Notification from '../../../components/Notifications/Notification';
import CourseCatalogContainer from '../../../components/AperianLive/CourseCatalogContainer';
import CourseCatalogCTAs from '../../../components/AperianLive/CourseCatalogCTAs';
import CohortEnrollmentSuccessMessage from '../../../components/Cohorts/CohortEnrollmentSuccessMessage';

import { cohortEnrollAction } from '../../../actions/cohorts';
import { setAttendedAction } from '../../../actions/aperianLive';
import logger from '../../../lib/logger';
import { trackShareAperianLiveCourseCatalog, trackViewAperianLiveCourseCatalog } from '../../../lib/tracker/aperian-live';

const AperianLiveCourseCatalog = ({ initialData }) => {
  const [sessionTopicData, setSessionTopicData] = useState(initialData);
  const [isCopied, setIsCopied] = useState(false);
  const [courseId, setCourseId] = useState('');
  const [hasAccess, setHasAccess] = useState(true);
  const [pageURL, setPageURL] = useState('');

  const { handleOpenDialog } = useContext(Context);
  const { apiService } = useContext(APIContext);
  const {
    addNotification,
    notificationMessage,
    removeNotification,
  } = useContext(NotificationContext);
  const { router } = useContext(RouterContext);
  const { currentUser } = useContext(CurrentUserContext);

  const {
    userid,
    accessLevel,
    organizations,
  } = currentUser;

  useEffect(() => {
    let timeout;
    if (notificationMessage) {
      timeout = window.setTimeout(() => {
        removeNotification();
      }, 5000);
    }
    return () => {
      window.clearTimeout(timeout);
      if (notificationMessage) removeNotification();
    };
  }, [notificationMessage]);

  useEffect(() => {
    const { pathname } = window.location;
    setPageURL(pathname);
    const urlCourseId = pathname.split('/').slice(-1).pop();
    setCourseId(urlCourseId);
    trackViewAperianLiveCourseCatalog(apiService, sessionTopicData.name, accessLevel);
    if (urlCourseId !== '1' && !accessLevel.some(string => string.includes('aperianlive'))) {
      setHasAccess(false);
    }
  }, []);

  const join = async (
    code,
    userJoinUrl,
    joinMethod,
  ) => {
    window.open(userJoinUrl);
    await setAttendedAction(apiService, code, { joinMethod });
  };

  const register = async (
    code,
    registrationMethod,
  ) => {
    try {
      const {
        displayName,
        redirectUrl,
        isAlreadyEnrolled,
        meetingDateTime,
      } = await cohortEnrollAction(apiService, code, { registrationMethod });

      const dialogOptions = {
        content: (
          <CohortEnrollmentSuccessMessage
            isAlreadyEnrolled={isAlreadyEnrolled}
            displayName={displayName}
            redirectUrl={redirectUrl}
            meetingDateTime={meetingDateTime}
          />),
      };

      handleOpenDialog(dialogOptions);

      const updatedSessionData = await apiService.get(`cohort-events/${courseId}`);
      setSessionTopicData(updatedSessionData);
    } catch (error) {
      window.scroll(0, 0);
      addNotification({ type: 'failure', message: error.details.message });
    }
  };

  const createShareableLink = async (
  ) => {
    const shareableLink = `${process.env.SITE_URL}/aperian-live/program/${courseId}`;
    try {
      await trackShareAperianLiveCourseCatalog(
        apiService,
        sessionTopicData.name,
      );
      await navigator.clipboard.writeText(shareableLink);
      setIsCopied(true);
    } catch (err) {
      logger.error('Error creating shareable link for this session', err);
    }
  };

  return (
    <div className="full-width">
      <PageHeader
        pageTitle="Aperian Live"
        icon="aperian-live"
        skipTarget="#aperian-live"
      />
      <Notification {...notificationMessage} />
      {sessionTopicData && (
        <>
          <CourseCatalogContainer
            sessionTopicData={sessionTopicData}
            join={join}
            register={register}
            copyShareableLink={createShareableLink}
            isCopied={isCopied}
            userId={userid}
            organizations={organizations}
            hasAccess={hasAccess}
          />
          {hasAccess ? (<CourseCatalogCTAs router={router} pageURL={pageURL} />) : null}
        </>
      )}
    </div>
  );
};

AperianLiveCourseCatalog.getAPIDataKey = () => 'aperianLiveCourseCatalog';

AperianLiveCourseCatalog.getData = (apiService, { id }) => apiService.get(`cohort-events/${id}`)
  .then(aperianLiveCourseCatalog => ({ aperianLiveCourseCatalog }));

AperianLiveCourseCatalog.propTypes = {
  initialData: PropTypes.shape({
    name: PropTypes.string,
    duration: PropTypes.number,
    description: PropTypes.string,
    prework: PropTypes.arrayOf(PropTypes.string),
    tags: PropTypes.arrayOf(PropTypes.string),
    sessions: PropTypes.arrayOf(
      PropTypes.shape({
        nameInternal: PropTypes.string,
        facilitator: PropTypes.string,
        facilitatorAvatarUrl: PropTypes.string,
        dateTime: PropTypes.string,
        cohortCode: PropTypes.string,
        isRegistered: PropTypes.bool,
        userJoinUrl: PropTypes.string,
      })),
  }),
};

AperianLiveCourseCatalog.defaultProps = {
  initialData: {
    sessions: [],
  },
};

export default withServerSideData(AperianLiveCourseCatalog);
