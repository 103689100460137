import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const getPositionClasses = position => {
  switch (position) {
    case 'bottom':
      return {
        tooltip: 'top-full left-1/2 -translate-x-2/4 my-2',
        svg: 'bottom-full left-1/2 rotate-180',
      };
    case 'left':
      return {
        tooltip: 'right-full -top-2 mx-2',
        svg: '-right-2 top-1/4 -rotate-90',
      };
    case 'right':
      return {
        tooltip: 'left-full -top-2 mx-2',
        svg: '-left-2 top-1/4 rotate-90',
      };
    default:
      return {
        tooltip: 'bottom-full left-1/2 -translate-x-2/4 my-2',
        svg: 'top-full left-1/2',
      };
  }
};

const Tooltip = ({ children, position, onHover, content, theme, className }) => {
  const positionClasses = getPositionClasses(position);
  const classes = classNames({
    [positionClasses.tooltip]: true,
    'absolute z-10 p-2 transition-all rounded-lg shadow-lg w-max max-w-sm': true,
    'bg-gray-900 text-white': theme === 'dark',
    'text-gray-900 bg-white': theme === 'light',
    'scale-0 group-hover:scale-100': onHover,
  });

  const svgClasses = classNames({
    'absolute h-2': true,
    [positionClasses.svg]: true,
    'fill-white shadow-lg stroke-gray-900': theme === 'light',
  });

  return (
    <div className={`${className} relative group`}>
      {children}
      {content
        ? (
          <div className={classes}>
            {content}
            <svg className={svgClasses} viewBox="0 0 255 255" xmlSpace="preserve">
              <polygon points="0,0 127.5,127.5 255,0" />
            </svg>
          </div>
        )
        : null}
    </div>
  );
};

Tooltip.defaultProps = {
  position: 'top',
  onHover: false,
  theme: 'dark',
  className: '',
  children: null,
  content: null,
};

Tooltip.propTypes = {
  children: PropTypes.node,
  position: PropTypes.string,
  onHover: PropTypes.bool,
  content: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  theme: PropTypes.string,
  className: PropTypes.string,
};

export default Tooltip;
