export const filterSessions = (sessions, filterValue) => {
  const specificKeys = ['name'];
  const searchTerm = filterValue.toLowerCase();
  const filteredBlocks = sessions.filter(block =>
    specificKeys.some(key =>
      block[key]?.toString().toLowerCase().includes(searchTerm)
        || JSON.stringify(block[key])?.toLowerCase().includes(searchTerm),
    ));
  return filteredBlocks;
};
