import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { APIContext } from '../../../context/API';
import { ModalContext } from '../../../context/Modal';
import { NotificationContext } from '../../../context/Notification';
import { RouterContext } from '../../../context/Router';

import withServerSideData from '../../../HOC/withServerSideData';

import BlockForm from './BlockForm';
import BlockConfirmModal from './BlocksConfirmModal';
import GlobeSmartModal from '../../../components/GlobeSmartModal';

import { createBlockAction } from '../../../actions/dashboardBlocks';

const BlocksNew = ({ initialData }) => {
  const {
    notificationMessage,
    removeNotification,
    addNotification,
  } = useContext(NotificationContext);

  const { handleOpenModal, handleCloseModal } = useContext(ModalContext);
  const { router } = useContext(RouterContext);
  const { apiService } = useContext(APIContext);

  useEffect(() => {
    let timeout;
    if (notificationMessage) {
      timeout = window.setTimeout(() => {
        removeNotification();
      }, 5000);
    }
    return () => {
      window.clearTimeout(timeout);
      if (notificationMessage) removeNotification();
    };
  }, [notificationMessage]);

  const handleModalRedirect = newBlockId => {
    handleCloseModal();
    router.push(`/administration/blocks/${newBlockId}/edit`);
  };

  const handleRemain = () => {
    handleCloseModal();
    router.push('/administration/blocks/new');
  };

  const handleFormSubmit = async (blockData, clearForm) => {
    // We want to enforce image selection for all blocks except
    // Promo Blocks (block type 3)
    if (blockData.type.value !== 3 && !blockData.blockImage) {
      addNotification({
        type: 'failure',
        message: 'Please select an image for your block.',
      });
      return;
    }
    try {
      const { block: { id: createdBlockId } } = await createBlockAction(apiService, blockData);
      clearForm();
      handleOpenModal({
        content: (
          <BlockConfirmModal
            onHandleRedirect={() => handleModalRedirect(createdBlockId)}
            onHandleRemain={handleRemain}
          />),
        modalSize: 'large',
        afterCloseAction: handleRemain,
      });
    } catch (err) {
      const errorMsg = (err?.details?.message) ? err.details.message : 'Something went wrong!';
      addNotification({
        type: 'failure',
        message: `Error: ${errorMsg}`,
      });
    }
  };

  return (
    <>
      <BlockForm
        formData={initialData}
        handleFormSubmit={handleFormSubmit}
        buttonText="Publish"
        subHeader="Create a block by filling out the details below."
      />
      <GlobeSmartModal />
    </>
  );
};

BlocksNew.getAPIDataKey = () => 'blockFormData';
BlocksNew.getData = apiService => apiService.get('blocks/form')
  .then(blockFormData => ({ blockFormData }));

BlocksNew.propTypes = {
  initialData: PropTypes.shape({
    plans: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    types: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.number,
      })),
  }).isRequired,
};

export default withServerSideData(BlocksNew);
