import { useContext } from 'react';
import PropTypes from 'prop-types';

import { Context } from '../../../context/ContextProvider';

import NewInvitationFeature from '../../../lib/features/NewInvitation';
import getLoginUrl from '../../../lib/urls/loginUrl';

const IndividualInvitationAuthorization = ({ children, location }) => {
  const { currentUser, router } = useContext(Context);
  return new NewInvitationFeature(currentUser)
    .positive(() => children)
    .negative(() => {
      if (currentUser.isLoggedIn()) {
        router.replace('/upgrade-to-professional');
      } else {
        const loginUrl = getLoginUrl(location);
        router.replace(loginUrl);
      }
      return null;
    })
    .execute();
};

IndividualInvitationAuthorization.propTypes = {
  children: PropTypes.node,
};

export default IndividualInvitationAuthorization;
