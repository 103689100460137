import React, { useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import { APIContext } from '../../../../context/API';
import { ModalContext } from '../../../../context/Modal';

import LabelTextarea from '../../../common/LabelTextarea';
import Button from '../../../common/Button';

import { extractEmails } from '../../../../lib/inputValidation/extractEmail';
import { addOrgMemberAction } from '../../../../actions/adminCenter';

import { trackInviteToOrganization } from '../../../../lib/tracker/invite';

const AdminCenterInviteModal = ({ orgId, orgName }) => {
  const { apiService } = useContext(APIContext);
  const { handleCloseModal } = useContext(ModalContext);

  const [state, setState] = useReducer((data, newData) =>
    ({ ...data, ...newData }), {
    emails: '',
    orgId,
    errorMessage: '',
  });

  const handleInvitationChange = e => {
    setState({ errorMessage: '' });

    const enteredEmails = e.target.value;
    setState({ emails: enteredEmails });
  };

  const handleFormSubmit = async e => {
    e.preventDefault();
    const { emails } = state;

    const invitationsWithUniqueEmails = [...new Set(emails)];
    if (invitationsWithUniqueEmails.length === 0) {
      setState({ errorMessage: 'You must invite at least one person.' });
      return;
    }

    const extractedEmails = extractEmails(emails);
    if (extractedEmails instanceof Error) {
      setState({ errorMessage: extractedEmails.message });
      return;
    }
    setState({ errorMessage: '' });

    const data = { orgName, invitations: extractedEmails };

    try {
      await trackInviteToOrganization({
        apiService,
        invitationCount: extractedEmails.length,
        invitees: extractedEmails.join(', '),
        invitationMethod: 'Admin Center',
      });

      await addOrgMemberAction(apiService, orgId, data);
      handleCloseModal();
    } catch (err) {
      setState({ errorMessage: err.message || err.details.message });
    }
  };

  const {
    emails,
    errorMessage,
  } = state;

  const isFormValid = emails.length === 0;

  return (
    <>
      <h2 className="mb-8 text-2xl text-center">Invite Organization Members</h2>
      <LabelTextarea
        id="orgemailinvitations"
        labelText="Enter Email Addresses"
        placeholder="Separate addresses by commas, new lines, or paste a spreadsheet column..."
        onChangeValue={handleInvitationChange}
        value={emails}
        errorMessage={errorMessage}
      />
      <div className="flex justify-end gap-2 mt-8">
        <Button
          onClick={handleCloseModal}
          isWarning
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          type="submit"
          onClick={handleFormSubmit}
          disabled={isFormValid}
        >
          Invite
        </Button>
      </div>
    </>
  );
};

AdminCenterInviteModal.propTypes = {
  orgId: PropTypes.string.isRequired,
  orgName: PropTypes.string.isRequired,
};

export default AdminCenterInviteModal;
