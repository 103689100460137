import tracker from '../../index';

const {
  eventNames,
  eventProps,
  resolveAfterTimeout,
} = tracker;

const trackEmailGroupReport = (asmtName, asmtType, groupName) => {
  tracker.track(eventNames.EMAIL_GROUP_REPORT, {
    [eventProps.ASSESSMENT]: `${asmtName} (${asmtType.toUpperCase()})`,
    [eventProps.GROUP_NAME]: groupName,
    [eventProps.SUCCESS]: true,
  });
};

const trackEmailGroupReportFailure = reason => {
  tracker.track(eventNames.EMAIL_GROUP_REPORT, {
    [eventProps.SUCCESS]: false,
    [eventProps.FAILURE_REASON]: reason,
  },
  );
  return resolveAfterTimeout;
};

export { trackEmailGroupReport, trackEmailGroupReportFailure };
