import React, { useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

import { CurrentUserContext } from '../../../context/CurrentUser';
import { APIContext } from '../../../context/API';
import { RouterContext } from '../../../context/Router';
import { ModalContext } from '../../../context/Modal';
import { NotificationContext } from '../../../context/Notification';

import withServerSideData from '../../../HOC/withServerSideData';

import Notification from '../../Notifications/Notification';
import UpdateUserAction from '../../../actions/user/update';
import deleteUserAction from '../../../actions/user/delete';
import { createInitialsAction, uploadAvatarAction, deleteAvatarImageAction } from '../../../actions/avatars';
import { trackAvatarType } from '../../../lib/tracker/avatars';

import AccountSettingsNamePhotoFields from './AccountSettingsNamePhotoFields';
import AccountSettingsEmailFields from './AccountSettingsEmailFields';
import AccountSettingsPasswordFields from './AccountSettingsPasswordFields';
import UserAvatarSettings from './UserAvatarSettings';
import DeleteAccount from '../../DeleteModals/DeleteAccountModal';
import Button from '../../common/Button';
import GlobeSmartModal from '../../GlobeSmartModal';
import ConfirmModal from '../../common/Modal/ConfirmModal';

import { trackProductUpdatesOptIn } from '../../../lib/tracker/product-updates';

const AccountSettingsPersonal = ({ initialData }) => {
  const { currentUser } = useContext(CurrentUserContext);
  const { apiService } = useContext(APIContext);
  const { router } = useContext(RouterContext);
  const { handleOpenModal, handleCloseModal } = useContext(ModalContext);

  const {
    addNotification,
    notificationMessage,
    removeNotification,
  } = useContext(NotificationContext);

  const [state, setState] = useReducer((data, newData) =>
    ({ ...data, ...newData }), { ...initialData });

  const updateUser = (item, data) => {
    const { userid } = currentUser;
    removeNotification();
    // Only update of values have been changed
    const changedKeys = Object.keys(data).filter(k => state[k] !== data[k]);

    if (changedKeys.length === 0) return;
    new UpdateUserAction(apiService)
      .execute(data)
      .then(() => {
        if (changedKeys.includes('optIn')) {
          trackProductUpdatesOptIn(userid, data.optIn);
        }
        addNotification({
          type: 'success',
          message: `Your ${item} has been updated.`,
        });
        setState(data);
      })
      .catch(err => {
        let message = '';
        if (err.reason && err.reason.error.details) {
          const { details } = err.reason.error;
          const firstKey = Object.keys(details[0])[0];
          message = details[0][firstKey];
        }
        addNotification({
          type: 'failure',
          message: `Could not update ${item}. ${message}`,
        });
      });
  };

  useEffect(() => () => {
    removeNotification();
  }, []);

  const deleteUser = () => deleteUserAction(apiService, currentUser.userid)
    .then(() => {
      handleCloseModal();
      router.push('/logout');
    })
    .catch(() => {
      handleCloseModal();
      addNotification({
        type: 'failure',
        message: 'Error: Something went wrong while deleting your account. Please try again later or contact <a href="mailto:support@aperian.com">support@aperian.com</a>.',
      });
    });

  const uploadProfileImage = imageFile => uploadAvatarAction(apiService, imageFile)
    .then(res => {
      const { profile: { avatarUrl } } = res.meta.auth;
      setState({ avatarUrl });
      handleCloseModal();
      addNotification({
        type: 'success',
        message: 'We have uploaded your new avatar. It might take few minutes to update everywhere.',
      });
    })
    .then(() => trackAvatarType('Photo'))
    .catch(() => {
      handleCloseModal();
      addNotification({
        type: 'failure',
        message: 'Error while uploading the profile. Try again later.',
      });
    });

  const createInitials = initials => createInitialsAction(apiService, initials)
    .then(res => {
      const { profile: { avatarUrl } } = res.meta.auth;
      setState({ avatarUrl });
      handleCloseModal();
      addNotification({
        type: 'success',
        message: 'Initials created successfully.',
      });
    })
    .then(() => trackAvatarType('Initials'))
    .catch(() => {
      handleCloseModal();
      addNotification({
        type: 'failure',
        message: 'Error while creating Initials. Try again later',
      });
    });

  const deleteProfileImage = async () => {
    try {
      const res = await deleteAvatarImageAction(apiService);
      const { profile: { avatarUrl } } = res.meta.auth;
      setState({ avatarUrl });
      await trackAvatarType('Default');
      handleCloseModal();
      addNotification({
        type: 'success',
        message: 'Your photo has been removed. It might take a few minutes to update everywhere',
      });
    } catch {
      handleCloseModal();
      addNotification({
        type: 'failure',
        message: 'Error while deleting the photo. Try again later.',
      });
    }
  };

  const handleOpenConfirmModal = () => {
    handleCloseModal();
    handleOpenModal({
      content: (
        <ConfirmModal
          confirmButtonText="Yes, Delete My Account"
          onCancel={handleCloseModal}
          onConfirm={deleteUser}
          title="Confirm Deletion"
          confirmText="DELETE"
        >
          <p className="text-base">Confirm deletion by typing <b>DELETE</b> in the box.</p>
        </ConfirmModal>
      ),
    });
  };

  const renderEmailFields = () => (
    <AccountSettingsEmailFields
      email={state.email}
      optIn={state.optIn}
      onUpdate={updateUser}
    />
  );

  const renderEmailAndPassword = () => (
    <div className="flex flex-col gap-4">
      <div className="py-2">
        {renderEmailFields()}
      </div>
      <div className="py-2">
        <AccountSettingsPasswordFields onUpdate={updateUser} />
      </div>
    </div>
  );

  const renderEmailWithoutPassword = () => (
    <div className="py-2">
      {renderEmailFields()}
    </div>
  );

  return (
    <div className="my-4">
      <Notification {...notificationMessage} />
      <div className="grid gap-2 md:grid-cols-6">
        <div className="md:col-span-2">
          <h4>Profile</h4>
          <p className="text-sm">If you need to change your email address, please contact <a href="mailto:support@aperian.com">support@aperian.com</a>.</p>
        </div>
        <div className="flex flex-col gap-4 md:col-span-4">
          <div className="flex flex-wrap justify-center md:flex-nowrap">
            <UserAvatarSettings
              {...state}
              handleCloseModal={handleCloseModal}
              handleOpenModal={handleOpenModal}
              uploadProfileImage={uploadProfileImage}
              userId={currentUser.userid}
              deleteProfileImage={deleteProfileImage}
              createInitials={createInitials}
            />
            <div className="w-full mt-1 ml-2">
              <AccountSettingsNamePhotoFields
                firstName={state.firstName}
                lastName={state.lastName}
                onUpdate={updateUser}
              />
            </div>
          </div>
          {(currentUser.externalAuth)
            ? renderEmailWithoutPassword()
            : renderEmailAndPassword()}
        </div>
      </div>

      {/* Commenting out for September Launch 2023 to be revisited later */}
      {/* <div className="p-1 mb-10">
        <h3 className="mb-2 text-base font-normal text-center">Demographics</h3>
        <AccountSettingsDemographics
          homeCountry={state.homeCountry}
          currentLocation={state.currentLocation}
          jobType={state.jobType}
          blockedContent={currentUser.blockedContent}
          onUpdate={updateUser}
        />
      </div> */}

      <hr className="my-8 border border-stone-400" />

      <div className="grid gap-2 md:grid-cols-6">
        <div className="md:col-span-2">
          <h4>Delete</h4>
          <p className="text-sm">
            Deleting your Aperian account will delete
            all your records on the Aperian platform.
          </p>
        </div>
        <div className="flex flex-col md:col-span-4">
          <div className="py-2">
            <Button
              className="float-right"
              isWarning
              onClick={() =>
                handleOpenModal({
                  content: <DeleteAccount
                    confirmButtonText="Yes, Delete My Account"
                    onCancel={handleCloseModal}
                    onConfirm={handleOpenConfirmModal}
                  />,
                  modalSize: 'large',
                })}
            >Delete My Account
            </Button>
          </div>
        </div>
      </div>
      <GlobeSmartModal />
    </div>
  );
};

AccountSettingsPersonal.getAPIDataKey = () => 'accountPersonal';
AccountSettingsPersonal.getData = apiService => apiService.get('users/current')
  .then(({ accountPersonal }) => ({ accountPersonal }));

AccountSettingsPersonal.propTypes = {
  initialData: PropTypes.shape({
    currentLocation: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    homeCountry: PropTypes.string,
    id: PropTypes.string,
    jobType: PropTypes.string,
    lastName: PropTypes.string,
    avatarUrl: PropTypes.string,
    optIn: PropTypes.bool,
  }).isRequired,
};

export default withServerSideData(AccountSettingsPersonal);
