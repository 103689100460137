import auth0 from 'auth0-js';
import { AUTH_CONFIG } from './auth0-variables';

export default class Auth {
  constructor() {
    this.auth0 = new auth0.WebAuth({
      domain: AUTH_CONFIG.domain,
      clientID: AUTH_CONFIG.clientId,
      redirectUri: AUTH_CONFIG.callbackUrl,
      responseType: 'token id_token',
      scope: 'openid email profile',
    });
  }

  login = (options = {}) => {
    this.auth0.authorize(options);
  };

  logout = () => {
    this.auth0.logout();
  };

  handleAuthentication = (hash, state) => new Promise((resolve, reject) => {
    this.auth0.parseHash({ hash, state, __enableIdPInitiatedLogin: true }, (err, authResult) => {
      if (err) {
        reject(err);
        return;
      }
      resolve(authResult);
    });
  });

  refresh = () => new Promise((resolve, reject) => {
    this.auth0.checkSession({}, (err, authResult) => {
      if (err) {
        reject(err);
        return;
      }
      resolve(authResult);
    });
  });
}
