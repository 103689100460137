import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../common/Button';
import Notification from '../../../Notifications/Notification';

const GroupMemberVersionModal = ({
  notification,
  handleFormSubmit,
  members,
}) => {
  const legalNotice = 'Your group\'s members have taken different versions of the survey. This report will only include the members who have completed version 3.0.';

  return (
    <>
      <h2 className="m-0 font-heading-2">Version 3.0 Upgrade Note</h2>
      <div className="mt-2 text-max-width">
        <p>{legalNotice}</p>
      </div>
      {notification && <Notification type={notification.type} message={notification.message} />}

      <div className="assessment__upgrade-note">
        <div className="my-1"><b>Users not included: </b></div>
        {
        members.map(member => (
          <div className="my-1" key={member.id}>{member.name}</div>
        ))
        }
      </div>
      <Button
        className="my-025"
        onClick={handleFormSubmit}
        filledColor="green"
        isNormalCase
      >
        Yes, email me the report
      </Button>
    </>
  );
};

GroupMemberVersionModal.propTypes = {
  notification: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string,
  }),
  members: PropTypes.arrayOf(PropTypes.shape({
    accessCode: PropTypes.string,
    dateJoined: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    remindedAt: PropTypes.string,
  })).isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
};

GroupMemberVersionModal.defaultProps = {
  notification: {},
};

export default GroupMemberVersionModal;
