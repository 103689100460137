import React from 'react';
import PropTypes from 'prop-types';

import ProfileCardTextWithButton from '../../../../../ProfileCards/ProfileCardTextWithButton';

const ConfirmRemove = (
  {
    ownerId,
    ownerUserId,
    confirmRemove,
    removeOwner,
  }) => {
  const options = {
    messageText: 'Are you sure you want to remove this group owner?',
    confirmText: 'Yes, Remove',
    confirmButton: {
      filledColor: 'red',
      isNormalCase: true,
      onClick: () => removeOwner(ownerId, ownerUserId),
    },
    cancelText: 'No, Keep',
    cancelButton: {
      isNormalCase: true,
      isCancel: true,
      onClick: () => confirmRemove(false),
    },
  };

  return <ProfileCardTextWithButton {...options} />;
};

ConfirmRemove.propTypes = {
  ownerId: PropTypes.number.isRequired,
  ownerUserId: PropTypes.string,
  confirmRemove: PropTypes.func.isRequired,
  removeOwner: PropTypes.func.isRequired,
};

ConfirmRemove.defaultProps = {
  ownerUserId: null,
};

export default ConfirmRemove;
