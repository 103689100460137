import React from 'react';
import PropTypes from 'prop-types';

import Badges from '../../../common/Badges';
import Icon from '../../../common/Icon';

const CourseCatalogSubContainer = ({
  prework,
  tags,
  duration,
}) => (
  <div className="flex flex-col justify-center flex-grow gap-2 p-4 rounded-lg md:p-6 bg-ivory-400">
    <div className="flex flex-col gap-4 md:gap-0">
      {duration ? (
        <div className="flex items-center gap-2 mt-2 mb-4 italic">
          <Icon icon="clock" iconColor="dark-gray" />
          <p className="mb-0 text-sm font-medium text-slate-900 md:text-base">{duration} minutes</p>
        </div>
      ) : null}
      <div className="flex flex-col gap-2 mb-4">
        <p className="mb-0 text-base font-medium text-charcoal-900">
          Prework
        </p>
        {prework.map(work => (
          <div className="flex gap-2" key={prework.indexOf(work)}>
            <Icon className="w-4 h-4" icon="quarter-pie" iconColor="gray" />
            <p className="mb-0 text-sm text-slate-900">
              {work}
            </p>
          </div>
        ))}
        {(prework.length === 0) && (<p className="mb-0 text-sm text-slate-900">None at this time</p>)}
      </div>
    </div>
    {tags.length > 0 ? (<Badges badges={tags} color="green" />) : null}
  </div>
);

CourseCatalogSubContainer.propTypes = {
  prework: PropTypes.arrayOf(PropTypes.string),
  tags: PropTypes.arrayOf(PropTypes.string),
  duration: PropTypes.number,
};

CourseCatalogSubContainer.defaultProps = {
  prework: [],
  tags: [],
  duration: null,
};

export default CourseCatalogSubContainer;
