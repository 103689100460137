import React, { useContext } from 'react';

import { Context } from '../../context/ContextProvider';

import BodyWrapper from '../BodyWrapper';
import AperianWordmark from '../AperianWordmark';
import ForgotPasswordForm from './Form';
import RustDividingLine from '../common/RustDividingLine';

import RequestPasswordResetAction from '../../actions/requestPasswordReset';

const ForgotPassword = () => {
  const { router, apiService } = useContext(Context);
  const handleSubmit = email =>
    new RequestPasswordResetAction(apiService)
      .execute(email)
      .then(response => {
        if (response.error) {
          router.replace('/error');
        } else {
          router.push(
            `/forgot-password/complete?email=${encodeURIComponent(email)}`,
          );
        }
      });

  return (
    <BodyWrapper documentTitle="Forgot Password | Aperian">
      <div className="flex items-center justify-center mb-6">
        <AperianWordmark />
      </div>
      <div>
        <h1 className="mb-2 text-2xl font-normal font-headline">Forgot Password</h1>
        <RustDividingLine />
        <p className="mt-3 mb-0 font-sans text-xs font-normal">Enter your email and we will send you a link to reset your password.</p>
      </div>
      <ForgotPasswordForm handleFormSubmit={handleSubmit} />
    </BodyWrapper>
  );
};

export default ForgotPassword;
