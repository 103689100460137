import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../../components/common/Button';

const CohortConfirmModal = ({ onHandleRedirect, onHandleRemain }) => (
  <>
    <h2 className="text-2xl text-center">Cohort Saved</h2>
    <p className="mb-8 text-center">What would you like to do next?</p>
    <div className="flex items-center justify-end gap-2">
      <Button
        onClick={onHandleRedirect}
      >View Details
      </Button>
      <Button
        onClick={onHandleRemain}
        outlinedColor="green"
      >Create New Cohort
      </Button>
    </div>
  </>
);

CohortConfirmModal.propTypes = {
  onHandleRedirect: PropTypes.func.isRequired,
  onHandleRemain: PropTypes.func.isRequired,
};

export default CohortConfirmModal;
