class OrgDirectoryDownloadAction {
  constructor(apiService) {
    this.apiService = apiService;
  }

  execute(orgId) {
    return this.apiService.get(`organizations/${orgId}/members/csv`);
  }
}

export default OrgDirectoryDownloadAction;
