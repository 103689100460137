/* eslint-disable react/prop-types */
import React from 'react';

const TeamAvatar = ({ className }) => (
  <svg className={className} viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="2" y="2" width="64" height="64" rx="32" fill="#FFE3E0" />
    <path d="M42.3333 49V45.6667C42.3333 43.8986 41.6309 42.2029 40.3807 40.9526C39.1305 39.7024 37.4348 39 35.6667 39H22.3333C20.5652 39 18.8695 39.7024 17.6193 40.9526C16.369 42.2029 15.6667 43.8986 15.6667 45.6667V49M52.3333 49V45.6667C52.3322 44.1895 51.8406 42.7546 50.9356 41.5872C50.0306 40.4198 48.7635 39.5859 47.3333 39.2167M40.6667 19.2167C42.1007 19.5838 43.3717 20.4178 44.2794 21.5872C45.1871 22.7565 45.6797 24.1947 45.6797 25.675C45.6797 27.1553 45.1871 28.5935 44.2794 29.7628C43.3717 30.9322 42.1007 31.7662 40.6667 32.1333M35.6667 25.6667C35.6667 29.3486 32.6819 32.3333 29 32.3333C25.3181 32.3333 22.3333 29.3486 22.3333 25.6667C22.3333 21.9848 25.3181 19 29 19C32.6819 19 35.6667 21.9848 35.6667 25.6667Z" stroke="#E64833" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <rect x="2" y="2" width="64" height="64" rx="32" stroke="#FFC9C2" strokeWidth="4" />
  </svg>
);

export default TeamAvatar;
