import PropTypes from 'prop-types';

const propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  first: PropTypes.bool,
  independent_interdependent: PropTypes.number.isRequired,
  egalitarianism_status: PropTypes.number.isRequired,
  risk_certainty: PropTypes.number.isRequired,
  direct_indirect: PropTypes.number.isRequired,
  task_relationship: PropTypes.number.isRequired,
  onTraitLinePositionsChanged: PropTypes.func.isRequired,
  isCulture: PropTypes.bool,
};

const defaultProps = {
  first: false,
  currentTraitLinePosition: null,
  isCulture: false,
};

export {
  propTypes,
  defaultProps,
};
