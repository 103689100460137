import React, { useContext, useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { propTypes } from './types';

import { CurrentUserContext } from '../../../context/CurrentUser';
import { NotificationContext } from '../../../context/Notification';

import emailNeedsVerified from '../notices/EmailNeedsVerified';
import teamsPromoFeature from '../notices/TeamsPromo';
import updateToProfessional from '../notices/UpdateToProfessional';
import NewConnection from '../notices/NewConnection';
import Notifications from '../index';
import Notification from '../Notification';

import { COOKIE_LANGUAGE } from '../../../lib/constants';

const DashboardNotifications = ({ location, layoutClasses }) => {
  const [cookies] = useCookies();
  const languages = {
    'zh-CN': '你好',
    'zh-TW': '你好',
    cs: 'Ahoj',
    da: 'Hej',
    nl: 'Hallo',
    en: 'Hello',
    fr: 'Bonjour',
    de: 'Hallo',
    it: 'Ciao',
    ja: 'こんにちは',
    ko: '안녕하세요',
    pl: 'Cześć',
    pt: 'Olá',
    ro: 'Buna ziua',
    ru: 'Здравствуйте',
    es: 'Hola',
    th: 'สวัสดี',
    tr: 'Merhaba',
    vi: 'Xin chào',
  };

  const languageCodes = Object.keys(languages);

  const getRandomLanguage = () => {
    const randomIndex = Math.floor(Math.random() * languageCodes.length);
    return languageCodes[randomIndex];
  };
  const cookieLanguage = cookies[COOKIE_LANGUAGE];
  const defaultLanguage = languageCodes.includes(cookieLanguage) ? cookieLanguage : 'en';
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);
  const [showMessage, setShowMessage] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowMessage(false);
      setTimeout(() => {
        setSelectedLanguage(getRandomLanguage());
        setShowMessage(true);
      }, 500);
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  const { currentUser } = useContext(CurrentUserContext);
  const { notificationMessage } = useContext(NotificationContext);
  return (
    <div id="dashboard" className={layoutClasses}>
      {emailNeedsVerified(currentUser, location)}
      {teamsPromoFeature(currentUser)}
      {updateToProfessional(currentUser)}
      <NewConnection location={location} />
      <Notifications location={location} />
      <Notification {...notificationMessage} />
      <div className="flex pt-8 ml-2 transition-transform duration-1000 ease-in-out transform">
        <h2 className={`transform ${showMessage ? 'opacity-100' : 'opacity-0'} transition-opacity ease-in duration-500`}>
          {languages[selectedLanguage]}
        </h2>
        <h2>, {currentUser.firstName}!</h2>
      </div>
    </div>
  );
};

DashboardNotifications.propTypes = propTypes;

export default DashboardNotifications;
