import PropTypes from 'prop-types';

import { profilePropTypes } from '../../../pages/Profile';

const propTypes = {
  maxAvatars: PropTypes.number.isRequired,
  numHidden: PropTypes.number.isRequired,
  profiles: PropTypes.arrayOf(profilePropTypes),
};

export {
  propTypes,
};
