import React from 'react';
import { iconPropTypes, defaultIconProps } from './types';

const IbiProductIcon = ({ className }) => (
  <svg
    className={className}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 1279.8 1297.9"
    enableBackground="new 0 0 1279.8 1297.9"
    xmlSpace="preserve"
  >
    <circle fill="#F2F0E7" cx="635.3" cy="653.5" r="188.3" />
    <g>
      <g>
        <path
          fill="#1E1E28"
          d="M942.1,239.5c70.5,53.8,85.7,154.3,33.2,226.4c-50,68.9-143.7,87.3-215.5,44.9 c38.6,33.6,63.1,82.9,63.1,138.1c0,25.7-5.4,50.2-14.9,72.4c-17.5,81.1,28.9,163.9,109.6,190.1c86.1,28,178.5-19.1,206.4-105.2 l0,0l0,0l0,0c16.1-49.5,24.9-102.4,24.9-157.3C1148.9,481,1067.5,332.2,942.1,239.5z"
        />
        <path
          fill="#F2F0E7"
          d="M997.7,287.4c-17.4-17.2-35.9-33.3-55.6-47.9c70.5,53.8,85.7,154.3,33.2,226.4 c-50,68.9-143.7,87.3-215.5,44.9c8.1,7.1,15.5,15,22.3,23.4c19.9,7.4,40.5,11.1,61,11.1c54.3,0,107.5-25.4,141.7-72.6 c27.6-37.9,38.7-84.3,31.4-130.6C1013,322.7,1006.7,304.3,997.7,287.4z"
        />
        <path
          fill="#1E1E28"
          d="M1124,806.2c-28,86.1-120.4,133.1-206.4,105.2C837,885.2,790.5,802.4,808,721.3 C780,786.4,715.3,832,639.9,832c-5.5,0-11-0.3-16.4-0.8c-82.8,8.2-147.5,78.1-147.5,163c0,90.5,73.4,163.8,163.8,163.8l0,0 C866.1,1158,1057.8,1010.4,1124,806.2L1124,806.2z"
        />
        <path
          fill="#F2F0E7"
          d="M917.6,911.4C837,885.2,790.5,802.4,808,721.3c-4.2,9.8-9.3,19-15.1,27.9C789.8,826.4,838,897.8,914,922.5 c17.9,5.8,36.2,8.6,54.1,8.6c48.2,0,94.1-20.1,127-54.5c11.3-22.6,21-46,28.9-70.3l0,0C1096.1,892.3,1003.6,939.4,917.6,911.4z"
        />
        <path
          fill="#1E1E28"
          d="M476.1,994.1c0-85,64.7-154.8,147.5-163c-79.7-7-144.6-65.1-162-141.3c-33.3-76.3-119.8-116.3-200.7-90 c-86.1,28-133.2,120.4-105.2,206.4l0,0l0,0c66.3,204.1,257.9,351.7,484.1,351.7C549.4,1158,476.1,1084.6,476.1,994.1z"
        />
      </g>
      <path
        fill="#1E1E28"
        d="M939.1,237.1c-0.7-0.5-1.4-0.9-2.1-1.4c-83.6-60.2-186.2-95.9-297.1-95.9s-213.5,35.6-297.1,95.9 c73.1-51.4,174.1-34.9,226.8,37.6c50.1,68.9,38.6,163.7-24,219c27.6-16.7,59.8-26.4,94.4-26.4c45.9,0,87.8,17,119.9,45 c71.9,42.3,165.5,23.9,215.5-44.9C1028.5,392.7,1012.3,290.3,939.1,237.1z"
      />
      <path
        fill="#1E1E28"
        d="M456.9,648.9c0-66.5,35.6-124.6,88.7-156.6c62.6-55.3,74.1-150.1,24-219c-53.2-73.2-155.6-89.4-228.8-36.2 c-1.1,0.8-2,1.6-3,2.4C212.3,332.3,130.9,481,130.9,648.9c0,54.9,8.8,107.7,24.9,157.2l0.1,0.1c-28-86.1,19.1-178.5,105.2-206.4 c80.9-26.3,167.3,13.7,200.7,90C458.6,676.7,456.9,663,456.9,648.9z"
      />
      <path
        fill="#F2F0E7"
        d="M569.5,273.4c50.1,68.9,38.6,163.7-24,219c9.3-5.6,19.2-10.4,29.5-14.3c48-60.7,51.1-146.6,3.9-211.5 c-27.5-37.9-68.2-62.8-114.5-70.2c-19.8-3.1-39.7-2.7-58.9,0.8c-21.9,11.4-42.9,24.3-62.8,38.6 C415.8,184.3,516.9,200.8,569.5,273.4z"
      />
      <path
        fill="#F2F0E7"
        d="M476.1,994.1c0-85,64.7-154.8,147.5-163c-10.9-1-21.4-3-31.7-5.7c-74.4,20.9-127.4,88.7-127.4,168.7 c0,69.7,41,129.9,100,158.2c24.6,3.7,49.8,5.7,75.5,5.7C549.4,1158,476.1,1084.6,476.1,994.1z"
      />
      <path
        fill="#F2F0E7"
        d="M461.6,689.8c-2.3-10.3-3.6-20.9-4.2-31.8c-42.8-64.5-123.8-94.1-200.1-69.3c-44.6,14.5-80.8,45.5-102.1,87.2 c-9.1,17.9-14.9,37-17.5,56.4c4.2,25.3,10.2,49.9,18,73.8l0.1,0.1c-28-86.1,19.1-178.5,105.2-206.4 C341.8,573.5,428.3,613.5,461.6,689.8z"
      />
    </g>
    <g>
      <g>
        <path
          fill="#F2F0E7"
          d="M927.9,591.1l0.5-0.5c8.1-8.1,21.2-8.1,29.2,0l15,15c0.2,0.2,0.2,0.5,0,0.6l-14.5,14.5 c-8.3,8.3-21.9,8.3-30.2,0l0,0C919.7,612.5,919.7,599.3,927.9,591.1z"
        />
        <g>
          <path
            fill="#F2F0E7"
            d="M1005.1,633.5l-27.3-27.3c-0.2-0.2-0.2-0.5,0-0.6l27.3-27.3c0.2-0.2,0.5-0.2,0.6,0l3.9,3.9 c13.1,13.1,13.1,34.2,0,47.3l-3.9,3.9C1005.5,633.7,1005.3,633.7,1005.1,633.5z"
          />
        </g>
      </g>
      <g>
        <path
          fill="#F2F0E7"
          d="M961.5,647.9h25.9c0.7,0,1.2-0.7,0.9-1.4l-13-33c-0.3-0.9-1.6-0.9-1.9,0l-13,33 C960.3,647.1,960.8,647.9,961.5,647.9z"
        />
      </g>
      <g>
        <path
          fill="#F2F0E7"
          d="M987.4,563.9h-25.9c-0.7,0-1.2,0.7-0.9,1.4l13,33c0.3,0.9,1.6,0.9,1.9,0l13-33 C988.6,564.7,988.1,563.9,987.4,563.9z"
        />
      </g>
    </g>
    <g>
      <path
        fill="#F2F0E7"
        d="M692.6,932.9L692.6,932.9c9.9,0,17.9,8,17.9,17.9V970c0,9.9-8,17.9-17.9,17.9l0,0c-9.9,0-17.9-8-17.9-17.9 v-19.2C674.7,940.9,682.7,932.9,692.6,932.9z"
      />
      <circle fill="#F2F0E7" cx="692.6" cy="892.6" r="17.7" />
      <g>
        <path
          fill="#F2F0E7"
          d="M693.3,910.3c-9.6,0-17.4,7.6-17.7,17.1c0,0.4,0.3,0.7,0.6,0.7h34.2c0.4,0,0.7-0.3,0.6-0.7 C710.7,917.9,702.9,910.3,693.3,910.3z"
        />
      </g>
    </g>
    <g>
      <path
        fill="#F2F0E7"
        d="M813.1,271v17.1c0,8.5-6.9,15.5-15.5,15.5l0,0c-8.5,0-15.5-6.9-15.5-15.5V271c0-0.4,0.4-0.8,0.8-0.8h29.3 C812.7,270.2,813.1,270.6,813.1,271z"
      />
      <g>
        <path
          fill="#F2F0E7"
          d="M729.1,315.1c8.3,0,15.1-6.6,15.4-14.8c0-0.3-0.2-0.6-0.6-0.6h-29.6c-0.3,0-0.6,0.3-0.6,0.6 C714.1,308.5,720.8,315.1,729.1,315.1z"
        />
      </g>
      <path
        fill="#F2F0E7"
        d="M778.9,282.8v17c0,8.5-6.9,15.4-15.4,15.4l0,0c-8.5,0-15.4-6.9-15.4-15.4v-17c0-0.4,0.4-0.8,0.8-0.8H778 C778.5,282,778.9,282.3,778.9,282.8z"
      />
      <circle fill="#F2F0E7" cx="797.6" cy="231.2" r="15.3" />
      <g>
        <path
          fill="#F2F0E7"
          d="M797.6,251.2c-8.4,0-15.2,6.6-15.5,14.9c0,0.3,0.2,0.6,0.6,0.6h29.8c0.3,0,0.6-0.3,0.6-0.6 C812.8,257.8,806,251.2,797.6,251.2z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          fill="#F2F0E7"
          d="M339.2,337.1c0,13.1,10.4,23.7,23.3,24.2c0.5,0,0.9-0.4,0.9-0.9v-46.7c0-0.5-0.4-0.9-0.9-0.9 C349.5,313.4,339.2,324,339.2,337.1z"
        />
      </g>
      <g>
        <path
          fill="#F2F0E7"
          d="M363.3,367.1v23.5c0,0.2-0.1,0.3-0.3,0.3h-23.5c-0.2,0-0.3-0.1-0.3-0.3l0,0c0-13.2,10.7-23.8,23.8-23.8l0,0 C363.2,366.8,363.3,367,363.3,367.1z"
        />
      </g>
      <circle fill="#F2F0E7" cx="393.1" cy="337" r="24.1" />
      <g>
        <path
          fill="#F2F0E7"
          d="M392.4,366.2c-13.4,0-24.3,10.6-24.8,23.8c0,0.5,0.4,0.9,0.9,0.9h47.7c0.5,0,0.9-0.4,0.9-0.9 C416.7,376.8,405.8,366.2,392.4,366.2z"
        />
      </g>
    </g>
    <g>
      <circle fill="#F2F0E7" cx="358.3" cy="729.4" r="29" />
      <path
        fill="#F2F0E7"
        d="M339.4,766.1L339.4,766.1c0,10.2-8.3,18.5-18.5,18.5h-17.5c-0.2,0-0.3-0.2-0.3-0.3v-17.9 c0-10,8.1-18.1,18.1-18.1h0.4C331.4,748.3,339.4,756.3,339.4,766.1z"
      />
      <g>
        <path
          fill="#F2F0E7"
          d="M302.9,800.3h-15.3c-0.1,0-0.2-0.1-0.2-0.2v-15.3c0-0.1,0.1-0.2,0.2-0.2h1.1c7.9,0,14.3,6.4,14.3,14.3v1.1 C303.1,800.2,303,800.3,302.9,800.3z"
        />
      </g>
    </g>
    <g>
      <g>
        <g>
          <path
            fill="#1E1E28"
            d="M604.2,547v33.9c0,0.4,0.3,0.7,0.7,0.7h33.9c0.4,0,0.7-0.3,0.7-0.7c-0.4-19-15.7-34.3-34.6-34.6 C604.5,546.3,604.2,546.6,604.2,547z"
          />
          <path
            fill="#1E1E28"
            d="M621.8,620.2c9.7,0,17.6-7.9,17.6-17.6s-7.9-17.6-17.6-17.6c-9.7,0-17.6,7.9-17.6,17.6 S612.1,620.2,621.8,620.2z"
          />
          <path
            fill="#1E1E28"
            d="M660.4,581.6c9.7,0,17.6-7.9,17.6-17.6s-7.9-17.6-17.6-17.6c-9.7,0-17.6,7.9-17.6,17.6 S650.7,581.6,660.4,581.6z"
          />
          <path
            fill="#1E1E28"
            d="M678.1,619.5v-33.9c0-0.4-0.3-0.7-0.7-0.7h-33.9c-0.4,0-0.7,0.3-0.7,0.7c0.4,18.9,15.6,34.2,34.6,34.6 C677.8,620.2,678.1,619.9,678.1,619.5z"
          />
        </g>
        <text transform="matrix(1 0 0 1 494.0252 670.1202)" fill="#1E1E28" fontFamily="IvarHeadline-Medium" fontSize="35.4785px">Inclusive Behaviors</text>
        <text transform="matrix(1 0 0 1 568.1252 712.7202)" fill="#1E1E28" fontFamily="IvarHeadline-Medium" fontSize="35.4785px">Inventory</text>
      </g>
    </g>
    <text transform="matrix(1 0 0 1 892.1365 707.7217)" fill="#F2F0E7" fontFamily="Roboto-Medium" fontSize="44.4697px">Working </text>
    <text transform="matrix(1 0 0 1 904.5365 761.1217)" fill="#F2F0E7" fontFamily="Roboto-Medium" fontSize="44.4697px">Across </text>
    <text transform="matrix(1 0 0 1 861.5365 814.4217)" fill="#F2F0E7" fontFamily="Roboto-Medium" fontSize="44.4697px">Boundaries</text>
    <text transform="matrix(1 0 0 1 600.3513 1040.0734)" fill="#F2F0E7" fontFamily="Roboto-Medium" fontSize="44.4697px">Becoming </text>
    <text transform="matrix(1 0 0 1 581.5513 1093.4734)" fill="#F2F0E7" fontFamily="Roboto-Medium" fontSize="44.4697px">a Champion</text>
    <text transform="matrix(1 0 0 1 682.4998 381.4678)" fill="#F2F0E7" fontFamily="Roboto-Medium" fontSize="44.4697px">Building </text>
    <text transform="matrix(1 0 0 1 667.5998 434.8678)" fill="#F2F0E7" fontFamily="Roboto-Medium" fontSize="44.4697px">Key Skills</text>
    <text transform="matrix(1 0 0 1 279.0227 458.3487)" fill="#F2F0E7" fontFamily="Roboto-Medium" fontSize="44.4697px">Learning </text>
    <text transform="matrix(1 0 0 1 257.4227 511.7487)" fill="#F2F0E7" fontFamily="Roboto-Medium" fontSize="44.4697px">About Bias</text>
    <text transform="matrix(1 0 0 1 266.677 863.8409)" fill="#F2F0E7" fontFamily="Roboto-Medium" fontSize="44.4697px">Getting </text>
    <text transform="matrix(1 0 0 1 264.977 917.2409)" fill="#F2F0E7" fontFamily="Roboto-Medium" fontSize="44.4697px">Results</text>
    <path
      fill="#DFDBD0"
      d="M1266.8,648.9c0,346.2-280.6,626.8-626.8,626.8S13.1,995.1,13.1,648.9S293.7,22.1,639.9,22.1 S1266.8,302.7,1266.8,648.9z M639.9,86.6c-310.6,0-562.3,251.8-562.3,562.3s251.8,562.3,562.3,562.3s562.3-251.8,562.3-562.3 S950.5,86.6,639.9,86.6z"
    />
    <path id="SVGID_x5F_1_x5F_" fill="none" d="M1100.7,300.1c0,0,135.4,169.7,115.9,403.9" />
    <text transform="matrix(0.5028 0.8644 -0.8644 0.5028 1131.9869 347.4172)" fontFamily="ArialMT" fontSize="12px"> </text>
    <text transform="matrix(0.4847 0.8747 -0.8747 0.4847 1133.7491 350.2502)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4786px">P</text>
    <text transform="matrix(0.4584 0.8887 -0.8887 0.4584 1144.5876 369.9202)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4786px">s</text>
    <text transform="matrix(0.4352 0.9004 -0.9004 0.4352 1151.7128 383.6918)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4786px">y</text>
    <text transform="matrix(0.411 0.9116 -0.9116 0.411 1159.7147 400.2763)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4786px">c</text>
    <text transform="matrix(0.3846 0.9231 -0.9231 0.3846 1166.5701 415.4006)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4786px">h</text>
    <text transform="matrix(0.3553 0.9348 -0.9348 0.3553 1174.7213 434.9898)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4786px">o</text>
    <text transform="matrix(0.3333 0.9428 -0.9428 0.3333 1181.7786 453.714)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4786px">l</text>
    <text transform="matrix(0.3109 0.9504 -0.9504 0.3109 1185.2853 463.4634)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4786px">o</text>
    <text transform="matrix(0.2824 0.9593 -0.9593 0.2824 1191.4927 482.4873)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4786px">g</text>
    <text transform="matrix(0.2605 0.9655 -0.9655 0.2605 1196.5114 499.6865)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4786px">i</text>
    <text transform="matrix(0.2393 0.9709 -0.9709 0.2393 1199.2897 509.8515)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4786px">c</text>
    <text transform="matrix(0.2118 0.9773 -0.9773 0.2118 1203.2729 525.9716)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4786px">a</text>
    <text transform="matrix(0.1889 0.982 -0.982 0.1889 1207.0247 543.4911)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4786px">l</text>
    <text transform="matrix(0.1748 0.9846 -0.9846 0.1748 1208.9683 553.6666)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4786px"> </text>
    <text transform="matrix(0.152 0.9884 -0.9884 0.152 1210.2367 560.3608)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4786px">S</text>
    <text transform="matrix(0.1195 0.9928 -0.9928 0.1195 1213.3392 580.668)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4786px">a</text>
    <text transform="matrix(9.387859e-02 0.9956 -0.9956 9.387859e-02 1215.4421 598.461)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4786px">f</text>
    <text transform="matrix(6.863983e-02 0.9976 -0.9976 6.863983e-02 1216.5936 610.3273)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4786px">e</text>
    <text transform="matrix(4.277409e-02 0.9991 -0.9991 4.277409e-02 1217.7405 627.4252)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4786px">t</text>
    <text transform="matrix(1.555889e-02 0.9999 -0.9999 1.555889e-02 1218.3087 639.7598)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4786px">y</text>
    <text fontFamily="ArialMT" fontSize="12px"> </text>
    <path id="SVGID_x5F_00000107558922332211113460000000009240555777193606_x5F_" fill="none" d="M433.9,1225.8c0,0,188.5,71,412,0" />
    <text transform="matrix(0.9789 0.2045 -0.2045 0.9789 494.7844 1242.3823)" fontFamily="ArialMT" fontSize="12px"> </text>
    <text transform="matrix(0.9826 0.186 -0.186 0.9826 498.0314 1243.1478)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4785px">L</text>
    <text transform="matrix(0.9874 0.158 -0.158 0.9874 519.163 1247.1151)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4785px">e</text>
    <text transform="matrix(0.9911 0.1332 -0.1332 0.9911 536.0837 1249.8271)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4785px">a</text>
    <text transform="matrix(0.9943 0.1062 -0.1062 0.9943 553.8391 1252.2297)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4785px">d</text>
    <text transform="matrix(0.9968 7.999203e-02 -7.999203e-02 0.9968 574.1974 1254.3827)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4785px">e</text>
    <text transform="matrix(0.9984 5.718239e-02 -5.718239e-02 0.9984 591.2796 1255.7444)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4785px">r</text>
    <text transform="matrix(0.9994 3.546334e-02 -3.546334e-02 0.9994 606.794 1256.6332)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4785px">s</text>
    <text transform="matrix(1 9.668220e-03 -9.668220e-03 1 622.2885 1257.2201)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4785px">h</text>
    <text transform="matrix(0.9999 -1.275265e-02 1.275265e-02 0.9999 643.504 1257.3654)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4785px">i</text>
    <text transform="matrix(0.9994 -3.488863e-02 3.488863e-02 0.9994 654.0419 1257.2871)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4785px">p</text>
    <text fontFamily="ArialMT" fontSize="12px"> </text>
    <path
      id="SVGID_x5F_00000178916456707932626870000000836298214331838142_x5F_"
      fill="none"
      d="M69.2,740.3 c0,0-50.3-287.6,159.4-495.9"
    />
    <text transform="matrix(1.585407e-02 -0.9999 0.9999 1.585407e-02 63.9692 653.3678)" fontFamily="ArialMT" fontSize="12px"> </text>
    <text transform="matrix(3.528529e-02 -0.9994 0.9994 3.528529e-02 63.9292 650.0311)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4783px">E</text>
    <text transform="matrix(7.583255e-02 -0.9971 0.9971 7.583255e-02 64.6286 627.5433)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4783px">m</text>
    <text transform="matrix(0.1151 -0.9934 0.9934 0.1151 67.1542 595.7823)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4783px">p</text>
    <text transform="matrix(0.1441 -0.9896 0.9896 0.1441 69.5491 575.2728)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4783px">a</text>
    <text transform="matrix(0.1671 -0.9859 0.9859 0.1671 72.1628 557.5481)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4783px">t</text>
    <text transform="matrix(0.1929 -0.9812 0.9812 0.1929 74.1682 545.3635)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4783px">h</text>
    <text transform="matrix(0.2236 -0.9747 0.9747 0.2236 78.2814 524.5494)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4783px">y</text>
    <text transform="matrix(0.2434 -0.9699 0.9699 0.2434 82.4567 506.6159)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4783px"> </text>
    <text transform="matrix(0.2681 -0.9634 0.9634 0.2681 84.0067 499.9805)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4783px">&amp;</text>
    <text transform="matrix(0.2929 -0.9561 0.9561 0.2929 90.6293 476.5982)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4783px"> </text>
    <text transform="matrix(0.3164 -0.9486 0.9486 0.3164 92.5322 470.0544)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4783px">P</text>
    <text transform="matrix(0.3485 -0.9373 0.9373 0.3485 99.6808 448.7639)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4783px">e</text>
    <text transform="matrix(0.3751 -0.927 0.927 0.3751 105.6629 432.7054)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4783px">r</text>
    <text transform="matrix(0.4005 -0.9163 0.9163 0.4005 111.4919 418.3001)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4783px">s</text>
    <text transform="matrix(0.43 -0.9028 0.9028 0.43 117.6627 404.0753)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4783px">p</text>
    <text transform="matrix(0.4608 -0.8875 0.8875 0.4608 126.5688 385.4453)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4783px">e</text>
    <text transform="matrix(0.4881 -0.8728 0.8728 0.4881 134.4687 370.2376)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4783px">c</text>
    <text transform="matrix(0.5114 -0.8594 0.8594 0.5114 142.5984 355.7595)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4783px">t</text>
    <text transform="matrix(0.5296 -0.8482 0.8482 0.5296 148.9204 345.1538)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4783px">i</text>
    <text transform="matrix(0.5524 -0.8336 0.8336 0.5524 154.4563 336.187)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4783px">v</text>
    <text transform="matrix(0.5799 -0.8147 0.8147 0.5799 164.6367 320.8424)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4783px">e</text>
    <text transform="matrix(0.6021 -0.7984 0.7984 0.6021 174.6021 306.9016)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4783px">-</text>
    <text transform="matrix(0.6287 -0.7777 0.7777 0.6287 181.8542 297.1253)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4783px">T</text>
    <text transform="matrix(0.6581 -0.7529 0.7529 0.6581 196.6174 278.9442)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4783px">a</text>
    <text transform="matrix(0.6846 -0.7289 0.7289 0.6846 208.3781 265.4517)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4783px">k</text>
    <text transform="matrix(0.7056 -0.7086 0.7086 0.7056 222.7661 250.2467)" fontFamily="IvarHeadline-SemiBold" fontSize="35.4783px">i</text>
    <text fontFamily="ArialMT" fontSize="12px"> </text>
    <path
      id="SVGID_x5F_00000064333741294476309660000001982983063575097746_x5F_"
      fill="none"
      d="M1376.2,744 c0,0-51.2-292.7,162.3-504.8"
    />
    <text transform="matrix(0.7054 -0.7088 0.7088 0.7054 1532.4885 245.2276)" fontFamily="IvarHeadline-SemiBold" fontSize="36.1123px">i</text>
    <text fontFamily="ArialMT" fontSize="12px"> </text>
  </svg>
);

IbiProductIcon.propTypes = iconPropTypes;
IbiProductIcon.defaultProps = defaultIconProps;

export default IbiProductIcon;
