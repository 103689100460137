import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Context } from '../../../context/ContextProvider';

import Tabs from '../../Tabs';

import needsAuthentication from '../../../lib/needsAuthentication';

const tabList = [
  {
    name: 'Search Organizations',
    link: '/administration/organizations/search',
  },
  {
    name: 'Add Organization',
    link: '/administration/organizations/add',
  },
];
const Organizations = ({ children, location }) => {
  const { currentUser, router } = useContext(Context);

  useEffect(() => {
    if (!children) {
      router.push('/administration/organizations/search');
    }
    needsAuthentication(router, currentUser);
  }, [children]);

  return (
    <div>
      <Tabs tabs={tabList} location={location} />
      {children}
    </div>
  );
};

Organizations.propTypes = {
  children: PropTypes.node,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

Organizations.defaultProps = {
  children: null,
};

export default Organizations;
