import React from 'react';
import PropTypes from 'prop-types';

import ProfileCardAvatarWithButton from '../../../../../ProfileCards/ProfileCardAvatarWithButton';

const CurrentTeamOwnerCard = (
  {
    displayName,
    displayDescription,
    ownerLength,
    setRemove,
    ownerUserId,
    avatarUrl,
  }) => {
  const buttonOptions = {
    confirmText: '',
    confirmButton: {},
    cancelText: ownerLength > 1 ? 'Remove' : '',
    cancelButton: {
      isNormalCase: true,
      isWarning: true,
      onClick: () => setRemove(true),
    },
  };

  return (
    <ProfileCardAvatarWithButton
      displayName={displayName}
      displayDescription={displayDescription}
      {...buttonOptions}
      memberUserId={ownerUserId}
      avatarUrl={avatarUrl}
    />
  );
};

CurrentTeamOwnerCard.propTypes = {
  displayName: PropTypes.string.isRequired,
  displayDescription: PropTypes.shape({}).isRequired,
  ownerLength: PropTypes.number.isRequired,
  setRemove: PropTypes.func.isRequired,
  ownerUserId: PropTypes.string,
  avatarUrl: PropTypes.string.isRequired,
};

CurrentTeamOwnerCard.defaultProps = {
  ownerUserId: null,
};

export default CurrentTeamOwnerCard;
