import React from 'react';
import BodyWrapper from '../BodyWrapper';
import AperianWordmark from '../AperianWordmark';
import Button from '../common/Button';
import RustDividingLine from '../common/RustDividingLine';

const Expired = () => (
  <BodyWrapper>
    <div className="flex items-center justify-center mb-6">
      <AperianWordmark />
    </div>
    <div>
      <h1 className="mb-2 text-2xl font-normal font-headline">You have been logged out.</h1>
      <RustDividingLine />
      <p className="mt-2 mb-0 font-sans text-xs font-normal">The session with this browser has expired.</p>
    </div>
    <Button variant="primary" buttonType="link" rel="noopener noreferrer" target="_blank" to={process.env.EXPIRED_LOGIN_REDIRECT}>
      Login
    </Button>
    <p className="mb-0 font-sans text-xs font-normal text-center">Questions? <a rel="noopener noreferrer" target="_blank" href="https://aperian.zendesk.com/hc">Contact us</a>.</p>
  </BodyWrapper>
);

export default Expired;
