import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { APIContext } from '../../../context/API';

import Button from '../../common/Button';

import { trackClickCTA } from '../../../lib/tracker/common';

const CourseCatalogCTAs = ({ router, pageURL }) => {
  const { apiService } = useContext(APIContext);
  return (
    <div className="flex flex-col gap-4 py-10 lg:py-12 lg:gap-8 md:flex-row">
      <div className="flex flex-col w-full px-8 py-6 text-3xl bg-white lg:px-4 lg:py-12 rounded-3xl md:w-1/2">
        <div className="h-full">
          <div className="flex flex-col items-center justify-center h-full gap-6 mx-auto pb-9">
            <span className="text-2xl font-semibold md:text-3xl font-headline text-charcoal-900">View Your GlobeSmart Profile</span>
            <p className="w-full my-auto font-sans text-base font-light leading-7 tracking-wide text-center md:text-lg text-charcoal-900 md:w-5/6">
              Discover your work-style preferences and
              how they relate to other colleagues and cultures.
            </p>
          </div>
        </div>
        <div className="flex justify-around justify-self-end">
          <Button
            trailingButtonIcon="arrow-right"
            variant="primary"
            onClick={async () => {
              await trackClickCTA(
                apiService,
                'Course Catalog',
                'Go to My Profile',
                '/profile',
                pageURL,
              );
              await router.replace('/profile');
            }}
          >
            Go to My Profile
          </Button>
        </div>
      </div>
      <div className="flex flex-col w-full px-8 py-6 text-3xl bg-white lg:px-4 lg:py-12 rounded-3xl md:w-1/2">
        <div className="h-full">
          <div className="flex flex-col items-center justify-center h-full gap-6 mx-auto pb-9">
            <span className="text-2xl font-semibold md:text-3xl font-headline text-charcoal-900">More from Aperian Live</span>
            <p className="w-full my-auto font-sans text-base font-light leading-7 tracking-wide text-center md:text-lg text-charcoal-900 md:w-5/6">
              Learn more about all the programs Aperian Live can offer your team.
              All sessions are led by an expert Aperian facilitator and
              cover a wide variety of topics to help your team grow.
            </p>
          </div>
        </div>
        <div className="flex justify-around justify-self-end">
          <Button
            trailingButtonIcon="arrow-right"
            variant="primary"
            onClick={async () => {
              await trackClickCTA(
                apiService,
                'Course Catalog',
                'View Course Catalog',
                'https://aperian.zendesk.com/hc/en-us/articles/29167750042899-Aperian-Live-Programs',
                pageURL,
              );
              await window.open('https://aperian.zendesk.com/hc/en-us/articles/29167750042899-Aperian-Live-Programs');
            }}
          >
            View Course Catalog
          </Button>
        </div>
      </div>
    </div>
  );
};

CourseCatalogCTAs.propTypes = {
  router: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  pageURL: PropTypes.string.isRequired,
};

export default CourseCatalogCTAs;
