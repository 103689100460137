import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import ElementQuery from 'react-eq';

import { APIContext } from '../../../context/API';
import { RouterContext } from '../../../context/Router';
import withServerSideData from '../../../HOC/withServerSideData';

import formatDate from '../../../lib/dateFormatter';
import Button from '../../../components/common/Button';
import DeleteCohortButton from '../../../components/Cohorts/DeleteCohortButton';
import CohortParticipantsTable from '../../../components/Cohorts/CohortParticipantsTable';
import CopyText from '../../../components/CopyText';
import BackArrowLink from '../../../components/common/BackArrowLink';

import getDownloadFileName from '../../../lib/fileDownload';
import Notification from '../../../components/Notifications/Notification';
import { deleteCohortAction, cohortsDirectoryDownloadAction, deactivateCohortAction } from '../../../actions/cohorts';
import { CurrentUserContext } from '../../../context/CurrentUser';

const queries = { 'card-layout': 400 };

const CohortsOverview = ({
  initialData: {
    name,
    displayName,
    code,
    sfJobCode,
    sfLink,
    linkExpiryDate,
    participants,
    type,
    deactivated: isCohortDeactived,
    org: {
      name: orgName,
      auth0ConnectionId,
    },
    cohortPlans,
    cohortEventsData,
  },
}) => {
  const { apiService } = useContext(APIContext);
  const { router } = useContext(RouterContext);
  const { currentUser: { allowSkillsTracking } } = useContext(CurrentUserContext);
  const [notification, setNotification] = useState(null);
  const [deactivated, setDeactivated] = useState(isCohortDeactived);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [refreshRequired, setRefreshRequired] = useState(false);
  const [participantsList, setParticipantsList] = useState(participants);

  const handleConfirmDelete = () => setConfirmDelete(true);
  const handleCancelDelete = () => setConfirmDelete(false);

  const handleSetRefresh = () => setRefreshRequired(true);

  const formattedMeetingDatetime = new Date(cohortEventsData?.meetingDatetime)
    .toLocaleString([], {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short',
    });

  useEffect(() => {
    if (refreshRequired) {
      const fetchData = async () => {
        const cohortData = await CohortsOverview.getData(apiService, { code });
        setParticipantsList(cohortData.cohort.participants);
        setRefreshRequired(false);
      };
      fetchData()
        .catch(console.error);
    }
  }, [refreshRequired]);

  const handleCompleteDelete = () => {
    deleteCohortAction(apiService, code)
      .then(() => router.push('/cohorts/search'))
      .catch(err => {
        setNotification({
          type: 'warning',
          message: `Could not remove member from cohort: ${err}`,
        });
      });
  };

  const handleDeactivateCohort = () => {
    deactivateCohortAction(apiService, code)
      .then(() => {
        setDeactivated(true);
        setNotification({
          type: 'success',
          message: 'Your cohort enrollment link is now deactivated. Please inform your members that enrollment is closed.',
        });
      })
      .catch(err => {
        setNotification({
          type: 'warning',
          message: `Could not deactivate cohort enrollment link: ${err}`,
        });
      });
  };

  const userError = err => {
    setNotification({
      type: 'warning',
      message: `Could not remove member from cohort: ${err}`,
    });
  };

  const exportParticipantsCSV = e => {
    const { dataset: { code: cohortCode } } = e.currentTarget;
    cohortsDirectoryDownloadAction(apiService, cohortCode)
      .then(async response => ({
        filename: getDownloadFileName(response),
        blob: await response.blob(),
      }))
      .then(({ blob, filename }) => saveAs(blob, filename))
      .catch(err => {
        setNotification({
          type: 'warning',
          message: `Could not download cohort directory: ${err}`,
        });
      });
  };

  const renderCohortStatus = () => {
    if (new Date() > new Date(linkExpiryDate)) {
      return 'Expired';
    }
    if (deactivated) {
      return 'Deactivated';
    }
    return 'Active';
  };

  const renderCohortPlans = () => {
    const plansString = cohortPlans.join(', ');
    return plansString;
  };

  const enrollmentLink = `${process.env.SITE_URL}/enroll/cohorts/${code}${auth0ConnectionId ? `?sp=${auth0ConnectionId}` : ''}`;
  return (
    <>
      <div className="flex mx-0 mt-0 mb-3 text-center align-self-start text-uppercase text-md">
        <BackArrowLink to="/cohorts/search">
          Back to Cohorts
        </BackArrowLink>
      </div>
      <div className="admin-dashboard-layout__header">
        <h2 className="mb-1 font-heading-2">{displayName}</h2>
        {type === 'Aperian Live' ? null : (
          <Button
            variant="primary"
            isSmall
            to={`/cohorts/${code}/edit`}
          >Edit
          </Button>
        )}
      </div>
      <Notification {...notification} />
      <div className="mb-1 container--flat">
        <div className="field-display">
          <p><b>Enrollment Link</b></p>
          <CopyText text={enrollmentLink} showText />
        </div>
        <div className="cohort-info">
          <div className="field-display">
            <p><b>Cohort Code</b> </p>
            {code}
          </div>
          <div className="field-display">
            <p><b>Cohort Type</b> </p>
            {type}
          </div>
          {orgName && (
            <div className="field-display">
              <p><b>Organization Name</b> </p>
              {orgName}
            </div>
          )}
          {linkExpiryDate && (
            <div className="field-display">
              <p><b>Enrollment Link Expires</b> </p>
              {formatDate(linkExpiryDate)}
            </div>
          )}
          {sfJobCode && (
            <div className="field-display">
              <p><b>SF Job Code</b> </p>
              {sfJobCode}
            </div>
          )}
          {sfLink && (
            <div className="field-display">
              <p><b>SF Link</b> </p>
              <a
                href={sfLink}
                rel="noopener noreferrer"
                target="_blank"
              >{sfLink}
              </a>
            </div>
          )}
          <div className="field-display">
            <p><b>Enrollment Link Type</b> </p>
            {auth0ConnectionId ? 'SSO' : 'Email / Password'}
          </div>
          {type === 'Aperian Live' ? (
            <>
              <div className="field-display">
                <p><b>Internal Name</b> </p>
                {name}
              </div>
              <div className="field-display">
                <p><b>Meeting Date/Time</b> </p>
                {formattedMeetingDatetime}
              </div>
              <div className="field-display">
                <p><b>Meeting Link</b> </p>
                https://aperian.zoom.us/j/{cohortEventsData.meetingId}
              </div>
              <div className="field-display">
                <p><b>Facilitator</b> </p>
                {cohortEventsData.facilitator}
              </div>
              <div className="field-display">
                <p><b>Evaluation Link</b> </p>
                {cohortEventsData.evaluationLink}
              </div>
            </>
          ) : null}
          <div className="field-display">
            <p><b>Cohort Status</b> </p>
            {renderCohortStatus()}
          </div>
        </div>
        <div className="field-display">
          <p><b>Plan{cohortPlans.length > 1 ? 's' : null}</b> </p>
          {renderCohortPlans()}
        </div>
      </div>
      <CohortParticipantsTable
        onExportParticipants={exportParticipantsCSV}
        participants={participantsList}
        cohortName={displayName}
        cohortCode={code}
        setReload={handleSetRefresh}
        error={userError}
        setNotification={setNotification}
        cohortType={type}
        allowSkillsTracking={allowSkillsTracking}
      />
      <div className="flex flex-col items-end gap-2 mb-6">
        <div className="mt-2 text-center">
          <Button
            isSmall
            disabled={deactivated}
            onClick={handleDeactivateCohort}
            variant="secondary"
          >Deactivate enrollment link
          </Button>
        </div>
        <ElementQuery queries={queries}>
          <div className="box-border w-11/12 mt-1 text-center ">
            <DeleteCohortButton
              isDeleteConfirmed={confirmDelete}
              confirmDelete={handleConfirmDelete}
              cancelDelete={handleCancelDelete}
              completeDelete={handleCompleteDelete}
            />
          </div>
        </ElementQuery>
      </div>
    </>
  );
};

CohortsOverview.getAPIDataKey = () => 'cohort';

CohortsOverview.getData = (apiService, { code }) => apiService.get(`cohorts/${code}/overview`)
  .then(cohort => ({ cohort }));

CohortsOverview.propTypes = {
  initialData: PropTypes.shape({
    name: PropTypes.string,
    displayName: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    sfJobCode: PropTypes.string,
    sfLink: PropTypes.string,
    linkExpiryDate: PropTypes.string.isRequired,
    deactivated: PropTypes.bool.isRequired,
    participants: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        surveyStatus: PropTypes.string,
        dateJoined: PropTypes.string,
        email: PropTypes.string,
        ibiSurveyStatus: PropTypes.string,
        attendanceStatus: PropTypes.string,
        attendanceDuration: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        culturalAgilitySkillStatus: PropTypes.string,
        teamCollaborationSkillStatus: PropTypes.string,
      }),
    ).isRequired,
    org: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      auth0ConnectionId: PropTypes.string,
    }),
    cohortPlans: PropTypes.arrayOf(PropTypes.string),
    cohortEventsData: PropTypes.shape({
      meetingId: PropTypes.number,
      meetingDatetime: PropTypes.string,
      evaluationLink: PropTypes.string,
      facilitator: PropTypes.string,
    }),
  }),
  params: PropTypes.shape({
    code: PropTypes.string,
  }).isRequired,
};

CohortsOverview.defaultProps = {
  initialData: {
    cohortEventsData: {
      meetingId: null,
      meetingDatetime: null,
      evaluationLink: null,
      facilitator: null,
    },
  },
};

export default withServerSideData(CohortsOverview);
