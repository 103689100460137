import React from 'react';
import PropTypes from 'prop-types';

import Card from '../../Card';
import Button from '../../common/Button';

const OrganizationYouOwn = ({ orgId, orgName, exportOrgDirectory }) => (
  <ul className="profile-group__list">
    <li className="profile-group__item">
      <Card key={orgId} displayName={orgName}>
        <Button
          isSmall
          isNormalCase
          leadingButtonIcon="download"
          variant="primary"
          dataID={orgId}
          onClick={exportOrgDirectory}
        >
          Download User Directory
        </Button>
      </Card>
    </li>
  </ul>
);

OrganizationYouOwn.propTypes = {
  orgName: PropTypes.string,
  orgId: PropTypes.string,
  exportOrgDirectory: PropTypes.func.isRequired,
};

OrganizationYouOwn.defaultProps = {
  orgId: null,
  orgName: null,
};

export default OrganizationYouOwn;
