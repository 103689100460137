import React, { useContext, useEffect, useReducer, useRef } from 'react';
import PropTypes from 'prop-types';

import { zonedTimeToUtc } from 'date-fns-tz';
import { NotificationContext } from '../../../context/Notification';

import { Form } from '../../common/Form';
import CohortFormHeader from './FormHeader';
import EventCohortForm from './EventForm';
import NonEventCohortForm from './NonEventForm';
import Notification from '../../Notifications/Notification';
import Button from '../../common/Button';
import DropdownSelect from '../../common/Dropdowns/DropdownSelect';

const CohortForm = ({ cohortFormData, handleFormSubmit, buttonText }) => {
  const {
    notificationMessage,
    removeNotification,
  } = useContext(NotificationContext);

  const { cohort, types } = cohortFormData;

  const [formData, setFormData] = useReducer((data, newData) =>
    ({ ...data, ...newData }),
  {
    type: types?.at(0)?.value,
    ...cohort,
  });

  const [formError, setFormError] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
    });

  const notificationRef = useRef(null);
  const formRef = useRef(null);

  useEffect(() => {
    setFormData(cohort);
  }, [cohort]);

  useEffect(() => {
    let timeout;
    if (notificationMessage) {
      notificationRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      timeout = window.setTimeout(() => {
        removeNotification();
      }, 5000);
    }
    return () => {
      window.clearTimeout(timeout);
      if (notificationMessage) removeNotification();
    };
  }, [notificationMessage]);

  const handleChange = ({ target: { value } }) => setFormData({ type: value });

  const handleFormData = data => {
    setFormData(data);
    const [name] = Object.keys(data);
    if (formError[name]) setFormError({ [name]: null });
  };

  const handleFormError = error => setFormError(error);

  const onSubmit = e => {
    e.preventDefault();
    const formInvalidations = formRef.current.validateForm();
    if (formInvalidations) {
      setFormError(formInvalidations);
    } else if (formData.type === '5') {
      const zonedDate = zonedTimeToUtc(formData.eventDate, formData.timezone);
      const utcDate = zonedDate.toISOString();
      const formattedDate = utcDate.substring(0, utcDate.length - 1);
      const clearForm = () => formRef.current.resetForm();
      handleFormSubmit({ ...formData, dateTime: formattedDate }, clearForm);
    } else {
      const clearForm = () => formRef.current.resetForm();
      handleFormSubmit(formData, clearForm);
    }
  };

  return (
    <>
      <Notification ref={notificationRef} {...notificationMessage} />
      <Form handleFormSubmit={onSubmit} bgColor="white">
        <CohortFormHeader formData={formData} />
        <DropdownSelect
          id="type"
          name="type"
          labelType="text"
          labelText="Type"
          options={types}
          value={formData.type}
          onChangeValue={handleChange}
          helperText="Choose a cohort type based on your needs. If unsure, choose General."
        />
        {(parseInt(formData.type, 10) === 5)
          ? (
            <EventCohortForm
              ref={formRef}
              formData={formData}
              handleFormData={handleFormData}
              facilitators={cohortFormData.facilitators}
              topicOptions={cohortFormData.topicOptions}
              formError={formError}
              handleFormError={handleFormError}
            />
          )
          : (
            <NonEventCohortForm
              ref={formRef}
              formData={formData}
              handleFormData={handleFormData}
              plans={cohortFormData.plans}
              formError={formError}
              handleFormError={handleFormError}
            />
          )}
        <div className="flex justify-end mt-2">
          <Button
            filledColor="secondary"
            type="submit"
          >
            {buttonText}
          </Button>
        </div>
      </Form>
    </>
  );
};

CohortForm.propTypes = {
  cohortFormData: PropTypes.shape({
    cohort: PropTypes.shape({
      name: PropTypes.string,
      displayName: PropTypes.string,
      type: PropTypes.number,
      code: PropTypes.string,
      linkExpiryDate: PropTypes.string,
      redirectUrl: PropTypes.string,
      sfJobCode: PropTypes.string,
      sfLink: PropTypes.string,
      org: PropTypes.string,
      orgName: PropTypes.string,
      stripePlans: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          value: PropTypes.string,
        })),
      eventDate: PropTypes.instanceOf(Date),
      evaluationLink: PropTypes.string,
      eventPrework: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          value: PropTypes.number,
        })),
      eventTime: PropTypes.string,
      facilitator: PropTypes.string,
      language: PropTypes.string,
      meetingPlatform: PropTypes.string,
      tags: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          value: PropTypes.number,
        })),
      timezone: PropTypes.string,
      topic: PropTypes.string,
    }),
    facilitators: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.number,
        timezone: PropTypes.string,
      })),
    plans: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
      })),
    types: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
    topicOptions: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
      })),
  }).isRequired,
  buttonText: PropTypes.string.isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
  notificationMessage: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string,
  }),
};

CohortForm.defaultProps = {
  notificationMessage: {},
};

export default CohortForm;
