import tracker from '../index';

const {
  eventNames, eventProps, peopleProps, resolveAfterTimeout,
} = tracker;

const trackInvitationSent = ({ userId, numberInvitationsSent, hasCustomMessage }) => {
  tracker.identify(userId);
  tracker.people.increment({
    [peopleProps.NUMBER_INVITATIONS_SENT]: numberInvitationsSent,
  });

  tracker.track(eventNames.SEND_INVITE, {
    [eventProps.NUMBER_INVITATIONS_SENT]: numberInvitationsSent,
    [eventProps.CUSTOM_MESSAGE]: hasCustomMessage,
  });

  // Track appcues 'Send Invite' event
  window?.Appcues?.track(eventNames.SEND_INVITE);

  return resolveAfterTimeout;
};

const trackInvitationAccepted = ({
  userId,
  invitationType,
  teamName,
  acceptMethod,
}) => {
  tracker.identify(userId);
  if (invitationType === 'team') {
    tracker.people.increment({
      [peopleProps.NUMBER_TEAMS_AS_MEMBER]: 1,
    });

    tracker.track(eventNames.ACCEPT_TEAM_INVITE, {
      [eventProps.TEAM_NAME]: teamName,
      [eventProps.ACCEPT_METHOD]: acceptMethod,
    });

    // Track appcues 'Accept Team Invite' event
    window?.Appcues?.track(eventNames.ACCEPT_TEAM_INVITE);
  } else if (invitationType === 'rater') {
    tracker.track(eventNames.ACCEPT_RATER_INVITATION, {
      [eventProps.ACCEPT_METHOD]: acceptMethod,
    });

    // Track appcues 'Accept Rater Invite' event
    window?.Appcues?.track(eventNames.ACCEPT_INVITE);
  } else {
    tracker.people.increment(peopleProps.NUMBER_INVITATIONS_ACCEPTED);

    tracker.track(eventNames.ACCEPT_INVITE, {
      [eventProps.NUMBER_INVITATIONS_ACCEPTED]: 1,
      [eventProps.ACCEPT_METHOD]: acceptMethod,
    });

    // Track appcues 'Accept Invite' event
    window?.Appcues?.track(eventNames.ACCEPT_INVITE);
  }

  return resolveAfterTimeout;
};

const trackInviteToOrganization = async ({
  apiService,
  invitationCount,
  invitees,
  invitationMethod,
}) => {
  await apiService.post('trackers/mixpanel', {
    eventName: eventNames.INVITE_TO_ORGANIZATION,
    properties: {
      [eventProps.NUMBER_INVITATIONS_SENT]: invitationCount,
      [eventProps.INVITEES]: invitees,
      [eventProps.INVITATION_METHOD]: invitationMethod,
    },
  });
  return resolveAfterTimeout;
};

export {
  trackInvitationSent,
  trackInvitationAccepted,
  trackInviteToOrganization,
};
