/* eslint-disable no-param-reassign */
import React, { useContext, useEffect, useState } from 'react';

import { PopupWidget } from 'react-calendly';

import { CurrentUserContext } from '../../context/CurrentUser';

const CalendlyWidget = () => {
  const { currentUser } = useContext(CurrentUserContext);

  const [componentIsMounted, setComponentIsMounted] = useState(false);

  useEffect(() => {
    setComponentIsMounted(true);
  }, []);

  const getRightStyle = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth <= 640) {
      return '50%';
    }
    if (windowWidth === 1024) {
      return '52%';
    }
    if (windowWidth === 1280) {
      return '60%';
    }
    if (windowWidth > 640 && windowWidth < 1480) {
      return '65%';
    }
    if (windowWidth > 2500) {
      return '80%';
    }
    return '72%';
  };

  useEffect(() => {
    if (componentIsMounted) {
      // looking for mount of widget to override default styles
      const widgets = document.querySelectorAll('.calendly-badge-widget');
      if (widgets.length > 0) {
        widgets.forEach(widget => {
          widget.style.bottom = 'auto';
          widget.style.right = getRightStyle();
        });
      }
    }
  }, [componentIsMounted]);

  return (
    currentUser.isTrial && componentIsMounted ? (
      <PopupWidget
        url="https://calendly.com/d/ck3x-xjs-9t5?hide_gdpr_banner=1&text_color=1e1e28&primary_color=326441"
        rootElement={document.getElementById('root')}
        text="Book a live demo"
        color="#326441"
        textColor="#FFFFFF"
        branding
      />
    ) : null
  );
};

export default CalendlyWidget;
