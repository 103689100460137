import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { ModalContext } from '../../../../../context/Modal';

import GlobeSmartModal from '../../../../GlobeSmartModal';
import Button from '../../../../common/Button';
import Payment from '../../../../Payment';

import determinePlanByType from '../../../../../lib/assessments/determinePlanByType';

const SummaryBlock = ({
  seatsRemaining,
  asmtType,
  refreshData,
}) => {
  const { handleOpenModal, handleCloseModal } = useContext(ModalContext);
  return (
    <div className="summary-block__card">
      <div className="card--small summary-block__cardborder">
        <div className="text-center label__message">
          {seatsRemaining ? (
            <>
              <b>{seatsRemaining} Seats Available</b>
              <br />
            </>
          ) : null}
          <Button
            isAnchorTag
            onClick={() =>
              handleOpenModal({
                content: <Payment
                  planId={determinePlanByType(asmtType)}
                  handleCloseModal={handleCloseModal}
                  forOthers
                />,
                afterCloseAction: () => refreshData(),
              })}
          >
            Buy Seats
          </Button>
          {seatsRemaining ? (
            <>
              <br />
              <Button
                isAnchorTag
                to={`${process.env.SITE_URL}/account-settings/summary`}
              >
                View All Access Codes
              </Button>
            </>
          ) : null}
        </div>
      </div>
      <GlobeSmartModal />
    </div>
  );
};

SummaryBlock.propTypes = {
  seatsRemaining: PropTypes.number,
  asmtType: PropTypes.string.isRequired,
  refreshData: PropTypes.func.isRequired,
};

SummaryBlock.defaultProps = {
  seatsRemaining: null,
};

export default SummaryBlock;
