import React from 'react';
import PropTypes from 'prop-types';

import { membersTypes } from '../types';

import SectionContainer from '../../../common/Containers/Section';
import TeamMemberCards from './TeamMemberCards';

const TeamMembers = ({
  isOwner,
  members,
  onRemind,
  onRemove,
  currentUserId,
}) => (
  <SectionContainer title="Team Members">
    <ul className="profile-group__list">
      <TeamMemberCards
        isOwner={isOwner}
        members={members}
        onRemind={onRemind}
        onRemove={onRemove}
        currentUserId={currentUserId}
      />
    </ul>
  </SectionContainer>
);

TeamMembers.propTypes = {
  onRemind: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  members: membersTypes.isRequired,
  isOwner: PropTypes.bool,
  currentUserId: PropTypes.string.isRequired,
};
TeamMembers.defaultProps = {
  isOwner: false,
};

export default TeamMembers;
