import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Context } from '../../../../context/ContextProvider';

import Button from '../../../common/Button';
import LabelInput from '../../../common/LabelInput';

class AccountSettingsNamePhotoFields extends Component {
  static contextType = Context;

  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      firstName: this.props.firstName,
      lastName: this.props.lastName,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      editing: false,
      firstName: nextProps.firstName,
      lastName: nextProps.lastName,
    });
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  formSubmit = e => {
    e.preventDefault();
    this.toggleEditing();

    this.props.onUpdate('name', {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
    });
  };

  toggleEditing = () => {
    const { editing } = this.state;
    this.setState({ editing: !editing });
  };

  toggleEditNameButtonText() {
    if (this.context.currentUser.externalAuth) {
      return null;
    }
    if (this.state.editing) {
      return (
        <Button
          key="saveName"
          variant="primary"
          isSmall
          className="float-right mt-3"
          type="submit"
        >
          Save Changes
        </Button>
      );
    }
    return (
      <Button
        className="float-right mt-3"
        key="editName"
        onClick={this.toggleEditing}
        isSmall
        variant="secondary"
      >
        Edit Name
      </Button>
    );
  }

  renderEditName() {
    return (
      <div className="grid grid-cols-2 gap-1">
        <div>
          <LabelInput
            id="firstName"
            name="firstName"
            labelText="First Name"
            labelType="text"
            value={this.state.firstName}
            onChangeValue={this.handleChange}
          />
        </div>
        <div>
          <LabelInput
            id="lastName"
            name="lastName"
            labelText="Last Name"
            labelType="text"
            value={this.state.lastName}
            onChangeValue={this.handleChange}
          />
        </div>
      </div>
    );
  }

  renderNameOrEditField() {
    return this.state.editing ? this.renderEditName() : this.renderFullName();
  }

  renderFullName() {
    const { firstName, lastName } = this.state;
    const displayName = `${firstName || ''} ${lastName || ''}`;
    return (
      <LabelInput
        id="displayName"
        labelType="text"
        labelText="Full Name"
        value={`${displayName}`}
        isDisabled
      />
    );
  }

  render() {
    return (
      <form action="/user" method="PATCH" onSubmit={this.formSubmit}>
        {this.renderNameOrEditField()}
        <div>{this.toggleEditNameButtonText()}</div>
      </form>
    );
  }
}

AccountSettingsNamePhotoFields.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
};

AccountSettingsNamePhotoFields.defaultProps = {
  firstName: '',
  lastName: '',
};

export default AccountSettingsNamePhotoFields;
