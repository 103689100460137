import React from 'react';
import PropTypes from 'prop-types';
import ProfileCardTextWithButtons from '../../ProfileCards/ProfileCardTextWithButton';

const ConfirmRemoveConnection = (
  {
    connectionId,
    connectionName,
    email,
    teams,
    handleOnRemove,
    handleConfirmRemove,
  }) => {
  const options = {
    messageText: `Are you sure you want to remove this individual connection with ${connectionName}?`,
    confirmText: 'Yes, Remove',
    confirmButton: {
      filledColor: 'red',
      isNormalCase: true,
      onClick: () => handleOnRemove({ connectionId, connectionName, email, teams }),
    },
    cancelText: 'No, Keep',
    cancelButton: {
      isNormalCase: true,
      isCancel: true,
      onClick: () => handleConfirmRemove(false),
    },
  };

  return <ProfileCardTextWithButtons {...options} />;
};

ConfirmRemoveConnection.propTypes = {
  connectionId: PropTypes.string.isRequired,
  connectionName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    }),
  ),
  handleOnRemove: PropTypes.func.isRequired,
  handleConfirmRemove: PropTypes.func.isRequired,
};

ConfirmRemoveConnection.defaultProps = {
  teams: null,
};

export default ConfirmRemoveConnection;
