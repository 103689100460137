import React from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';

const PendingInvites = ({ pendingTeamInvitations }) => {
  const numInvites = pendingTeamInvitations.length;
  if (!numInvites) return null;

  const plural = numInvites === 1 ? '' : 's';

  return (
    <p className="leading-loose my-[1em] font-sans text-xs text-center md:font-light md:text-base">
      You have {numInvites} team invitation{plural} waiting for you
      in <Link to="/invitations" className="underline whitespace-nowrap">pending invitations</Link>.
    </p>
  );
};

PendingInvites.propTypes = {
  pendingTeamInvitations: PropTypes.arrayOf(
    PropTypes.shape({

    }),
  ).isRequired,
};

export default PendingInvites;
