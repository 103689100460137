import React from 'react';
import PropTypes from 'prop-types';

import {
  AfterSurveyMessage,
  AfterTrackSelectExplanation,
  AfterTrackSelectMessage,
  BeforeSurveyMessage,
  EmailInput,
  EmailVerification,
  GSPChart,
  InviteOthers,
  OnboardingSingleForm,
  MiniSurvey,
  NameInput,
  TrackSelection,
} from '..';

const TopRightFade = () => (
  <svg className="fixed top-0 right-0 overflow-hidden -z-10" width="504" height="579" viewBox="0 0 504 579" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_f_1956_161534)">
      <path d="M613.93 386.571L613.93 -26.7181C613.93 -31.3354 610.192 -35 605.648 -35L192.358 -35.0001C187.741 -35.0001 183.93 -31.1889 184.003 -26.4983C188.4 204.223 374.634 390.456 605.428 394.927C610.119 395 613.93 391.189 613.93 386.571Z" fill="#F7F6F1" />
    </g>
    <defs>
      <filter id="filter0_f_1956_161534" x="0.00390625" y="-219" width="797.926" height="797.928" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="92" result="effect1_foregroundBlur_1956_161534" />
      </filter>
    </defs>
  </svg>
);

const LeftMiddleFade = () => (
  <svg className="fixed left-0 overflow-hidden -z-10" width="639" height="955" viewBox="0 0 639 955" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_f_1956_161180)">
      <path d="M-54.0871 177H442.017C451.14 177 457.413 186.11 454.105 194.537L202.29 841.801C200.351 846.812 195.561 850 190.201 850H-306.016C-315.14 850 -321.413 840.89 -318.105 832.463L-66.1761 185.199C-64.2373 180.188 -59.4473 177 -54.0871 177Z" fill="#F7F6F1" />
    </g>
    <defs>
      <filter id="filter0_f_1956_161180" x="-503" y="-7" width="1142" height="1041" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="92" result="effect1_foregroundBlur_1956_161180" />
      </filter>
    </defs>
  </svg>
);

const BottomLeftFade = () => (
  <svg className="fixed bottom-0 left-0 overflow-hidden -z-10" width="523" height="553" viewBox="0 0 523 553" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_f_1956_161084)">
      <path d="M13 836C193.045 836 339 690.045 339 510C339 329.955 193.045 184 13 184C-167.045 184 -313 329.955 -313 510C-313 690.045 -167.045 836 13 836Z" fill="#F7F6F1" />
    </g>
    <defs>
      <filter id="filter0_f_1956_161084" x="-497" y="0" width="1020" height="1020" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="92" result="effect1_foregroundBlur_1956_161084" />
      </filter>
    </defs>
  </svg>
);

const BottomLeftCircleFade = () => (
  <svg className="fixed bottom-0 left-0 overflow-hidden -z-10" width="523" height="553" viewBox="0 0 523 553" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_f_1956_161015)">
      <path d="M13 836C193.045 836 339 690.045 339 510C339 329.955 193.045 184 13 184C-167.045 184 -313 329.955 -313 510C-313 690.045 -167.045 836 13 836Z" fill="#F7F6F1" />
    </g>
    <defs>
      <filter id="filter0_f_1956_161015" x="-497" y="0" width="1020" height="1020" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="92" result="effect1_foregroundBlur_1956_161015" />
      </filter>
    </defs>
  </svg>

);

const BottomRightFade = () => (
  <svg className="fixed bottom-0 right-0 overflow-hidden -z-10" width="737" height="532" viewBox="0 0 737 532" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_f_1956_160915)">
      <path d="M448.913 184H945.017C954.14 184 960.413 193.11 957.105 201.537L705.29 848.801C703.351 853.812 698.561 857 693.201 857H196.984C187.86 857 181.587 847.89 184.895 839.463L436.824 192.199C438.763 187.188 443.553 184 448.913 184Z" fill="#F7F6F1" />
    </g>
    <defs>
      <filter id="filter0_f_1956_160915" x="0" y="0" width="1142" height="1041" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="92" result="effect1_foregroundBlur_1956_160915" />
      </filter>
    </defs>
  </svg>
);

const TopLeftFade = () => (
  <svg className="fixed top-0 left-0 overflow-hidden -z-10" width="614" height="579" viewBox="0 0 614 579" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_f_1956_162247)">
      <path d="M-7.30526e-07 386.571L-3.68615e-05 -26.7181C-3.72651e-05 -31.3354 3.73782 -35 8.28188 -35L421.571 -35.0001C426.189 -35.0001 430 -31.1889 429.927 -26.4983C425.529 204.223 239.296 390.456 8.50179 394.927C3.81115 395 -3.26865e-07 391.189 -7.30526e-07 386.571Z" fill="#F7F6F1" />
    </g>
    <defs>
      <filter id="filter0_f_1956_162247" x="-184" y="-219" width="797.926" height="797.928" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="92" result="effect1_foregroundBlur_1956_162247" />
      </filter>
    </defs>
  </svg>
);

const Background = ({ path, step, currentStep }) => (
  <>
    {[GSPChart, InviteOthers].includes(path[step]) && (step === currentStep)
      ? <TopLeftFade /> : null}
    {[BeforeSurveyMessage, MiniSurvey].includes(path[step]) && (step === currentStep)
      ? <TopRightFade /> : null}
    {[AfterTrackSelectExplanation, AfterTrackSelectMessage, AfterSurveyMessage]
      .includes(path[step]) && (step === currentStep)
      ? <LeftMiddleFade /> : null}
    {[OnboardingSingleForm, EmailInput].includes(path[step]) && (step === currentStep)
      ? <BottomLeftFade /> : null}
    {[TrackSelection].includes(path[step]) && (step === currentStep)
      ? <BottomLeftCircleFade /> : null}
    {[NameInput, EmailVerification].includes(path[step]) && (step === currentStep)
      ? <BottomRightFade /> : null}
  </>
);

Background.propTypes = {
  path: PropTypes.shape({}).isRequired,
  step: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
};

export default Background;
