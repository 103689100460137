class ResponsiveAvatars {
  static getWidthWithMargin(el) {
    if (!el) return 0;

    const style = typeof getComputedStyle !== 'undefined' ? getComputedStyle(el, null) : el.currentStyle;

    return el.offsetWidth + parseInt(style.marginLeft, 10) + parseInt(style.marginRight, 10);
  }

  constructor({ container, callback }) {
    this.container = container;
    this.callback = callback;

    window.addEventListener('resize', () => {
      this.callback(this.maxAvatars());
    });

    // Call here to initialize the value
    this.callback(this.maxAvatars());
  }

  getAvailableSpace() {
    const HIDDEN_COMPARISON_BUTTON_WIDTH = 64 + 24; // width + margin
    const NEW_COMPARISON_BUTTON_WIDTH = 48 + 24; // width + margin

    const CONTROLS_WIDTH = HIDDEN_COMPARISON_BUTTON_WIDTH + NEW_COMPARISON_BUTTON_WIDTH;
    const space = this.container.offsetWidth - CONTROLS_WIDTH;

    return space;
  }

  avatarWidth() {
    let width = 0;

    // we use the second avatar because the first one has no left margin
    const avatar = this.container.querySelector('.avatar-container__item:nth-child(2)');

    if (avatar) {
      width = ResponsiveAvatars.getWidthWithMargin(avatar);
    }
    return width;
  }

  maxAvatars() {
    return Math.floor(this.getAvailableSpace() / this.avatarWidth());
  }
}

export default ResponsiveAvatars;
