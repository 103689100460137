import PropTypes from 'prop-types';
import { Cookies } from 'react-cookie';

const propTypes = {
  cookies: PropTypes.instanceOf(Cookies).isRequired,
};

export {
  propTypes,
};
