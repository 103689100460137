/* eslint-disable prefer-arrow-callback */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import Button from '../../../common/Button';

import EditableHeader from '../../../EditableHeader';

const Header = forwardRef(
  function HeaderWithRef({
    completedCount,
    groupName,
    saveName,
    isEditing,
    toggleEditing,
    groupOrTeam,
    emailAsmtGroupReport,
    disableGroupReport,
  }, ref) {
    return (
      <div id="manage-group" className="flex flex-wrap items-center">
        <div className="flex-grow md:pr-8" ref={ref}>
          <EditableHeader
            name="name"
            label="Name"
            value={groupName}
            onUpdate={saveName}
            isEditing={isEditing}
            toggleEditing={toggleEditing}
            isHeading
          />
        </div>
        <div className="text-center">
          <p className="mb-1 text-center">
            {completedCount}
          </p>
          <Button
            isSmall
            isNormalCase
            variant="primary"
            disabled={disableGroupReport}
            onClick={emailAsmtGroupReport}
          >
            {groupOrTeam} Report
          </Button>
        </div>
      </div>
    );
  });

Header.propTypes = {
  completedCount: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  saveName: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  toggleEditing: PropTypes.func.isRequired,
  emailAsmtGroupReport: PropTypes.func.isRequired,
  disableGroupReport: PropTypes.bool,
  groupOrTeam: PropTypes.string.isRequired,
};

Header.defaultProps = {
  disableGroupReport: false,
};

export default Header;
