import React from 'react';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';

import {
  OnboardingHeader,
  MiniSurvey,
  Snapshot,
  MiniComparison,
  GSPChart,
  InviteOthers,
  PasswordInput,
  TrackSelection,
  SelectUseCase,
  OnboardingSingleForm,
} from '../index';

import Background from '../Background';

import Footer from '../../../common/Footer';

const singleRowSteps = () => ([
  MiniSurvey,
  SelectUseCase,
  Snapshot,
  MiniComparison,
  GSPChart,
  InviteOthers,
  PasswordInput,
  TrackSelection,
  OnboardingSingleForm,
]);

const OnboardingForm = ({
  children,
  path,
  step,
  currentStep,
  splitScreen,
  handleSubmit,
  handleRedirectLogin,
  formData,
}) => {
  const isSingleRow = singleRowSteps().includes(path[step]);

  let mobileRows = 'grid-rows-2 ';
  let mobileJustify = 'justify-end';

  if (isSingleRow) {
    mobileRows = 'grid-rows-1 md:grid-rows-2';
  }
  if (
    path[step] === InviteOthers
    || path[step] === PasswordInput
  ) {
    mobileJustify = 'justify-center md:justify-end';
  }
  if (
    path[step] === Snapshot
    || path[step] === MiniComparison
    || path[step] === TrackSelection
  ) {
    mobileJustify = 'justify-start';
  }

  return (
    <div className={`flex justify-between min-h-screen flex-col${splitScreen ? ' split-screen' : ''}`}>
      <Background path={path} step={step} currentStep={currentStep} />
      <OnboardingHeader
        currentStep={currentStep}
        handleRedirectLogin={handleRedirectLogin}
        formData={formData}
      />
      <div>
        <div className="flex flex-col lg:m-auto lg:w-11/12 lg:max-w-6xl">
          <CSSTransition
            in={step === currentStep}
            timeout={600}
            classNames="fade"
            unmountOnExit
          >
            <form onSubmit={handleSubmit} className={`grid flex-grow ${mobileRows} p-0 lg:grid-rows-1`}>
              <div className={`flex flex-col items-center ${mobileJustify} row-start-1 text-center lg:justify-center lg:row-start-1 lg:row-span-1`}>
                {children}
              </div>
            </form>
          </CSSTransition>
        </div>
      </div>
      <Footer />
    </div>
  );
};
OnboardingForm.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.shape({

  }).isRequired,
  step: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  splitScreen: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleRedirectLogin: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    invitationToken: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
};

export default OnboardingForm;
