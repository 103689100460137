class OrganizationAddOwnerAction {
  constructor(apiService) {
    this.apiService = apiService;
  }

  execute(orgId, userId) {
    return this.apiService.post(`organizations/${orgId}/owners`, {
      user: {
        id: userId,
      },
    });
  }
}

export default OrganizationAddOwnerAction;
