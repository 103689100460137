/* eslint-disable react/function-component-definition */
import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

import { CurrentUserContext } from '../CurrentUser';

import APIService from '../../lib/api-service';

export const APIContext = createContext({});

export default function APIProvider({ children }) {
  const { currentUser, cookies } = useContext(CurrentUserContext);
  let apiService = new APIService(currentUser, cookies);
  apiService = { apiService, currentUser };

  return (
    <APIContext.Provider
      value={{
        ...apiService,
      }}
    >
      {children}
    </APIContext.Provider>
  );
}

APIProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const APIConsumer = APIContext.Consumer;
