import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Context } from '../../../context/ContextProvider';

import PageHeader from '../../../components/PageHeader';
import Tabs from '../../../components/Tabs';

import needsAuthentication from '../../../lib/needsAuthentication';

const tabList = [
  {
    name: 'Search Blocks',
    link: '/administration/blocks/search',
  },
  {
    name: 'Create Block',
    link: '/administration/blocks/new',
  },
  {
    name: 'Default Order',
    link: '/administration/blocks/default-sort',
  },
];

const getTitle = pathname => {
  let title;
  if (pathname.includes('search')) {
    title = 'Search';
  } else if (pathname.includes('new')) {
    title = 'Create';
  } else if (pathname.includes('edit')) {
    title = 'Update';
  } else {
    title = 'Sort';
  }
  return title;
};

const BlocksAuth = ({ children, location }) => {
  const { currentUser, router } = useContext(Context);

  useEffect(() => {
    needsAuthentication(router, currentUser);
    if (!children) {
      router.push('/administration/blocks/search');
    }
  }, [children]);

  return (
    <>
      <PageHeader
        pageTitle={`Block | ${getTitle(location.pathname)}`}
        bgColor="ivory"
        skipTarget="#blocks-tabs"
      />
      <div>
        <Tabs tabs={tabList} location={location} />
        <div id="blocks-tabs">
          {children}
        </div>
      </div>
    </>
  );
};

BlocksAuth.propTypes = {
  children: PropTypes.node,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

BlocksAuth.defaultProps = {
  children: null,
  location: {},
};

export default BlocksAuth;
