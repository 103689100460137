import React from 'react';
import PropTypes from 'prop-types';

const CultureAvatarNavigation = ({ id, cultureGuideUrl, guideAccess, language }) => {
  const linkUrl = `/profile/comparison?culture=${id}&language=${language}#advice`;

  return (
    <nav className="avatar__nav avatar__nav--culture js-avatar-nav-content">
      <ul className="avatar__nav-list">
        <li className="avatar__nav-item">
          <a href={linkUrl} className="avatar__nav-link">
            Advice for Me
          </a>
        </li>
        {guideAccess ? (
          <li className="avatar__nav-item">
            <a id="culture-guide-link" href={`${cultureGuideUrl}`} className="avatar__nav-link">
              GlobeSmart Guide
            </a>
          </li>
        ) : null}
      </ul>
    </nav>
  );
};

CultureAvatarNavigation.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  cultureGuideUrl: PropTypes.string.isRequired,
  guideAccess: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
};

export default CultureAvatarNavigation;
