class SurveyCompleteAction {
  constructor(apiService) {
    this.apiService = apiService;
  }

  execute({ language }) {
    return this.apiService.post('profile/survey/complete', { language });
  }
}

export default SurveyCompleteAction;
