import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ElementQuery from 'react-eq';

import { NotificationContext } from '../../../context/Notification';
import { APIContext } from '../../../context/API';

import withServerSideData from '../../../HOC/withServerSideData';

import PageHeader from '../../../components/PageHeader';
import LabelInput from '../../../components/common/LabelInput';
import Notification from '../../../components/Notifications/Notification';
import ActionNavigation from '../../../components/Navigation/ActionNavigation';
import Button from '../../../components/common/Button';
import ActiveConnectionCards from '../../../components/ManageConnections/ActiveConnectionCards';

import { removeConnectionAction } from '../../../actions/manageConnections';

import NeedsCompletedSurvey from '../../../lib/NeedsCompletedSurvey';
import { trackRemoveConnection, trackViewManageConnections } from '../../../lib/tracker/manage-connections';
import { filterItems } from '../../../lib/filter-items';

import {
  ConnectionWithTeamsMessage,
  ConnectionRemovalSuccess,
  ConnectionRemovalError,
} from './notifications';

const queries = { 'card-layout': 400 };

const ManageConnections = ({ initialData: { connections } }) => {
  const [initialConnections, setInitialConnections] = useState((connections) || []);
  const [filteredArray, setFilteredArray] = useState((connections) || []);
  const [filterValue, setFilterValue] = useState('');

  const {
    notificationMessage,
    removeNotification,
    addNotification,
  } = useContext(NotificationContext);

  const { apiService } = useContext(APIContext);

  useEffect(() => {
    let timeout;
    if (notificationMessage) {
      window.scroll(0, 0);
      timeout = window.setTimeout(() => {
        removeNotification();
      }, 10000);
    }
    return () => window.clearTimeout(timeout);
  }, [notificationMessage]);

  useEffect(() => {
    trackViewManageConnections();
  }, []);

  const unfilterConnections = () => {
    setFilteredArray(initialConnections);
    setFilterValue('');
  };

  const updateFilter = e => {
    const { value } = e.target;
    setFilterValue(value);
    if (value) {
      const connectionsNamesMatch = filterItems(value, 'name', initialConnections);

      const teamsNamesMatch = initialConnections.reduce((cMatch, c) => {
        const matches = filterItems(value, 'name', c.teams);
        if (matches.length > 0) cMatch.push(c);
        return cMatch;
      }, []);

      const filtered = [...new Set([...connectionsNamesMatch, ...teamsNamesMatch])];

      return setFilteredArray(filtered);
    }
    return setFilteredArray(initialConnections);
  };

  const connectionsActionButtons = () => {
    let buttons = [];
    buttons = [...[
      {
        actionClass: 'compare',
        iconClass: 'compare-profile',
        to: '/profile/comparison/new',
        label: 'Begin New Comparison',
      },
      {
        actionClass: 'invites',
        iconClass: 'invites-action',
        to: '/invitations/new',
        label: 'Send Invitation',
      },
      {
        actionClass: 'create-team',
        iconClass: 'create-team-action',
        to: '/teams/new',
        label: 'Create Team',
      },
    ], ...buttons];
    return buttons;
  };

  const onSubmit = e => e.preventDefault();

  const onRemoveConnection = async ({
    connectionId,
    connectionName,
    email,
    teams,
  }) => {
    try {
      await removeConnectionAction(apiService, connectionId);
      await trackRemoveConnection({ email, teammate: !!teams });
      if (teams) {
        addNotification({
          type: 'success',
          message: <ConnectionWithTeamsMessage connectionName={connectionName} teams={teams} />,
        });
      } else {
        addNotification({
          type: 'success',
          message: <ConnectionRemovalSuccess connectionName={connectionName} />,
        });
      }
      setFilteredArray(filteredArray.filter(connection => connection.id !== connectionId));
      setInitialConnections(initialConnections
        .filter(connection => connection.id !== connectionId));
    } catch (err) {
      addNotification({
        type: 'warning',
        message: <ConnectionRemovalError err={err} />,
      });
    }
  };

  const renderButtons = (connectionsLength, filteredArrLength) => {
    if (!connectionsLength) {
      return (
        <div className="px-4 py-8 text-center md:px-8 md:py-16">
          <div className="mb-8 text-center">
            <p>You don&apos;t have any GlobeSmart Profile Connections yet.</p>
            <p className="mb-0">Invite a colleague or create a Team to get started.</p>
          </div>
          <div className="flex flex-col items-center gap-4 md:gap-8 md:justify-center md:flex-row">
            <Button
              isFullWidth
              variant="primary"
              to="/invitations/new"
              className="m-0.5"
            >
              Invite Colleagues
            </Button>
            <Button
              isFullWidth
              variant="primary"
              to="/profile/teams/new"
              className="m-0.5"
            >
              Create a Team
            </Button>
          </div>
        </div>
      );
    }
    if (!filteredArrLength) {
      return (
        <div className="text-center">
          <Button
            onClick={unfilterConnections}
          >
            No Results Found <br />Return to all
          </Button>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <PageHeader
        pageTitle="Manage Connections"
        icon="profile"
        skipTarget="#manage-connections"
      />
      <div className="breakout">
        <ActionNavigation items={connectionsActionButtons()} />
        <div className="main-layout">
          <ElementQuery queries={queries}>
            <div id="manage-connections" className="mb-2">
              {notificationMessage && <Notification {...notificationMessage} />}
              <form onSubmit={onSubmit}>
                <div className="my-2">
                  <h3 className="inline">
                    GlobeSmart Profile Connections
                  </h3>
                  <h4 className="inline ml-2">(A-Z)</h4>
                </div>

                <LabelInput
                  id="connections"
                  name="connections"
                  labelText="Search Term"
                  labelType="text"
                  value={filterValue}
                  onChangeValue={updateFilter}
                  helperText="Search names or teams to limit results."
                />
              </form>
              <ActiveConnectionCards
                connections={filteredArray}
                onRemoveConnection={onRemoveConnection}
              />
              {renderButtons(initialConnections.length, filteredArray.length)}
            </div>
          </ElementQuery>
        </div>
      </div>
    </>
  );
};

ManageConnections.needsCompletedSurvey = (router, currentUser) =>
  NeedsCompletedSurvey.apply(router, currentUser);

ManageConnections.getAPIDataKey = () => 'connectionsData';
ManageConnections.getData = apiService => apiService.get('connections')
  .then(connectionsData => ({ connectionsData }));

ManageConnections.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  initialData: PropTypes.shape({
    connections: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        teams: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            id: PropTypes.number,
          }),
        ),
        name: PropTypes.string,
        email: PropTypes.string,
        connectionDate: PropTypes.string,
        avatarURL: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default withServerSideData(ManageConnections);
