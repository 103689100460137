import tracker from '../index';

const { eventNames, resolveAfterTimeout } = tracker;

const trackViewCopilot = () => {
  tracker.track(eventNames.VIEW_GUIDES_COPILOT);
  return resolveAfterTimeout;
};

export { trackViewCopilot };
