import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Slider from 'react-slick';
import { NotificationContext } from '../../../../context/Notification';

import AutoSuggest from '../../../../components/AutoSuggest';
import Button from '../../../../components/common/Button';
import DropdownSelect from '../../../../components/common/Dropdowns/DropdownSelect';
import LabelInput from '../../../../components/common/LabelInput';
import LabelTextArea from '../../../../components/common/LabelTextarea';
import Notification from '../../../../components/Notifications/Notification';
import Block from '../../../../components/Block';
import PortraitBlock from '../../../../components/Block/PortraitBlock';
import AssessmentForm from './AssessmentForm';
import ExternalForm from './ExternalForm';
import ModuleForm from './ModuleForm';
import PromoForm from './PromoForm';

import { filterItems } from '../../../../lib/filter-items';

import { Form } from '../../../../components/common/Form';
import { translations } from '../../../../lib/constants';

import { prefixHostname } from '../../../../lib/cdn';

const SlickNextArrow = props => {
  const { className, style, onClick } = props;
  return (
    <button
      aria-label="next"
      className={`${className} block bg-stone-300 hover:!bg-stone-500 opacity-75 h-full before:content-[]`}
      style={{ ...style }}
      onClick={onClick}
    >
      <img
        src={prefixHostname('/images/right-chevron.svg')}
        alt="Next"
      />
    </button>
  );
};

const SlickPrevArrow = props => {
  const { className, style, onClick } = props;
  return (
    <button
      aria-label="previous"
      className={`${className} block bg-stone-300 hover:!bg-stone-500 opacity-75 h-full before:content-[]`}
      style={{ ...style }}
      onClick={onClick}
    >
      <img
        src={prefixHostname('/images/left-chevron.svg')}
        alt="Previous"
      />
    </button>
  );
};

const BlockForm = ({ formData, handleFormSubmit, buttonText, subHeader }) => {
  const {
    notificationMessage,
    removeNotification,
  } = useContext(NotificationContext);

  const { blockDetails, plans, types, tagOptions, images, assessments } = formData;

  const [blockData, setBlockData] = useReducer((data, newData) =>
    ({ ...data, ...newData }), {
    type: types && types[0],
    assessmentType: blockDetails
      ? assessments.find(asmt => asmt.value === blockDetails.assessment)
      : (assessments && assessments[0]),
    selectedCarouselImage: blockDetails
      ? images.findIndex(({ link }) => link === blockDetails.blockImage)
      : 0,
    ...blockDetails,
    languages: blockDetails?.languages.map(l => translations.find(t => t.value === l)) || [],
    tags: blockDetails?.tags || [],
    stripePlans: blockDetails?.stripePlans || [],
  });

  const [filteredImages, setFilteredImages] = useState(images);
  const [filterValue, setFilterValue] = useState('');
  const [error, setError] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);

  const autoSuggestRef = useRef(null);
  const notificationRef = useRef(null);

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);

  useEffect(() => {
    if (notificationMessage) {
      notificationRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [notificationMessage]);

  useEffect(() => {
    let timeout;
    if (notificationMessage) {
      timeout = window.setTimeout(() => {
        removeNotification();
      }, 5000);
    }
    return () => {
      window.clearTimeout(timeout);
      if (notificationMessage) removeNotification();
    };
  }, [notificationMessage]);

  useEffect(() => {
    if (error) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [error]);

  const selectCurrentImage = index => {
    setBlockData({
      selectedCarouselImage: index,
      blockImage: filteredImages[index].link.replace('.$https=2//', 'https://'),
      blockLandscapeImage: filteredImages[index].landscapeLink.replace('.$https=2//', 'https://'),
    });
  };

  const renderSlides = () => filteredImages.map((image, index) => (
    <div className="items-center w-full" key={image.link} id={image.name} data-landscape-link={image.landscapeLink}>
      <button
        className="object-contain aspect-video"
        onClick={e => {
          e.preventDefault();
          selectCurrentImage(index);
        }}
      >
        <img src={image.link} alt={image.name} className="lg:h-96 md:h-96 sm:h-auto" />
      </button>
      <p className="text-sm text-charcoal-900">{image.name}</p>
    </div>
  ));

  const renderPreviewSlides = () => filteredImages.map(image => (
    <div className="w-12 h-12 p-2 m-auto hover:border hover:border-solid hover:border-gray-800 active:border focus:border" key={image.link} id={image.name} data-landscape-link={image.landscapeLink}>
      <img src={image.link} alt={image.name} className="object-contain aspect-video" />
    </div>
  ));

  const clearForm = () => {
    const emptyBlock = Object.keys(blockData)
      .reduce((formObject, key) => {
        const form = formObject;
        form[key] = undefined;
        return form;
      }, {});

    // resets the options state for AutoSuggest
    autoSuggestRef?.current?.resetOptions();

    setBlockData({
      ...emptyBlock,
      completionRangeEnd: '',
      completionRangeStart: '',
      type: types && types[0],
      assessmentType: assessments && assessments[0],
      selectedCarouselImage: 0,
    });

    setFilterValue('');
    setFilteredImages(images);
  };

  const handleFilterImages = e => {
    const { value } = e.target;

    setFilterValue(value);

    if (value) {
      const imagesNamesMatch = filterItems(value, 'name', images);
      const filtered = [...new Set([...imagesNamesMatch])];

      return setFilteredImages(filtered);
    }
    return setFilteredImages(images);
  };

  const handleRemovePlan = value => {
    setBlockData({
      stripePlans: blockData.stripePlans
        .filter(plan => plan.value !== value),
    });
  };

  const handleRemoveTag = value => {
    setBlockData({
      tags: blockData.tags
        .filter(tag => tag.value !== value),
    });
  };

  const handleRemoveLanguage = value => {
    setBlockData({
      languages: blockData.languages
        .filter(language => language.value !== value),
    });
  };

  const handleChange = ({ target: { name, value } }) => {
    setBlockData({ [name]: value });

    if (name === 'completionRangeEnd') {
      if (value && (+blockData.completionRangeStart > +value)) {
        setError('The end of the completion range needs to be longer than the selected start.');
        setBlockData({ completionRangeEnd: '' });
      } else {
        setError(null);
      }
    }
  };

  const handleHTMLChange = newHTML => {
    setBlockData({ html: newHTML });
  };

  const handleSelectAssessmentChange = ({ target: { value } }) => {
    const assessmentType = assessments.find(option => option.value.toString() === value);
    setBlockData({ assessmentType });
  };

  const handleSelectTypeChange = ({ target: { value } }) => {
    const type = types.find(option => option.value.toString() === value);
    setBlockData({ typeName: type.name });
    setBlockData({ type });
  };

  const getTypeFormFields = () => {
    switch (blockData.type.value) {
      case 1:
        return (
          <ModuleForm
            moduleId={blockData.moduleId}
            completionRangeStart={blockData.completionRangeStart}
            completionRangeEnd={blockData.completionRangeEnd}
            handleChange={handleChange}
            handleRemoveLanguage={handleRemoveLanguage}
            languageRef={autoSuggestRef}
            languages={blockData.languages}
            errorMessage={error}
          />
        );
      case 2:
        return (
          <AssessmentForm
            assessments={assessments}
            assessmentType={blockData.assessmentType}
            handleSelectAssessmentChange={handleSelectAssessmentChange}
          />
        );
      case 3:
        return (
          <PromoForm
            buttonText={blockData.buttonText}
            directUrl={blockData.directUrl}
            html={blockData.html}
            handleHTMLChange={handleHTMLChange}
            handleChange={handleChange}
          />
        );
      case 5:
        return (
          <ExternalForm
            completionRangeStart={blockData.completionRangeStart}
            completionRangeEnd={blockData.completionRangeEnd}
            directUrl={blockData.directUrl}
            handleChange={handleChange}
            errorMessage={error}
          />
        );
      default:
        return null;
    }
  };

  const onSubmit = e => {
    e.preventDefault();
    handleFormSubmit(blockData, clearForm);
  };

  return (
    <Form bgColor="white" handleFormSubmit={onSubmit}>
      <Notification ref={notificationRef} {...notificationMessage} />
      <p className="max-w-3xl m-0 text-center">{subHeader}</p>
      <DropdownSelect
        id="type"
        name="type"
        labelType="text"
        labelText="Block Type"
        options={types}
        isDisabled={!!blockDetails}
        isRequired
        value={blockData.type?.value}
        onChangeValue={handleSelectTypeChange}
        helperText="Select the type of block you are building."
      />
      <LabelInput
        id="name"
        name="name"
        labelType="text"
        isRequired
        labelText="Block Name"
        value={blockData.name}
        onChangeValue={handleChange}
        helperText="Internal. Used in back-end search and reference, not visible to end users."
      />
      <LabelInput
        id="title"
        name="title"
        labelType="text"
        isRequired
        labelText="Title"
        value={blockData.title}
        onChangeValue={handleChange}
        helperText="Add the block title. Visible to end users."
      />
      {blockData.type.value !== 3
        ? (
          <LabelTextArea
            customClass="mt-2"
            id="description"
            name="description"
            labelText="Description"
            value={blockData.description}
            onChangeValue={handleChange}
            helperText="Add a brief description of the block or product. Visible to end users, should help them understand the product. Three lines of text or less, please."
          />
        )
        : null}
      {getTypeFormFields()}
      <AutoSuggest
        name="stripePlans"
        labelText="Select Plan"
        placeholder="Select Plan"
        options={plans}
        selectedOptions={blockData?.stripePlans}
        onSelectOption={handleChange}
        onRemoveSelectedOption={handleRemovePlan}
        isMultiSelect
        helperText="Search/select the plans associated with this block. You can select multiple plans for the same product to account for different access durations."
      />
      {blockData.type.value !== 3 ? (
        <>
          <AutoSuggest
            name="tags"
            labelText="Select Tag"
            placeholder="Tags"
            options={tagOptions}
            selectedOptions={blockData?.tags}
            onSelectOption={handleChange}
            onRemoveSelectedOption={handleRemoveTag}
            isMultiSelect
            ref={autoSuggestRef}
            helperText="Select the topics or audience associated with this product."
          />
          <div>
            <div className="mb-8">
              <LabelInput
                id="searchImage"
                name="searchImage"
                labelText="Search Images"
                labelType="text"
                value={filterValue}
                onChangeValue={handleFilterImages}
              />
            </div>
          </div>
          <div className="slider-container h-96">
            <Slider
              asNavFor={nav2}
              ref={slider => { sliderRef1 = slider; }}
              dots
              initialSlide={blockData.selectedCarouselImage}
              nextArrow={<SlickNextArrow />}
              prevArrow={<SlickPrevArrow />}
              afterChange={current => selectCurrentImage(current)}
              className="text-center h-96"
              slidesToShow={1}
              adaptiveHeight
              // eslint-disable-next-line react/no-unstable-nested-components
              customPaging={i => (
                <button className="before:!text-sm before:!text-gray-500">
                  {i}
                </button>
              )}
            >
              {filteredImages.length > 0 && renderSlides()}
            </Slider>
          </div>
          <div className="mt-3 slider-container">
            <Slider
              asNavFor={nav1}
              ref={slider => { sliderRef2 = slider; }}
              initialSlide={blockData.selectedCarouselImage}
              afterChange={current => selectCurrentImage(current)}
              slidesToShow={10}
              swipeToSlide
              focusOnSelect
              adaptiveHeight
            >
              {filteredImages.length > 0 && renderPreviewSlides()}
            </Slider>
          </div>
        </>
      ) : null}
      <div className="p-3 mt-4 rounded-lg bg-ivory-100">
        <h5 className="mb-8 text-lg text-left font-heading-5">Preview</h5>
        <Block
          {...blockData}
          blockImage={
            blockData?.blockImage
              ? blockData.blockImage
              : filteredImages
                .at(blockData.selectedCarouselImage).link
          }
          moduleId={+blockData.moduleId}
          blockType={blockData.type?.value}
          languages={blockData.languages?.map(({ value }) => value)}
          tags={blockData.tags?.map(({ name }) => name)}
          defaultImageLink={images.at(0).link}
          defaultImageName={images.at(0).name}
        />
        {(blockData.type.value === 1 || blockData.type.value === 5) ? (
          <div className="mt-4">
            <h5 className="pt-2 text-lg text-left font-heading-5">Portrait Preview</h5>
            <div className="flex items-center justify-center p-4">
              <PortraitBlock
                {...blockData}
                moduleId={+blockData.moduleId}
                blockType={blockData.type?.value}
                languages={blockData.languages?.map(({ value }) => value)}
                tags={blockData.tags?.map(({ name }) => name)}
                blockLandscapeImage={
                  blockData?.blockLandscapeImage
                    ? blockData.blockLandscapeImage
                    : filteredImages
                      .at(blockData.selectedCarouselImage).landscapeLink
                }
                defaultImageLink={images.at(0).landscapeLink}
                defaultImageName={images.at(0).name}
                className="md:w-80"
              />
            </div>
          </div>
        ) : null}
        <div className="mt-8 mb-1 text-right">
          <Button
            filledColor="secondary"
            type="submit"
            isDisabled={isDisabled}
            className="ml-auto"
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </Form>
  );
};

BlockForm.propTypes = {
  handleFormSubmit: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired,
  formData: PropTypes.shape({
    blockDetails: PropTypes.shape({
      name: PropTypes.string,
      title: PropTypes.string,
      assessment: PropTypes.number,
      languages: PropTypes.arrayOf(PropTypes.string),
      type: PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.number,
      }),
      description: PropTypes.string,
      blockImage: PropTypes.string,
      tags: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          value: PropTypes.number,
        }),
      ),
      stripePlans: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          value: PropTypes.string,
        }),
      ),
    }),
    plans: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
      })),
    tagOptions: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.number,
      })),
    types: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.number,
      })),
    images: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        link: PropTypes.string,
      }),
    ),
    assessments: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.number,
      })),
  }).isRequired,
};

SlickNextArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
  onClick: PropTypes.func,
};

SlickNextArrow.defaultProps = {
  className: '',
  style: {},
  onClick: () => { },
};

SlickPrevArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
  onClick: PropTypes.func,
};

SlickPrevArrow.defaultProps = {
  className: '',
  style: {},
  onClick: () => { },
};

export default BlockForm;
