import React from 'react';
import PropTypes from 'prop-types';

import AccessCodeCard from '../../../AccessCodeCard';

const ActiveAccessCodeCard = props => {
  const buttonOptions = {
    isInvitation: true,
    confirmText: 'Deactivate',
    confirmButton: {
      variant: 'primary',
      isNormalCase: true,
      onClick: props.confirmDeactivate,
    },
    cancelText: 'Cancel',
    cancelButton: {
      isNormalCase: true,
      isCancel: true,
      onClick: props.noDeactivate,
    },
  };

  return <AccessCodeCard {...props} {...buttonOptions} />;
};

ActiveAccessCodeCard.propTypes = {
  confirmDeactivate: PropTypes.func.isRequired,
  noDeactivate: PropTypes.func.isRequired,
};

export default ActiveAccessCodeCard;
