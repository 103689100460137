import pruneQueryParams from '../../urls/pruneQueryParams';

const isAuth0ClientIdForAssessment = state => state.startsWith('ASMT-');

const isGlobeSmartUrl = url => url.startsWith('http') && url.includes(process.env.SITE_URL);

const stateIsDeepLink = state => state && (state.startsWith('http') || isAuth0ClientIdForAssessment(state));

const getRedirectFromCookieOrDefault = (cookiesProvider, nameOfRedirectCookie) => {
  const redirectFromCookie = cookiesProvider.get(nameOfRedirectCookie);
  if (redirectFromCookie) {
    cookiesProvider.remove(nameOfRedirectCookie);
    const redirectUrl = pruneQueryParams(redirectFromCookie, ['sp']);
    return redirectUrl;
  }
  return '/dashboard';
};

const getUrlSuffix = state => {
  if (isAuth0ClientIdForAssessment(state)) {
    return state.replace(/^ASMT-/, '');
  }
  let auth0ClientID = process.env.AUTH0_CLIENT_ID;
  if (state.includes('gslearning')) {
    auth0ClientID = process.env.AUTH0_CLIENT_ID_GSLEARNING;
  }
  return `${auth0ClientID}?RelayState=${encodeURIComponent(state)}`;
};

const getSamlRedirect = (state, samlConnection) => {
  const urlSuffix = getUrlSuffix(state);
  const url = `https://${process.env.AUTH0_DOMAIN}/samlp/${urlSuffix}`;
  const delimiter = urlSuffix.includes('?') ? '&' : '?';
  const params = (samlConnection && samlConnection !== 'auth0') ? `${delimiter}connection=${samlConnection}` : '';
  return url + params;
};

export const getRedirectUrl = (state, cookiesProvider, nameOfRedirectCookie, auth0ConnectionId) => {
  if (!stateIsDeepLink(state)) {
    return getRedirectFromCookieOrDefault(cookiesProvider, nameOfRedirectCookie);
  }

  if (isGlobeSmartUrl(state)) {
    return state;
  }

  return getSamlRedirect(state, auth0ConnectionId);
};
