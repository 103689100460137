import React, { useContext, useEffect } from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

import { CurrentUserContext } from '../../../context/CurrentUser';
import { RouterContext } from '../../../context/Router';

import withServerSideData from '../../../HOC/withServerSideData';

import Notification from '../../Notifications/Notification';

import SiteHeader from '../../SiteHeader';
import Footer from '../../common/Footer';

const AssessmentRaterSurvey = ({ location, initialData }) => {
  const { currentUser } = useContext(CurrentUserContext);
  const { router } = useContext(RouterContext);
  const {
    displayName,
    surveyProviderUrl: {
      raterSurveyUrl,
      groupMemberName,
      surveyComplete,
    },
  } = initialData;
  const pageTitle = displayName;

  useEffect(() => {
    if (surveyComplete) {
      const timeout = window.setTimeout(() => {
        router.push('/dashboard');
      }, 5000);

      return () => clearTimeout(timeout);
    }
    const intervalId = window.setInterval(() => {
      currentUser.refreshAuthToken();
    }, 900000);

    return () => clearInterval(intervalId);
  }, []);

  const completedSurveyMessage = `You've already completed the IBI 360 survey for ${groupMemberName}. Please click <a href="/dashboard">here</a> to go to the dashboard`;

  return (
    <div className="flex flex-col min-h-screen">
      <section className="flex flex-col flex-grow w-full h-full overflow-hidden bg-ivory-400">
        <Helmet>
          <title>{pageTitle} | Aperian</title>
        </Helmet>
        <SiteHeader pageTitle={pageTitle} location={location} />
        {surveyComplete ? (
          <div className="layout-max-width">
            <Notification type="success" message={completedSurveyMessage} />
          </div>
        ) : (
          <>
            {/* This embed code may be restricted to use on a single domain */}
            {/* see documentation at: https://help.alchemer.com/help/iframe-embed#limit-permitted-domains-for-embeds */}
            <div className="my-8 bg-ivory-400">
              <h2 className="mb-0 leading-none text-center">You are rating {groupMemberName}</h2>
            </div>
            <iframe
              title="alchemer-survey"
              src={raterSurveyUrl}
              className="assessment__survey"
              frameBorder="0"
              sandbox="allow-top-navigation allow-scripts allow-forms"
            />
          </>
        )}

      </section>
      <Footer />
    </div>
  );
};

AssessmentRaterSurvey.getAPIDataKey = () => 'asmtSurveyData';
AssessmentRaterSurvey.getData = (apiService, { raterId }) =>
  apiService.get(`assessments/ibi/survey-url?isRater=true&raterId=${raterId}`)
    .then(data => ({ asmtSurveyData: data }));

AssessmentRaterSurvey.propTypes = {
  params: PropTypes.shape({
    raterId: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  initialData: PropTypes.shape({
    displayName: PropTypes.string,
    surveyProviderUrl: PropTypes.shape({
      raterSurveyUrl: PropTypes.string,
      groupMemberName: PropTypes.string,
      surveyComplete: PropTypes.bool,
    }).isRequired,
  }).isRequired,
};

export default withServerSideData(AssessmentRaterSurvey);
