import PropTypes from 'prop-types';

const propTypes = {
  heading: PropTypes.string.isRequired,
  sent: PropTypes.array.isRequired,
  onResend: PropTypes.func.isRequired,
  isInviter: PropTypes.bool,
};

const defaultProps = {
  isInviter: true,
};

export {
  propTypes,
  defaultProps,
};
