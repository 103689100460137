import React, { useContext } from 'react';
import { find } from 'lodash';
import classNames from 'classnames';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import parser from 'html-react-parser';
import DOMPurify from 'isomorphic-dompurify';

import { Context } from '../../../context/ContextProvider';

import Avatar from '../../common/Avatars/Avatar';
import Button from '../../common/Button';
import Payment from '../../Payment';

import { GS_PREMIUM_STRIPE_PLAN } from '../../../lib/constants';

import {
  propTypes as ProfileCardPropTypes,
  defaultProps as ProfileCardDefaultProps,
} from '../ProfileCard/types';

import { prefixHostname } from '../../../lib/cdn';

const SlickNextArrow = props => {
  const { style, onClick } = props;
  return (
    <button
      aria-label="next"
      className="block cursor font-0 leading-none p-0 -right-6 absolute top-1/2 w-5 -translate-y-1/2 bg-stone-300 hover:!bg-stone-500 opacity-75 h-full disabled:opacity-25"
      style={{ ...style }}
      onClick={onClick}
    >
      <img
        src={prefixHostname('/images/right-chevron.svg')}
        alt="Next"
      />
    </button>
  );
};

const SlickPrevArrow = props => {
  const { style, onClick } = props;
  return (
    <button
      aria-label="previous"
      className="block cursor font-0 leading-none p-0 -left-6 absolute top-1/2 w-5 -translate-y-1/2 bg-stone-300 hover:!bg-stone-500 opacity-75 h-full disabled:opacity-25"
      style={{ ...style }}
      onClick={onClick}
    >
      <img
        src={prefixHostname('/images/left-chevron.svg')}
        alt="Previous"
      />
    </button>
  );
};

const ProfileGroup = props => {
  const { router, handleOpenModal, handleCloseModal } = useContext(Context);
  const {
    groupName,
    sendInvitationsLink,
    hasPremium,
    createTeamLink,
  } = props;

  const renderGroupItems = ({ cards, onChange, selectedComparables }) => {
    if (!cards) return null;

    let sortedCards = cards;

    if (groupName === 'Cultures (A-Z)') {
      sortedCards = cards.sort((a, b) => a.displayName.localeCompare(b.displayName, undefined, { sensitivity: 'base' }));
    }

    return sortedCards.map(card => {
      let checked;
      if (selectedComparables) {
        checked = !!find(selectedComparables, c => c.id === card.id);
      } else {
        checked = card.isVisible;
      }

      const listClasses = classNames({
        checked,
        peer: true,
        'h-44 min-w-44 max-w-44': !props.isGrid,
        'h-28 min-w-28 max-w-28': props.isGrid,
        'p-1 m-1 md:h-32 md:min-w-32 md:max-w-32 lg:min-w-32 lg:max-w-32 lg:h-32 xl:min-w-36 xl:max-w-36 xl:h-36 xxl:min-w-38 xxl:max-w-38 xxl:h-38': true,
      });

      const comparable = {
        id: card.id,
        type: card.type,
        htmlElementId: () => `${card.type}-${card.id}`,
      };
      const cardDisplayName = parser(DOMPurify.sanitize(card.displayName, { ADD_ATTR: ['target'] }));
      const cardShortDisplayName = parser(DOMPurify.sanitize(card.shortDisplayName, { ADD_ATTR: ['target'] }));

      return (
        <div
          key={(cardDisplayName || cardShortDisplayName) + card.id.toString()}
          className={listClasses}
        >
          <input
            id={card.id}
            type="checkbox"
            checked={checked}
            className="hidden peer"
            onChange={e => { onChange(comparable, e.target.checked); }}
          />
          <label
            htmlFor={card.id}
            className="flex flex-col items-center justify-center w-full h-full transition-shadow duration-300 ease-in-out bg-white rounded-lg shadow-md cursor-pointer hover:shadow-xl peer-checked:ring-1 peer-checked:ring-inset peer-checked:shadow-input peer-checked:ring-rust-500"
          >
            <Avatar avatarUrl={card.avatarUrl} isCulture={card.type === 'culture'} />
            <span className="mt-2 font-sans text-sm font-medium text-center line-clamp-2 text-charcoal-900 text-balance">{(card.displayName ? cardDisplayName : cardShortDisplayName)}</span>
          </label>
        </div>
      );
    });
  };

  const handleEmptyGroup = () => {
    switch (groupName) {
      case 'Individuals (A-Z)': return (
        <div className="text-center">
          <Button
            variant="primary"
            className="m-auto"
            onClick={() => router.replace(sendInvitationsLink())}
            leadingButtonIcon={hasPremium() ? null : 'lock'}
          >
            Invite Colleagues
          </Button>
        </div>
      );
      case 'Teams and Organizations (A-Z)': return (
        <div className="text-center">
          <Button
            variant="primary"
            className="m-auto"
            onClick={() => router.replace(createTeamLink())}
            leadingButtonIcon={hasPremium() ? null : 'lock'}
          >
            Create a Team
          </Button>
        </div>
      );
      case 'Cultures (A-Z)': return (
        <div className="text-center">
          {
            hasPremium()
              ? (
                <Button
                  className="m-auto"
                  variant="primary"
                >
                  No results found
                </Button>
              )
              : (
                <Button
                  variant="primary"
                  className="m-auto"
                  onClick={() => handleOpenModal({
                    content: <Payment
                      planId={GS_PREMIUM_STRIPE_PLAN}
                      handleCloseModal={handleCloseModal}
                    />,
                  })}
                  leadingButtonIcon={hasPremium() ? null : 'lock'}
                  iconColor="white"
                >
                  Upgrade Your Account
                </Button>
              )
          }
        </div>
      );
      default: return <div />;
    }
  };

  const settings = {
    variableWidth: true,
    dots: true,
    infinite: false,
    speed: 500,
    swipeToSlide: true,
    slidesToScroll: 5,
    nextArrow: <SlickNextArrow />,
    prevArrow: <SlickPrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          swipeToSlide: true,
          slidesToScroll: 3,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          swipeToSlide: true,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          swipeToSlide: true,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
    // eslint-disable-next-line react/no-unstable-nested-components
    customPaging: i => (
      <button className="before:!text-sm before:!text-gray-500">
        {i}
      </button>
    ),
  };

  return (
    <section>
      <h4 className="mb-2">{props.groupName}</h4>
      {props.isGrid
        ? (
          <div className="flex flex-wrap justify-center h-full gap-1 md:gap-2">
            {renderGroupItems(props)}
          </div>
        )
        : (
          <div className="mx-8">
            {(!props.cards || !props.cards.length)
              ? handleEmptyGroup()
              : (
                <Slider {...settings}>
                  {renderGroupItems(props)}
                </Slider>
              )}
          </div>
        )}
    </section>
  );
};

ProfileGroup.propTypes = {
  ...ProfileCardPropTypes,
  ...{
    groupName: PropTypes.string,
    sendInvitationsLink: PropTypes.func,
    createTeamLink: PropTypes.func,
    hasPremium: PropTypes.func,
    handleOpenModal: PropTypes.func,
    handleCloseModal: PropTypes.func,
  },
};

ProfileGroup.defaultProps = {
  ...ProfileCardDefaultProps,
  ...{
    groupName: '',
    sendInvitationsLink: undefined,
    createTeamLink: undefined,
    hasPremium: undefined,
    handleOpenModal: undefined,
    handleCloseModal: undefined,
  },
};

SlickNextArrow.propTypes = {
  style: PropTypes.objectOf(PropTypes.string),
  onClick: PropTypes.func,
};

SlickNextArrow.defaultProps = {
  style: {},
  onClick: () => { },
};

SlickPrevArrow.propTypes = {
  style: PropTypes.objectOf(PropTypes.string),
  onClick: PropTypes.func,
};

SlickPrevArrow.defaultProps = {
  style: {},
  onClick: () => { },
};

export default ProfileGroup;
