import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from '../../common/Button';

const CouponInput = ({ applyCoupon, coupon }) => {
  useEffect(() => {
    if (coupon.id) applyCoupon(coupon.id);
  }, []);

  const handleCouponForm = e => {
    const data = new FormData(e.target);
    e.preventDefault();
    applyCoupon(data.get('coupon'));
  };

  return (
    <form
      className="flex flex-wrap items-center justify-center w-3/4 gap-2 px-3 pt-3 m-auto rounded-lg md:flex-nowrap"
      onSubmit={handleCouponForm}
    >
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="mt-0.5" htmlFor="payment-coupon">
        Coupon:
      </label>
      <input
        id="payment-coupon"
        name="coupon"
        className="font-sans font-normal box-border block w-full rounded-md border-1 border-[#e6e6e6] py-1.5 ring-1 ring-inset ring-gray-50 placeholder:text-gray-500 focus:ring-1 focus:ring-inset focus:ring-rust-500 focus:shadow-input sm:text-sm sm:leading-6"
        type="text"
        defaultValue={coupon.id}
      />
      <Button variant="primary" isSmall>
        Apply
      </Button>
    </form>
  );
};

CouponInput.propTypes = {
  applyCoupon: PropTypes.func.isRequired,
  coupon: PropTypes.shape({
    id: PropTypes.string,
  }),
};

CouponInput.defaultProps = {
  coupon: { id: null },
};

export default CouponInput;
