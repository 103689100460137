import React from 'react';
import PropTypes from 'prop-types';

import LabelInput from '../../../../components/common/LabelInput';
import CKEditorComponent from '../../../../components/CK5Editor';

const promoTemplate = '<h3>Title</h3><p class="text-base">Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor nesciunt dicta sequi, perspiciatis, pariatur blanditiis alias quidem nihil eum consequatur vero facere maxime.</p>';

const PromoForm = ({ buttonText, directUrl, html, handleChange, handleHTMLChange }) => (
  <>
    <LabelInput
      id="buttonText"
      name="buttonText"
      labelType="text"
      labelText="Button Text"
      value={buttonText}
      onChangeValue={handleChange}
      helperText="Add the text you would like on the block button."
    />
    <LabelInput
      id="directUrl"
      name="directUrl"
      labelType="text"
      labelText="Direct URL"
      value={directUrl}
      onChangeValue={handleChange}
      helperText="Enter the URL where the launch button should direct the user."
    />
    <div>
      <CKEditorComponent
        savedHTML={html || promoTemplate}
        updateHTML={handleHTMLChange}
      />
    </div>
  </>
);

PromoForm.propTypes = {
  buttonText: PropTypes.string,
  directUrl: PropTypes.string,
  html: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleHTMLChange: PropTypes.func.isRequired,
};

PromoForm.defaultProps = {
  buttonText: '',
  directUrl: '',
  html: '',
};

export default PromoForm;
