class CreateAccessCodeAction {
  constructor(apiService) {
    this.apiService = apiService;
  }

  execute(data) {
    return this.apiService.post('access-codes', data);
  }
}

export default CreateAccessCodeAction;
