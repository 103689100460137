import React, { useContext } from 'react';

import { Context } from '../../../context/ContextProvider';

import PageHeader from '../../PageHeader';
import Button from '../../common/Button';

import needsAuthentication from '../../../lib/needsAuthentication';
import NeedsCompletedSurvey from '../../../lib/NeedsCompletedSurvey';

const IndividualInvitationSuccess = () => {
  const { currentUser, router } = useContext(Context);
  needsAuthentication(router, currentUser);
  NeedsCompletedSurvey.apply(router, currentUser);

  return (
    <>
      <PageHeader
        pageTitle="Invitations Successfully Sent"
        icon="profile"
        skipTarget="#invitations-sent-status"
      />
      <div id="invitations-sent-status" className="flex flex-col items-center gap-8 px-1 py-4 md:py-8">
        <p className="mb-0 text-center">
          Your invitations have been sent. Once a new contact accepts your
          invitation, you may compare.
        </p>
        <div className="flex flex-col gap-2 md:gap-4 md:flex-row">
          <Button to="/invitations/new" variant="primary">
            Send Another Invitation
          </Button>
          <Button to="/invitations" variant="primary">
            View Pending Invitations
          </Button>
          <Button to="/profile/connections" variant="primary">
            Manage Connections
          </Button>
        </div>
        <Button
          to="/dashboard"
          trailingButtonIcon="arrow-right"
          iconColor="dark-gray"
        >
          Go to my dashboard
        </Button>
      </div>
    </>
  );
};

export default IndividualInvitationSuccess;
