import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { ModalContext } from '../../../context/Modal';

import Cultures from './Cultures';

import CulturesFeature from '../../../lib/features/Cultures';
import UpgradeMessage from './UpgradeMessage';

const DashboardCultures = ({ cultures, accessLevel, blockedContent, searchTerm, title }) => {
  const { handleOpenModal, handleCloseModal } = useContext(ModalContext);
  const [filteredCultures, setFilteredCultures] = useState(cultures);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!searchTerm || title.toLowerCase().includes(searchTerm)) {
        return setFilteredCultures(cultures);
      }
      const filtered = cultures.filter(culture =>
        culture.shortDisplayName?.toLowerCase().includes(searchTerm));
      return setFilteredCultures(filtered);
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, cultures]);

  if (!cultures) return null;

  return (
    <div className="bg-white rounded-lg">
      {new CulturesFeature({ accessLevel })
        .positive(() => (
          <Cultures
            cultures={filteredCultures.filter(culture =>
              !blockedContent?.cultures?.includes(culture.id))}
          />
        ))
        .negative(() => (
          <UpgradeMessage
            handleOpenModal={handleOpenModal}
            handleCloseModal={handleCloseModal}
          />
        ))
        .execute()}
    </div>
  );
};

DashboardCultures.propTypes = {
  cultures: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    shortDisplayName: PropTypes.string.isRequired,
    cultureGuideUrl: PropTypes.string.isRequired,
  })).isRequired,
  accessLevel: PropTypes.arrayOf(PropTypes.string).isRequired,
  blockedContent: PropTypes.shape({
    cultures: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  searchTerm: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default DashboardCultures;
