import React from 'react';
import PropTypes from 'prop-types';

import SectionContainer from '../../../common/Containers/Section';
import Button from '../../../common/Button';
import TeamOwnerCard from './TeamOwnerCard';

const TeamOwners = (
  {
    owners,
    isOwner,
    onRemove,
    modalOnClick,
  }) => (
  isOwner ? (
    <SectionContainer title="Team Owners">
      <div className="text-right">
        <Button
          className="mb-4 ml-auto"
          isSmall
          isNormalCase
          variant="primary"
          onClick={modalOnClick}
        >
          Add Team Owners
        </Button>
      </div>
      <ul className="profile-group__list">
        {owners.map(({
          id,
          name,
          email,
          userId,
          avatarUrl,
        }) => {
          const displayDescription = (<><b>Email: </b> {email}</>);
          return (
            <li key={id}>
              <TeamOwnerCard
                ownerId={id}
                ownerUserId={userId}
                displayName={`${name}`}
                displayDescription={displayDescription}
                ownerLength={owners.length}
                removeOwner={onRemove}
                avatarUrl={avatarUrl}
              />
            </li>
          );
        })}
      </ul>
    </SectionContainer>
  ) : null);

TeamOwners.propTypes = {
  owners: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      userId: PropTypes.string,
      email: PropTypes.string,
      name: PropTypes.string,
      avatarUrl: PropTypes.string,
    }),
  ).isRequired,
  isOwner: PropTypes.bool.isRequired,
  onRemove: PropTypes.func.isRequired,
  modalOnClick: PropTypes.func.isRequired,
};

export default TeamOwners;
