/* eslint-disable react/prop-types */
import React from 'react';
import { iconPropTypes, defaultIconProps } from './types';

import { strokeColorMap } from '../index.js';

const CheckIcon = ({ className, iconColor }) => (
  <svg className={`${className} ${strokeColorMap[iconColor]}`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 6L9 17L4 12" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

CheckIcon.propTypes = iconPropTypes;
CheckIcon.defaultProps = defaultIconProps;

export default CheckIcon;
