import PropTypes from 'prop-types';

const propTypes = {
  isVisible: PropTypes.bool.isRequired,
  children: PropTypes.node,
};

const defaultProps = {
  isVisible: false,
  children: null,
};

export {
  propTypes,
  defaultProps,
};
