import React from 'react';
import PropTypes from 'prop-types';

const formatPrice = price => price.toFixed(2);

const determineDurationDescription = (info, duration) => (info.includes('course-') ? null : `for ${duration}`);

const Price = ({
  planInfo,
  quantity,
  subtotal,
  total,
  tax,
  currentStep,
}) => {
  const isDiscounted = total !== subtotal;
  const priceStyle = isDiscounted ? { textDecoration: 'line-through' } : {};
  return (
    <>
      <h2 className="mx-0 mt-8 mb-4 text-3xl">
        US <span style={priceStyle}>${formatPrice(subtotal)}</span>
        {isDiscounted ? (<span> ${formatPrice(total)}</span>) : null}
      </h2>
      {isDiscounted && (
      <h5 className="mb-2 font-sans text-sm font-bold tracking-widest uppercase text-slate-800">
        Discount: {formatPrice(subtotal - total)}
      </h5>
      )}
      {currentStep === 3 ? (
        <>
          <h5>Sales Tax: {tax || 0}</h5>
          <h1 className="my-3 text-4xl">Total: ${tax ? formatPrice(total + tax) : formatPrice(total)}</h1>
        </>
      ) : null}
      <p className="mb-2"> {(quantity > 1) ? `${quantity} licenses` : 'Single license'} {determineDurationDescription(planInfo.id, planInfo.duration)}</p>
    </>
  );
};

Price.propTypes = {
  planInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    duration: PropTypes.string.isRequired,
  }).isRequired,
  quantity: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  subtotal: PropTypes.number.isRequired,
  tax: PropTypes.number,
  total: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
};

Price.defaultProps = {
  tax: 0,
};

export default Price;
