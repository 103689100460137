import React, { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';

import Icon from '../../common/Icon';

const NotificationBell = ({ hasPendingInvitations }) => (
  <Popover as="div" className="relative">
    <Popover.Button
      className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500 group"
      data-invitations={hasPendingInvitations}
    >
      <div className="flex flex-col items-center justify-center gap-1">
        <div className="group-data-[invitations='true']:before:content-[''] group-data-[invitations='true']:before:block group-data-[invitations='true']:before:absolute group-data-[invitations='true']:before:top-[1px] group-data-[invitations='true']:before:right-[1.5rem] group-data-[invitations='true']:before:w-2 group-data-[invitations='true']:before:h-2 group-data-[invitations='true']:before:bg-white group-data-[invitations='true']:before:border-[3px] group-data-[invitations='true']:before:border-rust-500 group-data-[invitations='true']:before:rounded-full">
          <Icon
            icon="bell"
            iconColor="gray"
            className="w-6 h-6 "
            aria-hidden="true"
          />
        </div>
        <span className="sr-only">View notifications</span>
        <p className="m-0 text-xs">Notifications</p>
      </div>
    </Popover.Button>
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Popover.Panel className="absolute right-0 z-[400] mt-1 w-80 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
        {({ close }) => (
          <div className="px-6 py-4">
            {hasPendingInvitations
              ? (
                <>
                  <p>You have pending invitations.</p>
                  <div className="flex">
                    <Link
                      to="/invitations"
                      onClick={() => close()}
                      className="ml-auto text-sm font-medium text-right text-gray-700 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-rust-500 focus:ring-offset-2"
                    >
                      View Notifications
                    </Link>
                  </div>
                </>
              )
              : (<p>You have no notifications</p>)}
          </div>
        )}
      </Popover.Panel>
    </Transition>
  </Popover>
);

NotificationBell.propTypes = {
  hasPendingInvitations: PropTypes.bool.isRequired,
};

export default NotificationBell;
