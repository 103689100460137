export async function createBlockAction(apiService, data) {
  return apiService.post('blocks', data);
}

export async function searchBlocksAction(apiService, name) {
  return apiService.get(`blocks/search?term=${name}`);
}

export async function updateProductSortOrderAction(apiService, orgId, data) {
  return apiService.post(`blocks/${orgId}/sort-order`, data);
}
