class InvitationCancelAction {
  constructor(apiService) {
    this.apiService = apiService;
  }

  execute(token) {
    return this.apiService.post(`invitations/${token}/cancel`);
  }
}

export default InvitationCancelAction;
