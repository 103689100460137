import React from 'react';
import PropTypes from 'prop-types';

import withServerSideData from '../../../HOC/withServerSideData';

import PageHeader from '../../../components/PageHeader';
import IbiThankYou from './Ibi';

const ThankYou = (
  { initialData: {
    productCode,
    productDisplayName,
    answerText,
    detailsText,
  } }) => {
  const determineComponent = code => {
    switch (code) {
      case 'IBI':
        return IbiThankYou;
      default:
        return IbiThankYou;
    }
  };

  const ComponentName = determineComponent(productCode);

  return (
    <>
      <PageHeader
        pageTitle="Thank You"
        icon="ibi-symbol"
        skipTarget="#thank-you"
      />
      <div id="thank-you">
        <ComponentName
          answerText={answerText}
          detailsText={detailsText}
          productDisplayName={productDisplayName}
          productCode={productCode}
        />
      </div>
    </>
  );
};

ThankYou.getAPIDataKey = () => 'thankyou';

ThankYou.getData = (apiService, params) =>
  apiService.post('nudges', params)
    .then(data => ({ thankyou: data }));

ThankYou.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  initialData: PropTypes.shape({
    productCode: PropTypes.string,
    productDisplayName: PropTypes.string,
    answerText: PropTypes.string,
    detailsText: PropTypes.string,
  }).isRequired,
};

export default withServerSideData(ThankYou);
