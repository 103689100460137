import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Avatar from '../../Avatars/Avatar';
import Option from './Option';
import Icon from '../../Icon';

const DropdownCustomSelect = ({
  labelText,
  placeHolder,
  options,
  onChangeValue,
  helperText,
  errorMessage,
  withAvatar,
  prevSelected,
  isTransparent,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(prevSelected || {});
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const handleDropdown = () => setIsOpen(!isOpen);

  const handleChangeValue = newValue => {
    if (!newValue.disabled) {
      setSelected(newValue);
      onChangeValue(newValue);
      handleDropdown();
    }
  };

  useEffect(() => {
    function handleClickOutside(e) {
      if ((dropdownRef?.current && !dropdownRef.current.contains(e.target))
        && (buttonRef?.current && !buttonRef.current.contains(e.target))) {
        e.preventDefault();
        buttonRef.current.blur();
        handleDropdown();
      }
    }
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [isOpen]);

  return (
    <div className="relative w-full">
      {(labelText && !isTransparent) && (
        <p className="block mb-2 text-sm font-medium leading-6 text-left text-gray-700">
          {labelText}
        </p>
      )}
      <button
        type="button"
        className={`flex justify-between items-center px-4 py-2 my-1 w-full gap-x-1.5 rounded-md${isTransparent ? '' : ' bg-white  shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-stone-25 focus:ring-inset focus:ring-rust-500 focus:shadow-input'}`}
        id="menu-button"
        onClick={handleDropdown}
        ref={buttonRef}
      >
        {withAvatar
          ? (
            <div className="flex h-6 flex-grow items-center gap-x-2.5">
              <div className="w-6 h-6">
                <Avatar
                  avatarUrl={selected?.avatarUrl}
                  avatarImgAlt={selected?.avatarUrl}
                />
              </div>
              <span className="flex-grow text-base font-medium leading-6 text-left text-gray-700">
                {selected?.name || placeHolder}
              </span>
            </div>
          )
          : (
            <span className="flex-grow text-base font-medium leading-6 text-left text-gray-700">
              {selected?.name || placeHolder}
            </span>
          )}
        <div>
          <Icon
            icon="chevron-down"
            iconColor="gray"
            className={`ml-4 w-3 h-3 ${isOpen ? '-rotate-180 transition-transform duration-500'
              : 'rotate-0 transition-transform duration-500'}`}
          />
        </div>
      </button>
      <ul
        className={`absolute right-0 z-10 w-full py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg border-1 focus:outline-none ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'} transition-opacity duration-300 ease-in-out`}
        aria-labelledby="dropdown-list"
        tabIndex="-1"
        ref={dropdownRef}
      >
        {options.map(item => (
          <Option
            key={item.name}
            item={item}
            onSelect={handleChangeValue}
            selected={item.value === selected?.value}
          >
            <div className="flex items-center justify-between ">
              {withAvatar
                ? (
                  <div className="flex h-6 flex-grow items-center gap-x-2.5 ">
                    <div className="w-6 h-6">
                      <Avatar
                        avatarUrl={item?.avatarUrl}
                        avatarImgAlt={item?.avatarUrl}
                        className="w-6 h-6"
                      />
                    </div>
                    {item?.name || <div className="text-gray-200">{placeHolder}</div>}
                  </div>
                )
                : item.name}
              {item.value === selected?.value
                    && (
                    <Icon
                      icon="check"
                      iconColor="secondary"
                      iconHoverColor="white"
                      className="w-6 h-6 group stroke-rust-500 group-hover:stroke-white"
                    />
                    )}
            </div>
          </Option>
        ))}
      </ul>
      {helperText && !errorMessage ? <p className="text-sm">{helperText}</p> : null}
      {errorMessage && !isOpen ? <p className="text-sm text-rust-500">{errorMessage}</p> : null}
    </div>
  );
};

DropdownCustomSelect.propTypes = {
  labelText: PropTypes.string,
  placeHolder: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
  ).isRequired,
  helperText: PropTypes.string,
  errorMessage: PropTypes.string,
  onChangeValue: PropTypes.func.isRequired,
  withAvatar: PropTypes.bool,
  isTransparent: PropTypes.bool,
  prevSelected: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }),
};

DropdownCustomSelect.defaultProps = {
  labelText: '',
  helperText: null,
  errorMessage: null,
  withAvatar: false,
  isTransparent: false,
  prevSelected: {},
};

export default DropdownCustomSelect;
