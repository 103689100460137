const eventNames = {
  SUBSCRIBE_TO_PRODUCT_UPDATES: 'Subscribe to Product Updates',
  COMPLETE_PROFILE: 'Complete Profile',
  CREATE_GROUP: 'Create Assessment Group',
  CREATE_TEAM: 'Create GSP Team',
  REDEEM_ACCESS_CODE: 'Redeem Access Code',
  SUBMIT_PURCHASE_FORM: 'Submit Purchase Form',
};

const customAttributes = {
  PRODUCT_UPDATES: 'Product Updates',
  GSP_COMPLETED: 'GSP Completed',
  ORGANIZATION_TYPE: 'Organization Type',
};

export { eventNames, customAttributes };
