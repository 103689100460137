import React from 'react';
import { iconPropTypes, defaultIconProps } from './types';

import { fillColorMap } from '../index.js';

const ProfileIcon = ({ className, iconColor }) => (
  <svg width="44" height="44" className={`${className} ${fillColorMap[iconColor]}`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.0011 11.0374C15.049 11.0374 17.5199 8.56662 17.5199 5.51872C17.5199 2.47081 15.049 0 12.0011 0C8.95324 0 6.48242 2.47081 6.48242 5.51872C6.48242 8.56662 8.95324 11.0374 12.0011 11.0374Z" />
    <path d="M11.0371 12.2835V22.8938C11.0371 23.0123 10.9411 23.1064 10.8245 23.1064H0.214203C0.0956621 23.1064 -0.0021801 23.0086 -0.0002985 22.8881C0.112597 16.9649 4.89372 12.1838 10.8188 12.069C10.9393 12.0671 11.0371 12.165 11.0371 12.2835Z" />
    <path d="M12.9629 12.2835V22.8938C12.9629 23.0123 13.0589 23.1064 13.1755 23.1064H23.7858C23.9043 23.1064 24.0022 23.0086 24.0003 22.8881C23.8874 16.9649 19.1063 12.1838 13.1812 12.069C13.0607 12.0671 12.9629 12.165 12.9629 12.2835Z" />
  </svg>
);

ProfileIcon.propTypes = iconPropTypes;
ProfileIcon.defaultProps = defaultIconProps;

export default ProfileIcon;
