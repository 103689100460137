import React, { PureComponent } from 'react';
import { withCookies } from 'react-cookie';

import { Context } from '../../../context/ContextProvider';

import { withErrors } from '../../../components/Errors';

import CohortEnrollmentSuccessMessage from '../../../components/Cohorts/CohortEnrollmentSuccessMessage';

import { cohortEnrollAction } from '../../../actions/cohorts';

import currentUrl from '../../../lib/urls/currentUrl';

const requiresHardReload = targetUrl => {
  const nonReactUrlIdentifiers = [
    'guides',
    'culture-guides',
    'gslearning.aperianglobal.com',
    'resources.aperian.com',
    'resources.aperianglobal.com',
    '/samlp/',
    'https',
  ];
  return nonReactUrlIdentifiers.some(url => targetUrl.includes(url));
};

// This is a non-visual component. It only exists to
// make API requests and redirect people as needed.

// PureComponent to prevent re-render and second API call.
class CohortEnroll extends PureComponent {
  static contextType = Context;

  componentDidMount() {
    const { apiService, router } = this.context;
    const authToken = this.context.currentUser.token;

    if (!authToken) {
      const redirectUrl = currentUrl(location, { removeQueryParams: ['sp'] });
      const connection = location.query.sp || null;
      const getConnectionQuery = () => (connection ? `sp=${connection}&` : '');
      const pushUrl = `/login?${getConnectionQuery()}redirect_to=${encodeURIComponent(redirectUrl)}`;
      this.context.router.push(pushUrl);
      return null;
    }

    // Prevents re-renders when the currentUser changes in context,
    // which is higher up in the component tree by returning `null`
    // if the tokens are the same for each re-render
    if (authToken === this.previousAuthToken) return null;

    this.previousAuthToken = authToken;

    cohortEnrollAction(apiService, router.params.code, { registrationMethod: 'link' })
      .then(({ displayName, redirectUrl, isAlreadyEnrolled }) => {
        const dialogOptions = {
          content: (
            <CohortEnrollmentSuccessMessage
              isAlreadyEnrolled={isAlreadyEnrolled}
              displayName={displayName}
              redirectUrl={redirectUrl}
            />),
        };

        if (redirectUrl && requiresHardReload(redirectUrl)) {
          dialogOptions.afterCloseAction = () => window.location.assign(redirectUrl);
        }

        if (redirectUrl && !requiresHardReload(redirectUrl)) {
          dialogOptions.afterCloseAction = () => router.push(redirectUrl);
        }

        this.context.handleOpenDialog(dialogOptions);
        router.push('/dashboard');
      })
      .catch(error => {
        this.context.addNotification({ type: 'failure', message: error.details.message });
        router.push('/dashboard');
      });

    return null;
  }

  render() {
    return null;
  }
}

export default withCookies(withErrors(CohortEnroll));
export { CohortEnroll as Component };
