import React from 'react';
import { iconPropTypes, defaultIconProps } from './types';

import { strokeColorMap } from '../index.js';

const MinusIcon = ({ className, iconColor }) => (
  <svg className={`${className} ${strokeColorMap[iconColor]}`} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 12H19" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

MinusIcon.propTypes = iconPropTypes;
MinusIcon.defaultProps = defaultIconProps;

export default MinusIcon;
