import React from 'react';

const GreetingIcon = () => (
  <div className="flex flex-col items-center">
    <svg className="mb-0.5" width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.0005 41.8605C32.56 41.8605 41.9308 32.4897 41.9308 20.9302C41.9308 9.37079 32.56 0 21.0005 0C9.4411 0 0.0703125 9.37079 0.0703125 20.9302C0.0703125 32.4897 9.4411 41.8605 21.0005 41.8605Z" fill="#FFA99D" />
    </svg>
    <svg className="mb-0.5" width="66" height="34" viewBox="0 0 66 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M32.9966 33.2793C50.6899 33.2793 65.086 19.1821 65.5582 1.61869C65.5737 1.13124 65.1634 0.721168 64.6758 0.721168L1.32509 0.721163C0.829738 0.721163 0.427272 1.1235 0.442744 1.61868C0.91488 19.1821 15.311 33.2793 32.9966 33.2793Z" fill="#FF8675" />
    </svg>
    <svg width="84" height="42" viewBox="0 0 84 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M41.9978 0.139652C64.7464 0.13965 83.2557 18.2646 83.8627 40.8461C83.8826 41.4729 83.3552 42.0001 82.7283 42.0001L1.27735 42.0001C0.640468 42.0001 0.123005 41.4828 0.142902 40.8462C0.749933 18.2646 19.2592 0.139654 41.9978 0.139652Z" fill="#FF523A" />
    </svg>
  </div>
);

export default GreetingIcon;
