class CreateOrganizationAction {
  constructor(apiService) {
    this.apiService = apiService;
  }

  execute(data) {
    return this.apiService.post('organizations', { organization: data });
  }
}

export default CreateOrganizationAction;
