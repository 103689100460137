import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../common/Button';

import { trackOnboardingStart } from '../../../../lib/tracker/onboarding';

const Landing = ({ formData, nextStep }) => {
  const handleSubmit = async () => {
    await trackOnboardingStart();
    nextStep();
  };

  return (
    <div>
      {formData.invitationToken
        ? (
          <>
            <h1 className="mt-0 mb-2 font-serif text-4xl tracking-wider md:mb-4 text-charcoal-900 md:text-6xl">Welcome,
            </h1>
            <h1 className="mt-0 mb-8 font-serif text-4xl italic tracking-wider md:mb-12 text-rust-500 md:text-6xl">
              {formData.email}
            </h1>
            <div className="flex flex-col items-center gap-2 md:gap-4">
              <Button
                variant="primary"
                trailingButtonIcon="arrow-right"
                onClick={nextStep}
              >Continue
              </Button>
              <a href="https://www.aperian.com" className="flex m-auto font-sans text-base font-semibold cursor-pointer text-rust-700 p-025 hover:underline">Not you?</a>
            </div>
          </>
        )
        : (
          <>
            <div className="w-full max-w-5xl px-6 m-auto md:w-2/3">
              <h1 className="mb-5 font-serif text-4xl tracking-wider text-charcoal-900 md:text-6xl md:mb-6">Let&apos;s set up your <em className="tracking-wider text-rust-500">trial!</em></h1>
              <p className="mb-5 font-sans text-base leading-6 text-gray-800 md:text-xl md:leading-8">
                In the next few steps, you&apos;ll respond to part of the
                <b> GlobeSmart<sup>&reg;</sup> Profile survey</b>&nbsp;for
                insights that will help your organization&apos;s teams work better together.
              </p>
            </div>
            <div className="flex justify-center">
              <Button
                trailingButtonIcon="arrow-right"
                variant="primary"
                onClick={handleSubmit}
              >Continue
              </Button>
            </div>
          </>
        )}
    </div>
  );
};

Landing.propTypes = {
  nextStep: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    invitationToken: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
};

export default Landing;
