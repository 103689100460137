const updateblockAction = (apiService, id, data) =>
  apiService.put(`blocks/${id}`, data);

const updateBlockOrderAction = (apiService, collection, data) =>
  apiService.put(`blocks/default/${collection}`, data);

const getCollectionBlocks = (apiService, collection) => apiService.get(`blocks/default/${collection}`);

export {
  getCollectionBlocks,
  updateblockAction,
  updateBlockOrderAction,
};
