import React from 'react';

import FreeUserFeature from '../../../../lib/features/Free';

const updateToProfessional = user => new FreeUserFeature(user)
  .positive(() => (
    <div className="notification-message notification-message--upgrade">
      <p className="notification-message__text">
        You are using GlobeSmart Basic.{' '}
        <a href="/upgrade-to-professional">
          Upgrade now
        </a>.
      </p>
    </div>
  ))
  .negative(() => null)
  .execute();

export default updateToProfessional;
