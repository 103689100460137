import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { RouterContext } from '../../../context/Router';

import tracker from '../../../lib/tracker';

const AdminCenterTrends = ({ currentOrg: { databoxEmbedLink } }) => {
  const { router } = useContext(RouterContext);

  const { eventNames } = tracker;

  const trackInsightTrendsViewed = () => {
    tracker.track(eventNames.VIEW_INSIGHTS_AND_TRENDS);
  };

  useEffect(() => {
    if (!databoxEmbedLink) {
      router.replace('/dashboard');
    } else {
      trackInsightTrendsViewed();
    }
  }, []);

  return (
    <div className="mx-3 mb-3">
      <h4 className="mb-4">Insights and Trends</h4>
      <p>Your organization&apos;s insights dashboards are below. Click the dots or arrows on the
        bottom of the dashboard to view more. This data comes from our third-party analytics
        service. Contact the Client Success team with any questions, <a href="mailto:clientsuccess@aperian.com">clientsuccess@aperian.com</a>.
      </p>
      <div className="pt-[63%] relative mt-2">
        <iframe
          className="absolute top-0 left-0 w-full h-full"
          src={`${databoxEmbedLink}?i`}
          title="Aperian Reporting"
          frameBorder="0"
          allowFullScreen
        />
      </div>
    </div>
  );
};
AdminCenterTrends.propTypes = {
  currentOrg: PropTypes.shape({
    databoxEmbedLink: PropTypes.string,
  }),
};
AdminCenterTrends.defaultProps = {
  currentOrg: {
    databoxEmbedLink: null,
  },
};
export default AdminCenterTrends;
