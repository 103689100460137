import React from 'react';
import PropTypes from 'prop-types';

import AperianWordmark from '../../../AperianWordmark';
import Button from '../../../common/Button';

const OnboardingHeader = ({ currentStep, handleRedirectLogin, formData }) => {
  const hasLoginButton = !formData.invitationToken && currentStep <= 5;

  const findStepName = () => {
    let stepName;
    switch (currentStep) {
      case 1:
        stepName = 'Landing';
        break;
      case 2:
        stepName = 'Select Use Case';
        break;
      case 3:
        stepName = 'Name Input';
        break;
      case 4:
        stepName = 'Greeting';
        break;
      case 5:
        stepName = 'Email Input';
        break;
      default:
        stepName = '';
    }
    return stepName;
  };

  const handleOnClick = async () => {
    const stepName = await findStepName();
    handleRedirectLogin(stepName);
  };

  return (
    <div className="w-full py-2 bg-white border-b border-gray-100 md:relative md:bg-transparent md:border-b-0">
      <div className="flex justify-between px-4 py-3 md:px-8 md:py-5">
        <AperianWordmark />
        {hasLoginButton ? (
          <Button
            leadingButtonIcon="user-icon"
            variant="secondary"
            iconColor="black"
            isSmall
            onClick={handleOnClick}
          >Login
          </Button>
        ) : null}
      </div>
    </div>
  );
};

OnboardingHeader.propTypes = {
  currentStep: PropTypes.number,
  handleRedirectLogin: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    invitationToken: PropTypes.string,
  }).isRequired,
};

OnboardingHeader.defaultProps = {
  currentStep: null,
};

export default OnboardingHeader;
