import { translations, GS_GLOBAL_TRANSLATIONS } from '../constants';

const validateLanguage = (validLanguages, requestedLanguage, useCase) => {
  const selectedLanguage = validLanguages
    .filter(language => (
      language.value === requestedLanguage && language.enableFor.includes(useCase)
    )).shift();

  const validLanguage = (selectedLanguage ? selectedLanguage.value : 'en');
  return validLanguage;
};

export default validateLanguage;

const isGlobalTranslation = translation =>
  translation.enableFor.some(t => GS_GLOBAL_TRANSLATIONS.includes(t));

export const languageOptions = criteria => translations
  .filter(translation => (criteria
    ? translation.enableFor.includes(criteria)
    : isGlobalTranslation(translation)
  ))
  .sort((a, b) => (
    a.name.localeCompare(
      b.name,
      'en-u-kn-true',
      { numeric: true, sensitivity: 'base' },
    )
  ));
