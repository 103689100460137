import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node,
};

const defaultTypes = {
  children: null,
};

export {
  propTypes,
  defaultTypes,
};
