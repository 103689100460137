import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { RouterContext } from '../../../context/Router';

import DropdownSelect from '../../../components/common/Dropdowns/DropdownSelect';

const AdminCenterList = ({ organizationsOwned }) => {
  const { router } = useContext(RouterContext);

  const handleChange = e => {
    router.push(`/admin-center/${e.target.value}/people`);
  };

  const defaultList = [{
    name: 'Choose an Organization',
    value: '',
  }];

  const formattedOrgs = organizationsOwned.map(org =>
    ({ id: org.id, name: org.name, value: org.id }));

  const orgList = [...defaultList, ...formattedOrgs];
  return (
    <DropdownSelect
      id="orgsOwned"
      name="orgsOwned"
      labelText="Your Organizations"
      options={orgList}
      onChangeValue={handleChange}
    />
  );
};

AdminCenterList.propTypes = {
  organizationsOwned: PropTypes.arrayOf(
    PropTypes.shape({
      orgId: PropTypes.string,
      orgName: PropTypes.string,
    }),
  ).isRequired,
};

export default AdminCenterList;
