import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../Icon';

const Checkbox = ({
  id,
  name,
  labelText,
  isRequired,
  labelClasses,
  isDisabled,
  checked,
  onChange,
  icon,
}) => {
  const checkBoxClasses = classNames({
    'pointer-events-none disabled:bg-gray-25 disabled:border-gray-200': isDisabled,
    'cursor-pointer relative w-4 h-4 border rounded ring-1 ring-inset ring-gray-50 focus:ring-inset focus:ring-rust-600 focus:shadow-input focus:ring-opacity-0 appearance-none peer': true,
    'hover:bg-rust-50 hover:border-rust-600 focus:border-rust-600': !checked,

    'checked:bg-rust-50 checked:border-rust-600 checked:hover:bg-rust-50 checked:focus:bg-rust-50 checked:focus:border-rust-600': checked,
  });

  const labelClassName = classNames({
    'flex items-center gap-2 relative': true,
    [labelClasses]: labelClasses,
  });

  return (
    <div className={labelClassName}>
      <div className="relative flex items-center">
        <input
          checked={checked}
          id={id}
          type="checkbox"
          className={checkBoxClasses}
          value={name}
          onChange={onChange}
          required={isRequired}
          disabled={isDisabled}
        />
        {checked && (
          <Icon
            icon={icon}
            iconColor="secondary"
            className="absolute w-3 h-3 m-0.5 pointer-events-none"
            style={{ left: '0.5rem', top: '0.5rem' }}
          />
        )}
      </div>
      <label htmlFor={id} className="font-sans text-sm">{labelText}</label>
    </div>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  name: PropTypes.string.isRequired,
  labelText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.node,
  ]),
  labelClasses: PropTypes.string,
  icon: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  style: PropTypes.shape({}),
};

Checkbox.defaultProps = {
  checked: false,
  labelText: '',
  style: {},
  labelClasses: '',
  isRequired: false,
  isDisabled: false,
  icon: 'check',
};

export default Checkbox;
