import tracker from '../index';

const {
  eventNames, resolveAfterTimeout, eventProps,
} = tracker;

const trackSkillActivityClick = (apiService, activityName, skillName) => {
  apiService.post('trackers/mixpanel', {
    eventName: eventNames.ACTIVITY_LINK_CLICKED,
    properties: {
      [eventProps.ACTIVITY_NAME]: activityName,
      [eventProps.RELATED_SKILL]: skillName,
    },
  },
  );

  return resolveAfterTimeout;
};

export { trackSkillActivityClick };
