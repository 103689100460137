export default class AssessmentCriteria {
  constructor(user, assessment) {
    this.user = user;
    this.assessment = assessment;
  }

  test() {
    return !!(this.user.accessLevel.some(accessLevel => [`a-${this.assessment}`]
      .some(prefix => accessLevel.startsWith(prefix))));
  }
}
