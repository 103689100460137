import React, { useContext } from 'react';

import { propTypes } from './types';

import { Context } from '../../context/ContextProvider';

import BodyWrapper from '../BodyWrapper';
import AperianWordmark from '../AperianWordmark';
import ResetPasswordForm from './Form';
import ResetPasswordAction from '../../actions/resetPassword';
import RustDividingLine from '../common/RustDividingLine';

const ResetPassword = ({ location: { query: { token } } }) => {
  const { apiService, router } = useContext(Context);
  const handleSubmit = password =>
    new ResetPasswordAction(apiService)
      .execute(password, token)
      .then(response => {
        if (response.error) {
          router.replace('/error');
        } else {
          router.push('/reset-password/success');
        }
      });

  return (
    <BodyWrapper documentTitle="Reset Password | Aperian">
      <div className="flex items-center justify-center mb-6">
        <AperianWordmark />
      </div>
      <div>
        <h1 className="mb-2 text-2xl font-normal font-headline">Reset Password</h1>
        <RustDividingLine />
        <p className="mt-3 mb-0 font-sans text-xs font-normal">Enter your new password.</p>
      </div>
      <ResetPasswordForm
        handleFormSubmit={handleSubmit}
      />
    </BodyWrapper>
  );
};

ResetPassword.propTypes = propTypes;

export default ResetPassword;
