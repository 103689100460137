import React from 'react';

const AfterSurveyMessageIcon = () => (
  <svg width="100" height="100" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M32.7919 0H1.18918C0.53671 0 0 0.53671 0 1.18918V32.7919C0 51.0822 14.3965 66.4994 32.6867 66.9204C51.7873 67.3519 67.3624 51.7873 66.9204 32.6867C66.4784 13.5861 51.0822 0 32.7919 0Z" fill="#FF523A" />
    <path d="M34.1378 66.9292L65.7405 66.9292C66.393 66.9292 66.9297 66.3925 66.9297 65.74L66.9297 34.1373C66.9297 15.847 52.5332 0.42977 34.243 0.00881672C15.1424 -0.422658 -0.432681 15.1419 0.00931072 34.2425C0.451303 53.3431 15.8475 66.9292 34.1378 66.9292Z" fill="#FF523A" />
    <path d="M94.0731 66.9293C105.908 66.9293 115.502 57.3354 115.502 45.5008C115.502 33.6661 105.908 24.0722 94.0731 24.0722C82.2384 24.0722 72.6445 33.6661 72.6445 45.5008C72.6445 57.3354 82.2384 66.9293 94.0731 66.9293Z" fill="#FFA99D" />
    <path d="M96.1962 120L118.557 120C119.019 120 119.398 119.621 119.398 119.159L119.398 96.7983C119.398 83.8568 109.212 72.9482 96.2707 72.6504C82.7559 72.3451 71.7356 83.3579 72.0483 96.8727C72.361 110.387 83.2548 120 96.1962 120Z" fill="#FF8675" />
    <path d="M95.2433 72.6436L72.8824 72.6436C72.4208 72.6436 72.041 73.0233 72.041 73.485L72.041 95.8458C72.041 108.787 82.2274 119.696 95.1688 119.994C108.684 120.299 119.704 109.286 119.391 95.7713C119.078 82.2566 108.185 72.6436 95.2433 72.6436Z" fill="#FF8675" />
    <path d="M66.3262 88.0416L66.3262 73.202C66.3262 72.8956 66.0627 72.6436 65.7424 72.6436L50.2282 72.6436C41.2494 72.6436 33.6809 79.4037 33.4742 87.9922C33.2624 96.9612 40.9032 104.275 50.2799 104.067C59.6566 103.86 66.3262 96.6301 66.3262 88.0416Z" fill="#FF523A" />
  </svg>
);

export default AfterSurveyMessageIcon;
