import React from 'react';
import PropTypes from 'prop-types';

import Button, { propTypes as buttonPropTypes } from '../../../../common/Button';

const SubscriptionCard = ({
  displayName,
  displayDescription,
  buttonOptions,
}) => (
  <div className="card">
    <div className="card__container card__container--confirm">
      <div className="card__content">
        <div className="card__heading">
          {displayName}
        </div>
        {displayDescription && (
          <div className="card__description">{displayDescription}</div>
        )}
      </div>
      <div className="card__actions">
        {Object.keys(buttonOptions).length > 0 && (
          <Button id="subscriptionButton" {...buttonOptions} className="card__button">
            Details
          </Button>
        )}
      </div>
    </div>
  </div>
);

SubscriptionCard.propTypes = {
  displayName: PropTypes.string.isRequired,
  displayDescription: PropTypes.string,
  buttonOptions: PropTypes.shape(buttonPropTypes),
};

SubscriptionCard.defaultProps = {
  displayDescription: '',
  buttonOptions: {},
};

export default SubscriptionCard;
