import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { AchievedSkillItem } from '../../components/SiteHeader/SkillsNotification';
import ToastCompletionContent from '../../components/Toasts/ToastCompletionContent';

const cookieToast = cookie => {
  const cookieToasts = [];
  if (cookie?.skills?.activitiesCompleted) {
    // eslint-disable-next-line array-callback-return
    cookie.skills.activitiesCompleted.map(activity => {
      const { name } = activity;
      cookieToasts.push({
        id: uuidv4(),
        toastPosition: 'top-right',
        isDismissable: true,
        content: <ToastCompletionContent text={name} title="You completed an Activity!" />,
        toastLink: '/skills',
        toastLinkText: 'View My Skills',
        autoDismiss: 8000,
      });
    });
  }

  if (cookie?.skills?.skillsAchieved) {
    cookie.skills.skillsAchieved.forEach(({ id, name, iconImage }) =>
      cookieToasts.push({
        id: uuidv4(),
        toastPosition: 'top-right',
        isDismissable: true,
        content: <AchievedSkillItem id={id} skillName={name} iconImage={iconImage} />,
        toastLink: '/skills',
        toastLinkText: 'View My Skills',
        autoDismiss: 8000,
      }));
  }

  return cookieToasts;
};

export default cookieToast;
