import PropTypes from 'prop-types';

const propTypes = {
  email: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export {
  propTypes,
};
