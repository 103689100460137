import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ActiveAccessCodeCard from './Cards/ActiveAccessCodeCard';
import ConfirmDeactivate from './Cards/ConfirmDeactivate';

const AccessCodeParent = props => {
  const [confirmDeactivate, setConfirmDeactivate] = useState(false);

  useEffect(() => {
    setConfirmDeactivate(false);
  }, [props]);

  const confirmDeactivation = e => {
    e.preventDefault();
    setConfirmDeactivate(true);
  };

  const noDeactivate = e => {
    e.preventDefault();
    setConfirmDeactivate(false);
  };

  const completeDeactivate = e => {
    e.preventDefault();
    props.onDeactivate(props.code);
  };

  return confirmDeactivate
    ? (
      <ConfirmDeactivate
        completeDeactivate={completeDeactivate}
        noDeactivate={noDeactivate}
      />
    )
    : (
      <ActiveAccessCodeCard
        {...props}
        confirmDeactivate={confirmDeactivation}
        noDeactivate={noDeactivate}
      />
    );
};

AccessCodeParent.propTypes = {
  code: PropTypes.string.isRequired,
  onDeactivate: PropTypes.func.isRequired,
};

AccessCodeParent.propTypes = {
  code: PropTypes.string.isRequired,
  description: PropTypes.string,
  internalNotes: PropTypes.string,
  redirectUrl: PropTypes.string,
  quantity: PropTypes.number,
  active: PropTypes.bool,
  seatsRemaining: PropTypes.number,
  stripePlanId: PropTypes.string,
  created: PropTypes.string,
  expirationDate: PropTypes.string,
  planName: PropTypes.string,
  user: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  accessCodeUses: PropTypes.arrayOf(
    PropTypes.shape({
      accessCode: PropTypes.string,
      redeemed: PropTypes.string,
      surveyResponse: PropTypes.shape({
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string,
        id: PropTypes.number,
        state: PropTypes.string,
        user: PropTypes.string,
      }),
      assessmentResponse: PropTypes.shape({
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string,
        id: PropTypes.number,
        userRole: PropTypes.string,
        state: PropTypes.string,
        editSurveyUrl: PropTypes.string,
        version: PropTypes.number,
        completionDate: PropTypes.string,
        languagePreference: PropTypes.string,
        user: PropTypes.string,
        assessment: PropTypes.number,
      }),
      user: PropTypes.shape({
        id: PropTypes.string,
        email: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
      stripePlanId: PropTypes.string,
    }),
  ),
  plans: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  onDeactivate: PropTypes.func.isRequired,
  editAccessCode: PropTypes.bool.isRequired,
};

AccessCodeParent.defaultProps = {
  description: '',
  internalNotes: '',
  redirectUrl: '',
  quantity: 0,
  active: true,
  seatsRemaining: 0,
  stripePlanId: '',
  created: '',
  expirationDate: '',
  planName: '',
  user: { },
  accessCodeUses: [],
  plans: [],
};

export default AccessCodeParent;
