import React from 'react';
import PropTypes from 'prop-types';

import SectionContainer from '../../../common/Containers/Section';
import GroupOwnerCard from './GroupOwnerCard';
import Button from '../../../common/Button';

const GroupOwners = ({
  owners,
  removeOwner,
  modalOnClick,
  groupOrTeam,
}) => (
  <SectionContainer title={`${groupOrTeam} Owners`}>
    <Button
      className="mb-4 ml-auto"
      isSmall
      isNormalCase
      variant="primary"
      onClick={modalOnClick}
    >
      Add {groupOrTeam} Owners
    </Button>
    <ul className="profile-group__list">
      {owners.map(({
        id,
        name,
        email,
        userId,
        avatarUrl,
      }) => {
        const displayDescription = (
          <>
            <b>Email: </b> {email}
          </>
        );
        return (
          <li key={id}>
            <GroupOwnerCard
              id={id}
              ownerUserId={userId}
              email={email}
              displayName={name}
              displayDescription={displayDescription}
              removeOwner={removeOwner}
              ownerLength={owners.length}
              avatarUrl={avatarUrl}
            />
          </li>
        );
      })}
    </ul>
  </SectionContainer>
);

GroupOwners.propTypes = {
  owners: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      email: PropTypes.string,
      userId: PropTypes.string,
      name: PropTypes.string,
      avatarUrl: PropTypes.string,
    }),
  ).isRequired,
  removeOwner: PropTypes.func.isRequired,
  modalOnClick: PropTypes.func.isRequired,
  groupOrTeam: PropTypes.string.isRequired,
};

export default GroupOwners;
