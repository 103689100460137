class OrganizationDeleteDomainAction {
  constructor(apiService) {
    this.apiService = apiService;
  }

  execute(orgId, domainId) {
    return this.apiService.delete(`organizations/${orgId}/domains/${domainId}`);
  }
}

export default OrganizationDeleteDomainAction;
