/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

import cookieToast from './cookieToast';

import { COOKIE_TOAST } from '../../lib/constants';

export const ToastContext = React.createContext({});

export default function ToastProvider({ children }) {
  const [cookies, removeCookie] = useCookies();
  const [toasts, setToasts] = useState([]);

  useEffect(() => {
    const cookieToasts = cookieToast(cookies[COOKIE_TOAST]);
    if (cookies[COOKIE_TOAST]) removeCookie(COOKIE_TOAST);
    setToasts(prev => [...prev, ...cookieToasts]);
  }, [cookies[COOKIE_TOAST], removeCookie]);

  const addToast = (...newToast) => {
    const toastsWithIds = newToast.map(toast => ({ ...toast, id: uuidv4() }));
    setToasts(prev => [...prev, ...toastsWithIds]);
  };

  const removeToast = toastId => {
    const toastToRemove = toasts.find(t => t.id === toastId);
    if (toastToRemove?.onClose) toastToRemove.onClose();
    setToasts(toasts.filter(t => t.id !== toastId));
  };

  const clearToasts = () => setToasts([]);

  return (
    <ToastContext.Provider
      value={{
        toasts,
        addToast,
        removeToast,
        clearToasts,
      }}
    >
      {children}
    </ToastContext.Provider>
  );
}

ToastProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const ToastConsumer = ToastContext.Consumer;
