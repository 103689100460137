import React from 'react';

import { propTypes, defaultProps } from './types';
import Button from '../../common/Button';

function renderButton(text, options, token, id) {
  if (!text) return null;

  return (
    <Button id={id} {...options} dataID={token} className="card__button">
      {text}
    </Button>
  );
}

function renderDescription(description) {
  if (!description) return null;

  return (
    <div className="card__description">
      {description}
    </div>
  );
}

const ProfileCardIconWithButtons = props => {
  const {
    token,
    displayName,
    displayDescription,
    confirmText,
    confirmId,
    confirmButton,
    cancelText,
    cancelId,
    cancelButton,
  } = props;

  return (
    <div className="card has-email">
      <div className="card__container">
        <div className="card__avatar" />
        <div className="card__content">
          <div className="card__heading">
            {displayName}
          </div>
          {displayDescription ? renderDescription(displayDescription) : null}
        </div>
        <div className="card__actions">
          {renderButton(confirmText, confirmButton, token, confirmId)}
          {renderButton(cancelText, cancelButton, token, cancelId)}
        </div>
      </div>
    </div>
  );
};

ProfileCardIconWithButtons.propTypes = propTypes;
ProfileCardIconWithButtons.defaultProps = defaultProps;

export default ProfileCardIconWithButtons;
