import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { APIContext } from '../../../../../../../context/API';

import RaterCard from './RaterCard';
import ConfirmRemove from './ConfirmRemove';

import remindRaterAction from '../../../../../../../actions/assessments/groups/groupRemindRater';

import formatDate from '../../../../../../../lib/dateFormatter';

const JoinedRaterCard = ({
  assessment,
  raterName,
  raterEmail,
  raterId,
  joinedAt,
  raterSurveyStatus,
  initialRemindedAt,
  token,
  raterInvitationId,
  handleSetNotification,
  removeRater,
}) => {
  const { apiService } = useContext(APIContext);
  const [remindedAt, setRemindedAt] = useState(initialRemindedAt || '');
  const [wasReminded, setWasReminded] = useState(false);
  const [confirmRemove, setConfirmRemove] = useState(false);

  useEffect(() => {
    let timeOut;
    if (wasReminded) {
      timeOut = setTimeout(() => {
        setWasReminded(false);
      }, 2000);
    }
    return () => clearTimeout(timeOut);
  }, [wasReminded]);

  /**
     * Call the action to remind the member and then mark that
     * member as reminded in the UI as reminded
     * @param  {String} memberId      We use the user's id
     * @return {undefined}
     */
  async function handleRemindRater() {
    try {
      const response = await remindRaterAction(apiService, raterId);
      if (response.status === 200) {
        const newRemindedAt = formatDate(response.remindedAt);
        setRemindedAt(newRemindedAt);
        setWasReminded(true);
        handleSetNotification({
          type: 'success',
          message: `An ${assessment.toUpperCase()} reminder has been sent to ${raterName}`,
        });
      }
    } catch (err) {
      handleSetNotification({
        type: 'failure',
        message: 'There was an error sending the reminder. Please try again later.',
      });
    }
  }

  const handleConfirmRemove = confirm => setConfirmRemove(confirm);

  return confirmRemove
    ? (
      <ConfirmRemove
        key={token}
        raterId={raterId}
        raterName={raterName}
        raterSurveyStatus={raterSurveyStatus}
        raterInvitationId={raterInvitationId}
        handleConfirmRemove={handleConfirmRemove}
        handleRemoveRater={removeRater}
        token={token}
      />
    )
    : (
      <RaterCard
        key={token}
        raterId={raterId}
        raterName={raterName}
        raterEmail={raterEmail}
        joinedAt={joinedAt}
        raterSurveyStatus={raterSurveyStatus}
        wasReminded={wasReminded}
        remindedAt={remindedAt}
        handleRemindRater={handleRemindRater}
        handleConfirmRemove={handleConfirmRemove}
        token={token}
      />
    );
};

JoinedRaterCard.propTypes = {
  assessment: PropTypes.string.isRequired,
  raterName: PropTypes.string.isRequired,
  raterId: PropTypes.number.isRequired,
  raterInvitationId: PropTypes.number.isRequired,
  removeRater: PropTypes.func.isRequired,
  raterSurveyStatus: PropTypes.string,
  initialRemindedAt: PropTypes.string,
  handleSetNotification: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  raterEmail: PropTypes.string.isRequired,
  joinedAt: PropTypes.string.isRequired,
};

JoinedRaterCard.defaultProps = {
  raterSurveyStatus: null,
  initialRemindedAt: null,
};

export default JoinedRaterCard;
