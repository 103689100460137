import React from 'react';
import PropTypes from 'prop-types';

import PendingInvites from './PendingInvites';

const Header = ({ pendingTeamInvitations }) => (
  <div id="my-teams" className="mx-auto mb-10 md:px-0 xl:mb-12">
    <p className="leading-loose my-[1em] font-sans text-xs md:font-light md:text-base">
      By creating a team, a group of individuals can share
      profiles with each other quickly and view an average
      profile for the team.<br />Each person needs a paid license to
      join the team. <a href="https://aperian.zendesk.com/hc/en-us/articles/360029877693-Creating-GlobeSmart-Profile-Teams" target="_blank" rel="noopener noreferrer">Learn more about creating teams</a>.
    </p>
    <PendingInvites pendingTeamInvitations={pendingTeamInvitations} />
  </div>
);

Header.propTypes = {
  pendingTeamInvitations: PropTypes.arrayOf(
    PropTypes.shape({

    }),
  ).isRequired,
};

export default Header;
