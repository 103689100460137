import React from 'react';
import { Link } from 'react-router';
import BodyWrapper from '../BodyWrapper';
import AperianWordmark from '../AperianWordmark';
import Button from '../common/Button';
import RustDividingLine from '../common/RustDividingLine';

const ComingSoon = () => (
  <BodyWrapper documentTitle="Coming Soon | Aperian">
    <div className="flex items-center justify-center mb-6">
      <AperianWordmark />
    </div>
    <div>
      <h1 className="mb-2 text-2xl font-normal font-headline">GlobeSmart<sup>&reg;</sup> is getting smarter.</h1>
      <RustDividingLine />
      <p className="mt-2 mb-0 font-sans text-xs font-normal">A new GlobeSmart<sup>&reg;</sup> experience is coming soon,
        but this page isn&#39;t quite ready.
      </p>
    </div>
    <Button variant="primary" buttonType="link" rel="noopener noreferrer" target="_blank" to="https://docs.google.com/a/heysparkbox.com/forms/d/e/1FAIpQLScQr4HgqHNsuqcj-v5NTl91YlWCCK7wgt1cNJ4grxZ179yrEQ/viewform">
      Keep me updated
    </Button>
    <div className="flex flex-col items-center">
      <p className="mb-2 font-sans text-xs font-normal underline text-rust-500"><Link to="/profile">Return to Profile</Link></p>
      <p className="mb-0 font-sans text-xs font-normal">Questions? <a rel="noopener noreferrer" target="_blank" href="https://aperian.zendesk.com/hc/">Contact us</a>.</p>
    </div>
  </BodyWrapper>
);

export default ComingSoon;
