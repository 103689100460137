import React from 'react';
import { iconPropTypes, defaultIconProps } from './types';

import { strokeColorMap } from '../index.js';

const Search = ({ className, iconColor }) => (
  <svg className={`${className} ${strokeColorMap[iconColor]}`} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19 19L14.65 14.65M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

Search.propTypes = iconPropTypes;
Search.defaultProps = defaultIconProps;

export default Search;
