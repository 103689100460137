import React from 'react';

import TeamsPromoFeature from '../../../../lib/features/TeamsPromo';

const teamsPromo = user => new TeamsPromoFeature(user)
  .positive(() => (
    <div className="notification-message notification-message--confirm">
      <p className="notification-message__text">
        Thanks for being a GlobeSmart Beta user! Premium access is free until
        January 31.{' '}
        <a href="http://www.aperianglobal.com/try-globesmart-profile-for-teams/">
          Learn more
        </a>.
      </p>
    </div>
  ))
  .negative(() => null)
  .execute();

export default teamsPromo;
