export const navItems = hasCompletedIBI => [
  {
    name: 'GlobeSmart Profile',
    to: '/profile',
    icon: 'profile',
    criteria: 'all',
    premiumFeature: true,
    children: [
      { name: 'My Profile', icon: 'profile', to: '/profile', exact: true },
      { name: 'New Comparison', icon: 'compare', to: '/profile/comparison/new' },
      { name: 'My Teams', icon: 'team', to: '/profile/teams' },
      { name: 'Manage Connections', icon: 'eye', to: '/profile/connections' },
    ],
  },
  {
    name: 'GlobeSmart Guides',
    to: '/guides',
    icon: 'culture-guide',
    criteria: 'all',
    premiumFeature: true,
  },
  {
    name: 'Learning Modules',
    to: '/learning-modules',
    icon: 'learning',
    criteria: 'all',
    premiumFeature: false,
  },
  { name: 'Inclusive Behaviors Inventory',
    to: '/assessments/ibi',
    icon: 'ibi-symbol',
    criteria: 'ibi',
    premiumFeature: false,
    children: [
      {
        name: 'IBI Home',
        to: '/assessments/ibi',
        exact: true,
      },
      {
        name: `${hasCompletedIBI ? 'My Results' : 'Take IBI'}`,
        to: `${hasCompletedIBI ? '/assessments/ibi/results' : '/assessments/ibi/survey'}`,
      },
    ] },
  {
    name: 'Aperian Live',
    to: '/aperian-live',
    icon: 'aperian-live',
    criteria: 'aperianlive',
    secondaryCriteria: 'premium',
    premiumFeature: false,
  },
  {
    name: 'Aperian Copilot',
    to: '/copilot',
    icon: 'copilot',
    current: false,
    criteria: 'copilot',
    premiumFeature: false,
  },
];
