import PropTypes from 'prop-types';

import { propTypes as profilePropTypes } from '../../../pages/Profile';

const propTypes = {
  hideAvatar: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  hasCulture: PropTypes.bool,
  cultureDisclaimer: PropTypes.string,
  cultureLinkText: PropTypes.string,
  cultureUrl: PropTypes.string,
  hasResizeSupport: PropTypes.bool,
  displayDimensionLink: PropTypes.bool,
  isDashboard: PropTypes.bool,
  withBorder: PropTypes.bool,
  ...profilePropTypes,
  downloadLocation: PropTypes.string,
};

const defaultProps = {
  hideAvatar: false,
  hasCulture: false,
  cultureDisclaimer: '',
  cultureLinkText: '',
  cultureUrl: '',
  displayDimensionLink: true,
  hasResizeSupport: false,
  isDashboard: false,
  withBorder: false,
  downloadLocation: '',
};

export {
  propTypes,
  defaultProps,
};
