import React from 'react';

import AperianWordmark from '../../../components/AperianWordmark';

const PrintLayoutHeader = () => (
  <div className="hidden p-0 bg-white print:flex print:justify-between">
    <AperianWordmark className="cmp-site-header__globe-smart" />
    <span className="mb-0 font-serif text-xl text-charcoal-900">
      GlobeSmart Profile
    </span>
  </div>
);

export default PrintLayoutHeader;
