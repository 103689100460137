import React from 'react';
import PropTypes from 'prop-types';

const Drawer = ({
  id,
  showDrawerContents,
  children,
}) => (
  <section
    key={id}
    className={`relative overflow-hidden px-4 transition-all overflow-y-scroll duration-300 max-h-[500px] ${showDrawerContents ? 'h-full opacity-100' : 'h-0 opacity-0'}`}
  >
    <div className="pb-4">
      {children}
    </div>
  </section>
);

Drawer.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node,
  showDrawerContents: PropTypes.bool.isRequired,
};
Drawer.defaultProps = {
  children: undefined,
};

export default Drawer;
