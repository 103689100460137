import { isBefore } from 'date-fns';

const byExpirationDateDesc = (a, b) => {
  const expirationA = new Date(a.expirationDate);
  const expirationB = new Date(b.expirationDate);
  if (isBefore(expirationA, expirationB)) return 1;
  if (isBefore(expirationB, expirationA)) return -1;
  return 0;
};

export default byExpirationDateDesc;
