import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../../common/Button';
import { APIContext } from '../../../context/API';
import { memberRemoveAction } from '../../../actions/cohorts';
import ConfirmDeleteButtons from '../ConfirmDeleteButtons';

const DeleteUserButton = ({
  id,
  code,
  setReload,
  error,
  setNotification,
  firstName,
  lastName,
}) => {
  const { apiService } = useContext(APIContext);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const handleConfirmDelete = () => setConfirmDelete(true);
  const handleCancelDelete = () => setConfirmDelete(false);

  const userId = id;
  const removeMember = () => {
    memberRemoveAction(apiService, code, userId)
      .then(() => {
        setReload();
        setNotification({
          type: 'success',
          message: `${firstName} ${lastName} was successfully removed. Please wait 24 hours if you would like to re-enroll this user in the cohort.`,
        });
      })
      .catch(err => {
        error(err);
      });
  };

  return confirmDelete
    ? (
      <ConfirmDeleteButtons
        id={userId}
        onRemove={removeMember}
        onCancel={handleCancelDelete}
      />
    ) : (
      <div>
        <Button
          dataID={id}
          className="card__button"
          isWarning
          isNormalCase
          onClick={handleConfirmDelete}
        >
          Remove
        </Button>
      </div>
    );
};

DeleteUserButton.propTypes = {
  setReload: PropTypes.func.isRequired,
  error: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  setNotification: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
};

export default DeleteUserButton;
