const getTrialSurveyQuestions = apiService =>
  apiService.get('trials/survey');

const submitMiniSurveyAction = (apiService, data) =>
  apiService.post('trials/survey', data);

const createTrialOwnerAction = (apiService, data) =>
  apiService.post('trials/user', data);

const findTrialUserStatusAction = (apiService, email) =>
  apiService.post('trials/email', email);

const retrieveTokenMetaData = (apiService, token) =>
  apiService.get(`trials/${token}/metadata`);

const createTrialOrg = (apiService, orgData) =>
  apiService.post('trials/org/create', orgData);

export {
  getTrialSurveyQuestions,
  submitMiniSurveyAction,
  createTrialOwnerAction,
  findTrialUserStatusAction,
  retrieveTokenMetaData,
  createTrialOrg,
};
