import React from 'react';
import PropTypes from 'prop-types';

import { Context } from '../../context/ContextProvider';

import GlobeSmartModal from '../GlobeSmartModal';
import Payment from '../Payment';

import { trimPaymentUrlParams } from '../../lib/urls/trimPaymentUrlParams';

const needsPlanValidation = (prevProps, newProps) => {
  const { location } = newProps;
  return location.query
    ? !!((prevProps.location.search !== location.search && location.query.plan)) : false;
};

class PaymentViaUrl extends React.PureComponent {
  static contextType = Context;

  constructor(props, context) {
    super(props, context);
    this.state = {
      planIsValid: false,
      planInfo: null,
    };
  }

  componentDidMount() {
    const { location: { query } } = this.props;
    if (query?.plan) {
      this.validatePlan();
    }
  }

  componentDidUpdate(prevProps) {
    if (needsPlanValidation(prevProps, this.props)) {
      this.validatePlan();
    }
  }

  validatePlanId = () => {
    const { location: { query } } = this.props;
    if (Object.keys(query).includes('purchase')) {
      // make sure the requested plan ID is valid and not a $0 plan
      return this.context.apiService
        .get(`plan/${query.plan}`)
        .then(data => {
          const price = parseFloat(data.plan.price);
          if (!isNaN(price) && price > 0) {
            this.setState({ planIsValid: true, planInfo: data });
          }
        })
        .catch(() => {
          this.setState({ planIsValid: false });
        });
    }
    return null;
  };

  validatePlan() {
    this.validatePlanId()
      .then(() => {
        const { handleOpenModal, router } = this.context;
        const { planIsValid, planInfo } = this.state;
        const { location } = this.props;
        if (planIsValid && planInfo) {
          handleOpenModal({
            afterCloseAction: () =>
              trimPaymentUrlParams(location, router),
          });
        }
      });
  }

  render() {
    const { planInfo } = this.state;
    return (
      <GlobeSmartModal>
        <Payment
          planInfo={planInfo}
          coupon={this.props.location ? this.props.location.query?.coupon : null}
        />
      </GlobeSmartModal>
    );

    // const { query: { plan: planId } } = this.props.location;

    // Note: leaving this stuff commented for now since we
    //  might need messaging or different behavior for these cases...
    // https://aperianglobal.atlassian.net/browse/GS-563
    //
    // let shouldDisplayModal = true;
    //
    // if it's a premium plan and they don't already have premium access, show the modal
    // let shouldDisplayModal = this.isPremiumPlan()
    //     && !(new PremiumUserCriteria(this.context.currentUser)).test();
    //
    // if (!shouldDisplayModal) {
    //    // if it's not a premium plan and the plan is not in their current access list,
    //    //  show the modal
    //   shouldDisplayModal = !this.isPremiumPlan()
    //     && !this.context.currentUser.accessLevel.includes(planId);
    // }
  }
}

PaymentViaUrl.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object,
};

PaymentViaUrl.defaultProps = {
  location: {},
};

export default PaymentViaUrl;
