import React, { useEffect, useRef, useState } from 'react';

import { propTypes } from './types';
import Button from '../../../common/Button';
import LabelInput from '../../../common/LabelInput';

const AccountSettingsPasswordFields = ({ onUpdate }) => {
  const [state, setState] = useState({});

  const formRef = useRef(null);
  const newPasswordRef = useRef(null);

  const verifyPasswordsMatch = () => {
    if (state.confirmPassword !== state.newPassword) {
      newPasswordRef?.current.setCustomValidity('Passwords entered do not match. Please try again.');
    } else {
      newPasswordRef?.current.setCustomValidity('');
    }
  };

  useEffect(() => {
    verifyPasswordsMatch();
  }, [state]);

  const handleChange = e => {
    e.persist();
    setState(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const formSubmit = e => {
    e.preventDefault();
    if (formRef?.current.checkValidity()) {
      onUpdate('password', { password: state.newPassword });
    }
  };

  return (
    <form action="/user" method="PATCH" onSubmit={formSubmit} ref={formRef}>
      <div className="mb-2">
        <LabelInput
          id="newPassword"
          labelType="password"
          labelText="New Password"
          name="newPassword"
          value={state.newPassword}
          onChangeValue={handleChange}
          isRequired
          ref={newPasswordRef}
        />
      </div>
      <div className="mb-3">
        <LabelInput
          id="confirmPassword"
          labelType="password"
          labelText="Confirm Password"
          name="confirmPassword"
          value={state.confirmPassword}
          onChangeValue={handleChange}
        />
      </div>
      <Button
        className="float-right"
        isSmall
        variant="secondary"
      >Update password
      </Button>
    </form>
  );
};

AccountSettingsPasswordFields.propTypes = propTypes;

export default AccountSettingsPasswordFields;
