class FreeUserCriteria {
  constructor(user) {
    this.user = user;
  }

  // eslint-disable-next-line class-methods-use-this
  test() {
    return !!(this.user.accessLevel.indexOf('free') >= 0);
  }
}

export default FreeUserCriteria;
