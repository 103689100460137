import React from 'react';
import PropTypes from 'prop-types';
import parser from 'html-react-parser';
import DOMPurify from 'isomorphic-dompurify';

import Badges from '../common/Badges';
import BlockTimeRange from './BlockTimeRange';
import BlockButton from './BlockButton';
import Icon from '../common/Icon';
import { translations } from '../../lib/constants';

const Block = ({
  internalName,
  connection,
  blockType,
  typeName,
  title,
  description,
  completionRangeStart,
  completionRangeEnd,
  tags,
  blockImage,
  defaultImageLink,
  buttonText,
  html,
  moduleId,
  directUrl,
  asmtId,
  languages,
}) => {
  const languageNames = translations
    .filter(language => languages?.includes(language.value))
    .map(({ name }) => name);

  if (blockType === 3) {
    return (
      <div className="flex flex-wrap items-center justify-between gap-1 px-6 py-4 rounded-lg lg:flex-nowrap bg-caramel-400 md:px-10 md:py-8">
        <div>
          {parser(DOMPurify.sanitize(html, { ADD_ATTR: ['target'] }))}
        </div>
        <BlockButton
          connection={connection}
          buttonText={buttonText}
          moduleId={moduleId}
          directUrl={directUrl}
          blockType={blockType}
          asmtId={asmtId}
          internalName={internalName}
          title={title}
          launchedFrom="Dashboard"
        />
      </div>
    );
  }
  return (
    <div className="bg-white rounded-lg">
      <div className="flex flex-col-reverse sm:flex-row gap-7">
        <div className="flex flex-col justify-between px-6 py-4 md:pl-10 md:pb-10 sm:w-full md:w-3/5">
          <div className="flex items-center gap-3">
            <div className="rounded-full bg-ivory-400">
              <Icon
                icon="learning"
                iconColor="gray"
                className="m-2.5 w-4 h-4 fill-slate-400"
              />
            </div>
            <p className="my-2 font-serif text-2xl antialiased font-thin tracking-wide sm:my-4 text-charcoal-500">
              {typeName}
            </p>
          </div>
          <div className="py-4 sm:py-10">
            <h4 className="mb-3 font-serif text-xl font-semibold leading-snug tracking-wider md:text-2xl text-charcoal-900">
              {title}
            </h4>
            <Badges
              badges={tags}
              size="small"
            />
            <div className={`flex ${completionRangeStart ? 'mt-4' : 'mt-2'} mb-2 items-center`}>
              {completionRangeStart ? (
                <Icon
                  icon="clock"
                  iconColor="medium-primary"
                  className="w-3 h-3"
                />
              ) : null}
              <BlockTimeRange
                completionRangeStart={completionRangeStart}
                completionRangeEnd={completionRangeEnd}
              />
            </div>
            <p className="py-4 font-sans text-sm font-light md:text-base">
              {description}
            </p>
            {languageNames.length ? (
              <p className="font-sans text-xs font-light md:text-sm">
                Available languages: {
                  languageNames.length > 2
                    ? languageNames.join(', ').replace(/,([^,]*)$/, ', and$1')
                    : languageNames.join(' and ')
                }
              </p>
            ) : null}
          </div>
          <BlockButton
            connection={connection}
            buttonText={buttonText}
            moduleId={moduleId}
            directUrl={directUrl}
            blockType={blockType}
            asmtId={asmtId}
            internalName={internalName}
            title={title}
            launchedFrom="Dashboard"
          />
        </div>
        <div className="sm:w-full md:w-2/5">
          <img
            src={blockImage || defaultImageLink}
            alt={`${title} logo`}
            className="object-cover h-full min-w-full rounded-tl-lg rounded-tr-lg rounded-br-none sm:rounded-br-lg sm:rounded-tl-none aspect-square"
          />
        </div>
      </div>
    </div>
  );
};

Block.propTypes = {
  connection: PropTypes.string,
  blockType: PropTypes.number,
  typeName: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  completionRangeStart: PropTypes.string,
  completionRangeEnd: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  blockImage: PropTypes.string,
  defaultImageLink: PropTypes.string,
  buttonText: PropTypes.string,
  html: PropTypes.string,
  moduleId: PropTypes.number,
  directUrl: PropTypes.string,
  asmtId: PropTypes.string,
  internalName: PropTypes.string,
  languages: PropTypes.arrayOf(PropTypes.string),
};

Block.defaultProps = {
  connection: 'auth0',
  blockType: 0,
  typeName: 'Learning Module',
  title: 'Learning Modules',
  description: 'Block Description',
  completionRangeStart: '',
  completionRangeEnd: '',
  languages: [],
  tags: [],
  blockImage: '',
  buttonText: '',
  html: '',
  defaultImageLink: '',
  moduleId: 0,
  directUrl: '',
  asmtId: '',
  internalName: '',
};

export default Block;
