import PropTypes from 'prop-types';
import { profilePropTypes } from '../../../../pages/Profile';

const propTypes = {
  profiles: PropTypes.arrayOf(profilePropTypes),
};

export {
  propTypes,
};
