import React, { Fragment } from 'react';
import { Link } from 'react-router';
import { Menu, Transition } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';

import Avatar from '../../common/Avatars/Avatar';
import Icon from '../../common/Icon';

import { menuItems } from './menuItems';

const AccountMenu = ({ firstName, lastName, avatarUrl, accessLevel, organizationsOwned }) => (
  <Menu as="div" className="relative">
    <Menu.Button className="-m-1.5 flex items-center p-1.5">
      <span className="sr-only">Open user menu</span>
      <Avatar
        className="w-8 h-8 rounded-full bg-gray-50"
        avatarImgAlt={`${firstName} ${lastName}`}
        avatarUrl={avatarUrl}
        isNavigation
      />
      <span className="hidden lg:flex lg:items-center">
        <span
          className="ml-4 text-sm font-semibold leading-6 text-charcoal-900"
          aria-hidden="true"
        >
          {firstName} {lastName}
        </span>
        <Icon icon="chevron-down" iconColor="gray" className="w-3 h-3 ml-2 text-gray-400" aria-hidden="true" />
      </span>
    </Menu.Button>
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items className="absolute right-0 z-[400] mt-2.5 w-40 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
        {menuItems.map(({ to, name, criteria }) =>
          (
            criteria === 'all'
            || (criteria && accessLevel?.includes(criteria))
            || (criteria === 'organizationsOwned' && organizationsOwned)
          ) && (
            <Menu.Item key={name}>
              {({ active, close }) => (
                <div>
                  <Link
                    to={to}
                    className={twMerge(
                      active ? 'bg-stone-50' : '',
                      'block px-3 py-1 text-sm leading-6 text-gray-900',
                    )}
                    onClick={close}
                  >
                    {name}
                  </Link>
                </div>
              )}
            </Menu.Item>
          ))}
      </Menu.Items>
    </Transition>
  </Menu>
);

AccountMenu.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string.isRequired,
  accessLevel: PropTypes.arrayOf(PropTypes.string).isRequired,
  organizationsOwned: PropTypes.bool.isRequired,
};

export default AccountMenu;
