class CopilotUserCriteria {
  constructor(user) {
    this.user = user;
  }

  test() {
    return !!(this.user.accessLevel.some(string => string.includes('copilot')));
  }
}

export default CopilotUserCriteria;
