import React from 'react';
import PropTypes from 'prop-types';
import ElementQuery from 'react-eq';

import AccessCode from './AccessCode';

const queries = {
  'card-layout': 400,
};

const renderAccessCodes = (accessCodes, onDeactivate) => {
  if (accessCodes.length === 0) {
    return (<p className="text-center">User does not own any Access Codes at this time.</p>);
  }
  return accessCodes.map(accessCode => (
    <li key={accessCode.code}>
      <AccessCode {...accessCode} onDeactivate={onDeactivate} />
    </li>
  ));
};

const ReviewAccessCodes = props => (
  <section className="px-4 py-8 bg-transparent rounded-lg">
    {props.accessCodes.length > 0 ? (
      <h3 className="font-sans text-base font-normal text-center text-charcoal-900">
        Access Codes
      </h3>
    ) : null}
    <ElementQuery queries={queries}>
      <ul className="p-0 m-0 list-none">
        {renderAccessCodes(props.accessCodes, props.onDeactivate)}
      </ul>
    </ElementQuery>
  </section>
);

ReviewAccessCodes.propTypes = {
  onDeactivate: PropTypes.func,
  accessCodes: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string,
    description: PropTypes.string,
    expirationDate: PropTypes.string,
    planName: PropTypes.string,
    quantity: PropTypes.number,
    active: PropTypes.bool,
  })),
};

ReviewAccessCodes.defaultProps = {
  onDeactivate: () => {},
  accessCodes: null,
};

export default ReviewAccessCodes;
