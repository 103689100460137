import { useContext, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';

import { NotificationContext } from '../../../../context/Notification';
import { APIContext } from '../../../../context/API';

import SearchUsersAction from '../../../../actions/users/search';

const UserSearch = props => {
  const { apiService } = useContext(APIContext);
  const { notificationMessage, removeNotification } = useContext(NotificationContext);
  const [state, setState] = useReducer((data, newData) =>
    ({ ...data, ...newData }), {
    firstName: '',
    lastName: '',
    email: '',
    users: [],
    notification: null,
  });

  const search = () => {
    const { firstName, lastName, email } = state;

    if (!firstName && !lastName && !email) {
      setState({ users: [] });
      return;
    }

    new SearchUsersAction(apiService)
      .execute({
        firstName,
        lastName,
        email: encodeURIComponent(email),
      })
      .then(({ users }) => setState({ users }))
      .catch(err => {
        setState({
          notification: {
            type: 'warning',
            message: `Error searching: ${err}`,
          },
        });
      });
  };

  const searchParamChange = params => {
    setState({
      notification: null,
      ...params,
    });
  };

  useEffect(() => {
    search();
  }, [state.firstName, state.lastName, state.email]);

  useEffect(() => {
    let timeout;
    if (notificationMessage) {
      timeout = window.setTimeout(() => {
        removeNotification();
      }, 5000);
    }
    return () => window.clearTimeout(timeout);
  }, [notificationMessage]);

  const updateUserState = updatedUser => {
    if (!updatedUser) return;
    setState(prevState => {
      const usersCopy = [...prevState.users];
      const theUser = find(usersCopy, { id: updatedUser.id });
      if (theUser) {
        theUser.organizations = updatedUser.organizations;
      }
      return { users: usersCopy };
    });
  };

  return (
    props.render({
      firstName: state.firstName,
      lastName: state.lastName,
      email: state.email,
      users: state.users,
      onSearchParamChange: searchParamChange,
      search,
      updateUserState,
      notification: notificationMessage || state.notification,
    })
  );
};

UserSearch.propTypes = {
  render: PropTypes.func.isRequired,
};

export default UserSearch;
