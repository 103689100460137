import React from 'react';
import PropTypes from 'prop-types';
import Toggle from '../../../Toggle';
import Video from '../../../Video';
import LineChart from '../../../LineChart';

const tendencyList = tendencies => {
  if (!tendencies) return null;

  return (
    <div className="pl-4">
      <ul className="leading-8 list-disc">
        {tendencies.map(tendency => (
          <li className="font-sans text-sm leading-6 md:text-base md:leading-8 text-charcoal-900 print:text-xs" key={tendency}>
            {tendency}
          </li>
        ))}
      </ul>
    </div>

  );
};

const ProfileDimensionItem = props => {
  const {
    differenceCTA,
    positioningStatement,
    tendencyHeading,
    tendencies,
  } = props.you;
  const { other, conflictVideoID, conflictVideoSentence, score, labels } = props;

  const renderOther = () => {
    const btnClasses = 'flex m-auto font-medium text-white py-3 font-xl font-headline bg-charcoal-900 hover:bg-charcoal-600 rounded-5xl print:hidden';
    if (other) {
      return (
        <Toggle
          id="dimensionTitle"
          btnClasses={btnClasses}
          buttonText={differenceCTA}
          arrowColor="white"
        >
          <div className="grid gap-6 pt-8 md:pt-12 lg:pt-16 lg:gap-0 md:grid-cols-2">
            <div className="w-full max-w-xs m-auto lg:max-w-lg lg:w-3/4">
              <Video wistiaId={conflictVideoID} pageTitle="My Profile" />
              <p className="mt-4 font-sans text-base font-semibold text-charcoal-900">{conflictVideoSentence}</p>
            </div>
            <div className="flex flex-col justify-center w-full m-auto text-left lg:w-3/4">
              <h4 className="font-sans text-base font-semibold text-charcoal-900">{other.tendencyHeading}</h4>
              {tendencyList(other.tendencies)}
            </div>
          </div>
        </Toggle>
      );
    }

    return (
      <Toggle
        id="dimensionTitle"
        btnClasses={btnClasses}
        buttonText={conflictVideoSentence}
      >
        <div className="w-full max-w-lg pt-8 m-auto lg:w-3/4 md:pt-12 lg:pt-16">
          <Video wistiaId={conflictVideoID} pageTitle="My Profile" />
        </div>
      </Toggle>
    );
  };

  return (
    <div className="w-full px-4 py-6 bg-white md:px-6 md:py-10 lg:px-10 lg:py-16 rounded-3xl print:!py-4">
      <h3 className="text-2xl font-semibold text-center md:text-3xl font-headline text-charcoal-900 print:text-lg print:text-charcoal-900 print:font-headine print:mb-1">
        {positioningStatement}
      </h3>
      <div className="grid gap-6 my-8 lg:gap-0 md:my-12 lg:my-16 md:grid-cols-2 print:!m-0 print:text-lg print:!grid-cols-1">
        <div className="w-full px-6 py-4 m-auto lg:w-3/4 h-28 md:py-auto md:px-10 bg-slate-50 rounded-2xl print:hidden">
          <LineChart
            leftText={labels.left}
            rightText={labels.right}
            points={[score]}
          />
        </div>
        <div className="flex flex-col justify-center w-full m-auto text-left lg:w-3/4">
          <h4 className="mb-4 font-sans text-lg font-semibold text-charcoal-900 print:!text-xs print:m-0">{tendencyHeading}</h4>
          <div>
            {tendencyList(tendencies)}
          </div>
        </div>
      </div>
      {renderOther(props)}
    </div>
  );
};

const item = PropTypes.shape({
  title: PropTypes.string.isRequired,
  positioningStatement: PropTypes.string.isRequired,
  tendencyHeading: PropTypes.string.isRequired,
  differenceCTA: PropTypes.string,
  tendencies: PropTypes.arrayOf(PropTypes.string).isRequired,
  styleSwitchingLink: PropTypes.string,
});

ProfileDimensionItem.propTypes = {
  you: item.isRequired,
  other: item,
  conflictVideoID: PropTypes.string,
  conflictVideoSentence: PropTypes.string,
  score: PropTypes.number,
  labels: PropTypes.shape({
    left: PropTypes.string,
    right: PropTypes.string,
  }),
};

ProfileDimensionItem.defaultProps = {
  other: null,
  conflictVideoID: '',
  conflictVideoSentence: '',
  score: 0,
  labels: {},
};

export default ProfileDimensionItem;
