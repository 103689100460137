/* eslint-disable prefer-arrow-callback */
import React from 'react';
import PropTypes from 'prop-types';

import {
  AddressElement,
} from '@stripe/react-stripe-js';
import { GOOGLE_MAPS_API_KEY } from '../../../lib/constants';

const PaymentBillingAddressForm = ({ handleFormChange }) => (
  <div className="mt-6 mb-8 overflow-auto text-left min-h-[20rem] h-auto">
    <AddressElement
      options={{
        mode: 'billing',
        autocomplete: {
          mode: 'google_maps_api',
          apiKey: GOOGLE_MAPS_API_KEY,
        },
      }}
      onChange={event => {
        handleFormChange(event);
      }}
    />
  </div>
);

PaymentBillingAddressForm.propTypes = {
  handleFormChange: PropTypes.func.isRequired,
};

export default PaymentBillingAddressForm;
