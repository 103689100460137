/* eslint-disable prefer-arrow-callback */
import React, { forwardRef, useContext, useImperativeHandle, useRef } from 'react';
import { isValid } from 'date-fns';
import PropTypes from 'prop-types';

import AutoSuggest from '../../../AutoSuggest';
import DatePickerInput from '../../../common/DatePicker';
import LabelInput from '../../../common/LabelInput';

import { APIContext } from '../../../../context/API';
import { NotificationContext } from '../../../../context/Notification';

import { searchOrgsAction } from '../../../../actions/cohorts';

const NonEventCohortForm = forwardRef(
  function NonEventForm({
    formData,
    handleFormData,
    plans,
    formError,
  },
  ref) {
    const { apiService } = useContext(APIContext);
    const { addNotification } = useContext(NotificationContext);

    const calendarRef = useRef(null);
    const stripeRef = useRef(null);

    const validateForm = () => {
      const isMissingStripePlan = !formData.stripePlans || formData.stripePlans.length === 0;
      return isMissingStripePlan ? {
        stripePlans: 'Please select a plan for your cohort.',
      } : null;
    };

    const resetForm = () => {
      stripeRef.current.resetOptions();
      handleFormData({
        type: formData.type,
        displayName: '',
        name: '',
        org: '',
        orgName: null,
        stripePlans: [],
        linkExpiryDate: null,
        sfJobCode: '',
        sfLink: '',
        redirectUrl: '',
      });
    };

    useImperativeHandle(ref, () => ({
      validateForm,
      resetForm,
    }));

    const handleChange = ({ target: { name, value } }) => handleFormData({ [name]: value });

    const searchOrg = async value => {
      try {
        const { organizations } = await searchOrgsAction(apiService, value);
        return organizations.map(({ name, id }) => ({ name, value: id }));
      } catch (err) {
        addNotification({ type: 'failure', message: `Error searching for organization: ${err.message}` });
        return [];
      }
    };

    const handleDateChange = (date, properties) => {
      const updatedDateProperties = properties.reduce((obj, property) => {
      // eslint-disable-next-line no-param-reassign
        obj[property] = isValid(new Date(date)) ? new Date(date) : null;
        return obj;
      }, {});
      handleFormData(updatedDateProperties);
    };

    const handleDateFocusOut = e => {
      const { target: { value: date } } = e;
      handleFormData({ linkExpiryDate: isValid(new Date(date)) ? new Date(date) : null });
    };

    const handleRemovePlan = value => {
      handleFormData({
        stripePlans: formData.stripePlans.filter(plan => plan.value !== value),
      });
    };

    return (
      <>
        <div>
          <div className="mb-4">
            <LabelInput
              id="displayName"
              name="displayName"
              labelType="text"
              isRequired
              labelText="Display Name"
              value={formData.displayName}
              onChangeValue={handleChange}
              helperText="Enter a friendly name that will appear to cohort members."
            />
          </div>
          <div className="mb-4">
            <LabelInput
              id="name"
              name="name"
              labelType="text"
              isRequired
              labelText="Name (Internal)"
              value={formData.name}
              onChangeValue={handleChange}
              helperText="Enter an internal name for search indexing."
            />
          </div>
          <div className="mb-4">
            <AutoSuggest
              name="org"
              placeholder="Search or View All Organizations"
              onSelectOption={handleChange}
              onSearchOptions={searchOrg}
              previousSelection={formData.orgName || ''}
              helperText="If needed, connect the cohort to an organization."
              labelText="Organization Name"
            />
          </div>
        </div>
        <div>
          <div className="mb-4">
            <AutoSuggest
              ref={stripeRef}
              name="stripePlans"
              placeholder="Search or View All Plans"
              options={plans}
              selectedOptions={formData?.stripePlans}
              onSelectOption={handleChange}
              onRemoveSelectedOption={handleRemovePlan}
              isMultiSelect
              helperText="Select the products this cohort needs. Choose the most appropriate time length."
              labelText="Select Plan"
              errorMessage={formError.stripePlans}
            />
          </div>
        </div>
        <div>
          <DatePickerInput
            ref={calendarRef}
            id="linkExpiryDate"
            labelText="Link Expiry Date"
            handleIconClick={() => calendarRef.current.setFocus()}
            value={formData.linkExpiryDate}
            dateProperties={['linkExpiryDate']}
            handleDateChange={handleDateChange}
            onBlur={handleDateFocusOut}
            onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
            onChangeValue={handleChange}
            minDate
            dateFormat="yyyy-MM-dd"
            helperText="Expiration date for the Enrollment Link. Choose the most appropriate time length."
          />
        </div>
        <LabelInput
          id="sfJobCode"
          name="sfJobCode"
          labelType="text"
          labelText="SF Job Code"
          value={formData.sfJobCode}
          onChangeValue={handleChange}
          helperText="Internal, optional reference. You can search by this field."
        />
        <LabelInput
          id="sfLink"
          name="sfLink"
          labelType="text"
          labelText="SF Opportunity Link"
          value={formData.sfLink}
          onChangeValue={handleChange}
          helperText="Internal, optional reference."
        />
        <LabelInput
          id="redirectUrl"
          name="redirectUrl"
          labelType="text"
          labelText="Redirect URL"
          value={formData.redirectUrl}
          onChangeValue={handleChange}
          helperText="Enter the URL cohort members should be sent to after enrolling. This can be any URL."
        />
      </>
    );
  });

NonEventCohortForm.propTypes = {
  handleFormData: PropTypes.func.isRequired,
  plans: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })),
  formData: PropTypes.shape({
    type: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    name: PropTypes.string,
    displayName: PropTypes.string,
    linkExpiryDate: PropTypes.instanceOf(Date),
    orgName: PropTypes.string,
    redirectUrl: PropTypes.string,
    sfJobCode: PropTypes.string,
    sfLink: PropTypes.string,
    stripePlans: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
      })),
  }).isRequired,
  formError: PropTypes.shape({
    stripePlans: PropTypes.string,
  }),
};

NonEventCohortForm.defaultProps = {
  plans: [],
  formError: {},
};

export default NonEventCohortForm;
