import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ProfileCardIconWithButton from '../../../../ProfileCards/ProfileCardIconWithButton';
import ProfileCardTextWithButton from '../../../../ProfileCards/ProfileCardTextWithButton';

const SentInvitationCard = props => {
  const {
    token,
    onCancel,
    isInviter,
    onResend,
    invitationSent,
  } = props;
  const [wasResent, setWasResent] = useState(!!invitationSent);
  const [confirmCancel, setConfirmCancel] = useState(false);

  useEffect(() => {
    setWasResent(false);
    setConfirmCancel(false);
  }, [invitationSent]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setWasResent(false);
    }, 2000);
    return () => clearTimeout(timeOut);
  }, [wasResent]);

  const handleResend = e => {
    const { dataset: { id } } = e.target;
    setWasResent(true);
    onResend(id);
  };

  const completeCancel = e => {
    const { dataset: { id } } = e.target;
    onCancel(id);
  };

  const renderSentCard = () => {
    const buttonOptions = isInviter ? {
      isInvitation: true,
      confirmText: wasResent ? 'Sent' : 'Resend',
      confirmButton: {
        variant: 'primary',
        isNormalCase: true,
        onClick: handleResend,
        disabled: wasResent,
      },
      cancelText: 'Cancel',
      cancelButton: {
        isNormalCase: true,
        isWarning: true,
        onClick: () => setConfirmCancel(true),
      },
    } : null;

    return <ProfileCardIconWithButton {...props} {...buttonOptions} />;
  };

  const renderConfirmCancel = () => {
    const options = {
      messageText: 'Are you sure you want to cancel this invitation?',
      confirmText: 'Yes, Cancel',
      confirmButton: {
        isWarning: true,
        onClick: completeCancel,
      },
      cancelText: 'No, Keep',
      cancelButton: {
        variant: 'secondary',
        onClick: () => setConfirmCancel(false),
      },
    };

    return <ProfileCardTextWithButton {...options} token={token} />;
  };

  if (confirmCancel) {
    return renderConfirmCancel();
  }

  return renderSentCard();
};

SentInvitationCard.propTypes = {
  token: PropTypes.string.isRequired,
  onResend: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isInviter: PropTypes.bool.isRequired,
};

export default SentInvitationCard;
