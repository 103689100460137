import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import LineChart from '../../../LineChart';

import { trackCompleteOnboardingStep } from '../../../../lib/tracker/onboarding';
import Button from '../../../common/Button';

const Snapshot = ({ nextStep, formData }) => {
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(false);

  useEffect(() => {
    if (formData.isTrialOwner) {
      setShowWelcomeMessage(true);
      const timeout = setTimeout(() => {
        setShowWelcomeMessage(false);
      }, 3500);
      return () => clearTimeout(timeout);
    }
    return setShowWelcomeMessage(false);
  }, []);

  const continueOnboardingFlow = async () => {
    await trackCompleteOnboardingStep('DI Placement');
    nextStep();
  };

  const renderStyleMessage = () => {
    let styleMessage;
    switch (formData.dimension) {
      case 'Direct':
        styleMessage = 'You prefer to get to your point quickly, avoid ambiguity, and speak from the heart. You are likely comfortable making requests, giving direction, or voicing disagreement openly.';
        break;
      case 'Indirect':
        styleMessage = 'You value context, express disagreement in subtle ways, and read body language with ease. You may pick up on tone and subtle cues, meaning you can sense potential issues before others.';
        break;
      case 'in the middle':
        styleMessage = 'You are adaptable. You speak from the heart, but can be subtle as well. You are able to strike a balance between when to say exactly what you mean and when to ease into your message.';
        break;
      default:
        styleMessage = '';
    }

    return styleMessage;
  };

  return showWelcomeMessage
    ? (
      <div className="relative text-center bg-gray-10 min-h-100">
        <div className="w-80">
          <h1>Welcome back! </h1>
          <h1>Let&apos;s pick up where you left off.</h1>
        </div>
      </div>
    ) : (
      <div className="grid grid-cols-1 md:grid-cols-2 min-h-100">
        <div className="flex justify-center w-full px-8 py-12 m-auto bg-white md:p-0 lg:p-4 md:bg-ivory-400 md:w-3/5 lg:w-4/6 lg:justify-start max-h-90 lg:m-0">
          <div className="w-full">
            <p className="font-serif text-2xl italic tracking-wider text-left">Your communication style</p>
            <LineChart
              leftText="Direct"
              rightText="Indirect"
              points={[100 - formData.dimensionPercent]}
            />
          </div>
        </div>
        <div className="flex justify-center px-6 pt-10 m-auto text-left md:w-3/5 lg:w-4/6 max-h-90 md:p-0">
          <div className="md:mt-6 ">
            <h1 className="mb-6 font-serif text-4xl font-normal tracking-wider md:text-5xl">Here&apos;s your snapshot.</h1>
            <p className="mb-4 font-serif text-xl italic leading-10 tracking-wider text-rust-500 md:text-3xl md:mb-6">You&apos;re more {formData.dimension}.</p>
            <p className="mb-8 font-sans text-sm text-gray-800 md:text-lg">
              {renderStyleMessage()}
            </p>
            <div className="max-w-2xl">
              <Button
                variant="primary"
                trailingButtonIcon="arrow-right"
                onClick={continueOnboardingFlow}
              >Continue
              </Button>
            </div>
          </div>
        </div>
      </div>

    );
};

Snapshot.propTypes = {
  nextStep: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    isTrialOwner: PropTypes.bool,
    dimension: PropTypes.string,
    dimensionPercent: PropTypes.number,
  }).isRequired,
};

export default Snapshot;
