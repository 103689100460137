import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import SectionContainer from '../../../common/Containers/Section';
import GroupMemberCard from './GroupMemberCard';
import MemberDescription from './GroupMemberCard/MemberDescription';
import Raters from './Raters';

const GroupMembers = ({
  assessment,
  groupId,
  members,
  emailMemberReport,
  inviteToUpgrade,
  removeMember,
  remindMember,
  onCancelRater,
  isIbi,
  groupOrTeam,
  handleSetNotification,
  onResend,
  removeRater,
  memberWithNewRaters,
}) => {
  const [showRaters, setShowRaters] = useState(!!memberWithNewRaters);
  const [ratersForMember, setRatersForMember] = useState(memberWithNewRaters);

  const ratersForMemberRef = useRef(null);

  useEffect(() => {
    if (memberWithNewRaters) {
      setShowRaters(true);
      setRatersForMember(memberWithNewRaters);
    }
  }, [memberWithNewRaters]);

  useEffect(() => {
    let timer;
    if (ratersForMemberRef.current) {
      timer = setTimeout(() => {
        ratersForMemberRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
      }, 250);
    }
    return () => clearTimeout(timer);
  }, [ratersForMember]);

  const toggleShowRaters = memberToShowRaters => {
    if (showRaters && ratersForMember === memberToShowRaters) {
      setShowRaters(false);
      setRatersForMember(null);
    } else {
      setShowRaters(true);
      setRatersForMember(memberToShowRaters);
    }
  };

  return (
    <SectionContainer title={`${groupOrTeam} Members`}>
      <ul className="list-none">
        {members.map(member => {
          const {
            id,
            memberId,
            name,
            avatarUrl,
            surveyStatus,
          } = member;
          return (
            <li key={id} ref={id === ratersForMember ? ratersForMemberRef : null}>
              <GroupMemberCard
                id={id}
                avatarUrl={avatarUrl}
                memberId={memberId}
                displayName={name}
                displayDescription={(
                  <MemberDescription
                    {...member}
                    isIbi={isIbi}
                    inviteToUpgrade={inviteToUpgrade}
                  />
                )}
                surveyStatus={surveyStatus}
                isIbi={isIbi}
                emailMemberReport={emailMemberReport}
                removeMember={removeMember}
                remindMember={remindMember}
                withDrawer={!!(isIbi)}
                drawerLabel="Raters"
                drawerContents={(
                  isIbi && (
                  <Raters
                    assessment={assessment}
                    groupId={groupId}
                    groupMember={member}
                    onCancelRater={onCancelRater}
                    handleSetNotification={handleSetNotification}
                    onResend={onResend}
                    removeRater={removeRater}
                  />
                  )
                )}
                showDrawerContents={showRaters && ratersForMember === id}
                toggleDrawer={toggleShowRaters}
              />
            </li>
          );
        })}
      </ul>
    </SectionContainer>
  );
};

GroupMembers.propTypes = {
  assessment: PropTypes.string.isRequired,
  groupId: PropTypes.number.isRequired,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
      avatarUrl: PropTypes.string,
      dateJoined: PropTypes.string,
      surveyStatus: PropTypes.string,
      remindedAt: PropTypes.string,
      accessCode: PropTypes.string,
    }),
  ).isRequired,
  emailMemberReport: PropTypes.func.isRequired,
  inviteToUpgrade: PropTypes.func.isRequired,
  removeMember: PropTypes.func.isRequired,
  remindMember: PropTypes.func.isRequired,
  onCancelRater: PropTypes.func.isRequired,
  isIbi: PropTypes.bool.isRequired,
  groupOrTeam: PropTypes.string.isRequired,
  handleSetNotification: PropTypes.func.isRequired,
  onResend: PropTypes.func.isRequired,
  removeRater: PropTypes.func.isRequired,
  memberWithNewRaters: PropTypes.string,
};

GroupMembers.defaultProps = {
  memberWithNewRaters: null,
};

export default GroupMembers;
