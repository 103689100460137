class LookupAccessCodeAction {
  constructor(apiService) {
    this.apiService = apiService;
  }

  execute({ accesscode }) {
    return this.apiService.get(`access-codes/${accesscode}`);
  }
}

export default LookupAccessCodeAction;
