import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../tailwind.config.js';

export const { theme } = resolveConfig(tailwindConfig);

export const rotate = rotateIcon =>
  (rotateIcon
    ? '-rotate-180 transition-transform duration-500'
    : 'rotate-0 transition-transform duration-500'
  );
