import PropTypes from 'prop-types';

const propTypes = {
  profiles: PropTypes.arrayOf(
    PropTypes.shape({
      shortDisplayName: PropTypes.string.isRequired,
      avatarUrl: PropTypes.string.isRequired,
      cultureGuideUrl: PropTypes.string,
      userId: PropTypes.string,
    })).isRequired,
  onAvatarPositionsChanged: PropTypes.func.isRequired,
  maxAvatars: PropTypes.number.isRequired,
  showControls: PropTypes.bool,
};

const defaultProps = {
  showControls: false,
};

export {
  propTypes,
  defaultProps,
};
