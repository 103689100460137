import React from 'react';
import { iconPropTypes, defaultIconProps } from './types';
import { strokeColorMap } from '../index.js';

const RetakeIcon = ({ className, iconColor }) => (
  <svg className={`${className} ${strokeColorMap[iconColor]}`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 4.00008V10.0001M1 10.0001H7M1 10.0001L5.64 5.64008C6.71475 4.56479 8.04437 3.77928 9.50481 3.35685C10.9652 2.93442 12.5089 2.88883 13.9917 3.22433C15.4745 3.55984 16.8482 4.26551 17.9845 5.27549C19.1209 6.28548 19.9828 7.56686 20.49 9.00008M23 20.0001V14.0001M23 14.0001H17M23 14.0001L18.36 18.3601C17.2853 19.4354 15.9556 20.2209 14.4952 20.6433C13.0348 21.0657 11.4911 21.1113 10.0083 20.7758C8.52547 20.4403 7.1518 19.7346 6.01547 18.7247C4.87913 17.7147 4.01717 16.4333 3.51 15.0001" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

RetakeIcon.propTypes = iconPropTypes;
RetakeIcon.defaultProps = defaultIconProps;

export default RetakeIcon;
