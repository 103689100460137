class SurveyReminderAction {
  constructor(apiService) {
    this.apiService = apiService;
  }

  execute(teamId, memberId) {
    return this.apiService.put(`teams/${teamId}/remind/${memberId}`);
  }
}

export default SurveyReminderAction;
