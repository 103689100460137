// End with a "." and leave off the extension to match all country variations of the domain.
// For example: "yahoo." will match yahoo.com, yahoo.co.uk, yahoo.fr, etc.
export const invalidEmailDomains = [
  '126.com',
  '139.com',
  '163.com',
  '21cn.com',
  'Global-LT.com',
  'akteos.com',
  'aliyun.com',
  'aol.com',
  'betterup.com',
  'bts.com',
  'c3consulting.com',
  'capeinclusion.com',
  'cdc.de',
  'communicaid.com',
  'connectingcultures.dk',
  'cookross.com',
  'countrynavigator.com',
  'crossculture.com',
  'culturalq.com',
  'culturewaves.com',
  'culturewise.com',
  'ddiworld.com',
  'developinggloballeaders.com',
  'eidam-und-partner.de',
  'fios.com',
  'foxmail.com',
  'franklincovey.com',
  'franklincoveyme.com',
  'gapsmoov.com',
  'global-cultures.com',
  'global-integration.com',
  'global-lt.com',
  'globiana.com',
  'gmail.',
  'hofstede-insights.com',
  'honehq.com',
  'hotmail.',
  'icloud.com',
  'intercultures.de',
  'kit.nl',
  'kornferry.com',
  'lcw.com',
  'lcwmail.com',
  'livingabroad.com',
  'livinginstitute.com',
  'mailchimp.com',
  'netease.com',
  'netexpat.com',
  'neuroleadership.com',
  'outlook.',
  'paradigmiq.com',
  'proton.me',
  'qq.com',
  'rw-3.com',
  'sina.com',
  'sohu.com',
  'symmetraglobal.com',
  'thehagueinternationalcentre.nl',
  'themindgym.com',
  'thtconsulting.com',
  'tma.com',
  'tmaworld.com',
  'tom.com',
  'topia.com',
  'trainingcq.com',
  'yahoo.',
  'yeah.net',
  'ymail.com',
  'yourculturemap.com',
  'xiaomi.com',
];
