import { brazeCreateGroupSuccess } from '../../../brazeTracker/customEvents';
import tracker from '../../index';

const {
  eventNames,
  eventProps,
  peopleProps,
  resolveAfterTimeout,
} = tracker;

const trackCreateGroupSuccess = ({
  userId,
  groupName,
  asmtName,
  asmtType,
  memberEmails,
  groupSize,
  buildMethod,
  hasCustomMessage,
}) => {
  tracker.identify(userId);

  tracker.people.increment({
    [peopleProps.NUMBER_OF_ASMT_GROUPS_CREATED]: 1,
  });

  tracker.track(eventNames.CREATE_GROUP, {
    [eventProps.GROUP_ASSESSMENT]: `${asmtName} (${asmtType.toUpperCase()})`,
    [eventProps.GROUP_NAME]: groupName,
    [eventProps.GROUP_SIZE]: groupSize,
    [eventProps.GROUP_MEMBERS]: memberEmails,
    [eventProps.BUILD_METHOD]: buildMethod,
    [eventProps.CUSTOM_MESSAGE]: hasCustomMessage,
  });

  // Track appcues 'Create Assessment Group' event
  window?.Appcues?.track(eventNames.CREATE_GROUP);
  brazeCreateGroupSuccess({
    asmtType,
    groupName,
    groupSize,
    memberEmails,
  });
  return resolveAfterTimeout;
};

const trackJoinAssessmentGroup = ({
  userId,
  groupName,
  asmtName,
  asmtType,
  acceptMethod,
}) => {
  tracker.identify(userId);
  tracker.people.increment({
    [peopleProps.NUMBER_OF_ASMT_GROUPS_AS_MEMBER]: 1,
  });

  tracker.track(eventNames.JOIN_ASSESSMENT_GROUP, {
    [eventProps.GROUP_ASSESSMENT]: `${asmtName} (${asmtType.toUpperCase()})`,
    [eventProps.GROUP_NAME]: groupName,
    [eventProps.ACCEPT_METHOD]: acceptMethod,
  });

  // Track appcues 'Join Assessment Group' event
  window?.Appcues?.track(eventNames.JOIN_ASSESSMENT_GROUP);

  return resolveAfterTimeout;
};

export {
  trackCreateGroupSuccess,
  trackJoinAssessmentGroup,
};
