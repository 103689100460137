/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Context } from '../../../context/ContextProvider';

import CopyText from '../../CopyText';
import Button from '../../common/Button';
import Spinner from '../../Spinner';
import Icon from '../../common/Icon';

const PaymentSuccessMessage = (
  {
    currentStep,
    planName,
    forOthers,
    accessCode,
    submitForm,
    isProcessing,
  }) => {
  if (currentStep !== 4) return null;
  const { handleCloseModal } = useContext(Context);

  useEffect(() => {
    submitForm();
  }, []);

  const buttonOptions = {
    isPurchase: true,
    isNormalCase: true,
    isSmall: true,
  };

  return isProcessing ? (
    <div className="flex flex-col items-center gap-6">
      <Spinner />
      <div className="text-lg font-bold">Your payment is processing</div>
    </div>
  ) : (
    <>
      <div className="flex items-center justify-center w-16 h-16 m-auto border rounded-full shadow-md">
        <Icon icon="check" iconColor="green" className="w-12 h-12" />
      </div>
      <h2 className="my-4">Success!</h2>
      {forOthers
        ? (
          <>
            <span className="text-lg">
              Click the clipboard icon to copy your <br /> Access Code link to share with others.
            </span>
            <CopyText text={`${process.env.SITE_URL}/access-codes/${accessCode}/redeem`} />
            <p className="text-lg font-normal">
              Your code is also accessible from the&nbsp;
              <Button
                isAnchorTag
                to={`${process.env.SITE_URL}/account-settings/summary`}
              >
                Access page
              </Button>
            </p>
          </>
        ) : (
          <p className="text-lg">
            Your purchase is complete. You now have access to {planName}.
          </p>
        )}
      <Button
        className="mx-auto mt-6"
        variant="primary"
        type="button"
        onClick={handleCloseModal}
        {...buttonOptions}
      >
        Close
      </Button>
    </>
  );
};

PaymentSuccessMessage.propTypes = {
  currentStep: PropTypes.number.isRequired,
  forOthers: PropTypes.bool.isRequired,
  planName: PropTypes.string.isRequired,
  accessCode: PropTypes.string,
  submitForm: PropTypes.func.isRequired,
  isProcessing: PropTypes.bool.isRequired,
};

PaymentSuccessMessage.defaultProps = {
  accessCode: '',
};

export default PaymentSuccessMessage;
