import React from 'react';
import PropTypes from 'prop-types';

import Animation from '../../common/Animation';

const ToastCompletionContent = ({ title, text }) => (
  <div className="flex items-center">
    <Animation animation="checkmark" />
    <span className="flex flex-col">
      <span className="ml-4 font-serif text-2xl">{title}</span>
      <span className="ml-4 font-sans text-lg text-gray-700">{text}</span>
    </span>
  </div>
);

ToastCompletionContent.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default ToastCompletionContent;
