import React from 'react';
import { iconPropTypes, defaultIconProps } from './types.js';

import { strokeColorMap, fillColorMap } from '../index.js';

const Minimize = ({ className, iconColor }) => (
  <svg className={`${className} ${strokeColorMap[iconColor]} ${fillColorMap[iconColor]}`} width="800px" height="800px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" d="M11 8a1 1 0 001 1h6a1 1 0 100-2h-3.586l3.793-3.793a1 1 0 00-1.414-1.414L13 5.586V2a1 1 0 10-2 0v6zm-2 4a1 1 0 00-1-1H2a1 1 0 100 2h3.586l-3.793 3.793a1 1 0 101.414 1.414L7 14.414V18a1 1 0 102 0v-6z" />
  </svg>
);

Minimize.propTypes = iconPropTypes;
Minimize.defaultProps = defaultIconProps;

export default Minimize;
