import PropTypes from 'prop-types';

const propTypes = {
  positions: PropTypes.shape({
    id: PropTypes.shape({
      avatar: PropTypes.number,
      traitLine: PropTypes.number,
    }),
  }).isRequired,
};

export {
  propTypes,
};
