import React from 'react';
import PropTypes from 'prop-types';

const fillColor = {
  charcoal: 'fill-charcoal-900',
  ivory: 'fill-ivory-400',
  rust: 'fill-rust-500',
};

const AperianLockup = ({ color }) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 360 407.72"
    xmlSpace="preserve"
  >
    <switch>
      <g>
        <g>
          <g>
            <path
              className={`st0 ${fillColor[color]}`}
              d="M163.3,72c-3.03,0-5.75,1.86-6.86,4.68L97.26,227.35c-6.18,15.32-12.63,22.31-22.31,24.19L72,252.08v8.33 h57.25v-8.33l-3.49-0.54c-13.44-2.15-19.89-6.45-13.98-21.77l9.14-24.19h66.39l9.41,24.46c5.11,13.71,0.27,19.35-15.05,21.5 l-3.76,0.54v8.33h102.72c5.19,0,8.75-5.21,6.87-10.05L219.92,76.7c-1.1-2.83-3.83-4.7-6.87-4.7H163.3z M125.49,193.75 l28.22-74.72l29.03,74.72H125.49z"
            />
          </g>
          <g>
            <path
              className={`st0 ${fillColor[color]}`}
              d="M73.04,304.54c1.93-4.09,6.4-7.52,14.06-7.52c10.2,0,13.47,5.58,13.47,11.98v16.59 c0,4.17,0.22,7.52,0.97,9.3l-5.28,1.27c-0.67-0.97-1.19-2.6-1.49-4.39c-1.49,2.16-4.99,4.76-10.57,4.76 c-7.52,0-12.2-4.46-12.2-11.24c0-7.96,7-11.46,15.11-11.46c3.42,0,6.1,0.45,7.37,0.82v-5.95c0-4.69-1.79-8.26-7.59-8.26 c-5.36,0-7.74,3.57-8.78,5.88C76.39,306.17,73.93,305.43,73.04,304.54z M94.47,329.32v-11.91c-1.04-0.3-3.2-0.67-6.03-0.67 c-5.28,0-10.42,2.23-10.42,8.26c0,5.81,3.8,8.04,8.34,8.04C90.08,333.04,92.69,331.4,94.47,329.32z"
            />
            <path
              className={`st0 ${fillColor[color]}`}
              d="M108.45,349.63v-51.57h5.73v4.09c2.23-2.68,6.03-4.91,11.16-4.91c10.42,0,15.26,8.26,15.26,19.65 c0,11.31-4.91,19.65-15.26,19.65c-4.99,0-8.63-2.01-10.86-4.54v17.64H108.45z M133.98,316.89c0-9.97-2.83-15.92-10.64-15.92 c-3.5,0-6.92,1.56-8.86,3.65v24.63c1.86,2.08,5.21,3.57,8.86,3.57C131.15,332.82,133.98,326.79,133.98,316.89z"
            />
            <path
              className={`st0 ${fillColor[color]}`}
              d="M177.55,316.82h-25.38c0.07,10.79,3.87,16.45,10.94,16.45c5.95,0,7.96-4.69,8.78-7.14 c1.56,0,4.39,0.37,5.43,0.89c-1.86,5.73-6.47,9.6-14.36,9.6c-11.53,0-17.26-8.56-17.26-19.87c0-11.09,5.73-19.57,16.59-19.57 c10.49,0,15.33,7.81,15.33,16.82C177.62,314.73,177.62,315.85,177.55,316.82z M171.45,313.54c0-8.33-2.83-13.1-9.08-13.1 c-5.51,0-9.45,4.09-10.12,13.1H171.45z"
            />
            <path
              className={`st0 ${fillColor[color]}`}
              d="M184.5,335.72v-37.65h5.73v5.95c1.19-2.98,4.76-6.32,9.82-6.32c0.6,0,1.41,0,2.01,0.15 c0.3,1.04,0.22,4.47-0.07,5.58c-0.45-0.07-1.34-0.22-2.46-0.22c-5.66,0-8.33,2.9-9,4.02v28.5H184.5z"
            />
            <path
              className={`st0 ${fillColor[color]}`}
              d="M208.27,287.27c0-1.93,1.49-3.57,3.72-3.57c2.16,0,3.65,1.64,3.65,3.57c0,1.86-1.49,3.5-3.65,3.5 C209.76,290.77,208.27,289.13,208.27,287.27z M208.87,335.72v-37.65h6.03v37.65H208.87z"
            />
            <path
              className={`st0 ${fillColor[color]}`}
              d="M222.89,304.54c1.93-4.09,6.4-7.52,14.06-7.52c10.2,0,13.47,5.58,13.47,11.98v16.59 c0,4.17,0.22,7.52,0.97,9.3l-5.28,1.27c-0.67-0.97-1.19-2.6-1.49-4.39c-1.49,2.16-4.99,4.76-10.57,4.76 c-7.52,0-12.2-4.46-12.2-11.24c0-7.96,7-11.46,15.11-11.46c3.42,0,6.1,0.45,7.37,0.82v-5.95c0-4.69-1.79-8.26-7.59-8.26 c-5.36,0-7.74,3.57-8.78,5.88C226.24,306.17,223.78,305.43,222.89,304.54z M244.32,329.32v-11.91c-1.04-0.3-3.2-0.67-6.03-0.67 c-5.28,0-10.42,2.23-10.42,8.26c0,5.81,3.79,8.04,8.33,8.04C239.93,333.04,242.54,331.4,244.32,329.32z"
            />
            <path
              className={`st0 ${fillColor[color]}`}
              d="M288,311.09v24.63h-6.1v-23.59c0-6.77-1.56-10.79-8.18-10.79c-4.32,0-7.67,2.16-9.3,3.94v30.44h-6.03 v-37.65h5.73v4.76c2.16-2.9,6.32-5.66,12.06-5.66C285.32,297.17,288,303.27,288,311.09z"
            />
          </g>
        </g>
      </g>
    </switch>
  </svg>
);

AperianLockup.propTypes = {
  color: PropTypes.string,
};

AperianLockup.defaultProps = {
  color: 'charcoal',
};

export default AperianLockup;
