import React from 'react';
import PropTypes from 'prop-types';

const AvatarNavigation = ({ id, type, language }) => {
  type = type === 'team' ? 'teams_average' : type; // eslint-disable-line no-param-reassign

  const linkUrl = `/profile/comparison?language=${language}&${type}=${id}#advice`;

  return (
    <nav className="avatar__nav js-avatar-nav-content">
      <ul className="avatar__nav-list">
        <li className="avatar__nav-item">
          <a href={linkUrl} className="avatar__nav-link">
            Advice for Me
          </a>
        </li>
      </ul>
    </nav>
  );
};

AvatarNavigation.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  type: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
};

export default AvatarNavigation;
