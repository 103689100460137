import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router';

import ProfileChartTransformer from '../../../common/ProfileChartTransformer';
import Button from '../../../common/Button';
import { LanguageContext } from '../../../../context/Language';

const Profile = ({ organization, profile }) => {
  const { selectedLanguage } = useContext(LanguageContext);

  return (
    <div className="flex flex-col items-baseline justify-between gap-6 md:flex-row">
      <div className="w-full h-auto max-w-[300px]">
        <Link to="/profile" tabIndex={-1}>
          <ProfileChartTransformer
            isMe
            isDashboard
            displayDimensionLink={false}
            myProfileVisibility="visible"
            hideProfileToggleText="hidden text"
            {...profile}
          />
        </Link>
      </div>
      <div className="flex flex-col justify-between flex-grow">
        <h3 className="mb-8 font-serif text-2xl font-semibold leading-snug tracking-wider text-charcoal-900">
          Congratulations, {profile.shortDisplayName}.
          <br />
          You&apos;ve completed your profile!
        </h3>
        <div className="flex flex-col items-start gap-6">
          <Button
            to="/profile"
            trailingButtonIcon="arrow-right"
            variant="primary"
          >
            My Profile
          </Button>
          <Button
            to="/profile/teams"
            trailingButtonIcon="arrow-right"
            variant="secondary"
          >
            My Teams
          </Button>
          {organization
            && (
              <Button
                to={`/profile/comparison?language=${selectedLanguage}&org=${organization.orgId}`}
                trailingButtonIcon="arrow-right"
                variant="secondary"
              >
                {organization.orgName} Profile
              </Button>
            )}
          <Button
            to="/profile/comparison/new"
            trailingButtonIcon="arrow-right"
            variant="secondary"
          >
            New Comparison
          </Button>
        </div>
      </div>
    </div>
  );
};

Profile.propTypes = {
  organization: PropTypes.shape({
    orgId: PropTypes.string,
    orgName: PropTypes.string,
  }),
  profile: PropTypes.shape({
    avatarUrl: PropTypes.string,
    dimensionLabelGroups: PropTypes.arrayOf(
      PropTypes.shape({
        left: PropTypes.string,
        right: PropTypes.string,
      }),
    ),
    grid: PropTypes.shape({
      direct_indirect: PropTypes.number,
      egalitarianism_status: PropTypes.number,
      independent_interdependent: PropTypes.number,
      risk_certainty: PropTypes.number,
      task_relationship: PropTypes.number,
    }),
    id: PropTypes.string,
    shortDisplayName: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
};

Profile.defaultProps = {
  organization: null,
};

export default Profile;
