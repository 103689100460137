import React from 'react';
import { propTypes, defaultProps } from './types';
import { prefixHostname } from '../../lib/cdn';

const AperianWordmark = props => (
  <img
    className={props.className}
    src={prefixHostname('/images/aperian-wordmark-charcoal.png')}
    srcSet={prefixHostname('/images/aperian-wordmark-charcoal.svg')}
    alt="Aperian&reg;"
  />
);

AperianWordmark.propTypes = propTypes;
AperianWordmark.defaultProps = defaultProps;

export default AperianWordmark;
