class RequestPasswordResetAction {
  constructor(apiService) {
    this.apiService = apiService;
  }

  execute(email) {
    return this.apiService.post('forgot-password', { email })
      .catch(err => ({ error: err }));
  }
}

export default RequestPasswordResetAction;
