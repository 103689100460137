import React, { useContext, useEffect } from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';

import { ModalContext } from '../../context/Modal';

const GlobeSmartModal = ({ children }) => {
  const {
    modalContent,
    showModal,
    handleCloseModal,
    modalClassName,
  } = useContext(ModalContext);

  useEffect(() => {
    ReactModal.setAppElement('body');
  }, [showModal]);

  return (
    showModal ? (
      <ReactModal
        isOpen={showModal}
        onRequestClose={handleCloseModal}
        className={modalClassName}
        overlayClassName="Overlay"
      >
        <button
          type="button"
          className="modal-close"
          onClick={handleCloseModal}
        >
          &times;
        </button>
        {modalContent || children}
      </ReactModal>
    ) : null
  );
};

GlobeSmartModal.propTypes = {
  children: PropTypes.node,
};

GlobeSmartModal.defaultProps = {
  children: null,
};

export default GlobeSmartModal;
