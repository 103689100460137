import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';

import { CurrentUserContext } from '../../context/CurrentUser';

import AperianWordmark from '../AperianWordmark';
import MobileSidebar from '../Layouts/SidebarLayout/MobileSidebar';
import NotificationBell from './NotificationBell';
import AccountMenu from './AccountMenu';
import SidebarToggle from '../Layouts/SidebarLayout/SidebarToggle';
import CalendlyWidget from '../CalendlyWidget';

import PremiumFeature from '../../lib/features/Premium';
import SkillsNotification from './SkillsNotification';

const SiteHeader = ({ location }) => {
  const {
    currentUser,
    currentUser: {
      firstName,
      lastName,
      avatarUrl,
      accessLevel,
      organizationsOwned,
      hasCompletedIBI,
      hasPendingInvitations,
      allowSkillsTracking,
    },
  } = useContext(CurrentUserContext);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [premiumUser, setPremiumUser] = useState(false);

  const handleSidebarOpen = value => setSidebarOpen(value);

  useEffect(() => {
    new PremiumFeature(currentUser)
      .positive(() => setPremiumUser(true))
      .negative(() => setPremiumUser(false))
      .execute();
  }, [currentUser.accessLevel]);

  return (
    <header className="print:hidden">
      {currentUser.isLoggedIn()
        && (
          <>
            <MobileSidebar
              sidebarOpen={sidebarOpen}
              handleSidebarOpen={handleSidebarOpen}
              location={location}
              accessLevel={accessLevel}
              premiumUser={premiumUser}
              hasCompletedIBI={hasCompletedIBI}
            />
            <div className="sticky top-0 z-[350] flex items-center h-16 px-4 bg-white shadow-sm shrink-0 gap-x-4 sm:gap-x-6 sm:px-6 lg:px-8">
              <Link className="hidden lg:block" to="/dashboard">
                <AperianWordmark className="cmp-site-header__aperian" />
              </Link>
              <SidebarToggle handleSidebarOpen={handleSidebarOpen} />
              <div className="w-px h-6 bg-charcoal-900/10 lg:hidden" aria-hidden="true" />
              {currentUser.isLoggedIn()
                && (
                  <div className="flex justify-end flex-1 gap-x-4 lg:gap-x-6">
                    <div className="flex items-center gap-x-4">
                      <CalendlyWidget />
                      {allowSkillsTracking && <SkillsNotification />}
                      <NotificationBell hasPendingInvitations={hasPendingInvitations} />
                      <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-charcoal-900/10" aria-hidden="true" />
                      <AccountMenu
                        firstName={firstName}
                        lastName={lastName}
                        avatarUrl={avatarUrl}
                        accessLevel={accessLevel}
                        organizationsOwned={organizationsOwned}
                      />
                    </div>
                  </div>
                )}
            </div>
          </>
        )}
    </header>
  );
};

SiteHeader.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default SiteHeader;
