import React from 'react';
import PropTypes from 'prop-types';

const bgColors = {
  white: 'bg-white',
  ivory: 'bg-ivory-400',
  slate: 'bg-slate-50',
};

const Form = ({ handleFormSubmit, children, className: additionalClasses, bgColor }) => (
  <form className={`border border-charcoal-900/10 max-w-4xl px-4 py-6 mx-auto my-4 md:px-8 md:py-10 rounded-3xl [&>:not(:last-child)]:mb-8 ${additionalClasses} ${bgColors[bgColor]}`} onSubmit={handleFormSubmit}>
    {children}
  </form>
);

Form.propTypes = {
  handleFormSubmit: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  bgColor: PropTypes.string,
};

Form.defaultProps = {
  className: null,
  bgColor: 'ivory',
};

export default Form;
