import React, {
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react';
import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';

import { APIContext } from '../../../context/API';
import { LanguageContext } from '../../../context/Language';

import withServerSideData from '../../../HOC/withServerSideData';

import PageHeader from '../../PageHeader';
import ActionNavigation from '../../Navigation/ActionNavigation';
import DropdownMenu from '../../common/Dropdowns/DropdownMenu';
import PDFViewer from '../../PDFViewer';
import Notification from '../../Notifications/Notification';

import { base64toBlob } from '../../../lib/downloads';

import validateLanguageForAsmtResults, { languageOptions } from '../../../lib/language';
import { translations } from '../../../lib/constants';
import { trackViewIbiResults, trackDownloadIndividualReport } from '../../../lib/tracker/assessments';

const Results = ({
  params: { assessment },
  initialData: {
    priorityDimension,
    assessmentName,
  },
  error,
}) => {
  const { apiService } = useContext(APIContext);
  const { selectedLanguage, onChangeLanguage } = useContext(LanguageContext);
  const [fileName, setFileName] = useState(null);
  const [pdf, setPDF] = useState(null);
  const [notification] = useState(error.message);
  const [asmtName] = useState(error.assessmentName || assessmentName);
  const titleRef = useRef();

  const retrieveTranslatedReport = async language =>
    apiService.get(`assessments/${assessment}/report?language=${language}`)
      .then(({ pdf: report, fileName: reportName }) => {
        setPDF(URL.createObjectURL(base64toBlob(report)));
        setFileName(reportName);
      });

  const retrieveResults = async () => {
    const language = validateLanguageForAsmtResults(
      translations,
      selectedLanguage,
      assessment,
    );
    await retrieveTranslatedReport(language);
    if (assessment === 'ibi') {
      await trackViewIbiResults();
    }
  };

  useEffect(() => {
    setPDF(null);
    retrieveResults();
  }, [selectedLanguage]);

  const downloadReport = async () => {
    saveAs(pdf, fileName);
    await trackDownloadIndividualReport(assessmentName, assessment);
  };

  const actionNavItems = [
    {
      actionClass: 'download',
      iconClass: 'download',
      onClick: downloadReport,
      label: 'Download Report',
    },
    {
      actionClass: 'retake',
      iconClass: 'retake-survey',
      to: '/assessments/ibi/survey',
      label: 'Retake Survey',
    },
  ];

  return (
    <>
      <PageHeader
        pageTitle={`${asmtName} - Results`}
        icon="ibi-symbol"
        backLink={{ to: `/assessments/${assessment}`, text: `Back to ${asmtName} Home` }}
        skipTarget="#your-ibi-results"
      />
      {notification && <Notification type="warning" message={notification} />}
      <div id="your-ibi-results" ref={titleRef} className="flex flex-wrap justify-center w-11/12 gap-6 px-4 py-8 mx-auto mt-4 mb-6 bg-white rounded-lg shadow-none h-fit md:mt-6 md:mb-8 md:px-6 md:py-10">
        <div className="text-center">
          <h2 className="px-8 mb-4 font-serif text-xl font-semibold tracking-wider lg:text-3xl">
            Your Priority Dimension: <br />
            <span className="text-rust-500">{priorityDimension}</span>
          </h2>
          <p className="mb-0 font-sans text-sm font-normal tracking-wide md:text-base">
            Your Priority Dimension is your lowest scoring dimension.
          </p>
        </div>
        <p className="text-base text-center">
          A copy of your report has been emailed to you.
        </p>
      </div>
      <div className="breakout">
        <ActionNavigation items={actionNavItems} />
        <div className="sticky flex justify-center mb-4 lg:m-0 lg:justify-end z-100 top-20 print:hidden">
          <div className="lg:fixed lg:bottom-auto lg:right-auto">
            <DropdownMenu
              options={(languageOptions('ibi'))}
              value={selectedLanguage || 'en'}
              onChangeValue={onChangeLanguage}
              isTransparent
            />
          </div>
        </div>
      </div>
      <PDFViewer pdf={pdf} />
    </>
  );
};

Results.getAPIDataKey = () => 'asmtResult';
Results.getData = (apiService, { assessment }) =>
  apiService.get(`assessments/${assessment}/results`)
    .then(data => ({ asmtResult: data }));

Results.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  params: PropTypes.shape({
    assessment: PropTypes.string.isRequired,
  }).isRequired,
  initialData: PropTypes.shape({
    priorityDimension: PropTypes.string,
    assessmentName: PropTypes.string,
    pdf: PropTypes.string,
    fileName: PropTypes.string,
  }).isRequired,
  error: PropTypes.shape({
    message: PropTypes.string,
    assessmentName: PropTypes.string,
  }),
};

Results.defaultProps = {
  error: {
    message: null,
    assessmentName: null,
  },
};
export default withServerSideData(Results);
