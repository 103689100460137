/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import { prefixHostname } from '../../../../lib/cdn';
import { AWS_AVATARS_HOST } from '../../../../lib/constants';

import InitialsAvatar from '../InitialsAvatar';
import Icon from '../../Icon';

const getAvatarSize = size => {
  switch (size) {
    case 'xs':
      return 'w-8 h-8';
    case 'sm':
      return 'w-12 h-12';
    case 'md':
      return 'w-16 h-16';
    case 'lg':
      return 'w-20 h-20';
    case 'xl':
      return 'w-28 h-28';
    default:
      return 'w-16 h-16';
  }
};

const handleUserAvatar = ({ avatarUrl, avatarImgAlt, className }) => {
  if (!avatarUrl) {
    return (<Icon icon="user-icon-avatar" className={className} />);
  }
  // For users that have initials as their avatar
  if (avatarUrl.startsWith('initials-')) {
    const initials = avatarUrl.split('-').pop();
    return (<InitialsAvatar initials={initials} />);
  }
  // For users with a profile photo
  return (
    <img
      className="rounded-full"
      src={`${AWS_AVATARS_HOST}${avatarUrl}`}
      alt={`Photo of ${avatarImgAlt}`}
    />
  );
};

const DetermineAvatar = props => {
  if (props.isCultureFlag) {
    return (
      <img
        className="rounded-md"
        src={prefixHostname(props.avatarUrl)}
        alt={props.avatarImgAlt}
      />
    );
  }

  if (props.isIcon) {
    const icon = props.avatarUrl.split('-').pop();
    return (<Icon icon={icon} className="rounded-full" />);
  }

  return handleUserAvatar(props);
};

const Avatar = props => {
  const animations = 'transition-transform hover:-translate-y-0.5 cursor-pointer hover:shadow-lg focus:border-4 focus:border-rust-100';
  const responsive = !props.isNavigation ? 'w-full h-auto' : '';
  const isIcon = props.avatarUrl.startsWith('icon-');
  const isCultureFlag = props.isCulture;
  const avatarClasses = classNames({
    'self-center': true,
    'overflow-hidden': true,
    'max-w-[3rem] max-h-[3rem] md:max-w-[4rem] md:max-h-[4rem]': !props.isDashboard,
    'max-w-[3rem] max-h-[3rem]': isCultureFlag && props.isDashboard,
    'min-w-[2.85rem] min-h-[2.85rem]': isCultureFlag && !props.isDashboard,
    relative: true,
    'bg-rust-50': isIcon || !props.avatarUrl,
    'rounded-full': !isCultureFlag,
    'rounded-md': isCultureFlag,
    'opacity-95': props.isHistoryProfile,
    [getAvatarSize(props.size)]: props.size,
    [responsive]: !props.size,
    [animations]: props.animate,
    [props.className]: props.className,
  });

  return (
    <div className={twMerge(avatarClasses, props.className)} tabIndex={-1}>
      <DetermineAvatar
        {...props}
        className={getAvatarSize(props.size)}
        isIcon={isIcon}
        isCultureFlag={isCultureFlag}
      />
      {props.icon
        ? (
          <div className="absolute p-0.5 bottom-0 right-0 w-1/4 bg-white border-2 border-white rounded-full h-1/4">
            <Icon icon={props.icon} iconColor="gray" />
          </div>
        )
        : null}
      {props.status
        ? <div className={`absolute bottom-0 right-0 w-1/4 border-2 border-white rounded-full h-1/4 bg-${props.status}-500`} />
        : null}
    </div>
  );
};

Avatar.propTypes = {
  className: PropTypes.string,
  avatarUrl: PropTypes.string,
  avatarImgAlt: PropTypes.string,
  isNavigation: PropTypes.bool,
  isCulture: PropTypes.bool,
  isDashboard: PropTypes.bool,
  isHistoryProfile: PropTypes.bool,
  animate: PropTypes.bool,
  status: PropTypes.string,
  icon: PropTypes.string,
  size: PropTypes.string,
};

Avatar.defaultProps = {
  className: '',
  avatarUrl: '',
  avatarImgAlt: '',
  isNavigation: false,
  isCulture: false,
  isDashboard: false,
  isHistoryProfile: false,
  animate: false,
  status: null,
  icon: null,
  size: null,
};

DetermineAvatar.propTypes = {
  avatarUrl: PropTypes.string,
  avatarImgAlt: PropTypes.string,
  isCultureFlag: PropTypes.bool,
  isIcon: PropTypes.bool,
};

DetermineAvatar.defaultProps = {
  avatarUrl: '',
  avatarImgAlt: '',
  isCultureFlag: false,
  isIcon: false,
};

export default Avatar;
