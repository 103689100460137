import React from 'react';

import PropTypes from 'prop-types';

const CircleHalfCirclesIcon = ({ className1, className2, className3 }) => (
  <svg className="max-w-[60%] max-h-full" width="150" height="70" viewBox="0 0 150 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M35.0001 70C54.3301 70 70.0001 54.33 70.0001 35C70.0001 15.67 54.3301 0 35.0001 0C15.6701 0 0 15.67 0 35C0 54.33 15.6701 70 35.0001 70Z" className={className1} />
    <path d="M74.667 34.9958C74.667 54.0162 89.8267 69.492 108.714 69.9995C109.238 70.0162 109.679 69.5752 109.679 69.051L109.679 0.948983C109.679 0.416481 109.246 -0.0161769 108.714 0.000463841C89.8267 0.508006 74.667 15.9838 74.667 34.9958Z" className={className2} />
    <path d="M114.346 34.9958C114.346 54.0162 129.505 69.492 148.393 69.9995C148.917 70.0162 149.358 69.5752 149.358 69.051L149.358 0.948983C149.358 0.416481 148.925 -0.0161769 148.393 0.000463841C129.505 0.508006 114.346 15.9838 114.346 34.9958Z" className={className3} />
  </svg>
);

CircleHalfCirclesIcon.propTypes = {
  className1: PropTypes.string,
  className2: PropTypes.string,
  className3: PropTypes.string,
};

CircleHalfCirclesIcon.defaultProps = {
  className1: '',
  className2: '',
  className3: '',
};

export default CircleHalfCirclesIcon;
