import {
  AfterTrackSelectExplanation,
  AfterTrackSelectMessage,
  AfterSurveyMessage,
  BeforeSurveyMessage,
  EmailVerification,
  Greeting,
  GSPChart,
  InviteOthers,
  Landing,
  MiniComparison,
  MiniSurvey,
  NameInput,
  OnboardingSingleForm,
  PasswordInput,
  SelectUseCase,
  Snapshot,
  TrackSelection,
} from '../../components/custom/Onboarding';

export const trialCreator = {
  1: OnboardingSingleForm,
};

export const fullFlowTrialCreator = {
  1: Landing,
  2: SelectUseCase,
  3: NameInput,
  4: Greeting,
  5: TrackSelection,
  6: AfterTrackSelectMessage,
  7: AfterTrackSelectExplanation,
  8: BeforeSurveyMessage,
  9: MiniSurvey,
  10: AfterSurveyMessage,
  11: Snapshot,
  12: MiniComparison,
  13: GSPChart,
  14: InviteOthers,
  15: EmailVerification,
};

export const trialInvitee = {
  1: Landing,
  2: NameInput,
  3: Greeting,
  4: PasswordInput,
};
