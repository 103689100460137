import React from 'react';
import BodyWrapper from '../BodyWrapper';
import AperianWordmark from '../AperianWordmark';
import RustDividingLine from '../common/RustDividingLine';

const UpgradeToProfessional = () => (
  <BodyWrapper documentTitle="Upgrade | Aperian">
    <div className="flex items-center justify-center mb-6">
      <AperianWordmark />
    </div>
    <div>
      <h1 className="mb-2 text-2xl font-normal font-headline">Trying to connect with colleagues?</h1>
      <RustDividingLine />
      <p className="mt-4 mb-2 font-sans text-xs"><strong>Unlock teams features by <a href="https://aperian.zendesk.com/hc/en-us/articles/18925799320851" target="_blank" rel="noopener noreferrer">upgrading your account</a></strong>.</p>
    </div>

  </BodyWrapper>
);

export default UpgradeToProfessional;
