import { useContext } from 'react';

import { propTypes } from './types';

import { CurrentUserContext } from '../../context/CurrentUser';
import { RouterContext } from '../../context/Router';

import TeamsFeature from '../../lib/features/Teams';
import NeedsCompletedSurvey from '../../lib/NeedsCompletedSurvey';
import getLoginUrl from '../../lib/urls/loginUrl';

import { GS_PREMIUM_STRIPE_PLAN } from '../../lib/constants';

const TeamsAuthorization = ({ children, location }) => {
  const { currentUser } = useContext(CurrentUserContext);
  const { router } = useContext(RouterContext);
  const { pathname } = location;

  NeedsCompletedSurvey.apply(router, currentUser);
  return new TeamsFeature(currentUser)
    .positive(() => children)
    .negative(() => {
      if (currentUser.isLoggedIn()) {
        const purchaseUrl = `/dashboard?purchase&plan=${GS_PREMIUM_STRIPE_PLAN}&redirect_to=${pathname}`;
        router.replace(purchaseUrl);
      } else {
        const loginUrl = getLoginUrl(location);
        router.replace(loginUrl);
      }
      return null;
    })
    .execute();
};

TeamsAuthorization.propTypes = propTypes;

export default TeamsAuthorization;
