import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../../../../common/Button';

const MemberActions = (
  {
    id,
    isOwner,
    surveyStatus,
    handleOnRemind,
    wasReminded,
    handleConfirmRemove,
    onLeaveEnabled,
  }) => {
  const renderActionButtons = () => {
    if (isOwner) {
      return (
        <>
          {surveyStatus !== 'Finished'
            ? (
              <Button
                className="card__button"
                variant="primary"
                isNormalCase
                dataID={id}
                onClick={handleOnRemind}
                disabled={wasReminded}
              >
                {wasReminded ? 'Reminder Sent' : 'Remind'}
              </Button>
            )
            : null}
          <Button
            className="card__button"
            isWarning
            isNormalCase
            onClick={() => handleConfirmRemove(true)}
          >
            Remove
          </Button>
        </>
      );
    }
    if (onLeaveEnabled) {
      return (
        <Button
          className="card__button"
          variant="tertiary"
          isNormalCase
          onClick={() => handleConfirmRemove(true)}
        >
          Leave
        </Button>
      );
    }
    return null;
  };
  return (
    <div className="card__actions">
      {renderActionButtons()}
    </div>
  );
};

MemberActions.propTypes = {
  id: PropTypes.string.isRequired,
  isOwner: PropTypes.bool.isRequired,
  surveyStatus: PropTypes.string.isRequired,
  handleOnRemind: PropTypes.func.isRequired,
  wasReminded: PropTypes.bool.isRequired,
  handleConfirmRemove: PropTypes.func.isRequired,
  onLeaveEnabled: PropTypes.bool.isRequired,
};

export default MemberActions;
