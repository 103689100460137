import React from 'react';
import PropTypes from 'prop-types';

import Notification from '../../../Notifications/Notification';

import InvitationForm from '../../../Invitations/InvitationForm';

const GroupAddOwnerModal = ({
  notification,
  handleCloseModal,
  handleFormSubmit,
  groupName,
  groupOrTeam,
  groupOrTeamUpperCase,
}) => {
  const legalNotice = `All ${groupOrTeam} owners have the same privileges to edit and administer ${groupOrTeam}s.`;

  return (
    <>
      <h2 className="m-0 font-heading-2">{groupName}</h2>
      <div className="mt-2 text-max-width">
        <p className="text-base">{legalNotice}</p>
      </div>
      {notification && <Notification type={notification.type} message={notification.message} />}
      <InvitationForm
        sendMessage="Add Owner"
        invitationType={`${groupOrTeamUpperCase} Owners`}
        handleFormSubmit={handleFormSubmit}
        handleCloseModal={handleCloseModal}
        hasCancel
      />
    </>
  );
};

GroupAddOwnerModal.propTypes = {
  notification: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string,
  }),
  handleCloseModal: PropTypes.func.isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
  groupName: PropTypes.string.isRequired,
  groupOrTeam: PropTypes.string.isRequired,
  groupOrTeamUpperCase: PropTypes.string.isRequired,
};

GroupAddOwnerModal.defaultProps = {
  notification: {},
};

export default GroupAddOwnerModal;
