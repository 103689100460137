class PaymentAction {
  constructor(apiService) {
    this.apiService = apiService;
  }

  execute({
    planName,
    planId,
    quantity,
    forOthers,
    token,
    coupon,
    metadata,
    shipping,
  }) {
    if (forOthers) {
      return this.apiService.post('payment/charge', {
        planName,
        planId,
        quantity,
        token,
        coupon,
        metadata,
        shipping,
      });
    }
    return this.apiService.post('payment/subscription', {
      planId,
      token,
      coupon,
      metadata,
      shipping,
    });
  }
}

export default PaymentAction;
