import React from 'react';
import { iconPropTypes, defaultIconProps } from './types.js';

import { fillColorMap } from '../index.js';

const CopilotSymbol = ({ className, iconColor }) => (
  <svg className={`${className} ${fillColorMap[iconColor]}`} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 113 113">
    <path className="cls-1" d="M56.22,63.55h-.26c-14.98,0-27.13-12.14-27.13-27.13V9.79c0-.52.42-.94.94-.94h26.38c15.12,0,27.38,12.26,27.38,27.38h0c0,15.09-12.23,27.33-27.33,27.33Z" />
    <path className="cls-1" d="M55.71,106.27c19.7,0,35.76-15.61,36.47-35.14.03-.74-.57-1.36-1.32-1.36H20.55c-.75,0-1.34.62-1.32,1.36.72,19.53,16.77,35.14,36.48,35.14Z" />
  </svg>
);

CopilotSymbol.propTypes = iconPropTypes;
CopilotSymbol.defaultProps = defaultIconProps;

export default CopilotSymbol;
