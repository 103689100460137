import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { differenceInMinutes, minutesToMilliseconds } from 'date-fns';
import { Link } from 'react-router';
import { APIContext } from '../../../context/API';
import { CurrentUserContext } from '../../../context/CurrentUser';

import Icon from '../../common/Icon';
import Badges from '../../common/Badges';

import { setAttendedAction } from '../../../actions/aperianLive';

const CustomLink = ({
  to,
  cohortCode,
  className,
  children,
}) => {
  const { apiService } = useContext(APIContext);
  const isExternal = to?.startsWith('http') || to?.startsWith('https');

  const targetUrl = to || '/aperian-live'; // default to AL home if session link is null for some reason

  const handleClick = async () => {
    await setAttendedAction(apiService, cohortCode, { joinMethod: 'events feed' });
  };

  if (isExternal) {
    return (
      <a href={targetUrl} key={cohortCode} className={className} target="_blank" rel="noopener noreferrer" onClick={handleClick}>{children}</a>
    );
  }

  return (
    <Link to={targetUrl} key={cohortCode} className={className}>{children}</Link>
  );
};

CustomLink.propTypes = {
  to: PropTypes.string.isRequired,
  cohortCode: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const DashboardEvents = ({ sessions }) => {
  const [formattedSessions, setFormattedSessions] = useState(sessions);

  const { currentUser } = useContext(CurrentUserContext);

  const isNonAperianLiveUser = !currentUser.accessLevel.some(string => string.includes('aperianlive'));

  useEffect(() => {
    const findIsJoinEnabled = dateTime => {
      const now = new Date();
      const startTime = new Date(dateTime);
      const diffToStartTime = differenceInMinutes(startTime, now);
      const meetingStartsInLessThanTen = diffToStartTime <= 10;
      const meetingStartsInMoreThanTen = diffToStartTime > 10;
      const meetingStartsInLessThanThirty = diffToStartTime <= 30;

      if (meetingStartsInLessThanTen) {
        return true;
      }

      if (meetingStartsInMoreThanTen && meetingStartsInLessThanThirty) {
        setTimeout(() => {
          setFormattedSessions(sessions
            .map(session => ({
              ...session,
              isJoinEnabled: findIsJoinEnabled(session.dateTime),
            })));
        },
        minutesToMilliseconds(diffToStartTime - 10));
      }
      return false;
    };

    setFormattedSessions(sessions
      .map(session => ({
        ...session,
        isJoinEnabled: findIsJoinEnabled(session.dateTime),
      })));
  }, [sessions]);

  const determineLink = cohortCode => {
    if (isNonAperianLiveUser) {
      return '/aperian-live/program/1';
    }
    return `/aperian-live?selected-session=${cohortCode}`;
  };

  return (
    <div className="flex flex-col items-center justify-end col-start-1 xl:flex-col h-fit md:col-span-12 lg:sticky xl:block lg:top-24 xl:mt-4 xl:h-0 xl:col-auto">
      <div className="w-full h-auto p-5 bg-white xl:max-w-md 3xl:max-w-lg sm:p-6 rounded-xl">
        <div className="flex items-center justify-start gap-2 mb-1.5">
          <Icon icon="aperian-live" iconColor="gray" className="w-10 h-10" />
          <h3 className="m-0 font-serif text-xl font-semibold leading-snug tracking-wider md:text-2xl text-charcoal-900" id="slide-over-title">
            Upcoming Aperian Live
          </h3>
        </div>
        {isNonAperianLiveUser && (<h5 className="mt-4 text-lg">{sessions[0].name}</h5>)}
        {formattedSessions.map(session => (
          <CustomLink
            to={session.isJoinEnabled && session.userJoinUrl
              ? session.userJoinUrl : determineLink(session.cohortCode)}
            key={session.cohortCode}
            cohortCode={session.cohortCode}
            className="no-underline"
          >
            <div key={session.cohortCode} className="flex flex-col px-3 py-2 text-xs bg-white border-b cursor-pointer rounded-t-md hover:bg-stone-50 border-rust-500 md:flex-row lg:flex-col md:justify-between">
              <div className="flex items-end py-2 font-sans text-sm font-medium leading-4 tracking-wide text-gray-800 md:text-base 2xl:text-md">
                {isNonAperianLiveUser ? `Facilitated By ${session.facilitator}` : `${session.name}`}
              </div>
              <div className="flex flex-col justify-between md:flex-row">
                <div className="flex items-center mb-2 md:items-end lg:items-center md:mb-0">
                  <Icon icon="calendar" className="w-4 h-4 mr-1 md:w-5 md:h-5" iconColor="dark-gray" />
                  <span className="font-light tracking-wider text-gray-800 2xl:text-md">{session.date} {session.time}</span>
                </div>
                {session.isRegistered && (<Badges color={session.isJoinEnabled ? 'green' : 'blue'} badges={session.isJoinEnabled ? ['Join'] : ['Registered']} size="small" />)}
              </div>
            </div>
          </CustomLink>
        ))}
        <div className="mt-4 mb-2 text-base text-end">
          <Link to={isNonAperianLiveUser ? '/aperian-live/program/1' : '/aperian-live'}>More Events</Link>
        </div>
      </div>
    </div>
  );
};
export default DashboardEvents;

DashboardEvents.propTypes = {
  sessions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      facilitator: PropTypes.string,
      duration: PropTypes.number,
      description: PropTypes.string,
      prework: PropTypes.arrayOf(PropTypes.string),
      dateTime: PropTypes.string,
      cohortCode: PropTypes.string,
      tags: PropTypes.arrayOf(PropTypes.string),
      isRegistered: PropTypes.bool,
      userJoinUrl: PropTypes.string,
    })).isRequired,
};
