import React from 'react';
import Parser from 'html-react-parser';
import DOMPurify from 'isomorphic-dompurify';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import Button from '../../../common/Button';
import deepAdviceLinkForTopic from '../../../../lib/deepAdviceLinkForTopic';

const TopAdviceItem = props => {
  const {
    explanation,
    linkText,
    onClick,
    topic,
    topicID,
    title,
    isAuthorized,
    searchParams,
  } = props;

  return (
    <>
      <h3>
        <Link
          data-topic={topic}
          to={deepAdviceLinkForTopic(topicID, isAuthorized, searchParams)}
          className="font-medium cursor-pointer hover:underline"
          onClick={onClick}
        >
          {topic}
        </Link>
        {isAuthorized ? null : <span className="payment__icon-upgrade-lock">&nbsp;</span>}
      </h3>
      <h4>
        {/* eslint-disable-next-line new-cap */}
        {Parser(DOMPurify.sanitize(title, { ADD_ATTR: ['target'] }))}
      </h4>
      <hr className="w-24 h-0.5 my-6 border-0 bg-rust-500" />
      {/* eslint-disable-next-line new-cap */}
      {Parser(DOMPurify.sanitize(explanation, { ADD_ATTR: ['target'] }))}
      <div className="flex mt-6">
        <Button
          to={deepAdviceLinkForTopic(topicID, isAuthorized, searchParams)}
          onClick={onClick}
          variant="primary"
          trailingButtonIcon="chevron-right"
          dataTopic={topic}
        >
          {linkText}
        </Button>
      </div>
      {isAuthorized ? null : <span className="payment__icon-upgrade-lock">&nbsp;</span>}
    </>
  );
};

TopAdviceItem.propTypes = {
  topic: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  explanation: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  topicID: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  isAuthorized: PropTypes.bool.isRequired,
  searchParams: PropTypes.string.isRequired,
};

TopAdviceItem.defaultProps = {
  onClick: () => { },
};

export default TopAdviceItem;
