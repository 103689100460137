class PremiumUserCriteria {
  constructor(user) {
    this.user = user || { accesslevel: [] };
  }

  test() {
    // when rendering server-side, we don't have accessLevel yet
    return this.user?.accessLevel?.some(access => access.startsWith('premium'));
  }
}

export default PremiumUserCriteria;
