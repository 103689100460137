import React from 'react';
import { iconPropTypes, defaultIconProps } from './types';

import { fillColorMap } from '../index.js';

const AperianLiveIcon = ({ className, iconColor }) => (
  <svg className={`${className} ${fillColorMap[iconColor]}`} viewBox="0 0 104 61" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.3784 37.9674C29.9153 37.9674 38.4569 29.5077 38.4569 19.0722C38.4569 8.63681 29.9153 0.177124 19.3784 0.177124C8.84159 0.177124 0.299805 8.63681 0.299805 19.0722C0.299805 29.5077 8.84159 37.9674 19.3784 37.9674Z" />
    <path d="M19.3764 41.4992C29.7443 41.4992 38.1804 49.4511 38.4569 59.3583C38.4661 59.6333 38.2257 59.8645 37.94 59.8645H0.817332C0.527063 59.8645 0.291221 59.6377 0.30029 59.3583C0.576954 49.4511 9.01287 41.4992 19.3764 41.4992Z" />
    <path d="M101.874 0.177124H43.9258C43.2854 0.177124 42.7662 0.697408 42.7662 1.33913V59.4089C42.7662 60.0508 43.2854 60.5709 43.9258 60.5709H101.874C102.514 60.5709 103.033 60.0508 103.033 59.4089V1.33913C103.033 0.697408 102.514 0.177124 101.874 0.177124Z" />
  </svg>

);

AperianLiveIcon.propTypes = iconPropTypes;
AperianLiveIcon.defaultProps = defaultIconProps;

export default AperianLiveIcon;
