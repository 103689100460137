import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../common/Button';

const DashboardHeading = ({
  headingText,
  link,
  subHeader,
  externalLink,
}) => (
  <div id="dashboard-content" className="flex items-center justify-between">
    <div className="w-full">
      <div className="flex w-full">
        <h3 className="mb-1 font-display text-bold text-charcoal-900">{headingText}</h3>
        {link && <Button to={link.url} className="btn btn--small">{link.text}</Button>}
      </div>
      {subHeader && (
      <p className="font-sans text-sm">{subHeader}
        {externalLink && (
        <a href={externalLink.url} rel="noopener noreferrer" target="_blank">
          {externalLink.text}
        </a>
        )}
      </p>
      )}
    </div>
  </div>
);

DashboardHeading.propTypes = {
  headingText: PropTypes.string.isRequired,
  subHeader: PropTypes.string,
  link: PropTypes.shape({
    url: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
  externalLink: PropTypes.shape({
    url: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
};

DashboardHeading.defaultProps = {
  subHeader: '',
  link: null,
  externalLink: null,
};

export default DashboardHeading;
