import React, { useContext, useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import withServerSideData from '../../HOC/withServerSideData';

import { APIContext } from '../../context/API';
import { Context } from '../../context/ContextProvider';
import { NotificationContext } from '../../context/Notification';
import { CurrentUserContext } from '../../context/CurrentUser';
import { RouterContext } from '../../context/Router';

import { useSlideOver } from '../../hooks/useSlideOver';

import PageHeader from '../../components/PageHeader';
import RegisteredSessionContainer from '../../components/AperianLive/RegisteredSessionContainer';
import SessionDetailsContainer from '../../components/AperianLive/SessionDetailsContainer';
import DropdownCustomSelect from '../../components/common/Dropdowns/DropdownCustomSelect';
import SlideOver from '../../components/SlideOver';
import CohortEnrollmentSuccessMessage from '../../components/Cohorts/CohortEnrollmentSuccessMessage';
import Notification from '../../components/Notifications/Notification';

import { filterSessions, formatSessionDates, getSessionTopics } from '../../lib/aperianLive';
import { cohortEnrollAction } from '../../actions/cohorts';
import { setAttendedAction } from '../../actions/aperianLive';
import { trackShareAperianLiveSession, trackViewAperianLiveHome, trackViewDetailsAperianLive } from '../../lib/tracker/aperian-live';
import logger from '../../lib/logger';
import HomePageTable from '../../components/AperianLive/HomePageTable';
import SHRMLogo from '../../components/AperianLive/SHRMLogo';

const AperianLiveHome = ({ initialData: { sessions } }) => {
  const { currentUser } = useContext(CurrentUserContext);
  const { apiService } = useContext(APIContext);
  const { handleOpenDialog } = useContext(Context);
  const {
    addNotification,
    notificationMessage,
    removeNotification,
  } = useContext(NotificationContext);
  const { router } = useContext(RouterContext);

  const {
    userid,
    accessLevel,
    organizations,
    email,
  } = currentUser;

  let formattedSessions = formatSessionDates(sessions);
  let topics = getSessionTopics(formattedSessions);
  let initialTopic = topics?.at(topics.length - 1);

  const [selectedTopic, setSelectedTopic] = useState(initialTopic);
  const [initialSessions, setInitialSessions] = useState(formattedSessions);
  const [registeredSessions, setRegisteredSessions] = useState([]);
  const [displayedSessions, setDisplayedSessions] = useState(formattedSessions);

  const [sessionDetails, setSessionDetails] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      name: '',
      nameInternal: '',
      date: '',
      duration: 0,
      time: '',
      dateTime: '',
      facilitator: '',
      description: '',
      prework: [],
      tags: [],
      isRegistered: false,
      userJoinUrl: '',
      cohortCode: '',
      join: () => {},
      register: () => {},
    });

  const { isSlideOverOpen, openSlideOver, closeSlideOver } = useSlideOver();

  const handleTopicChange = newTopic => {
    setSelectedTopic(newTopic);
  };

  useEffect(() => {
    setRegisteredSessions(initialSessions.filter(session => session.isRegistered));
  }, [initialSessions]);

  const join = async (
    code,
    userJoinUrl,
    joinMethod,
  ) => {
    window.open(userJoinUrl);
    await setAttendedAction(apiService, code, { joinMethod });
  };

  const register = async (
    code,
    registrationMethod,
  ) => {
    try {
      const {
        displayName,
        redirectUrl,
        isAlreadyEnrolled,
        meetingDateTime,
      } = await cohortEnrollAction(apiService, code, { registrationMethod });

      const dialogOptions = {
        content: (
          <CohortEnrollmentSuccessMessage
            isAlreadyEnrolled={isAlreadyEnrolled}
            displayName={displayName}
            redirectUrl={redirectUrl}
            meetingDateTime={meetingDateTime}
          />),
      };

      handleOpenDialog(dialogOptions);

      const { sessions: updatedSessions } = await apiService.get('cohort-events/dashboard');

      formattedSessions = formatSessionDates(updatedSessions);
      topics = getSessionTopics(formattedSessions);
      initialTopic = topics?.at(-1);
      setInitialSessions(formattedSessions);
      setDisplayedSessions(formattedSessions);
      setSessionDetails({ isRegistered: true });
    } catch (error) {
      closeSlideOver();
      window.scroll(0, 0);
      addNotification({ type: 'failure', message: error.details.message });
    }
  };

  const showDetailsContainer = async (cohortCode, detailsMethod) => {
    const viewableSession = displayedSessions.filter(session =>
      session.cohortCode === cohortCode)[0];
    const {
      date,
      dateTime,
      description,
      duration,
      facilitator,
      facilitatorAvatarUrl,
      isRegistered,
      name,
      nameInternal,
      prework,
      time,
      tags,
      userJoinUrl,
    } = viewableSession;
    try {
      await trackViewDetailsAperianLive(
        organizations,
        name,
        nameInternal,
        cohortCode,
        dateTime,
        duration,
        facilitator,
        detailsMethod,
      );
      setSessionDetails({
        name,
        nameInternal,
        date,
        duration,
        time,
        dateTime,
        facilitator,
        facilitatorAvatarUrl,
        description,
        prework,
        tags,
        isRegistered,
        userJoinUrl,
        cohortCode,
        register,
        join,
      });
      openSlideOver();
    } catch (err) {
      logger.error(err);
    }
  };

  useEffect(() => {
    if (!accessLevel.some(string => string.includes('aperianlive'))) {
      router.push('/aperian-live/program/1');
    } else {
      trackViewAperianLiveHome(organizations, email);
      const currentSearchParams = new URLSearchParams(window.location.search);
      if (currentSearchParams.size > 0) {
        const sessionCohortCode = currentSearchParams.get('selected-session');
        showDetailsContainer(sessionCohortCode, 'events feed');
      }
    }
  }, []);

  const handleOpenSlider = async (cohortCode, detailsMethod) => {
    const currentSearchParams = new URLSearchParams(window.location.search);
    currentSearchParams.set('selected-session', cohortCode);
    const urlWithSessionParams = `${window.location.pathname}?${currentSearchParams.toString()}`;
    window.history.pushState({ path: urlWithSessionParams }, '', urlWithSessionParams);
    await showDetailsContainer(cohortCode, detailsMethod);
  };

  const handleCloseSlideOver = cohortCode => {
    const currentSearchParams = new URLSearchParams(window.location.search);
    if (currentSearchParams.size > 0) {
      currentSearchParams.delete('selected-session', cohortCode);
      const urlWithoutSessionParams = `${window.location.pathname}`;
      window.history.pushState({ path: urlWithoutSessionParams }, '', urlWithoutSessionParams);
    }
    closeSlideOver();
  };

  useEffect(() => {
    if (selectedTopic.value === sessions.length) {
      setDisplayedSessions(initialSessions);
    } else {
      const filteredSessions = filterSessions(initialSessions, selectedTopic.name);
      const formattedFilterSessions = formatSessionDates(filteredSessions);
      setDisplayedSessions(formattedFilterSessions);
    }
  }, [selectedTopic]);

  useEffect(() => {
    let timeout;
    if (notificationMessage) {
      timeout = window.setTimeout(() => {
        removeNotification();
      }, 5000);
    }
    return () => {
      window.clearTimeout(timeout);
      if (notificationMessage) removeNotification();
    };
  }, [notificationMessage]);

  const createShareableLink = async (
    cohortCode,
    name,
    shareMethod,
  ) => {
    const shareableLink = `${process.env.SITE_URL}/aperian-live/?selected-session=${cohortCode}`;
    try {
      await trackShareAperianLiveSession(
        apiService,
        cohortCode,
        name,
        shareMethod,
      );
      await navigator.clipboard.writeText(shareableLink);
    } catch (err) {
      logger.error('Error creating shareable link for this session', err);
    }
  };

  return (
    accessLevel.some(string => string.includes('aperianlive')) && (
    <div className="h-full min-h-full full-width">
      <PageHeader
        pageTitle="Aperian Live"
        icon="aperian-live"
        skipTarget="#aperian-live"
      />
      <Notification {...notificationMessage} />
      <div className="mb-6 -mt-4 md:mb-10 lg:mb-14">
        <p className="w-full mb-0 text-sm lg:w-3/4">
          Discover the power of Aperian Live workshops, where you can engage in intimate,
          small-group learning experiences designed to foster effective collaboration across teams,
          departments, and cultures. With sessions available multiple times and not recorded,
          we ensure an interactive and dynamic experience for participants from all time zones.
        </p>
      </div>
      <div>
        <h3 className="mb-6">My Registered Sessions</h3>
        <div className="mb-8 md:mb-12 lg:mb-16">
          {registeredSessions.length !== 0 ? registeredSessions.map(session => (
            <div className="mb-4" key={session.cohortCode}>
              <RegisteredSessionContainer
                name={session.name}
                nameInternal={session.nameInternal}
                date={session.date}
                time={session.time}
                dateTime={session.dateTime}
                facilitator={session.facilitator}
                facilitatorAvatarUrl={session.facilitatorAvatarUrl}
                duration={session.duration}
                tags={session.tags}
                userJoinUrl={session.userJoinUrl}
                cohortCode={session.cohortCode}
                handleShowDetails={() => handleOpenSlider(session.cohortCode, 'my sessions')}
                join={join}
                organizations={organizations}
                userId={userid}
                createShareableLink={createShareableLink}
              />
            </div>
          )) : (<p className="py-4 text-center">You are not registered for any sessions.</p>)}
        </div>
        <h3 className="mb-4">Happening Soon</h3>
        <p className="w-full text-sm lg:w-3/4">
          Click on the sessions to learn more and register.
          Sessions are displayed in your local time.
          Sign up for multiple sessions to gain different perspectives and advice.
        </p>
        <div className="box-border flex flex-col gap-10 mx-auto my-5 font-normal lg:mb-10">
          <SlideOver isOpen={isSlideOverOpen} closeSlideOver={handleCloseSlideOver}>
            <SessionDetailsContainer
              name={sessionDetails.name}
              nameInternal={sessionDetails.nameInternal}
              date={sessionDetails.date}
              time={sessionDetails.time}
              dateTime={sessionDetails.dateTime}
              facilitator={sessionDetails.facilitator}
              facilitatorAvatarUrl={sessionDetails.facilitatorAvatarUrl}
              description={sessionDetails.description}
              duration={sessionDetails.duration}
              prework={sessionDetails.prework}
              tags={sessionDetails.tags}
              isRegistered={sessionDetails.isRegistered}
              userJoinUrl={sessionDetails.userJoinUrl}
              cohortCode={sessionDetails.cohortCode}
              register={register}
              join={join}
              organizations={organizations}
              userId={userid}
              createShareableLink={createShareableLink}
            />
          </SlideOver>
          <div className="flex justify-center mb-8 lg:max-h-[450px]">
            <div className="w-full -mx-4 -my-2 overflow-x-hidden md:overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle bg-white border rounded-md sm:px-6 lg:px-8">
                <div className="flex flex-col items-start gap-4 py-2">
                  <div className="w-full md:w-1/2">
                    <div className="px-2 md:px-0">
                      <DropdownCustomSelect
                        labelText="Find By Topic"
                        placeHolder={selectedTopic.name}
                        prevSelected={selectedTopic}
                        options={topics}
                        onChangeValue={handleTopicChange}
                      />
                    </div>
                  </div>
                  <HomePageTable
                    displayedSessions={displayedSessions}
                    register={register}
                    handleOpenSlider={handleOpenSlider}
                  />
                </div>
              </div>
            </div>
            <h3 className="hidden mb-2">Past Registered Sessions</h3>
          </div>
        </div>
        <div className="flex flex-col items-center gap-2 mx-auto mb-10 md:mb-8 lg:mb-14">
          <SHRMLogo className="w-24" />
          <p className="mb-2 text-xs italic text-center md:mb-6">Aperian Live sessions are eligible for Professional Development Credits (PDCs)
            toward SHRM-CP® or SHRM-SCP® recertification.
          </p>
        </div>
      </div>
    </div>
    ));
};
AperianLiveHome.getAPIDataKey = () => 'aperianLiveHome';
AperianLiveHome.getData = apiService => apiService.get('cohort-events/dashboard')
  .then(aperianLiveHome => ({ aperianLiveHome }));

AperianLiveHome.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  initialData: PropTypes.shape({
    sessions: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        nameInternal: PropTypes.string,
        facilitator: PropTypes.string,
        facilitatorAvatarUrl: PropTypes.string,
        duration: PropTypes.number,
        description: PropTypes.string,
        prework: PropTypes.arrayOf(PropTypes.string),
        dateTime: PropTypes.string,
        cohortCode: PropTypes.string,
        tags: PropTypes.arrayOf(PropTypes.string),
        isRegistered: PropTypes.bool,
        userJoinUrl: PropTypes.string,
        courseId: PropTypes.number,
      })).isRequired,
  }).isRequired,
};

export default withServerSideData(AperianLiveHome);
