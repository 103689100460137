import React, { useEffect, useState } from 'react';
import { minutesToMilliseconds, differenceInMinutes, format } from 'date-fns';
import PropTypes from 'prop-types';

import SessionsDetailsContainerSubcontainer from './SessionsDetailsContainerSubcontainer';
// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import SessionsDetailsContainerButtons from './SessionsDetailsContainerButtons';
import Badges from '../../common/Badges';
import SHRMLogo from '../SHRMLogo';

const SessionDetailsContainer = ({
  name,
  nameInternal,
  date,
  time,
  dateTime,
  facilitator,
  facilitatorAvatarUrl,
  description,
  duration,
  prework,
  tags,
  register,
  join,
  isRegistered,
  userJoinUrl,
  cohortCode,
  organizations,
  userId,
  createShareableLink,
}) => {
  const [isJoinEnabled, setIsJoinEnabled] = useState(false);

  useEffect(() => {
    let joinTimer;
    const now = new Date();
    const startTime = new Date(dateTime);
    const diffToStartTime = differenceInMinutes(startTime, now);
    const meetingStartsInLessThanTen = diffToStartTime <= 10;
    const meetingStartsInMoreThanTen = diffToStartTime > 10;
    const meetingStartsInLessThanThirty = diffToStartTime <= 30;

    if (meetingStartsInLessThanTen) {
      setIsJoinEnabled(true);
    }

    if (meetingStartsInMoreThanTen && meetingStartsInLessThanThirty) {
      joinTimer = setTimeout(() => {
        setIsJoinEnabled(true);
      }, minutesToMilliseconds(diffToStartTime - 10));
    }

    return () => {
      if (joinTimer) {
        clearTimeout(joinTimer);
      }
    };
  }, []);

  const todaysDate = format(new Date(), 'yyyy-MM-dd');
  const sessionDate = format(new Date(dateTime), 'yyyy-MM-dd');
  const isPast = todaysDate >= sessionDate;

  return (
    <div className="bg-white rounded-lg">
      <div className="flex flex-col justify-between w-full gap-6 px-8 py-8 md:px-10">
        <div className="flex flex-col items-start w-full gap-4 md:gap-8">
          <div className="flex flex-col gap-4">
            <h3 className="mb-0">
              {name}
            </h3>
            {tags.length > 0 ? (<Badges badges={tags} color="green" />) : null}
          </div>
          <div className="flex justify-end gap-2 ml-0 md:border-l md:ml-4 border-rust-500">
            <p className="mb-0 ml-0 text-base md:ml-2">
              {description}
            </p>
          </div>
        </div>
        <div className="w-full">
          <SessionsDetailsContainerSubcontainer
            prework={prework}
            facilitator={facilitator}
            facilitatorAvatarUrl={facilitatorAvatarUrl}
            date={date}
            time={time}
            duration={duration}
          />
        </div>
        <SessionsDetailsContainerButtons
          name={name}
          nameInternal={nameInternal}
          cohortCode={cohortCode}
          isRegistered={isRegistered}
          register={register}
          userJoinUrl={userJoinUrl}
          isJoinEnabled={isJoinEnabled}
          join={join}
          dateTime={dateTime}
          duration={duration}
          facilitator={facilitator}
          isPast={isPast}
          organizations={organizations}
          userId={userId}
          createShareableLink={createShareableLink}
        />
        <div className="flex flex-col gap-2">
          <SHRMLogo className="w-16 mx-auto" />
          <p className="w-full mx-auto mb-2 text-xs italic text-center md:w-3/4 md:mb-6">Aperian Live sessions are eligible for Professional Development Credits (PDCs)
            toward SHRM-CP® or SHRM-SCP® recertification.
          </p>
        </div>
      </div>
    </div>
  );
};

SessionDetailsContainer.propTypes = {
  name: PropTypes.string.isRequired,
  nameInternal: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  dateTime: PropTypes.string.isRequired,
  facilitator: PropTypes.string.isRequired,
  facilitatorAvatarUrl: PropTypes.string,
  description: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  prework: PropTypes.arrayOf(PropTypes.string),
  tags: PropTypes.arrayOf(PropTypes.string),
  register: PropTypes.func.isRequired,
  join: PropTypes.func.isRequired,
  isRegistered: PropTypes.bool.isRequired,
  userJoinUrl: PropTypes.string,
  cohortCode: PropTypes.string.isRequired,
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  userId: PropTypes.string.isRequired,
  createShareableLink: PropTypes.func,
};

SessionDetailsContainer.defaultProps = {
  prework: [],
  tags: [],
  facilitatorAvatarUrl: '',
  userJoinUrl: '',
  organizations: [],
  createShareableLink: () => { },
};

export default SessionDetailsContainer;
