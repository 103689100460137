import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const PaymentLabelInput = forwardRef(
  // eslint-disable-next-line prefer-arrow-callback
  function PaymentInput({
    children,
    id,
    name,
    type,
    labelText,
    value,
    onChangeValue,
    isStripe,
    isDisabled,
    isRequired,
    iconClass,
    styles,
    errorMessage,
  }, ref) {
    return (
      <div className="relative">
        <label
          htmlFor={`${id}`}
          className={`font-sans block text-sm text-left font-medium leading-6 text-gray-700 ${labelText ? 'visible mb-2' : 'invisible'}`}
        >
          {labelText}
        </label>
        <div className="relative rounded-md shadow-sm">
          {isStripe ? (children) : (
            <input
              ref={ref}
              id={id}
              name={name}
              className="font-sans font-normal box-border block w-full rounded-md border-0 py-1.5 ring-1 ring-inset ring-gray-50 placeholder:text-gray-500 focus:ring-1 focus:ring-inset focus:ring-rust-500  focus:shadow-input sm:text-sm sm:leading-6 pl-3"
              type={type}
              value={value}
              onChange={onChangeValue}
              disabled={isDisabled}
              required={isRequired}
              autoComplete={type === 'password' ? 'off' : null}
            />
          )}
          {errorMessage
            ? (
              <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                <svg width="16" height="16" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11 7V11M11 15H11.01M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="#F04338" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            ) : null}
        </div>
        {errorMessage ? (
          <p className="h-4 mt-2 font-sans text-sm text-left text-red-500">
            {errorMessage}
          </p>
        ) : null}
        <span className={`${iconClass} absolute bottom-[5px] right-2`} style={styles} />
      </div>
    );
  });

PaymentLabelInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  type: PropTypes.string,
  labelText: PropTypes.string,
  value: PropTypes.string,
  iconClass: PropTypes.string,
  onChangeValue: PropTypes.func,
  children: PropTypes.node,
  isStripe: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  styles: PropTypes.shape({}),
  errorMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
};

PaymentLabelInput.defaultProps = {
  children: null,
  name: '',
  type: '',
  labelText: '',
  value: '',
  iconClass: '',
  onChangeValue: () => { },
  isStripe: false,
  isDisabled: false,
  isRequired: false,
  styles: {},
  errorMessage: false,
};

export default PaymentLabelInput;
