import React from 'react';
import {
  Route,
  IndexRoute,
  IndexRedirect,
  Redirect,
} from 'react-router';

import App from './components/App';

/* ****** Onboarding ****** */
import Onboarding from './pages/Onboarding';
import ExpiredTrial from './pages/Onboarding/ExpiredTrial';

/* ****** Sessions ****** */
import Logout from './components/Logout';
import Auth0Login from './components/Login/Auth0/Login';
import Authenticated from './components/Login/Auth0/Authenticated';

/* ****** Registration ****** */
import Auth0Registration from './components/Login/Auth0/Registration';
import ValidateEmail from './components/EmailVerification/ValidateEmail';
import EmailVerificationResend from './components/EmailVerification/EmailVerificationResend';

/* ****** Password ****** */
import ForgotPassword from './components/ForgotPassword';
import ForgotPasswordSuccess from './components/ForgotPassword/Success';
import ResetPassword from './components/ResetPassword';
import ResetPasswordSuccess from './components/ResetPassword/Success';

/* ****** Dashboard ****** */
import Dashboard from './components/Dashboard';
import AccountSettings from './components/AccountSettings';
import AccountSettingsPersonal from './components/AccountSettings/AccountSettingsPersonal';
import AccountSettingsSummary from './components/AccountSettings/AccountSettingsSummary';

/* ****** Administration ****** */
import AdminAuthorization from './components/Administration/AdminAuthorization';
import AperianLiveAdmin from './components/Administration/AperianLiveAdmin';
import Users from './components/Administration/Users';
import UserDetails from './components/Administration/Users/UserDetails';
import Trials from './pages/Administration/Trials';
/* ****** Access Codes ****** */
import AccessCodeLookup from './components/Administration/AccessCodes/LookupAccessCode';
import AccessCodesEdit from './components/Administration/AccessCodes/EditAccessCode';
/* ****** Blocks ****** */
import BlocksAuth from './pages/DashboardBlocks/BlocksAuth';
import BlocksEdit from './pages/DashboardBlocks/BlocksEdit';
import BlocksNew from './pages/DashboardBlocks/BlocksNew';
import BlocksSearch from './pages/DashboardBlocks/BlocksSearch';
import BlocksDefaultSort from './pages/DashboardBlocks/BlocksDefaultSort';

/* ****** Organizations ****** */
import Organizations from './components/Administration/Organizations';
import OrganizationSearch from './components/Administration/Organizations/OrganizationSearch';
import OrganizationAdd from './components/Administration/Organizations/OrganizationAdd';
import OrganizationDetail from './components/Administration/Organizations/OrganizationDetail';
import OrganizationOwners from './components/Administration/Organizations/OrganizationOwners';
import OrganizationAddUser from './components/Administration/Organizations/OrganizationAddUser';
import OrganizationAuthorizations from './components/Administration/Organizations/OrganizationAuthorizations';
import OrganizationDashboardBlocks from './components/Administration/Organizations/OrganizationDashboardBlocks';
import OrganizationDashboardMessage from './components/Administration/Organizations/OrganizationDashboardMessage';
import OrganizationDeleteUsers from './components/Administration/Organizations/OrganizationDeleteUsers';
import OrganizationConfirmDelete from './components/Administration/Organizations/OrganizationConfirmDelete';

/* ****** Access Codes ****** */
import AccessCodeRedeem from './components/AccessCodes/AccessCodeRedeem';

/* ****** Invitations ****** */
import PendingInvitation from './components/Invitations/PendingInvitations';
import InvitationAccept from './components/Invitations/InvitationAccept';
import IndividualInvitationAuthorization from './components/Invitations/IndividualInvitationAuthorization';
import IndividualInvitation from './components/Invitations/IndividualInvitation';
import IndividualInvitationSuccess from './components/Invitations/IndividualInvitationSuccess';

/* ****** Teams ****** */
import TeamsAuthorization from './authorizations/TeamsAuthorization';
import Teams from './pages/Teams';
import TeamInvitation from './pages/TeamInvitation';
import TeamInvitationSuccess from './components/Invitations/TeamInvitationSuccess';
import Team from './pages/Team';
import AddTeamMembers from './components/Invitations/TeamAddMembers';

/* ****** Assessments ****** */
import AssessmentAuthorization from './components/Assessments';
import Assessment from './components/Assessments/Assessment';
import AssessmentSurvey from './components/Assessments/Survey';
import AssessmentInvitation from './components/Assessments/Invitation';
import AssessmentGroup from './components/Assessments/Group';
import AddGroupMembers from './components/Assessments/GroupAddMembers';
import AssessmentResults from './components/Assessments/Results';
import AddGroupRaters from './components/Assessments/GroupAddRaters';
import AssessmentRaterSurvey from './components/Assessments/RaterSurvey';
import ThankYou from './pages/Nudges/ThankYou';

/* ****** Admin Center ****** */
import AdminCenterAuth from './pages/AdminCenter';
import AdminCenterDetail from './pages/AdminCenter/AdminCenterDetail';
import AdminCenterProducts from './pages/AdminCenter/AdminCenterProducts';
import AdminCenterTrends from './pages/AdminCenter/AdminCenterTrends';

/* ****** Aperian Live ****** */
import AperianLiveAuth from './authorizations/AperianLiveAuthorization';
import AperianLiveHome from './pages/AperianLive';
import AperianLiveCourseCatalog from './pages/AperianLive/AperianLiveCourseCatalog';

/* ****** Cohorts ***** */
import CohortEnroll from './pages/Cohorts/CohortEnroll';
import CohortsSearch from './pages/Cohorts/CohortsSearch';
import CohortsAdd from './pages/Cohorts/CohortsAdd';
import CohortsEdit from './pages/Cohorts/CohortsEdit';
import CohortsAuth from './pages/Cohorts/CohortsAuth';
import CohortsOverview from './pages/Cohorts/CohortsOverview';

/* ****** Profile ****** */
import Profile from './pages/Profile';
import StartSurvey from './components/Survey/StartSurvey';
import SurveyState from './components/Survey/SurveyState';
import Compare from './pages/Compare';
import NewComparison from './components/NewComparison';
import TopicalAdviceAuthorization from './pages/Advice/TopicalAdviceAuthorization';
import ManageConnections from './pages/Connections/ManageConnections';

import CopilotAuth from './pages/Copilot/CopilotAuth';
import Copilot from './pages/Copilot';

/* ****Learning module ****** */
import LearningModule from './pages/LearningModule';
/* ****** GS Guides home ****** */
import GlobeSmartGuidesAuth from './authorizations/GlobeSmartGuidesAuthorization';
import GlobeSmartGuide from './pages/GlobeSmartGuide';

/* ****** Misc. ****** */
import UpgradeToProfessional from './components/UpgradeToProfessional';
import ComingSoon from './components/ComingSoon';
import NotFound from './components/NotFound';
import ServerError from './components/ServerError';
import Expired from './components/Expired';
import TopAdvice from './pages/Advice/TopAdvice';
import Payment from './components/Payment';
import MySkills from './pages/MySkills';
import MySkillsAuth from './pages/MySkills/MySkillsAuth';

/**
 *  Creates a Cookie context and returns the Routes for React Router
 *
 *  @param {Object} req HTTP Request object
 *  @return {DomNode} Rendered JSX Routes
 */
const routes = (
  <Route exact path="/" component={App}>
    {/* ****** Onboarding ****** */}
    <Route path="/try(/invitations/:token/accept)" component={Onboarding} />
    <Route path="/try(/ls)" component={Onboarding} />
    <Route path="/try(/al)" component={Onboarding} />
    <Route path="/try/expired" component={ExpiredTrial} />

    {/* ****** Sessions ****** */}
    <IndexRoute component={Auth0Login} />
    <Route path="/login" component={Auth0Login} />
    <Route path="/logout" component={Logout} />
    <Route path="/authenticated" component={Authenticated} />

    {/* ****** Registration ****** */}
    <Route path="/registration" component={Auth0Registration} />
    <Route path="/emails/:token/verify" component={ValidateEmail} />
    <Route path="/emails/resend-verification" component={EmailVerificationResend} />

    {/* ****** Password ****** */}
    <Route path="/forgot-password" component={ForgotPassword} />
    <Route path="/forgot-password/complete" component={ForgotPasswordSuccess} />
    <Route path="/reset-password" component={ResetPassword} />
    <Route path="/reset-password/success" component={ResetPasswordSuccess} />

    {/* ****** Dashboard ****** */}
    <Route path="/dashboard" component={Dashboard} />
    <Route path="/account-settings" component={AccountSettings}>
      <Route path="/account-settings/personal" component={AccountSettingsPersonal} />
      <Route path="/account-settings/summary" component={AccountSettingsSummary} />
    </Route>

    {/* ****** Administration ****** */}
    <Route path="/admin-center" component={AdminCenterAuth}>
      <Route path="/admin-center/:orgId/people" component={AdminCenterDetail} />
      <Route path="/admin-center/:orgId/products" component={AdminCenterProducts} />
      <Route path="/admin-center/:orgId/trends" component={AdminCenterTrends} />
    </Route>

    {/* ****** Aperian Live ****** */}
    <Route path="/aperian-live" component={AperianLiveAuth}>
      <IndexRoute component={AperianLiveHome} />
      <Route path="/aperian-live/program/:id" component={AperianLiveCourseCatalog} />
    </Route>

    {/* ****** GS Guides home ****** */}
    <Route path="/guides" component={GlobeSmartGuidesAuth}>
      <IndexRoute component={GlobeSmartGuide} />
    </Route>

    {/* ****** Cohorts ****** */}
    <Route path="/cohorts" component={CohortsAuth}>
      <Route path="/cohorts/search" component={CohortsSearch} />
      <Route path="/cohorts/new" component={CohortsAdd} />
      <Route path="/cohorts/:code/edit" component={CohortsEdit} />
      <Route path="/cohorts/:code" component={CohortsOverview} />
    </Route>

    {/* ****** Cohorts Enrollment ****** */}
    <Route path="enroll/cohorts/:code" component={CohortEnroll} />

    {/* ****** Administration ****** */}
    <Route path="/administration/blocks" component={BlocksAuth}>
      <Route path="/administration/blocks/:id/edit" component={BlocksEdit} />
      <Route path="/administration/blocks/new" component={BlocksNew} />
      <Route path="/administration/blocks/search" component={BlocksSearch} />
      <Route path="/administration/blocks/default-sort" component={BlocksDefaultSort} />
    </Route>

    <Route path="/administration" component={AdminAuthorization}>
      <IndexRedirect to="/administration/users" />
      <Route path="/administration/users" component={Users} />
      <Route path="/administration/user/:id" component={UserDetails} />
      <Route path="/administration/access-codes/lookup" component={AccessCodeLookup} />
      <Route path="/administration/access-codes/:code/edit" component={AccessCodesEdit} />
      <Route path="/administration/aperian-live" component={AperianLiveAdmin} />
      <Route path="/administration/organizations" component={Organizations}>
        <Route path="/administration/organizations/search" component={OrganizationSearch} />
        <Route path="/administration/organizations/add" component={OrganizationAdd} />
      </Route>
      <Route path="/administration/organizations/:id" component={OrganizationDetail}>
        <Route path="/administration/organizations/:id/owners" component={OrganizationOwners} />
        <Route path="/administration/organizations/:id/add-users" component={OrganizationAddUser} />
        <Route path="/administration/organizations/:id/authorizations" component={OrganizationAuthorizations} />
        <Route path="/administration/organizations/:id/dashboard/blocks" component={OrganizationDashboardBlocks} />
        <Route path="/administration/organizations/:id/dashboard/message" component={OrganizationDashboardMessage} />
        <Route path="/administration/organizations/:id/delete-users" component={OrganizationDeleteUsers} />
      </Route>
      <Route path="/administration/organizations/:id/delete-users/confirm" component={OrganizationConfirmDelete} />
      <Route path="/administration/trials" component={Trials} />
    </Route>

    {/* ****** Access Codes ****** */}
    <Route path="/access-codes/:accessCode/redeem" component={AccessCodeRedeem} />

    {/* ****** Invitations ****** */}
    <Route path="/invitations" component={PendingInvitation} />
    <Route path="invitations/:token/accept" component={InvitationAccept} />

    {/* Redirects to account for previous invitations routes  */}
    <Redirect from="/invitations/new" to="/profile/invitations/new" />
    <Redirect from="/invitations/success" to="/profile/invitations/success" />

    {/* ****** Profile ****** */}
    <Route path="/profile" component={Profile} />
    <Route path="/profile/invitations/new" component={IndividualInvitationAuthorization}>
      <IndexRoute component={IndividualInvitation} />
    </Route>
    <Route path="/profile/connections" component={ManageConnections} />
    <Route path="/profile/invitations/success" component={IndividualInvitationSuccess} />
    <Route path="/profile/survey" component={StartSurvey} />
    <Route path="/profile/survey/:question" component={SurveyState} />
    <Route path="/profile/comparison" component={Compare}>
      <IndexRoute component={TopAdvice} />
      <Route path="advice/:topicId" component={TopicalAdviceAuthorization} />
    </Route>
    <Route path="/profile/comparison/new" component={NewComparison} />

    {/* ****** Teams ****** */}
    <Route path="/profile/teams" component={TeamsAuthorization}>
      <IndexRoute component={Teams} />
      <Route path="/profile/teams/new" component={TeamInvitation} />
      <Route path="/profile/teams/success" component={TeamInvitationSuccess} />
      <Route path="/profile/teams/:id" component={Team} />
      <Route path="/profile/teams/:id/add" component={AddTeamMembers} />
    </Route>

    {/* Redirects to account for previous teams routes  */}
    <Redirect from="/teams" to="/profile/teams" />
    <Redirect from="/teams/new" to="/profile/teams/new" />
    <Redirect from="/teams/success" to="/profile/teams/success" />
    <Redirect from="/teams/:id" to="/profile/teams/:id" />
    <Redirect from="/teams/:id/:teamname/add" to="/profile/teams/:id/add" />

    {/* ****** Assessments ****** */}
    <Route path="/assessments/:assessment" component={AssessmentAuthorization}>
      <IndexRoute component={Assessment} />
      <Route path="/assessments/:assessment/survey(/:groupId)" component={AssessmentSurvey} />
      <Route path="/assessments/:assessment/group/new" component={AssessmentInvitation} />
      <Route path="/assessments/:assessment/group/:id" component={AssessmentGroup} />
      <Route path="/assessments/:assessment/group/:id/add" component={AddGroupMembers} />
      <Route path="/assessments/:assessment/results" component={AssessmentResults} />
      <Route path="/assessments/:assessment/group/:id/member/:memberId/raters/new" component={AddGroupRaters} />
    </Route>
    <Route path="/thankyou" component={ThankYou} />
    <Route path="/survey/rater/:raterId" component={AssessmentRaterSurvey} />

    {/* ****** Guides Copilot ****** */}
    <Route path="/copilot" component={CopilotAuth}>
      <IndexRoute component={Copilot} />
    </Route>

    {/* ****** Learning Module ****** */}
    <Route path="/learning-modules" component={LearningModule} />

    {/* ****** My Skills ****** */}
    <Route path="/skills" component={MySkillsAuth}>
      <IndexRoute component={MySkills} />
    </Route>

    {/* ****** Misc. ****** */}
    <Route path="/upgrade-to-professional" component={UpgradeToProfessional} />
    <Route path="/coming-soon" component={ComingSoon} />
    <Route path="/404" component={NotFound} />
    <Route path="/error" component={ServerError} />
    <Route path="/expired" component={Expired} />
    <Route path="/payment/:id" component={Payment} />
  </Route>
);

export default routes;
