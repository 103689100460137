import React, { useContext, useEffect, useState } from 'react';

import { Context } from '../../../../context/ContextProvider';
import { NotificationContext } from '../../../../context/Notification';

import Notification from '../../../Notifications/Notification';
import LabelInput from '../../../common/LabelInput';
import SearchOrganizationsAction from '../../../../actions/organizations/search';
import OrganizationList from '../OrganizationList';

const onSubmit = e => e.preventDefault();

const OrganizationSearch = () => {
  const { apiService } = useContext(Context);
  const {
    addNotification,
    notificationMessage,
    removeNotification,
  } = useContext(NotificationContext);
  const [organizationName, setOrganizationName] = useState('');
  const [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    let timer;
    if (!organizationName) return setOrganizations([]);
    if (timer) window.clearTimeout(timer);
    timer = setTimeout(() => new SearchOrganizationsAction(apiService)
      .execute({ name: organizationName })
      .then(data => setOrganizations(data.organizations))
      .catch(err => {
        addNotification({
          type: 'warning',
          message: `Error searching for organization: ${err.message}`,
        });
      }), 250);
    return () => clearTimeout(timer);
  }, [organizationName]);

  useEffect(() => {
    let timeout;
    if (notificationMessage && notificationMessage.type === 'success') {
      timeout = window.setTimeout(() => {
        removeNotification();
      }, 5000);
    }
    return () => {
      window.clearTimeout(timeout);
      if (notificationMessage) removeNotification();
    };
  }, [notificationMessage]);

  const onSearchParamChange = ({ target: { value } }) => {
    setOrganizationName(value);
  };

  return (
    <>
      <Notification {...notificationMessage} />
      <form onSubmit={onSubmit}>
        <LabelInput
          id="organizationName"
          name="Organization Name"
          labelText="Organization Name"
          labelType="text"
          value={organizationName}
          onChangeValue={onSearchParamChange}
        />
      </form>
      <OrganizationList organizations={organizations} />
    </>
  );
};

export default OrganizationSearch;
