import React from 'react';
import PropTypes from 'prop-types';
import ActiveConnectionCard from '../ActiveConnectionCard';

const ActiveConnectionCards = ({ connections, onRemoveConnection }) => (
  <ul className="profile-group__list my-1">
    {connections.map(({
      id,
      name,
      teams,
      email,
      avatarUrl,
      connectionDate,
    }) => (
      <li key={id}>
        <ActiveConnectionCard
          key={id}
          connectionId={id}
          connectionName={name}
          teams={teams}
          email={email}
          avatarUrl={avatarUrl}
          connectionDate={connectionDate}
          onRemoveConnection={onRemoveConnection}
        />
      </li>
    ),
    )}
  </ul>
);

ActiveConnectionCards.propTypes = {
  connections: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    teams: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number,
      }),
    ),
    name: PropTypes.string,
    email: PropTypes.string,
    connectionDate: PropTypes.string,
    avatarURL: PropTypes.string,
  })).isRequired,
  onRemoveConnection: PropTypes.func.isRequired,
};

export default ActiveConnectionCards;
