class AperianLiveUserCriteria {
  constructor(user) {
    this.user = user;
  }

  test() {
    return !!(this.user.accessLevel.some(string => string.includes('aperianlive') || string.startsWith('premium')));
  }
}

export default AperianLiveUserCriteria;
