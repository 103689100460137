import React, { Component } from 'react';
import { throttle } from 'lodash';

import { defaultProps, propTypes } from './types';

import ProfileChartAvatar from '../Avatars/ProfileChartAvatar';
import { NewComparisonButton } from '../../MultipleComparisons/Buttons';
import HiddenPeopleOverlay from '../../MultipleComparisons/HiddenPeopleOverlay';

import { numHidden } from '../../../lib/profile-utils';

class ProfileChartAvatars extends Component {
  constructor(props) {
    super(props);

    this.avatars = [];
    this.state = {
      avatars: [],
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateAvatarPositions());
    window.addEventListener('load', this.updateAvatarPositions());
    window.addEventListener('eq-update', this.updateAvatarPositions());
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateAvatarPositions());
    window.removeEventListener('load', this.updateAvatarPositions());
    window.removeEventListener('eq-update', this.updateAvatarPositions());
  }

  onUpdate = list => this.props.onUpdate(list, this.addOrUpdateAvatarPositions);

  /**
   *  Calculate current avatar positions and pass to onAvatarPositionsChanged
   */
  addOrUpdateAvatarPositions = () => {
    const newPositions = {};

    this.state.avatars.forEach(avatar => {
      const avatarRect = avatar.el.getBoundingClientRect();
      const avatarCenter = avatarRect.left + (avatarRect.width / 2);

      newPositions[avatar.id] = avatarCenter;
    });

    this.props.onAvatarPositionsChanged(newPositions);
  };

  /**
   *  Function executes only when component is loaded
   */
  updateAvatarPositions = () => throttle(this.addOrUpdateAvatarPositions, 0, { leading: false });

  /**
   *  Save a reference to this avatar element for future position calculations
   *  @param {DOMNode} el
   */
  addAvatar = el => {
    if (el) {
      const id = el.getAttribute('data-id');
      this.avatars.push({ id, el });
      this.setState({ avatars: this.avatars });
    }
  };

  renderHiddenButton(profiles) {
    if (!this.props.showControls) return null;

    const hidden = numHidden(profiles);
    if (hidden === 0) return null;

    return (
      <div key="HiddenProfiles" className="self-center border-none avatar-container__item js-hiddenProfilesButton">
        <HiddenPeopleOverlay
          profiles={profiles}
          numHidden={hidden}
          count={profiles.length}
          maxAvatars={this.props.maxAvatars}
          onUpdate={this.onUpdate}
          tooManyComparisonObjects={this.props.tooManyComparisonObjects}
        />
      </div>
    );
  }

  renderAddButton() {
    if (!this.props.showControls) return null;
    const { location: { search } } = this.props;
    return (
      <div key="newComparison" className="self-center border-none avatar-container__item js-newComparisonButton">
        <NewComparisonButton link={`/profile/comparison/new${search}`} />
      </div>
    );
  }

  renderMultipleAvatars() {
    const {
      compareProfile,
      handleAvatarClick,
      hasResizeSupport,
      myProfileVisibility,
      selectedAvatar,
      showControls,
      profiles,
    } = this.props;

    const avatars = profiles
      .filter(profile =>
        !hasResizeSupport
        || (hasResizeSupport && profile.isVisible))
      .map((profile, index) => {
        const hasNav = (showControls // Show controls (ie. the top chart)
          && (index !== 0)); // Not the first avatar in the list
        const hasVisibility = () =>
          ((compareProfile && profile.isMe) ? myProfileVisibility : null);

        return (
          <ProfileChartAvatar
            key={profile.id}
            id={profile.id}
            type={profile.type}
            isHistoryProfile={profile.isHistoryProfile}
            shortDisplayName={profile.shortDisplayName}
            avatarUrl={profile.avatarUrl}
            cultureGuideUrl={profile.cultureGuideUrl}
            handleAvatarClick={handleAvatarClick}
            selectedAvatar={selectedAvatar}
            addAvatarRef={this.addAvatar}
            index={index}
            hasNavigation={hasNav}
            showName
            compareProfile={compareProfile}
            myProfileVisibility={hasVisibility()}
            withBorder={this.props.withBorder}
          />
        );
      });

    return (
      <div className="flex items-end avatar-container__list">
        {avatars}
        {this.renderHiddenButton(this.props.profiles)}
        {this.renderAddButton()}
      </div>
    );
  }

  renderSingleAvatar(profile) {
    const {
      id,
      firstName,
      lastName,
      shortDisplayName,
      type,
      avatarUrl,
    } = profile;
    return (
      <div className={`avatar-container__list${this.props.isDashboard ? ' p-0' : ''}`}>
        <ProfileChartAvatar
          id={id}
          firstName={firstName}
          lastName={lastName}
          type={type}
          shortDisplayName={shortDisplayName}
          avatarUrl={avatarUrl}
          addAvatarRef={this.addAvatar}
          showName={false}
        />
      </div>
    );
  }

  render() {
    if (typeof this.props.profiles[0] === 'undefined' || !this.props.profiles[0].avatarUrl) return null;

    if (this.props.profiles.length === 1) {
      return this.renderSingleAvatar(this.props.profiles[0]);
    }

    return this.renderMultipleAvatars();
  }
}

ProfileChartAvatars.propTypes = propTypes;
ProfileChartAvatars.defaultProps = defaultProps;

export default ProfileChartAvatars;
