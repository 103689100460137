import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../common/Button';
import LabelInput from '../common/LabelInput';

const EditableHeader = ({
  value: originalValue,
  onUpdate,
  name,
  label,
  maxLength,
  isEditing,
  toggleEditing,
}) => {
  const [newValue, setNewValue] = useState(originalValue);

  useEffect(() => {
    if (!isEditing) setNewValue(originalValue);
  }, [isEditing]);

  const handleChange = e => setNewValue(e.target.value);

  const onCancel = () => toggleEditing();

  const handleOnUpdate = () => onUpdate(newValue);

  return (
    <>
      {isEditing ? (
        <LabelInput
          id={name}
          name={name}
          labelText={label || name}
          labelType="text"
          value={newValue}
          onChangeValue={handleChange}
          maxLength={maxLength}
        />
      ) : (
        <h3 className="font-headline text-charcoal-900">{newValue}</h3>
      )}
      <div className={`flex items-center justify-end gap-2 my-2 ${isEditing ? 'block' : 'hidden'}`}>
        <Button
          key="cancel"
          isWarning
          isNormalCase
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          key="save"
          variant="secondary"
          isSmall
          onClick={handleOnUpdate}
        >
          Save
        </Button>
      </div>
    </>
  );
};

EditableHeader.propTypes = {
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isEditing: PropTypes.bool.isRequired,
  toggleEditing: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  maxLength: PropTypes.number,
};
EditableHeader.defaultProps = {
  maxLength: 255,
};

export default EditableHeader;
