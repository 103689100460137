/**
 * returns a translated word from a number between 1 and 19.
 * @constructor
 * @param {number} number - The number to translate.
 */
export function translateNumber(number) {
  const ONE_TO_NINETEEN = [
    'one', 'two', 'three', 'four', 'five',
    'six', 'seven', 'eight', 'nine', 'ten',
    'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen',
    'sixteen', 'seventeen', 'eighteen', 'nineteen',
  ];

  if (number < 20) {
    return ONE_TO_NINETEEN[number - 1];
  }
  return null;
}
