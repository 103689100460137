import tracker from '../index';

const {
  eventNames,
  eventProps,
  peopleProps,
  superProps,
  resolveAfterTimeout,
  checkOrgNames,
  getFreeOrPaid,
} = tracker;

const trackViewAperianLiveHome = (organizations, email) => {
  tracker.track(eventNames.VIEW_APERIAN_LIVE_HOME, {
    [eventProps.EMAIL]: email,
    [eventProps.ORGANIZATION_NAME]: checkOrgNames(organizations),
  });

  return resolveAfterTimeout;
};

const trackAddSessionToCalendar = (
  userId,
  organizations,
  name,
  nameInternal,
  cohortCode,
  dateTime,
  duration,
  facilitator,
  method,
) => {
  tracker.track(eventNames.ADD_SESSION_TO_CALENDAR, {
    [eventProps.ORGANIZATION_NAME]: checkOrgNames(organizations),
    [eventProps.SESSION_DISPLAY_NAME]: name,
    [eventProps.SESSION_NAME_INTERNAL]: nameInternal,
    [eventProps.COHORT_CODE]: cohortCode,
    [eventProps.SESSION_START_DATETIME]: dateTime,
    [eventProps.SESSION_DURATION]: duration,
    [eventProps.SESSION_TYPE]: 'Aperian Live',
    [eventProps.FACILITATOR]: facilitator,
    [eventProps.ADD_TO_CALENDAR_METHOD]: method,
  });

  tracker.identify(userId);

  tracker.people.union(peopleProps.SESSIONS_ATTENDED, nameInternal);

  return resolveAfterTimeout;
};
const trackViewDetailsAperianLive = (
  organizations,
  name,
  nameInternal,
  code,
  dateTime,
  duration,
  facilitator,
  detailsMethod,
) => {
  tracker.track(eventNames.VIEW_SESSION_DETAILS, {
    [eventProps.ORGANIZATION_NAME]: checkOrgNames(organizations),
    [eventProps.SESSION_DISPLAY_NAME]: name,
    [eventProps.SESSION_NAME_INTERNAL]: nameInternal,
    [eventProps.COHORT_CODE]: code,
    [eventProps.SESSION_START_DATETIME]: dateTime,
    [eventProps.SESSION_DURATION]: duration,
    [eventProps.SESSION_TYPE]: 'Aperian Live',
    [eventProps.FACILITATOR]: facilitator,
    [eventProps.DETAILS_METHOD]: detailsMethod,
  });

  return resolveAfterTimeout;
};

const trackShareAperianLiveSession = async (
  apiService,
  code,
  name,
  shareMethod,
) => {
  await apiService.post('trackers/mixpanel', {
    eventName: eventNames.SHARE_SESSION,
    properties: {
      [eventProps.SESSION_DISPLAY_NAME]: name,
      [eventProps.COHORT_CODE]: code,
      [eventProps.SESSION_TYPE]: 'Aperian Live',
      [eventProps.SHARE_METHOD]: shareMethod,
    },
  });

  return resolveAfterTimeout;
};

const trackShareAperianLiveCourseCatalog = async (
  apiService,
  name,
) => {
  await apiService.post('trackers/mixpanel', {
    eventName: eventNames.SHARE_COURSE_CATALOG,
    properties: {
      [eventProps.SESSION_DISPLAY_NAME]: name,
      [eventProps.SESSION_TYPE]: 'Aperian Live',
      [eventProps.SHARE_METHOD]: 'course catalog page',
    },
  });

  return resolveAfterTimeout;
};

const trackViewAperianLiveCourseCatalog = async (
  apiService,
  name,
  accessLevel,
) => {
  await apiService.post('trackers/mixpanel', {
    eventName: eventNames.VIEW_COURSE_CATALOG_PAGE,
    properties: {
      [eventProps.SESSION_DISPLAY_NAME]: name,
      [superProps.ACCESS_LEVEL]: getFreeOrPaid(accessLevel),
    },
  });

  return resolveAfterTimeout;
};

export {
  trackAddSessionToCalendar,
  trackViewAperianLiveHome,
  trackViewDetailsAperianLive,
  trackShareAperianLiveSession,
  trackShareAperianLiveCourseCatalog,
  trackViewAperianLiveCourseCatalog,
};
