import React from 'react';
import { propTypes, defaultProps } from './types';

const SurveyResponseNA = props => {
  const responseValue = 0;
  function isChecked() {
    return props.selectedOption === responseValue;
  }

  function onOptionChangeHandler(changeEvent) {
    props.onOptionChange(parseInt(changeEvent.target.value, 10));
  }

  return (
    <label htmlFor={responseValue} className="block my-4 text-center cursor-pointer md:my-8">
      <input
        className="radio__input"
        type="radio"
        name={`question-${props.questionId}`}
        id={responseValue}
        value={responseValue}
        checked={isChecked()}
        onChange={onOptionChangeHandler}
      />
      <span className="font-serif text-base italic leading-tight text-left text-gray-500 md:text-xl md:text-center radio__text">Not Applicable</span>
    </label>
  );
};

SurveyResponseNA.propTypes = propTypes;
SurveyResponseNA.defaultProps = defaultProps;

export default SurveyResponseNA;
