class OrganizationAddDomainAction {
  constructor(apiService) {
    this.apiService = apiService;
  }

  execute(orgId, domainValue) {
    return this.apiService.post(`organizations/${orgId}/domains`, {
      domain: {
        value: domainValue,
      },
    });
  }
}

export default OrganizationAddDomainAction;
