import React, { useContext, useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';

import { APIContext } from '../../../../context/API';

import Button from '../../../common/Button';
import LabelInput from '../../../common/LabelInput';

import { createTrialOrg } from '../../../../actions/onboarding';

import logger from '../../../../lib/logger';
import { trackTrialInvitationCompletion } from '../../../../lib/tracker/onboarding';

const InviteTextField = ({ index, value, onChangeValue }) => (
  <div className="flex w-auto mb-4 align-items-end m-0-auto">
    <span className="w-8 mr-2 font-serif text-3xl italic text-rust-500">{index + 1}</span>
    <div className="flex-grow">
      <LabelInput
        id={`invite-${index}`}
        name={`invite-${index}`}
        placeholderText="Team Member Email"
        labelType="text"
        value={value}
        onChangeValue={e => onChangeValue(index, e.target.value)}
        leadingIcon="mail"
      />
    </div>
  </div>
);

const InviteOthers = ({ formData, nextStep, handleUpdateForm }) => {
  const { apiService } = useContext(APIContext);
  const [inviteForm, setInviteForm] = useReducer((data, newData) =>
    ({ ...data, ...newData }), {
    emails: new Array(4).fill(''),
    orgName: null,
  });

  const [formError, setFormError] = useState(null);

  useEffect(() => {
    if (formData.orgName) nextStep();
  }, [formData]);

  const handleInvite = async e => {
    e.preventDefault();
    if (!inviteForm.orgName) {
      setFormError('You must enter an organization name.');
      return;
    }

    const mixpanelEmailsArray = inviteForm.emails.filter(email => email !== '');
    try {
      await trackTrialInvitationCompletion({
        userId: formData.userId,
        emails: mixpanelEmailsArray.join(', '),
        numberInvitationsSent: mixpanelEmailsArray.length,
        organizationName: inviteForm.orgName,
        invitationMethod: 'Onboarding',
      });

      const { userToken, inviteeTokens } = await createTrialOrg(apiService, {
        userId: formData.userId,
        invitations: inviteForm.emails,
        orgName: inviteForm.orgName,
      });

      handleUpdateForm({ orgName: inviteForm.orgName, userToken, inviteeTokens });
      nextStep();
    } catch (err) {
      if (err.status === 409) {
        setFormError('Organization Name already taken by another organization.');
      }
      logger.error(err);
    }
  };

  const handleEmailChange = (index, value) => {
    const updatedEmails = [...inviteForm.emails];
    updatedEmails[index] = value;
    setInviteForm({ emails: updatedEmails });
  };

  const onChangeValue = e => {
    setFormError(null);
    setInviteForm({ [e.target.name]: e.target.value });
  };

  return (
    <div className="w-full px-6 my-6 md:w-3/5 lg:w-2/4">
      <h1 className="mb-6 font-serif text-4xl font-normal tracking-wider md:text-5xl">Imagine what you could do with <i>more insights</i>.</h1>
      <p className="m-0 mb-6 font-sans text-base text-gray-800">Enter your company name, and optionally invite a <b>few team members</b> to try Aperian.</p>
      <div className="flex items-center">
        <div className="w-full h-24 mb-2">
          <LabelInput
            id="orgName"
            name="orgName"
            placeholderText="Company Name"
            labelType="text"
            labelText="What's your company name?"
            value={inviteForm.orgName}
            errorMessage={formError}
            onChangeValue={onChangeValue}
            leadingIcon="building"
          />
        </div>
      </div>
      <hr />
      <fieldset className="my-4 border-0">
        {inviteForm.emails.map((email, index) => (
          <InviteTextField
            // eslint-disable-next-line react/no-array-index-key
            key={`email-${index}`}
            index={index}
            value={email}
            onChangeValue={handleEmailChange}
          />
        ),
        )}
      </fieldset>
      <div className="flex justify-center max-w-2xl">
        <Button
          variant="primary"
          trailingButtonIcon="arrow-right"
          onClick={handleInvite}
        >Continue
        </Button>
      </div>
    </div>
  );
};

InviteOthers.propTypes = {
  nextStep: PropTypes.func.isRequired,
  handleUpdateForm: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    userId: PropTypes.string,
    orgName: PropTypes.string,
  }).isRequired,
};

InviteTextField.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
  onChangeValue: PropTypes.func.isRequired,
};

export default InviteOthers;
