import React, { useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

import { ModalContext } from '../../../../context/Modal';
import { APIContext } from '../../../../context/API';
import { RouterContext } from '../../../../context/Router';
import { NotificationContext } from '../../../../context/Notification';

import withServerSideData from '../../../../HOC/withServerSideData';

import CreateAccessCode from '../../AccessCodes/CreateAccessCode';
import ReviewAccessCodes from '../../AccessCodes/ReviewAccessCodes';
import EditableField from '../../../EditableField';
import Notification from '../../../Notifications/Notification';

import logger from '../../../../lib/logger';
import byExpirationDateDesc from '../../../../lib/sortByExpirationDateDesc';
import DeactivateAccessCodeAction from '../../../../actions/accessCodes/deactivateAccessCode';
import UpdateUserAdminAction from '../../../../actions/user/updateAdmin';
import PasswordResetTable from '../../PasswordResetTable';
import Button from '../../../common/Button';
import DeleteAccount from '../../../DeleteModals/DeleteAccountModal';
import ConfirmModal from '../../../common/Modal/ConfirmModal';
import deleteUserAction from '../../../../actions/user/delete';
import GlobeSmartModal from '../../../GlobeSmartModal';

const UserDetails = ({ initialData, params: { id } }) => {
  const { apiService } = useContext(APIContext);
  const {
    addNotification,
    notificationMessage,
    removeNotification,
  } = useContext(NotificationContext);
  const { handleOpenModal, handleCloseModal } = useContext(ModalContext);
  const { router } = useContext(RouterContext);

  const [state, setState] = useReducer((data, newData) =>
    ({ ...data, ...newData }), { ...initialData });

  useEffect(() => {
    let timeout;
    if (notificationMessage) {
      timeout = window.setTimeout(() => {
        removeNotification();
      }, 5000);
    }
    return () => {
      window.clearTimeout(timeout);
      if (notificationMessage) removeNotification();
    };
  }, [notificationMessage]);

  const updateAccessCodes = () => apiService
    .get(`user/${id}?includeUnavailable=true`)
    .then(data => {
      const { meta, status, ...userData } = data;
      userData.accessCodes = data.accessCodes
        .filter(accessCode => accessCode)
        .sort(byExpirationDateDesc);
      setState(userData);
    });

  const setInactive = code => {
    const updatedAccessCodes = state.accessCodes.map(accessCode => {
      const thisCode = { ...accessCode };
      if (thisCode.code === code) {
        thisCode.active = false;
      }
      return thisCode;
    });
    setState({ accessCodes: updatedAccessCodes });
  };

  const onDeactivate = code => new DeactivateAccessCodeAction(apiService)
    .execute(code)
    .then(() => setInactive(code))
    .catch(err => logger.error(JSON.stringify(err)));

  const deleteUser = () => deleteUserAction(apiService, id)
    .then(() => {
      handleCloseModal();
      addNotification({ type: 'success', message: 'This account has been deleted' });
      router.push('/administration/users');
    })
    .catch(() => {
      handleCloseModal();
      addNotification({
        type: 'failure',
        message: 'Error: Something went wrong while deleting this account. Please try again later or contact <a href="mailto:support@aperian.com">support@aperian.com</a>.',
      });
    });

  const handleOpenConfirmModal = () => {
    handleCloseModal();
    handleOpenModal({
      content: (
        <ConfirmModal
          confirmButtonText="Yes, Delete this Account"
          onCancel={handleCloseModal}
          onConfirm={deleteUser}
          title="Confirm Deletion"
          confirmText="DELETE"
        >
          <p className="text-base">Confirm deletion by typing <b>DELETE</b> in the box.</p>
        </ConfirmModal>),
    });
  };

  const updateUser = data => {
    removeNotification();
    // Only update of values have been changed
    const changedKeys = Object.keys(data).filter(k => state[k] !== data[k]);
    if (changedKeys.length === 0) return;

    new UpdateUserAdminAction(apiService)
      .execute(state.id, data)
      .then(() => {
        addNotification({
          type: 'success',
          message: `User updated: ${changedKeys.join(', ')}`,
        });
        setState(data);
      })
      .catch(err => {
        let message = '';
        if (err.reason && err.reason.error.details) {
          const { details } = err.reason.error;
          const firstKey = Object.keys(details[0])[0];
          message = details[0][firstKey];
        }
        addNotification({
          type: 'failure',
          message: `Could not update user. ${message}`,
        });
      });
  };

  const renderOrgs = () => {
    const { organizations: orgs } = state;
    if (orgs && orgs.length > 0) {
      return orgs.map(org => org.name).join(', ');
    }
    return ' ';
  };

  const {
    firstName,
    lastName,
    email,
    externalAuth,
    stripeId,
    accessCodes,
    passwordResetUrls,
  } = state;

  return (
    <div>
      <Notification {...notificationMessage} />
      <div className="grid gap-2 md:grid-cols-6">
        <div className="md:col-span-2">
          <h4 className="mb-4 text-2xl">Profile</h4>
          <div className="flex flex-col gap-2 mb-6">
            <h4 className="mb-0 text-xl">{firstName} {lastName}</h4>
            <p className="mb-0 font-sans text-sm font-normal leading-tight">
              <b>External Auth:</b> {externalAuth === true ? 'SSO' : 'Email / Password'}
            </p>
            <p className="mb-0 font-sans text-sm font-normal leading-tight">
              <b>Stripe Id:</b> {stripeId}
            </p>
            <p className="mb-0 font-sans text-sm font-normal leading-tight">
              <b>Organizations:</b> {renderOrgs()}
            </p>
          </div>
        </div>
        <div className="flex flex-col md:col-span-4">
          <EditableField
            name="firstName"
            label="First Name"
            value={firstName}
            onUpdate={updateUser}
          />
          <EditableField
            name="lastName"
            label="Last Name"
            value={lastName}
            onUpdate={updateUser}
          />
          <EditableField
            name="email"
            label="Email"
            value={email}
            onUpdate={updateUser}
          />
        </div>
      </div>
      <hr className="my-8 border border-stone-400" />
      <div className="grid gap-2 md:grid-cols-6">
        <div className="md:col-span-2">
          <h4 className="mb-4 text-2xl">Password Reset URLs</h4>
        </div>
        <div className="flex flex-col overflow-x-auto md:col-span-4">
          <PasswordResetTable passwordResetUrls={passwordResetUrls} />
        </div>
      </div>
      <hr className="my-8 border border-stone-400" />
      <div className="grid gap-2 md:grid-cols-6">
        <div className="md:col-span-2">
          <h4 className="mb-4 text-2xl">Create Access Code</h4>
        </div>
        <div className="flex flex-col md:col-span-4">
          <CreateAccessCode {...state} onCreate={updateAccessCodes} />
        </div>
      </div>
      <hr className="my-8 border border-stone-400" />
      <div className="grid gap-2 md:grid-cols-6">
        <div className="md:col-span-2">
          <h4 className="mb-4 text-2xl">Owned Access Codes</h4>
        </div>
        <div className="flex flex-col md:col-span-4">
          <ReviewAccessCodes accessCodes={accessCodes} onDeactivate={onDeactivate} />
        </div>
      </div>
      <hr className="my-8 border border-stone-400" />
      <div className="grid gap-2 md:grid-cols-6">
        <div className="md:col-span-2">
          <h4>Delete</h4>
          <p className="text-sm">
            Deleting this account will delete
            all the user&apos;s records on the Aperian platform.
          </p>
        </div>
        <div className="flex flex-col md:col-span-4">
          <div className="py-2">
            <Button
              className="float-right"
              filledColor="red"
              onClick={() =>
                handleOpenModal({
                  content: <DeleteAccount
                    confirmButtonText="Yes, Delete this Account"
                    onCancel={handleCloseModal}
                    onConfirm={handleOpenConfirmModal}
                  />,
                  modalSize: 'large',
                })}
            >Delete User Account
            </Button>
          </div>
        </div>
      </div>
      <GlobeSmartModal />
    </div>
  );
};

UserDetails.getAPIDataKey = () => 'userData';

UserDetails.getData = (apiService, { id }) => apiService
  .get(`user/${id}?includeUnavailable=true`)
  .then(data => {
    const { meta, status, ...userData } = data;
    userData.accessCodes = data.accessCodes
      .filter(accessCode => accessCode)
      .sort(byExpirationDateDesc);
    return { userData };
  });

UserDetails.propTypes = {
  initialData: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    externalAuth: PropTypes.bool,
    stripeId: PropTypes.string,
    accessCodes: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        description: PropTypes.string,
        internalNotes: PropTypes.string,
        quantity: PropTypes.number,
        active: PropTypes.bool,
        seatsRemaining: PropTypes.number,
        stripePlanId: PropTypes.string,
        created: PropTypes.string,
        expirationDate: PropTypes.string,
        planName: PropTypes.string,
      }),
    ),
    organizations: PropTypes.arrayOf(
      PropTypes.shape({
        createdAt: PropTypes.string,
        dashboardLayout: PropTypes.number,
        displayName: PropTypes.string,
        displayOrgAverage: PropTypes.bool,
        externalId: PropTypes.string,
        id: PropTypes.string,
        name: PropTypes.string,
        note: PropTypes.string,
        updatedAt: PropTypes.string,
      }),
    ),
    passwordResetUrls: PropTypes.arrayOf(
      PropTypes.shape({
        createdAt: PropTypes.string,
        expiresAt: PropTypes.string,
        id: PropTypes.number,
        state: PropTypes.string,
        token: PropTypes.string,
        updatedAt: PropTypes.string,
        user: PropTypes.string,
      }),
    ),
  }).isRequired,
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default withServerSideData(UserDetails);
