import React from 'react';
import { Link } from 'react-router';

import { propTypes } from './types';
import Checkbox from '../../../common/Checkbox';

const renderInfo = (users, onUpdate) => users.map(user => (
  <tr key={user.id} className="even:bg-ivory-500">
    <td className="py-4 pl-0 pr-3 md:pl-4 truncate" data-label="Last Name">
      <Link to={`/administration/user/${user.id}`}>{user.lastName}</Link>
    </td>
    <td className="py-4 pl-0 pr-3 md:pl-4 truncate" data-label="First Name">
      <Link to={`/administration/user/${user.id}`}>{user.firstName}</Link>
    </td>
    <td className="py-4 pl-0 pr-3 md:pl-4 truncate" data-label="Email Address">
      {user.email}
    </td>
    <td className="py-4 pl-0 pr-3 md:pl-4" data-label="Admin User">
      <Checkbox
        id={`user-${user.id}`}
        name="isAdmin"
        value="admin"
        checked={user.isAdmin}
        onChange={e => onUpdate(e, user.id)}
      />
    </td>
    <td className="py-4 pl-0 pr-3 md:pl-4" data-label="IBI Admin User">
      <Checkbox
        id={`user-ibi-${user.id}`}
        name="isIBIAdmin"
        value="IBIAdmin"
        checked={user.isIBIAdmin}
        onChange={e => onUpdate(e, user.id)}
      />
    </td>
    <td className="py-4 pl-0 pr-3 md:pl-4" data-label="AG Cohort Admin User">
      <Checkbox
        id={`user-ag-cohort-admin-${user.id}`}
        name="isAgCohortAdmin"
        value="AgCohortAdmin"
        checked={user.isAgCohortAdmin}
        onChange={e => onUpdate(e, user.id)}
      />
    </td>
  </tr>
));

const UserList = ({ users, onUpdate }) => {
  if (!users || !users.length) return null;

  return (
    <div className="overflow-x-auto mt-5">
      <table className="w-full text-sm divide-y divide-gray-300 text-charcoal-900 xl:table-fixed">
        <thead>
          <tr>
            <th className="xl:w-2/12 py-3.5 pl-0 md:pl-4 pr-3 text-left font-semibold">
              Last Name
            </th>
            <th className="xl:w-2/12 py-3.5 pl-0 md:pl-4 pr-3 text-left font-semibold">
              First Name
            </th>
            <th className="xl:w-[30%] py-3.5 pl-0 md:pl-4 pr-3 text-left font-semibold">
              Email
            </th>
            <th className="py-3.5 pl-0 md:pl-4 pr-3 text-left font-semibold">
              Admin
            </th>
            <th className="py-3.5 pl-0 md:pl-4 pr-3 text-left font-semibold">
              IBI Admin
            </th>
            <th className="xl:w-2/12 py-3.5 pl-0 md:pl-4 pr-3 text-left font-semibold">
              AG Cohort Admin
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {renderInfo(users, onUpdate)}
        </tbody>
      </table>
    </div>
  );
};

UserList.propTypes = propTypes;

export default UserList;
