import React from 'react';
import { iconPropTypes, defaultIconProps } from './types';

import { fillColorMap, strokeColorMap } from '../index.js';

const AwardIcon = ({ className, iconColor }) => (
  <svg className={`${className} ${fillColorMap[iconColor]} ${strokeColorMap[iconColor]} `} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.96668 14.7219L7 22L11.5884 19.247C11.7381 19.1572 11.8129 19.1123 11.8928 19.0947C11.9634 19.0792 12.0366 19.0792 12.1072 19.0947C12.1871 19.1123 12.2619 19.1572 12.4116 19.247L17 22L16.0343 14.7212M19 9C19 12.866 15.866 16 12 16C8.13401 16 5 12.866 5 9C5 5.13401 8.13401 2 12 2C15.866 2 19 5.13401 19 9Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

AwardIcon.propTypes = iconPropTypes;
AwardIcon.defaultProps = defaultIconProps;

export default AwardIcon;
