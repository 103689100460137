import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useIntersectionObserver } from '../../hooks';

const LineChart = ({
  leftText,
  rightText,
  points,
}) => {
  const { element, isIntersecting } = useIntersectionObserver({
    threshold: 0.5,
  });

  const [hasAnimated, setHasAnimated] = useState(false);

  useEffect(() => {
    if (isIntersecting && !hasAnimated) {
      element.current.classList.add('animate-point-slide');
      setHasAnimated(true);
    }
  }, [isIntersecting, hasAnimated]);

  return (
    <div className="h-16 mt-3">
      <div className="flex mb-6">
        <span>{leftText}</span>
        <span className="flex-grow text-right">{rightText}</span>
      </div>
      <div className="rounded-full relative w-full h-2 bg-ivory-500 [&>*:nth-child(1)]:bg-rust-500 [&>*:nth-child(2)]:bg-rust-100">
        {points.map(percent => (
          <div
            ref={element}
            key={percent}
            className="absolute w-6 h-6 rounded-full -top-2 left-1/2"
            style={{ '--slide-percent': `${percent}%` }}
          />
        ))}
      </div>
    </div>
  );
};

LineChart.propTypes = {
  points: PropTypes.arrayOf(
    PropTypes.number,
  ).isRequired,
  leftText: PropTypes.string,
  rightText: PropTypes.string,
};

LineChart.defaultProps = {
  leftText: '',
  rightText: '',
};

export default LineChart;
