import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Avatar from '../../../common/Avatars/Avatar';
import Button from '../../../common/Button';
import UploadProfileModal from './UploadProfileModal';
import CreateInitialsModal from './CreateInitialsModal';
import DeleteProfilePhotoModal from '../../../DeleteModals/DeleteProfileImageModal';

const options = [
  {
    code: '1',
    label: 'Upload Photo',
  },
  {
    code: '2',
    label: 'Create Initials',
  },
  {
    code: '3',
    label: 'Remove',
  },
];

const UserAvatarSettings = props => {
  const [isOpen, setIsOpen] = useState(false);

  const { userId, handleCloseModal, handleOpenModal, deleteProfileImage } = props;

  const handleDropdown = () => setIsOpen(!isOpen);

  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(e) {
      if ((dropdownRef?.current && !dropdownRef.current.contains(e.target))
        && (buttonRef?.current && !buttonRef.current.contains(e.target))) {
        e.preventDefault();
        buttonRef.current.blur();
        handleDropdown();
      }
    }
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [isOpen]);

  const handleEditedProfile = canvas => {
    const type = 'image/png';
    canvas.toBlob(blob => {
      const file = new File([blob], `${userId}.png`, { type });

      const formData = new FormData();
      formData.append('avatar', file);
      props.uploadProfileImage(formData);
    }, type);
  };

  const handleProfileEditOption = e => {
    e.preventDefault();
    const { dataset: { id } } = e.target;
    switch (id) {
      case '1':
        handleOpenModal({
          content: <UploadProfileModal
            handleClose={handleCloseModal}
            handleEditedProfile={handleEditedProfile}
          />,
        });
        break;
      case '2':
        handleOpenModal({
          content: <CreateInitialsModal
            handleClose={handleCloseModal}
            defaultInitials={`${props.firstName.charAt(0)}${props.lastName.charAt(0)}`}
            handleSaveInitials={props.createInitials}
          />,
        });
        break;
      case '3':
        handleOpenModal({
          content: <DeleteProfilePhotoModal
            handleClose={handleCloseModal}
            handleConfirm={deleteProfileImage}
          />,
        });
        break;
      default:
        handleDropdown();
        break;
    }
    handleDropdown();
  };

  const renderDropdownSelections = () =>
    options.map(option => (
      <li key={option.code}>
        <Button
          isSmall
          className="w-full hover:text-white hover:bg-slate-400"
          onClick={handleProfileEditOption}
          outlinedColor="white"
          dataID={option.code}
        >
          {option.label}
        </Button>
      </li>
    ));

  return (
    <div className="relative block mx-1">
      <Avatar
        {...props}
        className="border-white min-w-[7rem] min-h-[7rem] border-6"
      />
      <div className="absolute bottom-0 right-0 md:bottom-3 md:right-2">
        <Button
          className="relative float-right w-10 h-10 border-4 hover:bg-slate-50 focus:bg-slate-25"
          ref={buttonRef}
          outlinedColor="white"
          onClick={handleDropdown}
          leadingButtonIcon="edit"
          iconColor="gray"
          isCircle
        />
        {isOpen
          ? (
            <div ref={dropdownRef}>
              <ul className={`absolute z-10 shadow-md border border-[#e5e7eb] flex flex-col w-40 gap-1 p-1 mr-0 list-none transform -translate-x-24 translate-y-12 md:translate-x-8 md:translate-y-10 bg-white rounded-md ${isOpen ? 'opacity-100' : 'opacity-0'} transition-opacity delay-100 duration-300 ease-in-out`}>
                {renderDropdownSelections()}
              </ul>
            </div>
          )
          : null}
      </div>
    </div>
  );
};

UserAvatarSettings.propTypes = {
  userId: PropTypes.string.isRequired,
  uploadProfileImage: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  createInitials: PropTypes.func.isRequired,
  deleteProfileImage: PropTypes.func.isRequired,
};

export default UserAvatarSettings;
