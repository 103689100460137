import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { RouterContext } from '../../../../context/Router';

import SectionContainer from '../../../common/Containers/Section';
import ProfileCardAvatarWithButton from '../../../ProfileCards/ProfileCardAvatarWithButton';
import Card from '../../../Card';
import Button from '../../../common/Button';

import formatDate from '../../../../lib/dateFormatter';

const AssessmentResults = ({
  firstName,
  lastName,
  asmtType,
  userSurvey,
  avatarUrl,
}) => {
  const { router } = useContext(RouterContext);

  if (!userSurvey) return null;

  const isFinished = userSurvey.filter(survey => survey.state === 'finished').length > 0;
  let lastFinishedSurvey = {};
  if (isFinished) {
    lastFinishedSurvey = userSurvey.filter(survey => survey.state === 'finished').shift();
  }

  const isStarted = userSurvey.filter(survey => survey.state === 'started').length > 0;
  const surveyButtonText = isStarted ? 'Resume Survey' : 'Take Survey';

  const displayDescription = isFinished
    ? (
      <><b>Completed: </b>{formatDate(lastFinishedSurvey.updatedAt)}<br />
        <b>Version: </b>{parseFloat(lastFinishedSurvey.version).toFixed(1)}
        {lastFinishedSurvey.userRole === 'leader' && <><br /><b>Leadership Indicator</b></>}
      </>
    )
    : null;

  const resultsCard = {
    avatarUrl,
    displayName: `${firstName} ${lastName}`,
    displayDescription,
  };

  const buttonOptions = {
    confirmText: 'View My Results',
    confirmButton: {
      variant: 'primary',
      to: `/assessments/${asmtType}/results`,
    },
    cancelText: 'Retake Survey',
    cancelButton: {
      variant: 'secondary',
      onClick: () => router.push(`/assessments/${asmtType}/survey`),
    },
  };

  return (
    <SectionContainer title={`Your ${asmtType.toUpperCase()} results`}>
      <div id={`your-${asmtType}-results`}>
        {isFinished
          ? (
            <ProfileCardAvatarWithButton
              {...resultsCard}
              {...buttonOptions}
              memberUserId={userSurvey[0].user}
              avatarUrl={avatarUrl}
            />
          )
          : (
            <Card
              displayName={`${firstName} ${lastName}`}
              avatarUrl={avatarUrl}
            >
              <Button
                to={`/assessments/${asmtType}/survey`}
                isNormalCase
                variant="primary"
              >
                {surveyButtonText}
              </Button>
            </Card>
          )}
      </div>
    </SectionContainer>
  );
};

AssessmentResults.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  asmtType: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string,
  userSurvey: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      id: PropTypes.number,
      userRole: PropTypes.string,
      state: PropTypes.string,
      editSurveyUrl: PropTypes.string,
      version: PropTypes.number,
      completionDate: PropTypes.string,
      languagePreference: PropTypes.string,
      user: PropTypes.string,
      assessment: PropTypes.number,
    }),
  ).isRequired,
};

AssessmentResults.defaultProps = {
  avatarUrl: '',
};

export default AssessmentResults;
