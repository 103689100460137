import React from 'react';

import PropTypes from 'prop-types';

import DashboardIBI from '../DashboardIBI';
import DashboardProfile from '../DashboardProfile';
import DashboardCultures from '../DashboardCultures';
import Block from '../../Block';
import Button from '../../common/Button';

const DashboardBlocks = ({
  currentUser,
  blocks,
  filterValue,
  handleClearFilter,
}) => {
  const coreBlocks = {
    DashboardProfile,
    DashboardCultures,
    DashboardIBI,
  };

  const { connection } = currentUser;

  const renderBlock = block => {
    const Component = coreBlocks[block.internalName];

    return Component
      ? (
        <div key={block.id} className="shadow-profile">
          <Component {...currentUser} {...block} searchTerm={filterValue?.toLowerCase()} />
        </div>
      )
      : (
        <div key={block.id} className="shadow-profile">
          <Block
            connection={connection}
            {...block}
            languages={block.languages}
            directUrl={block.promoDirectUrl || block.externalDirectUrl}
            completionRangeStart={block.moduleRangeStart || block.externalRangeStart}
            completionRangeEnd={block.moduleRangeEnd || block.externalRangeEnd}
          />
        </div>
      );
  };

  return Object.keys(blocks).length === 0 ? (
    <div className="shadow-profile">
      <div className="bg-white rounded-lg">
        <div className="flex flex-col-reverse sm:flex-row gap-7">
          <div className="flex flex-col justify-between w-full px-6 py-4 md:pl-10">
            <div className="py-2 sm:py-10">
              <h3 className="mb-4 font-serif leading-snug tracking-wider text-charcoal-900">
                <span className="underline decoration-rust-500 decoration-2 underline-offset-8 md:underline-offset-[15px]">
                  You
                </span>
                r search didn&apos;t match any results
              </h3>
              <p className="py-2 font-sans text-base font-light md:w-3/5 md:text-sm">
                It looks like the item you&apos;re looking for isn&apos;t here.
                Please alter your search or reach out to support for
                additional assistance.
              </p>
              <div className="flex">
                <Button
                  filledColor="secondary"
                  className="ml-auto whitespace-nowrap"
                  isExtraSmall
                  onClick={handleClearFilter}
                >Return to Dashboard
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (blocks.map(block => renderBlock(block)));
};

DashboardBlocks.propTypes = {
  currentUser: PropTypes.shape({
    accessLevel: PropTypes.arrayOf(PropTypes.string),
    connection: PropTypes.string,
  }).isRequired,
  blocks: PropTypes.arrayOf(PropTypes.shape({
    blockImage: PropTypes.string,
    blockType: PropTypes.number,
    description: PropTypes.string,
    id: PropTypes.number,
    internalName: PropTypes.string,
    languages: PropTypes.arrayOf(PropTypes.string),
    moduleId: PropTypes.number,
    moduleRangeEnd: PropTypes.string,
    moduleRangeStart: PropTypes.string,
    sortOrder: PropTypes.number,
    tags: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string,
    typeName: PropTypes.string,
  })).isRequired,
  filterValue: PropTypes.string.isRequired,
  handleClearFilter: PropTypes.func.isRequired,
};
export default DashboardBlocks;
