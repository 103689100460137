import React from 'react';

const PasswordInputIcon = () => (
  <div className="flex flex-col items-center gap-0.5 mb-5 md:mb-10">
    <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg" className="mb-0.5">
      <path d="M60.2506 30.7195C60.2506 47.286 46.8167 60.7199 30.2503 60.7199C13.6839 60.7199 0.25 47.286 0.25 30.7195C0.25 14.1531 13.6788 0.719238 30.2503 0.719238C46.8218 0.719238 60.2506 14.148 60.2506 30.7195Z" fill="#FF523A" />
    </svg>
    <svg width="81" height="69" viewBox="0 0 81 69" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M39.4127 0.691453L0.324693 66.518C-0.162602 67.3395 0.429113 68.3837 1.38978 68.3837H79.5589C80.5195 68.3837 81.1112 67.3395 80.6239 66.518L41.5359 0.691453C41.0556 -0.116064 39.893 -0.116064 39.4127 0.691453Z" fill="#FF8675" />
    </svg>
  </div>
);

export default PasswordInputIcon;
