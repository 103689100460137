/* eslint-disable react/function-component-definition */
import React from 'react';
import PropTypes from 'prop-types';
import { CookiesProvider } from 'react-cookie';
import { addLocaleData, IntlProvider } from 'react-intl';
import Helmet from 'react-helmet';

import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import i18nConfig from '../../../translations/en';
import CookieNotificationOverlay from '../Notifications/CookieNotificationOverlay';

import RouterProvider from '../../context/Router';
import CurrentUserProvider from '../../context/CurrentUser';
import APIProvider from '../../context/API';
import NotificationProvider from '../../context/Notification';
import LanguageProvider from '../../context/Language';
import ModalProvider from '../../context/Modal';
import ContextProvider from '../../context/ContextProvider';
import ToastProvider from '../../context/Toast';

import MainLayout from '../Layouts/MainLayout';
import GlobeSmartDialog from '../GlobeSmartDialog';
import Toasts from '../Toasts';

addLocaleData([...en, ...es]);

// if you need to exlude a route, add the `exclude` property as
// an array of routes to exclude
const sideLayouts = [
  { path: '/account-settings', layoutWidth: 'lg' },
  { path: '/assessments', exclude: ['/survey'] },
  { path: '/invitations' },
  { path: '/admin-center', layoutWidth: 'lg' },
  { path: '/dashboard', layoutWidth: '2xl', backToTop: true, exclude: ['/administration'] },
  { path: '/comparison/new', layoutWidth: 'xl' },
  { path: '/aperian-live', layoutWidth: 'xl', exclude: ['/administration'] },
  { path: '/cohorts' },
  { path: '/comparison', bgColor: 'white' },
  { path: '/copilot', layoutWidth: 'sm' },
  { path: '/guides', layoutWidth: 'lg' },
  { path: '/teams/' },
  { path: '/teams', bgColor: 'white' },
  { path: '/profile', exclude: ['/survey/', '/comparison'] },
  { path: '/learning-modules', bgColor: 'white', layoutWidth: 'xl' },
  { path: '/results', backToTop: true },
  { path: '/blocks', exclude: ['/dashboard'] },
  { path: '/skills', layoutWidth: 'xl' },
];

const Layout = ({ location, children }) => {
  const layout = sideLayouts.find(sideLayout => {
    if (sideLayout.exact) {
      return location.pathname === sideLayout.path;
    }
    if (location.pathname.includes(sideLayout.path) && sideLayout.exclude) {
      return !sideLayout.exclude.some(excluded => location.pathname.includes(excluded));
    }

    return location.pathname.includes(sideLayout.path);
  });

  return layout ? (
    <MainLayout location={location} {...layout}>
      {children}
    </MainLayout>
  ) : (children);
};

Layout.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  children: PropTypes.element,
};
Layout.defaultProps = {
  children: null,
};

export default function App(props) {
  /**
   *  Render the children inside the CookieProvider only if we're in the browser
   *  and won't have cookies from the server.
   *
   *  @return {DomNode]} Rendered JSX
   */
  const needsCookies = () => {
    const contents = (
      <>
        <Helmet>
          <title>Aperian</title>
          <body className="" />
        </Helmet>
        <RouterProvider>
          <CurrentUserProvider>
            <APIProvider>
              <NotificationProvider>
                <LanguageProvider>
                  <ModalProvider>
                    <ToastProvider location={props.location}>
                      <ContextProvider>
                        <GlobeSmartDialog />
                        <Layout location={props.location}>
                          {props.children}
                        </Layout>
                        <Toasts />
                      </ContextProvider>
                    </ToastProvider>
                  </ModalProvider>
                </LanguageProvider>
              </NotificationProvider>
            </APIProvider>
          </CurrentUserProvider>
        </RouterProvider>
        <CookieNotificationOverlay />
      </>
    );

    if (typeof window === 'object') {
      return (
        <CookiesProvider>
          {contents}
        </CookiesProvider>
      );
    }

    return contents;
  };

  return (
    <IntlProvider locale={i18nConfig.locale} messages={i18nConfig.messages}>
      {needsCookies()}
    </IntlProvider>
  );
}

App.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  children: PropTypes.element,
};
App.defaultProps = {
  children: null,
};
