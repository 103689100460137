import React, { useState, useEffect, useContext } from 'react';

import PropTypes from 'prop-types';

import { APIContext } from '../../../../context/API';

import Button from '../../Button';
import Tooltip from '../../Tooltip';

import { trackViewFullscreen } from '../../../../lib/tracker/profile';

const FullScreenProfile = ({
  fullScreenRef,
  comparables,
}) => {
  const { apiService } = useContext(APIContext);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleFullScreen = async () => {
    if (!isFullScreen) {
      if (fullScreenRef.current) {
        if (fullScreenRef.current.requestFullscreen) {
          fullScreenRef.current.requestFullscreen();
        } else if (fullScreenRef.current.mozRequestFullScreen) { // Firefox
          fullScreenRef.current.mozRequestFullScreen();
        } else if (fullScreenRef.current.webkitRequestFullscreen) { // Chrome, Safari, Opera
          fullScreenRef.current.webkitRequestFullscreen();
        } else if (fullScreenRef.current.msRequestFullscreen) { // IE/Edge
          fullScreenRef.current.msRequestFullscreen();
        }
      }
      setIsFullScreen(true);
      await trackViewFullscreen(apiService, comparables);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) { // Firefox
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) { // Chrome, Safari, Opera
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { // IE/Edge
        document.msExitFullscreen();
      }
      setIsFullScreen(false);
    }
  };

  useEffect(() => {
    const handleFullScreenChange = () => {
      const isCurrentlyFullScreen = !!document.fullscreenElement;
      setIsFullScreen(isCurrentlyFullScreen);

      const targetElem = document.getElementById('comparisonChart');
      if (targetElem) {
        if (isCurrentlyFullScreen) {
          targetElem.classList.add('w-screen');
        } else {
          targetElem.classList.remove('w-screen');
        }
        window.dispatchEvent(new Event('resize'));
      }
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
    document.addEventListener('mozfullscreenchange', handleFullScreenChange);
    document.addEventListener('MSFullscreenChange', handleFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullScreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullScreenChange);
    };
  }, []);

  return (
    <Tooltip
      key="hide-profile"
      onHover
      position="bottom"
      content={isFullScreen ? 'Exit Fullscreen' : 'View Fullscreen'}
    >
      <Button
        className="p-4"
        filledColor="secondary"
        onClick={handleFullScreen}
        leadingButtonIcon={isFullScreen ? 'minimize' : 'expand'}
        iconColor="white"
        isCircle
      />
    </Tooltip>
  );
};

FullScreenProfile.propTypes = {
  fullScreenRef: PropTypes.shape({
    current: PropTypes.shape({
      requestFullscreen: PropTypes.func,
      mozRequestFullScreen: PropTypes.func,
      webkitRequestFullscreen: PropTypes.func,
      msRequestFullscreen: PropTypes.func,
    }),
  }),
  comparables: PropTypes.arrayOf(PropTypes.string),
};

FullScreenProfile.defaultProps = {
  comparables: [],
  fullScreenRef: null,
};

export default FullScreenProfile;
