import { cloneDeep } from 'lodash';

const byName = (a, b) => {
  const a1 = `${a.firstName} ${a.lastName}`.toLowerCase();
  const b1 = `${b.firstName} ${b.lastName}`.toLowerCase();

  if (a1 < b1) return -1;
  if (a1 > b1) return 1;
  return 0;
};

const byTypeAndName = (a, b) => {
  const typeOrder = {
    org: 1,
    team: 2,
    person: 3,
    culture: 3,
  };
  if (typeOrder[a.type] < typeOrder[b.type]) {
    return -1;
  }
  if (typeOrder[a.type] > typeOrder[b.type]) {
    return 1;
  }

  // if types are the same, sort by name alphabetically
  if (a?.shortDisplayName?.toLowerCase() < b?.shortDisplayName?.toLowerCase()) {
    return -1;
  }
  if (a?.shortDisplayName?.toLowerCase() > b?.shortDisplayName?.toLowerCase()) {
    return 1;
  }
  return 0;
};

const markHiddenProfiles = (profiles, max) => {
  const clonedProfiles = cloneDeep(profiles);
  const you = clonedProfiles.shift();
  you.isVisible = true;

  const sortedList = clonedProfiles
    .sort(byTypeAndName)
    .map((profile, index) => ({ ...profile, isVisible: (index + 1 < max) }));

  return [].concat(you, sortedList);
};

const numVisible = profiles => profiles.reduce((count, profile) => {
  if (profile.isVisible) return count + 1;

  return count;
}, 0);

const numHidden = profiles => profiles.length - numVisible(profiles);

export {
  byName,
  markHiddenProfiles,
  numVisible,
  numHidden,
};
