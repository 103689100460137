import PropTypes from 'prop-types';

const item = PropTypes.shape({
  title: PropTypes.string.isRequired,
  positioningStatement: PropTypes.string.isRequired,
  tendencyHeading: PropTypes.string.isRequired,
  differenceCTA: PropTypes.string,
  tendencies: PropTypes.arrayOf(PropTypes.string).isRequired,
  styleSwitchingLink: PropTypes.string,
});

const ProfileDimensionItemPropTypes = {
  you: item.isRequired,
  other: item,
  conflictVideoID: PropTypes.string,
  conflictVideoSentence: PropTypes.string,
};

const propTypes = {
  profile: PropTypes.shape({
    shortDisplayName: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    avatarUrl: PropTypes.string,
    grid: PropTypes.shape({
      egalitarianism_status: PropTypes.number,
      direct_indirect: PropTypes.number,
      independent_interdependent: PropTypes.number,
      task_relationship: PropTypes.number,
      risk_certainty: PropTypes.number,
    }),
    profileDetails: PropTypes.shape({
      summaryContent: PropTypes.string.isRequired,
      dimensions: PropTypes.arrayOf(PropTypes.shape(ProfileDimensionItemPropTypes)).isRequired,
    }),
  }),
};

const defaultProps = {
  profile: {
    shortDisplayName: null,
    firstName: '',
    lastName: '',
    avatarUrl: '',
    grid: {
      egalitarianism_status: -1,
      direct_indirect: -1,
      independent_interdependent: -1,
      task_relationship: -1,
      risk_certainty: -1,
    },
    profileDetails: {
      summaryContent: '',
      dimensions: [],
    },
  },
};

export {
  propTypes,
  defaultProps,
};
