import React from 'react';
import PropTypes from 'prop-types';

import BackArrowLink from '../../../components/common/BackArrowLink';

const IbiThankYou = ({ answerText, detailsText, productDisplayName, productCode }) => {
  let link = '';

  switch (productDisplayName) {
    case 'Inclusive Behaviors Inventory':
      link = `/assessments/${productCode}/results`;
      break;
    default:
      link = `/assessments/${productCode}/results`;
  }

  return (
    <>
      <div className="m-2 mb-2 text-left text-uppercase text-md">
        <div className="flex mx-0 mt-0 mb-3 text-center align-self-start text-uppercase text-md">
          <BackArrowLink to={link}>
            Back to {productDisplayName}
          </BackArrowLink>
        </div>
      </div>
      <div className="flex flex-col justify-around px-8 py-6 mx-auto mb-12 bg-white md:px-12 md:py-10 rounded-3xl">
        <h1 className="mb-10 text-center md:mb-12">
          Thank you for your selection!
        </h1>
        <h3 className="mb-4 md:mb-6">
          Your Priority Dimension Behavior
        </h3>
        <h4 className="mb-8 text-2xl text-center md:mb-10 md:text-3xl text-rust-500">
          <b>{answerText}</b>
        </h4>
        <h3 className="mb-4 md:mb-6">
          About Your Choice
        </h3>
        <p className="mb-8 text-lg leading-loose md:mb-10">
          {detailsText}
        </p>
        <p className="leading-loose">
          <em>
            Check your email for more practical tips to help you work on this inclusive behavior.
          </em>
        </p>
      </div>
    </>
  );
};

IbiThankYou.propTypes = {
  answerText: PropTypes.string.isRequired,
  detailsText: PropTypes.string.isRequired,
  productDisplayName: PropTypes.string.isRequired,
  productCode: PropTypes.string.isRequired,
};

export default IbiThankYou;
