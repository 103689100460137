import React, { useContext, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import withServerSideData from '../../../HOC/withServerSideData';
import { APIContext } from '../../../context/API';

import Button from '../../common/Button';
import AperianLiveTopicsTable from './AperianLiveTopicsTable';
import { saveSessionTopicAction, updateSessionTopicAction } from '../../../actions/aperianLive';

const AperianLiveAdmin = ({ initialData: {
  sessionsTopicsData,
  preworkOptions,
  tagOptions,
} }) => {
  const [initialSessions, setInitialSessions] = useState(sessionsTopicsData);
  const [editableSessions, setEditableSessions] = useState(sessionsTopicsData?.map(session => ({
    ...session,
    isEditing: false,
  })));

  const { apiService } = useContext(APIContext);

  const tagsRef = useRef(null);
  const preworkRef = useRef(null);

  const handleChange = (name, value) => {
    const [id, property] = name.split('-');
    const updatedSessions = editableSessions
      .map(session => (session.id === +id ? { ...session, [property]: value } : session));
    setEditableSessions(updatedSessions);
  };

  const handleEditState = async id => {
    const originalSession = initialSessions.find(session => session.id === id);
    const updatedSession = editableSessions.find(session => session.id === id);
    const data = {
      name: updatedSession.name,
      description: updatedSession.description,
      duration: updatedSession.duration,
      tags: updatedSession.tags,
      prework: updatedSession.prework,
    };

    if (!originalSession) {
      await saveSessionTopicAction(apiService, data);
      const { sessionsTopicsData: updatedSessionTopics } = await apiService.get('cohort-events/admin-dashboard');
      setInitialSessions(updatedSessionTopics);
      setEditableSessions(updatedSessionTopics?.map(session => (session.id === id
        ? { ...session, isEditing: !session.isEditing }
        : session)));
      return;
    }
    const namesMatch = originalSession.name === updatedSession.name;
    const descriptionsMatch = originalSession.description === updatedSession.description;
    const durationsMatch = originalSession.duration === updatedSession.duration;
    const tagsMatch = JSON.stringify(originalSession.tags) === JSON.stringify(updatedSession.tags);
    const preworkMatch = JSON.stringify(originalSession.prework)
     === JSON.stringify(updatedSession.prework);

    if (!namesMatch || !descriptionsMatch || !durationsMatch || !tagsMatch || !preworkMatch) {
      await updateSessionTopicAction(apiService, id, data);
    }

    const updatedSessions = editableSessions
      .map(session => (session.id === id
        ? { ...session, isEditing: !session.isEditing }
        : session));
    setEditableSessions(updatedSessions);
  };

  const handleAddTopic = () => {
    const updatedSessions = [...editableSessions, {
      id: editableSessions.length + 1,
      name: '',
      descripton: '',
      duration: '',
      tags: [],
      prework: [],
      updatedAt: new Date(),
      isEditing: true,
    }];
    window.scroll({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
    setEditableSessions(updatedSessions);
  };

  const handleAutoSuggestChange = (event, id) => {
    const { target: { name, value } } = event;
    const updatedSessions = editableSessions
      .map(session => (session.id === +id ? { ...session, [name]: value } : session));
    setEditableSessions(updatedSessions);
  };

  const handleRemoveTag = (value, id) => {
    const updatedSessions = editableSessions.map(session =>
      (session.id === +id ? { ...session,
        tags:
        session.tags.filter(tag => tag.value !== value) } : session));
    setEditableSessions(updatedSessions);
  };

  const handleRemovePrework = (value, id) => {
    const updatedSessions = editableSessions.map(session =>
      (session.id === +id ? { ...session,
        prework:
        session.prework.filter(prework => prework.value !== value) } : session));
    setEditableSessions(updatedSessions);
  };

  return (
    <div className="box-border breakout">
      <div className="flex justify-end mb-4">
        <Button
          isExtraSmall
          variant="primary"
          leadingButtonIcon="plus"
          onClick={handleAddTopic}
        >
          Create New Topic
        </Button>
      </div>
      <div className="md:overflow-x-auto">
        <AperianLiveTopicsTable
          sessionTopicsData={editableSessions}
          handleChange={handleChange}
          handleEditSave={handleEditState}
          preworkOptions={preworkOptions}
          tagOptions={tagOptions}
          tagsRef={tagsRef}
          preworkRef={preworkRef}
          handleSuggestChange={handleAutoSuggestChange}
          handleRemoveTag={handleRemoveTag}
          handleRemovePrework={handleRemovePrework}
        />
      </div>
    </div>
  );
};

AperianLiveAdmin.getAPIDataKey = () => 'aperianLiveAdmin';
AperianLiveAdmin.getData = apiService => apiService.get('cohort-events/admin-dashboard')
  .then(aperianLiveAdmin => ({ aperianLiveAdmin }));

AperianLiveAdmin.propTypes = {
  initialData: PropTypes.shape({
    sessionsTopicsData: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        description: PropTypes.string,
        duration: PropTypes.number,
        tags: PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.number,
            name: PropTypes.string,
          }),
        ),
        prework: PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.number,
            name: PropTypes.string,
          }),
        ),
      })).isRequired,
    preworkOptions: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.number,
      })).isRequired,
    tagOptions: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.number,
      })).isRequired,
  }).isRequired,
};

export default withServerSideData(AperianLiveAdmin);
