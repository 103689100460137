let Rollbar;
let nodeRollbar = () => { };

if (typeof window === 'undefined') {
  Rollbar = require('rollbar'); // eslint-disable-line global-require

  nodeRollbar = new Rollbar({
    accessToken: process.env.ROLLBAR_SERVER,
    handleUncaughtExceptions: true,
    handleUnhandledRejections: true,
    environment: process.env.APG_ENV,
  });
}

const rollbar = typeof window === 'undefined' ? nodeRollbar : window?.Rollbar;

const log = name => (message, err) => {
  if (rollbar && typeof rollbar[name] === 'function') {
    rollbar[name](message, err);
  }

  if (typeof console[name] !== 'function') {
    console.log(`${name.toUpperCase()}: ${message}`, err);
  } else {
    console[name](message, err);
  }
};

const logger = {
  critical: log('critical'),
  error: log('error'),
  warn: log('warn'),
  info: log('info'),
  debug: log('debug'),
};

export default logger;

export {
  nodeRollbar,
};
