import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../common/Icon';
import Avatar from '../../../common/Avatars/Avatar';

const SessionsDetailsContainerSubcontainer = ({
  date,
  time,
  duration,
  facilitator,
  facilitatorAvatarUrl,
  prework,
}) => (
  <div className="flex flex-col flex-grow gap-2 p-4 rounded-lg md:p-6 bg-ivory-400">
    <div className="flex items-center gap-2 italic">
      <Icon icon="calendar" iconColor="dark-gray" />
      <p className="mb-0 text-sm font-medium text-slate-900 md:text-base">
        {date}
      </p>
      <p className="mb-0 text-sm font-medium text-slate-900 md:text-base">
        {time}
      </p>
    </div>
    <div className="flex items-center gap-2 italic">
      <Icon icon="clock" iconColor="dark-gray" />
      <p className="mb-0 text-sm font-medium text-slate-900 md:text-base">
        {duration} minutes
      </p>
    </div>
    <div className="flex flex-col justify-between gap-4 md:gap-0">
      {prework.length > 0 ? (
        <div className="flex flex-col gap-2 mt-2">
          <p className="mb-0 text-base font-medium text-charcoal-900">
            Prework
          </p>
          {prework.map(work => (
            <div className="flex gap-2" key={prework.indexOf(work)}>
              <Icon className="w-4 h-4" icon="quarter-pie" iconColor="gray" />
              <p className="mb-0 text-sm text-slate-900">
                {work}
              </p>
            </div>
          ))}
        </div>
      ) : null}
      <div className="flex items-center gap-2 mt-4">
        <Avatar
          className="w-12 h-auto rounded-full bg-gray-50"
          avatarImgAlt={facilitator}
          avatarUrl={facilitatorAvatarUrl}
          isNavigation
        />
        <p className="mb-0 ml-0 text-base italic md:ml-1 lg:ml-0">
          {facilitator}
        </p>
      </div>
    </div>
  </div>
);

SessionsDetailsContainerSubcontainer.propTypes = {
  date: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  facilitator: PropTypes.string.isRequired,
  facilitatorAvatarUrl: PropTypes.string.isRequired,
  prework: PropTypes.arrayOf(PropTypes.string),
};

SessionsDetailsContainerSubcontainer.defaultProps = {
  prework: [],
};

export default SessionsDetailsContainerSubcontainer;
