class AccessCodesDownloadAction {
  constructor(apiService) {
    this.apiService = apiService;
  }

  execute(accessCode) {
    return this.apiService.get(`access-codes/csv/${accessCode}`);
  }
}

export default AccessCodesDownloadAction;
