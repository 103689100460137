import React from 'react';
import PropTypes from 'prop-types';
import OrgTeamIcon from '../icons/OrgTeamIcon';
import StudentIcon from '../icons/StudentIcon';
import { trackOnboardingSelectUseCase } from '../../../../lib/tracker/onboarding';

const SelectUseCase = ({ nextStep }) => {
  const onSelectOption = e => {
    e.preventDefault();
    const { dataset: { id } } = e.currentTarget;
    trackOnboardingSelectUseCase(id);
    switch (id) {
      case 'Student':
        window.location.href = 'https://aperian.zendesk.com/hc/en-us/articles/18926425773971-Educational-Licensing-';
        break;
      case 'Personal':
        window.location.href = '/login';
        break;
      case 'Organization':
        nextStep();
        break;
      default:
    }
  };

  return (
    <>
      <h1 className="mb-5 font-serif text-4xl tracking-wider text-charcoal-900 md:text-6xl md:mb-6">What brings you to <em className="tracking-wider text-rust-500">Aperian?</em></h1>
      <div className="grid w-full grid-cols-1 gap-6 px-10 mx-auto mb-5 md:w-5/6 md:grid-cols-6">
        <button
          className="grid items-center justify-center grid-rows-2 p-4 overflow-hidden rounded shadow-xl cursor-pointer hover:shadow-2xl group md:col-start-2 md:col-span-2"
          onClick={onSelectOption}
          data-id="Organization"
          tabIndex={0}
        >
          <div className="flex justify-center items-center h-[85%]">
            <OrgTeamIcon
              className1="fill-[#c8c7c6] group-hover:fill-rust-100"
              className2="fill-[#767676] group-hover:fill-rust-500"
            />
          </div>
          <p className="text-gray-400 font-sans group-hover:text-charcoal-900 font-normal text-center m-0 text-base lg:text-xl !leading-snug tracking-wider">
            I am considering Aperian for my <span className="text-charcoal-900">work.</span>
          </p>
        </button>
        <button
          className="grid items-center justify-center grid-rows-2 p-4 overflow-hidden rounded shadow-xl cursor-pointer hover:shadow-2xl group md:col-span-2"
          onClick={onSelectOption}
          data-id="Student"
          tabIndex={0}
        >
          <div className="flex justify-center items-center h-[85%]">
            <StudentIcon
              className1="fill-[#6b6b6b] group-hover:fill-rust-500"
              className2="fill-[#c8c7c6] group-hover:fill-rust-100"
            />
          </div>
          <p className="text-gray-400 font-sans group-hover:text-charcoal-900 font-normal text-center m-0 text-base lg:text-xl !leading-snug tracking-wider">
            I am a <span className="text-charcoal-900">student or professor.</span>
          </p>
        </button>
      </div>
      <button
        className="flex m-6 mx-auto font-sans text-base font-semibold cursor-pointer text-rust-700 p-025 hover:underline"
        onClick={onSelectOption}
        data-id="Personal"
      >
        Not interested in organization-wide access? Let&apos;s set up your personal Aperian account.
      </button>
    </>
  );
};

SelectUseCase.propTypes = {
  nextStep: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    invitationToken: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
};

export default SelectUseCase;
