/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable import/no-cycle */
import React, { Component } from 'react';
import { find, forEach } from 'lodash';
import ElementQuery from 'react-eq';

import { defaultProps, propTypes } from './types';

import Avatars from './Avatars';
import ProfileItems from './ProfileItems';
import TraitLines from './TraitLines';
import DimensionLink from './DimensionLink';
import FullScreenProfile from './FullScreenProfile';
import HideProfileToggle from './HideProfileToggle';
import HideTeamsToggle from './HideTeamsToggle';
import OrgDisclaimer from './OrgDisclaimer';
import CultureDisclaimer from './CultureDisclaimer';
import DownloadScreenshot from './DownloadScreenshot';
import PrintMessage from './PrintMessage';

import { markHiddenProfiles, numVisible } from '../../../lib/profile-utils';
import ResponsiveAvatars from '../../../lib/responsiveAvatars';
import { trackViewDimension } from '../../../lib/tracker/profile';

const MAXIMUM_AVATARS_TO_BE_SHOWN = 30;
class ProfileChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vectorEffect: '',
      maxAvatars: 10,
      query: props.location ? props.location.query : {},
      selectedAvatar: null,
      profiles: props.profiles,
      positions: {},
    };
    this.isComponentMounted = false;
  }

  componentDidMount() {
    this.isComponentMounted = true;
    this.setState({
      vectorEffect:
        document.documentElement.style.vectorEffect === undefined
          ? ' no-vector-effect'
          : ' has-vector-effect',
    });
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  componentWillReceiveProps(nextProps) {
    // Make sure we update which profiles are isVisible on new props
    const profiles = markHiddenProfiles(nextProps.profiles, this.state.maxAvatars);
    this.setState({ profiles });
  }

  avatarContainerRef = el => {
    this.setState({ container: el }, () => {
      // eslint-disable-next-line react/no-unused-class-component-methods
      this.responsiveAvatars = new ResponsiveAvatars({
        container: this.state.container,
        callback: num => {
          const profilesToShow = Math.min(num, MAXIMUM_AVATARS_TO_BE_SHOWN);
          if (this.isComponentMounted) {
            this.setState({ maxAvatars: profilesToShow }, () => {
              const profiles = markHiddenProfiles(
                this.state.profiles,
                this.state.maxAvatars,
              );
              this.setState({ profiles });
            });
          }
        },
      });
    });
  };

  /**
   *  Receive avatar positions from child components and store the values in state
   *  @param {Object} data
   */
  avatarPositions = data => {
    if (this.isComponentMounted) {
      const updatedPositions = this.state.positions;

      forEach(data, (position, id) => {
        updatedPositions[id] = {
          avatar: data[id],
          traitLine: updatedPositions[id] ? updatedPositions[id].traitLine : null,
        };
      });

      this.setState({ positions: updatedPositions });
    }
  };

  /**
   *  Receive traitline positions from child components and store the values in state
   *  @param {Object} data
   */
  traitLinePositions = (id, newPosition) => {
    if (typeof newPosition === 'number') {
      const updatedPositions = this.state.positions;

      if (!updatedPositions[id]) {
        updatedPositions[id] = {};
      }

      updatedPositions[id].traitLine = newPosition;
      this.setState({ positions: updatedPositions });
    }
  };

  handleAvatarClick = selectedEl => {
    if (this.state.selectedAvatar === selectedEl.props.index) {
      this.setState({ selectedAvatar: null });
    } else {
      this.setState({ selectedAvatar: selectedEl.props.index });
    }
  };

  isTeamOwnerHidden = () =>
    this.props.teamInformation
    && this.props.teamInformation.isOwner
    && !this.props.teamInformation.isMember;

  updateSelectedProfiles = (list, callback) => {
    if (numVisible(list) >= this.state.maxAvatars) {
      this.setState({ tooManyComparisonObjects: true });
      return false;
    }

    this.setState({ tooManyComparisonObjects: false });

    const newList = this.state.profiles.map(profile => {
      const updatedProfile = find(list, { id: profile.id });
      // eslint-disable-next-line no-param-reassign
      if (updatedProfile) profile.isVisible = updatedProfile.isVisible;

      return profile;
    });

    this.setState({ profiles: newList }, () => {
      if (typeof callback === 'function') callback();
    });

    return true;
  };

  chartClasses() {
    const isCompareProfile = this.props.compareProfile ? 'pb-1' : '';
    const hasMultipleAvatars = this.props.profiles.length > 1 ? 'has-multiple-avatars' : '';
    const avatarSelected = this.state.selectedAvatar !== null
      ? `has-selected-profile selected-profile-1 selected-profile-${this.state.selectedAvatar + 1
      }`
      : '';
    const background = this.props.bgColor ? this.props.bgColor : '';
    const hideCurrentUser = this.isTeamOwnerHidden() ? 'hide-current-user' : '';
    const adviceComparison = this.props.isAdviceComparison ? 'comparison-print-layout' : '';
    return `profile__chart-container ${this.state.vectorEffect} ${hasMultipleAvatars} ${avatarSelected} ${hideCurrentUser} ${isCompareProfile} ${adviceComparison} ${background}`;
  }

  toggleTeamDisplay = (index, property, teamId, queryKey) => {
    const { updateProfiles } = this.props;
    const { query } = this.state;

    const params = Array.isArray(query[queryKey])
      ? query[queryKey]
      : [query[queryKey]];

    const isTeamInQuery = params.includes(teamId);

    const updatedQueryValue = isTeamInQuery
      ? params.filter(q => q !== teamId)
      : [teamId, ...params];

    const newQuery = { ...query, [queryKey]: updatedQueryValue };

    const teamProfiles = [...this.props.teamProfiles];
    const currentPropertyValue = teamProfiles[index][property];
    const teamProfile = { ...teamProfiles[index], [property]: !currentPropertyValue };
    teamProfiles[index] = teamProfile;

    this.setState({ query: newQuery }, () => updateProfiles(newQuery, teamProfiles));
  };

  // eslint-disable-next-line class-methods-use-this
  trackViewDimensionClick = async () => {
    const moduleName = 'Understanding the GlobeSmart Dimensions';
    await trackViewDimension(moduleName);
  };

  render() {
    return (
      <>
        <ElementQuery
          queries={{
            'chart-medium': 768,
            'chart-large': 1024,
          }}
        >
          <div className={this.chartClasses()} id={this.props.compareProfile ? 'comparisonChart' : 'adviceChart'}>
            <Avatars
              profiles={this.state.profiles}
              positions={this.state.positions}
              onAvatarPositionsChanged={this.avatarPositions}
              avatarContainerRef={this.avatarContainerRef}
              maxAvatars={this.state.maxAvatars}
              showControls={this.props.showControls}
              handleAvatarClick={this.handleAvatarClick}
              selectedAvatar={this.state.selectedAvatar}
              updateSelectedProfiles={this.updateSelectedProfiles}
              tooManyComparisonObjects={this.state.tooManyComparisonObjects}
              hasResizeSupport={this.props.hasResizeSupport}
              location={this.props.location}
              compareProfile={this.props.compareProfile}
              myProfileVisibility={((!this.isTeamOwnerHidden())) ? this.props.myProfileVisibility : 'hidden'}
              isDashboard={this.props.isDashboard}
              isHistoryProfile={this.props.isHistoryProfile}
              haveProfilesChanged={this.state.haveProfilesChanged}
              responsiveAvatars={this.responsiveAvatars}
              withBorder={this.props.withBorder}
            />
            <div className="profile__chart z-100">
              <ProfileItems
                dimensionLabelGroups={this.props.dimensionLabelGroups}
                isDashboard={this.props.isDashboard}
              />
              <TraitLines
                profiles={this.state.profiles}
                compareProfile={this.props.compareProfile}
                myProfileVisibility={this.props.myProfileVisibility}
                hasResizeSupport={this.props.hasResizeSupport}
                traitLinePositions={this.traitLinePositions}
              />
            </div>
          </div>
        </ElementQuery>
        {this.props.displayDimensionLink ? (
          <DimensionLink
            isProfile={this.props.isProfile}
            trackViewDimensionClick={this.trackViewDimensionClick}
          />
        ) : null}
        {this.props.compareProfile ? (
          <>
            <div className="block px-4 py-4 mt-6 md:mt-4 lg:mt-0 md:px-6 lg:px-8 print:hidden">
              <div className="relative flex items-center justify-center gap-4">
                <DownloadScreenshot
                  compareProfile
                  userName={this.state.profiles?.length
                    ? this.state.profiles[0].shortDisplayName
                    : null}
                  downloadLocation={this.props.downloadLocation}
                />
                <HideProfileToggle
                  isTeamOwnerHidden={this.isTeamOwnerHidden}
                  isHistoryProfile={this.props.isHistoryProfile}
                  isHistoryHidden={this.props.isHistoryHidden}
                  onClick={this.props.onClick}
                  hideProfileToggleText={this.props.hideProfileToggleText}
                />
                <FullScreenProfile
                  fullScreenRef={this.props.fullScreenRef}
                  comparables={this.props.profiles.map(profile => profile.shortDisplayName)}
                />
              </div>
              <HideTeamsToggle
                teamProfiles={this.props.teamProfiles}
                location={this.props.location}
                toggleTeamDisplay={this.toggleTeamDisplay}
              />
            </div>
            <div className="flex flex-col gap-4 md:gap-2.5 mb-10">
              <OrgDisclaimer hasOrg={this.props.hasOrg} profiles={this.props.profiles} />
              <CultureDisclaimer
                hasCulture={this.props.hasCulture}
                cultureDisclaimer={this.props.cultureDisclaimer}
                cultureUrl={this.props.cultureUrl}
                cultureLinkText={this.props.cultureLinkText}
              />
              <PrintMessage compareProfile={this.props.compareProfile} />
            </div>
          </>
        )
          : null}
      </>
    );
  }
}

ProfileChart.propTypes = propTypes;
ProfileChart.defaultProps = defaultProps;

export default ProfileChart;
export { ProfileChart as Component };
export { propTypes, defaultProps };
