import PropTypes from 'prop-types';

const propTypes = {
  selectedOption: PropTypes.number,
  isLast: PropTypes.bool.isRequired,
  currentQuestion: PropTypes.number.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  isOnboarding: PropTypes.bool,
};

const defaultProps = {
  selectedOption: null,
  onBack: null,
  isOnboarding: false,
};

export {
  propTypes,
  defaultProps,
};
