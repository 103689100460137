/* eslint-disable react/function-component-definition */
import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';

import { COOKIE_LANGUAGE, IS_PRODUCTION, translations } from '../../lib/constants';
import validateLanguageForContext from '../../lib/language';

export const LanguageContext = createContext({});

export default function LanguageProvider({ children }) {
  const [cookies, setCookies] = useCookies();
  const initialLanguage = validateLanguageForContext(
    translations,
    cookies[COOKIE_LANGUAGE],
    'profile',
  );
  const [selectedLanguage, setSelectedLanguage] = useState(initialLanguage);

  const setLanguageCookie = language => {
    const date = new Date();
    date.setDate(date.getDate() + 365);
    return new Promise(resolve => {
      resolve(setCookies(COOKIE_LANGUAGE, language, { expires: date, path: '/', secure: IS_PRODUCTION }));
    });
  };

  const onChangeLanguage = code => setLanguageCookie(code)
    .then(() => setSelectedLanguage(code));

  return (
    <LanguageContext.Provider
      value={{
        selectedLanguage,
        onChangeLanguage,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
}

LanguageProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const LanguageConsumer = LanguageContext.Consumer;
