import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { differenceInCalendarDays } from 'date-fns';
import { APIContext } from '../../../context/API';
import { trackViewBannerResource } from '../../../lib/tracker/dashboard';

import { sendHubspotEventDataAction } from '../../../actions/hubspotTracker';

const eventData = {
  eventName: 'HUBSPOT_CLICKED_TRIAL_BANNER',
  properties: {},
};

const handleContactUsButtonClick = apiService => {
  trackViewBannerResource('Trial Contact Form')
    .then(sendHubspotEventDataAction(apiService, eventData),
    );
};

const DashboardAnnouncement = ({
  trialEndDate,
}) => {
  const { apiService } = useContext(APIContext);
  const remainingTrialDays = differenceInCalendarDays(new Date(trialEndDate), new Date());
  const moreThanOneDayLeft = remainingTrialDays > 1;
  const zeroDaysLeft = remainingTrialDays === 0;

  return (
    <div className="flex flex-col items-center justify-center w-full py-3 m-auto font-sans font-medium text-center text-white full-width bg-charcoal-900">
      <div className="px-6 text-sm font-normal md:text-base">
        {remainingTrialDays < 0
          ? 'Your trial has ended.'
          : `There ${moreThanOneDayLeft || zeroDaysLeft ? 'are' : 'is'} ${remainingTrialDays} day${moreThanOneDayLeft || zeroDaysLeft ? 's' : ''} remaining in your trial.`}
        <a className="no-underline text-rust-500" rel="noopener noreferrer" target="_blank" href="https://aperian.com/trial-contact/" onClick={() => handleContactUsButtonClick(apiService)}> Contact us</a> for help or to chat with our team.
      </div>
    </div>
  );
};

DashboardAnnouncement.propTypes = {
  trialEndDate: PropTypes.string.isRequired,
};

DashboardAnnouncement.defaultProps = {};

export default DashboardAnnouncement;
