import React from 'react';
import getQuery from '../../../lib/urls/getQuery';

import BodyWrapper from '../../BodyWrapper';
import AperianWordmark from '../../AperianWordmark';
import Button from '../../common/Button';
import RustDividingLine from '../../common/RustDividingLine';

const ForgotPasswordSuccess = props => {
  const emailEntered = getQuery('email', props);
  return (
    <BodyWrapper documentTitle="Password Assistance | Aperian">
      <div className="flex items-center justify-center mb-6">
        <AperianWordmark />
      </div>
      <div className="px-5 md:px-0">
        <h1 className="mb-2 text-2xl font-normal font-headline">Password Assistance</h1>
        <RustDividingLine />
        <p className="mt-2 mb-0 font-sans text-xs font-normal">If the e-mail address you entered
          {emailEntered ? ` - ${emailEntered} - ` : ' '}
          is associated with an account in our system, you will receive an e-mail from us
          with instructions for resetting your password.
        </p>
      </div>
      <Button to="/login" variant="primary">Return to Login</Button>
      <p className="mb-0 font-sans text-xs font-normal text-center">
        If you don&apos;t receive this e-mail, please check your junk mail folder
        or contact <a href="mailto:support@aperian.com" className="mb-0 underline text-rust-500">support@aperian.com</a> for further assistance.
      </p>
    </BodyWrapper>
  );
};

export default ForgotPasswordSuccess;
