import Feature from '../Feature';
import AnyCriteria from '../criteria/any';
import ProUserCriteria from '../criteria/pro_user';
import PremiumUserCriteria from '../criteria/premium_user';

export default class TopicalAdviceFeature extends Feature {
  constructor(user) {
    const proUser = new ProUserCriteria(user);
    const premiumUser = new PremiumUserCriteria(user);
    const any = new AnyCriteria(proUser, premiumUser);

    super(any);
  }
}
