import React from 'react';
import PropTypes from 'prop-types';
import BodyWrapper from '../BodyWrapper';
import Footer from '../common/Footer';
import SiteHeader from '../SiteHeader';
import AperianWordmark from '../AperianWordmark';
import RustDividingLine from '../common/RustDividingLine';
import Button from '../common/Button';

const ServerError = ({
  pageTitle,
  error,
}) => (
  <div className="flex flex-col min-h-screen">
    {pageTitle && <SiteHeader pageTitle={pageTitle} />}
    <div className="flex-grow">
      <BodyWrapper documentTitle="Error | Aperian">
        <div className="flex items-center justify-center mb-6">
          <AperianWordmark />
        </div>
        <div>
          <h1 className="mb-2 text-2xl font-normal font-headline">Looks like something went wrong.</h1>
          <RustDividingLine />
          <p className="mt-2 mb-0 font-sans text-xs font-normal">Please clear your browser&rsquo;s cookies and cache, then try to log in again</p>
        </div>
        <Button to="/logout" variant="primary">Log in again</Button>
        <div>
          {error && <p>{error.message}</p>}
          <p className="mb-0 font-sans text-xs font-normal text-center">If this issue persists, please contact <a href="mailto:support@aperian.com">Customer Support</a>.</p>
        </div>
      </BodyWrapper>
    </div>
    {pageTitle && <Footer />}
  </div>
);
ServerError.propTypes = {
  pageTitle: PropTypes.string,
  error: PropTypes.shape({
    message: PropTypes.string,
    status: PropTypes.string,
  }),
};

ServerError.defaultProps = {
  error: null,
  pageTitle: '',
};

export default ServerError;
