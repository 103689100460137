import tracker from '../index';

const {
  eventNames,
  eventProps,
  resolveAfterTimeout,
} = tracker;

const trackViewAssessmentHome = (asmtName, asmtType) => {
  tracker.track(eventNames.VIEW_ASSESSMENT_HOME, {
    [eventProps.ASSESSMENT]: `${asmtName} (${asmtType.toUpperCase()})`,
  });

  return resolveAfterTimeout;
};

const trackViewDimensionLearningModuleSuccess = (asmtType, moduleName) => {
  if (asmtType === 'ibi') {
    tracker.track(eventNames.VIEW_IBI_LEARNING_MODULE, {
      [eventProps.MODULE_NAME]: moduleName,
    });
  }

  return resolveAfterTimeout;
};

const trackViewIbiResults = () => {
  tracker.track(eventNames.VIEW_IBI_RESULTS);

  return resolveAfterTimeout;
};

const trackDownloadIndividualReport = (asmtName, asmtType) => {
  tracker.track(eventNames.DOWNLOAD_INDIVIDUAL_REPORT, {
    [eventProps.ASSESSMENT]: `${asmtName} (${asmtType.toUpperCase()})`,
  });

  return resolveAfterTimeout;
};

export {
  trackViewDimensionLearningModuleSuccess,
  trackViewAssessmentHome,
  trackViewIbiResults,
  trackDownloadIndividualReport,
};
