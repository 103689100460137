import React, { useContext, useEffect } from 'react';

import { propTypes, defaultTypes } from './types';

import { Context } from '../../context/ContextProvider';

import PageHeader from '../PageHeader';
import Tabs from '../Tabs';

import needsAuthentication from '../../lib/needsAuthentication';

const AccountSettings = ({ children, location }) => {
  const { currentUser, router } = useContext(Context);

  if (!children) {
    router.push('/account-settings/personal');
  }

  const tabList = [
    {
      key: 'account-settings-personal',
      name: 'Personal',
      link: '/account-settings/personal',
    },
    {
      key: 'account-settings-summary',
      name: 'Summary',
      link: '/account-settings/summary',
    },
  ];

  needsAuthentication(router, currentUser);

  useEffect(() => {
    if (!children) {
      router.push('/account-settings/personal');
    }
  }, [children]);

  return (
    <>
      <PageHeader
        layoutWidth="lg"
        location={location}
        pageTitle="Account Settings"
        skipTarget="#tabs-content"
      />
      <div>
        <Tabs tabs={tabList} location={location} />
        {children}
      </div>
    </>
  );
};

AccountSettings.propTypes = propTypes;
AccountSettings.defaultTypes = defaultTypes;

export default AccountSettings;
