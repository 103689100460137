import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../../../../common/Button';

const renderButton = (text, options, userId) => {
  if (!text) return null;

  return (
    <Button {...options} dataID={userId} className="card__button">
      {text}
    </Button>
  );
};

const renderButtonContainer = options => {
  const {
    userId,
    confirmText,
    confirmButton,
    cancelText,
    cancelButton,
  } = options;

  return (
    <div className="card__actions">
      {renderButton(confirmText, confirmButton, userId)}
      {renderButton(cancelText, cancelButton)}
    </div>
  );
};

const OrganizationAddUserButton = props => {
  const [confirmAdd, setConfirmAdd] = useState(false);

  const completeAdd = e => {
    const { dataset: { id } } = e.target;
    props.onAdd(id);
    setConfirmAdd(false);
  };

  function renderAddUserButton({ orgId, id: userId, organizations }) {
    let buttonOptions = { userId };

    if (!organizations.some(org => org.id === orgId)) {
      buttonOptions = Object.assign(buttonOptions, {
        confirmText: 'Add User',
        confirmButton: {
          filledColor: 'primary',
          isNormalCase: true,
          onClick: () => setConfirmAdd(true),
        },
      });
    }

    return renderButtonContainer(buttonOptions);
  }

  function renderConfirmCancel({ id }) {
    const options = {
      userId: id,
      displayName: 'Are you sure you want to add this user?',
      confirmText: 'Yes, Add',
      confirmButton: {
        filledColor: 'red',
        isNormalCase: true,
        onClick: completeAdd,
      },
      cancelText: 'No, Cancel',
      cancelButton: {
        isNormalCase: true,
        isCancel: true,
        onClick: () => setConfirmAdd(false),
      },
    };

    return renderButtonContainer(options);
  }

  return (confirmAdd ? renderConfirmCancel(props) : renderAddUserButton(props));
};

OrganizationAddUserButton.propTypes = {
  onAdd: PropTypes.func.isRequired,
};

export default OrganizationAddUserButton;
