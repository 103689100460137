import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../common/Button';

const AfterTrackSelectExplanation = ({ nextStep, formData }) => {
  const determineMessage = () => {
    switch (formData.track) {
      case 1:
        return (
          <div className="flex flex-wrap justify-center">
            <h1 className="font-serif text-4xl tracking-wider md:!leading-[3.5rem] lg:!leading-[4rem] text-charcoal-900 md:text-5xl lg:text-6xl">
              When learning journeys start with self&#8209;awareness, people
              <span className="italic text-rust-500">
                &nbsp;create inclusion with impact.
              </span>
            </h1>
          </div>
        );
      case 2:
        return (
          <div className="flex flex-wrap justify-center">
            <h1 className="font-serif text-4xl tracking-wider md:!leading-[3.5rem] lg:!leading-[4rem] text-charcoal-900 md:text-5xl lg:text-6xl">
              When learners start with self&#8209;awareness, they can
              <span className="italic text-rust-500">
                &nbsp;go deeper to evolve their behaviors.
              </span>
            </h1>
          </div>
        );
      case 3:
        return (
          <div className="flex flex-wrap justify-center">
            <h1 className="font-serif text-4xl tracking-wider md:!leading-[3.5rem] lg:!leading-[4rem] text-charcoal-900 md:text-5xl lg:text-6xl">
              When learners start with self&#8209;awareness, their
              <span className="italic text-rust-500">
                &nbsp;collaboration across culture grows.
              </span>
            </h1>
          </div>
        );
      case 4:
        return (
          <div className="flex flex-wrap justify-center">
            <h1 className="font-serif text-4xl tracking-wider md:!leading-[3.5rem] lg:!leading-[4rem] text-charcoal-900 md:text-5xl lg:text-6xl">
              When expatriates start with self&#8209;awareness, they are
              <span className="italic text-rust-500">
                &nbsp;better prepared to embrace new cultures.
              </span>
            </h1>
          </div>
        );
      default:
        return (
          <div className="flex flex-wrap justify-center">
            <h1 className="font-serif text-4xl tracking-wider md:!leading-[3.5rem] lg:!leading-[4rem] text-charcoal-900 md:text-5xl lg:text-6xl">
              When learners start with self&#8209;awareness, they can evolve their behaviors and
              <span className="italic text-rust-500">
                &nbsp;create inclusion with impact.
              </span>
            </h1>
          </div>
        );
    }
  };

  return (
    <div className="flex flex-col items-center justify-center gap-4 px-2 md:px-8 lg:px-12 md:max-w-[90%]">
      {determineMessage()}
      <Button
        variant="primary"
        trailingButtonIcon="arrow-right"
        onClick={nextStep}
      >Continue
      </Button>
    </div>
  );
};

AfterTrackSelectExplanation.propTypes = {
  nextStep: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    track: PropTypes.number,
  }).isRequired,
};

export default AfterTrackSelectExplanation;
