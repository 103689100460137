import React, { useContext, useEffect } from 'react';

import PropTypes from 'prop-types';

import { RouterContext } from '../../context/Router';
import { CurrentUserContext } from '../../context/CurrentUser';
import { ModalContext } from '../../context/Modal';
import GlobeSmartDialog from '../GlobeSmartDialog';
import RefreshMessage from '../GlobeSmartDialog/RefreshMessage';

import { SESSION_ALERT_INTERVAL } from '../../lib/constants';

import needsAuthentication from '../../lib/needsAuthentication';
import AssessmentFeature from '../../lib/features/Assessment';
import getLoginUrl from '../../lib/urls/loginUrl';
import determinePlanByType from '../../lib/assessments/determinePlanByType';

const AssessmentAuthorization = ({
  params: { assessment },
  location: { pathname },
  children,
}) => {
  const { router } = useContext(RouterContext);
  const { currentUser } = useContext(CurrentUserContext);
  const { handleOpenDialog } = useContext(ModalContext);

  needsAuthentication(router, currentUser, `redirect_to=/assessments/${assessment}`);

  useEffect(() => {
    const intervalId = window.setInterval(() => {
      /* the survey page has it's own interval to keep the GlobeSmart session alive
          while the user is taking the Alchemer survey, so we don't need <RefreshMessage />
          on that page. */
      if (!pathname.endsWith('/survey')) {
        handleOpenDialog({
          content: <RefreshMessage />,
          afterCloseAction: () => currentUser.refreshAuthToken(),
        });
      }
    }, SESSION_ALERT_INTERVAL);

    return () => clearInterval(intervalId);
  }, []);

  return new AssessmentFeature(currentUser, assessment)
    .positive(() => (
      <>
        <GlobeSmartDialog />
        {children}
      </>
    ))
    .negative(() => {
      if (currentUser && currentUser.isLoggedIn()) {
        const plan = determinePlanByType(assessment);
        const purchaseUrl = `/dashboard?purchase&plan=${plan}&redirect_to=${pathname}`;
        router.replace(purchaseUrl);
      } else {
        const loginUrl = getLoginUrl(location);
        router.replace(loginUrl);
      }
      return null;
    })
    .execute();
};

AssessmentAuthorization.propTypes = {
  params: PropTypes.shape({
    assessment: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.node,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

AssessmentAuthorization.defaultProps = { children: null };

export default AssessmentAuthorization;
