export const getSessionTopics = sessions => {
  const sessionTopicsWithDupes = sessions.map(session => session.name);

  const sessionTopicsArray = [...new Set(sessionTopicsWithDupes)];

  const sessionTopics = sessionTopicsArray.map(topic => {
    const index = sessionTopicsArray.indexOf(topic);
    return {
      name: topic,
      value: index,
    };
  });
  sessionTopics.push({
    name: 'View All Topics',
    value: sessions.length,
  });
  return sessionTopics;
};
