import React, { useContext } from 'react';

import { RouterContext } from '../../../context/Router';
import { CurrentUserContext } from '../../../context/CurrentUser';

import MySkills from '..';
import MySkillsFeature from '../../../lib/features/MySkills';

const MySkillsAuth = () => {
  const { currentUser } = useContext(CurrentUserContext);
  const { router } = useContext(RouterContext);

  return new MySkillsFeature(currentUser)
    .positive(() => (
      <MySkills />
    ))
    .negative(() => {
      router.replace('/404');
      return null;
    })
    .execute();
};

export default MySkillsAuth;
