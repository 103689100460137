import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import LabelInput from '../../../common/LabelInput';
import Button from '../../../common/Button';

import { trackOnboardingStepCompletionName } from '../../../../lib/tracker/onboarding';

const NameInput = ({
  formData: {
    firstName,
    lastName,
    trialOwner,
  },
  handleUpdateForm,
  nextStep,
}) => {
  const [firstNameError, setFirstNameError] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);

  useEffect(() => {
    if (firstName) { setFirstNameError(null); }
    if (lastName) { setLastNameError(null); }
  }, [firstName, lastName]);

  const handleChange = e => handleUpdateForm({ [e.target.name]: e.target.value });

  const handleSubmit = async e => {
    e.preventDefault();

    if (!firstName || !lastName) {
      setFirstNameError(!firstName && 'You must enter your first name.');
      setLastNameError(!lastName && 'You must enter your last name.');
      return;
    }

    await trackOnboardingStepCompletionName(`${firstName} ${lastName}`);
    nextStep();
  };

  return (
    <div>
      <h1 className="m-0 mb-5 font-serif text-4xl tracking-wider text-charcoal-900 md:text-6xl md:mb-6">
        What&apos;s your <em className="tracking-wider text-rust-500">name?</em>
      </h1>
      {trialOwner
        ? (
          <p className="m-0 mb-5 font-sans text-base text-gray-800 md:text-xl md:mb-10">
            You&apos;re about to join {trialOwner}&apos;s free trial of Aperian.
          </p>
        )
        : (
          <p className="m-0 mb-5 font-sans text-base text-gray-800 md:text-xl md:mb-10">
            We&apos;ll use this to <b>personalize your experience.</b>
          </p>
        )}
      <div className="flex w-full max-w-md mx-auto mb-6 md:mb-12 [&>div]:w-full gap-4 md:flex-row flex-col">
        <LabelInput
          id="firstName"
          name="firstName"
          type="text"
          placeholderText="First Name"
          labelType="text"
          value={firstName}
          errorMessage={firstNameError}
          onChangeValue={handleChange}
        />
        <LabelInput
          id="lastName"
          name="lastName"
          type="text"
          placeholderText="Last Name"
          labelType="text"
          value={lastName}
          errorMessage={lastNameError}
          onChangeValue={handleChange}
        />
      </div>
      <div className="flex justify-center">
        <Button
          variant="primary"
          trailingButtonIcon="arrow-right"
          onClick={handleSubmit}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

NameInput.propTypes = {
  nextStep: PropTypes.func.isRequired,
  handleUpdateForm: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    trialOwner: PropTypes.string,
    orgName: PropTypes.string,
  }).isRequired,
};

export default NameInput;
