import tracker from '../index';

const { eventNames, resolveAfterTimeout } = tracker;

const trackTeamProfileActivityViewed = () => {
  tracker.track(eventNames.VIEW_TEAM_PROFILE_ACTIVITY);

  return resolveAfterTimeout;
};

export { trackTeamProfileActivityViewed };
