import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import ElementQuery from 'react-eq';
import withServerSideData from '../../../../HOC/withServerSideData';

import Button from '../../../common/Button';
import DraggableCardList from '../../../DraggableCardList';
import Card from '../../../Card';

import { APIContext } from '../../../../context/API';

import { updateProductSortOrderAction } from '../../../../actions/dashboardBlocks';
import ToggleSwitch from '../../../common/ToggleSwitch';
import { trackProductSorted } from '../../../../lib/tracker/blocks';

const queries = { 'card-layout': 400 };

const OrganizationDashboardBlocks = ({
  params: { id: orgId },
  initialData: { orgProducts },
  addNotification,
  orgName,
}) => {
  const { apiService } = useContext(APIContext);

  const [sortedProducts, setSortedProducts] = useState(orgProducts);
  const [isDisabled, setIsDisabled] = useState(true);

  const handleProductUpdate = async () => {
    try {
      await updateProductSortOrderAction(apiService, orgId, sortedProducts);
      await trackProductSorted({ sortedProducts, sortedBy: 'Tech Support', orgName });
      addNotification({
        type: 'success',
        message: 'Product sort order updated',
      });
    } catch (err) {
      addNotification({
        type: 'failure',
        message: `Error: ${err.message}`,
      });
    }
  };

  const handleChangeProducts = newProducts => {
    setIsDisabled(!orgProducts.some((item, index) => item.id !== newProducts[index].id));
    setSortedProducts(newProducts);
  };

  const handleToggleUpdate = ({ target: { checked, name } }) => {
    const updatedBlocks = sortedProducts
      .map(block => (block.id === Number(name) ? { ...block, isActive: checked } : block));
    setSortedProducts(updatedBlocks);
    setIsDisabled(false);
  };

  return (
    <ElementQuery queries={queries}>
      <div className="mb-4 text-max-width">
        {orgProducts.length > 0 ? (
          <>

            <div className="admin-dashboard-layout__header">
              <h5 className="mb-4 font-serif text-2xl">Products</h5>
              <Button
                filledColor="secondary"
                isSmall
                disabled={isDisabled}
                onClick={handleProductUpdate}
              >
                SAVE
              </Button>
            </div>
            <DraggableCardList
              list={sortedProducts}
              updateList={handleChangeProducts}
              renderItem={({ id, displayName, color, isActive, blockType }) => (
                <Card
                  key={id}
                  displayName={displayName}
                  blockType={blockType}
                  color={color}
                  isDraggable
                >
                  <ToggleSwitch
                    name={`${id}`}
                    checked={isActive}
                    onChange={handleToggleUpdate}
                  />
                </Card>
              )}
            />
          </>

        )
          : (
            <div className="mt-3 text-center">
              <p>
                This organization does not have any products.
              </p>
            </div>
          )}
      </div>
    </ElementQuery>
  );
};

OrganizationDashboardBlocks.getAPIDataKey = () => 'organizationBlocks';

OrganizationDashboardBlocks.getData = (apiService, { id }) =>
  apiService.get(`blocks/organizations/${id}`).then(data => ({ organizationBlocks: data }));

OrganizationDashboardBlocks.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  initialData: PropTypes.shape({
    orgProducts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        displayName: PropTypes.string,
        blockType: PropTypes.number,
      }),
    ),
  }).isRequired,
  addNotification: PropTypes.func.isRequired,
  orgName: PropTypes.string.isRequired,
};

export default withServerSideData(OrganizationDashboardBlocks);
