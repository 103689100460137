import React from 'react';
import PropTypes from 'prop-types';

import AccessCodeUses from './AccessCodeUses';
import Button from '../../../common/Button';
import Drawer from '../../../Drawer';
import AccessCodeCard from '../../../Administration/AccessCodes/ReviewAccessCodes/AccessCodeCard';

const AccessCodesYouOwn = ({
  accessCodes,
  accessCodeUses,
  showResults,
  currentCode,
  getInfo,
  exportCSV,
}) => {
  const buttonOptions = {
    getInfoButton: {
      isSmall: true,
      isNormalCase: false,
      variant: 'primary',
      onClick: getInfo,
    },
  };
  const hasAccessCodeUses = accessCodeUses && accessCodeUses.length > 0;
  return accessCodes.map(accessCode => accessCode && accessCode.code && (
    <ul className="profile-group__list" key={accessCode.code}>
      <li className="profile-group__item">
        <AccessCodeCard {...accessCode} {...buttonOptions} forAccountSettings />
        <Drawer
          id={accessCode.code}
          showDrawerContents={showResults && currentCode === accessCode.code}
        >
          {hasAccessCodeUses ? (
            <Button
              isSmall
              variant="secondary"
              type="button"
              onClick={exportCSV}
            >
              EXPORT
            </Button>
          ) : null}

          <h3 className="text-center profile-group__header font-heading-5">Access Code Uses</h3>
          {hasAccessCodeUses
            ? (<ul className="profile-group__list"><AccessCodeUses accessCodeUses={accessCodeUses} /></ul>)
            : (<p className="p-1 text-center">There are no Access Code uses to display.</p>)}
        </Drawer>
      </li>
    </ul>
  ));
};

AccessCodesYouOwn.propTypes = {
  accessCodes: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      code: PropTypes.string,
      created: PropTypes.string,
      description: PropTypes.string,
      expirationDate: PropTypes.string,
      internalNotes: PropTypes.string,
      planName: PropTypes.string,
      quantity: PropTypes.number,
      seatsRemaining: PropTypes.number,
      stripePlanId: PropTypes.string,
    }),
  ),
  accessCodeUses: PropTypes.arrayOf(
    PropTypes.shape({
      accessCode: PropTypes.string,
      redeemed: PropTypes.string,
      surveyResponse: PropTypes.shape({
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string,
        id: PropTypes.number,
        state: PropTypes.string,
        user: PropTypes.string,
      }),
      user: PropTypes.shape({
        id: PropTypes.string,
        email: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
    }),
  ),
  showResults: PropTypes.bool.isRequired,
  currentCode: PropTypes.string,
  getInfo: PropTypes.func.isRequired,
  exportCSV: PropTypes.func.isRequired,
};

AccessCodesYouOwn.defaultProps = {
  accessCodes: [],
  accessCodeUses: [],
  showResults: false,
  currentCode: '',
};

export default AccessCodesYouOwn;
