import { buildCompareUrl } from './buildCompareUrl';

export const teamActionButtonsConfig = ({ isOwner, teamId, toggleEditing }) => {
  let buttons = [{
    actionClass: 'compare',
    iconClass: 'compare-profile mr-0',
    to: buildCompareUrl(teamId),
    label: 'Compare Profile',
  }];

  if (isOwner) {
    buttons = [...[
      {
        actionClass: 'edit-team-name',
        iconClass: 'pencil',
        onClick: toggleEditing,
        label: 'Change Name',
      },
      {
        actionClass: 'add-member',
        iconClass: 'add-team-member',
        to: `/profile/teams/${teamId}/add`,
        label: 'Add Member',
      },
    ], ...buttons];
  }
  return buttons;
};
