import React from 'react';
import PropTypes from 'prop-types';

const InitialsAvatar = ({ initials }) => (
  <svg className="w-full h-auto" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" labelledby="user_initials">
    <title id="user_initials">{initials}</title>
    <rect x="2" y="2" width="60" height="60" rx="30" fill="#FFE3E0" />
    <rect x="2" y="2" width="60" height="60" rx="30" stroke="#FFC9C2" strokeWidth="4" />
    <text
      x="50%"
      y={`${66 - initials.length}%`}
      textAnchor="middle"
      fontSize={`${28 - (initials.length * 2.25)}`}
      fontFamily="Arial, sans-serif"
      fill="#E64A34"
    >
      {initials}
    </text>
  </svg>
);

InitialsAvatar.propTypes = {
  initials: PropTypes.string,
};

InitialsAvatar.defaultProps = {
  initials: '',
};

export default InitialsAvatar;
