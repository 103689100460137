import React from 'react';
import PropTypes from 'prop-types';
import OrganizationAddUserButton from '../OrganizationAddUserButton';

const renderInfo = (orgId, users, onAdd) => users.map(user => (
  <tr key={user.id} className="even:bg-ivory-500">
    <td className="py-4 pl-0 pr-3 text-sm font-medium text-gray-900 md:pl-4 break-word" data-label="Last Name">
      {user.lastName}
    </td>
    <td className="px-3 py-4 text-sm font-medium text-gray-900 break-word" data-label="First Name">
      {user.firstName}
    </td>
    <td className="px-3 py-4 text-sm font-medium text-gray-900 whitespace-nowrap">
      {user.email}
    </td>
    <td className="px-3 py-4 whitespace-nowrap" data-label="Organizations">
      {user.organizations.map(org =>
        <p key={org.id} className="mb-0 text-sm font-medium text-gray-900">{org.name}</p>)}
    </td>
    <td className="px-3 py-4 text-sm font-medium text-gray-900 whitespace-nowrap">
      <OrganizationAddUserButton orgId={orgId} {...user} onAdd={onAdd} />
    </td>
  </tr>
));

const OrganizationUserList = ({ orgId, users, onAdd }) => {
  if (!users || !users.length) return null;

  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead>
        <tr>
          <th scope="col" className="py-3.5 pl-0 md:pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
            Last Name
          </th>
          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
            First Name
          </th>
          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
            Email
          </th>
          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
            Organizations
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {renderInfo(orgId, users, onAdd)}
      </tbody>
    </table>
  );
};

OrganizationUserList.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    organizations: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
    })),
  })).isRequired,
  onAdd: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
};

export default OrganizationUserList;
