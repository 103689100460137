import React from 'react';
import PropTypes from 'prop-types';
import Card from '../../Card';
import Button from '../../common/Button';

const ConnectionCard = ({
  connectionId,
  connectionName,
  email,
  avatarUrl,
  teams,
  connectionDate,
  handleConfirmRemove,
}) => (
  <Card
    displayName={connectionName}
    avatarUrl={avatarUrl}
    displayDescription={(
      <>
        <b>Email:</b> {email}<br />
        {teams ? (
          <>
            <b>Teammate:</b> {teams.map(team => team.name).join(', ')}
            <br />
          </>
        ) : null}
        <b>Date Connected:</b> {connectionDate}
      </>
    )}
  >
    <Button
      className="card__button"
      isNormalCase
      variant="primary"
      to={`/profile/comparison?person=${connectionId}`}
    >
      Compare
    </Button>
    <Button
      className="card__button"
      isWarning
      isSmall
      onClick={handleConfirmRemove}
    >
      Remove
    </Button>
  </Card>
);

ConnectionCard.propTypes = {
  connectionId: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string.isRequired,
  connectionName: PropTypes.string.isRequired,
  connectionDate: PropTypes.string.isRequired,
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    }),
  ),
  email: PropTypes.string.isRequired,
  handleConfirmRemove: PropTypes.func.isRequired,
};

ConnectionCard.defaultProps = {
  teams: null,
};

export default ConnectionCard;
