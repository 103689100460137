class ResetPasswordAction {
  constructor(apiService) {
    this.apiService = apiService;
  }

  execute(password, token) {
    return this.apiService.post(`reset-password?reset_token=${token}`, { password })
      .catch(err => ({ error: err }));
  }
}

export default ResetPasswordAction;
