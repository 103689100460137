export const COOKIE_AUTH = 'apg_auth';
// MAX_AGE_IN_SECONDS_FOR_COOKIE_AUTH needs to match the JWT Expiration set in Auth0
export const MAX_AGE_IN_SECONDS_FOR_COOKIE_AUTH = 1800;
export const COOKIE_PROFILE = 'apg_profile';
export const COOKIE_CONNECTION = 'apg_connection';
export const COOKIE_NOTIFICATION = 'apg_notification';
export const COOKIE_TOAST = 'apg_toast';
export const COOKIE_LANGUAGE = 'apg_language';
export const COOKIE_REDIRECT = 'apg_redirect';
export const COOKIE_REGISTERED = 'apg_registered';

export const LOCAL_STORAGE_ANNOUNCEMENT = 'apg_announcement';
export const LOCAL_STORAGE_BUTTON_SORT = 'apg_button_sort';

export const { STRIPE_PUBLIC_API_KEY } = process.env;
export const { GOOGLE_MAPS_API_KEY } = process.env;

// THE SESSION_ALERT_INTERVAL is synced to the session end which is 30 minutes
// So, we set the interval to 29 minutes and 30 seconds. The SESSION_ALERT_TIMEOUT is
// is the amount of time the alert will countdown before redirecting to logout
export const SESSION_ALERT_INTERVAL = 1770000;
export const SESSION_ALERT_TIMEOUT = 30;

export const { GROWTH_PLAN_ID } = process.env;
export const GS_PREMIUM_STRIPE_PLAN = process.env.GS_DEFAULT_PREMIUM_PLAN_ID;
export const { GS_ASMT_PLAN_IDS } = process.env;
export const GS_STRIPE_PLAN_PARAMS = ['purchase', 'plan', 'coupon', 'redirect_to'];
export const COURSE_OR_PLAN_PREFIXES = ['course-', 'asmt-', 'a-'];

export const GS_GLOBAL_TRANSLATIONS = ['advice', 'profile', 'compare'];

export const IS_PRODUCTION = process.env.NODE_ENV === 'production';
export const IS_DEVELOPMENT = process.env.APG_ENV === 'development';

export const { CDN_HOST } = process.env;

export const { AWS_AVATARS_HOST } = process.env;

export const { AWS_APG_RESOURCES_HOST } = process.env;

export const { MIN_SURVEYS_FOR_ORG_REPORT } = process.env;

export const ANONYMOUS_USER = {
  firstName: '',
  lastName: '',
  avatarUrl: '',
  accessLevel: ['free'],
};

export const DASHBOARD_LAYOUT_OPTIONS = [
  { id: 1 },
  { id: 2 },
  { id: 3 },
  { id: 4 },
  { id: 5 },
];

export const translations = [
  {
    value: 'ar',
    name: 'Arabic',
    rtl: true,
    enableFor: ['survey'],
  },
  {
    value: 'zh-hans',
    name: 'Chinese (Simplified)',
    rtl: false,
    enableFor: ['survey', 'ibi', ...GS_GLOBAL_TRANSLATIONS],
  },
  {
    value: 'zh-hant',
    name: 'Chinese (Traditional)',
    rtl: false,
    enableFor: ['survey'],
  },
  {
    value: 'cz',
    name: 'Czech',
    rtl: false,
    enableFor: ['survey'],
  },
  {
    value: 'da',
    name: 'Danish',
    rtl: false,
    enableFor: ['survey'],
  },
  {
    value: 'nl',
    name: 'Dutch',
    rtl: false,
    enableFor: ['survey'],
  },
  {
    value: 'en',
    name: 'English',
    rtl: false,
    enableFor: ['survey', 'ibi', ...GS_GLOBAL_TRANSLATIONS],
  },
  {
    value: 'fr',
    name: 'French',
    rtl: false,
    enableFor: ['survey', ...GS_GLOBAL_TRANSLATIONS],
  },
  {
    value: 'de',
    name: 'German',
    rtl: false,
    enableFor: ['survey', ...GS_GLOBAL_TRANSLATIONS],
  },
  {
    value: 'gk',
    name: 'Greek',
    rtl: false,
    enableFor: [],
  },
  {
    value: 'hb',
    name: 'Hebrew',
    rtl: false,
    enableFor: [],
  },
  {
    value: 'hg',
    name: 'Hungarian',
    rtl: false,
    enableFor: [],
  },
  {
    value: 'it',
    name: 'Italian',
    rtl: false,
    enableFor: ['survey', ...GS_GLOBAL_TRANSLATIONS],
  },
  {
    value: 'ja',
    name: 'Japanese',
    rtl: false,
    enableFor: ['survey', 'ibi', ...GS_GLOBAL_TRANSLATIONS],
  },
  {
    value: 'ko',
    name: 'Korean',
    rtl: false,
    enableFor: ['survey', ...GS_GLOBAL_TRANSLATIONS],
  },
  {
    value: 'ml',
    name: 'Malay',
    rtl: false,
    enableFor: [],
  },
  {
    value: 'pl',
    name: 'Polish',
    rtl: false,
    enableFor: ['survey', ...GS_GLOBAL_TRANSLATIONS],
  },
  {
    value: 'pt-br',
    name: 'Portuguese',
    rtl: false,
    enableFor: ['survey', ...GS_GLOBAL_TRANSLATIONS],
  },
  {
    value: 'ro',
    name: 'Romanian',
    rtl: false,
    enableFor: ['survey'],
  },
  {
    value: 'ru',
    name: 'Russian',
    rtl: false,
    enableFor: ['survey'],
  },
  {
    value: 'es',
    name: 'Spanish',
    rtl: false,
    enableFor: ['survey', 'ibi', ...GS_GLOBAL_TRANSLATIONS],
  },
  {
    value: 'th',
    name: 'Thai',
    rtl: false,
    enableFor: ['survey'],
  },
  {
    value: 'tr',
    name: 'Turkish',
    rtl: false,
    enableFor: ['survey'],
  },
  {
    value: 'vi',
    name: 'Vietnamese',
    rtl: false,
    enableFor: ['survey'],
  },
];

export const platforms = [
  {
    name: 'Zoom',
    value: 1,
  },
];
