import PropTypes from 'prop-types';
import { propTypes as buttonPropTypes, defaultProps as defaultButtonProps } from '../../common/Button';

const propTypes = {
  messageText: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  confirmId: PropTypes.string,
  confirmButton: PropTypes.shape(buttonPropTypes),
  cancelText: PropTypes.string.isRequired,
  cancelId: PropTypes.string,
  cancelButton: PropTypes.shape(buttonPropTypes),
  token: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

const defaultProps = {
  confirmButton: defaultButtonProps,
  confirmId: 'confirm',
  cancelButton: defaultButtonProps,
  cancelId: 'cancel',
  token: null,
};

export {
  propTypes,
  defaultProps,
};
