const getAvailableReportVersion = asmtGroupMembers => {
  const allSurveysFinished = asmtGroupMembers.filter(member => member.surveyStatus === 'Finished');

  // if completed surveys are less than 2 then no needed to display the toast
  if (allSurveysFinished.length < 2) {
    return false;
  }

  // if all members have taken version 2 and at least two finished surveys exist,
  //  then group report is available in version 2
  const v2SurveysFinished = allSurveysFinished.filter(member => member.version === 2);
  if (v2SurveysFinished.length === allSurveysFinished.length
    && v2SurveysFinished.length >= 2) return 2;

  // if all members have taken version 3 and at least two finished surveys exist,
  //  then group report is available in version 3
  const v3SurveysFinished = allSurveysFinished.filter(member => member.version === 3);
  if (v3SurveysFinished.length >= 2) return 3;

  return null; // report unavailable
};

export default getAvailableReportVersion;
