import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { twMerge } from 'tailwind-merge';
import Icon from '../common/Icon';
import { rotate } from '../../../styles/theme';

const Toast = ({
  header,
  toastIcon,
  textColor,
  bgColor,
  toastPosition,
  showToast,
  toggleToast,
  isReopenable,
  isDismissable,
  dismissText,
  children,
  width,
}) => {
  const hasBeenOpened = useRef(false);

  const toastClasses = () => {
    let toastClass = twMerge('toast pointer-events-auto overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5', `bg-${bgColor}`, `w-${width}`);
    toastClass += ` text-${textColor}`;
    toastClass += ` toast-${toastPosition}`;

    if (showToast) {
      toastClass += ` toast-${toastPosition}`;
      toastClass += ` toast--enter-from-${toastPosition}`;
      // Set the ref after opening the first time
      if (!hasBeenOpened.current) hasBeenOpened.current = true;
    }

    // Only add the class for closing animation after toast is opened once
    if (!showToast && hasBeenOpened.current) {
      toastClass += ` toast-${toastPosition}`;
      toastClass += ` toast--exit-to-${toastPosition}`;
    }

    return toastClass;
  };

  const isRight = toastPosition.includes('right');
  const isLeft = toastPosition.includes('left');

  return (
    <div className={toastClasses()}>
      {isDismissable ? (
        <button
          type="button"
          className="absolute top-0 right-0 px-4 text-2xl text-inherit rounded-xl"
          onClick={() => toggleToast(false)}
        >
          &times;
        </button>
      ) : null}
      {isReopenable && isRight
        && (
          <button
            className="toast__handle-right"
            onClick={() => (showToast ? toggleToast(false) : toggleToast(true))}
          >
            <Icon
              icon="chevron-left"
              iconColor="white"
              className={`w-5 h-5 sm:w-6 sm:h-6 ${rotate(showToast)}`}
            />
          </button>
        )}
      {toastIcon ? <Icon icon={toastIcon} /> : null}
      <div className="[&>p]:p-0 [&>p]:m-0 [&>p]:text-base [&>p]:leading-normal">
        {header && (<div className="toast__header">{header}</div>)}
        {children}
        {(isDismissable && dismissText)
          ? (
            <button
              className={`border-none block text-sm underline ${isRight ? 'float-right' : 'float-left'} bg-${bgColor}`}
              onClick={() => toggleToast(false)}
            >
              {dismissText}
            </button>
          ) : null}
      </div>
      {isReopenable && isLeft
        && (
          <button
            className="toast__handle-left"
            onClick={() => (showToast ? toggleToast(false) : toggleToast(true))}
          >
            <Icon
              icon="chevron-right"
              iconColor="white"
              className={`w-5 h-5 sm:w-6 sm:h-6 ${rotate}`}
            />
          </button>
        )}
    </div>
  );
};

Toast.propTypes = {
  header: PropTypes.string,
  toastIcon: PropTypes.string,
  textColor: PropTypes.string,
  bgColor: PropTypes.string,
  toastPosition: PropTypes.string.isRequired,
  showToast: PropTypes.bool.isRequired,
  toggleToast: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  isReopenable: PropTypes.bool,
  isDismissable: PropTypes.bool,
  dismissText: PropTypes.string,
  width: PropTypes.string,
};
Toast.defaultProps = {
  header: null,
  toastIcon: '',
  textColor: 'black',
  bgColor: 'white',
  isReopenable: false,
  isDismissable: false,
  dismissText: null,
  width: '',
};

export default Toast;
