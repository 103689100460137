/* eslint-disable func-names */
import React from 'react';
import Error from '.';

// eslint-disable-next-line react/display-name
const withErrors = WrappedComponent => function (props) {
  return (
    <Error>
      <WrappedComponent {...props} />
    </Error>
  );
};

export default withErrors;
