export const AUTH_CONFIG = {
  domain: process.env.AUTH0_DOMAIN,
  clientId: process.env.AUTH0_CLIENT_ID,
  clientSecret: process.env.AUTH0_CLIENT_SECRET,
  callbackUrl: process.env.AUTH0_CALLBACK_URL,
  dbConnection: process.env.AUTH0_DB_CONNECTION,
  mgmtAPIClientId: process.env.AUTH0_API_M2M_CLIENT_ID,
  mgmtAPIClientSecret: process.env.AUTH0_API_M2M_CLIENT_SECRET,
  mgmtAPIAudience: process.env.AUTH0_API_M2M_AUDIENCE,
};
