import React from 'react';
import PropTypes from 'prop-types';

const SectionContainer = ({ className, title, children, isFloating }) => {
  const defaultContainerClasses = 'py-4 lg:py-8 overflow-y-auto bg-ivory-400';
  const floatingContainerClasses = 'p-4 md:p-6 mb-2 bg-white rounded-2xl';

  const defaultTitleClasses = 'mb-4';
  const smallTitleClasses = 'mb-3';

  const sectionClasses = isFloating ? floatingContainerClasses : defaultContainerClasses;
  return (
    <section className={`${sectionClasses} ${className}`}>
      {title
        ? (
          <h4 className={isFloating ? smallTitleClasses : defaultTitleClasses}>
            {title}
          </h4>
        ) : null}
      {children}
    </section>
  );
};

SectionContainer.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  isFloating: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

SectionContainer.defaultProps = {
  title: null,
  className: null,
  isFloating: false,
};

export default SectionContainer;
