import PropTypes from 'prop-types';

const propTypes = {
  currentQuestionNumber: PropTypes.number.isRequired,
  totalQuestionCount: PropTypes.number.isRequired,
};

const defaultProps = {
  currentQuestionNumber: 0,
  totalQuestionCount: 0,
};

export {
  propTypes,
  defaultProps,
};
