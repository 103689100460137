import React from 'react';

const EmailVerificationIcon = () => (
  <div className="flex flex-row justify-center gap-2">
    <svg width="87" height="87" viewBox="0 0 87 87" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M42.1396 0.410112C66.8695 0.41011 86.9258 20.4664 86.9258 45.1962L86.9258 85.3175C86.9258 86.2048 86.2054 86.9252 85.3181 86.9252L45.1968 86.9252C20.4582 86.9252 0.410717 66.8689 0.410714 42.1303C0.410712 19.0871 19.0877 0.401334 42.1396 0.401332" fill="#FF523A" />
    </svg>
    <svg width="46" height="92" viewBox="0 0 46 92" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M45.7109 45.9967C45.7109 21.1878 25.9292 0.988923 1.2803 0.328373C0.591938 0.307513 0.028736 0.877671 0.028736 1.56603L0.0287321 90.4342C0.0287321 91.1226 0.591934 91.6858 1.2803 91.6719C25.9292 91.0044 45.7109 70.8125 45.7109 45.9967Z" fill="#FF8675" />
    </svg>
    <svg width="69" height="81" viewBox="0 0 69 81" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M67.4867 39.4614L2.5696 0.913469C1.75951 0.432906 0.729731 1.01645 0.729731 1.96384L0.729727 79.0529C0.729727 80.0003 1.7595 80.5839 2.5696 80.1033L67.4867 41.5553C68.2831 41.0816 68.2831 39.9351 67.4867 39.4614Z" fill="#FFCBC4" />
    </svg>
  </div>
);

export default EmailVerificationIcon;
