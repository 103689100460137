import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { Context } from '../../../context/ContextProvider';

import AdminFeature from '../../../lib/features/Admin';
import AdminLayout from '../../Layouts/AdminLayout';

import needsAuthentication from '../../../lib/needsAuthentication';

const adminPageTitles = [
  { path: '/user/', title: 'User' },
  { path: '/users', title: 'Users' },
  { path: '/access-codes/lookup', title: 'Access Codes' },
  { path: '/access-codes', title: 'Access Code' },
  { path: '/aperian-live', title: 'Aperian Live' },
  { path: '/organizations/', title: 'Organization' },
  { path: '/organizations', title: 'Organizations' },
  { path: '/trials', title: 'Trials' },
];

const Administration = ({ location, children }) => {
  const { currentUser, router } = useContext(Context);

  needsAuthentication(router, currentUser);

  const routeMatch = adminPageTitles.find(({ path }) => location.pathname.includes(path));
  const title = routeMatch?.title ? `| ${routeMatch.title}` : '';

  const renderAdmin = () => (
    <AdminLayout pageTitle={`Administration ${title}`} location={location}>
      {children}
    </AdminLayout>
  );

  return new AdminFeature(currentUser)
    .positive(() => renderAdmin())
    .negative(() => {
      router.replace('/404');
      return null;
    })
    .execute();
};

Administration.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Administration;
