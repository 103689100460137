const createInitialsAction = (apiService, initials) =>
  apiService.post('avatar/initials', initials);

const uploadAvatarAction = (apiService, file) =>
  apiService.upload('avatar/image', file);

const deleteAvatarImageAction = apiService => apiService.delete('avatar/image');

export {
  createInitialsAction,
  uploadAvatarAction,
  deleteAvatarImageAction,
};
