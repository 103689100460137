import tracker from '../index';

const {
  booleanAsString,
  eventNames,
  eventProps,
  resolveAfterTimeout,
} = tracker;

const trackViewVideo = (videoHashedId, pageTitle, videoTitle) => {
  tracker.track(eventNames.WATCH_VIDEO, {
    [eventProps.PAGE_TITLE]: pageTitle,
    [eventProps.WISTIA_ID]: videoHashedId,
    [eventProps.VIDEO_TITLE]: videoTitle,
  });

  return resolveAfterTimeout;
};

const trackViewDimension = moduleName => {
  tracker.track(eventNames.VIEW_GS_LEARNING_MODULE, {
    [eventProps.MODULE_NAME]: moduleName,
  });

  return resolveAfterTimeout;
};

const trackProfileDownload = async (apiService, language) => {
  await apiService.post('trackers/mixpanel', {
    eventName: eventNames.DOWNLOAD_PROFILE,
    properties: {
      [eventProps.LANGUAGE]: language,
    },
  });
  return resolveAfterTimeout;
};

const trackProfileShare = platform => {
  tracker.track(eventNames.SHARE_PROFILE_CLICKED, {
    [eventProps.SHARE_TYPE]: platform,
  });
  return resolveAfterTimeout;
};

const trackComparisonChartScreenshotDownload = async (
  apiService,
  language,
  downloadLocation,
) => {
  await apiService.post('trackers/mixpanel', {
    eventName: eventNames.DOWNLOAD_COMPARISON_CHART_SCREENSHOT,
    properties: {
      [eventProps.LANGUAGE]: language,
      [eventProps.DOWNLOAD_LOCATION]: downloadLocation,
    },
  },
  );
  return resolveAfterTimeout;
};

const trackViewFullscreen = async (apiService, comparables) => {
  await apiService.post('trackers/mixpanel', {
    eventName: eventNames.VIEW_FULLSCREEN,
    properties: {
      [eventProps.COMPARISON_OBJECT]: comparables.join(', '),
      [eventProps.MULTIPLE_COMPARISON]: booleanAsString(comparables.length > 2),
    },
  },
  );
  return resolveAfterTimeout;
};

export {
  trackViewVideo,
  trackViewDimension,
  trackProfileDownload,
  trackProfileShare,
  trackComparisonChartScreenshotDownload,
  trackViewFullscreen,
};
