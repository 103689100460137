import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ConnectionCard from '../ConnectionCard';
import ConfirmRemoveConnection from '../ConfirmRemoveConnection';

const ActiveConnectionCard = ({
  connectionId,
  avatarUrl,
  connectionName,
  email,
  teams,
  connectionDate,
  onRemoveConnection,
}) => {
  const [confirmRemove, setConfirmRemove] = useState(false);

  useEffect(() => {
    setConfirmRemove(false);
  }, [onRemoveConnection]);

  const handleConfirmRemove = shouldRemove => {
    setConfirmRemove(shouldRemove);
  };

  return confirmRemove
    ? (
      <ConfirmRemoveConnection
        connectionId={connectionId}
        connectionName={connectionName}
        email={email}
        teams={teams}
        handleOnRemove={onRemoveConnection}
        handleConfirmRemove={handleConfirmRemove}
      />
    )
    : (
      <ConnectionCard
        connectionId={connectionId}
        avatarUrl={avatarUrl}
        connectionName={connectionName}
        email={email}
        teams={teams}
        connectionDate={connectionDate}
        handleConfirmRemove={handleConfirmRemove}
      />
    );
};

ActiveConnectionCard.propTypes = {
  connectionId: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string,
  connectionName: PropTypes.string.isRequired,
  connectionDate: PropTypes.string.isRequired,
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    }),
  ),
  email: PropTypes.string.isRequired,
  onRemoveConnection: PropTypes.func.isRequired,
};

ActiveConnectionCard.defaultProps = {
  avatarUrl: '',
  teams: null,
};

export default ActiveConnectionCard;
