import React, { useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import Button from '../../../common/Button';
import { trackViewOnboardingSignupForm } from '../../../../lib/tracker/onboarding';
import LabelInput from '../../../common/LabelInput';
import DropdownSelect from '../../../common/Dropdowns/DropdownSelect';
import { tracks } from '../../../../pages/Onboarding/tracks';
import Checkbox from '../../../common/Checkbox';
import Tooltip from '../../../common/Tooltip';
import PasswordInput from '../PasswordInput';
import Spinner from '../../../Spinner';
import { validateCreatorEmail } from '../../../../lib/onboarding';

const InvalidEmailMessageTooltip = ({ isEdu }) => {
  if (isEdu) {
    // TODO: Add proper `href` for `click here to purchase` link.
    return (
      <>
        This trial is for organizations interested in licensing Aperian. If you are a professor or student looking for individual access, please <a href="https://aperian.zendesk.com/hc/en-us/articles/18926425773971-Educational-Licensing-" target="_blank" rel="noopener noreferrer" className="underline text-rust-500">click here to purchase</a> or <a href="https://aperian.com/contact" target="_blank" rel="noopener noreferrer" className="underline text-rust-500">get in touch</a>.
      </>
    );
  }
  return (
    <>
      This trial is for organizations interested in licensing Aperian. Please sign up using your work email address or <a href="https://aperian.com/our-pricing-plans/" target="_blank" rel="noopener noreferrer" className="underline text-rust-500">purchase access</a>.
    </>
  );
};

InvalidEmailMessageTooltip.propTypes = {
  isEdu: PropTypes.bool.isRequired,
};

const OnboardingSingleForm = ({
  formData: {
    email,
    firstName,
    lastName,
    orgName,
    password,
    track,
  },
  onSubmit,
  handleUpdateForm,
  hasError,
}) => {
  const [formError, setFormError] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      firstName: null,
      lastName: null,
      email: null,
      isInvalid: null,
      password: null,
      track: null,
      isEdu: null,
    });
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = e => {
    handleUpdateForm({ [e.target.name]: e.target.value });
    setFormError({ [e.target.name]: null });
  };

  useEffect(() => {
    trackViewOnboardingSignupForm();
  }, []);

  useEffect(() => {
    setIsTermsChecked(false);
  }, [hasError]);

  useEffect(() => {
    if (email === null) return;

    const { isEdu, isInvalid } = validateCreatorEmail(email);

    setFormError({ isEdu, isInvalid });
  }, [email]);

  useEffect(() => {
    if (password) {
      setFormError({ password: null });
    }
  }, [password]);

  const validateEmail = () => {
    if (!email) {
      return 'You must enter your email address.';
    }
    if (!email?.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/i)) {
      return 'Enter a valid email address.';
    }
    return null;
  };

  const handleSubmitForm = async e => {
    e.preventDefault();
    const errors = {
      firstName: !firstName && 'You must enter your first name.',
      lastName: !lastName && 'You must enter your last name.',
      email: validateEmail(),
      password: !password && 'You must enter a valid password.',
      track: !track && 'Please select any option.',
      orgName: !orgName && 'You must enter an organization name.',
    };

    if (Object.values(errors).some(value => value)
      || Object.values(formError).some(value => value)) {
      setFormError(errors);
      return;
    }
    setIsSubmitted(true);
    await onSubmit(e);
  };

  const defaultList = [{
    name: 'Select one',
    value: '',
  }];

  const formattedOrgs = tracks.map(({ id, name }) => ({ id, name, value: id }));

  const trackList = [...defaultList, ...formattedOrgs];

  const isCustomTrack = track === '6' || track === '7';

  return (
    <>
      <div className="w-full max-w-5xl px-6 m-auto md:w-2/3">
        <h1 className="mb-4 font-serif text-4xl tracking-wider text-charcoal-900 md:text-6xl md:mb-8">Let&apos;s set up your <em className="tracking-wider text-rust-500">trial!</em></h1>
        <p className="mb-4 font-sans text-sm leading-6 text-gray-800 md:text-base">
          Whether you&apos;re looking to try a specific product for your organization or
          you just want to explore
          the platform, this 7-day trial will show you
          how Aperian can support you and your teams.
        </p>
        <p className="mb-4 font-sans text-sm leading-6 text-gray-800 md:text-base md:mb-8">The trial is for those who are considering Aperian for their teams or across their organization. <a href="https://aperian.zendesk.com/hc/en-us/articles/18926425773971-Academic-Licensing">Students and professors</a>  can also create their own accounts.</p>

      </div>
      <div className="grid md:grid-cols-2 grid-cols-1 w-full max-w-xs md:max-w-[42rem] mx-auto mb-4 md:mb-8 [&>div]:w-full gap-4 md:gap-8 md:flex-row flex-col">
        <LabelInput
          id="firstName"
          name="firstName"
          type="text"
          placeholderText="First Name"
          labelText="First Name"
          labelType="text"
          value={firstName}
          errorMessage={formError.firstName}
          onChangeValue={handleChange}
        />
        <LabelInput
          id="lastName"
          name="lastName"
          type="text"
          placeholderText="Last Name"
          labelText="Last Name"
          labelType="text"
          value={lastName}
          errorMessage={formError.lastName}
          onChangeValue={handleChange}
        />
        <Tooltip
          content={(formError?.isInvalid || formError?.isEdu)
            && <InvalidEmailMessageTooltip isEdu={formError?.isEdu} />}
        >
          <LabelInput
            id="email"
            name="email"
            type="text"
            placeholderText="Work Email"
            labelText="Work Email"
            labelType="text"
            value={email}
            onChangeValue={handleChange}
            errorMessage={formError.email}
          />
        </Tooltip>
        <PasswordInput
          isSingleStepForm
          handleUpdateForm={handleUpdateForm}
          passwordError={formError.password}
        />
        <LabelInput
          id="orgName"
          name="orgName"
          type="text"
          placeholderText="Organization Name"
          labelText="Organization"
          labelType="text"
          value={orgName}
          errorMessage={formError.orgName}
          onChangeValue={handleChange}
          leadingIcon="building"
          className="underline-transition text-charcoal-900 hover:text-rust-500 decoration-rust-500"
        />
        {isCustomTrack ? null : (
          <DropdownSelect
            id="track"
            name="track"
            value={track}
            labelText="How can Aperian help your organization?"
            options={trackList}
            onChangeValue={handleChange}
            isRequired
            errorMessage={formError.track}
          />
        )}
      </div>
      <div className="mx-auto mb-4 md:mb-8">
        <Checkbox
          id="termsOfUse"
          name="termsOfUse"
          checked={isTermsChecked}
          onChange={e => setIsTermsChecked(e.target.checked)}
          labelClasses="text-lg mt-4"
          labelText={(
            <span>I agree to the&nbsp;
              <a
                href="https://app.aperian.com/content/terms-of-use"
                target="_blank"
                rel="noopener noreferrer"
              >Terms of Use
              </a>
              &nbsp;and&nbsp;
              <a
                href="https://app.aperian.com/content/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </span>
          )}
        />
      </div>
      <div className="flex justify-center mb-4">
        {
          isSubmitted && !hasError
            ? (<Spinner margin="0.5rem" isSmall />)
            : (
              <Button
                trailingButtonIcon="arrow-right"
                variant="primary"
                disabled={!isTermsChecked && hasError}
                onClick={handleSubmitForm}
              >Get Started
              </Button>
            )
        }
      </div>
    </>
  );
};

OnboardingSingleForm.propTypes = {
  handleUpdateForm: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    invitationToken: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    userId: PropTypes.string,
    orgName: PropTypes.string,
    password: PropTypes.string,
    track: PropTypes.string,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
};

export default OnboardingSingleForm;
