import React from 'react';
import PropTypes from 'prop-types';

import DropdownSelect from '../../../../components/common/Dropdowns/DropdownSelect';

const AssessmentForm = ({ assessments, assessmentType, handleSelectAssessmentChange }) => (
  <div className="px-1 pt-1">
    <DropdownSelect
      id="assessmentType"
      name="assessmentType"
      labelType="text"
      labelText="Assessment Type"
      options={assessments}
      isRequired
      value={assessmentType?.value}
      onChangeValue={handleSelectAssessmentChange}
      helperText="Select the type of assessment your block will address."
    />
  </div>
);

AssessmentForm.propTypes = {
  assessments: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    })),
  assessmentType: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.number,
  }),
  handleSelectAssessmentChange: PropTypes.func.isRequired,
};

AssessmentForm.defaultProps = {
  assessments: [],
  assessmentType: {},
};

export default AssessmentForm;
