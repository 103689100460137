import PropTypes from 'prop-types';
import {
  propTypes as ButtonPropTypes,
  defaultProps as ButtonDefaultProps,
} from '../../common/Button';
import { propTypes as selectedComparablesPropTypes } from '../../../propTypes/selectedComparables';

const propTypes = {
  takeoverTitle: PropTypes.string,
  confirmOptions: PropTypes.shape(ButtonPropTypes),
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  cancelOptions: PropTypes.shape(ButtonPropTypes),
  children: PropTypes.node,
  cancelHandler: PropTypes.func.isRequired,
  confirmHandler: PropTypes.func.isRequired,
  sendInvitationsLink: PropTypes.func,
  createTeamLink: PropTypes.func,
  compareTargets: PropTypes.arrayOf(PropTypes.shape({})),
  selectedComparables: selectedComparablesPropTypes,
  filterValue: PropTypes.string,
  onRemove: PropTypes.func,
  onFilter: PropTypes.func,
  hasPremium: PropTypes.func,
};

const defaultProps = {
  takeoverTitle: null,
  children: null,
  confirmText: null,
  cancelText: 'Close',
  confirmOptions: ButtonDefaultProps,
  cancelOptions: ButtonDefaultProps,
  sendInvitationsLink: undefined,
  createTeamLink: undefined,
  filterValue: '',
  onFilter: undefined,
  onRemove: undefined,
  hasPremium: undefined,
};

const contextTypes = {
  currentUser: PropTypes.object,
  router: PropTypes.object,
};

export { propTypes, defaultProps, contextTypes };
