import React from 'react';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import Parser from 'html-react-parser';
import DOMPurify from 'isomorphic-dompurify';
import Avatar from '../../common/Avatars/Avatar';
import { trackCultureClicked } from '../../../lib/tracker/guides';

const CultureCards = ({
  cultures,
  applyGrid,
  expandedBox,
  launchedFrom,
  guideLinkSubdirectory,
}) => {
  const classNames = twMerge('grid max-h-full grid-cols-1 gap-4 px-4 overflow-y-scroll sm:grid-cols-2 xl:grid-cols-3 md:px-0 lg:px-8 custom-scroll', `${applyGrid}`);

  return (
    <div className={`rounded-b-lg ${expandedBox ? 'rounded-md h-auto bg-ivory-400 py-8' : 'h-72 bg-stone-400 py-4'}`}>
      {cultures.length
        ? (
          <div className={classNames}>
            {cultures.map(culture => (
              <a
                href={guideLinkSubdirectory ? `${culture.value}${guideLinkSubdirectory}` : culture.value}
                key={culture.id}
                onClick={() => trackCultureClicked(launchedFrom, culture.name)}
                className={twMerge(
                  'w-full px-6 md:px-0 lg:px-6 py-2 font-light bg-white rounded-full hover:shadow-lg hover:bg-ivory-200 hover:font-medium whitespace-nowrap',
                  expandedBox,
                )}
              >
                <div className="flex items-center h-full gap-3 md:gap-2 lg:gap-3">
                  <Avatar
                    isCulture
                    isDashboard
                    avatarUrl={culture.flagIcon}
                    avatarImgAlt={`${culture.name} flag`}
                    className="!w-8 !h-8 !min-w-8 rounded-md shadow-lg"
                  />
                  <p className="m-0 text-sm">
                    {/* eslint-disable-next-line new-cap */}
                    {Parser(DOMPurify.sanitize(culture.name, { ADD_ATTR: ['target'] }))}
                  </p>
                </div>
              </a>
            ))}
          </div>
        )
        : (
          <div className="flex justify-center">
            <div className="flex flex-col justify-between w-full px-6 md:pl-10 max-w-[610px]">
              <h3 className="mb-4 font-serif leading-snug tracking-wider text-left text-charcoal-900">
                <span className="underline decoration-rust-500 decoration-2 underline-offset-8 md:underline-offset-[15px]">
                  You
                </span>r search didn&apos;t match any results
              </h3>
              <p className="py-2 font-sans text-base font-light text-left md:text-sm">
                It looks like the culture you&apos;re looking for isn&apos;t here.
                Please alter your search or reach out to support for
                additional assistance.
              </p>
            </div>
          </div>
        )}
    </div>
  );
};

CultureCards.propTypes = {
  cultures: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  applyGrid: PropTypes.string,
  expandedBox: PropTypes.string,
  launchedFrom: PropTypes.string.isRequired,
  guideLinkSubdirectory: PropTypes.string,
};

CultureCards.defaultProps = {
  applyGrid: '',
  expandedBox: '',
  guideLinkSubdirectory: '',
};

export default CultureCards;
