import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ProfileCardTextWithButton from '../../../../ProfileCards/ProfileCardTextWithButton';
import ProfileCardAvatarWithButtons from '../../../../ProfileCards/ProfileCardAvatarWithButton';

const GroupMemberCard = ({
  id: memberUserId,
  memberId,
  avatarUrl,
  displayName,
  displayDescription,
  surveyStatus,
  token,
  isIbi,
  emailMemberReport,
  removeMember,
  remindMember,
  withDrawer,
  drawerLabel,
  drawerContents,
  showDrawerContents,
  toggleDrawer,
}) => {
  const [wasReminded, setWasReminded] = useState();
  const [wasEmailed, setWasEmailed] = useState();
  const [confirmRemove, setConfirmRemove] = useState(false);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setWasReminded(false);
    }, 2000);
    return () => clearTimeout(timeOut);
  }, [wasReminded]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setWasEmailed(false);
    }, 2000);
    return () => clearTimeout(timeOut);
  }, [wasEmailed]);

  const handleOnRemind = () => {
    setWasReminded(true);
    remindMember(memberUserId);
  };

  const handleEmailReport = () => {
    setWasEmailed(true);
    emailMemberReport(memberUserId, memberId);
  };

  function renderConfirmRemove() {
    const options = {
      type: 'button',
      messageText: `Are you sure you want to remove ${displayName}?`,
      confirmText: 'Yes, Remove',
      confirmButton: {
        isWarning: true,
        isNormalCase: true,
        onClick: () => removeMember(memberUserId),
      },
      cancelText: 'No, Keep',
      cancelButton: {
        isNormalCase: true,
        variant: 'secondary',
        onClick: () => setConfirmRemove(false),
      },
    };

    return <ProfileCardTextWithButton {...options} token={token} withDrawer />;
  }

  function renderGroupMemberCard() {
    const buttonOptions = {
      confirmText: '',
      confirmButton: {},
      cancelText: 'Remove',
      cancelButton: {
        isNormalCase: true,
        isWarning: true,
        onClick: () => setConfirmRemove(true),
      },
    };

    if (isIbi) {
      buttonOptions.confirmText = wasEmailed ? 'Emailing Report' : 'Email Me Report';
      buttonOptions.confirmButton = {
        variant: 'primary',
        isNormalCase: true,
        onClick: handleEmailReport,
        disabled: wasEmailed,
      };
    }

    if (surveyStatus !== 'Finished') {
      buttonOptions.confirmText = wasReminded ? 'Reminder Sent' : 'Remind';
      buttonOptions.confirmButton = {
        variant: 'primary',
        isNormalCase: true,
        onClick: handleOnRemind,
        disabled: wasReminded,
      };
    }

    return (
      <ProfileCardAvatarWithButtons
        memberUserId={memberUserId}
        avatarUrl={avatarUrl}
        displayName={displayName}
        displayDescription={displayDescription}
        withDrawer={withDrawer}
        drawerLabel={drawerLabel}
        showDrawerContents={showDrawerContents}
        toggleDrawer={toggleDrawer}
        {...buttonOptions}
        drawerContents={drawerContents}
        color="green"
      />
    );
  }
  return confirmRemove ? renderConfirmRemove() : renderGroupMemberCard();
};

GroupMemberCard.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  avatarUrl: PropTypes.string,
  memberId: PropTypes.number.isRequired,
  displayName: PropTypes.string.isRequired,
  displayDescription: PropTypes.shape({}),
  isIbi: PropTypes.bool.isRequired,
};

GroupMemberCard.defaultProps = {
  avatarUrl: '',
  displayDescription: null,
};

export default GroupMemberCard;
