import React from 'react';
import ElementQuery from 'react-eq';
import PropTypes from 'prop-types';

import DeleteUserButton from '../DeleteUserButton';

const renderInfo = ({
  participants,
  cohortCode,
  setReload,
  error,
  setNotification,
  cohortType,
  allowSkillsTracking,
}) => participants.map(participant => (
  <tr key={participant.id} className="admin-list__item">
    <td className="admin-list__cell" data-label="Email">
      {participant.email}
    </td>
    <td className="admin-list__cell" data-label="First Name">
      {participant.firstName}
    </td>
    <td className="admin-list__cell" data-label="Last Name">
      {participant.lastName}
    </td>
    <td className="admin-list__cell" data-label="Date Enrolled">
      {participant.dateJoined}
    </td>
    <td className="admin-list__cell" data-label="GSP Status">
      {participant.surveyStatus || 'not started'}
    </td>
    <td className="admin-list__cell" data-label="IBI Status">
      {participant.ibiSurveyStatus || 'not started'}
    </td>
    {cohortType === 'Aperian Live' ? (
      <>
        <td className="admin-list__cell" data-label="Attendance Status">
          {participant.attendanceStatus || 'none'}
        </td>
        <td className="admin-list__cell" data-label="Attendance Duration">
          {participant.attendanceDuration || 'none'}
        </td>
      </>
    ) : null}
    {allowSkillsTracking ? (
      <>
        <td className="admin-list__cell" data-label="Cultural Agility Skill Status">
          {participant.culturalAgilitySkillStatus || 'none'}
        </td>
        <td className="admin-list__cell" data-label="Team Collaboration Skill Status">
          {participant.teamCollaborationSkillStatus || 'none'}
        </td>
      </>
    ) : null}
    <td className="admin-list__cell" data-label="Remove">
      <DeleteUserButton
        id={participant.id}
        code={cohortCode}
        setReload={setReload}
        error={error}
        setNotification={setNotification}
        firstName={participant.firstName}
        lastName={participant.lastName}
      />
    </td>
  </tr>
));

const renderDefault = cohortName => (
  <tr>
    <td colSpan={8} className="text-center mt-0.5">
      <i>There are no participants enrolled with {cohortName}</i>
    </td>
  </tr>
);

const CohortParticipantsTable = ({
  participants,
  onExportParticipants,
  cohortName,
  cohortCode,
  setReload,
  error,
  setNotification,
  cohortType,
  allowSkillsTracking,
}) => (
  <>
    <div className="admin-dashboard-layout__header">
      <h2 className="font-heading-2 my-0.5">Participants</h2>
      <button
        title="Download to CSV"
        className="mr-1 btn--anchor"
        data-code={cohortCode}
        onClick={onExportParticipants}
      >
        <span className="btn__icon icon-download" />
      </button>
    </div>
    {cohortType === 'Aperian Live' ? (
      <p className="mb-0 text-sm">
        Full Attendance: Present for at least 40 minutes, including waiting room time.
        Partial Attendance: Any attempt to join the call. Missed: No attempt to join.
        Duration is shown in minutes.
      </p>
    ) : null}
    <ElementQuery queries={{ 'table-view': 700 }}>
      <table className="bg-white admin-list">
        <thead className="admin-list__header">
          <tr>
            <th className="admin-list__cell admin-list__cell--first-name">
              Email
            </th>
            <th className="admin-list__cell">
              First Name
            </th>
            <th className="admin-list__cell">
              Last Name
            </th>
            <th className="admin-list__cell">
              Date Enrolled
            </th>
            <th className="admin-list__cell">
              GSP Status
            </th>
            <th className="admin-list__cell">
              IBI Status
            </th>
            {cohortType === 'Aperian Live' ? (
              <>
                <th className="admin-list__cell">
                  Attendance Status
                </th>
                <th className="admin-list__cell">
                  Attendance Duration
                </th>
              </>
            ) : null}
            {allowSkillsTracking ? (
              <>
                <th className="admin-list__cell">
                  Cultural Agility Skill Status
                </th>
                <th className="admin-list__cell">
                  Team Collaboration Skill Status
                </th>
              </>
            ) : null}
            <th className="admin-list__cell">
              Remove
            </th>
          </tr>
        </thead>
        <tbody className="admin-list__container">
          {participants.length
            ? renderInfo({
              participants,
              cohortCode,
              setReload,
              error,
              setNotification,
              cohortType,
              allowSkillsTracking,
            })
            : renderDefault(cohortName)}
        </tbody>
      </table>
    </ElementQuery>
  </>
);

CohortParticipantsTable.propTypes = {
  participants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      surveyStatus: PropTypes.string,
      dateJoined: PropTypes.string,
      email: PropTypes.string,
      ibiSurveyStatus: PropTypes.string,
      attendanceStatus: PropTypes.string,
      attendanceDuration: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      culturalAgilitySkillStatus: PropTypes.string,
      teamCollaborationSkillStatus: PropTypes.string,
    }),
  ).isRequired,
  onExportParticipants: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  error: PropTypes.func.isRequired,
  cohortName: PropTypes.string.isRequired,
  cohortCode: PropTypes.string.isRequired,
  setNotification: PropTypes.func.isRequired,
  cohortType: PropTypes.string.isRequired,
  allowSkillsTracking: PropTypes.bool.isRequired,
};

export default CohortParticipantsTable;
