import React from 'react';

import JoinedRaterCard from './JoinedRaterCard';

function JoinedRaterCards({
  raters,
  assessment,
  handleSetNotification,
  removeRater,
}) {
  return raters
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(({
      name: raterName,
      email: raterEmail,
      raterId,
      joinedAt,
      surveyStatus: raterSurveyStatus,
      remindedAt,
      token,
      raterInvitationId,
    }) => (
      <JoinedRaterCard
        key={token}
        assessment={assessment}
        raterName={raterName}
        raterEmail={raterEmail}
        raterId={raterId}
        joinedAt={joinedAt}
        raterSurveyStatus={raterSurveyStatus}
        initialRemindedAt={remindedAt}
        token={token}
        raterInvitationId={raterInvitationId}
        handleSetNotification={handleSetNotification}
        removeRater={removeRater}
      />
    ));
}

export default JoinedRaterCards;
