import React from 'react';

import BodyWrapper from '../../components/BodyWrapper';
import AperianWordmark from '../../components/AperianWordmark';
import RustDividingLine from '../../components/common/RustDividingLine';
import Button from '../../components/common/Button';

function handleRedirect() {
  window.location.href = 'https://aperian.com/contact';
}

const ExpiredTrial = () => (
  <div className="flex flex-col">
    <div className="flex-grow">
      <BodyWrapper documentTitle="Error | Aperian">
        <div className="flex items-center justify-center mb-6">
          <a href="https://aperian.com/">
            <AperianWordmark />
          </a>
        </div>
        <div>
          <h1 className="mb-2 text-2xl font-normal font-headline">We&apos;re sorry, but this trial has ended.</h1>
          <RustDividingLine />
          <p className="mt-2 mb-0 font-sans text-xs font-normal">If you&apos;re still interested in Aperian, please get in touch.</p>
        </div>
        <Button variant="primary" onClick={() => handleRedirect()}>Contact Us</Button>
      </BodyWrapper>
    </div>
  </div>
);

export default ExpiredTrial;
