import React, { Component } from 'react';
import { Link } from 'react-router';
import { isPast } from 'date-fns';

import { Context } from '../../../../../context/ContextProvider';

import CopyText from '../../../../CopyText';
import Button from '../../../../common/Button';

import AdminFeature from '../../../../../lib/features/Admin';

function renderActions(expirationDate, active, confirmButton) {
  if (isPast(new Date(expirationDate))) return <p>Expired</p>;
  if (!active) return <p>Deactivated</p>;
  return (
    <Button {...confirmButton} id="accessCodeButton" className="card__button">
      Deactivate
    </Button>
  );
}

function renderGetInfoButton(code, getInfoButton) {
  return (
    <Button dataID={code} {...getInfoButton} className="text-xs w-100 lg:text-sm">
      Get Information
    </Button>
  );
}

function renderDescription(description) {
  if (!description) return null;

  return <div className="card__description">{description}</div>;
}

const renderAccessCode = (code, editAccessCode, currentUser) =>
  new AdminFeature(currentUser)
    .positive(() => (
      editAccessCode
        ? code
        : <Link to={`/administration/access-codes/${code}/edit`}>{code}</Link>
    ))
    .negative(() => code)
    .execute();

class AccessCodeCard extends Component {
  static contextType = Context;

  static getDerivedStateFromProps(nextProps) {
    return { ...nextProps };
  }

  constructor(props) {
    super(props);

    this.state = {
      ...props,
    };
  }

  render() {
    const {
      code,
      seatsRemaining,
      quantity,
      planName,
      expirationDate,
      active,
      isArchived,
      created,
      description,
      confirmButton,
      forAccountSettings,
      editAccessCode,
      getInfoButton,
      user,
    } = this.state;

    return (
      <div className="card">
        <div className="card__container card__container--confirm">
          <div className="card__content">
            {isPast(new Date(expirationDate)) ? <><div className="label--error">Expired</div>&nbsp;&nbsp;</> : null }
            {(!active) ? <><div className="label--error">Deactivated</div>&nbsp;&nbsp;</> : null}
            {(isArchived) ? <div className="label--error">Plan Archived</div> : null }
            <div className="card__heading">
              {renderAccessCode(code, editAccessCode, this.context.currentUser)}
              {` - ${planName}`} <CopyText text={`${process.env.SITE_URL}/access-codes/${code}/redeem`} />
            </div>Expires {expirationDate}&nbsp;| Created {created}
            <div>
              {seatsRemaining}/{quantity} remaining
            </div>
            {user && user.email && (
              <div>Owner: {user.firstName} {user.lastName} ({user.email})</div>
            )}
            {renderDescription(description)}
          </div>
          <div className="card__actions">
            {forAccountSettings
              ? renderGetInfoButton(code, getInfoButton)
              : renderActions(expirationDate, active, confirmButton)}
          </div>
        </div>
      </div>
    );
  }
}

export default AccessCodeCard;
