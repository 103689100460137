import React from 'react';
import PropTypes from 'prop-types';

import ProfileChartAvatarContainer from '../../ProfileChartAvatarContainer';

const Avatars = ({
  haveProfilesChanged,
  profiles,
  positions,
  maxAvatars,
  selectedAvatar,
  tooManyComparisonObjects,
  showControls,
  hasResizeSupport,
  location,
  compareProfile,
  myProfileVisibility,
  isDashboard,
  withBorder,
  isHistoryProfile,
  hideAvatar,
  onAvatarPositionsChanged,
  avatarContainerRef,
  handleAvatarClick,
  updateSelectedProfiles,
}) => (
  hideAvatar
    ? null
    : (
      <ProfileChartAvatarContainer
        profiles={profiles}
        positions={positions}
        onAvatarPositionsChanged={onAvatarPositionsChanged}
        avatarContainerRef={avatarContainerRef}
        maxAvatars={maxAvatars}
        showControls={showControls}
        handleAvatarClick={handleAvatarClick}
        selectedAvatar={selectedAvatar}
        onUpdate={updateSelectedProfiles}
        tooManyComparisonObjects={tooManyComparisonObjects}
        hasResizeSupport={hasResizeSupport}
        location={location}
        compareProfile={compareProfile}
        myProfileVisibility={myProfileVisibility}
        isDashboard={isDashboard}
        isHistoryProfile={isHistoryProfile}
        haveProfilesChanged={haveProfilesChanged}
        withBorder={withBorder}
      />
    ));

Avatars.propTypes = {
  haveProfilesChanged: PropTypes.bool,
  profiles: PropTypes.arrayOf(
    PropTypes.shape({
      shortDisplayName: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      avatarUrl: PropTypes.string,
      grid: PropTypes.shape({
        egalitarianism_status: PropTypes.number,
        direct_indirect: PropTypes.number,
        independent_interdependent: PropTypes.number,
        task_relationship: PropTypes.number,
        risk_certainty: PropTypes.number,
      }),
    })).isRequired,
  positions: PropTypes.shape({

  }).isRequired,
  maxAvatars: PropTypes.number.isRequired,
  selectedAvatar: PropTypes.number,
  tooManyComparisonObjects: PropTypes.bool,
  showControls: PropTypes.bool,
  hasResizeSupport: PropTypes.bool.isRequired,
  location: PropTypes.shape({}),
  compareProfile: PropTypes.bool,
  myProfileVisibility: PropTypes.string.isRequired,
  isDashboard: PropTypes.bool,
  isHistoryProfile: PropTypes.bool,
  hideAvatar: PropTypes.bool,
  onAvatarPositionsChanged: PropTypes.func.isRequired,
  avatarContainerRef: PropTypes.func.isRequired,
  handleAvatarClick: PropTypes.func.isRequired,
  updateSelectedProfiles: PropTypes.func,
  withBorder: PropTypes.bool,
};

Avatars.defaultProps = {
  location: undefined,
  haveProfilesChanged: false,
  selectedAvatar: null,
  tooManyComparisonObjects: false,
  showControls: false,
  compareProfile: false,
  isDashboard: false,
  isHistoryProfile: false,
  hideAvatar: false,
  updateSelectedProfiles: undefined,
  withBorder: false,
};

export default Avatars;
