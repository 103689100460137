import React, { useEffect, useState } from 'react';

import { propTypes, defaultProps } from './types';
import SurveyQuestionText from '../SurveyQuestionText';
import SurveyResponsesList from '../SurveyResponsesList';
import SurveyResponseNA from '../SurveyResponseNA';
import SurveyNavigation from '../SurveyNavigation';
import SurveyQuestionLanguage from '../SurveyQuestionLanguage';

const SurveyQuestion = ({
  previousAnswer,
  questionId,
  questionText,
  onBack,
  onAnswer,
  isLast,
  currentQuestionNumber,
  languageSelected,
  isOnboarding,
  onLanguageChange,
}) => {
  const [selectedOption, setSelectedOption] = useState(previousAnswer);

  useEffect(() => {
    setSelectedOption(previousAnswer);
  }, [previousAnswer]);

  const onOptionChange = newValue => setSelectedOption(newValue);

  const onNext = () => onAnswer(selectedOption);

  return (
    <div className="flex flex-col justify-around flex-grow m-auto">
      <SurveyQuestionText
        questionText={questionText}
        languageSelected={languageSelected}
      />
      <div className="flex-grow py-0 md:py-8">
        <SurveyResponsesList
          questionId={questionId}
          selectedOption={selectedOption}
          onOptionChange={onOptionChange}
        />
        {isOnboarding ? null : (
          <SurveyResponseNA
            questionId={questionId}
            selectedOption={selectedOption}
            onOptionChange={onOptionChange}
          />
        )}
        <SurveyNavigation
          selectedOption={selectedOption}
          onNext={onNext}
          onBack={onBack}
          isLast={isLast}
          currentQuestion={currentQuestionNumber}
          isOnboarding={isOnboarding}
        />
      </div>
      <SurveyQuestionLanguage
        selectedLanguage={languageSelected}
        onLanguageChange={onLanguageChange}
      />
    </div>
  );
};

SurveyQuestion.propTypes = propTypes;
SurveyQuestion.defaultProps = defaultProps;

export default SurveyQuestion;
