import React from 'react';
import PropTypes from 'prop-types';

import ProfileCardTextWithButton from '../../../../../ProfileCards/ProfileCardTextWithButton';

const ConfirmLeave = ({ token, completeLeave, noLeave }) => {
  const options = {
    messageText: 'Are you sure you want to leave this team?',
    confirmText: 'Yes, Leave',
    confirmButton: {
      filledColor: 'red',
      isNormalCase: true,
      onClick: completeLeave,
    },
    cancelText: 'No, Stay',
    cancelButton: {
      isNormalCase: true,
      isCancel: true,
      onClick: noLeave,
    },
  };

  return <ProfileCardTextWithButton {...options} token={token} />;
};

ConfirmLeave.propTypes = {
  token: PropTypes.number.isRequired,
  completeLeave: PropTypes.func.isRequired,
  noLeave: PropTypes.func.isRequired,
};

export default ConfirmLeave;
