import React from 'react';
import Notification from '../../Notifications/Notification';

const renderNotifications = notifications => (
  notifications.map(notification => notification && notification.message && (
    <Notification
      type={notification.type}
      message={notification.message}
      key={notification.message}
    />
  )));

const getErrorMessage = err => {
  let message;
  if (err.reason && err.reason.error.details) {
    const { details } = err.reason.error;
    const firstKey = Object.keys(details[0])[0];
    message = details[0][firstKey];
  } else if (err.error) {
    message = err.error;
  } else {
    message = err;
  }
  return message;
};

export { renderNotifications, getErrorMessage };
