import { useContext } from 'react';
import PropTypes from 'prop-types';

import { RouterContext } from '../../context/Router';
import { CurrentUserContext } from '../../context/CurrentUser';

import GlobeSmartGuidesFeature from '../../lib/features/GlobeSmartGuides';
import getLoginUrl from '../../lib/urls/loginUrl';
import { GS_PREMIUM_STRIPE_PLAN } from '../../lib/constants';

const GlobeSmartGuidesAuth = ({ children, location }) => {
  const { currentUser } = useContext(CurrentUserContext);
  const { router } = useContext(RouterContext);
  const { pathname } = location;

  return new GlobeSmartGuidesFeature(currentUser)
    .positive(() => children)
    .negative(() => {
      if (currentUser.isLoggedIn()) {
        const purchaseUrl = `/dashboard?purchase&plan=${GS_PREMIUM_STRIPE_PLAN}&redirect_to=${pathname}`;
        router.replace(purchaseUrl);
      } else {
        const loginUrl = getLoginUrl(location);
        router.replace(loginUrl);
      }
      return null;
    })
    .execute();
};

GlobeSmartGuidesAuth.propTypes = {
  children: PropTypes.node,
};

export default GlobeSmartGuidesAuth;
