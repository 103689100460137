import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TrackIcon from '../icons/TrackIconRender';

const TrackSelectionCard = ({ text, trackId, trackName, handleSelectTrack, disableButtons }) => {
  const [isSelected, setIsSelected] = useState(false);

  return (
    <button
      key={text}
      type="button"
      className="grid items-center justify-center grid-rows-2 p-4 font-sans cursor-pointer group aspect-square unbuttonize card active:transform active:scale-95"
      onClick={() => {
        setIsSelected(true);
        return handleSelectTrack({ trackId, trackName });
      }}
      disabled={disableButtons}
      tabIndex={0}
    >
      <div className="flex justify-center items-center h-[90%]">
        <TrackIcon
          trackId={trackId}
          isSelected={isSelected}
        />
      </div>
      <p className={`text-gray-400 font-sans group-hover:text-charcoal-900 font-normal text-center m-0 text-base lg:text-xl !leading-snug tracking-wider ${(isSelected) ? '!text-charcoal-900' : ''}`}>
        {text}
      </p>
    </button>
  );
};

TrackSelectionCard.propTypes = {
  text: PropTypes.string.isRequired,
  trackId: PropTypes.number.isRequired,
  trackName: PropTypes.string.isRequired,
  handleSelectTrack: PropTypes.func.isRequired,
  disableButtons: PropTypes.bool,
};

TrackSelectionCard.defaultProps = {
  disableButtons: false,
};

export default TrackSelectionCard;
