import React from 'react';

import { propTypes, defaultProps } from './types';
import BodyWrapper from '../BodyWrapper';
import withErrors from './withErrors';

class Errors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      message: null,
    };
  }

  onError = message => {
    this.setState({
      hasError: true,
      message,
    });
  };

  renderError() {
    const svgStyle = {
      margin: '0 auto',
    };

    return (
      <BodyWrapper documentTitle="Error | Aperian" containerClasses="text-max-width container--thick-padding container--error">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 84 84" height="100" width="100" style={svgStyle}>
          <circle cx="42" cy="42" r="38" strokeWidth="7" stroke="#D2D4D6" fill="none" />
          <g fill="#F47D21">
            <path d="M38,20 A2,2 0 0,1 40,18 L44,18 A2,2 0 0,1 46,20 L46,48 A2,2 0 0,1 44,50 L40,50 A2,2 0 0,1 38,48z" />
            <circle cx="42" cy="59" r="4.5" />
          </g>
        </svg>
        {this.state.message}
      </BodyWrapper>
    );
  }

  render() {
    if (this.state.hasError) return this.renderError();

    return React.cloneElement(this.props.children, { onError: this.onError });
  }
}

Errors.propTypes = propTypes;
Errors.defaultProps = defaultProps;

export default Errors;
export {
  withErrors,
};
