/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';

const ActionItem = (
  {
    actionClass,
    currentAction,
    toggleAction,
    iconClass,
    onClick,
    to,
    label,
  }) => {
  const [ariaExpanded, setAriaExpanded] = useState(false);

  useEffect(() => {
    if (currentAction !== actionClass) {
      setAriaExpanded(false);
    } else {
      setAriaExpanded(true);
    }
  }, [currentAction]);

  const buttonOnClick = e => {
    e.currentTarget.closest('.actions-item').blur();
    e.currentTarget.blur();
    setAriaExpanded(false);
    onClick();
  };

  return (
    <li className={`actions-item flex-1 basis-14 group relative actions-list__item--${actionClass}`}>
      <button
        type="button"
        className="peer appearance-none bg-white border border-l-0 rounded-none text-inherit cursor-pointer flex m-0 h-14 justify-center py-4 w-full z-[900] lg:items-center lg:bg-transparent lg:hover:bg-transparent relative lg:border-0 lg:p-0 lg:pointer-events-none items-center before:block before:w-0 before:h-0 before:opacity-0 before:absolute before:pointer-events-none before:transition-opacity before:bottom-[calc(100%+.0125rem)] before:left-[calc(50%-.5rem)] aria-expanded:content-[''] aria-expanded:before:content-[''] aria-expanded:before:border-solid aria-expanded:before:border-8 aria-expanded:before:border-transparent aria-expanded:before:border-t-white aria-expanded:before:opacity-100 aria-expanded:before:pointer-events-auto"
        data-action={actionClass}
        aria-expanded={ariaExpanded}
        onClick={toggleAction}
      >
        <i
          role="button"
          aria-label={label}
          className={`${iconClass === 'create-team-action' ? 'w-9 h-9' : 'w-6 h-6'} icon-${iconClass} no-pointer-events`}
        />
      </button>
      {to ? (
        <Link
          to={to}
          className="box-border fixed block p-2 text-center transition-opacity bg-white border rounded-md shadow-xl opacity-0 cursor-pointer pointer-events-none text-inherit left-3 right-3 bottom-[4.25rem] lg:pointer-events-auto lg:rounded-md lg:bottom-0 lg:left-0 lg:pl-14 lg:absolute lg:right-auto lg:top-0 lg:group-hover:opacity-100 lg:group-hover:pointer-events-auto peer-aria-expanded:opacity-100 peer-aria-expanded:pointer-events-auto z-[300]"
        >
          <span className="inline-block px-3 py-2 font-bold leading-snug lg:whitespace-nowrap font-base">
            {label}
          </span>
        </Link>
      ) : (
        <a
          role="button"
          tabIndex={0}
          className="box-border fixed block p-2 text-center transition-opacity bg-white border rounded-md shadow-xl opacity-0 cursor-pointer pointer-events-none text-inherit left-3 right-3 bottom-[4.25rem] lg:pointer-events-auto lg:rounded-md lg:bottom-0 lg:left-0 lg:pl-14 lg:absolute lg:right-auto lg:top-0 lg:group-hover:opacity-100 lg:group-hover:pointer-events-auto peer-aria-expanded:opacity-100 peer-aria-expanded:pointer-events-auto z-[300]"
          onKeyDown={buttonOnClick}
          onClick={buttonOnClick}
        >
          <span className="inline-block px-3 py-2 font-bold leading-snug lg:whitespace-nowrap font-base">
            {label}
          </span>
        </a>
      )}
    </li>
  );
};

ActionItem.propTypes = {
  actionClass: PropTypes.string.isRequired,
  currentAction: PropTypes.string.isRequired,
  toggleAction: PropTypes.func.isRequired,
  iconClass: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  label: PropTypes.string.isRequired,
  to: PropTypes.string,
};

ActionItem.defaultProps = {
  to: '',
  onClick: () => {},
};

export default ActionItem;
