class EmailValidateAction {
  constructor(apiService) {
    this.apiService = apiService;
  }

  execute(token) {
    return this.apiService.post(`emails/${token}/verify`);
  }
}

export default EmailValidateAction;
