import React, { Fragment, useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';

import PropTypes from 'prop-types';

const positionMap = {
  top: {
    popover: 'bottom-full -translate-y-4 -translate-x-1/2 left-1/2',
    svg: 'after:-bottom-2 after:left-1/2',
  },
  bottom: {
    popover: 'top-full translate-y-4 -translate-x-1/2 left-1/2',
    svg: 'after:-top-2 after:left-1/2',
  },
  left: {
    popover: '-translate-x-4 right-full top-0 translate-y-4',
    svg: 'after:top-1/2 after:-right-2',
  },
  right: {
    popover: 'translate-x-4 left-full top-0 translate-y-4',
    svg: 'after:top-1/2 after:-left-2',
  },
};

// eslint-disable-next-line react/display-name
const PopoverContent = forwardRef(({
  anchorEl,
  defaultPosition,
  children,
  className,
  singleLineClassName,
  isStatic,
}, ref) => {
  const popoverRef = useRef(null);
  const [position, setPosition] = useState(defaultPosition);

  useEffect(() => {
    const anchorRect = anchorEl.current.getBoundingClientRect();
    const spaceBelow = window.innerHeight - anchorRect.bottom;
    let newPosition = defaultPosition;
    if (window?.screen?.width < 640 && spaceBelow < 200) newPosition = 'top';
    if (window?.screen?.width < 640 && spaceBelow > 200) newPosition = 'bottom';
    setPosition(newPosition);
  }, [defaultPosition]);

  const updatePopoverPosition = () => {
    if (anchorEl.current && popoverRef.current) {
      const anchorRect = anchorEl.current.getBoundingClientRect();
      const popoverRect = popoverRef.current.getBoundingClientRect();

      const spaceToRight = window.innerWidth - anchorRect.right;
      const spaceToLeft = anchorRect.left;
      const spaceAbove = anchorRect.top;
      const spaceBelow = window.innerHeight - anchorRect.bottom;

      let newPosition = '';

      const notEnoughLeftSpace = spaceToLeft <= popoverRect.width;
      const notEnoughRightSpace = spaceToRight <= popoverRect.width;
      const notEnoughTopSpace = spaceAbove <= popoverRect.height;
      const notEnoughBottomSpace = spaceBelow <= popoverRect.height;

      if (window?.screen?.width < 640 && spaceBelow < 200) {
        newPosition = 'top';
      } else if ((notEnoughRightSpace && notEnoughLeftSpace) || (notEnoughTopSpace && defaultPosition === 'top')) {
        newPosition = 'bottom';
      } else if (notEnoughRightSpace && defaultPosition === 'right') {
        newPosition = 'left';
      } else if (notEnoughLeftSpace && defaultPosition === 'left') {
        newPosition = 'right';
      } else if (notEnoughBottomSpace && defaultPosition === 'bottom') {
        newPosition = 'top';
      } else {
        const maxValue = Math.max(spaceToRight, spaceToLeft, spaceAbove, spaceBelow);

        if (maxValue === spaceToRight) {
          newPosition = 'right';
        } else if (maxValue === spaceToLeft) {
          newPosition = 'left';
        } else if (maxValue === spaceAbove) {
          newPosition = 'top';
        } else if (maxValue === spaceBelow) {
          newPosition = 'bottom';
        } else {
          newPosition = position;
        }
      }

      setPosition(newPosition);
    }
  };

  useImperativeHandle(ref, () => ({
    updatePopoverPosition,
  }));

  useEffect(() => {
    if (!isStatic) {
      window.addEventListener('scroll', updatePopoverPosition, true);

      return () => {
      // Clean up
        window.removeEventListener('scroll', updatePopoverPosition, true);
      };
    }
    return () => {
      window.removeEventListener('scroll', updatePopoverPosition, false);
    };
  }, []);

  return (
    <Transition
      as={Fragment}
      enter="transition ease-out"
      enterFrom="opacity-0 translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-1"
    >
      <Popover.Panel className={twMerge(`absolute drop-shadow-lg z-1000 ${positionMap[position].popover} w-screen max-w-sm px-4 transform sm:px-0`, className)}>
        <div ref={popoverRef} className={twMerge(`relative flex flex-col gap-4 p-4 bg-white rounded-md after:absolute after:w-4 after:h-4 after:rotate-45 after:bg-white drop-shadow-lg ring-1 ring-black/5 ${positionMap[position].svg}`, singleLineClassName)}>
          {children}
        </div>
      </Popover.Panel>
    </Transition>
  );
});

PopoverContent.propTypes = {
  defaultPosition: PropTypes.string.isRequired,
  className: PropTypes.string,
  singleLineClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  anchorEl: PropTypes.shape({
    current: PropTypes.shape({
      getBoundingClientRect: PropTypes.func,
    }),
  }),
  isStatic: PropTypes.bool,
};

PopoverContent.defaultProps = {
  anchorEl: null,
  className: null,
  singleLineClassName: null,
  isStatic: false,
};

const PopoverComponent = ({
  content,
  className,
  singleLineClassName,
  position,
  children,
  onHover,
  fullWidthAtMobile,
  isStatic,
}) => {
  const popoverRef = useRef();
  const triggerRef = useRef();
  const timeOutRef = useRef();
  const contentRef = useRef();

  const handleEnter = isOpen => {
    if (onHover && !isOpen) {
      triggerRef?.current.click();
      timeOutRef.current = setTimeout(() => { contentRef.current.updatePopoverPosition(); }, 120);
    }
  };

  const handleLeave = isOpen => {
    clearTimeout(timeOutRef.current);
    if (onHover && isOpen) triggerRef?.current.click();
  };

  return (
    <Popover className={`${fullWidthAtMobile ? 'w-full md:w-auto' : null}`}>
      {({ open }) => (
        <div
          ref={popoverRef}
          className="relative"
          onMouseEnter={() => handleEnter(open)}
          onMouseLeave={() => handleLeave(open)}
        >
          <Popover.Button ref={triggerRef} as="div">
            {children}
          </Popover.Button>
          <PopoverContent
            ref={contentRef}
            anchorEl={triggerRef}
            defaultPosition={position}
            className={className}
            singleLineClassName={singleLineClassName}
            isStatic={isStatic}
          >
            {content}
          </PopoverContent>
        </div>
      )}
    </Popover>
  );
};

PopoverComponent.propTypes = {
  position: PropTypes.string,
  children: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  className: PropTypes.string,
  singleLineClassName: PropTypes.string,
  onHover: PropTypes.bool,
  fullWidthAtMobile: PropTypes.bool,
  isStatic: PropTypes.bool,
};

PopoverComponent.defaultProps = {
  position: 'right',
  className: null,
  singleLineClassName: null,
  onHover: false,
  fullWidthAtMobile: false,
  isStatic: false,
};

export default PopoverComponent;
