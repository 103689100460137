import React from 'react';
import PropTypes from 'prop-types';

import ProfileCardTextWithButton from '../../../ProfileCards/ProfileCardTextWithButton';
import Button from '../../../common/Button/index';

const DeleteTeamButton = ({
  isOwner,
  isDeleteConfirmed,
  confirmDelete,
  cancelDelete,
  completeDelete,
}) => {
  if (!isOwner) return null;

  if (isDeleteConfirmed) {
    const options = {
      messageText:
        'Deleting a team does not break the individual connections created among the team members. Are you sure you want to delete the team?',
      confirmText: 'Yes, Delete',
      confirmButton: {
        isWarning: true,
        isNormalCase: true,
        onClick: completeDelete,
      },
      cancelText: 'No, Keep',
      cancelButton: {
        isNormalCase: true,
        variant: 'secondary',
        onClick: cancelDelete,
      },
    };

    return (
      <div className="my-2">
        <ProfileCardTextWithButton {...options} />
      </div>
    );
  }

  return (
    <div className="px-2 my-4">
      <Button className="m-auto" onClick={confirmDelete} isWarning>
        Delete Team
      </Button>
    </div>
  );
};

DeleteTeamButton.propTypes = {
  isOwner: PropTypes.bool.isRequired,
  isDeleteConfirmed: PropTypes.bool.isRequired,
  confirmDelete: PropTypes.func.isRequired,
  cancelDelete: PropTypes.func.isRequired,
  completeDelete: PropTypes.func.isRequired,
};

export default DeleteTeamButton;
