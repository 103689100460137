import React from 'react';

const StarterDescription = () => (
  <div>
    <p className="text-sm">With Aperian Starter, you will have full access to:</p>
    <ul className="mx-0 my-4 text-sm font-normal list-none ps-2">
      <li>GlobeSmart Profile Survey</li>
      <li>GlobeSmart Profile Teams, Comparisons, and Advice</li>
      <li>100+ GlobeSmart Guides</li>
      <li>Aperian Copilot</li>
    </ul>
  </div>
);

export default StarterDescription;
