import React from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';

import Button from '../Button';

const BackArrowLink = ({ to, children, onClick }) => (to ? (
  <Link
    className="uppercase mb-8 font-sans font-bold tracking-widest relative flex items-center text-charcoal-600 before:border-r-charcoal-600 before:border-solid before:border-[6px] before:border-t-transparent before:border-b-transparent before:border-l-transparent before:inline-block before:mr-2 before:mb-[2.5px] before:content-[''] before:transition-transform before:duration-200 before:ease-in-out hover:before:-translate-x-1 text-sm hover:underline hover:underline-offset-4 w-fit"
    to={to}
  >
    {children}
  </Link>
) : (
  <Button
    className="-mx-5 bg-white uppercase font-sans font-bold tracking-widest relative flex items-center text-charcoal-600 before:border-r-charcoal-600 before:border-solid before:border-[6px] before:border-t-transparent before:border-b-transparent before:border-l-transparent before:inline-block before:mr-2 before:mb-[2.5px] before:content-[''] before:transition-transform before:duration-200 before:ease-in-out hover:before:-translate-x-1 text-sm hover:underline hover:underline-offset-4
    hover:bg-transparent w-fit"
    outlinedColor="white"
    onClick={onClick}
  >
    {children}
  </Button>
));

BackArrowLink.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

BackArrowLink.defaultProps = {
  to: '',
  onClick: () => {},
};

export default BackArrowLink;
