import React, { Component } from 'react';
import ElementQuery from 'react-eq';

import { defaultProps, propTypes } from './types';

import { translateNumber } from '../../../lib/translate-number';

const svgWidth = 650;
const svgHeight = 400;

class ProfileTraitLine extends Component {
  componentDidMount() {
    window.addEventListener('resize', this.updateTraitLinePositions);
    window.addEventListener('load', this.updateTraitLinePositions);
    window.addEventListener('eq-update', this.updateTraitLinePositions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateTraitLinePositions);
    window.removeEventListener('load', this.updateTraitLinePositions);
    window.removeEventListener('eq-update', this.updateTraitLinePositions);
  }

  /**
   *  Save a reference to this traitLine element for future position calculations
   *  @param {DOMNode} el
   */
  addTraitLine = el => {
    if (el) {
      const id = el.getAttribute('data-chart-for');
      this.traitLine = { id, el };
    }
  };

  /**
   *  Calculate current traitLine positions and pass to onTraitLinePositionsChanged
   */
  updateTraitLinePositions = () => {
    if (this.traitLine) {
      const firstPoint = this.traitLine.el.querySelector(
        '.traitline__point--one',
      );
      const traitRect = firstPoint.getBoundingClientRect();
      const traitRectCenter = traitRect.left + traitRect.width / 2;

      this.props.onTraitLinePositionsChanged(
        this.traitLine.id,
        traitRectCenter,
      );
    }
  };

  values() {
    return [
      this.props.independent_interdependent,
      this.props.egalitarianism_status,
      this.props.risk_certainty,
      this.props.direct_indirect,
      this.props.task_relationship,
    ];
  }

  whichPoint() {
    if (this.props.isCulture) {
      return <rect className="point" height="6" width="6" x="2" y="2" />;
    }

    return <circle className={`point${this.props.isHistoryProfile ? ' history' : ''}`} r="3" cx="5" cy="5" />;
  }

  drawPoints() {
    return this.values().map((item, i) => {
      const distanceFromEdge = item * 10;
      const edgeOnRight = 100; // 0 is on left, 100% is on right
      const positionOnX = edgeOnRight - distanceFromEdge;

      const position = {
        left: `${positionOnX - 1}%`,
      };
      const pointClasses = `traitline__point traitline__point--${translateNumber(
        i + 1,
      )}${this.props.isHistoryProfile ? ' history' : ''}`;

      /* eslint react/no-array-index-key: 0 */
      return (
        <div key={`circle-${i}`} style={position} className={pointClasses}>
          <svg
            width="32"
            height="32"
            viewBox="0 0 10 10"
            preserveAspectRatio="xMidYMin slice"
            visibility={this.props.myProfileVisibility}
          >
            {this.whichPoint()}
          </svg>
        </div>
      );
    });
  }

  drawLine() {
    // Convert the Object to an array of just the values
    const items = this.values(this.props);

    if (!items.length) {
      return null;
    }

    const viewBox = `-5 -5 ${svgWidth + 10} ${svgHeight + 10}`;
    let line = '';

    items.forEach((item, i) => {
      const distanceFromEdge = (item / 10) * svgWidth;
      const edgeOnRight = svgWidth; // 0 is on left, svgWidth is on right
      const positionOnX = edgeOnRight - distanceFromEdge;
      const isLastPoint = i === items.length - 1;

      const point = {
        x: isLastPoint || this.props.isCompare ? positionOnX : positionOnX + 7.5,
        y: i * 100,
      };
      line += `${point.x} ${point.y}, `;
    });

    line = line.slice(0, -2); // chop off whitespace and final comma

    return (
      <svg
        className={`traitline__path-svg${this.props.isHistoryProfile ? ' history' : ''}`}
        width="100%"
        viewBox={viewBox}
        preserveAspectRatio="none"
        visibility={this.props.myProfilVisibility}
      >
        <polyline
          fill="none"
          stroke="black"
          className={`line${this.props.isHistoryProfile ? ' history' : ''}`}
          points={line}
          visibility={this.props.myProfileVisibility}
        />
      </svg>
    );
  }

  renderFirstClasses(className) {
    return this.props.first ? `${className}` : '';
  }

  render() {
    return (
      <ElementQuery
        queries={{
          trait480: 480,
          trait640: 640,
          trait800: 800,
          trait960: 960,
          trait1280: 1280,
        }}
      >
        <div
          className={`traitline${this.renderFirstClasses(' traitline--first')}`}
        >
          <div
            data-chart-for={this.props.id}
            className="traitline__container"
            ref={this.addTraitLine}
          >
            <div className="traitline__path-container">{this.drawLine()}</div>
            {this.drawPoints()}
          </div>
        </div>
      </ElementQuery>
    );
  }
}

ProfileTraitLine.propTypes = propTypes;
ProfileTraitLine.defaultProps = defaultProps;

export default ProfileTraitLine;
