import React from 'react';
import PropTypes from 'prop-types';
import parser from 'html-react-parser';
import DOMPurify from 'isomorphic-dompurify';

import AdviceTopic from '../AdviceTopic';

const AdviceTopicsListing = ({
  adviceTopics,
  onClick,
  isAuthorized,
  searchParams,
  shortDisplayName,
}) => {
  if (!adviceTopics) return null;
  return (
    <div className="text-center">
      <hr className="w-full h-px my-10 border-0 bg-rust-500" />
      <h2>More advice topics for working with {parser(DOMPurify.sanitize(shortDisplayName, { ADD_ATTR: ['target'] }))}</h2>
      <hr className="w-24 h-0.5 my-10 border-0 bg-rust-500 mx-auto" />
      <div className="flex flex-wrap justify-center gap-5">
        {adviceTopics.map(topic => (
          <AdviceTopic
            key={topic.linkText}
            {...topic}
            onClick={onClick}
            isAuthorized={isAuthorized}
            searchParams={searchParams}
          />
        ))}
      </div>
    </div>
  );
};

AdviceTopicsListing.propTypes = {
  adviceTopics: PropTypes.arrayOf(
    PropTypes.shape({
      linkText: PropTypes.string.isRequired,
      topic_id: PropTypes.string.isRequired,
    }),
  ),
  onClick: PropTypes.func.isRequired,
  searchParams: PropTypes.string.isRequired,
  isAuthorized: PropTypes.bool,
  shortDisplayName: PropTypes.string,
};

AdviceTopicsListing.defaultProps = {
  adviceTopics: [],
  shortDisplayName: '',
  isAuthorized: false,
};

export default AdviceTopicsListing;
