import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import LineChart from '../../../LineChart';
import Button from '../../../common/Button';

import { trackCompleteOnboardingStep } from '../../../../lib/tracker/onboarding';

const MiniComparison = ({ nextStep, formData }) => {
  const [scores, setScores] = useState([100 - formData.dimensionPercent]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      // add fake member score
      setScores([...scores, formData.dimensionPercent <= 44 ? 25 : 75]);
    }, 750);
    return () => clearTimeout(timeout);
  }, []);

  const continueOnboardingFlow = async () => {
    await trackCompleteOnboardingStep('DI Comparison');
    nextStep();
  };

  const renderComparisonMessage = () => {
    let comparisonMessage;
    switch (formData.dimension) {
      case 'Direct':
        comparisonMessage = 'It looks like you can expect this team member to be less direct than you.';
        break;
      case 'Indirect':
        comparisonMessage = 'It looks like you can expect this team member to be more direct than you.';
        break;
      case 'in the middle':
        comparisonMessage = 'Let\'s consider your communication style compared to someone who is less direct than you.';
        break;
      default:
        comparisonMessage = '';
    }
    return comparisonMessage;
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 min-h-100">
      <div className="flex justify-center w-full px-8 py-12 m-auto bg-white md:p-0 lg:p-4 md:bg-ivory-400 md:w-3/5 lg:w-4/6 lg:justify-start max-h-90 lg:m-0">
        <div className="w-full">
          <p className="font-serif text-2xl italic tracking-wider text-left">Your communication style compared to an example team member</p>
          <LineChart
            leftText="Direct"
            rightText="Indirect"
            points={scores}
          />
        </div>
      </div>
      <div className="flex justify-center w-4/5 pt-10 m-auto text-left max-h-90 md:p-0">
        <div>
          <h1 className="mb-6 font-serif text-4xl font-normal tracking-wider md:text-5xl">Understanding others.</h1>
          <p className="mb-4 font-serif text-xl italic leading-6 tracking-wider text-rust-500 md:text-3xl md:leading-10 md:mb-6">
            {renderComparisonMessage()}
          </p>
          <p className="mb-8 font-sans text-sm text-gray-800 md:text-lg">Understanding our own preferred communication styles is key to working inclusively with others. Factors like our upbringing, cultural background, beliefs, and more can influence how we communicate. By recognizing and respecting these differences, we can build more effective and collaborative relationships.</p>
          <div className="max-w-2xl">
            <Button
              variant="primary"
              trailingButtonIcon="arrow-right"
              onClick={continueOnboardingFlow}
            >Continue
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

MiniComparison.propTypes = {
  nextStep: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    dimension: PropTypes.string,
    dimensionPercent: PropTypes.number,
  }).isRequired,
};

export default MiniComparison;
