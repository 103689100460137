import React from 'react';
import PropTypes from 'prop-types';
import ElementQuery from 'react-eq';

import Button from '../../../../common/Button';

const renderInfo = (membersFound, onRemove) => membersFound.map(member => (
  <tr key={member.id} className="admin-list__item">
    <td className="admin-list__cell admin-list__cell--name" data-label="First Name">
      {member.firstName}
    </td>
    <td className="admin-list__cell admin-list__cell--name" data-label="Last Name">
      {member.lastName}
    </td>
    <td className="admin-list__cell" data-label="Email Address">
      <span className="admin-list__email">{member.email}</span>
    </td>
    <td className="admin-list__cell" data-label="Remove Owner">
      <Button isSmall isWarning onClick={e => onRemove(member.id, e)}>Remove</Button>
    </td>
  </tr>
));

const OrgConfirmUsersList = ({ membersFound, onRemove }) => (
  <ElementQuery queries={{ 'table-view': 700 }}>
    <table className="admin-list">
      <thead className="admin-list__header">
        <tr>
          <th className="admin-list__cell admin-list__cell--last-name">
            First Name
          </th>
          <th className="admin-list__cell admin-list__cell--first-name">
            Last Name
          </th>
          <th className="admin-list__cell">
            Email
          </th>
          <th className="admin-list__cell">
            Remove
          </th>
        </tr>
      </thead>
      <tbody className="admin-list__container">
        {renderInfo(membersFound, onRemove)}
      </tbody>
    </table>
  </ElementQuery>
);

OrgConfirmUsersList.propTypes = {
  membersFound: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      userId: PropTypes.string,
      email: PropTypes.string,
    }),
  ),
  onRemove: PropTypes.func,
};

OrgConfirmUsersList.defaultProps = {
  membersFound: [],
  onRemove: () => {},
};

export default OrgConfirmUsersList;
