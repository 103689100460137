import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../common/Button';

const DeleteOrganizationModal = ({ onConfirm, onCancel }) => (
  <>
    <h2>Are you sure you want to delete this organization?</h2>
    <div className="text-left">
      <p className="mt-0.5">Prior to deleting an organization you should:
      </p>
      <ol>
        <li>Cancel all Stripe Plan subscriptions</li>
        <li>Remove all domains and Auth0 connections</li>
      </ol>
      <p className="mt-0.5">
        Deleting an organization will delete all data related to the organization, including:
      </p>
      <ul>
        <li>All user data for organization members</li>
        <li>All usage statistics and reports (IBI Groups, IBI Organization, GSP Org Profile)</li>
        <li>All organization records</li>
      </ul>
      <p>
        This cannot be undone. After deletion, you will be returned to the Organizations screen.
      </p>
    </div>
    <div className="flex justify-end gap-2">
      <Button onClick={onCancel}>No, Cancel</Button>
      <Button onClick={onConfirm} outlinedColor="red">Yes, Delete This Organization</Button>
    </div>
  </>
);

DeleteOrganizationModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default DeleteOrganizationModal;
