import React from 'react';
import PropTypes from 'prop-types';

const DimensionLink = ({ isProfile, trackViewDimensionClick }) => isProfile && (
  <div className="my-1 text-center">
    <a
      className="profile-dimension__module-link"
      target="_blank"
      // eslint-disable-next-line react/jsx-indent-props
      href="https://resources.aperian.com/mod/cd/index.html"
      onClick={trackViewDimensionClick}
      rel="noopener noreferrer"
    >Learn more about the GlobeSmart Dimensions
    </a>
  </div>
);

DimensionLink.propTypes = {
  isProfile: PropTypes.bool,
  trackViewDimensionClick: PropTypes.func,
};

DimensionLink.defaultProps = {
  isProfile: false,
  trackViewDimensionClick: undefined,
};

export default DimensionLink;
