import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Icon from '../../Icon';

const DropdownMenu = ({
  options,
  value,
  onChangeValue,
  isTransparent,
  isRound,
}) => {
  const defaultOption = options.find(option => option.value === value) || options.at(0);
  const [selectedOption, setSelectedOption] = useState(defaultOption);
  const [showOptions, setShowOptions] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const onShowOptions = () => setShowOptions(!showOptions);

  useEffect(() => {
    function handleClickOutside(e) {
      if ((dropdownRef?.current && !dropdownRef.current.contains(e.target))
        && (buttonRef?.current && !buttonRef.current.contains(e.target))) {
        e.preventDefault();
        buttonRef.current.blur();
        onShowOptions();
      }
    }
    if (showOptions) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [showOptions]);

  const handleChangeOption = newOption => {
    onChangeValue(newOption.value);
    setSelectedOption(newOption);
    setShowOptions(false);
  };

  return (
    <div className="relative inline-block text-left ">
      <button
        className={`m-auto relative right-0 z-10 min-w-[15ch] flex items-center justify-between px-4 py-2 origin-top-right ${isTransparent ? '' : ' bg-white shadow-sm ring-1 hover:bg-stone-25 focus:ring-inset focus:ring-rust-500 focus:shadow-input ring-rust-500 ring-opacity-5 focus:outline-none'} ${isRound ? 'rounded-full' : 'rounded-md'}`}
        onClick={onShowOptions}
        ref={buttonRef}
      >
        <span className="flex-grow text-base font-medium leading-6 text-left text-gray-700">
          {selectedOption.name}
        </span>
        <Icon
          icon="chevron-down"
          iconColor="gray"
          className={`ml-4 w-3 h-3 ${showOptions ? '-rotate-180 transition-transform duration-500'
            : 'rotate-0 transition-transform duration-500'}`}
        />
      </button>
      <ul ref={dropdownRef} className={`absolute right-0 z-[400] w-48 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${showOptions ? 'opacity-100' : 'opacity-0 pointer-events-none'} transition-opacity duration-300 ease-in-out`}>
        {options.map(option => (
          <li key={option.value}>
            <button
              type="button"
              className="block w-full px-4 py-2 text-sm text-left text-gray-700 border-none hover:bg-gray-100 active:text-gray-900"
              onClick={() => handleChangeOption(option)}
            >
              {option.name}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

DropdownMenu.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })).isRequired,
  value: PropTypes.string,
  onChangeValue: PropTypes.func.isRequired,
  isTransparent: PropTypes.bool,
  isRound: PropTypes.bool,
};

DropdownMenu.defaultProps = {
  value: '',
  isTransparent: false,
  isRound: false,
};

export default DropdownMenu;
