import PropTypes from 'prop-types';

const propTypes = {
  questionId: PropTypes.number.isRequired,
  responseValue: PropTypes.number.isRequired,
  responseText: PropTypes.string.isRequired,
  selectedOption: PropTypes.number,
  onOptionChange: PropTypes.func.isRequired,
};

const defaultProps = {
  selectedOption: null,
};

export {
  propTypes,
  defaultProps,
};
