import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';

import { Context } from '../../context/ContextProvider';
import withServerSideData from '../../HOC/withServerSideData';

import PageHeader from '../../components/PageHeader';
import Tabs from '../../components/Tabs';
import AdminCenterList from './AdminCenterList';

import needsAuthentication from '../../lib/needsAuthentication';
import { COOKIE_NOTIFICATION, IS_PRODUCTION } from '../../lib/constants';

const renderComponent = (children, currentOrg) =>
  (currentOrg ? React.cloneElement(children, { currentOrg }) : null);

const AdminCenterAuth = ({ location, initialData, children, params: { orgId } }) => {
  const [currentOrg, setCurrentOrg] = useState(null);
  const { currentUser, router } = useContext(Context);
  const { organizationsOwned } = initialData;

  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookies] = useCookies();

  needsAuthentication(router, currentUser, 'redirect_to=/admin-center');

  useEffect(() => {
    if (orgId) {
      setCurrentOrg(organizationsOwned.find(({ id }) => id === orgId));
      return;
    }

    switch (organizationsOwned.length) {
      case 0:
        setCookies(COOKIE_NOTIFICATION,
          { type: 'failure', message: 'You are not authorized to view this page.' },
          { path: '/', secure: IS_PRODUCTION });
        router.replace('/dashboard');
        break;
      case 1: {
        const [org] = organizationsOwned;
        router.push(`/admin-center/${org.id}/people`);
      }
        break;
      default:
    }
  }, [orgId]);

  const tabs = [{
    name: 'People and Invites',
    link: `/admin-center/${orgId}/people`,
  },
  {
    name: 'Products and Dashboard',
    link: `/admin-center/${orgId}/products`,
  }];

  if (currentOrg?.databoxEmbedLink) {
    tabs.push({
      name: 'Insights and Trends',
      link: `/admin-center/${orgId}/trends`,
    });
  }

  return (
    <>
      <PageHeader
        pageTitle="Admin Center"
        orgId={orgId}
        currentOrg={currentOrg}
      />
      {!orgId && organizationsOwned.length > 1
        ? (
          <AdminCenterList organizationsOwned={organizationsOwned} />
        )
        : (
          <div className="mb-12">
            <Tabs tabs={tabs} location={location} />
            {renderComponent(children, currentOrg)}
          </div>
        )}
    </>
  );
};

AdminCenterAuth.getAPIDataKey = () => 'adminCenteAuth';
AdminCenterAuth.getData = apiService => apiService.get('admin-center/list')
  .then(data => ({ adminCenteAuth: data }));

AdminCenterAuth.propTypes = {
  children: PropTypes.node,
  initialData: PropTypes.shape({
    organizationsOwned: PropTypes.arrayOf(
      PropTypes.shape({
        orgId: PropTypes.string,
        orgName: PropTypes.string,
      }),
    ),
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  params: PropTypes.shape({
    orgId: PropTypes.string,
  }).isRequired,
};

AdminCenterAuth.defaultProps = {
  children: null,
};

export default withServerSideData(AdminCenterAuth);
