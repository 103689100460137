import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import DOMPurify from 'isomorphic-dompurify';

import SectionContainer from '../common/Containers/Section';
import LabelTextArea from '../common/LabelTextarea';
import Button from '../common/Button';

const CustomMessage = (
  {
    isOwner,
    customMessage,
    isCustomMessageEditing,
    toggleCustomMessageEditing,
    customMessageHandleOnChange,
    saveCustomMessage,
  },
) => isOwner && (
  <SectionContainer title="Custom Message">
    {!isCustomMessageEditing ? (
      <div className="card">
        <div className="card__container card__container--confirm">
          <div className="p-4 card__content">
            {parse(DOMPurify.sanitize(customMessage, { USE_PROFILES: [] }))}
          </div>
          <div className="card__actions">
            <Button
              key="edit"
              onClick={toggleCustomMessageEditing}
              isSmall
              variant="secondary"
            >Edit
            </Button>
          </div>
        </div>
      </div>
    )
      : (
        <div className="card">
          <div className="p-4 card">
            <LabelTextArea
              id="customMessage"
              name="customMessage"
              labelText="Enter Custom Message"
              labelType="text"
              value={DOMPurify.sanitize(customMessage, { USE_PROFILES: [] })}
              customClass="textarea__custom-message"
              onChangeValue={customMessageHandleOnChange}
            />

            <div className="flex items-center justify-end gap-1 my-2">
              <Button
                key="cancel"
                isWarning
                isNormalCase
                onClick={toggleCustomMessageEditing}
              >
                Cancel
              </Button>
              <Button
                key="save"
                variant="primary"
                isSmall
                onClick={saveCustomMessage}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      )}
  </SectionContainer>
);

CustomMessage.propTypes = {
  isOwner: PropTypes.bool.isRequired,
  customMessage: PropTypes.string,
  isCustomMessageEditing: PropTypes.bool.isRequired,
  toggleCustomMessageEditing: PropTypes.func.isRequired,
  saveCustomMessage: PropTypes.func.isRequired,
  customMessageHandleOnChange: PropTypes.func.isRequired,
};

CustomMessage.defaultProps = {
  customMessage: '',
};

export default CustomMessage;
