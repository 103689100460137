import React from 'react';
import PropTypes from 'prop-types';

import LabelInput from '../../common/LabelInput';

const DashboardBlockSearch = (
  {
    filterValue,
    handleChange,
    handleInputOnBlur,
    handleClearFilter,
  },
) => (
  <div className="top-0 z-[301] overflow-hidden xl:sticky bg-ivory-400">
    <div className="my-4">
      <LabelInput
        id="filterValue"
        name="filterValue"
        labelType="text"
        value={filterValue}
        onChangeValue={handleChange}
        type="text"
        placeholderText="Start typing to search the dashboard..."
        leadingIcon="search"
        onBlur={handleInputOnBlur}
        trialingIcon="x-close"
        handleIconClick={handleClearFilter}
        iconButtonDisabled={!filterValue}
      />
    </div>
  </div>
);

DashboardBlockSearch.propTypes = {
  filterValue: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleInputOnBlur: PropTypes.func.isRequired,
  handleClearFilter: PropTypes.func.isRequired,
};

export default DashboardBlockSearch;
