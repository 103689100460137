import React, {
  useContext,
  useReducer,
  useRef,
  useEffect,
  useState,
} from 'react';

import PropTypes from 'prop-types';

import { APIContext } from '../../context/API';
import { RouterContext } from '../../context/Router';
import withServerSideData from '../../HOC/withServerSideData';

import PageHeader from '../../components/PageHeader';
import TeamInvitationForm from '../../components/custom/TeamInvitationForm';

import createGSPTeamAction from '../../actions/teams/teamCreate';

import { trackCreateTeamSuccess } from '../../lib/tracker/team';
import { accessCodeList } from '../../lib/accessCodeList';
import { extractEmails } from '../../lib/inputValidation/extractEmail';
import logger from '../../lib/logger';

const TeamInvitation = ({ initialData: { accessCodes: initialAccessCodes } }) => {
  const { apiService } = useContext(APIContext);
  const { router } = useContext(RouterContext);
  const { accessCodes, defaultAccessCode } = accessCodeList({
    codes: initialAccessCodes, onlyPremium: true,
  });

  const [formData, setFormData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      teamName: '',
      ownerEmails: '',
      customMessage: '',
      memberEmails: '',
      selectedAccessCode: defaultAccessCode,
    });

  const [validatedEmails, setValidatedEmails] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      ownerEmails: [],
      memberEmails: [],
    });

  const [formError, setFormError] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      teamName: null,
      ownerEmails: null,
      memberEmails: null,
    });

  const [notification, setNotification] = useState(null);
  const formRef = useRef();

  useEffect(() => {
    if (notification) {
      window.scroll(0, 0);
    }
  }, [notification]);

  useEffect(() => {
    if (formError.teamName) {
      formRef?.current.teamName.focus();
      return;
    }
    if (formError.ownerEmails) {
      formRef?.current.ownerEmails.focus();
      return;
    }
    if (formError.memberEmails) {
      formRef?.current.memberEmails.focus();
    }
  }, [formError]);

  const updateAccessCode = e => {
    const accessCodeSet = e.target.value;
    setFormData({ selectedAccessCode: accessCodeSet });
  };

  const handleOnChange = ({ target: { name, value } }) => {
    setFormData({ [name]: value });
    if (formError[name]) setFormError({ [name]: null });
  };

  const validateEmails = ({ target: { name, value } }) => {
    const extractedEmails = extractEmails(value);
    if (extractedEmails instanceof Error) {
      setFormError({ [name]: extractedEmails.message });
    } else {
      setFormData({ [name]: value });
      setValidatedEmails({ [name]: extractedEmails });
      setFormError({ [name]: null });
    }
  };

  async function handleFormSubmit(e) {
    e.preventDefault();

    const { teamName, customMessage, selectedAccessCode } = formData;
    if (!teamName) {
      setFormError({ teamName: 'Please enter a name for the team.' });
      return;
    }

    if (!formData.memberEmails) {
      setFormError({ memberEmails: 'You must invite at least one person.' });
      return;
    }

    if (formError.teamName || formError.memberEmails) {
      return;
    }

    const { ownerEmails, memberEmails } = validatedEmails;
    const ownerInvitationEmails = ownerEmails.map(email =>
      ({ email, accessCode: '' }));
    const memberInvitationEmails = memberEmails.map(email =>
      ({ email, accessCode: selectedAccessCode }));

    const payload = {
      name: teamName,
      ownerInvitationEmails,
      memberInvitationEmails,
      customMessage,
    };

    const genericErrorMessage = 'Something went wrong. Please try again later.';

    try {
      const response = await createGSPTeamAction(apiService, payload);
      if (response && response.message) {
        const readableErrorMessage = response.message.includes('E_VALIDATION')
          ? 'An email you submitted was invalid.'
          : genericErrorMessage;
        setNotification({ type: 'warning', message: readableErrorMessage });
      } else {
        const trackData = {
          ...payload,
          memberEmails,
          teamSize: memberInvitationEmails.length,
        };
        await trackCreateTeamSuccess(trackData)
          .then(() => { router.push(`/profile/teams/${response.team.id}`); });
      }
    } catch (err) {
      logger.error(`Error creating team.
        Payload: ${JSON.stringify(payload, null, 2)}
        Error: ${err}`);
      setNotification({ type: 'warning', message: genericErrorMessage });
    }
  }

  return (
    <>
      <PageHeader
        pageTitle="Create A Team"
        icon="profile"
        backLink={{ to: '/profile/teams', text: 'Back to my Teams' }}
        skipTarget="#create-team"
      />
      <TeamInvitationForm
        ref={formRef}
        formData={formData}
        formError={formError}
        accessCodes={accessCodes}
        handleOnChange={handleOnChange}
        updateAccessCode={updateAccessCode}
        validateEmails={validateEmails}
        handleFormSubmit={handleFormSubmit}
        notification={notification}
      />
    </>
  );
};
TeamInvitation.getAPIDataKey = () => 'TeamInvitation';
TeamInvitation.getData = apiService =>
  apiService.get('access-codes')
    .then(data => ({ TeamInvitation: data }));

TeamInvitation.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  initialData: PropTypes.shape({
    accessCodes: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        description: PropTypes.string,
        internalNotes: PropTypes.string,
        quantity: PropTypes.number,
        active: PropTypes.bool,
        seatsRemaining: PropTypes.number,
        stripePlanId: PropTypes.string,
        created: PropTypes.string,
        expirationDate: PropTypes.string,
        planName: PropTypes.string,
        redirectUrl: PropTypes.string,
      }),
    ),
  }).isRequired,

};

export default withServerSideData(TeamInvitation);
