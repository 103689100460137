import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import ElementQuery from 'react-eq';
import DOMPurify from 'isomorphic-dompurify';

import { propTypes, defaultProps } from './types';
import { Context } from '../../context/ContextProvider';
import { CurrentUserContext } from '../../context/CurrentUser';
import { RouterContext } from '../../context/Router';
import { APIContext } from '../../context/API';
import withServerSideData from '../../HOC/withServerSideData';

import TeamMembers from '../../components/custom/Team/TeamMembers';
import TeamOwners from '../../components/custom/Team/TeamOwners';
import TeamAddOwnerModal from '../../components/custom/Team/TeamAddOwnerModal';
import DeleteTeamButton from '../../components/custom/Team/DeleteTeamButton';

import PageHeader from '../../components/PageHeader';
import SentInvitations from '../../components/Invitations/PendingInvitations/SentInvitations';
import EditableHeader from '../../components/EditableHeader';
import CustomMessage from '../../components/CustomMessage';
import ActionNavigation from '../../components/Navigation/ActionNavigation';
import GlobeSmartModal from '../../components/GlobeSmartModal';
import Notification from '../../components/Notifications/Notification';

import TeamUpdateAction from '../../actions/teams/teamUpdate';
import TeamRemoveMemberAction from '../../actions/teams/teamRemoveMember';
import TeamDeleteAction from '../../actions/teams/teamDelete';
import addTeamOwnerAction from '../../actions/teams/teamAddOwner';
import removeTeamOwnerAction from '../../actions/teams/teamRemoveOwner';
import InvitationCancelAction from '../../actions/invitationCancel';
import InvitationResendAction from '../../actions/invitationResend';
import SurveyReminderAction from '../../actions/surveyRemind';

import { teamActionButtonsConfig } from '../../components/custom/Teams/helpers';

import getQuery from '../../lib/urls/getQuery';
import { trackRemoveSelfFromTeam } from '../../lib/tracker/team';

const queries = { 'card-layout': 400 };

const Team = ({
  initialData: {
    id,
    name,
    customMessage,
    isOwner,
    avatarUrl,
    members,
    pendingInvitations,
    owners,
  },
}) => {
  const { currentUser } = useContext(CurrentUserContext);
  const { apiService } = useContext(APIContext);
  const { router } = useContext(RouterContext);
  const { handleOpenModal, handleCloseModal } = useContext(Context);

  const [team, setTeam] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      id,
      name,
      customMessage,
      isOwner,
      avatarUrl,
      members,
      pendingInvitations,
      owners,
    },
  );
  const [editing, setEditing] = useState(false);
  const [currentName, setCurrentName] = useState(name);
  const [customMessageEditing, setCustomMessageEditing] = useState(false);
  const [currentCustomMessage, setCurrentCustomMessage] = useState(customMessage);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [notification, setNotification] = useState(null);
  const [modalNotification, setModalNotification] = useState(null);
  const teamNameRef = useRef();

  useEffect(() => {
    setCurrentCustomMessage(team.customMessage);
  }, [team.customMessage]);

  /**
   * Get latest invitation data from the API and save it to state
   * @type {Promise}  Will resolve with updated Team data object
   */
  const refreshData = () => apiService
    .get(`teams/${id}`)
    .then(data => setTeam({ ...data }));

  /**
   * Mark the card in the list that matches `token` with `resent`
   * @param  {String} token The invitation token from the API
   * @return {Array}        The modified invitation list
   */
  const markInviteResent = (token, sentAt) => pendingInvitations
    .map(invitation => {
      if (invitation.token === token) {
        Object.assign(invitation, { sentAt });
      }
      return invitation;
    });

  /**
   * Call the action to resend the invitation and then mark that
   * invitation in the UI as resent
   * @param  {String} id      We use the token from the API as the ID
   * @return {undefined}
   */
  const onResend = token =>
    new InvitationResendAction(apiService)
      .execute(token)
      .then(data => {
        const resentInvitations = markInviteResent(token, data.sentAt);
        setTeam({ pendingInvitations: resentInvitations });
      })
      .catch(() => router.replace('/error'));

  /**
   * Call the action to cancel the invitation and then remove it from the UI
   * @param  {String} id      We use the token from the API as the ID
   * @return {Promise}
   */
  const onCancel = token =>
    new InvitationCancelAction(apiService)
      .execute(token)
      .then(refreshData)
      .catch(() => router.replace('/error'));

  /**
   * Mark the card in the list that matches `memberId` with `remindedAt`
   * @param  {String} memberId The member id from the click event
   * @param { String} remindedAt The date that the member was reminded
   * @return {Array}        The modified member list
   */
  const markMemberReminded = (memberId, remindedAt) => members
    .map(member => {
      if (member.id === memberId) {
        Object.assign(member, { remindedAt });
      }
      return member;
    });

  /**
   * Call the action to remind the member and then mark that
   * member as reminded in the UI as reminded
   * @param  {String} memberId      We use the user's id
   * @return {undefined}
   */
  const onRemind = memberId => new SurveyReminderAction(apiService)
    .execute(id, memberId)
    .then(response => {
      if (response.status === 200) {
        const remindedMembers = markMemberReminded(memberId, response.remindedAt);
        setTeam({ members: remindedMembers });
      }
    })
    .catch(() => router.replace('/error'));

  /**
   * If the user is removing themself from the team, track in mixpanel
   * @param  {String} memberId   We use the token from the API as the ID
   * @return {Promise}
   */
  function trackIfRemovingSelf(memberId) {
    if (memberId === currentUser.userid) {
      return trackRemoveSelfFromTeam(memberId);
    }
    return Promise.resolve();
  }

  /**
   * Call the action to remove this user from the team and then update the UI
   * @param  {String} memberId   We use the token from the API as the ID
   * @param  {Boolean} redirect   We use it to redirect or refresh the data
   * @return {Promise}
   */
  const onRemoveMember = ({ memberId, redirect }) =>
    new TeamRemoveMemberAction(apiService)
      .execute(id, memberId)
      .then(() => trackIfRemovingSelf(memberId))
      .then(() => (redirect ? router.push('/profile/teams') : refreshData()))
      .catch(() => router.replace('/error'));

  const handleConfirmDelete = () => setConfirmDelete(true);

  const handleCancelDelete = () => setConfirmDelete(false);

  /**
   * Call the action to accept the invitation and then remove it from the UI
   * @return {Promise}
   */
  const handleCompleteDelete = () =>
    new TeamDeleteAction(apiService)
      .execute(id)
      .then(() => router.push('/profile/teams'))
      .catch(() => router.replace('/error'));

  const toggleEditing = () => {
    teamNameRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest',
    });
    setEditing(!editing);
  };

  const saveName = newTeamName => {
    new TeamUpdateAction(apiService)
      .execute(id, { name: newTeamName })
      .then(() => {
        setCurrentName(newTeamName);
        setEditing(!editing);
      });
  };

  const toggleCustomMessageEditing = () => {
    setCustomMessageEditing(!customMessageEditing);
    if (team.customMessage !== currentCustomMessage) {
      setCurrentCustomMessage(team.customMessage);
    }
  };

  const customMessageHandleOnChange = e => setCurrentCustomMessage(e.target.value);

  const saveCustomMessage = () =>
    new TeamUpdateAction(apiService)
      .execute(id, {
        customMessage: DOMPurify.sanitize(currentCustomMessage, { USE_PROFILES: [] }),
      })
      .then(() => {
        setCustomMessageEditing(!customMessageEditing);
        setTeam({ customMessage: currentCustomMessage });
      });

  const addTeamOwner = async ({ invitations }) => {
    try {
      const formSubmitResponse = await addTeamOwnerAction(apiService, id, { invitations });
      if (formSubmitResponse.message === 200) {
        setModalNotification(formSubmitResponse.message);
      } else {
        handleCloseModal();
        refreshData();
      }
    } catch (err) {
      setModalNotification({
        type: 'failure',
        message: err.message
          ? err.message
          : 'There was an error adding a group owner. Please try again later.',
      });
    }
  };

  /**
   * Call the action to remove this owner from the group and then update the UI
   * @param  {Number} ownerId  owner table primary key Id
   * @param  {String} ownerUserId  Since we allow for unregistered users to be added as
   * group owners using their email as an identifier until they've registered.
   * The ownerId referenced here is the actual owner record id which covers both cases.
   * @return {Promise}
   */
  const removeTeamOwner = async (ownerId, ownerUserId) => {
    try {
      const response = await removeTeamOwnerAction(apiService, team.id, ownerId);
      if (response.status === 200) {
        if (currentUser.userid === ownerUserId) {
          router.push('/teams');
        } else {
          refreshData();
        }
      }
    } catch (err) {
      setNotification({
        type: 'failure',
        message: err.message
          ? err.message
          : 'There was an error deleting a team owner. Please try again later.',
      });
    }
  };

  return (
    <>
      <PageHeader
        pageTitle="Manage Team"
        icon="profile"
        backLink={{ to: '/profile/teams', text: 'Back to My Teams' }}
        skipTarget="#manage-team"
      />
      <GlobeSmartModal>
        <TeamAddOwnerModal
          notification={modalNotification}
          handleCloseModal={handleCloseModal}
          handleFormSubmit={addTeamOwner}
          teamName={name}
        />
      </GlobeSmartModal>
      <div className="breakout">
        <ActionNavigation
          items={teamActionButtonsConfig({
            isOwner,
            teamId: id,
            toggleEditing,
          })}
        />
        <div className="main-layout">
          <ElementQuery queries={queries}>
            <div id="manage-team" className="box-border">
              {notification
              && <Notification type={notification.type} message={notification.message} />}
              <div ref={teamNameRef}>
                {currentName && (
                <EditableHeader
                  name="teamname"
                  label="Name"
                  value={currentName}
                  onUpdate={saveName}
                  isEditing={editing}
                  toggleEditing={toggleEditing}
                  isHeading
                />
                )}
              </div>
              {isOwner && (
              <SentInvitations
                heading="Pending Team Members"
                sent={team.pendingInvitations}
                onResend={onResend}
                onCancel={onCancel}
                isInviter={isOwner}
              />
              )}
              <TeamMembers
                members={team.members}
                onRemind={onRemind}
                onRemove={onRemoveMember}
                isOwner={isOwner}
                currentUserId={currentUser.userid}
              />
              <CustomMessage
                isOwner={isOwner}
                customMessage={DOMPurify.sanitize(currentCustomMessage, { USE_PROFILES: [] })}
                isCustomMessageEditing={customMessageEditing}
                toggleCustomMessageEditing={toggleCustomMessageEditing}
                saveCustomMessage={saveCustomMessage}
                customMessageHandleOnChange={customMessageHandleOnChange}
              />
              <TeamOwners
                isOwner={isOwner}
                owners={team.owners}
                onRemove={removeTeamOwner}
                modalOnClick={() => {
                  handleOpenModal({
                    modalSize: 'large',
                    afterCloseAction: () => setModalNotification(null),
                  });
                }}
              />
              <DeleteTeamButton
                isOwner={isOwner}
                isDeleteConfirmed={confirmDelete}
                confirmDelete={handleConfirmDelete}
                cancelDelete={handleCancelDelete}
                completeDelete={handleCompleteDelete}
              />
            </div>
          </ElementQuery>
        </div>
      </div>
    </>
  );
};

Team.getAPIDataKey = props => {
  const id = getQuery('id', props);
  return `team${id}`;
};

Team.getData = (apiService, { id }) => apiService
  .get(`teams/${id}`).then(data => ({ [`team${id}`]: data }));

Team.propTypes = propTypes;
Team.defaultProps = defaultProps;

export default withServerSideData(Team);

const memberPropTypes = propTypes.members;
const pendingInvitationPropTypes = propTypes.pendingInvitations;

export { memberPropTypes, pendingInvitationPropTypes };
