/* eslint-disable quotes */
/* eslint-disable quote-props */
import React from 'react';
import PropTypes from 'prop-types';

import {
  Checkmark,
} from './animations';

export const animationMap = {
  'checkmark': Checkmark,
};

const Animation = ({ animation }) => {
  const Component = animationMap[animation];
  return (
    <Component
      animation={animation}
    />
  );
};

Animation.propTypes = {
  animation: PropTypes.string.isRequired,
};

export default Animation;
