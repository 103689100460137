import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from '../../common/Checkbox';

const QuantityInput = ({
  quantity,
  forOthers,
  handleQuantityChange,
  handleForOthersChange,
}) => (
  <>
    <div className="flex flex-col items-center justify-center w-full m-auto text-xs">
      <div className="mx-0 my-2">
        <Checkbox
          id="multiple-licenses"
          name="multiple-licenses"
          labelText="I am purchasing for others"
          checked={forOthers}
          onChange={handleForOthersChange}
        />
      </div>
    </div>
    {forOthers
      ? (
        <div className="flex items-center justify-center w-full gap-2 mx-auto my-2">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className="font-sans" htmlFor="total-number">
            Enter total licenses needed:
          </label>
          <input
            id="total-number"
            className="font-sans font-normal box-border block rounded-md py-1.5 ring-1 ring-inset ring-gray-50 border-1 border-[#e6e6e6] placeholder:text-gray-500 focus:ring-1 focus:ring-inset focus:ring-rust-500 focus:shadow-input sm:text-sm sm:leading-6 pl-3 w-20"
            type="text"
            value={quantity}
            onChange={handleQuantityChange}
          />
        </div>
      )
      : null}
  </>
);

QuantityInput.propTypes = {
  quantity: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  forOthers: PropTypes.bool.isRequired,
  handleQuantityChange: PropTypes.func.isRequired,
  handleForOthersChange: PropTypes.func.isRequired,
};

export default QuantityInput;
