import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../common/Button';

import { prefixHostname } from '../../../../lib/cdn';

import { trackCompleteOnboardingStep } from '../../../../lib/tracker/onboarding';

const GSPChart = ({ nextStep }) => {
  const continueOnboardingFlow = async () => {
    await trackCompleteOnboardingStep('GSP View');
    nextStep();
  };

  return (
    <div className="grid grid-cols-1 my-6 md:my-12 md:grid-cols-5">
      <div className="flex flex-col self-center w-4/5 col-span-1 m-auto text-left md:my-auto md:mx-0 md:col-span-3 md:w-11/12 md:self-start">
        <h1 className="mb-3 font-serif text-2xl font-normal tracking-wider md:text-4xl md:mb-6">Communicating effectively is just one aspect of working inclusively.</h1>
        <p className="m-0 mb-4 text-base md:mb-6 md:text-lg">
          Direct/Indirect is just one dimension in the GlobeSmart Profile.
          Complete the survey in Aperian to get a full picture of your work style and
          personalized insights into how you can best work with others.
        </p>
        <div className="mb-10 justify-self-start md:mb-0">
          <Button
            variant="primary"
            trailingButtonIcon="arrow-right"
            onClick={continueOnboardingFlow}
          >Continue
          </Button>
        </div>
      </div>
      <div className="w-4/5 col-span-2 m-auto md:w-full md:text-left">
        <img
          src={prefixHostname('/images/onboarding-comparison-chart.svg')}
          alt="GlobeSmart Profile Example Chart"
        />
      </div>
    </div>
  );
};

GSPChart.propTypes = {
  nextStep: PropTypes.func.isRequired,
};

export default GSPChart;
