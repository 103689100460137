import { BadRequestError } from '../../lib/errors';

class InvitationSendAction {
  constructor(apiService) {
    this.apiService = apiService;
  }

  execute(data) {
    return this.apiService.post('invitations', data)
      .catch(err => {
        if (err instanceof BadRequestError) {
          return err.reason;
        }
        throw err;
      });
  }
}

export default InvitationSendAction;
