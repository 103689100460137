import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import { Context } from '../../../context/ContextProvider';
import withServerSideData from '../../../HOC/withServerSideData';

import PageHeader from '../../PageHeader';
import Notification from '../../Notifications/Notification';
import InvitationForm from '../../Invitations/InvitationForm';

import GroupAddMemberAction from '../../../actions/assessments/groups/groupAddMember';
import { accessCodeList } from '../../../lib/accessCodeList';

const AddGroupMembers = ({ params: { assessment, id }, initialData }) => {
  const {
    apiService,
    router,
  } = useContext(Context);
  const {
    asmtData: { displayName },
    asmtGroupData: {
      id: groupId,
      name: groupName,
      customMessage: initialCustomMessage,
    },
    accessCodes: initialAccessCodes,
  } = initialData;
  const [errorMessage, setErrorMessage] = useState(null);

  const isGta = assessment === 'gta';
  const { accessCodes, defaultAccessCode } = accessCodeList({ codes: initialAccessCodes });
  const groupOrTeam = isGta ? 'team' : 'group';
  const groupOrTeamUpperCase = groupOrTeam.charAt(0).toUpperCase() + groupOrTeam.slice(1);

  const handleFormSubmit = ({ invitations, customMessage }) => {
    new GroupAddMemberAction(apiService)
      .execute(id, assessment, { invitations, customMessage })
      .then(response => {
        if (response.message) {
          const readableErrorMessage = response.message.includes('E_VALIDATION')
            ? 'An email you submitted was invalid.'
            : 'Something went wrong. Please try again later.';
          window.scroll(0, 0);
          setErrorMessage({ errorMessage: readableErrorMessage });
        } else {
          router.push(`/assessments/${assessment}/group/${id}`);
        }
      });
  };

  const redirectUrl = `/assessments/${assessment}/group/${groupId}`;
  return (
    <>
      <PageHeader
        pageTitle={`${displayName} - Add ${groupOrTeamUpperCase} Members`}
        icon="ibi-symbol"
        backLink={{ to: redirectUrl, text: `Back to ${groupName}` }}
        skipTarget="#add-group-members"
      />
      <div id="add-group-members" className="mb-8 text-charcoal-900 bg-ivory-400 rounded-2xl∂">
        <div className="round-corners">
          <h3>{groupName}</h3>
          <p className="mb-6 text-base">
            Use this form to add {groupOrTeam} members to the {groupName} {groupOrTeam}.
          </p>
        </div>
        {errorMessage && <Notification type="failure" message={errorMessage} />}
        <InvitationForm
          sendMessage="Send Invitation"
          handleFormSubmit={handleFormSubmit}
          accessCodes={accessCodes}
          defaultAccessCode={defaultAccessCode}
          customMessage={initialCustomMessage}
          isCustomMessageVisible
        />
      </div>
    </>
  );
};

AddGroupMembers.getAPIDataKey = () => 'asmtGroupInviteData';

AddGroupMembers.getData = (apiService, { assessment, id }) =>
  apiService.get(`invitations/assessments/${assessment}?group=${id}`)
    .then(data => ({ asmtGroupInviteData: data }));

AddGroupMembers.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
    assessment: PropTypes.string.isRequired,
  }).isRequired,
  initialData: PropTypes.shape({
    asmtData: PropTypes.shape({
      displayName: PropTypes.string,
    }),
    asmtGroupData: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      assessment: PropTypes.number,
      customMessage: PropTypes.string,
      deleted: PropTypes.bool,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
    }),
    accessCodes: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        description: PropTypes.string,
        internalNotes: PropTypes.string,
        quantity: PropTypes.number,
        active: PropTypes.bool,
        seatsRemaining: PropTypes.number,
        stripePlanId: PropTypes.string,
        created: PropTypes.string,
        expirationDate: PropTypes.string,
        planName: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default withServerSideData(AddGroupMembers);
