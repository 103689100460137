import React from 'react';
import { iconPropTypes, defaultIconProps } from './types';

import { strokeColorMap } from '../index.js';

const directions = {
  'chevron-up': 'M19 10L10 1 1 10',
  'chevron-down': 'M1 1L10 10 19 1',
  'chevron-right': 'm 5.5,14.5 9,-9 -9,-9',
  'chevron-left': 'm 14.5,-3.5 -9,9 9,9',
};

const Chevron = ({ icon, className, iconColor }) => (
  <svg width="20" height="12" className={`${className} ${strokeColorMap[iconColor]}`} viewBox="0 0 20 12" xmlns="http://www.w3.org/2000/svg">
    <path d={directions[icon]} strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

Chevron.propTypes = iconPropTypes;
Chevron.defaultProps = defaultIconProps;

export default Chevron;
