/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import TopAdviceItem from '../TopAdviceItem';

const TopAdviceGroup = ({
  topAdvice,
  onClick,
  isAuthorized,
  searchParams,
}) => (
  <ol className="p-0 mt-4 list-none">
    {topAdvice.map(topAdviceItem => (
      <li key={topAdviceItem.topic} className="mb-10">
        <TopAdviceItem
          {...topAdviceItem}
          onClick={onClick}
          isAuthorized={isAuthorized}
          searchParams={searchParams}
        />
      </li>
    ))}
  </ol>
);

TopAdviceGroup.propTypes = {
  topAdvice: PropTypes.arrayOf(
    PropTypes.shape({
      dimensionName: PropTypes.string,
      explanation: PropTypes.string.isRequired,
      linkText: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      topic: PropTypes.string.isRequired,
      topicID: PropTypes.string.isRequired,
    })),
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isAuthorized: PropTypes.bool.isRequired,
  searchParams: PropTypes.string.isRequired,
};

TopAdviceGroup.defaultProps = {
  topAdvice: [],
};

export default TopAdviceGroup;
