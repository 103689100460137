import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { CurrentUserContext } from '../../../../context/CurrentUser';

import CultureAvatarNavigation from './CultureAvatarNavigation';
import DefaultAvatarNavigation from './DefaultAvatarNavigation';
import { LanguageContext } from '../../../../context/Language';
import CultureFeature from '../../../../lib/features/Cultures';

const cultureGuideAccess = user => new CultureFeature(user)
  .positive(() => true)
  .negative(() => false)
  .execute();

const AvatarNavigation = props => {
  const { currentUser } = useContext(CurrentUserContext);
  const { selectedLanguage } = useContext(LanguageContext);
  const { id, cultureGuideUrl } = props;
  if (props.type === 'culture') {
    return (
      <CultureAvatarNavigation
        id={id}
        cultureGuideUrl={cultureGuideUrl}
        user={currentUser}
        language={selectedLanguage}
        guideAccess={cultureGuideAccess(currentUser)}
      />
    );
  }
  return (
    <DefaultAvatarNavigation
      {...props}
      language={selectedLanguage}
    />
  );
};

AvatarNavigation.propTypes = {
  id: PropTypes.oneOfType([ // eslint-disable-line react/no-unused-prop-types
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  type: PropTypes.string.isRequired,
  cultureGuideUrl: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
};

AvatarNavigation.defaultProps = {
  cultureGuideUrl: null,
};

export default AvatarNavigation;
