import qs from 'query-string';
import removeObjectProperties from '../../removeObjectProperties';

function currentUrl(location, options = {}) {
  const opts = {
    removeQueryParams: options.removeQueryParams || [],
  };

  if (opts.removeQueryParams.length > 0) {
    const queryParamsObj = qs.parse(location.search);
    const newQueryParamsObj = removeObjectProperties(queryParamsObj, opts.removeQueryParams);
    const newQueryString = qs.stringify(newQueryParamsObj);

    return `${location.pathname}${newQueryString ? `?${newQueryString}` : ''}${location.hash}`;
  }

  return `${location.pathname}${location.search}${location.hash}`;
}

export default currentUrl;
