import React, { useContext, useEffect, useReducer } from 'react';
import qs from 'query-string';
import { union, find } from 'lodash';
import PropTypes from 'prop-types';

import withServerSideData from '../../HOC/withServerSideData';

import { RouterContext } from '../../context/Router';
import { LanguageContext } from '../../context/Language';
import { CurrentUserContext } from '../../context/CurrentUser';

import PageHeader from '../PageHeader';
import FullScreenHeader from '../FullScreen/FullScreenHeader';
import FullScreenBody from '../FullScreen/FullScreenBody';
import ProfileGroup from '../ProfileCards/ProfileGroup';
import NewComparisonCTA from './NewComparisonCTA';
import PaymentViaUrl from '../PaymentViaUrl';
import ServerError from '../ServerError';

import PremiumFeature from '../../lib/features/Premium';
import NeedsCompletedSurvey from '../../lib/NeedsCompletedSurvey';
import { GS_PREMIUM_STRIPE_PLAN } from '../../lib/constants';
import { trackBeginNewComparison } from '../../lib/tracker/comparison';

const checkArray = (...items) => {
  let array = [];
  items.forEach(i => {
    if (Array.isArray(i)) { array = array.concat(i); } else { array.push(i); }
  });
  return array;
};

const paymentUrl = `/profile/comparison/new?purchase&plan=${GS_PREMIUM_STRIPE_PLAN}`;

const NewComparison = ({ initialData }) => {
  const { router } = useContext(RouterContext);
  const { selectedLanguage } = useContext(LanguageContext);
  const { currentUser } = useContext(CurrentUserContext);

  const [state, setState] = useReducer((data, newData) =>
    ({ ...data, ...newData }), {
    ...initialData,
    selectedComparables: [],
    filterValue: '',
    currentAccessLevel: currentUser.accessLevel,
  });

  const assembleCardData = cards => {
    if (!cards) return null;
    return (cards || [])
      .map(target => ({
        id: target.id,
        type: target.type,
        avatarUrl: target.avatarUrl,
        displayName: target.type === 'org' ? `${target.name} Average` : target.name,
        displayDescription: '',
        isComparisonItem: true,
      }))
      .sort((a, b) => {
        const nameA = a.displayName.toUpperCase(); // ignore upper and lowercase
        const nameB = b.displayName.toUpperCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
  };

  const getPreviousSelections = () => {
    const {
      culture,
      person,
      teams_members, // eslint-disable-line camelcase
      org,
      compareTargets,
    } = state;

    const team = (Array.isArray(teams_members))
      // eslint-disable-next-line camelcase
      ? teams_members.map(Number)
      // eslint-disable-next-line radix
      : parseInt(teams_members, 0);

    const previousSelections = checkArray(culture, person, team, org);

    const selected = [];

    previousSelections.forEach(id => {
      const comparable = find(compareTargets, c => c.id === id);
      selected.push(comparable);
    });

    setState({ selectedComparables: selected.filter(s => s !== undefined) });
  };

  useEffect(() => {
    getPreviousSelections();
  }, []);

  useEffect(() => {
    const previousAccessLevel = state.currentAccessLevel;
    if (currentUser.accessLevel.length !== previousAccessLevel.length) {
      getPreviousSelections();
      setState({
        currentAccessLevel: currentUser.accessLevel,
      });
    }
  }, [currentUser.accessLevel]);

  const handleCheckboxChange = ({ id }, include) => {
    const comparable = find(state.compareTargets, c => c.id === id);
    const { selectedComparables } = state;
    if (include) {
      setState({
        selectedComparables: union(selectedComparables, [comparable]),
        filterValue: '',
      });
    } else {
      setState({
        selectedComparables: selectedComparables.filter(
          item => item.id !== id,
        ),
        filterValue: '',
      });
    }
  };

  const compareTargets = types => {
    if (!state.compareTargets) return [];
    let pattern = state.filterValue.toLowerCase();
    pattern = pattern.replace(/[^\w\s-]*/g, ''); // allow only alphanumeric, underscore, white space, hyphen

    const filterMatch = new RegExp(`.*${pattern.split('').join('.*')}.*`);
    return state.compareTargets
      .filter(c => types.includes(c.type))
      .filter(c => c.name.toLowerCase().match(filterMatch));
  };

  const handleCancel = () => {
    router.goBack();
  };

  const handleClickToCompare = () => {
    const queryStringData = state.selectedComparables.reduce((object, comparable) => {
      object[comparable.type].push(comparable.id);
      return object;
    }, {
      person: [], team: [], org: [], culture: [],
    });
    const queryString = qs.stringify({
      person: queryStringData.person,
      teams_members: queryStringData.team,
      teams_average: queryStringData.team,
      org: queryStringData.org,
      culture: queryStringData.culture,
      language: selectedLanguage,
    });
    trackBeginNewComparison()
      .then(() => router.push(`/profile/comparison?${queryString}`));
  };

  const onFilter = filterValue => {
    setState({ filterValue });
  };

  const clearAll = () => {
    setState({ selectedComparables: [] });
  };

  const hasPremium = () => new PremiumFeature(currentUser)
    .positive(() => true)
    .negative(() => false)
    .execute();

  const sendInvitationsLink = () => (hasPremium() ? '/invitations/new' : paymentUrl);

  const createTeamLink = () => (hasPremium() ? '/profile/teams/new' : paymentUrl);

  const hasNoCompareTargets = () => state.selectedComparables.length === 0;

  const {
    compareTarget,
    selectedComparables,
    filterValue,
    error,
  } = state;
  if (error) return <ServerError pageTitle="New Comparison" error={error} />;

  return (
    <>
      <PageHeader
        pageTitle="New Comparison"
        skipTarget="#tabs-content"
        icon="profile"
      />
      <PaymentViaUrl {...state} />
      {/* Since we are calling <FullScreenHeader/> directly, we need to pass
          cancelHandler and confirmHandler instead of button onClick callbacks
        */}
      <FullScreenHeader
        confirmText="Create"
        confirmHandler={handleClickToCompare}
        confirmOptions={{
          isDisabled: hasNoCompareTargets(),
        }}
        cancelText="Cancel"
        cancelHandler={handleCancel}
        cancelOptions={{
          className: 'mr-0.5',
        }}
        compareTarget={compareTarget}
        selectedComparables={selectedComparables}
        onRemove={handleCheckboxChange}
        filterValue={filterValue}
        onFilter={onFilter}
        onClearAll={clearAll}
        takeoverTitle="New Comparison"
      />

      <FullScreenBody>
        <div className="grid justify-start flex-grow grid-cols-1 grid-rows-3 gap-4 pb-8 m-auto md:pb-12 lg:pb-16 max-w-7xl auto-rows-max bg-ivory-400">
          <ProfileGroup
            groupName="Teams and Organizations (A-Z)"
            cards={assembleCardData(compareTargets(['team', 'org']))}
            onChange={handleCheckboxChange}
            selectedComparables={selectedComparables}
            hasPremium={hasPremium}
            createTeamLink={createTeamLink}
          />
          <ProfileGroup
            groupName="Individuals (A-Z)"
            cards={assembleCardData(compareTargets(['person']))}
            onChange={handleCheckboxChange}
            selectedComparables={selectedComparables}
            hasPremium={hasPremium}
            sendInvitationsLink={sendInvitationsLink}
          />
          <ProfileGroup
            groupName="Cultures (A-Z)"
            cards={assembleCardData(compareTargets(['culture']))}
            onChange={handleCheckboxChange}
            selectedComparables={selectedComparables}
            hasPremium={hasPremium}
            {...state}
          />
        </div>
        <div className="w-full px-6 py-8 md:py-12 lg:px-20 lg:py-16 bg-slate-50">
          <NewComparisonCTA
            sendInvitationsLink={sendInvitationsLink}
            hasPremium={hasPremium}
            createTeamLink={createTeamLink}
            router={router}
          />
        </div>
      </FullScreenBody>
    </>
  );
};

NewComparison.needsCompletedSurvey = (router, currentUser) =>
  NeedsCompletedSurvey.apply(router, currentUser);

NewComparison.getAPIDataKey = () => 'newcomparison';

NewComparison.getData = apiService => apiService.get('profile/search?connections=true&teams=true&organizations=true&cultures=true')
  .then(data => {
    const { currentUser: { blockedContent } } = apiService;
    const hasBlockedCultures = blockedContent.cultures.length;
    const hasCultures = data.cultures;
    const filteredCultures = (hasCultures && hasBlockedCultures)
      ? data.cultures.filter(culture => !blockedContent.cultures.some(c => c === culture.id))
      : data.cultures;

    return ({
      newcomparison: {
        compareTargets: [
          ...(data.teams || []),
          ...(data.organizations || []),
          ...(data.people || []),
          ...(filteredCultures || []),
        ],
      },
    });
  });

NewComparison.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  initialData: PropTypes.shape({
    compareTargets: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default withServerSideData(NewComparison);
