import React from 'react';
import { iconPropTypes, defaultIconProps } from './types';

import { fillColorMap } from '../index.js';

const IbiSymbol = ({ className, iconColor }) => (
  <svg className={`${className} ${fillColorMap[iconColor]}`} viewBox="0 0 16 17" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.183838 0.653248V7.87951C0.183838 7.96024 0.249193 8.02431 0.328645 8.02431H7.5549C7.63564 8.02431 7.70228 7.95768 7.70099 7.87566C7.6241 3.84157 4.36787 0.58533 0.332489 0.507159C0.250475 0.505878 0.183838 0.572515 0.183838 0.653248Z" />
    <path d="M3.94242 16.244C6.01823 16.244 7.70101 14.5613 7.70101 12.4855C7.70101 10.4096 6.01823 8.72687 3.94242 8.72687C1.86661 8.72687 0.183838 10.4096 0.183838 12.4855C0.183838 14.5613 1.86661 16.244 3.94242 16.244Z" />
    <path d="M12.1622 8.02435C14.238 8.02435 15.9207 6.34157 15.9207 4.26576C15.9207 2.18995 14.238 0.507172 12.1622 0.507172C10.0863 0.507172 8.40356 2.18995 8.40356 4.26576C8.40356 6.34157 10.0863 8.02435 12.1622 8.02435Z" />
    <path d="M15.9208 16.098L15.9208 8.87171C15.9208 8.79098 15.8554 8.72691 15.776 8.72691L8.54971 8.72691C8.46898 8.72691 8.40234 8.79354 8.40362 8.87556C8.48051 12.9097 11.7367 16.1659 15.7721 16.2441C15.8541 16.2453 15.9208 16.1787 15.9208 16.098Z" />
  </svg>
);

IbiSymbol.propTypes = iconPropTypes;
IbiSymbol.defaultProps = defaultIconProps;

export default IbiSymbol;
