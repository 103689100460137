import React from 'react';
import PropTypes from 'prop-types';

import SectionContainer from '../../../common/Containers/Section';
import ReceivedInvitationCard from './ReceivedInvitationCard';

const ReceivedInvitations = ({
  received,
  onAccept,
  onReject,
  isAuthorized,
  paymentUrl,
}) => {
  const renderCards = () => received.map(card => (
    <li key={card.token} className="profile-group__item">
      <ReceivedInvitationCard
        {...card}
        onAccept={onAccept}
        onReject={onReject}
        isAuthorized={isAuthorized}
        paymentUrl={paymentUrl}
      />
    </li>
  ));

  if (!received || !received.length) return null;

  return (
    <SectionContainer title={`${received.length} Received Invitations`}>
      <ul className="profile-group__list">
        {renderCards()}
      </ul>
    </SectionContainer>
  );
};

ReceivedInvitations.propTypes = {
  received: PropTypes.arrayOf(PropTypes.shape({
    accessCode: PropTypes.string,
    avatarUrl: PropTypes.string,
    name: PropTypes.string,
    token: PropTypes.string,
    type: PropTypes.string,
  })).isRequired,
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  isAuthorized: PropTypes.bool.isRequired,
  paymentUrl: PropTypes.string,
};

ReceivedInvitations.defaultProps = {
  paymentUrl: '',
};

export default ReceivedInvitations;
