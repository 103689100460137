import React from 'react';
import { iconPropTypes, defaultIconProps } from './types';

import { strokeColorMap } from '../index.js';

const NewComparison = ({ className, iconColor }) => (
  <svg className={`${className} ${strokeColorMap[iconColor]}`} width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3.71094" y="3.40283" width="64" height="64" rx="32" fill="white" />
    <path d="M52.6665 32.2405H38.8943V18.4807C38.8943 14.3769 32.5639 14.3769 32.5639 18.4807V32.2405H18.7916C14.6841 32.2405 14.6841 38.5652 18.7916 38.5652H32.5639V52.325C32.5639 56.4288 38.8943 56.4288 38.8943 52.325V38.5652H52.6665C56.7257 38.6135 56.7257 32.2405 52.6665 32.2405Z" fill="#CBC4BC" />
    <rect x="3.71094" y="3.40283" width="64" height="64" rx="32" strokeWidth="6" />
  </svg>
);

NewComparison.propTypes = iconPropTypes;
NewComparison.defaultProps = defaultIconProps;

export default NewComparison;
