import React, { Component } from 'react';

import { propTypes } from './types';

import LabelInput from '../../../common/LabelInput';
import Checkbox from '../../../common/Checkbox';

class AccountSettingsEmailFields extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      email: props.email,
      optIn: props.optIn,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      editing: false,
      email: nextProps.email,
      optIn: nextProps.optIn,
    });
  }

  handleEmailChange = event => {
    this.setState({ email: event.target.value });
  };

  handleOptInChange = event => {
    this.setState({ optIn: event.target.checked }, () => {
      this.props.onUpdate('opt-in', {
        optIn: this.state.optIn,
      });
    });
  };

  formSubmit = e => {
    e.preventDefault();
    this.toggleEditing();

    this.props.onUpdate('email', {
      email: this.state.email,
    });
  };

  toggleEditing = () => {
    const { editing } = this.state;
    this.setState({ editing: !editing });
  };

  renderEmailField() {
    return (
      <LabelInput
        id="email"
        labelType="email"
        labelText="Email"
        value={this.state.email}
        onChangeValue={this.handleEmailChange}
        isDisabled={!this.state.editing}
      />
    );
  }

  renderOptInCheckbox() {
    return (
      <Checkbox
        id="opt-in"
        name="opt-in-updates"
        labelText="Please send me product updates"
        checked={this.state.optIn}
        onChange={this.handleOptInChange}
      />
    );
  }

  render() {
    return (
      <form onSubmit={this.formSubmit}>
        <div className="mb-1">
          {this.renderEmailField()}
        </div>
        <div className="mt-3">
          {this.renderOptInCheckbox()}
        </div>
      </form>
    );
  }
}

AccountSettingsEmailFields.propTypes = propTypes;

export default AccountSettingsEmailFields;
