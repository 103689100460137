/* eslint-disable new-cap */
import React from 'react';
import PropTypes from 'prop-types';
import Parser from 'html-react-parser';
import DOMPurify from 'isomorphic-dompurify';

const AdviceContent = ({ title, explanation, subHeadings }) => (
  <li>
    <h4 className="mb-4">
      {Parser(DOMPurify.sanitize(title, { ADD_ATTR: ['target'] }))}
    </h4>
    {Parser(DOMPurify.sanitize(explanation, { ADD_ATTR: ['target'] }))}
    {subHeadings?.length
      ? (
        <ul>
          {subHeadings.map(item => (
            <li key={Math.random()}>
              {Parser(DOMPurify.sanitize(item, { ADD_ATTR: ['target'] }))}
            </li>
          ))}
        </ul>
      )
      : null}
  </li>
);

AdviceContent.propTypes = {
  title: PropTypes.string.isRequired,
  explanation: PropTypes.string.isRequired,
  subHeadings: PropTypes.arrayOf(PropTypes.string),
};

AdviceContent.defaultProps = {
  subHeadings: [],
};

export default AdviceContent;
