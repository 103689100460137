import React from 'react';

import BodyWrapper from '../../BodyWrapper';
import AperianWordmark from '../../AperianWordmark';
import Button from '../../common/Button';
import RustDividingLine from '../../common/RustDividingLine';

const ResetPasswordSuccess = () => (
  <BodyWrapper documentTitle="New Password Sent | Aperian">
    <div className="flex items-center justify-center mb-6">
      <AperianWordmark />
    </div>
    <div>
      <h1 className="mb-2 text-2xl font-normal font-headline">Password Reset</h1>
      <RustDividingLine />
      <p className="my-4 font-sans text-xs font-normal">Your password has successfully been reset.</p>
    </div>
    <Button to="/login" variant="primary">Return to Login</Button>
  </BodyWrapper>
);

export default ResetPasswordSuccess;
