import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import { Context } from '../../../context/ContextProvider';
import withServerSideData from '../../../HOC/withServerSideData';

import PageHeader from '../../PageHeader';
import InvitationForm from '../InvitationForm';
import Notification from '../../Notifications/Notification';

import TeamAddMemberAction from '../../../actions/teams/teamAddMember';
import { accessCodeList } from '../../../lib/accessCodeList';

const AddTeamMembers = ({ params: { id }, initialData }) => {
  const {
    apiService,
    router,
  } = useContext(Context);

  const {
    accessCodes: initialAccessCodes,
    teamData: {
      name: teamName,
      customMessage: initialCustomMessage,
    },
  } = initialData;

  const { accessCodes, defaultAccessCode } = accessCodeList({
    codes: initialAccessCodes, onlyPremium: true,
  });
  const [errorMessage, setErrorMessage] = useState(null);

  const onSubmit = ({ invitations, customMessage }) => {
    new TeamAddMemberAction(apiService)
      .execute(id, { invitations, customMessage })
      .then(response => {
        if (response.message) {
          const readableErrorMessage = response.message.includes('E_VALIDATION')
            ? 'An email you submitted was invalid.'
            : 'Something went wrong. Please try again later.';
          window.scroll(0, 0);
          setErrorMessage({ errorMessage: readableErrorMessage });
        } else {
          router.push(`/profile/teams/${id}`);
        }
      });
  };

  const redirectUrl = `/profile/teams/${id}`;
  return (
    <>
      <PageHeader
        pageTitle="Add Team Members"
        bgColor="white"
        icon="profile"
        backLink={{ to: redirectUrl, text: `Back to ${teamName}` }}
        skipTarget="#add-team-members"
      />
      <div id="add-team-members" className="px-4 py-6 my-2 mb-8 md:p-8 text-charcoal-900 bg-ivory-400 rounded-2xl">
        <div className="round-corners">
          <h3 className="text-center">{teamName}</h3>
          <p className="mb-6 text-base text-center">Use this form to add team members to the {teamName} team.
          </p>
        </div>
        {errorMessage && <Notification type="failure" message={errorMessage} />}
        <InvitationForm
          addMessage="Add Another Member"
          sendMessage="Send Invitation"
          accessCodes={accessCodes}
          handleFormSubmit={onSubmit}
          customMessage={initialCustomMessage}
          isCustomMessageVisible
          defaultAccessCode={defaultAccessCode}
        />
      </div>
    </>
  );
};
AddTeamMembers.getAPIDataKey = () => 'TeamAddMember';

AddTeamMembers.getData = (apiService, { id }) =>
  apiService.get(`teams/${id}/teamInvites`)
    .then(data => ({ TeamAddMember: data }));

AddTeamMembers.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  initialData: PropTypes.shape({
    teamData: PropTypes.shape({
      createdAt: PropTypes.string,
      currentProfile: PropTypes.number,
      customMessage: PropTypes.string,
      id: PropTypes.number,
      name: PropTypes.string,
      owner: PropTypes.string,
      updatedAt: PropTypes.string,
    }),
    accessCodes: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        description: PropTypes.string,
        internalNotes: PropTypes.string,
        quantity: PropTypes.number,
        active: PropTypes.bool,
        seatsRemaining: PropTypes.number,
        stripePlanId: PropTypes.string,
        created: PropTypes.string,
        expirationDate: PropTypes.string,
        planName: PropTypes.string,
        redirectUrl: PropTypes.string,
      }),
    ),
  }).isRequired,
};
export default withServerSideData(AddTeamMembers);
