import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { trackOnboardingStepCompletionTrack } from '../../../../lib/tracker/onboarding';

import logger from '../../../../lib/logger';

import TrackSelectionCard from './TrackSelectionCard';

const TrackSelection = ({ tracks, handleUpdateForm, nextStep }) => {
  const [disableButtons, setDisabledButtons] = useState(false);

  const handleSelectTrack = async ({ trackId, trackName }) => {
    setDisabledButtons(true);

    try {
      await trackOnboardingStepCompletionTrack(trackName);

      handleUpdateForm({
        track: trackId,
        trackName,
      });
      nextStep();
    } catch (err) {
      logger.error(err);
    }
  };

  return (
    <div className="p-2 mt-8">
      <h1 className="mb-8 font-serif text-4xl tracking-wider text-charcoal-900 md:text-6xl md:mb-16">
        How can Aperian help your organization?
      </h1>
      <div className="grid grid-cols-2 grid-rows-2 gap-2 mb-8 md:grid-cols-4 md:grid-rows-1 lg:gap-8 md:mx-4 md:mb-16">
        {tracks.map(({ id: trackId, name: trackName, text }) => (
          <TrackSelectionCard
            key={text}
            trackId={trackId}
            trackName={trackName}
            text={text}
            handleSelectTrack={handleSelectTrack}
            disableButtons={disableButtons}
          />
        ))}
      </div>
    </div>
  );
};

TrackSelection.propTypes = {
  nextStep: PropTypes.func.isRequired,
  handleUpdateForm: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    track: PropTypes.number,
  }).isRequired,
  tracks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      icon: PropTypes.string,
      text: PropTypes.string,
    }),
  ).isRequired,
};

export default TrackSelection;
