/* eslint-disable new-cap */
/* eslint-disable global-require */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

let CKEditor;
let ClassicEditor;

if (typeof window !== 'undefined') {
  CKEditor = require('@ckeditor/ckeditor5-react').CKEditor;
  ClassicEditor = require('ckeditor5-custom-build/build/ckeditor.js');
}

const editorConfiguration = {
  htmlSupport: {
    allow: [{
      name: /^(?!.*script|.*link).*$/,
      attributes: /^(?!.*script|.*link|onclick).*$/,
      classes: /^(?!.*script|.*link).*$/,
      styles: /^(?!.*script|.*link).*$/,
    }],
  },
};

const CKEditorComponent = ({ savedHTML, updateHTML }) => {
  const [editorContent, setEditorContent] = useState(savedHTML);

  useEffect(() => {
    updateHTML(editorContent);
  }, [editorContent]);

  return ((CKEditor && ClassicEditor)
    ? (
      <CKEditor
        editor={ClassicEditor}
        data={editorContent}
        config={editorConfiguration}
        onChange={(event, editor) => {
          const data = editor.getData();
          setEditorContent(data);
        }}
      />
    ) : <h2>Loading Editor ...</h2>);
};
export default CKEditorComponent;

CKEditorComponent.propTypes = {
  savedHTML: PropTypes.string,
  updateHTML: PropTypes.func.isRequired,
};

CKEditorComponent.defaultProps = {
  savedHTML: PropTypes.string,
};
