import React, { useContext, useEffect, useState } from 'react';

import { NotificationContext } from '../../../context/Notification';
import { APIContext } from '../../../context/API';

import Notification from '../../../components/Notifications/Notification';
import LabelInput from '../../../components/common/LabelInput';
import BlocksList from '../BlocksList';

import { searchBlocksAction } from '../../../actions/dashboardBlocks';

const BlocksSearch = () => {
  const { apiService } = useContext(APIContext);

  const {
    addNotification,
    notificationMessage,
    removeNotification,
  } = useContext(NotificationContext);

  const [blockName, setBlockName] = useState('');
  const [blocks, setBlocks] = useState([]);

  useEffect(() => {
    let timeout;
    if (notificationMessage) {
      timeout = window.setTimeout(() => {
        removeNotification();
      }, 5000);
    }
    return () => {
      window.clearTimeout(timeout);
      if (notificationMessage) removeNotification();
    };
  }, [notificationMessage]);

  useEffect(() => {
    let timer;
    if (!blockName) return setBlocks([]);
    if (timer) window.clearTimeout(timer);
    timer = setTimeout(() => searchBlocksAction(apiService, blockName)
      .then(data => setBlocks(data.blocks))
      .catch(err => {
        addNotification({
          type: 'warning',
          message: `Error searching for block: ${err.message}`,
        });
      }), 250);
    return () => clearTimeout(timer);
  }, [blockName]);

  const onSubmit = e => e.preventDefault();

  const onSearchParamChange = ({ target: { value } }) => {
    setBlockName(value);
  };

  return (
    <>
      {notificationMessage && <Notification {...notificationMessage} />}
      <div className="pt-1">
        <form onSubmit={onSubmit}>
          <LabelInput
            id="BlockName"
            name="Block Name"
            labelText="Search Term"
            labelType="text"
            value={blockName}
            onChangeValue={onSearchParamChange}
            helperText="Search for a Block by Name (Internal), Title, Type, or Tags."
          />
        </form>
      </div>
      <BlocksList blocks={blocks} />
    </>
  );
};

export default BlocksSearch;
