import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../Button';
import Tooltip from '../../Tooltip';

const HideProfileToggle = ({
  isTeamOwnerHidden,
  isHistoryHidden,
  onClick,
  hideProfileToggleText,
}) => {
  if (!isTeamOwnerHidden()) {
    return (
      <Tooltip
        key="hide-profile"
        onHover
        position="bottom"
        content={hideProfileToggleText}
      >
        <Button
          className="p-4"
          filledColor="secondary"
          onClick={onClick}
          leadingButtonIcon={isHistoryHidden ? 'eye' : 'eye-off'}
          iconColor="white"
          isCircle
        />
      </Tooltip>
    );
  }
  return null;
};

HideProfileToggle.propTypes = {
  isTeamOwnerHidden: PropTypes.func.isRequired,
  isHistoryHidden: PropTypes.bool,
  onClick: PropTypes.func,
  hideProfileToggleText: PropTypes.string.isRequired,
};

HideProfileToggle.defaultProps = {
  isHistoryHidden: false,
  onClick: undefined,
};

export default HideProfileToggle;
