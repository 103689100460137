import React from 'react';
import PropTypes from 'prop-types';

import SectionContainer from '../../../common/Containers/Section';
import GroupMemberCard from './GroupMemberCard';
import ReceivedInvitationCard from '../../../Invitations/PendingInvitations/ReceivedInvitations/ReceivedInvitationCard';

import formatDate from '../../../../lib/dateFormatter';

const GroupsYouAreIn = ({
  asmtType,
  groupsYouAreIn,
  groupInvitations,
  onAccept,
  onReject,
  onLeave,
}) => {
  if (!groupInvitations.length && !groupsYouAreIn.length) return null;
  const renderPendingInvitations = () =>
    groupInvitations.map(card => (
      <li key={card.token} className="profile-group__item">
        <ReceivedInvitationCard
          {...card}
          onAccept={onAccept}
          onReject={onReject}
          paymentUrl={`assessments/${asmtType}`}
          isAuthorized
          isAssessmentPage
          acceptButtonText="Accept"
        />
      </li>
    ));
  const renderGroupsJoined = () =>
    groupsYouAreIn.map(({
      id,
      name,
      avatarUrl,
      createdAt,
      ownerEmails,
    }) => {
      const displayDescription = (
        <>
          <b>Group Owner{ownerEmails && ownerEmails.split(',').length > 1 ? 's' : ''}: </b> {ownerEmails} <br />
          <b>Created: </b> {formatDate(createdAt)} <br />
        </>
      );
      return (
        <li key={id}>
          <GroupMemberCard
            id={id}
            avatarUrl={avatarUrl}
            displayName={name}
            displayDescription={displayDescription}
            onLeave={onLeave}
          />
        </li>
      );
    });

  return (
    <SectionContainer title="Groups you have joined">
      <ul className="profile-group__list">
        {renderPendingInvitations()}
        {renderGroupsJoined()}
      </ul>
    </SectionContainer>
  );
};

GroupsYouAreIn.propTypes = {
  asmtType: PropTypes.string.isRequired,
  groupsYouAreIn: PropTypes.arrayOf(PropTypes.shape({})),
  groupInvitations: PropTypes.arrayOf(PropTypes.shape({})),
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  onLeave: PropTypes.func.isRequired,
};

GroupsYouAreIn.defaultProps = {
  groupsYouAreIn: [],
  groupInvitations: [],
};

export default GroupsYouAreIn;
