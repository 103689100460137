import React from 'react';
import PropTypes from 'prop-types';
import ProgressBar from '../../common/ProgressBar';

const Usage = ({
  planDateRange,
  atuSeatsUsed,
  atuPlan,
  hasAtu,
  hasContentControllerUsage,
}) => (
  <div className="p-8 bg-white rounded-lg w-100 text-charcoal-900">
    <h3 className="mb-1 font-serif text-2xl semibold"><b>Usage</b></h3>
    {hasAtu
      ? (
        <div className="font-sans">
          <p className="mb-8 text-sm">This billing period ({planDateRange})</p>
          <p className="my-1 text-xl font-semibold"><b>{atuSeatsUsed}/{atuPlan || 0}</b></p>
          <ProgressBar
            percent={Math.floor(((atuSeatsUsed / atuPlan) * 100))}
            position="right"
            showPercentColor
          />
          <div className="font-sans">
            {hasContentControllerUsage ? (
              <i className="text-sm">This number includes usage across all products and platforms.</i>
            ) : null}
            <div className="mt-1">
              <p className="text-sm">Exceeding your ATUs within the billing period will incur overage fees.</p>
            </div>
          </div>
          <p className="my-1 font-sans text-sm">
            <span className="font-semibold">Need more ATUs? </span>
            Contact&nbsp;
            <a className="inline-block" href="mailto:clientsuccess@aperian.com">
              clientsuccess@aperian.com
            </a>.
          </p>
        </div>
      )
      : (
        <div>
          <p> Download your organization directory below for current usage.
            Speak with your Client Success Manager for support.
          </p>
        </div>
      )}
  </div>
);

Usage.propTypes = {
  planDateRange: PropTypes.string,
  atuSeatsUsed: PropTypes.number,
  atuPlan: PropTypes.number,
  hasAtu: PropTypes.bool,
  hasContentControllerUsage: PropTypes.bool,
};

Usage.defaultProps = {
  planDateRange: '',
  atuSeatsUsed: 0,
  atuPlan: 0,
  hasAtu: false,
  hasContentControllerUsage: false,
};

export default Usage;
