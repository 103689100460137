import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { RouterContext } from '../../../../../context/Router';

import ProfileCardAvatarWithButton from '../../../../ProfileCards/ProfileCardAvatarWithButton';
import ProfileCardTextWithButton from '../../../../ProfileCards/ProfileCardTextWithButton';
import AssessmentCriteria from '../../../../../lib/features/criteria/assessment_criteria';

import determinePlanByType from '../../../../../lib/assessments/determinePlanByType';

const ReceivedInvitationCard = props => {
  const { router } = useContext(RouterContext);
  const [confirmReject, setConfirmReject] = useState(false);
  const {
    token,
    onAccept,
    onReject,
    isAssessmentPage,
    acceptButtonText,
  } = props;

  const handleAccept = e => {
    const { dataset: { id } } = e.target;
    onAccept(id);
  };

  const handleConfirmReject = () => {
    setConfirmReject(true);
  };

  const noReject = () => {
    setConfirmReject(false);
  };

  const completeReject = e => {
    const { dataset: { id } } = e.target;
    onReject(id);
  };

  const getClickHandlerForAccept = (thisCard, redirectUrl) => {
    if (thisCard.isAuthorized || thisCard.accessCode || thisCard.type === 'person') {
      return handleAccept;
    }
    if (thisCard.type === 'group' && AssessmentCriteria) {
      return handleAccept;
    }
    return () => router.replace(redirectUrl);
  };

  function renderReceivedCard(data) {
    const thisCard = { ...data };
    const isPerson = thisCard.type === 'person';
    const isTeam = thisCard.type === 'team';
    const isGroup = thisCard.type === 'group';

    const redirectUrl = isGroup
      ? `/invitations?purchase&plan=${determinePlanByType(thisCard.asmtType)}`
      : thisCard.paymentUrl;
    const buttonOptions = {
      isInvitation: true,
      confirmText: acceptButtonText,
      confirmButton: {
        variant: 'primary',
        isNormalCase: true,
        onClick: getClickHandlerForAccept(thisCard, redirectUrl),
      },
      cancelText: 'Ignore',
      cancelButton: {
        isNormalCase: true,
        isWarning: true,
        onClick: handleConfirmReject,
      },
    };

    thisCard.displayName = thisCard.group || thisCard.team || thisCard.name;

    thisCard.displayDescription = (
      <>
        {isPerson && <><b>GlobeSmart Profile</b><br /></>}
        {isTeam && (
          <>
            <b>GlobeSmart Profile Team</b><br />
            <b>Team Owner: </b>{thisCard.name}, {thisCard.email}<br />
            <b>Team Members: </b>{thisCard.members.join(', ')}
          </>
        )}
        {(isGroup && !isAssessmentPage) && (
          <>
            <b>{thisCard.asmtName}</b><br />
            <b>Group Owner{thisCard.owners.length > 1 && 's'}: </b>{thisCard.owners.join(', ')}
          </>
        )}
      </>
    );

    return (
      <ProfileCardAvatarWithButton
        {...thisCard}
        {...buttonOptions}
      />
    );
  }

  function renderConfirmCancel() {
    const options = {
      messageText: 'Are you sure you want to reject this invitation?',
      confirmText: 'Yes, Ignore',
      confirmButton: {
        isWarning: true,
        isNormalCase: true,
        onClick: completeReject,
      },
      cancelText: 'No, Keep',
      cancelButton: {
        isNormalCase: true,
        variant: 'secondary',
        onClick: noReject,
      },
    };

    return <ProfileCardTextWithButton {...options} token={token} />;
  }

  return confirmReject ? renderConfirmCancel() : renderReceivedCard(props);
};

ReceivedInvitationCard.propTypes = {
  name: PropTypes.string.isRequired,
  team: PropTypes.string,
  group: PropTypes.string,
  token: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  isAuthorized: PropTypes.bool,
  isAssessmentPage: PropTypes.bool,
  acceptButtonText: PropTypes.string,
};

ReceivedInvitationCard.defaultProps = {
  team: null,
  group: null,
  isAuthorized: false,
  isAssessmentPage: false,
  acceptButtonText: 'Accept',
};

export default ReceivedInvitationCard;
