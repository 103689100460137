/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ModalContext } from '../../../context/Modal';
import PaymentAddressForm from '../PaymentBillingAddressForm';
import Button from '../../common/Button';
import Checkbox from '../../common/Checkbox';
import Icon from '../../common/Icon';

const PaymentBillingForm = ({
  currentStep,
  handleNext,
  isSameShippingAddress,
  handleShippingAddress,
  handleFormChange,
  handleNotification,
  billingAddressError,
  stripe,
}) => {
  if (currentStep !== 1) return null;
  const { handleCloseModal } = useContext(ModalContext);

  const validateAddressForm = () => {
    handleNotification({});
    if (!stripe) {
      return;
    }
    handleNext();
  };

  return (
    <div>
      <div>
        <Checkbox
          id="is-shipping-address"
          name="is-shipping-address"
          labelText="Billing Address is same as Shipping Address"
          checked={isSameShippingAddress}
          onChange={handleShippingAddress}
        />
      </div>
      { billingAddressError ? (
        <div className="flex items-center gap-2 mt-2 mb-0">
          <Icon icon="info" iconColor="red" />
          <p className="h-4 mt-2 font-sans text-base text-left text-red-500">
            {billingAddressError}
          </p>
        </div>
      ) : null}
      {stripe ? (
        <>
          <p className="mt-4 mb-0 font-medium text-left">Billing Address</p>
          <PaymentAddressForm
            handleFormChange={handleFormChange}
          />
        </>

      ) : null}
      <div className="flex flex-wrap w-full gap-2 m-auto justify-evenly">
        <Button
          className="flex-grow"
          type="button"
          onClick={handleCloseModal}
        >
          Cancel
        </Button>
        <Button
          className="flex-grow"
          type="button"
          variant="primary"
          onClick={validateAddressForm}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

PaymentBillingForm.propTypes = {
  currentStep: PropTypes.number.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  isSameShippingAddress: PropTypes.bool.isRequired,
  handleShippingAddress: PropTypes.func.isRequired,
  handleNotification: PropTypes.func.isRequired,
  billingAddressError: PropTypes.string,
  stripe: PropTypes.shape({}),
};

PaymentBillingForm.defaultProps = {
  billingAddressError: '',
  stripe: null,
};

export default PaymentBillingForm;
