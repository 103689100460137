import React from 'react';
import ReactDOM from 'react-dom';
import { Router, browserHistory } from 'react-router';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-datepicker/dist/react-datepicker.css';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './lib/rollbar';
import './lib/mixpanel';
import './lib/braze';
import './lib/csTracker';
import './lib/safeFocus';

import routes from './routes';

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line global-require
  require('../styles/index.css');
}

function logPageView() {
  window?.Appcues?.page();
}

ReactDOM.hydrate(
  <Router history={browserHistory} onUpdate={logPageView}>
    {routes}
  </Router>,
  document.getElementById('root'),
);
