import React from 'react';
import PropTypes from 'prop-types';
import Button, { propTypes as buttonPropTypes, defaultProps as defaultButtonProps } from '../../common/Button';

import Avatar from '../../common/Avatars/Avatar';
import Drawer from '../../Drawer';
import Icon from '../../common/Icon';

import { rotate } from '../../../../styles/theme';

function renderButton(text, options, token, id) {
  if (!text) return null;

  return (
    <Button id={id} {...options} dataID={token} isFullWidth>
      {text}
    </Button>
  );
}

function renderDescription(description) {
  if (!description) return null;

  return (
    <div className="leading-snug card__description hyphens-none">
      {description}
    </div>
  );
}

const DrawerButton = ({
  drawerLabel,
  showDrawerContents,
  toggleDrawer,
  memberUserId,
}) => (
  <Button
    variant="secondary"
    isFullWidth
    onClick={() => toggleDrawer(memberUserId)}
  >
    <div className="flex items-center">
      <span className="flex-grow m-0">
        {drawerLabel}
      </span>
      <Icon
        icon="chevron-down"
        iconColor="current"
        className={`w-4 h-4 ${rotate(showDrawerContents)}`}
      />
    </div>
  </Button>
);

DrawerButton.propTypes = {
  drawerLabel: PropTypes.string.isRequired,
  showDrawerContents: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  memberUserId: PropTypes.string.isRequired,
};

const ProfileCardAvatarWithButton = props => {
  const {
    memberUserId,
    token,
    displayName,
    displayDescription,
    confirmText,
    confirmId,
    confirmButton,
    cancelText,
    cancelId,
    cancelButton,
    withDrawer,
    drawerLabel,
    showDrawerContents,
    toggleDrawer,
    drawerContents,
  } = props;

  return (
    <div className="card">
      <div className="card__container">
        <Avatar
          avatarUrl={props.avatarUrl}
          avatarImgAlt={props.displayName}
          id={props.memberUserId}
        />
        <div className="card__content">
          <div className="card__heading text-charcoal-900">
            {displayName}
          </div>
          {renderDescription(displayDescription)}
        </div>
        <div className="gap-1 leading-snug card__actions">
          {renderButton(confirmText, confirmButton, token, confirmId)}
          {renderButton(cancelText, cancelButton, token, cancelId)}
          {withDrawer
            ? (
              <DrawerButton
                drawerLabel={drawerLabel}
                showDrawerContents={showDrawerContents}
                toggleDrawer={toggleDrawer}
                memberUserId={memberUserId}
              />
            )
            : null}
        </div>
      </div>
      <Drawer
        id={`raters-${memberUserId}`}
        showDrawerContents={showDrawerContents}
      >
        {drawerContents}
      </Drawer>
    </div>
  );
};

ProfileCardAvatarWithButton.propTypes = {
  avatarUrl: PropTypes.string,
  displayName: PropTypes.string.isRequired,
  displayDescription: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
  ]),
  confirmText: PropTypes.string.isRequired,
  confirmId: PropTypes.string,
  confirmButton: PropTypes.shape(buttonPropTypes),
  cancelText: PropTypes.string.isRequired,
  cancelId: PropTypes.string,
  cancelButton: PropTypes.shape(buttonPropTypes),
  token: PropTypes.string,
  withDrawer: PropTypes.bool,
  drawerLabel: PropTypes.string,
  showDrawerContents: PropTypes.bool,
  drawerContents: PropTypes.node,
  toggleDrawer: PropTypes.func,
  memberUserId: PropTypes.string,
};
ProfileCardAvatarWithButton.defaultProps = {
  avatarUrl: '',
  displayDescription: null,
  confirmButton: defaultButtonProps,
  confirmId: 'confirm',
  cancelButton: defaultButtonProps,
  cancelId: 'cancel',
  token: null,
  withDrawer: false,
  drawerLabel: '',
  showDrawerContents: false,
  toggleDrawer: () => { },
  drawerContents: undefined,
  memberUserId: '',
};

export default ProfileCardAvatarWithButton;
