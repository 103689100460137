import React from 'react';

import PropTypes from 'prop-types';

const CirclesQuarterCircleIcon = ({ className1, className2 }) => (
  <svg className="max-w-full max-h-full" width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M56.9359 58.0645L1.11846 58.0645C0.494858 58.0645 -6.62381e-05 57.5596 -6.62113e-05 56.9459L-6.37715e-05 1.1285C-6.37442e-05 0.504895 0.514656 -0.00982403 1.14816 7.4459e-05C32.3085 0.593984 57.4605 25.746 58.0643 56.9162C58.0742 57.5497 57.5595 58.0645 56.9359 58.0645Z" className={className1} />
    <path d="M90.9678 58.0645C107.002 58.0645 120 45.0663 120 29.0323C120 12.9982 107.002 0 90.9678 0C74.9337 0 61.9355 12.9982 61.9355 29.0323C61.9355 45.0663 74.9337 58.0645 90.9678 58.0645Z" className={className2} />
    <path d="M63.0641 61.9355L118.882 61.9355C119.505 61.9355 120 62.4404 120 63.0541L120 118.872C120 119.495 119.485 120.01 118.852 120C87.6915 119.406 62.5395 94.254 61.9357 63.0838C61.9258 62.4503 62.4405 61.9355 63.0641 61.9355Z" className={className1} />
    <path d="M29.0322 61.9355C12.9981 61.9355 -6.48499e-05 74.9337 -6.48499e-05 90.9677C-6.48499e-05 107.002 12.9981 120 29.0322 120C45.0663 120 58.0645 107.002 58.0645 90.9677C58.0645 74.9337 45.0663 61.9355 29.0322 61.9355Z" className={className2} />
  </svg>
);

CirclesQuarterCircleIcon.propTypes = {
  className1: PropTypes.string,
  className2: PropTypes.string,
};

CirclesQuarterCircleIcon.defaultProps = {
  className1: '',
  className2: '',
};

export default CirclesQuarterCircleIcon;
