import React from 'react';
import PropTypes from 'prop-types';

import SentInvitations from '../../../Invitations/PendingInvitations/SentInvitations';

const GroupPendingMembers = ({
  pendingInvitations,
  onResend,
  onCancel,
  groupOrTeam,
}) => (
  <SentInvitations
    heading={`Pending ${groupOrTeam} Members`}
    sent={pendingInvitations}
    onResend={onResend}
    onCancel={onCancel}
    isInviter
  />
);

GroupPendingMembers.propTypes = {
  pendingInvitations: PropTypes.arrayOf(
    PropTypes.shape({
      accessCode: PropTypes.string,
      email: PropTypes.string,
      sentAt: PropTypes.string,
      token: PropTypes.string,
    }),
  ).isRequired,
  onResend: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  groupOrTeam: PropTypes.string.isRequired,
};

export default GroupPendingMembers;
