export function hasDataFor(componentName) {
  return !!(typeof window === 'object' && window.apgServerData && window.apgServerData[componentName]);
}

export function noDataFor(componentName) {
  if (window && !window.apgServerData) {
    return true;
  }

  return !!(typeof window === 'object' && !window.apgServerData[componentName]);
}
