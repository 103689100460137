import tracker from '../index';

const { eventNames, eventProps, resolveAfterTimeout } = tracker;

const trackRegistrationFailure = reason => {
  tracker.track(
    eventNames.SIGN_UP, {
      [eventProps.SUCCESS]: false,
      [eventProps.FAILURE_REASON]: reason,
    },
  );
  return resolveAfterTimeout;
};

export {
  trackRegistrationFailure,
};
