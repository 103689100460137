import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from '../common/Button';
import Spinner from '../Spinner';

const DragAndDrop = ({
  displayText,
  errorText,
  handleError,
  handleFileUpload,
  filesAccepted,
  fileSize,
}) => {
  const [dragOver, setDragOver] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fileInputRef = useRef(null);

  const handleDragLeave = () => setDragOver(false);

  const validateFile = file => {
    const fileType = file?.type.split('/');
    if (file?.size && (filesAccepted === `${fileType[0]}/*` || filesAccepted.includes(fileType[1]))) {
      if (file.size < (fileSize * 1024 * 1024)) {
        setIsLoading(true);
        handleFileUpload(file);
        return;
      }
      handleError('File Size exceeds the maximum limit');
      return;
    }
    handleError('Could not load image, the format is invalid.');
  };

  const handleDrop = e => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    validateFile(droppedFile);
    handleDragLeave();
  };

  const handleDragOver = e => {
    e.preventDefault();
    setDragOver(true);
    handleError(null);
  };

  const handleInputChange = e => {
    const selectedFile = e.target.files[0];
    fileInputRef.current.value = null;
    if (selectedFile) {
      validateFile(selectedFile);
    }
  };

  const handleButtonClick = e => {
    handleError(null);
    setIsLoading(false);
    fileInputRef.current.click();
    e.currentTarget.blur();
  };

  const dropZoneClassNames = classNames({
    'flex justify-center items-center': true,
    'h-72 w-full': true,
    'border-2 border-dashed': true,
    'round-corners p-4': true,
    'bg-gray-5': true,
    'border-blue-400 bg-gray-5': dragOver,
    'border-red-500': !dragOver && errorText,
    'border-charcoal-900': !dragOver && !errorText,
  });

  return (
    <div className="flex flex-col items-center">
      <div
        className={dropZoneClassNames}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        <div className="flex flex-col gap-2 text-charcoal-900">
          <p className="mb-0 text-base text-center">{displayText}</p>
          <Button
            onClick={handleButtonClick}
            leadingButtonIcon="upload"
            variant={!errorText && 'secondary'}
            isWarning={!!errorText}
          >Upload a photo
          </Button>
          <span className="text-sm italic text-center text-charcoal-900 font-base">({fileSize} MB maximum file size)</span>
          {errorText && <p className="text-sm italic text-center text-red font-base">{errorText}</p>}
          {isLoading && !errorText
            ? <Spinner margin="0.5rem 0 0" />
            : null}
        </div>
        <input type="file" name="avatar" accept={filesAccepted} ref={fileInputRef} onChange={handleInputChange} className="visually-hidden" id="fileInput" />
      </div>
    </div>
  );
};

DragAndDrop.propTypes = {
  handleFileUpload: PropTypes.func.isRequired,
  displayText: PropTypes.string.isRequired,
  handleError: PropTypes.func.isRequired,
  errorText: PropTypes.string,
  filesAccepted: PropTypes.string,
  fileSize: PropTypes.number,
};

DragAndDrop.defaultProps = {
  filesAccepted: 'image/*',
  errorText: 'Invalid file Format',
  fileSize: 4,
};

export default DragAndDrop;
