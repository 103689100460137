import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import LabelInput from '../LabelInput';
import LabelTextArea from '../LabelTextarea';

const EditableCell = ({
  id,
  name,
  text,
  onTextChange,
  inputType,
}) => {
  const [inputtedValue, setInputtedValue] = useState(text);
  const inputRef = useRef(null);
  const textareaRef = useRef(null);

  useEffect(() => {
    setInputtedValue(text);
  }, [text]);

  const handleChange = e => {
    onTextChange(e.target.name, e.target.value);
    if (inputRef.current !== null) {
      const { selectionStart } = inputRef.current;
      setTimeout(() => {
        inputRef.current.selectionStart = selectionStart;
        inputRef.current.selectionEnd = selectionStart;
      }, 0);
    }

    if (textareaRef.current !== null) {
      const { selectionStart } = textareaRef.current;
      setTimeout(() => {
        textareaRef.current.selectionStart = selectionStart;
        textareaRef.current.selectionEnd = selectionStart;
      }, 0);
    }
  };

  const renderEditor = () => {
    switch (inputType) {
      case 'textarea':
        return (
          <LabelTextArea
            id={id}
            ref={textareaRef}
            name={name}
            value={inputtedValue}
            onBlur={e => handleChange(e)}
            onChangeValue={e => handleChange(e)}
            labelText=""
          />
        );
      case 'input':
      default:
        return (
          <LabelInput
            id={id}
            ref={inputRef}
            name={name}
            value={inputtedValue}
            onBlur={e => handleChange(e)}
            onChangeValue={e => handleChange(e)}
            labelText=""
          />
        );
    }
  };

  return renderEditor();
};

EditableCell.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  text: PropTypes.string,
  onTextChange: PropTypes.func.isRequired,
  inputType: PropTypes.string,
};

EditableCell.defaultProps = {
  inputType: 'input',
  text: '',
};

export default EditableCell;
