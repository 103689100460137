import React, { useContext } from 'react';
import { propTypes } from './types';

import { RouterContext } from '../../../context/Router';
import { CurrentUserContext } from '../../../context/CurrentUser';

import TopicalAdvice from '../../../components/custom/Advice/TopicalAdvice';

import TopicalAdviceFeature from '../../../lib/features/TopicalAdvice';
import { GS_PREMIUM_STRIPE_PLAN } from '../../../lib/constants';

const TopicalAdviceAuthorization = props => {
  const { currentUser } = useContext(CurrentUserContext);
  const { router } = useContext(RouterContext);
  const { children, params: { topicId } } = props;

  return new TopicalAdviceFeature(currentUser)
    .positive(() => (
      <TopicalAdvice {...props} topicId={topicId}>
        {children}
      </TopicalAdvice>
    ))
    .negative(() => {
      router.push(`/profile/comparison?purchase&plan=${GS_PREMIUM_STRIPE_PLAN}`);
      return null;
    })
    .execute();
};

TopicalAdviceAuthorization.propTypes = propTypes;

export default TopicalAdviceAuthorization;
