import Feature from '../Feature';
import AnyCriteria from '../criteria/any';
import AssessmentCriteria from '../criteria/assessment_criteria';

export default class AssessmentFeature extends Feature {
  constructor(user, assessment) {
    const assessmentUser = new AssessmentCriteria(user, assessment);
    const any = new AnyCriteria(assessmentUser);

    super(any);
  }
}
