import React, { useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

import { CurrentUserContext } from '../../../../context/CurrentUser';
import { RouterContext } from '../../../../context/Router';

import ProfileChartTransformer from '../../../common/ProfileChartTransformer';
import Button from '../../../common/Button';

import NeedsCompletedSurvey from '../../../../lib/NeedsCompletedSurvey';
import ServerError from '../../../ServerError';

const ProfileHistory = props => {
  const { currentUser } = useContext(CurrentUserContext);
  const { router } = useContext(RouterContext);

  const { profile, compareTargets, dimensionLabelGroups, fullScreenRef } = props;
  profile.isMe = true;
  profile.isVisible = true;

  const compareTargetsWithHistory = compareTargets.map(target =>
    ({ ...target, isHistoryProfile: true }));

  const consolidatedProfiles = {
    initialProfiles: [profile].concat(compareTargetsWithHistory),
    profiles: [profile].concat(compareTargetsWithHistory[0]),
    profile,
    compareTargets: compareTargetsWithHistory,
    dimensionLabelGroups,
  };

  const [state, setState] = useReducer((data, newData) =>
    ({ ...data, ...newData }), {
    ...consolidatedProfiles,
    previousProfileIndex: 0,
    isHistoryProfile: true,
    hideProfileToggleText: 'Hide My History',
    myProfileVisibility: 'visible',
    isHistoryHidden: false,
    error: null,
  });

  useEffect(() => {
    // To trigger an update to the ProfileTraitLine and ProfileChartAvatars to reposition
    // the ProfileConnectingLines to the new profiles we pass a resize event
    // IE11 doesn't support window.dispatch(newEvent('resize')), so this is a solution
    if (typeof (Event) === 'function') {
      window.dispatchEvent(new Event('resize'));
    } else {
      const event = window.document.createEvent('UIEvents');
      event.initUIEvent('resize', true, false, window, 0);
      window.dispatchEvent(event);
    }
  }, [state.profiles]);

  useEffect(() => {
    setState({ dimensionLabelGroups });
  }, [dimensionLabelGroups]);

  useEffect(() => {
    setState({ profiles: [profile].concat(state.compareTargets[state.previousProfileIndex]) });
  }, [state.previousProfileIndex]);

  const needsCompletedSurvey = NeedsCompletedSurvey.apply(router, currentUser);

  const hideProfileToggle = () => {
    if (state.profiles.length === 1) {
      setState({ hideProfileToggleText: 'Hide My History', profiles: [state.profile].concat(state.compareTargets[0]), isHistoryHidden: false });
      return;
    }
    const mostRecentProfile = state.profiles.filter(p => p.id === currentUser.userid);
    setState({ hideProfileToggleText: 'Show My History', profiles: mostRecentProfile, isHistoryHidden: true });
  };

  const handlePreviousProfileHistory = () => {
    if (state.previousProfileIndex !== 0) {
      setState({ previousProfileIndex: state.previousProfileIndex - 1 });
    }
  };

  const handleNextProfileHistory = () => {
    if (state.previousProfileIndex < state.compareTargets.length - 1) {
      setState({ previousProfileIndex: state.previousProfileIndex + 1 });
    }
  };

  if (state.error) return <ServerError pageTitle="Compare Profile" error={state.error} />;
  if (needsCompletedSurvey) return null;

  return (
    <section className="relative">
      {(state.isHistoryHidden || state.compareTargets.length === 1)
        ? null
        : (
          <div className="absolute z-10 flex justify-between w-full gap-2 transform -translate-x-1/2 md:w-auto md:justify-center top-10 left-1/2 align-center md:left-auto md:right-10 md:transform-none md:top-0">
            <Button
              onClick={handlePreviousProfileHistory}
              className="p-2"
              variant="secondary"
              leadingButtonIcon="chevron-left"
              disabled={state.previousProfileIndex === 0}
            />
            <Button
              onClick={handleNextProfileHistory}
              className="p-2"
              variant="secondary"
              trailingButtonIcon="chevron-right"
              disabled={state.previousProfileIndex === state.compareTargets.length - 1}
            />
          </div>
        )}
      <ProfileChartTransformer
        {...state}
        hasResizeSupport
        onClick={hideProfileToggle}
        compareProfile
        handlePrevious={handlePreviousProfileHistory}
        handleNext={handleNextProfileHistory}
        fullScreenRef={fullScreenRef}
        downloadLocation="Profile History"
      />
    </section>
  );
};

const profilePropTypes = PropTypes.shape({
  id: PropTypes.string,
  type: PropTypes.string,
  shortDisplayName: PropTypes.string,
  avatarUrl: PropTypes.string,
  grid: PropTypes.shape({
    independent_interdependent: PropTypes.number,
    egalitarianism_status: PropTypes.number,
    risk_certainty: PropTypes.number,
    direct_indirect: PropTypes.number,
    task_relationship: PropTypes.number,
  }),
  isMe: PropTypes.bool,
  isVisible: PropTypes.bool,
});

ProfileHistory.propTypes = {
  children: PropTypes.node,
  profile: profilePropTypes.isRequired,
  compareTargets: PropTypes.arrayOf(profilePropTypes).isRequired,
  dimensionLabelGroups: PropTypes.arrayOf(
    PropTypes.shape({
      left: PropTypes.string,
      right: PropTypes.string,
    }),
  ).isRequired,
  fullScreenRef: PropTypes.shape({
    current: PropTypes.shape({
      requestFullscreen: PropTypes.func,
      mozRequestFullScreen: PropTypes.func,
      webkitRequestFullscreen: PropTypes.func,
      msRequestFullscreen: PropTypes.func,
    }),
  }),
};

ProfileHistory.defaultProps = {
  children: null,
  fullScreenRef: null,
};

export default ProfileHistory;
