import React from 'react';
import PropTypes from 'prop-types';

import Avatar from '../../../../../common/Avatars/Avatar';
import MemberActions from './MemberActions';

const ActiveTeamMemberCard = ({
  id,
  avatarUrl,
  isOwner,
  displayName,
  description,
  surveyStatus,
  wasReminded,
  handleOnRemind,
  handleConfirmRemove,
  onLeaveEnabled,
}) => (
  <div className="card">
    <div className="card__container">
      <Avatar
        avatarUrl={avatarUrl}
        avatarImgAlt={displayName}
        id={id}
      />
      <div className="card__content">
        <div className="card__heading">
          {displayName}
        </div>
        {description ? (
          <div className="card__description">
            {description}
          </div>
        ) : null}
      </div>
      <MemberActions
        id={id}
        isOwner={isOwner}
        surveyStatus={surveyStatus}
        wasReminded={wasReminded}
        handleOnRemind={handleOnRemind}
        handleConfirmRemove={handleConfirmRemove}
        onLeaveEnabled={onLeaveEnabled}
      />
    </div>
  </div>
);

ActiveTeamMemberCard.propTypes = {
  id: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string.isRequired,
  isOwner: PropTypes.bool.isRequired,
  displayName: PropTypes.string.isRequired,
  description: PropTypes.node,
  surveyStatus: PropTypes.string.isRequired,
  wasReminded: PropTypes.bool.isRequired,
  handleOnRemind: PropTypes.func.isRequired,
  handleConfirmRemove: PropTypes.func.isRequired,
  onLeaveEnabled: PropTypes.bool.isRequired,
};

ActiveTeamMemberCard.defaultProps = {
  description: null,
};

export default ActiveTeamMemberCard;
