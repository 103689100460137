import React, { useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { pick } from 'lodash';
import ElementQuery from 'react-eq';
import { saveAs } from 'file-saver';

import { RouterContext } from '../../../../context/Router';
import { APIContext } from '../../../../context/API';

import withServerSideData from '../../../../HOC/withServerSideData';

import LabelInput from '../../../common/LabelInput';
import Button from '../../../common/Button';
import AccessCodeUseCard from '../../../AccessCodes/AccessCodeUseCard';
import Notification from '../../../Notifications/Notification';
import AccessCode from '../ReviewAccessCodes/AccessCode';
import AutoSuggest from '../../../AutoSuggest';

import logger from '../../../../lib/logger';
import getDownloadFileName from '../../../../lib/fileDownload';
import GetPlansAction from '../../../../actions/getPlans';
import EditAccessCodeAction from '../../../../actions/accessCodes/editAccessCode';
import DeactivateAccessCodeAction from '../../../../actions/accessCodes/deactivateAccessCode';
import AccessCodesDownloadAction from '../../../../actions/accessCodes/downloadAccessCodes';

const renderCard = cardType => {
  const queries = { 'card-layout': 400 };
  return (
    <ElementQuery queries={queries}>
      <ul className="profile-group__list">
        {cardType}
      </ul>
    </ElementQuery>
  );
};

const renderAccessCodeUses = accessCodeUses =>
  accessCodeUses.map(accessCodeUse => {
    const {
      user: {
        id,
        firstName,
        lastName,
        email,
      },
      redeemed,
      stripePlanId,
      surveyResponse,
      assessmentResponse,
    } = accessCodeUse;

    return (
      <li className="profile-group__item" key={id}>
        <AccessCodeUseCard
          firstName={firstName}
          lastName={lastName}
          email={email}
          redeemed={redeemed}
          stripePlanId={stripePlanId}
          surveyResponse={surveyResponse}
          assessmentResponse={assessmentResponse}
        />
      </li>
    );
  });

const EditAccessCode = ({ initialData }) => {
  const { apiService } = useContext(APIContext);
  const { router } = useContext(RouterContext);

  const [state, setState] = useReducer((data, newData) =>
    ({ ...data, ...newData }), { ...initialData, formData: {} });

  const getPlans = () => {
    new GetPlansAction(apiService)
      .execute()
      .then(response => {
        const plans = response.plans
          .map(plan => ({
            name: `${plan.name} (${plan.id})`,
            value: plan.id,
          }))
          .sort((a, b) => (a.value > b.value ? 1 : -1));
        setState({ plans });
      });
  };

  useEffect(() => {
    getPlans();
  }, []);

  const exportCSV = () =>
    new AccessCodesDownloadAction(apiService)
      .execute(state.code)
      .then(async response => ({
        filename: getDownloadFileName(response),
        blob: await response.blob(),
      }))
      .then(({ blob, filename }) => saveAs(blob, filename))
      .catch(err => {
        setState({
          notification: {
            type: 'warning',
            message: `Could not download access code uses: ${err}`,
          },
        });
      });

  const handleChange = e => {
    const { formData } = state;
    const { target: { name, value } } = e;
    const updatedFormData = { ...formData, [name]: value };
    setState({ formData: updatedFormData, [name]: value });
  };

  const formSubmit = e => {
    e.preventDefault();
    const { formData, redirectUrl } = state;
    if (redirectUrl && !(redirectUrl.startsWith('/') || redirectUrl.includes('https'))) {
      return setState({
        notification: {
          type: 'failure',
          message: 'Enter valid Redirect URL',
        },
      });
    }
    return new EditAccessCodeAction(apiService)
      .execute(state.code, {
        ...pick(formData, [
          'code',
          'description',
          'internalNotes',
          'redirectUrl',
          'expiration',
          'quantity',
          'stripePlanId',
        ]),
        userId: state.userId,
      })
      .then(data => {
        setState({
          ...pick(data, [
            'code',
            'description',
            'internalNotes',
            'redirectUrl',
            'expirationDate',
            'quantity',
            'stripePlanId',
            'planName',
            'seatsRemaining',
          ]),
          notification: {
            type: 'success',
            message: 'Access Code has been updated successfully',
          },
        });
        router.replace(
          `/administration/access-codes/${data.code}/edit`,
        );
      })
      .catch(err => {
        setState({
          notification: {
            type: 'failure',
            message: EditAccessCode.errorMessage(err),
          },
        });
      });
  };

  const setInactive = () => {
    setState({ active: false });
  };

  const onDeactivate = code =>
    new DeactivateAccessCodeAction(apiService)
      .execute(code)
      .then(() => setInactive())
      .catch(err => logger.error(JSON.stringify(err)));

  const {
    accessCodeUses,
    code,
    redirectUrl,
    quantity,
    description,
    expiration,
    internalNotes,
    stripePlanId,
    plans,
    planName,
  } = state;

  const disableAccessCode = accessCodeUses && accessCodeUses.length > 0;
  return (
    <div>
      <div className="mb-1">
        {code
            && renderCard(
              <li className="profile-group__item">
                <AccessCode
                  {...state}
                  onDeactivate={onDeactivate}
                  editAccessCode
                />
              </li>,
            )}
      </div>
      <form action="/access-codes" method="POST" onSubmit={formSubmit}>
        <Notification {...state.notification} />
        <div className="my-2 mx-0.5 md:mx-0">
          <div className="p-1 mb-1 round-corners">
            <div className="flex">
              <div className="flex-grow ml-1">
                <LabelInput
                  id="accessCode"
                  name="code"
                  labelType="text"
                  labelText="Access Code"
                  value={code}
                  onChangeValue={handleChange}
                  isDisabled={disableAccessCode}
                />
              </div>
            </div>

            <div className="p-1 mb-1 round-corners">
              <LabelInput
                id="description"
                name="description"
                labelType="text"
                labelText="Description"
                value={description}
                onChangeValue={handleChange}
              />
              <LabelInput
                id="internalNotes"
                name="internalNotes"
                labelType="text"
                labelText="Internal Notes"
                value={internalNotes}
                onChangeValue={handleChange}
              />
            </div>
          </div>
          <div className="grid grid-col-2@sm grid-gap-1@sm">
            <div className="p-1 mb-1 round-corners">
              <LabelInput
                id="redirectUrl"
                name="redirectUrl"
                labelType="text"
                labelText="Redirect URL"
                value={redirectUrl || ''}
                onChangeValue={handleChange}
              />
              <LabelInput
                id="expiration"
                name="expiration"
                labelType="text"
                labelText="Expires in # of days"
                value={expiration}
                onChangeValue={handleChange}
              />

              <div className="mt-3">
                <LabelInput
                  id="quantity"
                  name="quantity"
                  labelType="text"
                  labelText="Quantity"
                  value={quantity}
                  onChangeValue={handleChange}
                />
              </div>
            </div>
            <div className="p-1 mb-1 round-corners">
              {accessCodeUses && accessCodeUses.length === 0 && plans
                ? (
                  <AutoSuggest
                    name="stripePlanId"
                    labelText="Associated Stripe Plan"
                    placeholder="Search or View All Plans"
                    previousSelection={stripePlanId}
                    options={state.plans}
                    onSelectOption={handleChange}
                    isRequired
                  />
                )
                : (
                  <>
                    <h3 className="card-link-container__header">Associated Stripe Plan</h3>
                    <p>{`${planName} (${stripePlanId})`}</p>
                  </>
                )}
            </div>
          </div>
          <Button filledColor="secondary" type="submit">
            Save
          </Button>
        </div>
      </form>
      <div>
        <section className="profile-group">
          <Button
            isSmall
            filledColor="secondary"
            type="button"
            onClick={exportCSV}
          >
            EXPORT
          </Button>
          <h3 className="text-center profile-group__header font-heading-5">
            Access Code Uses
          </h3>
          {accessCodeUses && accessCodeUses.length > 0 ? (
            renderCard(renderAccessCodeUses(accessCodeUses))
          ) : (
            <p>There are no Access Code uses to display.</p>
          )}
        </section>
      </div>
    </div>
  );
};

EditAccessCode.getAPIDataKey = () => 'subscription';

EditAccessCode.getData = (apiService, { code }) => apiService
  .get(`access-codes/${code}`)
  .then(data => ({ subscription: data }));

EditAccessCode.errorMessage = error => {
  let message = 'Sorry, there was an error updating the access code.';
  if (error.name === 'BadRequestError') {
    message = error.reason.details.includes('maxLength')
      ? 'Access Codes have a 15 character limit'
      : error.reason.details;
  } else {
    logger.error(JSON.stringify(error.reason));
  }
  return message;
};

EditAccessCode.propTypes = {
  initialData: PropTypes.shape({
    code: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    internalNotes: PropTypes.string.isRequired,
    redirectUrl: PropTypes.string,
    quantity: PropTypes.number.isRequired,
    active: PropTypes.bool.isRequired,
    seatsRemaining: PropTypes.number.isRequired,
    stripePlanId: PropTypes.string.isRequired,
    created: PropTypes.string.isRequired,
    expirationDate: PropTypes.string.isRequired,
    planName: PropTypes.string.isRequired,
    user: PropTypes.shape({
      id: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default withServerSideData(EditAccessCode);
