import React from 'react';
import { Helmet } from 'react-helmet';

import { propTypes, defaultProps } from './types';
import SiteHeader from '../../SiteHeader';
import SurveyQuestionNumber from '../SurveyQuestionNumber';
import SurveyQuestion from '../SurveyQuestion';

const SurveyQuestionLayout = props => (
  <>
    <Helmet>
      <title>Survey Question {props.currentQuestionNumber.toString()} | Aperian</title>
    </Helmet>
    <SiteHeader location={props.location} />
    <div className="flex-grow w-11/12 max-w-5xl m-auto">
      <SurveyQuestionNumber
        currentQuestionNumber={props.currentQuestionNumber}
        totalQuestionCount={props.totalQuestionCount}
      />
      <SurveyQuestion {...props} />
    </div>
  </>
);
SurveyQuestionLayout.propTypes = propTypes;
SurveyQuestionLayout.defaultProps = defaultProps;

export default SurveyQuestionLayout;
