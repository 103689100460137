import React from 'react';
import PropTypes from 'prop-types';

import MemberTeamCard from './MemberTeamCard';

const TeamsYouAreIn = ({ teamsYouAreIn, onLeave }) => {
  const renderdescription = ownerEmails =>
    (<><b>Team Owner{ownerEmails.length > 1 ? 's' : ''}: </b> {ownerEmails.join(', ')}</>);

  return ((teamsYouAreIn.length > 0)
    ? (
      <section className="p-4 mx-auto mb-2 lg:py-10 md:mb-5 md:px-8 lg:mb-10 bg-ivory-400 rounded-xl">
        <h4 className="mb-4 font-serif font-semibold text-left">
          Teams You Have Joined
        </h4>
        <ul className="p-0 m-0 list-none">
          {teamsYouAreIn.map(({ id, name, ownerEmails, avatarUrl }) => (
            <li key={id}>
              <MemberTeamCard
                id={id}
                avatarUrl={avatarUrl}
                displayName={name}
                onLeave={onLeave}
                displayDescription={ownerEmails?.length ? renderdescription(ownerEmails) : ''}
              />
            </li>
          ))}
        </ul>
      </section>
    ) : null);
};

TeamsYouAreIn.propTypes = {
  teamsYouAreIn: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      customMessage: PropTypes.string,
      isOwner: PropTypes.bool,
      avatarUrl: PropTypes.string,
    }),
  ),
  onLeave: PropTypes.func.isRequired,
};

TeamsYouAreIn.defaultProps = {
  teamsYouAreIn: [],
};

export default TeamsYouAreIn;
