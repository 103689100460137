import React, { useContext, useEffect, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { Link } from 'react-router';

import { APIContext } from '../../../context/API';

import { getLatestActivity } from '../../../actions/skills';

import Icon from '../../common/Icon';
import CircularProgress from '../../common/CircularProgress';
import Spinner from '../../Spinner';

import { skillPropTypes, activityPropTypes, progressPropTypes, getStartedSkillPropTypes } from './types';

export const AchievedSkillItem = ({ id, skillName, iconImage }) => (
  <div className="md:items-center md:flex" key={id}>
    <img
      alt={skillName}
      src={`${process.env.AWS_APG_RESOURCES_HOST}${iconImage}`}
      className="m-auto md:mr-3 w-28 h-28"
    />
    <span className="font-serif text-2xl">{`You achieved your ${skillName} skill!`}</span>
  </div>
);

AchievedSkillItem.propTypes = skillPropTypes;

export const GetStartedSkillItem = ({ name, iconImage, close }) => (
  <>
    <span className="font-serif text-2xl">Get Started with Skills</span>
    <Link
      className="flex items-center no-underline hover:bg-slate-50"
      to="/profile"
      onClick={close}
    >
      <img
        alt="GS-Profile"
        src={`${process.env.AWS_APG_RESOURCES_HOST}${iconImage}`}
        className="w-10 h-10 mr-1"
      />
      <span className="ml-4 text-base text-gray-700">
        {`${name} to start learning.`}
      </span>
    </Link>
  </>
);

GetStartedSkillItem.propTypes = getStartedSkillPropTypes;

export const ActivityItem = ({ activityName, id, iconImage }) => (
  <div className="flex" key={id}>
    <img
      alt="GS-Profile"
      src={`${process.env.AWS_APG_RESOURCES_HOST}${iconImage}`}
      className="w-10 h-10 mr-1"
    />
    <span className="ml-4 font-sans text-lg text-gray-700">
      {activityName}
    </span>
  </div>
);

ActivityItem.propTypes = activityPropTypes;

export const SkillProgressItem = ({
  skillName,
  completedActivities,
  totalActivities,
  id,
  skillId,
  close,
}) => (
  <button onClick={close} className="flex items-center mt-2" key={id}>
    <CircularProgress total={totalActivities} completed={completedActivities} />
    <a
      href={`/skills#${skillId}`}
      className="no-underline "
    >
      <span
        className="ml-3 text-lg text-gray-600 hover:text-rust-500"
      >{skillName}
      </span>
    </a>
  </button>
);

SkillProgressItem.propTypes = progressPropTypes;

const SkillsNotification = () => {
  const { apiService } = useContext(APIContext);
  const [data, setData] = useState({
    skillsData: [],
    activitiesData: {},
    achivedSkill: [],
    isLoading: true,
    isFirstActivity: false,
  });

  async function fetchSkillsData() {
    const { skills, activities, isFirstActivity } = await getLatestActivity(apiService);
    setData({
      skillsData: skills,
      activitiesData: activities,
      achivedSkill: skills.filter(obj => obj.isAchieved),
      isLoading: false,
      isFirstActivity,
    });
  }

  useEffect(() => {
    fetchSkillsData(false);
  }, []);

  const renderSkillsAndActivities = close => (
    <div>
      {data.activitiesData && (
        <ActivityItem
          key={data.activitiesData.id}
          activityName={data.activitiesData.activityName}
          id={data.activitiesData.id}
          iconImage={data.activitiesData.iconImage}
        />
      )}
      {data.skillsData.map(
        ({ skillName, completedActivities, totalActivities, id }) => (
          <SkillProgressItem
            key={id}
            id={id}
            skillName={skillName}
            completedActivities={completedActivities}
            totalActivities={totalActivities}
            skillId={skillName.toLowerCase().split(' ').join('-')}
            close={close}
          />
        ),
      )}
    </div>
  );

  const renderNotification = close => {
    if (data.isLoading) {
      return (
        <div className="m-auto">
          <Spinner />
        </div>
      );
    }

    if (data.achivedSkill.length > 0) {
      return data.achivedSkill?.map(({ id, skillName, iconImage }) => (
        <AchievedSkillItem
          key={id}
          id={id}
          skillName={skillName}
          iconImage={iconImage}
        />
      ));
    }

    if (data.skillsData.length === 0) {
      return data.activitiesData?.map(({ name, iconImage }) => (
        <GetStartedSkillItem key={name} name={name} iconImage={iconImage} close={close} />
      ));
    }

    if (data.isFirstActivity) {
      return (
        <div className="md:flex">
          {renderSkillsAndActivities(close)}
          <div className="flex items-center w-64 p-2 m-1 text-center text-gray-700 border md:w-48 rounded-3xl bg-ivory-400">
            You completed an activity. You are one step closer to gaining a new Aperian Skill.
          </div>
        </div>
      );
    }

    return renderSkillsAndActivities(close);
  };

  return (
    <Popover as="div">
      {({ open, close }) => (
        <>
          <Popover.Button
            className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-500 group"
            onClick={() => fetchSkillsData(open)}
          >
            <div className="flex flex-col items-center justify-center gap-1">
              <Icon
                icon="award"
                iconColor="gray"
                className="w-6 h-6 "
                aria-hidden="true"
              />
              <span className="sr-only">View Skill/Activities notifications</span>
              <p className="m-0 text-xs">My Skills</p>
            </div>
          </Popover.Button>
          <Transition
            as={React.Fragment}
            show={open}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <div className="relative">
              <Popover.Panel className={`absolute -right-[120px] md:-right-6 z-[400] mt-2.5 ${data.isFirstActivity ? 'w-80 md:w-[30rem]' : 'w-80'} origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none`}>
                <div className="relative px-6 pb-4">
                  {(data.skillsData.length > 0 && data.achivedSkill.length === 0) && (
                    <span className="font-serif text-2xl">
                      You completed an activity!
                    </span>
                  )}
                  <div className={`grid gap-2 ${data.skillsData.length === 0 ? 'mb-2' : 'my-2'}`}>
                    {renderNotification(close)}
                  </div>
                  <Link
                    to="/skills"
                    onClick={close}
                    className="flex justify-end ml-auto text-sm font-medium text-gray-700 underline bg-white rounded-md hover:text-rust-500 focus:outline-none"
                  >
                    View My Skills
                  </Link>
                </div>
              </Popover.Panel>
            </div>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default SkillsNotification;
