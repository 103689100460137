import PropTypes from 'prop-types';
import { propTypes as profilePropTypes } from '../../../pages/Profile';

const propTypes = {
  avatarContainerRef: PropTypes.func.isRequired,
  maxAvatars: PropTypes.number.isRequired,
  tooManyComparisonObjects: PropTypes.bool,
  hasResizeSupport: PropTypes.bool,
  ...profilePropTypes,
};

const defaultTypes = {
  tooManyComparisonObjects: false,
  hasResizeSupport: false,
  withBorder: false,
};

export {
  propTypes,
  defaultTypes,
};
