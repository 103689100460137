import React, { forwardRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import PropTypes from 'prop-types';

import Button from '../../../../../common/Button';

const ProfileEditor = forwardRef(({ image }, ref) => {
  const [zoom, setZoom] = useState(1);
  const [rotate, setRotate] = useState(0);

  const handleZoomChange = newZoom => setZoom(Math.round(newZoom * 10) / 10);

  const handleZoomIn = () => {
    if (zoom < 3) {
      handleZoomChange(zoom + 0.1);
    }
  };

  const handleZoomOut = () => {
    if (zoom > 0.7) {
      handleZoomChange(zoom - 0.1);
    }
  };

  const handleScrollZoom = e => {
    const newScale = zoom + e.deltaY / 50;
    handleZoomChange(Math.min(Math.max(newScale, 0.7), 3));
  };

  const handleRotate = degrees => {
    const newRotate = (rotate + degrees) % 360;
    setRotate(newRotate < 0 ? 360 + newRotate : newRotate);
  };

  return (
    <>
      <AvatarEditor
        ref={ref}
        className="mx-auto avatar-transparent-bg"
        image={image}
        width={200}
        height={200}
        border={25}
        scale={zoom}
        borderRadius={100}
        rotate={rotate}
        color={[0, 0, 0, 0.3]}
        onWheel={handleScrollZoom}
      />
      <div className="flex justify-center">
        <button
          type="button"
          className="mx-1 cursor-pointer unbuttonize p-025"
          onClick={handleZoomOut}
        >
          <span className="text-xl font-bold">-</span>
        </button>
        <input
          className="w-64 cursor-pointer accent-charcoal-900"
          type="range"
          min="0.7"
          max="3"
          step="0.1"
          value={zoom}
          onChange={e => handleZoomChange(e.target.value)}
        />
        <button
          type="button"
          className="mx-1 cursor-pointer unbuttonize p-025"
          onClick={handleZoomIn}
        >
          <span className="text-xl font-bold">+</span>
        </button>
      </div>
      <div className="flex justify-center gap-2">
        <Button isSmall variant="secondary" onClick={() => handleRotate(-90)}>Rotate Left</Button>
        <Button isSmall variant="secondary" onClick={() => handleRotate(90)}>Rotate Right</Button>
      </div>
    </>
  );
});

ProfileEditor.displayName = 'ProfileEditor';

ProfileEditor.propTypes = {
  image: PropTypes.string.isRequired,
};

export default ProfileEditor;
