import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ActiveTeamMemberCard from './ActiveTeamMemberCard';
import ConfirmLeave from './ConfirmLeave';

const TeamMemberCard = ({
  id,
  displayName,
  displayDescription,
  isOwner,
  token,
  onRemind,
  onRemove,
  surveyStatus,
  avatarUrl,
  onLeaveEnabled,
}) => {
  const [wasReminded, setWasReminded] = useState(false);
  const [confirmRemove, setConfirmRemove] = useState(false);

  useEffect(() => {
    setConfirmRemove(false);
  }, [onRemove]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setWasReminded(false);
    }, 2000);
    return () => clearTimeout(timeOut);
  }, [wasReminded]);

  const handleOnRemind = () => {
    setWasReminded(true);
    onRemind(id);
  };

  const handleConfirmRemove = shouldRemove => {
    setConfirmRemove(shouldRemove);
  };

  return confirmRemove
    ? (
      <ConfirmLeave
        token={token}
        id={id}
        onLeaveEnabled={onLeaveEnabled}
        displayName={displayName}
        handleOnRemove={onRemove}
        handleConfirmRemove={handleConfirmRemove}
      />
    )
    : (
      <ActiveTeamMemberCard
        id={id}
        avatarUrl={avatarUrl}
        isOwner={isOwner}
        onLeaveEnabled={onLeaveEnabled}
        displayName={displayName}
        description={displayDescription}
        surveyStatus={surveyStatus}
        wasReminded={wasReminded}
        handleOnRemind={handleOnRemind}
        handleConfirmRemove={handleConfirmRemove}
      />
    );
};

TeamMemberCard.propTypes = {
  id: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  token: PropTypes.string,
  displayDescription: PropTypes.shape({
    email: PropTypes.string,
    dateJoined: PropTypes.string,
    surveyStatus: PropTypes.string,
  }),
  surveyStatus: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string,
  onRemind: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  isOwner: PropTypes.bool,
  onLeaveEnabled: PropTypes.bool.isRequired,
};
TeamMemberCard.defaultProps = {
  displayDescription: null,
  token: null,
  avatarUrl: '',
  isOwner: false,
};

export default TeamMemberCard;
