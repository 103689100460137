import React, { Component } from 'react';

import { propTypes } from './types';

import LabelInput from '../../common/LabelInput';
import Button from '../../common/Button';

class ResetPasswordForm extends Component {
  static parseErrors(password, confirmPassword) {
    const errors = {};

    errors.password = (password.trim() === '') ? 'Password is required' : '';
    errors.password = errors.password
      || ((password.trim().length < 10) ? 'Your password must be at least 10 characters.' : '');
    errors.confirmPassword = (confirmPassword.trim() === '') ? 'Confirm Password is required' : '';
    errors.confirmPassword = errors.confirmPassword
      || ((password !== confirmPassword) ? 'Passwords entered do not match.' : '');

    return errors;
  }

  constructor(props) {
    super(props);

    this.state = {
      password: '',
      confirmPassword: '',
      errors: {},
    };
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = event => {
    event.preventDefault();

    const { password, confirmPassword } = this.state;
    const errors = ResetPasswordForm.parseErrors(password, confirmPassword);

    this.setState({ errors });

    if (!errors.password && !errors.confirmPassword) {
      this.props.handleFormSubmit(password);
    }
  };

  renderErrorMessages() {
    const { errors: { password, confirmPassword } } = this.state;
    const messages = [password, confirmPassword]
      .filter(error => !!error)
      .map(error => (<div key={error}>{error}</div>));

    if (messages.length === 0) { return null; }
    return (
      <p className="label__message label__message--error js-label__message--error">
        {messages}
      </p>
    );
  }

  render() {
    return (
      <div>
        {this.renderErrorMessages()}
        <form action="/forgot-password" method="POST" onSubmit={this.handleSubmit}>
          <div className="mb-4">
            <LabelInput
              id="create-password"
              name="password"
              iconClass="password"
              labelText="Create Password"
              labelType="password"
              errorMessage={this.state.errors.password}
              value={this.state.password}
              onChangeValue={this.handleChange}
            />
          </div>
          <div className="mb-4">
            <LabelInput
              id="confirm-password"
              name="confirmPassword"
              iconClass="password"
              labelText="Confirm Password"
              labelType="password"
              errorMessage={this.state.errors.confirmPassword}
              value={this.state.confirmPassword}
              onChangeValue={this.handleChange}
            />
          </div>
          <Button className="w-full" variant="primary" type="submit">Reset Password</Button>
        </form>
      </div>
    );
  }
}

ResetPasswordForm.propTypes = propTypes;

export default ResetPasswordForm;
