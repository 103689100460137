import React from 'react';
import PropTypes from 'prop-types';

const CircularProgress = ({ total, completed, className }) => {
  const radius = 18;
  const circumference = 2 * Math.PI * radius;
  const percentage = (completed / total) * 100;
  const offset = circumference - (percentage / 100) * circumference;

  return (
    <div className={`relative ${className || 'w-12 text-xs'}`}>
      <svg className="w-full h-full transform -rotate-90" viewBox="0 0 44 44">
        <circle
          cx="22"
          cy="22"
          r="18"
          fill="transparent"
          stroke="#d6d6d6"
          strokeWidth="4"
        />
        <circle
          cx="22"
          cy="22"
          r="18"
          fill="transparent"
          stroke="green"
          strokeWidth="4"
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          style={{ transition: 'stroke-dashoffset 0.35s' }}
        />
      </svg>
      <div className="absolute inset-0 flex items-center justify-center font-bold">
        {`${completed}/${total}`}
      </div>
    </div>
  );
};

CircularProgress.propTypes = {
  total: PropTypes.number.isRequired,
  completed: PropTypes.number.isRequired,
  className: PropTypes.string,
};

CircularProgress.defaultProps = {
  className: null,
};

export default CircularProgress;
