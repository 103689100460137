import React, { Component } from 'react';
import Helmet from 'react-helmet';

import { propTypes, defaultProps } from './types';
import FullScreenHeader from './FullScreenHeader';
import FullScreenBody from './FullScreenBody';

class FullScreen extends Component {
  constructor(props) {
    super(props);
    this.state = { isVisible: false };
  }

  showTakeover = () => {
    this.setState({ isVisible: true });
  };

  cancelTakeover = () => {
    this.setState({ isVisible: false });
    this.props.cancel.onClick();
  };

  confirm = () => {
    if (this.props.confirmOptions.onClick()) {
      this.setState({ isVisible: false });
    }
  };

  renderTrigger() {
    return React.cloneElement(
      this.props.takeoverTrigger,
      { onClick: this.showTakeover },
    );
  }

  render() {
    return (
      <div>
        {this.renderTrigger()}
        <section className={`fullscreen ${this.state.isVisible ? 'is-visible' : ''}`}>
          <Helmet>
            <body className={`${this.state.isVisible ? ' is-fixed' : ''}`} />
          </Helmet>
          <FullScreenHeader
            confirmHandler={this.confirm}
            cancelText="Cancel"
            cancelHandler={this.cancelTakeover}
            cancelOptions={{
              isCancel: true,
              className: 'mr-0.5',
            }}
            isHiddenOverlay
            {...this.props}
          >
            {this.props.headerContent}
          </FullScreenHeader>
          <FullScreenBody overflow>
            {this.props.children}
          </FullScreenBody>
        </section>
      </div>
    );
  }
}

FullScreen.propTypes = propTypes;
FullScreen.defaultProps = defaultProps;

export default FullScreen;
