import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const DropdownSelect = props => {
  const labelRef = useRef(null);
  useEffect(() => {
    if (props.errorMessage) {
      labelRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [props.errorMessage]);

  return (
    <div>
      <label htmlFor={props.id} className="block text-sm font-medium leading-6 text-left text-gray-700">
        {props.labelText}
      </label>
      <div ref={labelRef} className="relative">
        <select
          id={props.id}
          name={props.name}
          value={props.value}
          className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 shadow-sm text-gray-900 ring-1 hover:bg-stone-25 focus:ring-inset focus:ring-rust-500 focus:shadow-input ring-rust-500 ring-opacity-5 focus:outline-none sm:text-sm sm:leading-6"
          onChange={props.onChangeValue}
          disabled={props.isDisabled}
          required={props.isRequired}
        >
          {props.options.map(option => (
            <option key={option.value} value={option.value}>
              {option.name}
            </option>
          ))}
        </select>
      </div>
      {props.helperText && !props.errorMessage
        ? (
          <p className="mt-2 font-sans text-sm text-left text-gray-500">
            {props.helperText}
          </p>
        ) : null}
      {props.errorMessage
        ? (
          <p className="h-4 mt-2 font-sans text-sm text-left text-red-500">
            {props.errorMessage}
          </p>
        ) : null}
    </div>
  );
};

DropdownSelect.propTypes = {
  labelText: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]).isRequired,
  })),
  onChangeValue: PropTypes.func.isRequired,
  helperText: PropTypes.string,
  errorMessage: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

DropdownSelect.defaultProps = {
  value: undefined,
  options: [],
  labelText: '',
  errorMessage: '',
  helperText: '',
  isRequired: false,
  isDisabled: false,
};

export default DropdownSelect;
