import tracker from '../index';

const {
  eventNames,
  eventProps,
  resolveAfterTimeout,
} = tracker;

const trackClickCTA = async (
  apiService,
  pageTitle,
  buttonText,
  link,
  pageURL,
) => {
  await apiService.post('trackers/mixpanel', {
    eventName: eventNames.CLICK_CTA,
    properties: {
      [eventProps.PAGE_TITLE]: pageTitle,
      [eventProps.PAGE_URL]: pageURL,
      [eventProps.BUTTON_TEXT]: buttonText,
      [eventProps.LINK]: link,
    },
  });
  return resolveAfterTimeout;
};

export {
  trackClickCTA,
};
