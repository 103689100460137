import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../../common/Button';

const renderInfo = (domains, onDelete) => domains.map(domain => (
  <tr key={domain.id} className="even:bg-ivory-500">
    <td className="py-4 pl-0 pr-3 text-sm font-medium text-gray-900 md:pl-4 break-word" data-label="Domain Value">
      {domain.domain}
    </td>
    <td className="px-3 py-4 text-sm text-gray-900 whitespace-nowrap" data-label="">
      <Button
        isSmall
        dataID={`domain-${domain.id}`}
        onClick={onDelete}
      >Delete
      </Button>
    </td>
  </tr>
));

const OrganizationDomainList = ({ domains, onDelete }) => {
  if (!domains || !domains.length) return null;

  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead>
        <tr>
          <th scope="col" className="py-3.5 pl-0 md:pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
            Domain
          </th>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" />
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {renderInfo(domains, onDelete)}
      </tbody>
    </table>
  );
};

OrganizationDomainList.propTypes = {
  domains: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    domain: PropTypes.string.isRequired,
  })),
  onDelete: PropTypes.func.isRequired,
};

OrganizationDomainList.defaultProps = {
  domains: [],
};

export default OrganizationDomainList;
