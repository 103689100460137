import React from 'react';
import { propTypes, defaultProps } from './types';

import { translations } from '../../../lib/constants';

const isLanguageRtl = langCode => (
  translations.some(lang => lang.value === langCode && lang.rtl === true)
);

const SurveyQuestionText = props => {
  let classNames = 'm-0 py-2 md:py-4 font-serif text-2xl font-normal leading-8 tracking-wider text-center md:leading-tight md:text-3xl md:leading-15';
  if (isLanguageRtl(props.languageSelected)) {
    classNames += ' rtl';
  }
  return <h3 className={classNames}>{props.questionText}</h3>;
};

SurveyQuestionText.propTypes = propTypes;
SurveyQuestionText.defaultProps = defaultProps;

export default SurveyQuestionText;
