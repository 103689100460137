/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { CurrentUserContext } from '../../context/CurrentUser';

import withServerSideData from '../../HOC/withServerSideData';

import PageHeader from '../../components/PageHeader';
import PortraitBlock from '../../components/Block/PortraitBlock';
import LabelInput from '../../components/common/LabelInput';
import Button from '../../components/common/Button';

import { trackLearningModuleHomeViewed, trackLMBlocksSearch } from '../../lib/tracker/learning-module-home';

const LearningModuleHome = ({ initialData: { learningModuleBlocks } }) => {
  const initialBlocks = learningModuleBlocks;
  const { currentUser } = useContext(CurrentUserContext);
  const { connection } = currentUser;

  const [filterValue, setFilterValue] = useState('');
  const [blocks, setBlocks] = useState(learningModuleBlocks);

  useEffect(() => {
    trackLearningModuleHomeViewed();
  }, []);

  const handleChange = e => setFilterValue(e.target.value);

  useEffect(() => {
    if (!filterValue) return setBlocks(learningModuleBlocks);
    const delayDebounceFn = setTimeout(() => {
      const specificKeys = ['title', 'typeName', 'tags', 'description', 'cultures', 'languages'];
      const searchTerm = filterValue.toLowerCase();
      const filteredBlocks = learningModuleBlocks.filter(block =>
        specificKeys.some(key =>
          block[key]?.toString().toLowerCase().includes(searchTerm)
          || JSON.stringify(block[key])?.toLowerCase().includes(searchTerm),
        ));
      setBlocks(filteredBlocks);
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [filterValue]);

  const handleClearFilter = e => {
    e.preventDefault();
    setFilterValue('');
  };

  const handleInputOnBlur = () => {
    if (filterValue.length > 2) {
      trackLMBlocksSearch(filterValue);
    }
  };

  return (
    <>
      <PageHeader
        pageTitle="Learning Modules"
        icon="learning"
        skipTarget="#learning-module-home"
      />
      <div>
        <div className="p-2">
          <LabelInput
            id="filterValue"
            name="filterValue"
            labelType="text"
            value={filterValue}
            onChangeValue={handleChange}
            onBlur={handleInputOnBlur}
            type="text"
            placeholderText="Start typing to search learning modules…"
            leadingIcon="search"
            trialingIcon="x-close"
            handleIconClick={handleClearFilter}
            iconButtonDisabled={!filterValue}
          />
        </div>
        <section className="flex flex-row w-full px-6 py-8 m-auto text-center xl:my-10 xl:flex-col md:flex-row bg-ivory-100 md:px-8 md:py-12 xl:px-12 rounded-3xl">
          {initialBlocks.length === 0 ? (
            <div className="flex justify-center">
              <div className="flex flex-col justify-between w-full px-6 md:pl-10 max-w-[610px]">
                <h3 className="mb-4 font-serif leading-snug tracking-wider text-left text-charcoal-900">
                  <span className="underline decoration-rust-500 decoration-2 underline-offset-8 md:underline-offset-[15px]">
                    You d
                  </span>
                  on&apos;t have any learning modules
                </h3>
                <p className="py-2 font-sans text-base font-light text-left md:text-sm">
                  It looks like you don&apos;t have any learning modules.
                  You can <a target="_blank" href="https://aperian.com/contact/" rel="noreferrer">reach out</a> to learn more about getting access!
                  If you think this is an error, please contact <a target="_blank" href="https://aperian.zendesk.com/hc/" rel="noreferrer">support</a> for additional assistance.
                </p>
                <div className="flex">
                  <Button
                    to="/dashboard"
                    filledColor="primary"
                    className="ml-auto whitespace-nowrap"
                    isExtraSmall
                  >Return to Dashboard
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            (Object.keys(blocks).length === 0 && initialBlocks.length !== 0) ? (
              <div className="flex justify-center">
                <div className="flex flex-col justify-between w-full px-6 md:pl-10 max-w-[610px]">
                  <h3 className="mb-4 font-serif leading-snug tracking-wider text-left text-charcoal-900">
                    <span className="underline decoration-rust-500 decoration-2 underline-offset-8 md:underline-offset-[15px]">
                      You
                    </span>
                    r search didn&apos;t match any results
                  </h3>
                  <p className="py-2 font-sans text-base font-light text-left md:text-sm">
                    It looks like the item you&apos;re looking for isn&apos;t here.
                    Please alter your search or reach out to support for
                    additional assistance.
                  </p>
                  <div className="flex">
                    <Button
                      filledColor="primary"
                      className="ml-auto whitespace-nowrap"
                      isExtraSmall
                      onClick={handleClearFilter}
                    >Return to Learning Modules
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-3">
                {blocks
                  .sort((a, b) => (a.title > b.title ? 1 : -1))
                  .map(block => (
                    <PortraitBlock
                      key={`${block.id}-${block.internalName}`}
                      {...block}
                      connection={connection}
                      languages={block.languages}
                      directUrl={block.externalDirectUrl}
                      completionRangeStart={block.moduleRangeStart || block.externalRangeStart}
                      completionRangeEnd={block.moduleRangeEnd || block.externalRangeEnd}
                    />
                  ))}
              </div>
            )
          )}
        </section>
      </div>
    </>
  );
};

LearningModuleHome.getAPIDataKey = () => 'learningModuleHome';
LearningModuleHome.getData = apiService => apiService.get('blocks/getLearningModules')
  .then(learningModuleHome => ({ learningModuleHome }));

LearningModuleHome.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  initialData: PropTypes.shape({
    learningModuleBlocks: PropTypes.arrayOf(
      PropTypes.shape({
        connection: PropTypes.string,
        blockType: PropTypes.number,
        typeName: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        completionRangeStart: PropTypes.string,
        completionRangeEnd: PropTypes.string,
        tags: PropTypes.arrayOf(PropTypes.string),
        blockImage: PropTypes.string,
        defaultImageLink: PropTypes.string,
        buttonText: PropTypes.string,
        html: PropTypes.string,
        moduleId: PropTypes.number,
        directUrl: PropTypes.string,
        asmtId: PropTypes.string,
        internalName: PropTypes.string,
        languages: PropTypes.arrayOf(PropTypes.string),
      })).isRequired,
  }).isRequired,
};

export default withServerSideData(LearningModuleHome);
