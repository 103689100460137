import { AuthError, BadRequestError } from '../../lib/errors';

class LogoutAction {
  constructor(apiService) {
    this.apiService = apiService;
  }

  execute() {
    return this.apiService.post('logout')
      .catch(err => {
        if (err instanceof AuthError) {
          return { authError: 'Please try again. There was an error with your authentication.' };
        }
        if (err instanceof BadRequestError) {
          return err.reason;
        }
        throw err;
      });
  }
}

export default LogoutAction;
