import React, { useEffect } from 'react';

import PageHeader from '../../components/PageHeader';

import { trackViewCopilot } from '../../lib/tracker/copilot';

const Copilot = () => {
  useEffect(() => {
    const trackPageView = async () => {
      await trackViewCopilot();
    };
    trackPageView();
  }, []);

  return (
    <>
      <PageHeader
        pageTitle="Aperian Copilot"
        icon="copilot"
        skipTarget="#copilot"
      />
      <section className="flex flex-col flex-grow px-4 py-8 m-auto my-4 text-center bg-white md:px-12 rounded-3xl">
        <div>
          <p className="px-4 text-base md:px-8">
            Get answers to your global business questions quickly,
            easily, and in any language!
            The <b>Aperian Copilot</b>, our AI&#8209;powered cultural expert,
            is now available. Ask about any of
            the <a href="https://aperian.zendesk.com/hc/en-us/articles/24341543840915-GlobeSmart-Guide-Cultures-List" target="_blank" rel="noopener noreferrer">102 cultures</a> currently available in our GlobeSmart Guides
            by typing a question below.
          </p>
          <p className="mb-1 text-base italic font-bold">
            We want to get your feedback,
          </p>
          <p className="mb-8 text-base italic font-bold">so please share your thoughts <a href="https://form.asana.com/?k=wVsgVMC_3PxSQyh96JF_JQ&d=87210769388827" target="_blank" rel="noopener noreferrer">here</a>.
          </p>
        </div>
        <iframe
          className="flex-grow"
          title="guides-copilot"
          src="https://app.chaindesk.ai/agents/cllfrduux000h74rg41vqexhn/iframe"
          allow="clipboard-write"
          width="100%"
          height="525"
        />
      </section>
    </>
  );
};

export default Copilot;
