/* eslint-disable prefer-arrow-callback */
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import PropTypes from 'prop-types';

import Tag from '../common/Tag';

const AutoSuggest = forwardRef(
  function AutoSuggest({
    name,
    labelText,
    options: initialOptions,
    previousSelection,
    selectedOptions,
    onSearchOptions,
    onSelectOption,
    onRemoveSelectedOption,
    placeholder,
    isMultiSelect,
    isRequired,
    helperText,
    errorMessage,
  }, ref) {
    const filteredOptions = selectedOptions.length ? initialOptions.filter(opt =>
      !selectedOptions.some(so => so.value === opt.value)) : initialOptions;

    const [inputValue, setInputValue] = useState(previousSelection || '');
    const [options, setOptions] = useState(filteredOptions);
    const [suggestions, setSuggestions] = useState(options);

    const inputRef = useRef(null);

    const handleOnChange = newValue => setInputValue(newValue);

    useEffect(() => {
      setInputValue(previousSelection || '');
    }, [previousSelection]);

    useEffect(() => {
      let timer;
      if (!inputValue && !!timer) return setSuggestions([]);

      if (onSearchOptions) {
        if (timer) window.clearTimeout(timer);
        timer = setTimeout(async () => {
          // onSearchOptions prop should return an array
          setSuggestions(await onSearchOptions(inputValue));
        }, 250);
      }
      return () => clearTimeout(timer);
    }, [inputValue]);

    useEffect(() => {
      if (errorMessage) {
        inputRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }, [errorMessage]);

    const resetOptions = () => {
      setInputValue('');
      setOptions(initialOptions);
      setSuggestions([]);
    };

    useImperativeHandle(ref, () => ({
      resetOptions,
    }));

    const getSuggestions = newValue => options.filter(({ name: option }) =>
      option.toLowerCase().includes(newValue.trim().toLowerCase()));

    const onSuggestionsFetchRequested = ({ value: newValue }) => {
      setSuggestions(getSuggestions(newValue));
    };

    /*
    When using the isMultiSelect property, when you add
    the selected suggestion to the state array, it must be
    in the format of
    { name: e.target.name, value: e.target.value }
    so that the SelectedOptions component can render correctly
  */
    const onSuggestionSelected = (e, { suggestion }) => {
      e.preventDefault();
      if (isMultiSelect) {
        setInputValue('');
        setOptions(options.filter(opt => opt.value !== suggestion.value));
        onSelectOption({
          target: {
            name,
            value: [...selectedOptions, suggestion],
          },
        });
      } else {
        inputRef.current.blur();
        const newEvent = ['submit', 'button'].includes(e.target.type) ? e : { target: suggestion };
        setInputValue(suggestion?.name);
        onSelectOption(newEvent);
      }
    };

    const onSuggestionRemoved = ({ name: planName, value }) => {
      const planReturned = [...options, { name: planName, value }];
      const sortedPlans = planReturned.sort((a, b) => (a.value > b.value ? 1 : -1));
      setOptions(sortedPlans);
      onRemoveSelectedOption(value);
    };

    const onSuggestionsClearRequested = () => (isMultiSelect ? setInputValue('') : setSuggestions([]));

    const getSuggestionValue = suggestion => suggestion?.name || '';

    const renderSuggestion = ({ name: displayName, value }) => (
      <button
        key={name}
        name={isMultiSelect ? `${displayName}` : name}
        value={value}
        className="react-autosuggest__link"
      >
        {displayName}
      </button>
    );

    const onEnterPress = e => {
      if (e.key === 'Enter' && suggestions.length === 1) {
        const remainingSuggestion = suggestions.shift();
        if (isMultiSelect) {
          e.target = remainingSuggestion;
        } else {
          e.target = { name, value: remainingSuggestion.value };
        }

        onSuggestionSelected(e, { suggestion: remainingSuggestion });
        return;
      }

      if (e.key === 'Enter') {
        e.preventDefault();
        const selectedPlan = options.find(option => option.name === inputValue);
        if (!selectedPlan) {
          setInputValue('');
        }
      }
    };

    const onBlur = (event, { highlightedSuggestion }) => {
      if (!event?.target?.name) { return; }
      const newEvent = {
        target: {
          name: event.target.name,
          value: highlightedSuggestion?.value || (isMultiSelect ? selectedOptions : null),
        },
      };
      onSelectOption(newEvent);
    };

    const renderInputComponent = inputProps => (
      <input name={name || undefined} className="selected-profiles__input" {...inputProps} />
    );

    const inputProps = {
      placeholder,
      value: inputValue,
      ref: inputRef,
      onChange: (event, { newValue }) => handleOnChange(newValue),
      onKeyDown: event => onEnterPress(event),
      onBlur,
      required: isRequired,
    };

    return (
      <div>
        <p className="mb-2 font-sans text-sm font-medium leading-6 text-left text-gray-700">{labelText}</p>
        <div className="autosuggest">
          {isMultiSelect && (
          <ul className="flex flex-wrap gap-2 list-none">
            {selectedOptions.map(option => (
              <Tag
                text={option.name}
                key={option.value}
                onIconClick={() => onSuggestionRemoved(option)}
              />
            ))}
          </ul>
          )}
          <Autosuggest
            focusInputOnSuggestionClick={false}
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            shouldRenderSuggestions={() => true}
            renderInputComponent={renderInputComponent}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
            onSuggestionSelected={onSuggestionSelected}
          />
          {helperText && !errorMessage ? <p className="mt-2 font-sans text-sm text-left text-gray-500">{helperText}</p> : null}
          {errorMessage ? <p className="h-4 mt-2 font-sans text-sm text-left text-red-500">{errorMessage}</p> : null}
        </div>
      </div>
    );
  });

AutoSuggest.propTypes = {
  name: PropTypes.string,
  labelText: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  })),
  selectedOptions: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  })),
  onChangeValue: PropTypes.func,
  onSearchOptions: PropTypes.func,
  onSelectOption: PropTypes.func,
  onRemoveSelectedOption: PropTypes.func,
  previousSelection: PropTypes.string,
  optionsAreLinks: PropTypes.bool,
  isMultiSelect: PropTypes.bool,
  isRequired: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  errorMessage: PropTypes.string,
};

AutoSuggest.defaultProps = {
  name: '',
  labelText: '',
  options: [],
  selectedOptions: [],
  onChangeValue: null,
  onSearchOptions: null,
  onSelectOption: null,
  onRemoveSelectedOption: null,
  previousSelection: '',
  optionsAreLinks: false,
  isMultiSelect: false,
  isRequired: false,
  helperText: '',
  errorMessage: '',
};

export default AutoSuggest;
