import React from 'react';
import Button from '../../../components/common/Button';

const ProfileCTA = () => (
  <div className="flex flex-col my-3 bg-ivory-400 px-8 lg:px-[4.375rem] py-6 lg:py-16 w-[95%] text-3xl mx-auto rounded-3xl">
    <div>
      <div className="flex flex-col items-center justify-center gap-6 mx-auto pb-9">
        <span className="text-2xl font-semibold md:text-3xl font-headline text-charcoal-900">What&apos;s next?</span>
        <p className="w-full m-auto font-sans text-base font-light leading-7 tracking-wide text-center md:text-lg text-charcoal-900 md:w-5/6">
          Your work style profile is unique and you can learn even more by
          comparing your profile to someone else in your network.
          Use our <b>Profile Comparison</b> tool to invite and compare
          with a colleague.
        </p>
      </div>
    </div>
    <div className="flex justify-around">
      <Button
        trailingButtonIcon="arrow-right"
        variant="primary"
        to="profile/comparison/new"
      >
        Compare your Profile to others
      </Button>
    </div>
  </div>
);

export default ProfileCTA;
