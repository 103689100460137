import React from 'react';
import PropTypes from 'prop-types';

import { prefixHostname } from '../../../lib/cdn';
import Payment from '../../Payment';
import { GS_PREMIUM_STRIPE_PLAN } from '../../../lib/constants';
import Icon from '../../common/Icon';
import Button from '../../common/Button';

const UpgradeMessage = ({ handleOpenModal, handleCloseModal }) => (
  <div className="p-6 md:px-10">
    <div className="flex items-center gap-4">
      <div className="rounded-full bg-ivory-400">
        <Icon icon="culture-guide" className="m-2.5 w-4 h-4" iconColor="gray" />
      </div>
      <h2 className="my-2 font-serif text-2xl antialiased font-thin tracking-wide sm:my-4 text-charcoal-500">GlobeSmart Guides</h2>
    </div>
    <div className="grid grid-cols-1 pt-3 pb-2 lg:grid-cols-7">
      <div className="col-span-3 py-3 lg:px-3">
        <p className="pb-4 font-light">GlobeSmart Guides give you access to valuable information about
          working, communicating, and doing business in 100+ cultures. Upgrade today for access!
        </p>
        <Button
          isLarge
          variant="primary"
          trailingButtonIcon="arrow-right"
          iconColor="white"
          onClick={() => handleOpenModal({
            content: <Payment
              planId={GS_PREMIUM_STRIPE_PLAN}
              handleCloseModal={handleCloseModal}
            />,
          })}
        >
          Upgrade your account
        </Button>
      </div>
      <div className="col-span-4 py-3 text-center lg:px-3">
        <img
          className="border border-solid border-stone-200"
          src={prefixHostname('/images/culture-guides-default.png')}
          alt="Culture Guide Screenshot"
        />
      </div>
    </div>
  </div>
);

UpgradeMessage.propTypes = {
  handleOpenModal: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};

export default UpgradeMessage;
