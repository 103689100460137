import { invalidEmailDomains } from '../../pages/Onboarding/invalidEmailDomains';

const getDimensionZone = score => {
  if (score >= 5.6) {
    return 'Direct';
  } if (score <= 4.4) {
    return 'Indirect';
  }
  return 'in the middle';
};

const validateCreatorEmail = email => {
  // Validates email domain.
  const isInvalid = invalidEmailDomains.some(domain => {
    // escape the period so it's not treated as a regex wildcard
    const domainPattern = `@${domain.toLowerCase().replace('.', '\\.')}`;
    const re = new RegExp(domainPattern);
    return re.test(email.toLowerCase());
  });

  // Check if the email ends with '.edu' or contains '.edu.' after '@'.
  const isEdu = email.endsWith('.edu') || (email.includes('@') && (email.split('@')[1].includes('.edu.')));

  return { isInvalid, isEdu };
};

export {
  getDimensionZone,
  validateCreatorEmail,
};
