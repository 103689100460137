import { brazeAccessCodeRedeem } from '../../brazeTracker/customEvents';
import tracker from '../index';

const {
  eventNames,
  eventProps,
  peopleProps,
  superProps,
  getHighestAccessLevel,
  resolveAfterTimeout,
} = tracker;

const trackAccessCodeRedeem = ({
  userid, accessLevel = [], planName, accessCode,
}) => {
  tracker.identify(userid);

  tracker.track(eventNames.REDEEM_ACCESS_CODE, {
    [eventProps.PLAN_NICKNAME]: planName,
    [eventProps.ACCESS_CODE_ID]: accessCode,
    [eventProps.SUCCESS]: true,
  });

  tracker.register({
    [superProps.ACCESS_LEVEL]: getHighestAccessLevel(accessLevel),
  });

  tracker.people.union(peopleProps.ACCESS_CODES_REDEEMED, planName);
  brazeAccessCodeRedeem(planName, accessCode);
  return resolveAfterTimeout;
};

const trackAccessCodeRedeemFailure = reason => {
  tracker.track(
    eventNames.REDEEM_ACCESS_CODE, {
      [eventProps.SUCCESS]: false,
      [eventProps.FAILURE_REASON]: reason,
    },
  );
  return resolveAfterTimeout;
};

export { trackAccessCodeRedeem, trackAccessCodeRedeemFailure };
