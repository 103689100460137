import React from 'react';
import PropTypes from 'prop-types';

const BlockTimeRange = ({ completionRangeStart, completionRangeEnd }) => {
  const rangeText = () => {
    const rangeEnd = completionRangeEnd > 0 ? `- ${completionRangeEnd}` : '';
    const text = `${completionRangeStart} ${rangeEnd} minutes`;
    return text;
  };

  return completionRangeStart > 0 ? (<p className="mb-0 ml-1 text-xs antialiased font-normal tracking-wider text-charcoal-400">{rangeText()}</p>) : null;
};

BlockTimeRange.propTypes = {
  completionRangeStart: PropTypes.string,
  completionRangeEnd: PropTypes.string,
};

BlockTimeRange.defaultProps = {
  completionRangeStart: '',
  completionRangeEnd: '',
};

export default BlockTimeRange;
