import React from 'react';

const ProfileHistorySummary = () => (
  <div className="w-full py-4 m-auto leading-loose text-center md:w-5/6">
    <h2>Your style has changed over time. Have a look at your Profile History.</h2>
    <p>
      Our workstyles are flexible and change over time and with new experiences. Why do you think
      your workstyle has changed in this way? Perhaps you’re working on a different team, have new
      responsibilities, or have been influenced by a new culture.
    </p>
    <p>
      Remember, there is no right or wrong profile. All styles bring value to the workplace.
    </p>
  </div>
);

export default ProfileHistorySummary;
