/* eslint-disable prefer-arrow-callback */
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import DOMPurify from 'isomorphic-dompurify';
import PropTypes from 'prop-types';

import { Form } from '../../common/Form';

import TeamInvitationFormHeader from './FormHeader';
import LabelTextArea from '../../common/LabelTextarea';
import DropdownSelect from '../../common/Dropdowns/DropdownSelect';
import LabelInput from '../../common/LabelInput';
import Button from '../../common/Button';
import Notification from '../../Notifications/Notification';

const TeamInvitationForm = forwardRef(
  function TeamInvitationForm({
    formData,
    formError,
    handleOnChange,
    validateEmails,
    accessCodes,
    updateAccessCode,
    handleFormSubmit,
    notification,
  }, ref) {
    const teamNameRef = useRef(null);
    const memberEmailsRef = useRef(null);
    const ownerEmailsRef = useRef(null);

    useImperativeHandle(ref, () => ({
      get teamName() {
        return teamNameRef.current;
      },
      get memberEmails() {
        return memberEmailsRef.current;
      },
      get ownerEmails() {
        return ownerEmailsRef.current;
      },
    }));

    return (
      <div id="create-team">
        <Form className="[&>:nth-child(2)]:mt-12" bgColor="ivory" handleFormSubmit={handleFormSubmit}>
          {notification ? <Notification {...notification} /> : null}
          <TeamInvitationFormHeader />
          <LabelInput
            ref={teamNameRef}
            id="teamname"
            name="teamName"
            labelText="Team Name"
            labelType="text"
            value={formData.teamName}
            onChangeValue={handleOnChange}
            helperText="Enter a friendly name that will appear to your team members."
            errorMessage={formError.teamName}
            isRequired
          />
          <LabelInput
            ref={ownerEmailsRef}
            id="additionalOwner"
            name="ownerEmails"
            labelText="Additional Group Owners"
            labelType="text"
            value={formData.ownerEmails}
            onChangeValue={handleOnChange}
            onBlur={validateEmails}
            helperText="Invite someone to help you manage the team."
            errorMessage={formError.ownerEmails}
          />
          <LabelTextArea
            id="customMessage"
            name="customMessage"
            labelText="Custom Message"
            placeholder="You might consider including:&#10;- Please complete the survey by DATE...&#10;- If you have questions, reach out to EMAIL..."
            onChangeValue={handleOnChange}
            value={DOMPurify.sanitize(formData.customMessage, { USE_PROFILES: [] })}
            helperText="Enter your message that will appear within the standard invitation."
          />

          <LabelTextArea
            ref={memberEmailsRef}
            id="memberEmails"
            name="memberEmails"
            labelText="Team Members"
            placeholder="johndoe@example.com, janedoe@example.com"
            onChangeValue={handleOnChange}
            errorMessage={formError.memberEmails}
            value={formData.memberEmails}
            onBlur={validateEmails}
            helperText="Enter comma separated email addresses or paste a spreadsheet column."
          />

          {accessCodes.length > 0
            && (
              <DropdownSelect
                id="accessCode"
                name="selectedAccessCodes"
                labelText="Access Code"
                value={formData.selectedAccessCode}
                options={accessCodes}
                onChangeValue={updateAccessCode}
                helperText="Select a code to provide access to team members."
              />
            )}
          {/*
              TODOs:
                - The Button component needs to be updated to move the icon
                  to the right, per Figma files. For now, I left off the icon.
                - Hover color was a guess. Should also be updated in the
                  Button component.
            */}
          <Button
            className="ml-auto"
            variant="primary"
            type="submit"
            onClick={handleFormSubmit}
          >
            Create Team
          </Button>
        </Form>
      </div>
    );
  });

TeamInvitationForm.propTypes = {
  formData: PropTypes.shape({
    teamName: PropTypes.string,
    memberEmails: PropTypes.string,
    ownerEmails: PropTypes.string,
    customMessage: PropTypes.string,
    selectedAccessCode: PropTypes.string,
  }).isRequired,
  formError: PropTypes.shape({
    teamName: PropTypes.string,
    memberEmails: PropTypes.string,
    ownerEmails: PropTypes.string,
  }).isRequired,
  handleOnChange: PropTypes.func.isRequired,
  validateEmails: PropTypes.func.isRequired,
  accessCodes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  updateAccessCode: PropTypes.func.isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
  notification: PropTypes.string,
};

TeamInvitationForm.defaultProps = {
  accessCodes: null,
  notification: null,
};

export default TeamInvitationForm;
