import PropTypes from 'prop-types';

const propTypes = {
  totalQuestionCount: PropTypes.number.isRequired,
  currentQuestionNumber: PropTypes.number.isRequired,
  questionId: PropTypes.number.isRequired,
  questionText: PropTypes.string.isRequired,
  previousAnswer: PropTypes.number,
  onBack: PropTypes.func,
  onAnswer: PropTypes.func.isRequired,
  isLast: PropTypes.bool.isRequired,
};

const defaultProps = {
  previousAnswer: null,
  onBack: null,
};

export {
  propTypes,
  defaultProps,
};
