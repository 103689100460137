/* eslint-disable react/prop-types */
import React from 'react';

const OrgAvatar = ({ className }) => (
  <svg className={className} viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="2" y="2" width="64" height="64" rx="32" fill="#FFE3E0" />
    <path d="M26.5 32.3333H21.6667C20.7333 32.3333 20.2665 32.3333 19.91 32.515C19.5964 32.6748 19.3415 32.9297 19.1817 33.2433C19 33.5999 19 34.0666 19 35V49M41.5 32.3333H46.3333C47.2668 32.3333 47.7335 32.3333 48.09 32.515C48.4036 32.6748 48.6586 32.9297 48.8184 33.2433C49 33.5999 49 34.0666 49 35V49M41.5 49V24.3333C41.5 22.4665 41.5 21.5331 41.1367 20.82C40.8171 20.1928 40.3072 19.6829 39.68 19.3633C38.9669 19 38.0335 19 36.1667 19H31.8333C29.9665 19 29.0331 19 28.32 19.3633C27.6928 19.6829 27.1829 20.1928 26.8633 20.82C26.5 21.5331 26.5 22.4665 26.5 24.3333V49M50.6667 49H17.3333M32.3333 25.6667H35.6667M32.3333 32.3333H35.6667M32.3333 39H35.6667" stroke="#E64833" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <rect x="2" y="2" width="64" height="64" rx="32" stroke="#FFC9C2" strokeWidth="4" />
  </svg>
);

export default OrgAvatar;
