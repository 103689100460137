import React from 'react';
import { propTypes } from './types';

const FullScreenBody = props => (
  <div className={`flex flex-col${props.overflow ? ' overflow-auto' : ''}`}>
    {props.children}
  </div>
);

FullScreenBody.propTypes = propTypes;

export default FullScreenBody;
