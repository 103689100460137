import React, { useContext } from 'react';

import { ToastContext } from '../../context/Toast';

import Toast from './Toast';

const Toasts = () => {
  const { toasts, removeToast } = useContext(ToastContext);
  return (
    <div
      aria-live="assertive"
      className="fixed inset-0 z-[315] flex items-end px-4 py-6 pointer-events-none top-12 sm:items-start sm:p-6"
    >
      <div className="flex flex-col items-center w-full space-y-3 sm:items-end">
        {toasts?.map((toast, i) => (
          <Toast
            key={toast.id}
            id={toast.id}
            dismiss={removeToast}
            bgColor={toast?.bgColor}
            content={toast.content}
            autoDismiss={toast?.autoDismiss}
            delay={toast?.delay || i * 100}
            toastLink={toast.toastLink}
            toastLinkText={toast.toastLinkText}
          />
        ))}
      </div>
    </div>
  );
};

Toasts.propTypes = {};
Toasts.defaultProps = {};

export default Toasts;
