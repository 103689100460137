import React from 'react';
import PropTypes from 'prop-types';

import ProfileDimensionItem from '../ProfileDimensionItem';

const ProfileDimensionGroup = props => {
  if (!props.dimensions) return null;

  return props.dimensions.map((dimension, index) => {
    const dim = { ...dimension };
    const labels = props.dimensionLabelGroups[index];

    const keys = Object.values(props.scores);
    const dimensionScore = keys[index];

    dim.labels = labels;
    dim.score = 100 - (dimensionScore * 10);
    return (<ProfileDimensionItem key={dimension.conflictVideoID} {...dim} />);
  });
};

export default ProfileDimensionGroup;

const item = PropTypes.shape({
  title: PropTypes.string.isRequired,
  positioningStatement: PropTypes.string.isRequired,
  tendencyHeading: PropTypes.string.isRequired,
  differenceCTA: PropTypes.string,
  tendencies: PropTypes.arrayOf(PropTypes.string).isRequired,
  styleSwitchingLink: PropTypes.string,
});

const ProfileDimensionItemPropTypes = {
  you: item.isRequired,
  other: item,
  conflictVideoID: PropTypes.string,
  conflictVideoSentence: PropTypes.string,
};

ProfileDimensionGroup.propTypes = {
  dimensions: PropTypes.arrayOf(PropTypes.shape(ProfileDimensionItemPropTypes)).isRequired,
  dimensionLabelGroups: PropTypes.arrayOf(PropTypes.shape({
    left: PropTypes.string,
    right: PropTypes.string,
  },
  )).isRequired,
  scores: PropTypes.shape({}).isRequired,
};
