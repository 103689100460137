import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../common/Button';

const NewComparisonCTA = ({ sendInvitationsLink, hasPremium, createTeamLink, router }) => {
  const buttonInfo = [
    {
      id: 1,
      buttonText: 'Invite Colleagues',
      link: sendInvitationsLink(),
      leadingButtonIcon: hasPremium() ? null : 'lock',
    },
    {
      id: 2,
      buttonText: 'Create a Team',
      link: createTeamLink(),
      leadingButtonIcon: hasPremium() ? null : 'lock',
    },
    {
      id: 3,
      buttonText: 'Manage Connections',
      link: '/profile/connections',
    },
  ];

  return (
    <div className="flex flex-col flex-wrap items-center justify-center w-full max-w-3xl gap-6 px-8 py-8 m-auto mx-auto text-center bg-white rounded-lg md:py-12">
      <p className="px-8 font-serif text-2xl font-semibold tracking-wider lg:text-3xl">Looking for more comparisons?</p>
      <p className="font-sans text-base font-normal tracking-wide lg:text-xl">Invite colleagues to connect or create a team.</p>
      <div className="flex flex-col gap-2 lg:gap-4 md:flex-row">
        {buttonInfo.map(({ id, buttonText, link, leadingButtonIcon }) => (
          <Button
            key={id}
            variant="primary"
            trailingButtonIcon="arrow-right"
            leadingButtonIcon={leadingButtonIcon || null}
            onClick={() => router.replace(link)}
          >
            {buttonText}
          </Button>
        ))}
      </div>
    </div>
  );
};

NewComparisonCTA.propTypes = {
  sendInvitationsLink: PropTypes.func,
  hasPremium: PropTypes.func,
  createTeamLink: PropTypes.func,
  router: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
};

NewComparisonCTA.defaultProps = {
  sendInvitationsLink: undefined,
  createTeamLink: undefined,
  hasPremium: undefined,
};

export default NewComparisonCTA;
