import PropTypes from 'prop-types';
import React, { useContext, useEffect, useReducer } from 'react';

import { APIContext } from '../../../context/API';
import { CurrentUserContext } from '../../../context/CurrentUser';
import withServerSideData from '../../../HOC/withServerSideData';

import TopAdviceGroup from '../../../components/custom/Advice/TopAdviceGroup';
import AdviceTopicsListing from '../../../components/custom/Advice/AdviceTopicsListing';
import PaymentViaUrl from '../../../components/PaymentViaUrl';
import ProfileChartTransformer from '../../../components/common/ProfileChartTransformer';

import comparableIDandType from '../../../lib/comparableIDandType';
import { trackAdviceViewed } from '../../../lib/tracker/advice';
import PremiumFeature from '../../../lib/features/Premium';
import { VIEW_ADVICE_FOR_GSP_TEAM_AVERAGE, VIEW_GLOBESMART_PROFILE_COMPARISON_ADVICE } from '../../MySkills/activityConstants';
import { trackActivityCompletion } from '../../../actions/skills';

const TopAdvice = props => {
  const { currentUser } = useContext(CurrentUserContext);
  const { apiService } = useContext(APIContext);

  const { id, type } = comparableIDandType(props);
  const [state, setState] = useReducer((data, newData) =>
    ({ ...data, ...newData }), {
    ...props.initialData,
    id,
    type,
    shortDisplayName: props.shortDisplayName,
    language: props.selectedLanguage,
  });

  useEffect(() => {
    if (props.selectedLanguage !== state.language) {
      const fetchData = async () => {
        await apiService.get(`profile/compare?${type}=${id}&language=${props.selectedLanguage}`)
          .then(data => {
            const { adviceTopics, topAdvice } = data;
            setState({
              adviceTopics,
              topAdvice,
              language: props.selectedLanguage,
            });
          });
      };
      fetchData()
        .catch(console.error);
    }
  }, [props.selectedLanguage]);

  const hasPremium = () => new PremiumFeature(currentUser)
    .positive(() => true)
    .negative(() => false)
    .execute();

  const trackAdviceandActivities = async e => {
    let topic = e.target.getAttribute('data-topic');

    if (!topic) {
      const parentWithDataTopic = e.target.closest('[data-topic]');
      if (parentWithDataTopic) {
        topic = parentWithDataTopic.getAttribute('data-topic');
      }
    }
    await trackAdviceViewed(apiService, topic, props.shortDisplayName);

    setTimeout(() => {
      try {
        const activity = VIEW_GLOBESMART_PROFILE_COMPARISON_ADVICE;
        if (state?.type === 'team') {
          const activities = [
            VIEW_GLOBESMART_PROFILE_COMPARISON_ADVICE,
            VIEW_ADVICE_FOR_GSP_TEAM_AVERAGE,
          ];
          trackActivityCompletion(apiService, activities);
        } else {
          trackActivityCompletion(apiService, activity);
        }
      } catch (error) {
        console.error('An error occurred: ', error);
      }
    }, 5000);
  };

  return (
    <div>
      <h3 className="mb-0 font-normal font-headline">
        Top advice for <span className="italic text-rust-500">biggest</span> difference:
        {state.topAdvice && state.topAdvice.length
          ? <span className="ml-2 font-normal">{state.topAdvice[0].dimensionName}</span>
          : null}
      </h3>
      <div className="relative grid grid-cols-1 gap-4 md:pt-8 md:grid-cols-2">
        <div className="hidden mx-auto lg:w-5/6 md:block">
          <div className="sticky px-6 py-4 rounded-lg top-8 bg-slate-25">
            <ProfileChartTransformer
              {...props}
              isAdviceComparison
              withBorder
              bgColor="bg-slate-25"
            />
          </div>
        </div>
        <PaymentViaUrl {...props} />
        {state.topAdvice
          ? (
            <TopAdviceGroup
              {...state}
              onClick={trackAdviceandActivities}
              isAuthorized={hasPremium()}
              searchParams={props.location.search}
            />
          )
          : null}
      </div>
      <AdviceTopicsListing
        {...state}
        onClick={trackAdviceandActivities}
        isAuthorized={hasPremium()}
        searchParams={props.location.search}
      />
    </div>
  );
};

TopAdvice.getAPIDataKey = ({ org, teams_average: team, culture, person }) =>
  `topAdvice_${person || team || culture || org}`;

TopAdvice.getData = (apiService, params) => {
  const { id, type } = comparableIDandType(params);
  if (!Array.isArray(id)) {
    return apiService.get(`profile/compare?${type}=${id}&language=${params.language}`)
      .then(data => ({ [`topAdvice_${id}`]: data }));
  }
  return Promise.resolve({});
};

TopAdvice.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
    pathname: PropTypes.string,
  }).isRequired,
  initialData: PropTypes.shape({
    initialProfiles: PropTypes.arrayOf(
      PropTypes.shape({
        avatarUrl: PropTypes.string,
      }),
    ),
    profiles: PropTypes.arrayOf(
      PropTypes.shape({
        avatarUrl: PropTypes.string,
      }),
    ),
    teamInformation: PropTypes.shape({
      isOwner: PropTypes.bool,
      isMember: PropTypes.bool,
    }),
    dimensionLabelGroups: PropTypes.arrayOf(
      PropTypes.shape({
        left: PropTypes.string,
        right: PropTypes.string,
      }),
    ),
  }).isRequired,
  shortDisplayName: PropTypes.string,
  selectedLanguage: PropTypes.string.isRequired,
};

TopAdvice.defaultProps = {
  shortDisplayName: '',
};

export default withServerSideData(TopAdvice);
