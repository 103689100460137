import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ElementQuery from 'react-eq';

import { RouterContext } from '../../../context/Router';
import { NotificationContext } from '../../../context/Notification';
import { APIContext } from '../../../context/API';

import withServerSideData from '../../../HOC/withServerSideData';

import { getCollectionBlocks, updateBlockOrderAction } from '../../../actions/blocks';

import Button from '../../../components/common/Button';
import DraggableCardList from '../../../components/DraggableCardList';
import Card from '../../../components/Card';
import Notification from '../../../components/Notifications/Notification';
import DropdownSelect from '../../../components/common/Dropdowns/DropdownSelect';

const queries = { 'card-layout': 400 };

const BlocksDefaultSort = ({ initialData: { blocks, collectionIdentifiers } }) => {
  const { router } = useContext(RouterContext);
  const {
    notificationMessage,
    removeNotification,
    addNotification,
  } = useContext(NotificationContext);
  const { apiService } = useContext(APIContext);
  const [currentCollection, setCurrentCollection] = useState(collectionIdentifiers.at(0).value);
  const [collectionName, setCollectionName] = useState(collectionIdentifiers.at(0).name);
  const [sortedProducts, setSortedProducts] = useState(blocks);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    let timeout;
    if (notificationMessage) {
      window.scroll(0, 0);
      timeout = window.setTimeout(() => {
        removeNotification();
      }, 5000);
    }
    return () => {
      window.clearTimeout(timeout);
      if (notificationMessage) removeNotification();
    };
  }, [notificationMessage]);

  const handleChangeCollection = async ({ target: { value } }) => {
    const newBlocks = await getCollectionBlocks(apiService, value);
    const newConnectionName = collectionIdentifiers.find(id => id.value.toString() === value).name;
    setCurrentCollection(value);
    setCollectionName(newConnectionName);
    setSortedProducts(newBlocks.blocks);
  };

  const handleChangeProducts = newProducts => {
    setIsDisabled(!blocks, collectionIdentifiers.some((item, index) =>
      item.id !== newProducts[index].id));
    setSortedProducts(newProducts);
  };

  const handleBlockEdit = id => router.push(`/administration/blocks/${id}/edit`);

  const onSubmit = async () => {
    const defaultBlocksOrder = sortedProducts.map(({ id }) => id);
    try {
      await updateBlockOrderAction(apiService, currentCollection, defaultBlocksOrder);
      addNotification({
        type: 'success',
        message: 'Default blocks sort order updated',
      });
    } catch (err) {
      addNotification({
        type: 'failure',
        message: `Error: ${err.message}`,
      });
    }
  };

  return (
    <ElementQuery queries={queries}>
      <div>
        <h2 className="mb-1 text-navy">{collectionName} Blocks</h2>
        <h4 className="my-1">Set default sort order for {collectionName} Blocks.</h4>
        <Notification {...notificationMessage} />
        <div className="my-4">
          <DropdownSelect
            id="collections"
            name="Collections"
            labelText="Collections"
            value={currentCollection}
            options={collectionIdentifiers}
            onChangeValue={handleChangeCollection}
          />
        </div>
        <DraggableCardList
          list={sortedProducts}
          updateList={handleChangeProducts}
          renderItem={({ type, id, displayName, image, color }) => (
            <Card
              key={id}
              displayName={displayName}
              imageUrl={image}
              color={color}
              isDraggable
              blockType={type}
            >
              { type !== 6
                ? (
                  <Button
                    isNormalCase
                    isFullWidth
                    filledColor="secondary"
                    onClick={() => handleBlockEdit(id)}
                  >
                    Edit
                  </Button>
                ) : null}
            </Card>
          )}
        />
        <Button
          className="my-8 ml-auto"
          filledColor="green"
          disabled={isDisabled}
          onClick={onSubmit}
        >SAVE
        </Button>
      </div>
    </ElementQuery>
  );
};

BlocksDefaultSort.getAPIDataKey = () => 'blocks';

BlocksDefaultSort.getData = apiService =>
  apiService.get('blocks/default/all').then(data => ({ blocks: data }));

BlocksDefaultSort.propTypes = {
  initialData: PropTypes.shape({
    blocks: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.number,
        id: PropTypes.number,
        displayName: PropTypes.string,
        image: PropTypes.string,
      }),
    ),
    collectionIdentifiers: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ]),
      }),
    ).isRequired,
  }),
  params: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

BlocksDefaultSort.defaultProps = {
  initialData: {
    blocks: [],
    collectionIdentifiers: [],
  },
};

export default withServerSideData(BlocksDefaultSort);
