import Feature from '../Feature';
import AnyCriteria from '../criteria/any';
import AdminUserCriteria from '../criteria/admin_user';
import ProUserCriteria from '../criteria/pro_user';
import PremiumUserCriteria from '../criteria/premium_user';
import DateTimeCriteria from '../criteria/dateTime';

export default class NewInvitationFeature extends Feature {
  constructor(user) {
    const teamsPromo = new DateTimeCriteria();
    const adminUser = new AdminUserCriteria(user);
    const proUser = new ProUserCriteria(user);
    const premiumUser = new PremiumUserCriteria(user);

    const any = new AnyCriteria(adminUser, proUser, premiumUser, teamsPromo);

    super(any);
  }
}
