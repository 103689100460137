import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../common/Button';

const DeleteAccountModal = ({ onConfirm, onCancel, confirmButtonText }) => (
  <>
    <h4>Are you sure you want to delete your account?</h4>
    <div className="my-4 text-left">
      <p className="text-base">Deleting your GlobeSmart account will delete all your records
        on the GlobeSmart platform, including your:
      </p>
      <ul className="pl-4 mb-4 text-base font-normal list-disc">
        <li className="mb-4">GlobeSmart Profile</li>
        <li className="mb-4">IBI Results</li>
        <li className="mb-4">Team and Group Affiliations (we recommend <a
          href="https://aperian.zendesk.com/hc/en-us/articles/360030007513-Managing-GlobeSmart-Profile-Teams"
          target="_blank"
          rel="noreferrer"
        // eslint-disable-next-line react/jsx-closing-tag-location
        > transferring ownership of your teams</a> and groups before deleting your account)
        </li>
        <li className="mb-4">GlobeSmart Profile Connections</li>
      </ul>
      <p className="text-base"><a href="https://aperian.zendesk.com/hc/en-us/articles/5930340358291" target="_blank" rel="noreferrer">Learn more about what happens when your account is deleted</a>.
        If you create a new account, you will be treated as a new user.
      </p>
      <p className="text-base">
        This cannot be undone. After deletion, you will be returned to the Aperian Login screen.
      </p>
    </div>
    <div className="flex justify-end gap-2">
      <Button variant="secondary" onClick={onCancel}>No, Cancel</Button>
      <Button onClick={onConfirm} isWarning>{confirmButtonText}</Button>
    </div>
  </>
);

DeleteAccountModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  confirmButtonText: PropTypes.string.isRequired,
};

export default DeleteAccountModal;
