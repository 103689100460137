import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../common/Button';

const AfterTrackSelectMessage = ({ nextStep, formData }) => {
  const determineMessage = () => {
    switch (formData.track) {
      case 1:
        return (
          <div className="flex flex-wrap justify-center">
            <h1 className="font-serif text-4xl tracking-wider md:!leading-[3.5rem] lg:!leading-[4rem] text-charcoal-900 md:text-5xl lg:text-6xl">
              Great! Let&apos;s start your organization&apos;s<br />
              <span className="italic text-rust-500">
                &nbsp;DEI learning journey.
              </span>
            </h1>
          </div>
        );
      case 2:
        return (
          <div className="flex flex-wrap justify-center">
            <h1 className="font-serif text-4xl tracking-wider md:!leading-[3.5rem] lg:!leading-[4rem] text-charcoal-900 md:text-5xl lg:text-6xl">
              Let&apos;s explore how you can dive deeper to create a
              <span className="italic text-rust-500">
                &nbsp;more inclusive workplace.
              </span>
            </h1>
          </div>
        );
      case 3:
        return (
          <div className="flex flex-wrap justify-center">
            <h1 className="font-serif text-4xl tracking-wider md:!leading-[3.5rem] lg:!leading-[4rem] text-charcoal-900 md:text-5xl lg:text-6xl">
              Excellent! All of our learning experiences are
              <span className="italic text-rust-500">
                &nbsp;rooted in culture.
              </span>
            </h1>
          </div>
        );
      case 4:
        return (
          <h1 className="font-serif text-4xl tracking-wider md:!leading-[3.5rem] lg:!leading-[4rem] text-charcoal-900 md:text-5xl lg:text-6xl">
            Let&apos;s ensure success for your expatriates.
          </h1>
        );
      default:
        return (
          <h1 className="font-serif text-4xl tracking-wider md:!leading-[3.5rem] lg:!leading-[4rem] text-charcoal-900 md:text-5xl lg:text-6xl">
            Let&apos;s start exploring all you can do in Aperian!
          </h1>
        );
    }
  };

  return (
    <div className="flex flex-col items-center justify-center px-4 mb-0 xs:mb-14 md:mb-0 md:px-8 lg:px-12 md:max-w-[95%] gap-4">
      {determineMessage()}
      <Button
        variant="primary"
        trailingButtonIcon="arrow-right"
        onClick={nextStep}
      >Continue
      </Button>
    </div>
  );
};

AfterTrackSelectMessage.propTypes = {
  nextStep: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    track: PropTypes.number,
  }).isRequired,
};

export default AfterTrackSelectMessage;
