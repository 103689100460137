async function createCohortAction(apiService, data) {
  return apiService.post('cohorts', data);
}

async function searchCohortsAction(apiService, name) {
  return apiService.get(`cohorts/search?term=${name}`);
}

async function updateCohortAction(apiService, code, data) {
  return apiService.put(`cohorts/${code}`, data);
}

async function cohortEnrollAction(apiService, code, registrationMethod) {
  return apiService.post(`cohorts/${code}/enroll`, registrationMethod);
}

async function deleteCohortAction(apiService, code) {
  return apiService.delete(`cohorts/${code}`);
}

async function memberRemoveAction(apiService, code, memberId) {
  return apiService.delete(`cohorts/${code}/members/${memberId}`);
}

async function cohortsDirectoryDownloadAction(apiService, code) {
  return apiService.get(`cohorts/${code}/members/csv`);
}

async function deactivateCohortAction(apiService, code) {
  return apiService.put(`cohorts/${code}/deactivate`, { deactivated: true });
}

async function searchOrgsAction(apiService, term) {
  return apiService.get(`cohorts/organizations?term=${term}`);
}

export {
  cohortEnrollAction,
  createCohortAction,
  searchCohortsAction,
  updateCohortAction,
  deleteCohortAction,
  memberRemoveAction,
  cohortsDirectoryDownloadAction,
  deactivateCohortAction,
  searchOrgsAction,
};
