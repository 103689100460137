import React, { useContext, useState } from 'react';
import ElementQuery from 'react-eq';
import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';

import { APIContext } from '../../../context/API';
import withServerSideData from '../../../HOC/withServerSideData';

import Notification from '../../Notifications/Notification';

import Subscriptions from './Subscriptions';
import AccessCodesYouOwn from './AccessCodesYouOwn';

import getDownloadFileName from '../../../lib/fileDownload';

import AccessCodesDownloadAction from '../../../actions/accessCodes/downloadAccessCodes';

const queries = { 'card-layout': 400 };

const AccountSettingsSummary = ({ initialData }) => {
  const { apiService } = useContext(APIContext);
  const { accountSummary, accessCodes } = initialData;
  const [accessCodeUsesData, setAccessCodeUsesData] = useState([]);
  const [currentCode, setCurrentCode] = useState(null);
  const [showResults, setShowResults] = useState(false);
  const [notification, setNotification] = useState(null);

  const { accessCodeUses } = accessCodeUsesData;

  const getAccessCodeUsesData = code =>
    apiService.get(`access-codes/${code}`).then(data => setAccessCodeUsesData(data));

  const getInfo = e => {
    const { dataset: { id: code } } = e.target;
    if (showResults && currentCode === code) {
      setCurrentCode('');
      setShowResults(false);
      return;
    }
    getAccessCodeUsesData(code).then(() => {
      setCurrentCode(code);
      setShowResults(true);
    });
  };

  const exportCSV = () =>
    new AccessCodesDownloadAction(apiService)
      .execute(currentCode)
      .then(async response => ({
        filename: getDownloadFileName(response),
        blob: await response.blob(),
      }))
      .then(({ blob, filename }) => saveAs(blob, filename))
      .catch(err => {
        setNotification({
          type: 'warning',
          message: `Could not download access code uses: ${err}`,
        });
      });

  return (
    <div className="my-4">
      <Notification {...notification} />
      <div className="grid gap-2 md:grid-cols-6">
        <div className="md:col-span-2">
          <h4>Your Plans</h4>
        </div>
        <div className="md:col-span-4">
          <ElementQuery queries={queries}>
            <div>
              <Subscriptions accountSummary={accountSummary} />
            </div>
          </ElementQuery>
        </div>
      </div>

      <hr className="my-8 border border-stone-400" />

      <div className="grid gap-2 md:grid-cols-6">
        <div className="md:col-span-2">
          <h4>Access Codes You Own </h4>
          <p className="text-sm">
            For information on managing Access Codes, please see our <a href="https://aperian.zendesk.com/hc/en-us/sections/360005091593-Using-Access-Codes">Help Center</a>.
          </p>
        </div>
        <div className="md:col-span-4">
          <ElementQuery queries={queries}>
            <div className="mb-1">
              {accessCodes
                && (
                  <AccessCodesYouOwn
                    accessCodes={accessCodes}
                    accessCodeUses={accessCodeUses}
                    showResults={showResults}
                    currentCode={currentCode}
                    getInfo={getInfo}
                    exportCSV={exportCSV}
                  />
                )}
            </div>
          </ElementQuery>
        </div>
      </div>
    </div>
  );
};

AccountSettingsSummary.getAPIDataKey = () => 'accountSummary';

AccountSettingsSummary.getData = apiService => {
  const { currentUser: { userid } } = apiService;
  return apiService.get(`account-settings/summary?user=${userid}&includeUnavailable=true`)
    .then(data => ({ accountSummary: data }));
};

AccountSettingsSummary.propTypes = {
  initialData: PropTypes.shape({
    accountSummary: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        expires: PropTypes.string,
      }),
    ),
    accessCodes: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        description: PropTypes.string,
        internalNotes: PropTypes.string,
        quantity: PropTypes.number,
        active: PropTypes.bool,
        seatsRemaining: PropTypes.number,
        stripePlanId: PropTypes.string,
        created: PropTypes.string,
        expirationDate: PropTypes.string,
        planName: PropTypes.string,
      }),
    ),
    assessmentsData: PropTypes.arrayOf(
      PropTypes.shape({
        orgId: PropTypes.string,
        orgName: PropTypes.string,
        assessments: PropTypes.arrayOf(
          PropTypes.shape({
            completedCount: PropTypes.number,
            assessmentType: PropTypes.string,
            assessmentDisplayName: PropTypes.string,
          }),
        ),
      }),
    ),
  }).isRequired,
};

export default withServerSideData(AccountSettingsSummary);
