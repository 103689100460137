import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ActionItem from './ActionItem';

const ActionNavigation = ({ items }) => {
  const [currentAction, setCurrentAction] = useState('');
  const toggleCurrentAction = e => {
    const { dataset: { action } } = e.target;
    if (currentAction !== action) {
      setCurrentAction(action);
    } else {
      setCurrentAction('');
    }
  };
  return (
    <div className="lg:sticky lg:z-100 lg:top-8 lg:min-w-16">
      <ul className="fixed bottom-0 left-0 flex w-full p-0 m-0 list-none bg-white lg:bg-transparent lg:bottom-auto lg:flex-col lg:left-auto lg:w-16 print:hidden z-[1000]">
        {items.map(item => (
          <ActionItem
            key={item.actionClass}
            actionClass={item.actionClass}
            currentAction={currentAction}
            toggleAction={toggleCurrentAction}
            iconClass={item.iconClass}
            onClick={item.onClick}
            to={item.to}
            label={item.label}
          />
        ))}
      </ul>
    </div>
  );
};

ActionNavigation.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({}),
  ).isRequired,
};

export default ActionNavigation;
