import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../common/Button';
import GreetingIcon from '../icons/GreetingIcon';

const Greeting = ({ nextStep, formData }) => (
  <>
    <GreetingIcon />
    <h1 className="mt-5 mb-6 font-serif text-4xl tracking-wider text-charcoal-900 text-bold md:text-6xl fade-in-slide-up-text md:mt-6 md:mb-10">Nice to meet you, <em className="text-rust-500">{formData.firstName} {formData.lastName}.</em></h1>
    <Button
      variant="primary"
      trailingButtonIcon="arrow-right"
      onClick={nextStep}
    >Continue
    </Button>
  </>
);

export default Greeting;

Greeting.propTypes = {
  formData: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }).isRequired,
  nextStep: PropTypes.func.isRequired,
};
