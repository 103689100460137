import React from 'react';
import PropTypes from 'prop-types';

const CultureDisclaimer = ({
  hasCulture,
  cultureDisclaimer,
  cultureUrl,
  cultureLinkText,
}) => {
  if (hasCulture) {
    return (
      <div className="flex justify-center px-4 text-center disclaimer md:px-0">
        <p className="font-sans font-light text-sm md:text-md text-charcoal-900">
          {cultureDisclaimer}&nbsp;
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${cultureUrl}`}
          >
            {cultureLinkText}
          </a>
          .
        </p>
      </div>
    );
  }
  return null;
};

CultureDisclaimer.propTypes = {
  hasCulture: PropTypes.bool,
  cultureDisclaimer: PropTypes.string,
  cultureUrl: PropTypes.string,
  cultureLinkText: PropTypes.string,
};

CultureDisclaimer.defaultProps = {
  hasCulture: false,
  cultureDisclaimer: '',
  cultureUrl: '',
  cultureLinkText: '',
};

export default CultureDisclaimer;
