import React from 'react';

import PropTypes from 'prop-types';

const TrianglesIcon = ({ className1, className2 }) => (
  <svg className="max-w-full max-h-full" width="118" height="120" viewBox="0 0 118 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className={className2} d="M55.4675 120H1.11945C0.495334 120 0 119.495 0 118.881V64.5326C0 63.532 1.20861 63.0268 1.9219 63.7302L56.2699 118.078C56.9733 118.782 56.478 120 55.4774 120H55.4675Z" />
    <path d="M55.4675 56.5973H1.11945C0.495334 56.5973 0 56.092 0 55.4778V1.12977C0 0.129198 1.20861 -0.376042 1.9219 0.327332L56.2699 54.6754C56.9733 55.3787 56.478 56.5973 55.4774 56.5973H55.4675Z" className={className1} />
    <path d="M115.897 56.5973H61.5491C60.925 56.5973 60.4297 56.092 60.4297 55.4778V1.12977C60.4297 0.129198 61.6383 -0.376042 62.3516 0.327332L116.7 54.6754C117.403 55.3787 116.908 56.5973 115.907 56.5973H115.897Z" className={className2} />
    <path d="M115.897 120H61.5491C60.925 120 60.4297 119.495 60.4297 118.881V64.5326C60.4297 63.532 61.6383 63.0268 62.3516 63.7302L116.7 118.078C117.403 118.782 116.908 120 115.907 120H115.897Z" className={className1} />
  </svg>
);

TrianglesIcon.propTypes = {
  className1: PropTypes.string,
  className2: PropTypes.string,
};

TrianglesIcon.defaultProps = {
  className1: '',
  className2: '',
};

export default TrianglesIcon;
