import { BadRequestError } from '../../../../lib/errors';

class GroupAddMember {
  constructor(apiService) {
    this.apiService = apiService;
  }

  execute(groupId, assessment, data) {
    return this.apiService.post(`assessments/${assessment}/group/${groupId}/members`, data)
      .catch(err => {
        if (err instanceof BadRequestError) { return err.reason; }
        throw err;
      });
  }
}

export default GroupAddMember;
