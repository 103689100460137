import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const RedirectToDashboard = ({ onSubmit }) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      onSubmit();
    }, 4000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <h1>You&apos;re all set. Let&apos;s go to your dashboard!</h1>
  );
};

RedirectToDashboard.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default RedirectToDashboard;
