import tracker from '../../index';

const {
  eventNames,
  eventProps,
} = tracker;

const trackEmailOrgReport = (isSuccess, assessmentName, asmtType, orgName) => {
  tracker.track(eventNames.EMAIL_ORGANIZATION_REPORT, {
    [eventProps.ASSESSMENT]: `${assessmentName} (${asmtType.toUpperCase()})`,
    [eventProps.REPORT_FOR_ORG]: orgName,
    [eventProps.SUCCESS]: isSuccess,
  });
};

export { trackEmailOrgReport };
