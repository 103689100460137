import React from 'react';
import PropTypes from 'prop-types';

import Card from '../../../../Card';
import Button from '../../../../common/Button';

const GroupOwnerCard = ({
  avatarUrl,
  displayName,
  displayDescription,
  assessment,
  groupId,
  emailAssessmentGroupReport,
  disableGroupReport,
  groupOrTeam,
}) => (
  <Card
    displayName={displayName}
    displayDescription={displayDescription}
    avatarUrl={avatarUrl}
    displayNameLink={`/assessments/${assessment}/group/${groupId}`}
  >
    <Button
      onClick={() => emailAssessmentGroupReport(groupId, displayName)}
      disabled={disableGroupReport}
      isNormalCase
      variant="primary"
      isFullWidth
    >
      {`${groupOrTeam} Report`}
    </Button>
    <Button
      className="mt-1"
      to={`/assessments/${assessment}/group/${groupId}`}
      isNormalCase
      variant="secondary"
      isFullWidth
    >
      Manage
    </Button>
  </Card>
);

GroupOwnerCard.propTypes = {
  avatarUrl: PropTypes.string,
  displayName: PropTypes.string.isRequired,
  displayDescription: PropTypes.shape({}),
  assessment: PropTypes.string.isRequired,
  groupId: PropTypes.number.isRequired,
  emailAssessmentGroupReport: PropTypes.func.isRequired,
  disableGroupReport: PropTypes.bool,
  groupOrTeam: PropTypes.string.isRequired,
};

GroupOwnerCard.defaultProps = {
  avatarUrl: '',
  displayDescription: null,
  disableGroupReport: false,
};

export default GroupOwnerCard;
