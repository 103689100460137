import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { withCookies } from 'react-cookie';

import html2canvas from 'html2canvas';
import downloadjs from 'downloadjs';

import { APIContext } from '../../../../context/API';

import Spinner from '../../../Spinner';
import { trackComparisonChartScreenshotDownload } from '../../../../lib/tracker/profile';
import { COOKIE_LANGUAGE } from '../../../../lib/constants';
import imageToDataUrl from '../../../../lib/imageToDataUrl';
import Button from '../../Button';
import Tooltip from '../../Tooltip';

const createScreenshot = async ({
  apiService,
  chartLanguage,
  userName,
  setIsDownloadingScreenshot,
  downloadLocation,
}) => {
  await trackComparisonChartScreenshotDownload(
    apiService,
    chartLanguage,
    downloadLocation,
  );

  const targetElem = document.getElementById('comparisonChart');

  const options = {
    allowTaint: true,
    removeContainer: true,
    backgroundColor: '#ffffff',
    logging: false,
    scale: 2,
    useCORS: true,
    onclone: async cloneDoc => {
      const customStyles = '.line-clamp-3 { display: block; overflow: unset; margin: 0 0 5px 0; } .traitline__point { z-index: 300; }';
      const style = cloneDoc.createElement('style');
      style.textContent = customStyles;
      cloneDoc.body.appendChild(style);
      const polyLines = cloneDoc.getElementsByTagName('polyline');
      // eslint-disable-next-line no-plusplus
      for (let i = polyLines.length - 1; i >= 0; i--) {
        const line = polyLines[i];
        // Get the points on the line
        const linePoints = line.getAttribute('points');
        const updatedPoints = linePoints
          // Split the points attribute into an array of points
          .split(', ')
          // Modify the y-coordinate of each point to move the polyline up by 10 pixels
          .map(point => {
            const [x, y] = point.split(' ').map(parseFloat);
            return `${x} ${y - 8},`;
          })
          // Join the updated points back into a string and slice off the final comma
          .join(' ')
          .slice(0, -1);

        line.setAttribute('points', updatedPoints);
      }

      const imageElements = cloneDoc.getElementsByTagName('img');
      const images = Array.from(imageElements);
      const EXCLUDE_HOST = 'fast.wistia.com';
      await Promise.all(images.map(image =>
        !image.src.includes(EXCLUDE_HOST) && imageToDataUrl(image)));
    },
  };

  const canvas = await html2canvas(targetElem, options);
  const chartImage = canvas.toDataURL('image/png');
  downloadjs(chartImage, `${userName}ComparisonChart.png`, 'image/png');
  setIsDownloadingScreenshot(false);
};

const DownloadScreenshot = ({
  compareProfile,
  userName,
  cookies,
  downloadLocation,
}) => {
  const { apiService } = useContext(APIContext);
  const [isDownloadingScreenshot, setIsDownloadingScreenshot] = useState(false);

  if (!compareProfile) {
    return null;
  }

  if (isDownloadingScreenshot) {
    return <Spinner margin="0.5rem" isSmall />;
  }

  const chartLanguage = cookies.get(COOKIE_LANGUAGE) || 'en';

  return (
    <Tooltip
      key="screenshot"
      onHover
      position="bottom"
      content="Take a Screenshot"
    >
      <Button
        className="p-4"
        filledColor="secondary"
        onClick={() => {
          setIsDownloadingScreenshot(true);
          createScreenshot({
            apiService,
            chartLanguage,
            userName,
            setIsDownloadingScreenshot,
            downloadLocation,
          });
        }}
        leadingButtonIcon="download"
        iconColor="white"
        isCircle
      />
    </Tooltip>
  );
};

DownloadScreenshot.propTypes = {
  compareProfile: PropTypes.bool,
  userName: PropTypes.string,
  cookies: PropTypes.shape({
    get: PropTypes.func,
  }).isRequired,
  downloadLocation: PropTypes.string,
};

DownloadScreenshot.defaultProps = {
  compareProfile: false,
  userName: null,
  downloadLocation: '',
};

export default withCookies(DownloadScreenshot);
