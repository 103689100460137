import React, { useContext, useEffect, useState, useReducer } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, pick } from 'lodash';

import { APIContext } from '../../../../context/API';
import { RouterContext } from '../../../../context/Router';

import Button from '../../../common/Button';
import LabelInput from '../../../common/LabelInput';
import Tooltip from '../../../common/Tooltip';

import AfterSurveyMessageIcon from '../icons/AfterSurveyMessageIcon';

import { submitMiniSurveyAction, findTrialUserStatusAction, createTrialOwnerAction } from '../../../../actions/onboarding';

import { getDimensionZone, validateCreatorEmail } from '../../../../lib/onboarding';
import logger from '../../../../lib/logger';

import { trackOnboardingStepCompletionIdentify, trackOnboardingStepCompletionEmail } from '../../../../lib/tracker/onboarding';

const InvalidEmailMessageTooltip = ({ isEdu }) => {
  let invalidMessage = (
    <>
      This trial is for organizations interested in licensing Aperian. Please sign up using your work email address or <a href="https://aperian.zendesk.com/hc/en-us/articles/18925799320851-Individual-Licensing-" target="_blank" rel="noopener noreferrer" className="underline text-rust-500">purchase access</a>.
    </>
  );

  if (isEdu) {
    invalidMessage = (
      <>
        This trial is for organizations interested in licensing Aperian. If you are a professor or student looking for individual access, please <a href="https://aperian.zendesk.com/hc/en-us/articles/18926425773971-Educational-Licensing-" target="_blank" rel="noopener noreferrer" className="underline text-rust-500">click here to purchase</a> or <a href="https://aperian.com/contact" target="_blank" rel="noopener noreferrer" className="underline text-rust-500">get in touch</a>.
      </>
    );
  }

  return invalidMessage;
};

InvalidEmailMessageTooltip.propTypes = {
  isEdu: PropTypes.bool.isRequired,
};

const AfterSurveyMessage = ({
  formData,
  nextStep,
  handleUpdateForm,
  setPathToJoinExistingTrial,
  setPathToReturningTrialCreator,
  returnCreatorToSurvey,
}) => {
  const { apiService } = useContext(APIContext);
  const { router } = useContext(RouterContext);

  const [email, setEmail] = useState(formData.email);
  const [emailError, setEmailError] = useReducer((data, newData) =>
    ({ ...data, ...newData }), {
    email: null,
    isInvalid: null,
    isEdu: null,
    redirectToLogin: false,
  });

  let timeout;

  const onChangeValue = e => {
    setEmailError({
      email: null,
      isInvalid: null,
      isEdu: null,
      redirectToLogin: false,
    });
    setEmail(e.target.value);
  };

  useEffect(() => () => {
    if (timeout) clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (email === null) return;

    const { isEdu, isInvalid } = validateCreatorEmail(email);

    setEmailError({ isEdu, isInvalid });
  }, [email]);

  const handleReturningUser = ({
    track, firstName, lastName, userId, dimensionScore,
  }) => {
    handleUpdateForm({
      email,
      track,
      firstName,
      lastName,
      userId,
      dimension: getDimensionZone(dimensionScore),
      dimensionPercent: dimensionScore * 10,
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (emailError.redirectToLogin) {
      router.push('/login');
      return;
    }
    if (!email?.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/i)) {
      setEmailError({
        email: email
          ? 'Enter a valid email address.'
          : 'You must enter your email address.',
      });
      return;
    }
    if (Object.values(emailError).some(value => value)) {
      return;
    }

    handleUpdateForm({ email });

    try {
      const {
        trialUserData,
        isExistingTrial,
        isTrialOwner,
        isTrialExpired,
        redirectToLogin,
        questions,
      } = await findTrialUserStatusAction(apiService, { email });
      if (!isExistingTrial) {
        const userData = {
          ...pick(formData, ['firstName', 'lastName', 'track']),
          email,
        };
        const { userId } = await createTrialOwnerAction(apiService, userData);

        handleUpdateForm({ userId });

        await trackOnboardingStepCompletionEmail(email);

        await trackOnboardingStepCompletionIdentify({
          ...userData,
          userId,
        });

        const surveyQuestions = formData.responses.map(({ id, response }) => (
          { question_id: id, response }
        ));
        const data = { userId, questions: surveyQuestions };

        try {
          const { dimensionScore } = await submitMiniSurveyAction(apiService, data);
          handleUpdateForm({
            dimension: getDimensionZone(dimensionScore),
            dimensionPercent: dimensionScore * 10,
          });
        } catch (err) {
          logger.error(err);
        }

        nextStep();
      } else if (questions) {
        handleUpdateForm(trialUserData);
        returnCreatorToSurvey(questions);
      } else if (isTrialExpired) {
        router.push('/try/expired');
      } else if (redirectToLogin) {
        setEmailError({
          email: 'You already have an existing trial. Redirecting to Login Page',
          redirectToLogin: true,
        });
      } else if (!isTrialOwner) {
        // The user is a member of an org existing, active trial
        // The trialUserData should have the org's id for use
        // when registering the user
        if (!isEmpty(trialUserData)) handleUpdateForm(trialUserData);
        setPathToJoinExistingTrial();
        nextStep();
      } else {
        // The user is a owner of an existing, active trial
        if (!isEmpty(trialUserData)) handleReturningUser(trialUserData);
        setPathToReturningTrialCreator();
        nextStep();
      }
    } catch (err) {
      const { details: { status, userId, isConverted, orgId } } = err;
      if (status === 409) {
        if (userId) {
          setEmailError({
            email: 'User already exists. Redirecting to Login Page.',
            redirectToLogin: true,
          });
        }
        if (isConverted) {
          setEmailError({ email: 'You\'ve got full access to Aperian! Log in now.' });
          timeout = setTimeout(() => router.push('/login'), 6000);
          return;
        }
        if (orgId) {
          setEmailError({
            email: 'Your organization already has an Aperian account. Log into Aperian with your organization email address.',
            redirectToLogin: true,
          });
        }
      }
      logger.error(err);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center px-2 pt-4 md:pt-0 md:px-4">
      <AfterSurveyMessageIcon />
      <h1 className="my-4 font-serif text-4xl font-normal tracking-wider md:mb-6 md:text-5xl">Great! Let&apos;s see <span className="italic text-rust-500">your style.</span></h1>
      <p className="m-0 font-sans text-base leading-6 text-gray-800 md:text-xl md:leading-8 ">Communicating effectively is the first step to working more inclusively.</p>
      <p className="m-0 mt-5 font-sans text-base leading-6 text-gray-800 md:text-xl md:leading-8 md:mb-10">Enter your email to see your results.</p>
      <Tooltip
        content={(emailError?.isInvalid || emailError?.isEdu)
          && <InvalidEmailMessageTooltip isEdu={emailError?.isEdu} />}
      />
      <div className="w-5/6 max-w-md mx-auto mb-6 md:w-full md:mb-12">
        <LabelInput
          id="email"
          name="email"
          type="email"
          placeholderText="Email"
          value={email}
          onChangeValue={onChangeValue}
          leadingIcon="mail"
          errorMessage={emailError?.email}
          iconButtonDisabled={(emailError?.isInvalid || emailError?.isEdu)}
        />
      </div>
      <div className="flex justify-center">
        <Button
          variant="primary"
          trailingButtonIcon="arrow-right"
          onClick={handleSubmit}
        >
          {emailError.redirectToLogin ? 'Login' : 'Continue'}
        </Button>
      </div>
    </div>
  );
};

AfterSurveyMessage.propTypes = {
  formData: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    userId: PropTypes.string,
    responses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        text: PropTypes.string,
      }),
    ),
  }).isRequired,
  nextStep: PropTypes.func.isRequired,
  handleUpdateForm: PropTypes.func.isRequired,
  setPathToJoinExistingTrial: PropTypes.func.isRequired,
  setPathToReturningTrialCreator: PropTypes.func.isRequired,
  returnCreatorToSurvey: PropTypes.func.isRequired,
};

export default AfterSurveyMessage;
