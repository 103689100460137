import React from 'react';
import { Link } from 'react-router';

import Icon from '../../common/Icon';
import { propTypes, defaultProps } from './types';

const sharedBtnClasses = 'circle-btn';
const sharedTextClasses = 'circle-btn__text text-responsive';

const HiddenProfilesButton = props => (
  <button type="button" className={`${sharedBtnClasses} circle-btn--hidden print:hidden`} onClick={props.onClick}>
    <span className={`${sharedTextClasses}`}>{props.numHidden} Hidden</span>
  </button>
);

const NewComparisonButton = props => (
  <Link to={props.link}>
    <Icon icon="new-compare" className="w-16 h-16 print:hidden" iconColor="gray" />
  </Link>
);

HiddenProfilesButton.propTypes = propTypes;
NewComparisonButton.propTypes = propTypes;
HiddenProfilesButton.defaultProps = defaultProps;
NewComparisonButton.defaultProps = defaultProps;

export { HiddenProfilesButton, NewComparisonButton };
