import React from 'react';
import { Link } from 'react-router';

import { propTypes, defaultProps } from '../../cardPropTypes';
import Button from '../../../../common/Button';
import Avatar from '../../../../common/Avatars/Avatar';
import { buildCompareUrl } from '../../buildCompareUrl';

function renderDescription(description) {
  if (!description) return null;

  return (
    <div className="card__description">
      {description}
    </div>
  );
}

const OwnedTeamCard = props => {
  const {
    id,
    displayName,
    displayDescription,
  } = props;

  return (
    <div className="card">
      <div className="card__container">
        <Avatar avatarUrl={props.avatarUrl} avatarImgAlt={props.displayName} />
        <div className="card__content">
          <div className="card__heading">
            <Link to={`/profile/teams/${id}`}>
              {displayName}
            </Link>
          </div>
          {renderDescription(displayDescription)}
        </div>
        <div className="card__actions">
          <Button to={buildCompareUrl(id)} variant="primary" isNormalCase className="card__button">
            Compare
          </Button>
          <Button variant="secondary" isNormalCase to={`/profile/teams/${id}`} className="card__button">
            Manage
          </Button>
        </div>
      </div>
    </div>
  );
};

OwnedTeamCard.propTypes = propTypes;
OwnedTeamCard.defaultProps = defaultProps;

export default OwnedTeamCard;
