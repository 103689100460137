import React from 'react';

const RustDividingLine = () => (
  <div>
    <svg width="39" height="2" viewBox="0 0 39 2" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="0.25" y1="1.24299" x2="38.9945" y2="1.24299" stroke="#FF4F38" strokeWidth="0.968612" />
    </svg>
  </div>
);

export default RustDividingLine;
