import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../common/Button';

const TrialExistsMessage = ({ formData, nextStep }) => (
  <>
    <h1 className="max-w-2xl mb-5 font-serif text-4xl tracking-wider text-charcoal-900 md:text-6xl md:mb-10 fade-in-slide-up-text">
      {formData.orgName} already has an active Aperian trial!
    </h1>
    <p className="mb-5 font-sans text-base leading-6 text-gray-800 md:mb-10 md:text-xl md:leading-8">
      Let&apos;s get you added to the trial.
    </p>
    <Button
      variant="primary"
      trailingButtonIcon="arrow-right"
      onClick={nextStep}
    >Continue
    </Button>
  </>
);

export default TrialExistsMessage;

TrialExistsMessage.propTypes = {
  formData: PropTypes.shape({
    orgName: PropTypes.string,
  }).isRequired,
  nextStep: PropTypes.func.isRequired,
};
