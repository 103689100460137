import React, { useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { RouterContext } from '../../../../context/Router';
import { ModalContext } from '../../../../context/Modal';
import { NotificationContext } from '../../../../context/Notification';
import { APIContext } from '../../../../context/API';

import Button from '../../../common/Button';
import OrgConfirmUsersList from './OrgConfirmUsersList';
import OrgUsersDetails from './OrgUsersDetails';
import ConfirmModal from '../../../common/Modal/ConfirmModal';
import GlobeSmartModal from '../../../GlobeSmartModal';
import deleteBulkUsersAction from '../../../../actions/user/bulkDelete';
import Notification from '../../../Notifications/Notification';

const OrganizationConfirmDelete = ({ location: { state: initialData }, params: { id: orgId } }) => {
  const { apiService } = useContext(APIContext);
  const {
    addNotification,
    notificationMessage,
  } = useContext(NotificationContext);
  const { router } = useContext(RouterContext);
  const { handleOpenModal, handleCloseModal } = useContext(ModalContext);

  const [state, setState] = useReducer((data, newData) =>
    ({ ...data, ...newData }), { ...initialData });

  useEffect(() => {
    if (!initialData || !state.membersFound.length) {
      router.replace(`/administration/organizations/${orgId}/delete-users`, {});
    }
  }, [initialData, state.membersFound]);

  const onRemove = memberId => {
    const updatedMembers = state.membersFound.filter(member => member.id !== memberId);
    setState({ membersFound: updatedMembers });
  };

  const onCancel = () => {
    router.replace(
      `/administration/organizations/${orgId}/delete-users`,
      {});
  };

  const deleteUsers = () => {
    const { membersFound } = state;
    deleteBulkUsersAction(apiService, membersFound)
      .then(() => {
        handleCloseModal();
        addNotification({ type: 'success', message: 'The accounts have been deleted.' });
        router.replace(`/administration/organizations/${orgId}/delete-users`, {});
      })
      .catch(err => {
        handleCloseModal();
        addNotification({
          type: 'failure',
          message: `Error: Something went wrong while deleting these accounts. ${JSON.stringify(err, null, 2)}`,
        });
      });
  };

  const handleOpenConfirmModal = () => {
    handleOpenModal({
      content: (
        <ConfirmModal
          confirmButtonText={`Yes, Delete ${state.membersFound.length} Accounts`}
          onCancel={handleCloseModal}
          onConfirm={deleteUsers}
          title="Confirm Deletion"
          confirmText="DELETE"
        >
          <p className="text-base">You are about to delete {state.membersFound.length} user accounts and all related data. Confirm deletion by typing <b>DELETE</b> in the box.</p>
        </ConfirmModal>),
    });
  };

  return state.membersFound?.length
    ? (
      <div>
        <h3 className="antialiased">Users to be Deleted</h3>
        <Notification {...notificationMessage} />
        <OrgConfirmUsersList
          membersFound={state.membersFound}
          onRemove={onRemove}
        />
        <OrgUsersDetails
          membersFound={state.membersFound}
          membersNotFound={state.membersNotFound}
          usersNotInOrg={state.usersNotInOrg}
        />
        <div className="flex justify-end gap-2 mt-4">
          <Button onClick={onCancel}>Cancel Deletion
          </Button>
          <Button
            onClick={handleOpenConfirmModal}
            outlinedColor="red"
          >Delete {state.membersFound.length} users
          </Button>
        </div>
        <GlobeSmartModal />
      </div>
    ) : null;
};

OrganizationConfirmDelete.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      membersFound: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          userId: PropTypes.string,
          email: PropTypes.string,
        }),
      ),
      membersNotFound: PropTypes.arrayOf(PropTypes.string),
      usersNotInOrg: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
};

OrganizationConfirmDelete.defaultProps = {
  location: { state: undefined },
};

export default OrganizationConfirmDelete;
