import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../../common/Button';
import Card from '../../../../Card';

const AsmtTeamMemberCard = ({
  asmtType,
  teamId,
  avatarUrl,
  displayName,
  displayDescription,
  surveyStatus,
}) => (
  <Card
    displayName={displayName}
    displayDescription={displayDescription}
    avatarUrl={avatarUrl}
    asmtType={asmtType}
    color="blue"
  >
    {surveyStatus === 'started' && (
    <Button
      to={`assessments/${asmtType}/survey/${teamId}`}
      className="card__button"
      isNormalCase
      filledColor="primary"
    >
      Resume Survey
    </Button>
    )}
    {surveyStatus === 'Not Started' && (
    <Button
      className="card__button"
      to={`assessments/${asmtType}/survey/${teamId}`}
      isNormalCase
      filledColor="primary"
    >
      Take Survey
    </Button>
    )}
  </Card>
);

AsmtTeamMemberCard.propTypes = {
  teamId: PropTypes.number.isRequired,
  avatarUrl: PropTypes.string,
  displayName: PropTypes.string.isRequired,
  displayDescription: PropTypes.node,
  asmtType: PropTypes.string.isRequired,
  surveyStatus: PropTypes.string.isRequired,
};

AsmtTeamMemberCard.defaultProps = {
  avatarUrl: '',
  displayDescription: null,
};

export default AsmtTeamMemberCard;
