import React, { useContext } from 'react';
import { Link } from 'react-router';

import { Context } from '../../../context/ContextProvider';

import PageHeader from '../../PageHeader';
import Button from '../../common/Button';

import needsAuthentication from '../../../lib/needsAuthentication';

const TeamInvitationSuccess = () => {
  const { currentUser, router } = useContext(Context);
  needsAuthentication(router, currentUser);

  return (
    <>
      <PageHeader
        pageTitle="Team Successfully Created"
        icon="profile"
        skipTarget="#team-success-message"
      />
      <div id="team-success-message" className="my-2 narrow-text-max-width">
        <div className="p-1 bg-gray-5 round-corners">
          <p>
            Your team has been created and invitations have been sent to your
            team members.
          </p>
          <p>
            Team members may leave the team at any time, and you may{' '}
            <Link to="/profile/teams">edit a team</Link> at any time.
          </p>
          <div className="mt-2 text-center">
            <Button
              to="/profile/teams/new"
              className="mb-1 mx-0.5"
              filledColor="primary"
            >
              Create Another Team
            </Button>
            <Button to="/profile/teams" className="mb-1 mx-0.5">
              View My Teams
            </Button>
          </div>
        </div>
        <div className="mt-2 text-center">
          <Button to="/dashboard" isCancel>
            Go to my dashboard
          </Button>
        </div>
      </div>
    </>
  );
};

export default TeamInvitationSuccess;
