import React from 'react';
import PropTypes from 'prop-types';

import SubscriptionCard from './SubscriptionCard';

const Subscriptions = ({ accountSummary }) => {
  if (!accountSummary) return null;

  return accountSummary.map(subscription => (
    <SubscriptionCard
      key={`${subscription.name}-${subscription.expires}`}
      displayName={subscription.name}
      displayDescription={`Expires on ${subscription.expires}`}
    />
  ));
};

Subscriptions.propTypes = {
  accountSummary: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      expires: PropTypes.string,
    }),
  ),
};

Subscriptions.defaultProps = {
  accountSummary: [],
};

export default Subscriptions;
