import React, { useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';

import DatePicker from 'react-datepicker';
import DOMPurify from 'isomorphic-dompurify';
import Icon from '../Icon';

const DatePickerInput = forwardRef(
  // eslint-disable-next-line prefer-arrow-callback
  function DatePickerInput(props, ref) {
    useEffect(() => {
      if (props.errorMessage) {
        ref.current.input.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        ref.current.setFocus();
      }
    }, [props.errorMessage]);

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);

    return (
      <div className="flex-grow">
        <label htmlFor={`${props.id}`} className={`font-sans block text-sm text-left font-medium leading-6 text-gray-700 ${props.labelText ? 'visible mb-2' : 'invisible'}`}>
          {props.labelText}
        </label>
        <div className="datepicker">
          <DatePicker
            ref={ref}
            id={props.id}
            wrapperClassName="w-full rounded-lg shadow-sm"
            className="font-sans font-normal box-border block w-full rounded-md border-0 py-1.5 ring-1 ring-inset ring-gray-50 placeholder:text-gray-500 focus:ring-1 focus:ring-inset focus:ring-rust-500  focus:shadow-input sm:text-sm sm:leading-6"
            dateFormat={props.dateFormat}
            selected={props.value ? new Date(DOMPurify.sanitize(props.value)) : ''}
            onSelect={date => props.handleDateChange(date, props.dateProperties)}
            onBlur={props.onCalendarBlur || props.onBlur}
            onKeyDown={props.onKeyDown}
            minDate={props.allowPreviousDates ? null : currentDate}
            popperPlacement="bottom"
          />
          <i
            role="button"
            aria-label="Calendar"
            className="absolute flex items-center justify-center w-6 h-6 cursor-pointer top-[0.375rem] right-2"
            onClick={props.handleIconClick}
            onKeyDown={props.handleIconClick}
            tabIndex={-1}
          >
            <Icon icon="calendar" iconColor="dark-gray" className="top-[0.375rem]" />
          </i>
        </div>
        {props.helperText && !props.errorMessage ? <p className="mt-2 font-sans text-sm text-left text-gray-500">{props.helperText}</p> : null}
        {props.errorMessage ? <p className="h-4 mt-2 font-sans text-sm text-left text-red-500">{props.errorMessage}</p> : null}
      </div>
    );
  });

DatePickerInput.propTypes = {
  id: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
  onKeyDown: PropTypes.func,
  handleDateChange: PropTypes.func,
  onBlur: PropTypes.func,
  onCalendarBlur: PropTypes.func,
  dateFormat: PropTypes.string,
  helperText: PropTypes.string,
  errorMessage: PropTypes.string,
  allowPreviousDates: PropTypes.bool,
  handleIconClick: PropTypes.func,
  dateProperties: PropTypes.arrayOf(
    PropTypes.string,
  ),
};

DatePickerInput.defaultProps = {
  labelText: '',
  value: '',
  handleDateChange: () => { },
  onBlur: () => { },
  onCalendarBlur: () => { },
  onKeyDown: () => { },
  handleIconClick: () => { },
  dateFormat: '',
  helperText: '',
  errorMessage: '',
  allowPreviousDates: false,
  dateProperties: [],
};

export default DatePickerInput;
