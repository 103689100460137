const safeFocusClass = 'safe-focus';
const htmlEl = document.documentElement;
const cutsTheMustard = () => !!htmlEl.classList;

/**
*  Remove class to key off of for showing focus outlines
*  @return {undefined}
*/
const deactivateSafeFocus = () => {
  htmlEl.classList.remove(safeFocusClass);
};

/**
*  Add class to key off of for showing focus outlines
*  Only the `tab` key activates the safe focus visual, any other key turns it off
*  @return {undefined}
*/
const activateSafeFocus = e => {
  if (e.key === 'Tab') {
    htmlEl.classList.add(safeFocusClass);
  } else {
    deactivateSafeFocus();
  }
};

/**
*  Bind events for adding & removing class to key off of for showing focus outlines
*  @return {undefined}
*/
const safeFocus = () => {
  if (cutsTheMustard()) {
    deactivateSafeFocus();
    document.addEventListener('mousedown', deactivateSafeFocus);
    document.addEventListener('keydown', activateSafeFocus);
  }
};

safeFocus();

export {
  safeFocus,
  activateSafeFocus,
  deactivateSafeFocus,
};
