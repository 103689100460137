import jwt from 'jsonwebtoken';
import logger from '../../logger';

export default function verifyJWT({ token, onError, isAuth0 = false }) {
  const key = isAuth0 ? process.env.AUTH0_PUBLIC_KEY : process.env.JWT_PUBLIC_KEY;
  try {
    return jwt.verify(token, key);
  } catch (err) {
    if (err.name !== 'TokenExpiredError') {
      logger.error(err);
    }
    if (typeof onError === 'function') onError();
    return false;
  }
}
