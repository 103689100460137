import React from 'react';
import PropTypes from 'prop-types';

import Price from '../Price';
import QuantityInput from '../QuantityInput';
import CouponInput from '../CouponInput';

const PaymentPurchaseDetails = ({
  planInfo,
  total,
  subtotal,
  quantity,
  tax,
  forOthers,
  coupon,
  applyCoupon,
  handleForOthersChange,
  handleQuantityChange,
  currentStep,
}) => {
  if (currentStep === 4) return null;
  return (
    <>
      <Price
        currentStep={currentStep}
        planInfo={planInfo.plan}
        quantity={quantity}
        subtotal={subtotal}
        tax={tax}
        total={total}
      />
      {currentStep === 1 ? (
        <>
          <QuantityInput
            quantity={quantity}
            forOthers={forOthers}
            handleForOthersChange={handleForOthersChange}
            handleQuantityChange={handleQuantityChange}
          />
          <CouponInput coupon={coupon} applyCoupon={applyCoupon} />
        </>
      ) : null}
    </>
  );
};

PaymentPurchaseDetails.propTypes = {
  currentStep: PropTypes.number.isRequired,
  planInfo: PropTypes.shape({
    plan: PropTypes.shape({
      id: PropTypes.string.isRequired,
      duration: PropTypes.string.isRequired,
    }),
  }).isRequired,
  quantity: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  coupon: PropTypes.shape({
    id: PropTypes.string,
  }),
  subtotal: PropTypes.number.isRequired,
  tax: PropTypes.number,
  total: PropTypes.number.isRequired,
  forOthers: PropTypes.bool.isRequired,
  applyCoupon: PropTypes.func.isRequired,
  handleForOthersChange: PropTypes.func.isRequired,
  handleQuantityChange: PropTypes.func.isRequired,
};

PaymentPurchaseDetails.defaultProps = {
  tax: 0,
  coupon: { id: null },
};

export default PaymentPurchaseDetails;
