import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '../common/Button';
import { APIContext } from '../../context/API';
import { CurrentUserContext } from '../../context/CurrentUser';
import { trackBlockButtonClick } from '../../lib/tracker/blocks';
import { sendHubspotEventDataAction } from '../../actions/hubspotTracker';

const BlockButton = ({
  connection,
  buttonText,
  moduleId,
  directUrl,
  blockType,
  asmtId,
  internalName,
  title,
  className,
  launchedFrom,
}) => {
  const { apiService } = useContext(APIContext);
  const { currentUser } = useContext(CurrentUserContext);
  const { isTrial, userid } = currentUser;

  const launchButtonText = buttonText || 'Launch';
  const isPromo = blockType === 3;
  const getUrlSuffix = () => {
    const auth0ClientID = process.env.AUTH0_CLIENT_ID_GSLEARNING;
    return `${auth0ClientID}?RelayState=${encodeURIComponent(`${process.env.GSLEARNING_HOST}/course/view.php?id=${moduleId}`)}`;
  };

  const createLearningModuleLink = () => {
    const urlSuffix = getUrlSuffix();
    const url = `https://${process.env.AUTH0_DOMAIN}/samlp/${urlSuffix}`;
    const delimiter = urlSuffix.includes('?') ? '&' : '?';
    const params = (connection && connection !== 'auth0')
      ? `${delimiter}connection=${connection}`
      : '';
    return url + params;
  };

  const handleExternalButtonClick = () => {
    trackBlockButtonClick({ userId: userid, internalName, title, launchedFrom })
      .then(() => {
        if (isTrial) {
          const eventData = {
            eventName: 'HUBSPOT_BLOCK_BUTTON_CLICKED',
            properties: {
              block_title: internalName,
            },
          };
          sendHubspotEventDataAction(apiService, eventData);
        }
      })
      .then(() => {
        let buttonUrl;
        if (blockType === 1) {
          buttonUrl = createLearningModuleLink();
        } else if (blockType === 2) {
          buttonUrl = `/assessments/${asmtId}`;
        } else {
          // Promo and External Resource blocks use directUrl
          buttonUrl = directUrl;
        }
        window.open(buttonUrl);
      });
  };

  return isPromo ? (
    <Button
      className="w-full md:w-auto whitespace-nowrap"
      trailingButtonIcon="arrow-right"
      iconColor="black"
      filledColor="white"
      onClick={() => handleExternalButtonClick()}
    >
      {launchButtonText}
    </Button>
  ) : (
    <Button
      className={`${className} mr-auto`}
      trailingButtonIcon="arrow-right"
      variant="primary"
      onClick={() => handleExternalButtonClick()}
    >
      {launchButtonText}
    </Button>
  );
};

BlockButton.propTypes = {
  connection: PropTypes.string,
  buttonText: PropTypes.string,
  moduleId: PropTypes.number,
  directUrl: PropTypes.string,
  blockType: PropTypes.number,
  asmtId: PropTypes.string,
  internalName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  launchedFrom: PropTypes.string.isRequired,
};

BlockButton.defaultProps = {
  connection: 'auth0',
  buttonText: '',
  moduleId: 0,
  directUrl: '',
  blockType: 0,
  asmtId: '',
  className: '',
};

export default BlockButton;
