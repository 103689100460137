import React, { useContext, useEffect } from 'react';
import { findIndex } from 'lodash';
import PropTypes from 'prop-types';

import withServerSideData from '../../../HOC/withServerSideData';

import { CurrentUserContext } from '../../../context/CurrentUser';
import { APIContext } from '../../../context/API';
import { RouterContext } from '../../../context/Router';

import PageHeader from '../../PageHeader';
import SurveyState from '../SurveyState';
import Button from '../../common/Button';

import PremiumFeature from '../../../lib/features/Premium';
import getLoginUrl from '../../../lib/urls/loginUrl';
import { GS_PREMIUM_STRIPE_PLAN } from '../../../lib/constants';

const StartSurvey = ({ location, initialData: { questions } }) => {
  const { currentUser } = useContext(CurrentUserContext);
  const { apiService } = useContext(APIContext);
  const { router } = useContext(RouterContext);

  /**
  * Pick up at the first unanswered question
  * @return Number The ordinal value of the first unanswered question
  */
  const checkProgress = () => findIndex(questions, q => typeof q.response === 'undefined') + 1 || 1;

  // eslint-disable-next-line react/prop-types
  const questionOneResponse = questions[0].response;

  const redirectForQuestionNumber = () => {
    if (currentUser.isTrial && !questionOneResponse) {
      return;
    }
    if (questions && currentUser.surveyStatus === 'started') {
      router.push(`/profile/survey/${checkProgress()}`);
    }
  };

  useEffect(() => {
    new PremiumFeature(currentUser)
      .positive(() => redirectForQuestionNumber())
      .negative(() => {
        if (currentUser.isLoggedIn()) {
          router.replace(`/dashboard?purchase&plan=${GS_PREMIUM_STRIPE_PLAN}`);
        } else {
          const loginUrl = getLoginUrl(location);
          router.replace(loginUrl);
        }
        return null;
      })
      .execute();
  }, []);

  useEffect(() => {
    redirectForQuestionNumber();
  }, [questions]);

  const handleOnBegin = e => {
    e.preventDefault();
    return apiService
      .post('profile/survey/start')
      .then(response => {
        if (response && response.lastQuestion) {
          router.push(`/profile/survey/${response.lastQuestion}`);
        } else {
          router.push('/profile/survey/1');
        }
      });
  };

  return (
    <>
      <PageHeader
        pageTitle="GlobeSmart Survey"
        skipTarget="#survey-introduction"
      />
      <div className="h-full">
        <div id="survey-introduction" className="box-border p-5 mb-6 bg-white h-fit xs:p-10 xl:p-14 rounded-3xl">
          <h3>Before you begin…</h3>
          <hr className="w-24 h-0.5 my-6 md:my-8 border-0 bg-rust-500" />
          <h4>There are a few things you should know:</h4>
          <ul>
            <li>The survey takes 5-10 minutes to complete.</li>
            <li>Answer honestly and quickly. Your first response is usually the best. </li>
            <li>
              No one can see your Profile without your permission,
              and no one will ever see your survey responses.
            </li>
            <li>
              Some of the items in the survey reference a manager.
              If you are a student or in a role without a manager,
              consider substituting &quot;manager&quot; with &quot;instructor
              &quot; or someone with more authority than you in your workplace or institution.
            </li>
          </ul>
          <Button
            id="startSurvey"
            variant="primary"
            onClick={handleOnBegin}
            className="mx-auto mt-6"
          >
            Begin Survey
          </Button>
        </div>
      </div>
    </>
  );
};
StartSurvey.getAPIDataKey = () => SurveyState.getAPIDataKey();
StartSurvey.getData = apiService => SurveyState.getData(apiService);

StartSurvey.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
  }).isRequired,
  initialData: PropTypes.shape({
    questions: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      text: PropTypes.string,
      sortOrder: PropTypes.number,
      dimension: PropTypes.string,
      excludeFromProfile: PropTypes.bool,
      deleted: PropTypes.bool,
    })),
  }).isRequired,
};

export default withServerSideData(StartSurvey);
