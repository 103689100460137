import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { propTypes as selectedComparablesPropTypes } from '../../../propTypes/selectedComparables';
import Button from '../../common/Button';
import Tag from '../../common/Tag';
import Icon from '../../common/Icon';

const SelectedComparables = ({
  compareTargets,
  selectedComparables,
  filterValue,
  onRemove,
  onFilter,
  handleClear,
  handleCreateComparison,
}) => {
  const filterInputRef = useRef(null);

  if (!compareTargets || !selectedComparables) return null;

  return (
    <div className="flex flex-wrap items-center justify-end gap-3">
      <div
        className="flex-grow px-5 py-3 transition-all bg-white rounded-full cursor-text"
        tabIndex="-1"
        onClick={() => filterInputRef.current.focus()}
        onKeyDown={() => filterInputRef.current.focus()}
        role="searchbox"
      >
        <div className="max-h-[4.3rem] flex gap-3 justify-between items-center overflow-y-scroll custom-scroll">
          {selectedComparables.length
            ? null
            : <Icon icon="search" className="flex-shrink-0 w-6 h-6" iconColor="dark-gray" />}

          <ul className="flex flex-wrap items-center flex-grow gap-2 list-none">
            {selectedComparables.map(item => (
              <Tag
                key={item.id}
                text={item.name}
                onIconClick={() => onRemove({ type: item.type, id: item.id }, false)}
              />
            ))}
            <li>
              <input
                className="flex-grow p-0 font-normal border-0"
                ref={filterInputRef}
                onChange={e => onFilter(e.target.value)}
                value={filterValue}
                placeholder="Start typing"
                type="input"
              />
            </li>
          </ul>
          {selectedComparables.length || filterValue
            ? (
              <div className="flex items-end md:items-center">
                <Button
                  className="p-0 border-none h-fit"
                  onClick={filterValue ? () => onFilter('') : handleClear}
                  isSmall
                >
                  <Icon
                    icon="x-close"
                    className="w-3 h-3"
                    iconColor="gray"
                  />
                </Button>
              </div>
            ) : null}
        </div>
      </div>
      {selectedComparables.length
        ? (
          <Button
            className="w-full md:w-auto"
            variant="primary"
            onClick={handleCreateComparison}
            isSmall
          >
            Create
          </Button>
        )
        : null}
    </div>
  );
};

SelectedComparables.propTypes = {
  compareTargets: PropTypes.arrayOf(PropTypes.shape({})),
  selectedComparables: selectedComparablesPropTypes,
  filterValue: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  handleClear: PropTypes.func,
  handleCreateComparison: PropTypes.func,
};

SelectedComparables.defaultProps = {
  compareTargets: [],
  selectedComparables: [],
  handleClear: null,
  handleCreateComparison: null,
};

export default SelectedComparables;
