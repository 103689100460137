import React from 'react';
import PropTypes from 'prop-types';

import SectionContainer from '../../../../common/Containers/Section';
import PendingRaterCards from './PendingRaters';
import JoinedRaterCards from './JoinedRaters';
import Button from '../../../../common/Button';

const Raters = ({
  assessment,
  groupId,
  groupMember: {
    name: groupMemberName,
    memberId,
    raters,
  },
  onCancelRater,
  handleSetNotification,
  onResend,
  removeRater,
}) => {
  const pendingRaters = raters.filter(rater => rater.invitationStatus === 'pending');
  const joinedRaters = raters.filter(rater => rater.invitationStatus === 'accepted');
  return (
    <SectionContainer title={`Raters for ${groupMemberName}`}>
      <div className="flex justify-center my-4 text-center">
        <Button
          to={`/assessments/${assessment}/group/${groupId}/member/${memberId}/raters/new`}
          isSmall
          isNormalCase
          variant="primary"
        >
          Invite Raters
        </Button>
      </div>

      {(raters.length)
        ? (
          <div className="px-4">
            <PendingRaterCards
              raters={pendingRaters}
              onCancelRater={onCancelRater}
              onResend={onResend}
            />
            <JoinedRaterCards
              assessment={assessment}
              raters={joinedRaters}
              removeRater={removeRater}
              handleSetNotification={handleSetNotification}
            />
          </div>
        )
        : (
          <div className="text-center">
            <p>
              There are no raters for {groupMemberName}.
            </p>
          </div>
        )}
    </SectionContainer>
  );
};

Raters.propTypes = {
  assessment: PropTypes.string.isRequired,
  groupId: PropTypes.number.isRequired,
  groupMember: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    available: PropTypes.string,
    raters: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        email: PropTypes.string,
        groupMemberId: PropTypes.number,
        invitationSentDate: PropTypes.string,
        invitationStatus: PropTypes.string,
        joinedAt: PropTypes.string,
        name: PropTypes.string,
        raterId: PropTypes.number,
        raterInvitationId: PropTypes.number,
        surveyStatus: PropTypes.string,
        token: PropTypes.string,
      }),
    ),
    memberId: PropTypes.number,
  }).isRequired,
  handleSetNotification: PropTypes.func.isRequired,
  onCancelRater: PropTypes.func.isRequired,
  onResend: PropTypes.func.isRequired,
  removeRater: PropTypes.func.isRequired,
};

export default Raters;
