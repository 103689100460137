import React, {
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import ElementQuery from 'react-eq';

import DOMPurify from 'isomorphic-dompurify';

import { Context } from '../../../context/ContextProvider';

import withServerSideData from '../../../HOC/withServerSideData';

import PageHeader from '../../PageHeader';
import Header from './Header';
import GroupMembers from './GroupMembers';
import GroupPendingMembers from './GroupPendingMembers';
import GroupOwners from './GroupOwners';
import GroupFooter from './GroupFooter';
import Notification from '../../Notifications/Notification';
import GlobeSmartModal from '../../GlobeSmartModal';

import getAssessmentGroupAction from '../../../actions/assessments/groups/manageGroup';
import groupUpdateAction from '../../../actions/assessments/groups/groupUpdate';
import emailGroupReportAction from '../../../actions/assessments/emailGroupReport';
import emailReportAction from '../../../actions/assessments/emailReport';
import emailReminder from '../../../actions/assessments/emailReminder';
import groupRemoveMemberAction from '../../../actions/assessments/groups/groupRemoveMember';
import groupRemoveAction from '../../../actions/assessments/groups/groupRemove';
import groupRemoveOwnerAction from '../../../actions/assessments/groups/groupRemoveOwner';
import groupRemoveRaterAction from '../../../actions/assessments/groups/groupRemoveRater';
import addGroupOwnerAction from '../../../actions/assessments/addGroupOwner';
import inviteToUpgradeAction from '../../../actions/assessments/inviteToUpgrade';
import InvitationResendAction from '../../../actions/invitationResend';
import InvitationCancelAction from '../../../actions/invitationCancel';

import getAvailableReportVersion from '../../../lib/assessments/reportVersion';
import {
  trackEmailGroupReport,
  trackEmailGroupReportFailure,
} from '../../../lib/tracker/assessments/email-group-report';
import {
  trackEmailIndividualReport,
  trackEmailIndividualReportFailure,
} from '../../../lib/tracker/assessments/email-individual-report';

import ActionNavigation from '../../Navigation/ActionNavigation';
import GroupAddOwnerModal from './GroupAddOwnerModal';
import GroupMemberVersionModal from './GroupMemberVersionModal';
import CustomMessage from '../../CustomMessage';

const queries = { 'card-layout': 400 };
const AssessmentGroup = ({ location, params: { assessment, id }, initialData }) => {
  const {
    router,
    currentUser,
    apiService,
    handleOpenModal,
    handleCloseModal,
  } = useContext(Context);

  const [asmtGroupData, setAsmtGroupData] = useState(initialData);
  const [groupCurrentName, setGroupCurrentName] = useState(asmtGroupData.name);
  const [groupCustomMessage, setGroupCustomMessage] = useState(asmtGroupData.customMessage);
  const [customMessage, setCustomMessage] = useState(asmtGroupData.customMessage);
  const [notification, setNotification] = useState(null);
  const [modalNotification, setModalNotification] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isVersionModal, setIsVersionModal] = useState(false);
  const [customMessageEditing, setCustomMessageEditing] = useState(false);
  const [memberWithNewRaters, setMemberWithNewRaters] = useState(null);

  const notificationRef = useRef();
  const groupNameRef = useRef();

  const {
    asmtData: {
      displayName,
      type: asmtType,
    },
    id: groupId,
    name: groupName,
    pendingInvitations,
    members,
    owners,
    completed,
  } = asmtGroupData;

  const isGroupOwner = owners.some(owner => owner.userId === currentUser.userid);
  if (!isGroupOwner) {
    router.push(`/assessments/${assessment}`);
  }

  const pageTitle = displayName;
  const reportVersion = getAvailableReportVersion(members);
  const isGroupReportUnavailable = reportVersion === null;

  const isIbi = assessment === 'ibi';
  const isGta = assessment === 'gta';
  const groupOrTeam = isGta ? 'team' : 'group';
  const groupOrTeamUpperCase = groupOrTeam.charAt(0).toUpperCase() + groupOrTeam.slice(1);

  useEffect(() => {
    if (location.state && location.state.memberId) {
      const memberId = parseInt(location.state.memberId, 10);
      const member = members.find(m => m.memberId === memberId);
      const memberUserId = member.id;
      setMemberWithNewRaters(memberUserId);
    }
  }, [location]);

  useEffect(() => {
    setGroupCustomMessage(customMessage);
  }, [customMessage]);

  useEffect(() => {
    if (notification) {
      window.scroll(0, 0);
    }
  }, [notification]);

  /**
   * Call the action to email a group's report to the owner and then track
   * @return {Promise}
   */
  async function emailAssessmentGroupReport() {
    if (isVersionModal) {
      handleCloseModal();
    }
    try {
      const response = await emailGroupReportAction(apiService, assessment, groupId);
      if (response.status === 200) {
        await trackEmailGroupReport(
          pageTitle,
          assessment,
          groupName,
        );
        setNotification({ type: 'success', message: `Your ${groupOrTeam} report will be emailed to you` });
      }
    } catch (err) {
      if (err.status === 404 && err.message !== '') {
        setNotification({ type: 'upgrade', message: err.message });
      } else {
        trackEmailGroupReportFailure(err);
        setNotification({
          type: 'failure',
          message: err.message
            ? err.message
            : `There was an error sending the ${groupOrTeam} report. Please try again later`,
        });
      }
    }
  }

  async function checkVersionOfMember() {
    const version2Members = members.filter(member => member.version === 2);
    if (reportVersion === 3 && version2Members.length > 0) {
      setIsVersionModal(!isVersionModal);
      setNotification(null);
      handleOpenModal({
        modalSize: 'large',
        afterCloseAction: () => {
          setModalNotification(null);
          setIsVersionModal(false);
        },
      });
    } else {
      emailAssessmentGroupReport();
    }
  }

  function isRater(token) {
    return token.slice(0, 2) === 'ri';
  }

  /**
   * Call the action to email a member's report to the owner and then track
   * @param  {String} memberUserId   We use the token from the API as the ID
   * @param  {String} memberId   We use the member's id to email that member's report with raters
   * @return {Promise}
   */
  async function emailMemberReport(memberUserId, memberId) {
    try {
      const response = await emailReportAction(apiService, assessment, memberUserId, memberId);
      if (response.status === 200) {
        const { email, organizations } = currentUser;
        await trackEmailIndividualReport(
          displayName,
          asmtType,
          email,
          false,
          organizations,
        );
        const reportMember = members.filter(member => member.id === memberUserId).shift().name;
        setNotification({
          type: 'success',
          message: `The ${assessment.toUpperCase()} report for ${reportMember} has been emailed to you`,
        });
      }
    } catch (err) {
      trackEmailIndividualReportFailure(err);
      setNotification({
        type: 'failure',
        message: 'There was an error sending the report. Please try again later.',
      });
    }
  }

  /**
   *
   * @param {String} memberId
   * @param {String} memberEmail
   * @returns response status code
   */
  async function inviteToUpgrade(memberId, memberEmail) {
    try {
      const response = await inviteToUpgradeAction({
        apiService,
        assessment,
        groupId,
        memberId,
      });
      if (response.status === 200) {
        setNotification({
          type: 'success',
          message: `An invitation to upgrade has been resent to ${memberEmail}`,
        });
      }
    } catch (err) {
      setNotification({
        type: 'failure',
        message: 'There was an error sending the invitation. Please try again later.',
      });
    }
  }

  /**
   * Mark the card in the list that matches `memberUserId` with `remindedAt`
   * @param  {String} memberUserId The member id from the click event
   * @param { String} remindedAt The date that the member was reminded
   * @return {Array}        The modified member list
   */
  function markMemberReminded(memberUserId, remindedAt) {
    return members.map(member => {
      if (member.id === memberUserId) {
        Object.assign(member, { remindedAt });
      }
      return member;
    });
  }

  /**
   * Call the action to remind the member and then mark that
   * member as reminded in the UI as reminded
   * @param  {String} memberUserId      We use the user's id
   * @return {undefined}
   */
  async function onRemindMember(memberUserId) {
    try {
      const response = await emailReminder(apiService, id, memberUserId);
      if (response.status === 200) {
        const remindedMembers = markMemberReminded(memberUserId, response.remindedAt);
        const remindedMember = members.filter(member => member.id === memberUserId).shift().name;
        setNotification({
          type: 'success',
          message: `An ${assessment.toUpperCase()} survey reminder has been sent to ${remindedMember}`,
        });
        setAsmtGroupData({ ...asmtGroupData, members: remindedMembers });
      }
    } catch (err) {
      setNotification({
        type: 'failure',
        message: 'There was an error sending the reminder. Please try again later.',
      });
    }
  }

  /**
   * Mark the card in the list that matches `token` with `resent`
   * @param  {String} token The invitation token from the API
   * @param  {String} sentAt The updated time that the invite was resent
   * @return {Array}        The modified invitation list
   */
  function markInviteResent(token, sentAt) {
    if (isRater(token)) {
      const updatedMember = members
        .filter(member => {
          const invitation = member.raters.find(raterInvitation => raterInvitation.token === token);
          if (invitation) {
            return Object.assign(invitation, { invitationSentDate: sentAt });
          }
          return invitation;
        });
      return members.map(member => updatedMember.find(o => o.id === member.id) || member);
    }
    return pendingInvitations
      .map(invitation => {
        if (invitation.token === token) {
          Object.assign(invitation, { sentAt });
        }
        return invitation;
      });
  }

  /**
   * Call the action to resend the invitation and then mark that
   * invitation in the UI as resent
   * @param  {String} token We use the token from the API as the ID
   * @return {undefined}
   */
  async function onResend(token) {
    try {
      const resentInvitation = await new InvitationResendAction(apiService).execute(token);
      const resentInvitations = markInviteResent(token, resentInvitation.sentAt);
      let resentEmail;

      if (isRater(token)) {
        resentEmail = resentInvitation.recipientEmail;
      } else {
        resentEmail = pendingInvitations
          .filter(invitation => invitation.token === token).shift().email;
        setAsmtGroupData({ ...asmtGroupData, pendingInvitations: resentInvitations });
      }

      setNotification({
        type: 'success',
        message: `An invitation has been resent to ${resentEmail}`,
      });
    } catch (err) {
      setNotification({
        type: 'failure',
        message: 'There was an error resending the invitation. Please try again later.',
      });
    }
  }

  /**
   * Call the action to cancel the invitation and then remove it from the UI
   * @param  {String} id  We use the token from the API as the ID
   * @return {Promise}
   */
  async function onCancel(token) {
    await new InvitationCancelAction(apiService).execute(token);
    const groupData = await getAssessmentGroupAction(apiService, assessment, groupId);
    setAsmtGroupData(groupData);
  }

  /**
   * Call the action to remove this user from the group and then update the UI
   * @param  {String} memberUserId The member's user id
   * @return {Promise}
   */
  async function onRemoveMember(memberUserId) {
    try {
      await groupRemoveMemberAction(apiService, groupId, memberUserId);
      const groupData = await getAssessmentGroupAction(apiService, assessment, groupId);
      setAsmtGroupData(groupData);
    } catch (err) {
      setNotification({
        type: 'failure',
        message: err.message
          ? err.message
          : 'There was an error deleting a group member. Please try again later.',
      });
    }
  }

  /**
   * Call the action to remove this rater and then update the UI
   * @param  {Number} raterId The rater's id
   * @return {Promise}
   */
  async function onRemoveRater(raterId) {
    try {
      await groupRemoveRaterAction(apiService, raterId);
      const groupData = await getAssessmentGroupAction(apiService, assessment, groupId);
      setAsmtGroupData(groupData);
    } catch (err) {
      setNotification({
        type: 'failure',
        message: err.message
          ? err.message
          : 'There was an error deleting a rater. Please try again later.',
      });
    }
  }

  /**
   * Call the action to remove this owner from the group and then update the UI
   * @param  {Number} ownerId  owner table primary key Id
   * @param  {String} ownerUserId  Since we allow for unregistered users to be added as
   * group owners using their email as an identifier until they've registered.
   * The ownerId referenced here is the actual owner record id which covers both cases.
   * @return {Promise}
   */
  async function onRemoveOwner(ownerId, ownerUserId) {
    try {
      const response = await groupRemoveOwnerAction(apiService, groupId, ownerId);
      if (response.status === 200) {
        if (currentUser.userid === ownerUserId) {
          router.push(`/assessments/${assessment}`);
        } else {
          const groupData = await getAssessmentGroupAction(apiService, assessment, groupId);
          setAsmtGroupData(groupData);
        }
      }
    } catch (err) {
      setNotification({
        type: 'failure',
        message: err.message
          ? err.message
          : 'There was an error deleting a group owner. Please try again later.',
      });
    }
  }

  async function handleResponseMessage(message) {
    const readableErrorMessage = message.includes('E_VALIDATION')
      ? 'An email you submitted was invalid.'
      : 'Something went wrong. Please try again later.';
    setModalNotification({
      type: 'failure',
      message: readableErrorMessage,
    });
  }

  async function addGroupOwner({ invitations }) {
    try {
      const formSubmitResponse = await addGroupOwnerAction(apiService, groupId, { invitations });
      if (formSubmitResponse.message) {
        handleResponseMessage(formSubmitResponse.message);
      } else {
        handleCloseModal();
        const groupData = await getAssessmentGroupAction(apiService, assessment, groupId);
        setAsmtGroupData(groupData);
      }
    } catch (err) {
      setModalNotification({
        type: 'failure',
        message: err.message
          ? err.message
          : 'There was an error adding a group owner. Please try again later.',
      });
    }
  }

  async function onGroupDelete() {
    await groupRemoveAction(apiService, groupId);
    router.push(`assessments/${assessment}`);
  }

  const toggleEditing = () => {
    groupNameRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest',
    });
    setIsEditing(!isEditing);
  };

  async function saveName(newGroupName) {
    try {
      await groupUpdateAction(apiService, id, { name: newGroupName });
      setGroupCurrentName(newGroupName);
      setIsEditing(!isEditing);
    } catch (err) {
      setNotification({
        type: 'failure',
        message: 'There was an error to update the group name. Please try again.',
      });
    }
  }

  const toggleCustomMessageEditing = () => {
    setCustomMessageEditing(!customMessageEditing);
    if (customMessage !== groupCustomMessage) {
      setGroupCustomMessage(customMessage);
    }
  };

  const customMessageHandleOnChange = e => {
    setGroupCustomMessage(e.target.value);
  };

  async function saveCustomMessage() {
    const newCustomMessage = {
      customMessage: DOMPurify.sanitize(groupCustomMessage, { USE_PROFILES: [] }),
    };
    try {
      await groupUpdateAction(apiService, id, newCustomMessage);
      setCustomMessageEditing(!customMessageEditing);
      setCustomMessage(groupCustomMessage);
    } catch (err) {
      setNotification({
        type: 'failure',
        message: 'There was an error to update the custom message. Please try again.',
      });
    }
  }

  const groupActionButtonsConfig = () => {
    let buttons = [];
    buttons = [...[
      {
        actionClass: 'edit-team-name',
        iconClass: 'pencil',
        onClick: toggleEditing,
        label: 'Change Name',
      },
      {
        actionClass: 'add-member',
        iconClass: 'add-team-member',
        to: `/assessments/${assessment}/group/${id}/add`,
        label: 'Add Member',
      },
    ], ...buttons];
    return buttons;
  };

  const handleSetNotification = newNotification => setNotification(newNotification);

  return (
    <>
      <PageHeader
        pageTitle={`${pageTitle} - Manage ${groupOrTeamUpperCase}`}
        icon="ibi-symbol"
        backLink={{ to: `/assessments/${asmtType}`, text: `Back to ${pageTitle} Home` }}
        skipTarget="#manage-group"
      />
      <GlobeSmartModal>
        {isVersionModal ? (
          <GroupMemberVersionModal
            notification={modalNotification}
            members={members.filter(member => member.version === 2)}
            handleFormSubmit={emailAssessmentGroupReport}
          />
        ) : (
          <GroupAddOwnerModal
            notification={modalNotification}
            handleCloseModal={handleCloseModal}
            handleFormSubmit={addGroupOwner}
            groupName={groupName}
            groupOrTeam={groupOrTeam}
            groupOrTeamUpperCase={groupOrTeamUpperCase}
          />
        )}
      </GlobeSmartModal>
      <div className="breakout">
        <ActionNavigation items={groupActionButtonsConfig()} />
      </div>
      <ElementQuery queries={queries}>
        <div className="box-border">
          {notification
            && (
              <Notification
                ref={notificationRef}
                type={notification.type}
                message={notification.message}
              />
            )}
          <Header
            ref={groupNameRef}
            completedCount={completed}
            groupName={groupCurrentName}
            saveName={saveName}
            isEditing={isEditing}
            toggleEditing={toggleEditing}
            groupOrTeam={groupOrTeamUpperCase}
            disableGroupReport={isGroupReportUnavailable}
            emailAsmtGroupReport={() => checkVersionOfMember()}
          />
          {members.length > 0 ? (
            <GroupMembers
              assessment={assessment}
              groupId={groupId}
              members={members}
              onResend={onResend}
              emailMemberReport={emailMemberReport}
              inviteToUpgrade={inviteToUpgrade}
              removeMember={onRemoveMember}
              removeRater={onRemoveRater}
              remindMember={onRemindMember}
              onCancelRater={onCancel}
              isIbi={isIbi}
              groupOrTeam={groupOrTeamUpperCase}
              handleSetNotification={handleSetNotification}
              memberWithNewRaters={memberWithNewRaters}
            />
          ) : null}
          <GroupPendingMembers
            pendingInvitations={pendingInvitations}
            onResend={onResend}
            onCancel={onCancel}
            groupOrTeam={groupOrTeamUpperCase}
          />
          <CustomMessage
            isOwner={owners.some(owner => owner.userId === currentUser.userid)}
            customMessage={DOMPurify.sanitize(groupCustomMessage, { USE_PROFILES: [] })}
            isCustomMessageEditing={customMessageEditing}
            toggleCustomMessageEditing={toggleCustomMessageEditing}
            saveCustomMessage={saveCustomMessage}
            customMessageHandleOnChange={customMessageHandleOnChange}
          />
          <GroupOwners
            owners={owners}
            removeOwner={onRemoveOwner}
            groupOrTeam={groupOrTeamUpperCase}
            modalOnClick={() => {
              handleOpenModal({
                modalSize: 'large',
                afterCloseAction: () => setModalNotification(null),
              });
            }}
          />
          <GroupFooter completeGroupDelete={onGroupDelete} groupOrTeam={groupOrTeamUpperCase} />
        </div>
      </ElementQuery>
    </>
  );
};

AssessmentGroup.getAPIDataKey = () => 'asmtGroupData';
AssessmentGroup.getData = (apiService, { assessment, id }) =>
  apiService.get(`assessments/${assessment}/group/${id}`).then(data => ({ asmtGroupData: data }));

AssessmentGroup.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      memberId: PropTypes.string,
    }),
  }).isRequired,
  params: PropTypes.shape({
    assessment: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  initialData: PropTypes.shape({
    asmtData: PropTypes.shape({
      displayName: PropTypes.string,
    }),
    id: PropTypes.number,
    name: PropTypes.string,
    pendingInvitations: PropTypes.arrayOf(PropTypes.shape({
      accessCode: PropTypes.string,
      email: PropTypes.string,
      token: PropTypes.string,
      sentAt: PropTypes.string,
    })),
    members: PropTypes.arrayOf(PropTypes.shape({
      accessCode: PropTypes.string,
      dateJoined: PropTypes.string,
      email: PropTypes.string,
      id: PropTypes.string,
      name: PropTypes.string,
      remindedAt: PropTypes.string,
      avatarUrl: PropTypes.string,
    })),
    owners: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      userId: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
      avatarUrl: PropTypes.string,
    })),
    completed: PropTypes.string,
    customMessage: PropTypes.string,
  }).isRequired,
};

export default withServerSideData(AssessmentGroup);
