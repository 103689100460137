import React from 'react';

import PropTypes from 'prop-types';

const PrintLayoutSummary = ({ firstName, lastName }) => (
  <div className="hidden bg-white print:block">
    <div className="flex items-center justify-center p-2">
      <div className="text-base font-semibold font-headline text-charcoal-900">{firstName} {lastName}</div>
      <div className="ml-2 font-sans text-base text-charcoal-900">Profile Summary</div>
    </div>
  </div>
);

PrintLayoutSummary.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
};

export default PrintLayoutSummary;
