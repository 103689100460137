import React from 'react';
import PropTypes from 'prop-types';

import Notification from '../../../Notifications/Notification';

import InvitationForm from '../../../Invitations/InvitationForm';

const TeamAddOwnerModal = (
  {
    notification,
    handleCloseModal,
    teamName,
    handleFormSubmit,
  }) => {
  const legalNotice = 'All Team Owners have the same privileges to edit and administer teams.';

  return (
    <>
      <h2 className="m-0 font-heading-2">{teamName}</h2>
      <div className="mt-2 text-max-width">
        <p className="text-base">{legalNotice}</p>
      </div>
      {notification && <Notification type={notification.type} message={notification.message} />}
      <InvitationForm
        sendMessage="Add Owner"
        invitationType="Team Owners"
        handleFormSubmit={handleFormSubmit}
        handleCloseModal={handleCloseModal}
        hasCancel
      />
    </>
  );
};

TeamAddOwnerModal.propTypes = {
  notification: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string,
  }),
  handleCloseModal: PropTypes.func.isRequired,
  teamName: PropTypes.string.isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
};

TeamAddOwnerModal.defaultProps = {
  notification: {},
};

export default TeamAddOwnerModal;
