class SurveyQuestionResponseAction {
  constructor(apiService) {
    this.apiService = apiService;
  }

  execute({ questionId, response }) {
    return this.apiService.patch('profile/survey', { question_id: questionId, response });
  }
}

export default SurveyQuestionResponseAction;
