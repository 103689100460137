import React, { useEffect, useRef, useState } from 'react';
import { atcb_action as addToCalendar } from 'add-to-calendar-button';
import PropTypes from 'prop-types';
import Button from '../../../common/Button';
import PopoverComponent from '../../../common/Popover';
import ShareSessionPopover from '../../ShareSessionPopover';

import { trackAddSessionToCalendar } from '../../../../lib/tracker/aperian-live';
import { formatCalendarData } from '../../../../lib/aperianLive';

const SessionsDetailsContainerButtons = ({
  name,
  nameInternal,
  cohortCode,
  isRegistered,
  register,
  userJoinUrl,
  isJoinEnabled,
  join,
  dateTime,
  duration,
  facilitator,
  isPast,
  organizations,
  userId,
  createShareableLink,
}) => {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    let timeout;
    if (isCopied) {
      timeout = setTimeout(() => {
        setIsCopied(false);
      }, 6000);
    }
    return () => clearTimeout(timeout);
  }, [isCopied]);

  const calendarConfig = formatCalendarData(dateTime, duration, userJoinUrl, name);
  const buttonRef = useRef(null);

  const showSessionStatus = () => (isPast
    ? <p className="mb-0 text-sm font-semibold tracking-wider px-3.5 py-2 text-charcoal-200">Closed</p>
    : (
      <Button
        onClick={() => register(
          cohortCode,
          'details',
        )}
        isExtraSmall
        variant="primary"
        disabled={!!isRegistered}
      >
        {isRegistered ? 'Registered' : 'Register'}
      </Button>
    ));

  const copyShareableLink = async () => {
    await createShareableLink(
      cohortCode,
      name,
      'details',
    );
    setIsCopied(true);
  };

  const sessionShareOptions = [
    {
      id: 1,
      name: 'Copy Link',
      onClick: copyShareableLink,
      icon: 'link',
      isCopy: true,
    },
  ];

  return (
    <div className="flex flex-wrap gap-2 md:flex-nowrap md:justify-end md:flex-row">
      { isRegistered ? (
        <>
          <PopoverComponent
            position="bottom"
            content={(
              <ShareSessionPopover
                isCopied={isCopied}
                profileShareOptions={sessionShareOptions}
              />
              )}
            className="flex-grow w-full md:w-max"
            singleLineClassName="px-4 py-2"
            fullWidthAtMobile
            isStatic
          >
            <Button
              isSmall
              variant="secondary"
              trailingButtonIcon="share"
              iconColor="primary"
              className="w-full text-base md:w-auto"
            >
              Share
            </Button>
          </PopoverComponent>
          <Button
            ref={buttonRef}
            trailingButtonIcon="calendar-plus"
            iconColor="dark-gray"
            variant="secondary"
            className="w-full md:w-auto"
            onClick={() => addToCalendar(calendarConfig, buttonRef.current).then(
              trackAddSessionToCalendar(
                userId,
                organizations,
                name,
                nameInternal,
                cohortCode,
                dateTime,
                duration,
                facilitator,
                'details',
              ),
            )}
          >
            Add to Cal
          </Button>
          <Button
            onClick={() => join(
              cohortCode,
              userJoinUrl,
              'details',
            )}
            className="w-full md:w-auto"
            variant="primary"
            disabled={!isJoinEnabled}
          >
            Join
          </Button>
        </>
      ) : (
        showSessionStatus()
      )}
    </div>
  );
};

SessionsDetailsContainerButtons.propTypes = {
  name: PropTypes.string.isRequired,
  nameInternal: PropTypes.string.isRequired,
  cohortCode: PropTypes.string.isRequired,
  isRegistered: PropTypes.bool.isRequired,
  register: PropTypes.func.isRequired,
  userJoinUrl: PropTypes.string,
  isJoinEnabled: PropTypes.bool.isRequired,
  join: PropTypes.func.isRequired,
  dateTime: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  facilitator: PropTypes.string.isRequired,
  isPast: PropTypes.bool.isRequired,
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  userId: PropTypes.string.isRequired,
  createShareableLink: PropTypes.func,
};

SessionsDetailsContainerButtons.defaultProps = {
  userJoinUrl: '',
  organizations: [],
  createShareableLink: () => { },
};

export default SessionsDetailsContainerButtons;
