class EmailVerificationResendAction {
  constructor(apiService) {
    this.apiService = apiService;
  }

  execute() {
    return this.apiService.post('emails/resend-verification');
  }
}

export default EmailVerificationResendAction;
