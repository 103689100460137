/* eslint-disable max-classes-per-file */
class ExtendableError extends Error {
  constructor(message) {
    super(message);
    this.name = this.constructor.name;
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = (new Error(message)).stack;
    }
  }
}

export class AuthError extends ExtendableError {}
export class TokenError extends ExtendableError {}
export class BadRequestError extends ExtendableError {
  constructor(message, reason) {
    super(message);
    this.reason = reason;
  }
}
