import React, { useContext, useEffect, useRef, useState } from 'react';
import ElementQuery from 'react-eq';
import PropTypes from 'prop-types';

import withServerSideData from '../../../HOC/withServerSideData';

import { NotificationContext } from '../../../context/Notification';
import { APIContext } from '../../../context/API';

import Button from '../../../components/common/Button';
import Card from '../../../components/Card';
import DraggableCardList from '../../../components/DraggableCardList';
import Notification from '../../../components/Notifications/Notification';
import DashboardHeading from '../../../components/Dashboard/DashboardHeading';

import { updateProductSortOrderAction } from '../../../actions/dashboardBlocks';

import { trackProductSorted } from '../../../lib/tracker/blocks';
import ToggleSwitch from '../../../components/common/ToggleSwitch';

const queries = { 'card-layout': 400 };

const AdminCenterProducts = ({
  initialData: { orgProducts },
  params: { orgId },
  currentOrg,
}) => {
  const { apiService } = useContext(APIContext);
  const {
    notificationMessage,
    removeNotification,
    addNotification,
  } = useContext(NotificationContext);

  const productsToDisplayForSort = orgProducts.filter(product => product.blockType !== 3);
  const [sortedProducts, setSortedProducts] = useState(productsToDisplayForSort);
  const [isDisabled, setIsDisabled] = useState(true);

  const notificationRef = useRef(null);

  useEffect(() => {
    let timeout;
    if (notificationMessage) {
      timeout = window.setTimeout(() => {
        removeNotification();
      }, 5000);
    }
    return () => {
      window.clearTimeout(timeout);
      if (notificationMessage) removeNotification();
    };
  }, [notificationMessage]);

  const handleChangeCards = updatedProducts => {
    setIsDisabled(!orgProducts.some((item, index) => item.id !== updatedProducts[index].id));
    setSortedProducts(updatedProducts);
  };

  const submitSortOrder = async () => {
    try {
      const sortedProductsWithPromosAddedBack = [...sortedProducts];
      const promos = orgProducts.filter(product => product.blockType === 3);
      promos.forEach(promo =>
        sortedProductsWithPromosAddedBack.splice(promo.sortOrder - 1, 0, promo));
      await updateProductSortOrderAction(apiService, orgId, sortedProductsWithPromosAddedBack);
      await trackProductSorted({ sortedProducts, sortedBy: 'Self Serve', orgName: currentOrg?.name });
      addNotification({
        type: 'success',
        message: 'Product sort order updated',
      });
    } catch (err) {
      addNotification({
        type: 'failure',
        message: `Error: ${err.message}`,
      });
    }
  };

  const handleToggleUpdate = ({ target: { name, checked } }) => {
    const updatedBlocks = sortedProducts
      .map(block => (block.id === Number(name) ? { ...block, isActive: checked } : block));
    setSortedProducts(updatedBlocks);
    setIsDisabled(false);
  };

  return (
    <ElementQuery queries={queries}>
      <div>
        <Notification ref={notificationRef} {...notificationMessage} />
        {orgProducts.length > 0 ? (
          <div className=" bg-ivory-400">
            <DashboardHeading headingText="Available Products" />
            <h4 className="py-4 my-1 text-xl">Drag and drop the products into your preferred order. This order will appear for all of your organization&apos;s learners.</h4>
            <DraggableCardList
              list={sortedProducts}
              updateList={handleChangeCards}
              renderItem={({ id, displayName, color, isActive, blockType }) => (
                <Card
                  key={id}
                  displayName={displayName}
                  blockType={blockType}
                  color={color}
                  isDraggable
                >
                  <ToggleSwitch
                    id={id}
                    name={`${id}`}
                    checked={isActive}
                    onChange={handleToggleUpdate}
                  />
                </Card>
              )}
            />
            <div className="flex justify-end py-4 my-1">
              <Button
                filledColor="green"
                disabled={isDisabled}
                onClick={submitSortOrder}
              >SAVE
              </Button>
            </div>
          </div>

        ) : (
          <div className="mt-3 text-center">
            <p>
              This organization does not have any products.
            </p>
          </div>
        )}
      </div>
    </ElementQuery>
  );
};

AdminCenterProducts.getAPIDataKey = () => 'AdminCenterProducts';

AdminCenterProducts.getData = (apiService, { orgId }) =>
  apiService.get(`blocks/organizations/${orgId}`).then(data => ({ AdminCenterProducts: data }));

AdminCenterProducts.propTypes = {
  currentOrg: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  params: PropTypes.shape({
    orgId: PropTypes.string,
  }).isRequired,
  initialData: PropTypes.shape({
    orgProducts: PropTypes.arrayOf(
      PropTypes.shape({
        displayName: PropTypes.string,
        color: PropTypes.string,
        id: PropTypes.number,
        blockType: PropTypes.number,
      }),
    ),
  }).isRequired,
};

export default withServerSideData(AdminCenterProducts);
