import React from 'react';
import { iconPropTypes, defaultIconProps } from './types';

import { fillColorMap } from '../index.js';

const Culture = ({ className, iconColor }) => (
  <svg className={`${className} ${fillColorMap[iconColor]}`} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.000488281 2.22282V13.2434C0.000488281 13.3666 0.10016 13.4643 0.221331 13.4643H11.242C11.3651 13.4643 11.4667 13.3627 11.4647 13.2376C11.3475 7.08526 6.38146 2.11924 0.227194 2.00003C0.102115 1.99807 0.000488281 2.0997 0.000488281 2.22282Z" />
    <path d="M11.1918 11.4639H0.271741C0.151057 11.4639 0.0532227 11.5626 0.0532227 11.6844V22.7076C0.0532227 22.8294 0.151057 22.9282 0.271741 22.9282H11.1918C11.3125 22.9282 11.4104 22.8294 11.4104 22.7076V11.6844C11.4104 11.5626 11.3125 11.4639 11.1918 11.4639Z" />
    <path d="M23.9292 2.22282V13.2434C23.9292 13.3666 23.8295 13.4643 23.7084 13.4643H12.6877C12.5646 13.4643 12.463 13.3627 12.4649 13.2376C12.5822 7.08526 17.5482 2.11924 23.7025 2.00003C23.8276 1.99807 23.9292 2.0997 23.9292 2.22282Z" />
    <path d="M12.7378 11.4639H23.6579C23.7786 11.4639 23.8765 11.5626 23.8765 11.6844V22.7076C23.8765 22.8294 23.7786 22.9282 23.6579 22.9282H12.7378C12.6172 22.9282 12.5193 22.8294 12.5193 22.7076V11.6844C12.5193 11.5626 12.6172 11.4639 12.7378 11.4639Z" />
  </svg>
);

Culture.propTypes = iconPropTypes;
Culture.defaultProps = defaultIconProps;

export default Culture;
