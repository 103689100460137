import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../common/Button';

const ConfirmDeleteButtons = ({
  id,
  onRemove,
  onCancel,
}) => (
  <div style={{ maxWidth: 'fit-content' }}>
    <Button
      className="card__button"
      dataID={id}
      isSmall
      isNormalCase
      isWarning
      onClick={onRemove}
    >
      <span>Really?</span>
      <br />
      <span>Yes, Remove.</span>
    </Button>
    <Button
      className="card__button"
      dataID={id}
      isSmall
      isNormalCase
      variant="secondary"
      onClick={onCancel}
    >
      <span>No, Keep.</span>
    </Button>
  </div>
);

ConfirmDeleteButtons.propTypes = {
  onRemove: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default ConfirmDeleteButtons;
