import PropTypes from 'prop-types';

const propTypes = {
  onClick: PropTypes.func,
  to: PropTypes.string,
  rel: PropTypes.string,
  target: PropTypes.string,
  type: PropTypes.string,
  trailingButtonIcon: PropTypes.string,
  leadingButtonIcon: PropTypes.string,
  className: PropTypes.string,
  isSmall: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  isCenter: PropTypes.bool,
  isWarning: PropTypes.bool,
  isCancel: PropTypes.bool,
  isNormalCase: PropTypes.bool,
  isDisabled: PropTypes.bool,
  disabledIconColor: PropTypes.string,
  dataID: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

const defaultProps = {
  onClick: () => {},
  to: null,
  rel: null,
  target: null,
  type: null,
  leadingButtonIcon: null,
  trailingButtonIcon: null,
  className: null,
  isSmall: false,
  isFullWidth: false,
  isCenter: false,
  isWarning: false,
  isCancel: false,
  isNormalCase: false,
  isDisabled: false,
  disabledIconColor: 'gray',
  dataID: null,
};

export {
  propTypes,
  defaultProps,
};
