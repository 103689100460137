class InvitationResendAction {
  constructor(apiService) {
    this.apiService = apiService;
  }

  execute(token) {
    return this.apiService.post(`invitations/${token}/resend`);
  }
}

export default InvitationResendAction;
