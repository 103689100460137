import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { NotificationContext } from '../../../context/Notification';
import { APIContext } from '../../../context/API';
import { ModalContext } from '../../../context/Modal';
import { RouterContext } from '../../../context/Router';

import withServerSideData from '../../../HOC/withServerSideData';

import { createCohortAction } from '../../../actions/cohorts';

import CohortForm from '../../../components/Cohorts/CohortForm';
import CohortsConfirmModal from './CohortsConfirmModal';
import GlobeSmartModal from '../../../components/GlobeSmartModal';

const CohortsAdd = ({ initialData }) => {
  const [cohortFormData, setCohortFormData] = useState(initialData);
  const { apiService } = useContext(APIContext);
  const { handleOpenModal, handleCloseModal } = useContext(ModalContext);
  const { router } = useContext(RouterContext);
  const {
    notificationMessage,
    removeNotification,
    addNotification,
  } = useContext(NotificationContext);

  useEffect(() => {
    let timeout;
    if (notificationMessage) {
      timeout = window.setTimeout(() => {
        removeNotification();
      }, 5000);
    }
    return () => {
      window.clearTimeout(timeout);
      if (notificationMessage) removeNotification();
    };
  }, [notificationMessage]);

  async function getNewCohortFormData() {
    const { cohortFormData: newCohortFormData } = await CohortsAdd.getData(apiService);
    handleCloseModal();
    setCohortFormData(newCohortFormData);
  }

  function handleModalRedirect() {
    handleCloseModal();
    const { cohort: { code } } = cohortFormData;
    router.push(`/cohorts/${code}`);
  }

  const formSubmit = async (cohortData, handleClearForm) => {
    try {
      await createCohortAction(apiService, cohortData);
      handleClearForm();
      handleOpenModal({
        content: (
          <CohortsConfirmModal
            onHandleRedirect={handleModalRedirect}
            onHandleRemain={getNewCohortFormData}
          />),
        afterCloseAction: getNewCohortFormData,
      });
    } catch (err) {
      addNotification({
        type: 'failure',
        message: `Error while creating cohort: ${err.message}`,
      });
    }
  };

  return (
    <>
      <CohortForm
        cohortFormData={cohortFormData}
        handleFormSubmit={formSubmit}
        buttonText="Create Cohort"
      />
      <GlobeSmartModal />
    </>
  );
};

CohortsAdd.getAPIDataKey = () => 'cohortFormData';
CohortsAdd.getData = apiService => apiService.get('cohorts/form')
  .then(({ cohortFormData }) => ({ cohortFormData }));

CohortsAdd.propTypes = {
  initialData: PropTypes.shape({
    cohort: PropTypes.shape({
      code: PropTypes.string,
    }).isRequired,
    plans: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    types: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.number,
      })),
  }).isRequired,
  params: PropTypes.shape({
    id: PropTypes.string,
  }),
};

CohortsAdd.defaultProps = {
  params: {},
};

export default withServerSideData(CohortsAdd);
