import React from 'react';
import PropTypes from 'prop-types';

const ProfileItem = ({ classNames, left, right, isDashboard }) => (
  <div className={classNames}>
    <span className={`profile__label profile__label--left ${isDashboard ? 'profile__label--dashboard' : ''}`}>
      { left }
    </span>
    <span className={`profile__label profile__label--right ${isDashboard ? 'profile__label--dashboard' : ''}`}>
      { right }
    </span>
  </div>
);

ProfileItem.propTypes = {
  classNames: PropTypes.string.isRequired,
  left: PropTypes.string.isRequired,
  right: PropTypes.string.isRequired,
  isDashboard: PropTypes.bool,
};

ProfileItem.defaultProps = {
  isDashboard: false,
};

export default ProfileItem;
