import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { CurrentUserContext } from '../../../context/CurrentUser';

import withServerSideData from '../../../HOC/withServerSideData';
import SiteHeader from '../../SiteHeader';

const AssessmentSurvey = ({ location, initialData }) => {
  const { currentUser } = useContext(CurrentUserContext);
  const { displayName, surveyProviderUrl } = initialData;
  const pageTitle = displayName;

  useEffect(() => {
    const intervalId = window.setInterval(() => {
      currentUser.refreshAuthToken();
    }, 900000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <SiteHeader pageTitle={pageTitle} location={location} />
      <div className="flex flex-col min-h-screen">
        {/* This embed code may be restricted to use on a single domain */}
        {/* see documentation at: https://help.alchemer.com/help/iframe-embed#limit-permitted-domains-for-embeds */}
        <iframe
          title="alchemer-survey"
          src={surveyProviderUrl}
          className="assessment__survey"
          frameBorder="0"
          sandbox="allow-top-navigation allow-scripts allow-forms"
        />
      </div>
    </>
  );
};

AssessmentSurvey.getAPIDataKey = () => 'asmtSurveyData';
AssessmentSurvey.getData = (apiService, { assessment, groupId }) => {
  let route = `assessments/${assessment}/survey-url`;
  if (groupId) route += `?group=${groupId}`;
  return apiService.get(route).then(data => ({ asmtSurveyData: data }));
};

AssessmentSurvey.propTypes = {
  params: PropTypes.shape({
    assessment: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  initialData: PropTypes.shape({
    displayName: PropTypes.string,
    surveyProviderUrl: PropTypes.string,
  }).isRequired,
};

export default withServerSideData(AssessmentSurvey);
