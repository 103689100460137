const skillsRedirectMap = {
  communication: '/communication-styles',
  core: '/core-values-and-implications-for-business',
  teamwork: '/building-teamwork',
  credibility: '/establishing-credibility',
};

export const determineSkillsRedirect = activity => {
  const matchingRecord = Object.entries(skillsRedirectMap).find(([key]) => activity.includes(key));
  const redirectValue = matchingRecord[1];
  return redirectValue;
};
