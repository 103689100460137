import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { format, isValid } from 'date-fns';

import DatePickerInput from '../common/DatePicker';
import LabelInput from '../common/LabelInput';
import Icon from '../common/Icon';

const EditableField = ({
  value: initialValue,
  onUpdate,
  name,
  label,
  inputType,
  maxLength,
  onBlur,
  isDisabled,
  helperText,
  allowPreviousDates,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newValue, setNewValue] = useState(initialValue);
  const inputRef = useRef(null);

  useEffect(() => {
    setIsEditing(false);
    setNewValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (!isEditing) setNewValue(initialValue);
    else if (inputType === 'date' && !newValue) setNewValue(new Date());
  }, [isEditing]);

  const handleOnToggle = () => setIsEditing(!isEditing);

  const handleDateFocusOut = e => {
    const { target: { value: date } } = e;
    if (!isValid(new Date(date))) {
      setNewValue(null);
    }
  };

  const handleOnChange = ({ target: { value } }) => setNewValue(value);

  const handleOnCancel = () => setIsEditing(false);

  const handleOnUpdate = () => {
    if (newValue === initialValue) {
      return setIsEditing(false);
    }
    if ((inputType === 'number' || inputType === 'text') && !newValue) {
      return onUpdate({ [name]: null });
    }
    return onUpdate({ [name]: newValue });
  };

  const toggleEditButtonText = () => {
    if (isEditing) {
      return (
        <div className="flex justify-end gap-1 mb-1 ml-2">
          <button
            key="save"
            type="button"
            onClick={handleOnUpdate}
          >
            <Icon icon="save" />
          </button>
          <button
            key="cancel"
            type="button"
            onClick={handleOnCancel}
          >
            <Icon icon="x-close" className="w-4 h-4" />
          </button>
        </div>
      );
    }
    return isDisabled ? null : (
      <div className="flex justify-end mb-1">
        <button
          key="edit"
          type="button"
          className="ml-2"
          onClick={handleOnToggle}
        >
          <Icon icon="pencil" />
        </button>
      </div>
    );
  };

  const renderEditingState = () => {
    if (inputType === 'date') {
      return (
        <DatePickerInput
          ref={inputRef}
          id={name}
          labelText={label || name}
          value={newValue}
          onChangeValue={handleOnChange}
          handleIconClick={() => inputRef.current.setFocus()}
          maxLength={maxLength}
          isDisabled={isDisabled}
          dateFormat="yyyy-MM-dd"
          handleDateChange={date => setNewValue(date)}
          onBlur={onBlur}
          onCalendarBlur={handleDateFocusOut}
          helperText={helperText}
          allowPreviousDates={allowPreviousDates}
        />
      );
    }
    return (
      <LabelInput
        ref={inputRef}
        id={name}
        name={name}
        labelText={label || name}
        value={newValue}
        onChangeValue={handleOnChange}
        handleIconClick={() => inputRef.current.setFocus()}
        maxLength={maxLength}
        isDisabled={isDisabled}
        onBlur={onBlur}
        helperText={helperText}
      />
    );
  };

  return (
    <div className={`flex ${helperText ? 'items-center' : 'items-end'} w-full`}>
      {isEditing
        ? (
          renderEditingState()
        )
        : (
          <LabelInput
            id={name}
            labelType="text"
            labelText={label || name}
            value={(inputType === 'date' && newValue)
              ? format(new Date(newValue), 'yyyy-MM-dd')
              : newValue}
            isDisabled
            helperText={helperText}
            allowPreviousDates={allowPreviousDates}
          />
        )}
      {toggleEditButtonText()}
    </div>
  );
};

EditableField.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  inputType: PropTypes.string,
  maxLength: PropTypes.number,
  isDisabled: PropTypes.bool,
  allowPreviousDates: PropTypes.bool,
  helperText: PropTypes.string,
};
EditableField.defaultProps = {
  maxLength: 255,
  value: '',
  isDisabled: false,
  allowPreviousDates: false,
  inputType: 'text',
  helperText: null,
  onBlur: () => { },
};

export default EditableField;
