import PropTypes from 'prop-types';

const propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  type: PropTypes.string.isRequired,
  shortDisplayName: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string.isRequired,
  showName: PropTypes.bool,
  index: PropTypes.number,
  hasNavigation: PropTypes.bool,
  withBorder: PropTypes.bool,
};

const defaultProps = {
  showName: true,
  index: 0,
  withBorder: false,
};

export {
  propTypes,
  defaultProps,
};
