import React from 'react';

const GrowthDescription = () => (
  <div>
    <p className="text-sm">With Aperian Growth, you will have full access to:</p>
    <ul className="mx-0 my-4 text-sm font-normal list-none ps-2">
      <li>GlobeSmart Profile</li>
      <li>100+ GlobeSmart Guides</li>
      <li>Aperian Live</li>
      <li>Aperian Copilot</li>
      <li>A selection of our most popular learning modules</li>
    </ul>
  </div>
);

export default GrowthDescription;
