import React from 'react';

import { propTypes } from './types';

import { Context } from '../../../context/ContextProvider';

import needsAuthentication from '../../../lib/needsAuthentication';
import EmailVerificationResendAction from '../../../actions/emailVerificationResendAction';
import { COOKIE_NOTIFICATION, IS_PRODUCTION } from '../../../lib/constants';

// This is a non-visual component. It only exists to
// make API requests and redirect people as needed.

// PureComponent to prevent re-render and second API call
class EmailVerificationResend extends React.PureComponent {
  static contextType = Context;

  constructor(props, context) {
    super(props, context);
    this.redirectRoute = context.router.location.query.redirect_to || '/dashboard';
    needsAuthentication(context.router, context.currentUser);
  }

  setNotification(type, message) {
    this.context.cookies.set(
      COOKIE_NOTIFICATION,
      { type, message },
      { path: '/', secure: IS_PRODUCTION },
    );
  }

  render() {
    const { apiService, router } = this.context;

    new EmailVerificationResendAction(apiService)
      .execute()
      .then(() => {
        this.setNotification('success', 'Your verification email has been re-sent.');
        router.push(this.redirectRoute);
      })
      .catch(() => {
        this.setNotification('failure', 'There was a problem re-sending your email verification. Please try again later.');
        router.push(this.redirectRoute);
      });

    return null;
  }
}

EmailVerificationResend.propTypes = propTypes;

export default EmailVerificationResend;
export {
  EmailVerificationResend as Component,
};
