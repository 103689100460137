const needsAuthentication = (router, currentUser, params, cb) => {
  if (!currentUser || !currentUser.isLoggedIn()) {
    const url = params ? `/login?${params}` : '/login';

    router.push(url);
  } else if (typeof cb === 'function') {
    cb();
  }
};

export default needsAuthentication;
