import React from 'react';

import PropTypes from 'prop-types';

const OrgTeamIcon = ({ className1, className2 }) => (
  <svg className="max-w-full max-h-full" width="120" height="120" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 108.58 91.54">
    <path className={className1} d="m72.39,33.54c8.8,0,16-7.2,16-16S81.19,1.54,72.39,1.54s-16,7.2-16,16,7.2,16,16,16Z" />
    <path className={className2} d="m37.39,33.54c8.8,0,16-7.2,16-16S46.19,1.54,37.39,1.54s-16,7.2-16,16,7.2,16,16,16Z" />
    <path className={className2} d="m90.39,73.54c-8.7,0-15.8,6.9-16,15.6,0,.2.2.4.4.4h31.1c.2,0,.4-.2.4-.4-.1-8.6-7.2-15.6-15.9-15.6Z" />
    <path className={className1} d="m90.39,70.54c8.8,0,16-7.2,16-16s-7.2-16-16-16-16,7.2-16,16,7.2,16,16,16Z" />
    <path className={className1} d="m54.39,73.54c-8.7,0-15.8,6.9-16,15.6,0,.2.2.4.4.4h31.1c.2,0,.4-.2.4-.4-.2-8.6-7.2-15.6-15.9-15.6Z" />
    <path className={className2} d="m54.39,70.54c8.8,0,16-7.2,16-16s-7.2-16-16-16-16,7.2-16,16,7.1,16,16,16Z" />
    <path className={className2} d="m18.29,73.54c-8.7,0-15.8,6.9-16,15.6,0,.2.2.4.4.4h31.1c.2,0,.4-.2.4-.4-.1-8.6-7.2-15.6-15.9-15.6Z" />
    <path className={className1} d="m18.29,70.54c8.8,0,16-7.2,16-16s-7.2-16-16-16S2.29,45.74,2.29,54.54c0,8.9,7.2,16,16,16Z" />
  </svg>
);

OrgTeamIcon.propTypes = {
  className1: PropTypes.string,
  className2: PropTypes.string,
};

OrgTeamIcon.defaultProps = {
  className1: '',
  className2: '',
};

export default OrgTeamIcon;
