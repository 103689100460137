import React, { useState } from 'react';
import { propTypes, defaultProps } from './types';
import RadioIcons from './icons';

const SurveyResponse = props => {
  const surveyResponseId = `response-${props.responseValue}`;
  const [isHovered, setIsHovered] = useState(false);
  const [isPressed, setIsPressed] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);

  const handleMouseLeave = () => setIsHovered(false);

  const handleMouseDown = () => setIsPressed(true);

  const handleMouseUp = () => setIsPressed(false);

  function isChecked() {
    return props.selectedOption === props.responseValue;
  }

  function onOptionChangeHandler(changeEvent) {
    props.onOptionChange(parseInt(changeEvent.target.value, 10));
  }

  return (
    <li key={props.questionId} className="flex flex-row items-center gap-4 leading-none sm:flex-col md:h-full">
      <label
        htmlFor={surveyResponseId}
      >
        <input
          className="sr-only"
          type="radio"
          name={`question-${props.questionId}`}
          id={surveyResponseId}
          value={props.responseValue}
          onChange={onOptionChangeHandler}
          checked={isChecked()}
        />
        <span
          role="radio"
          tabIndex="0"
          aria-checked={isChecked()}
          className={`p-3 w-16 md:w-24 md:h-20 lg:w-28 lg:h-24 flex-grow flex items-center justify-center gap-1 rounded-survey-option-icon shadow-survey-option-icon cursor-pointer transition duration-300 ease-in-out bg-white ${isPressed ? 'transform scale-95' : ''}`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onFocus={handleMouseEnter}
          onBlur={handleMouseLeave}
        >
          <RadioIcons
            bgColor={props.bgColor}
            isChecked={isChecked()}
            isHovered={isHovered}
            icon={props.icon}
          />
        </span>
      </label>
      <span className="flex-grow font-serif text-base italic leading-tight text-left text-gray-500 md:text-xl md:text-center">
        {props.responseText}
      </span>
    </li>
  );
};

SurveyResponse.propTypes = propTypes;
SurveyResponse.defaultProps = defaultProps;

export default SurveyResponse;
