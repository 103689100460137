import React from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';

import AperianMonogram from '../../AperianMonogram';
import NavigationLinks from './NavigationLinks';

import { prefixHostname } from '../../../lib/cdn';

const DesktopSidebar = ({
  location,
  accessLevel,
  premiumUser,
  hasCompletedIBI,
  allowGSPShowing,
}) => (
  <div className="hidden lg:fixed lg:inset-y-0 lg:z-[350] lg:flex lg:w-72 lg:flex-col">
    {/* Sidebar component, swap this element with another sidebar if you like */}
    <div className="flex flex-col pb-4 pr-6 overflow-y-auto bg-charcoal-900 grow gap-y-5">
      <Link to="/dashboard" className="flex items-center h-16 pl-6 shrink-0">
        <img
          className="w-auto h-8"
          src={prefixHostname('/images/aperian-wordmark-ivory.svg')}
          srcSet={prefixHostname('/images/aperian-wordmark-ivory.svg')}
          alt="Aperian&reg;"
          width={50}
          height={50}
        />
      </Link>
      <nav className="flex flex-col flex-1">
        <ul className="flex flex-col flex-1 gap-y-7">
          <li>
            <ul className="-mx-2 space-y-1">
              <NavigationLinks
                accessLevel={accessLevel}
                location={location}
                premiumUser={premiumUser}
                hasCompletedIBI={hasCompletedIBI}
                allowGSPShowing={allowGSPShowing}
              />
            </ul>
          </li>
        </ul>
      </nav>
      <div className="flex items-center h-auto gap-2 pl-6 shrink-0">
        <a
          className="focus:border focus:border-white"
          href="https://aperian.com"
          target="_blank"
          rel="noreferrer noopener"
        >
          <AperianMonogram />
        </a>
        <a
          className="font-sans text-sm text-center text-white no-underline hover:underline focus:border focus:border-white"
          href="https://aperian.com"
          target="_blank"
          rel="noreferrer noopener"
        >
          &#169; {new Date().getFullYear()} Aperian Global, Inc.
        </a>
      </div>
    </div>
  </div>
);

DesktopSidebar.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  accessLevel: PropTypes.arrayOf(PropTypes.string).isRequired,
  premiumUser: PropTypes.bool.isRequired,
  hasCompletedIBI: PropTypes.bool.isRequired,
  allowGSPShowing: PropTypes.bool,
};

DesktopSidebar.defaultProps = {
  allowGSPShowing: true,
};

export default DesktopSidebar;
