import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const getBadgeSize = size => {
  switch (size) {
    case 'medium':
      return 'text-sm px-2.5 py-0.5';
    case 'large':
      return 'text-sm px-2 py-1';
    default:
      return 'text-xs px-2 py-1';
  }
};

const getBadgeColor = color => {
  switch (color) {
    case 'primary':
      return 'text-charcoal-900 bg-slate-50';
    case 'secondary':
      return 'text-rust-700 bg-rust-50';
    case 'green':
      return 'text-green-800 bg-green-50';
    case 'blue':
      return 'text-blue-800 bg-blue-50';
    default:
      return 'text-charcoal-900 bg-slate-50';
  }
};

const Badges = ({ badges, size, color }) => {
  const className = classNames({
    [getBadgeSize(size)]: !!size,
    'm-[2px] font-sans antialiased font-medium uppercase font-medium tracking-wide rounded-full': true,
    [getBadgeColor(color)]: !!color,
  });
  return (
    <div className="flex flex-wrap">
      {badges.length > 0
        ? badges.map(tag => (
          <span key={tag} className={className}>
            {tag}
          </span>
        ))
        : null}
    </div>
  );
};

Badges.propTypes = {
  badges: PropTypes.arrayOf(PropTypes.string),
  size: PropTypes.string,
  color: PropTypes.string,
};

Badges.defaultProps = {
  badges: [],
  size: 'small',
  color: 'primary',
};

export default Badges;
