class ProUserCriteria {
  constructor(user) {
    this.user = user || { accesslevel: 'none' };
  }

  test() {
    return !!(this.user.accessLevel.indexOf('teams') >= 0 || this.user.accessLevel.indexOf('pro') >= 0);
  }
}

export default ProUserCriteria;
