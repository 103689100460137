import Feature from '../Feature';
import AnyCriteria from '../criteria/any';
import ProUserCriteria from '../criteria/pro_user';
import PremiumUserCriteria from '../criteria/premium_user';
import DateTimeCriteria from '../criteria/dateTime';

export default class TeamsFeature extends Feature {
  constructor(user) {
    const teamsPromo = new DateTimeCriteria();
    const proUser = new ProUserCriteria(user);
    const premiumUser = new PremiumUserCriteria(user);
    const any = new AnyCriteria(proUser, premiumUser, teamsPromo);

    super(any);
  }
}
