import React from 'react';
import PropTypes from 'prop-types';
import { prefixHostname } from '../../../lib/cdn';

const SHRMLogo = props => (
  <img
    className={props.className}
    src={prefixHostname('/images/SHRM-logo_credly-300x300.png')}
    alt="SHRM&reg; Logo"
  />
);

SHRMLogo.propTypes = {
  className: PropTypes.string,
};

SHRMLogo.defaultProps = {
  className: '',
};

export default SHRMLogo;
