import tracker from '../../index';

const {
  eventNames, eventProps, resolveAfterTimeout, checkOrgNames,
} = tracker;

const trackEmailIndividualReport = (
  asmtName,
  asmtType,
  surveyTakerEmail,
  isOwnReport,
  orgs,
) => {
  tracker.track(eventNames.EMAIL_INDIVIDUAL_REPORT, {
    [eventProps.ASSESSMENT]: `${asmtName} (${asmtType.toUpperCase()})`,
    [eventProps.SURVEY_TAKER]: surveyTakerEmail,
    [eventProps.OWN_REPORT]: isOwnReport,
    [eventProps.SUCCESS]: true,
    [eventProps.ORGANIZATION_NAME]: checkOrgNames(orgs),
  });
};

const trackEmailIndividualReportFailure = reason => {
  tracker.track(eventNames.EMAIL_INDIVIDUAL_REPORT, {
    [eventProps.SUCCESS]: false,
    [eventProps.FAILURE_REASON]: reason,
  });
  return resolveAfterTimeout;
};

export { trackEmailIndividualReport, trackEmailIndividualReportFailure };
