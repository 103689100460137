import { brazeProductUpdatesOptIn } from '../../brazeTracker/customEvents';
import tracker from '../index';

const { peopleProps, booleanAsString, resolveAfterTimeout } = tracker;

const trackProductUpdatesOptIn = (userid, optIn) => {
  tracker.identify(userid);
  tracker.people.set({
    [peopleProps.PRODUCT_UPDATES]: booleanAsString(optIn),
  });
  brazeProductUpdatesOptIn(optIn);
  return resolveAfterTimeout;
};

export { trackProductUpdatesOptIn };
