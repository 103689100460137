import React from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import Avatar from '../common/Avatars/Avatar';
import Icon from '../common/Icon';

function determineCardClass(hasAvatar, hasBlockType) {
  let cardClass = 'card__container';
  if (hasBlockType) cardClass += ' flex';
  if (!hasAvatar && !hasBlockType) cardClass += ' card__container--no-avatar';
  return cardClass;
}

function determineIconType(blockType, displayName) {
  if (blockType === 6) {
    switch (displayName) {
      case 'GlobeSmart Profile':
        return 'profile';
      case 'Culture Guides':
        return 'culture-guide';
      default:
        return 'ibi-symbol';
    }
  } else {
    return 'learning';
  }
}

function renderIcon(displayName, blockType) {
  if (!blockType) return null;
  return (
    <div className="flex items-center justify-center w-16 h-16 p-2 rounded-full bg-ivory-400">
      <Icon icon={determineIconType(blockType, displayName)} className="m-2.5 w-16 h-16" iconColor="gray" />
    </div>
  );
}

function renderAvatar(avatarUrl, displayName) {
  if (!avatarUrl) return null;
  return (
    <Avatar
      avatarUrl={avatarUrl}
      avatarImgAlt={displayName}
    />
  );
}

function renderDisplayName(displayName, link) {
  return (link
    ? <Link to={link}>{displayName}</Link>
    : displayName
  );
}

function renderDescription(description) {
  if (!description) return null;
  return (
    <div className="card__description">
      {description}
    </div>
  );
}

function renderActions(children) {
  if (!children) return null;
  return (
    <div className="card__actions">
      {children}
    </div>

  );
}

const Grip = () => (
  <svg className="ml-2" viewBox="0 0 30 80" width="30" height="70">
    <g transform="translate(6, 20)">
      <circle cx="0" cy="0" r="2" fill="#C7C7C7" />
      <circle cx="0" cy="8" r="2" fill="#C7C7C7" />
      <circle cx="0" cy="16" r="2" fill="#C7C7C7" />
      <circle cx="0" cy="24" r="2" fill="#C7C7C7" />
      <circle cx="0" cy="32" r="2" fill="#C7C7C7" />
    </g>
    <g transform="translate(16, 20)">
      <circle cx="0" cy="0" r="2" fill="#C7C7C7" />
      <circle cx="0" cy="8" r="2" fill="#C7C7C7" />
      <circle cx="0" cy="16" r="2" fill="#C7C7C7" />
      <circle cx="0" cy="24" r="2" fill="#C7C7C7" />
      <circle cx="0" cy="32" r="2" fill="#C7C7C7" />
    </g>
  </svg>
);

const dragClasses = ' cursor-grab select-none inline-flex items-center';

const Card = ({
  displayName,
  displayNameLink,
  displayDescription,
  avatarUrl,
  children,
  isDraggable,
  blockType,
}) => (
  <div className={`card${isDraggable ? dragClasses : ''}`}>
    {isDraggable ? <Grip /> : null}
    <div className={determineCardClass(avatarUrl, blockType)}>
      {renderIcon(displayName, blockType)}
      {renderAvatar(avatarUrl, displayName)}
      <div className="card__content pl-0.5 md:pl-0">
        <div className="text-lg font-medium">
          {renderDisplayName(displayName, displayNameLink)}
        </div>
        {renderDescription(displayDescription)}
      </div>
      {renderActions(children)}
    </div>
  </div>
);

Card.propTypes = {
  displayName: PropTypes.string.isRequired,
  displayNameLink: PropTypes.string,
  displayDescription: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
  ]),
  avatarUrl: PropTypes.string,
  children: PropTypes.node,
  isDraggable: PropTypes.bool,
  blockType: PropTypes.number,
};

Card.defaultProps = {
  displayNameLink: '',
  displayDescription: null,
  avatarUrl: '',
  children: null,
  isDraggable: false,
  blockType: 0,
};

export default Card;
