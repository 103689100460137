import React, { useContext, useEffect } from 'react';
import ReactModal from 'react-modal';

import { ModalContext } from '../../context/Modal';

const GlobeSmartDialog = () => {
  const {
    dialogContent,
    showDialog,
    handleCloseDialog,
    dialogClassName,
  } = useContext(ModalContext);

  useEffect(() => {
    ReactModal.setAppElement('body');
  }, [showDialog]);

  return (
    showDialog ? (
      <ReactModal
        isOpen={showDialog}
        onRequestClose={handleCloseDialog}
        className={dialogClassName}
        overlayClassName="Overlay"
      >
        <button
          type="button"
          className="modal-close"
          onClick={handleCloseDialog}
        >
          &times;
        </button>
        {dialogContent}
      </ReactModal>
    ) : null
  );
};

export default GlobeSmartDialog;
