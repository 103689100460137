import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import DropdownSelect from '../../../../components/common/Dropdowns/DropdownSelect';
import LabelInput from '../../../../components/common/LabelInput';
import AutoSuggest from '../../../../components/AutoSuggest';

import { incrementsObject } from '../../../../lib/increments';
import { translations } from '../../../../lib/constants';

const ModuleForm = ({ moduleId,
  handleChange,
  completionRangeStart,
  completionRangeEnd,
  handleRemoveLanguage,
  languages,
  languageRef,
  errorMessage }) => {
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (completionRangeStart) {
      setIsDisabled(false);
    }
  }, [completionRangeStart]);

  return (
    <>
      <AutoSuggest
        name="languages"
        labelText="Select Block Languages"
        placeholder="Languages"
        options={translations}
        selectedOptions={languages}
        onSelectOption={handleChange}
        onRemoveSelectedOption={handleRemoveLanguage}
        isMultiSelect
        ref={languageRef}
      />
      <LabelInput
        id="moduleId"
        name="moduleId"
        labelType="number"
        isRequired
        labelText="Module Id"
        value={moduleId}
        onChangeValue={handleChange}
        helperText="Add the id number for the module your block will address."
      />
      <div>
        <h3 className="mb-2 text-left font-heading-5">Set Time Range (Minutes)</h3>
        <div className="grid md:grid-cols-6">
          <div className="p-1 md:col-span-2">
            <DropdownSelect
              id="completionRangeStart"
              name="completionRangeStart"
              labelType="text"
              labelText="Completion Range Start"
              options={incrementsObject(0, 120, 5)}
              value={completionRangeStart}
              onChangeValue={handleChange}
              helperText="Completion time displays as a range. Enter the min. estimated time here."
            />
          </div>
          <div className="p-1 md:col-start-4 md:col-end-6">
            <DropdownSelect
              id="completionRangeEnd"
              name="completionRangeEnd"
              labelType="text"
              labelText="Completion Range End"
              options={incrementsObject(0, 120, 5)}
              value={completionRangeEnd}
              onChangeValue={handleChange}
              helperText="Enter the max. estimated time here."
              errorMessage={errorMessage}
              isDisabled={isDisabled}
            />
          </div>
        </div>
      </div>
    </>
  );
};

ModuleForm.propTypes = {
  moduleId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  completionRangeStart: PropTypes.string,
  completionRangeEnd: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  handleRemoveLanguage: PropTypes.func.isRequired,
  languages: PropTypes.arrayOf(PropTypes.shape(
    {
      name: PropTypes.string,
      value: PropTypes.string,
    })),
  languageRef: PropTypes.shape({}),
};

ModuleForm.defaultProps = {
  moduleId: 0,
  completionRangeStart: '',
  completionRangeEnd: '',
  errorMessage: '',
  languages: [],
  languageRef: {},
};

export default ModuleForm;
