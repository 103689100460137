import html2canvas from 'html2canvas';

import imageToDataUrl from '../imageToDataUrl';

export const createProfileShareImages = async (thumbNailElement, profileChartElement) => {
  const thumbNailImages = thumbNailElement.getElementsByTagName('img');
  const profileImages = profileChartElement.getElementsByTagName('img');
  const images = Array.from([...thumbNailImages, ...profileImages]);
  await Promise.all(images.map(image => imageToDataUrl(image)));

  const polyLine = profileChartElement.getElementsByTagName('polyline');
  const polyLinePoints = polyLine[0].getAttribute('points');
  // Split the points attribute into an array of points
  const pointsArray = polyLinePoints.split(', ');
  // Modify the y-coordinate of each point to move the polyline up by 10 pixels
  const updatedPoints = pointsArray.map(point => {
    const [x, y] = point.split(' ').map(parseFloat);
    return `${x} ${y - 10},`;
  });
  // Join the updated points back into a string and slice off the final comma
  const newPointsAttribute = updatedPoints.join(' ').slice(0, -1);
  // Set the new 'points' attribute on the polyline element
  polyLine[0].setAttribute('points', newPointsAttribute);

  const thumbNailOptions = {
    allowTaint: true,
    removeContainer: true,
    backgroundColor: null,
    logging: true,
    scale: 1,
    useCORS: true,
    width: 1200,
    height: 630,
  };
  const thumbNailCanvas = await html2canvas(thumbNailElement, thumbNailOptions);

  const thumbNailBlobPromise = new Promise(resolve => thumbNailCanvas.toBlob(resolve, 'image/png'));

  const profileChartOptions = {
    allowTaint: true,
    removeContainer: true,
    backgroundColor: 'white',
    logging: true,
    scale: 1,
    useCORS: true,
  };
  const profileChartCanvas = await html2canvas(profileChartElement, profileChartOptions);
  const profileChartBlobPromise = new Promise(resolve => profileChartCanvas.toBlob(resolve, 'image/png'));

  const [thumbNailBlob, profileChartBlob] = await Promise.all([
    thumbNailBlobPromise,
    profileChartBlobPromise,
  ]);

  const formData = new FormData();
  formData.append('thumbnail', thumbNailBlob);
  formData.append('chart', profileChartBlob);
  return formData;
};

export const openLinkedInShareWindow = shareId => {
  const shareProfileUrl = `https://www.linkedin.com/shareArticle?url=${process.env.SITE_URL}/share/profile/${shareId}`;
  const windowConfig = 'width=600,height=600,bottom=100,left=100,scrollbars=yes,centerscreen=yes,resizable=no';
  window.open(shareProfileUrl, 'authWindow', windowConfig);
};
