import React from 'react';
import { iconPropTypes, defaultIconProps } from './types';

import { fillColorMap } from '../index.js';

const QuarterPieIcon = ({ className, iconColor }) => (
  <svg className={`${className} ${fillColorMap[iconColor]}`} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.6712 16.1244H1.17943C1.03843 16.1244 0.925293 16.0112 0.925293 15.8703V0.378524C0.925293 0.237532 1.03843 0.12439 1.17943 0.12439C9.87569 0.12439 16.9253 7.174 16.9253 15.8703C16.9253 16.0112 16.8122 16.1244 16.6712 16.1244Z" />
  </svg>
);

QuarterPieIcon.propTypes = iconPropTypes;
QuarterPieIcon.defaultProps = defaultIconProps;

export default QuarterPieIcon;
