class NeedsCompletedSurvey {
  static apply(router, currentUser) {
    if (currentUser.isLoggedIn() && !currentUser.hasCompletedSurvey) {
      router.push('/profile/survey');
      return true;
    }
    return false;
  }
}

export default NeedsCompletedSurvey;
