import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../../components/common/Button';

const BlockConfirmModal = ({ onHandleRedirect, onHandleRemain }) => (
  <>
    <h2 className="text-2xl text-center">Block Created</h2>
    <p className="mb-8 text-center">What would you like to do next?</p>
    <div className="flex items-center justify-end gap-2 mt-8">
      <Button
        onClick={onHandleRedirect}
      >View This block
      </Button>
      <Button
        onClick={onHandleRemain}
        outlinedColor="green"
      >Create Another Block
      </Button>
    </div>
  </>
);

BlockConfirmModal.propTypes = {
  onHandleRedirect: PropTypes.func.isRequired,
  onHandleRemain: PropTypes.func.isRequired,
};

export default BlockConfirmModal;
