import React, {
  useState,
} from 'react';

import PropTypes from 'prop-types';

import Button from '../../../common/Button';
import ProfileCardTextWithButton from '../../../ProfileCards/ProfileCardTextWithButton';

const GroupFooter = ({ completeGroupDelete, groupOrTeam }) => {
  const [confirmDelete, setConfirmDelete] = useState(false);

  const noDelete = () => {
    setConfirmDelete(false);
  };

  if (confirmDelete) {
    const options = {
      messageText: `Are you sure you want to delete this ${groupOrTeam}?`,
      confirmText: 'Yes, Delete',
      confirmButton: {
        isWarning: true,
        isNormalCase: true,
        onClick: completeGroupDelete,
      },
      cancelText: 'No, Keep',
      cancelButton: {
        isNormalCase: true,
        variant: 'secondary',
        onClick: noDelete,
      },
    };

    return (
      <div className="my-2">
        <ProfileCardTextWithButton {...options} />
      </div>
    );
  }

  return (
    <div className="px-2 my-4">
      <Button className="m-auto" onClick={setConfirmDelete} isWarning>
        Delete {groupOrTeam}
      </Button>
    </div>
  );
};

GroupFooter.propTypes = {
  completeGroupDelete: PropTypes.func.isRequired,
  groupOrTeam: PropTypes.string.isRequired,
};

export default GroupFooter;
