import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ProfileCardTextWithButton from '../../../../ProfileCards/ProfileCardTextWithButton';
import ProfileCardAvatarWithButton from '../../../../ProfileCards/ProfileCardAvatarWithButton';

const GroupOwnerCard = ({
  id: ownerId,
  ownerUserId,
  displayName,
  displayDescription,
  ownerLength,
  token,
  removeOwner,
  avatarUrl,
}) => {
  const [confirmRemove, setConfirmRemove] = useState(false);

  useEffect(() => {
    setConfirmRemove(false);
  }, [removeOwner]);

  function renderConfirmRemove() {
    const options = {
      messageText: 'Are you sure you want to remove this group owner?',
      confirmText: 'Yes, Remove',
      confirmButton: {
        isWarning: true,
        isNormalCase: true,
        onClick: () => removeOwner(ownerId, ownerUserId),
      },
      cancelText: 'No, Keep',
      cancelButton: {
        isNormalCase: true,
        variant: 'secondary',
        onClick: () => setConfirmRemove(false),
      },
    };

    return <ProfileCardTextWithButton {...options} token={token} />;
  }

  function renderGroupOwnerCard() {
    const buttonOptions = {
      confirmText: '',
      confirmButton: {},
      cancelText: ownerLength > 1 ? 'Remove' : '',
      cancelButton: {
        isNormalCase: true,
        isWarning: true,
        onClick: () => setConfirmRemove(true),
      },
    };
    return (
      <ProfileCardAvatarWithButton
        displayName={displayName}
        displayDescription={displayDescription}
        {...buttonOptions}
        memberUserId={ownerUserId}
        avatarUrl={avatarUrl}
      />
    );
  }
  return confirmRemove ? renderConfirmRemove() : renderGroupOwnerCard();
};

GroupOwnerCard.propTypes = {
  id: PropTypes.number.isRequired,
  displayName: PropTypes.string.isRequired,
  displayDescription: PropTypes.shape({}),
  ownerLength: PropTypes.number,
  avatarUrl: PropTypes.string.isRequired,
};

GroupOwnerCard.defaultProps = {
  ownerUserId: null,
  displayDescription: null,
  ownerLength: 1,
};

export default GroupOwnerCard;
