/* eslint-disable react/button-has-type */
import React, { forwardRef } from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';

import ButtonIconCheck from '../../ButtonIconCheck';
import buttonClasses from './classes';
import { propTypes, defaultProps } from './types';

const Button = forwardRef(
  // eslint-disable-next-line prefer-arrow-callback
  function Button(props, ref) {
    const {
      to,
      rel,
      target,
      onClick,
      type,
      dataID,
      children,
      disabled,
      leadingButtonIcon,
      trailingButtonIcon,
      iconColor,
      isCircle,
      onMouseEnter,
      onMouseLeave,
      direction,
      dataTopic,
      ariaLabel,
    } = props;

    const renderLink = () => (
      <Link
        className={buttonClasses(props)}
        to={to}
        rel={rel}
        ref={ref}
        target={target}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        data-topic={dataTopic || undefined}
        tabIndex={disabled ? -1 : 0}
        aria-label={ariaLabel}
      >
        <ButtonIconCheck
          leadingButtonIcon={leadingButtonIcon}
          trailingButtonIcon={trailingButtonIcon}
          iconColor={iconColor}
          isCircle={isCircle}
          direction={direction}
        >{children}
        </ButtonIconCheck>
      </Link>
    );

    const renderAnchorTag = () => (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a
        className={buttonClasses(props)}
        href={props.externalUrl}
        target={props.openInNewWindow ? '_blank' : '_self'}
        rel={props.openInNewWindow ? 'noopener noreferrer' : ''}
        tabIndex={disabled ? -1 : 0}
        aria-label={ariaLabel}
      >
        <ButtonIconCheck
          leadingButtonIcon={leadingButtonIcon}
          trailingButtonIcon={trailingButtonIcon}
          iconColor={iconColor}
          isCircle={isCircle}
          direction={direction}
        >{children}
        </ButtonIconCheck>
      </a>
    );

    const renderButton = () => (
      <button
        className={buttonClasses(props)}
        type={type}
        ref={ref}
        onClick={onClick}
        data-id={dataID}
        disabled={disabled}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        aria-label={ariaLabel}
      >
        <ButtonIconCheck
          leadingButtonIcon={leadingButtonIcon}
          trailingButtonIcon={trailingButtonIcon}
          iconColor={iconColor}
          isCircle={isCircle}
          direction={direction}
        >{children}
        </ButtonIconCheck>
      </button>
    );

    if (props.to) {
      return renderLink();
    }

    if (props.externalUrl) {
      return renderAnchorTag();
    }

    return renderButton();
  });

Button.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  to: PropTypes.string,
  rel: PropTypes.string,
  target: PropTypes.string,
  type: PropTypes.string,
  leadingButtonIcon: PropTypes.string,
  trailingButtonIcon: PropTypes.string,
  iconColor: PropTypes.string,
  isCircle: PropTypes.bool,
  disabled: PropTypes.bool,
  dataID: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  direction: PropTypes.string,
  dataTopic: PropTypes.string,
  externalUrl: PropTypes.string,
  openInNewWindow: PropTypes.bool,
  ariaLabel: PropTypes.string,
};
Button.defaultProps = {
  children: null,
  onClick: null,
  to: null,
  rel: null,
  target: null,
  type: null,
  leadingButtonIcon: null,
  trailingButtonIcon: null,
  iconColor: 'current',
  isCircle: null,
  disabled: false,
  dataID: null,
  onMouseEnter: null,
  onMouseLeave: null,
  direction: null,
  dataTopic: null,
  externalUrl: null,
  openInNewWindow: false,
  ariaLabel: null,
};

export default Button;
export { propTypes, defaultProps };
