import { useContext } from 'react';
import PropTypes from 'prop-types';

import { RouterContext } from '../../context/Router';
import { CurrentUserContext } from '../../context/CurrentUser';

import AperianLiveUserFeature from '../../lib/features/AperianLive';
import { GROWTH_PLAN_ID } from '../../lib/constants';

const AperianLiveAuth = ({
  children,
}) => {
  const { currentUser } = useContext(CurrentUserContext);
  const { router } = useContext(RouterContext);

  return new AperianLiveUserFeature(currentUser)
    .positive(() => children)
    .negative(() => {
      router.replace(`/dashboard?purchase&plan=${GROWTH_PLAN_ID}`);
      return null;
    })
    .execute();
};

AperianLiveAuth.propTypes = {
  children: PropTypes.node,
};

export default AperianLiveAuth;
