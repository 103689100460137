class InvitationRejectAction {
  constructor(apiService) {
    this.apiService = apiService;
  }

  execute(token) {
    return this.apiService.post(`invitations/${token}/reject`);
  }
}

export default InvitationRejectAction;
