import React from 'react';

import { propTypes, defaultProps } from './types';
import Button from '../../common/Button';

function renderButton({ text, options, token, id }) {
  if (!text) return null;

  return (
    <Button id={id} {...options} dataID={token} className="card__button">
      {text}
    </Button>
  );
}

const ProfileCardTextWithButtons = props => {
  const {
    token,
    messageText,
    confirmText,
    confirmId,
    confirmButton,
    cancelText,
    cancelId,
    cancelButton,
  } = props;

  return (
    <div className="card has-email">
      <div className="card__container card__container--confirm">
        <div className="card__content">
          <p className="text-center">{messageText}</p>
        </div>
        <div className="card__actions">
          {renderButton({ text: confirmText, options: confirmButton, token, id: confirmId })}
          {renderButton({ text: cancelText, options: cancelButton, id: cancelId })}
        </div>
      </div>
    </div>
  );
};

ProfileCardTextWithButtons.propTypes = propTypes;
ProfileCardTextWithButtons.defaultProps = defaultProps;

export default ProfileCardTextWithButtons;
