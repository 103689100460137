import { addMinutes, format } from 'date-fns';

export const formatCalendarData = (
  dateTime,
  duration,
  userJoinUrl,
  name,
) => {
  const { timeZone } = new Intl.DateTimeFormat().resolvedOptions();
  const sessionTimeStamp = new Date(dateTime);
  const startDate = format(sessionTimeStamp, 'yyyy-MM-dd');
  const startTime = format(sessionTimeStamp, 'kk:mm');
  const endTime = format(addMinutes(sessionTimeStamp, duration), 'kk:mm');
  const description = `Thank you for registering for Aperian Live! This meeting will be hosted in zoom. You'll need to log into ${process.env.SITE_URL}/aperian-live to join the meeting or by clicking this link: [url]${userJoinUrl}|join link[/url]. The meeting room will open 10 minutes before, and we look forward to seeing you there!`;

  const calendarConfig = {
    name,
    description,
    startDate,
    startTime,
    endTime,
    options: ['Apple', 'Google', 'Microsoft365', 'MicrosoftTeams'],
    timeZone,
    listStyle: 'dropdown',
  };

  return calendarConfig;
};
