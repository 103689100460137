import queryString from 'query-string';

function getQuery(key, props) {
  if (props.params && props.params[key]) return props.params[key];
  if (!props.location) return null;

  const fromLocationSearch = queryString.parse(props.location.search)[key];
  if (fromLocationSearch) {
    return fromLocationSearch;
  }

  return null;
}

export default getQuery;
