import React from 'react';
import PropTypes from 'prop-types';

import ProfileCardTextWithButton from '../../ProfileCards/ProfileCardTextWithButton';
import Button from '../../common/Button';

const DeleteCohortButton = ({
  isDeleteConfirmed,
  confirmDelete,
  cancelDelete,
  completeDelete,
}) => {
  if (isDeleteConfirmed) {
    const options = {
      messageText:
        'Deleting the cohort will delete all cohort details, enrollments, and related data. Are you sure?',
      confirmText: 'Yes, Delete',
      confirmButton: {
        isWarning: true,
        isNormalCase: true,
        onClick: completeDelete,
      },
      cancelText: 'No, Keep',
      cancelButton: {
        isNormalCase: true,
        variant: 'secondary',
        onClick: cancelDelete,
      },
    };

    return (
      <div className="my-2">
        <ProfileCardTextWithButton {...options} />
      </div>
    );
  }

  return (
    <div className="flex justify-end">
      <Button onClick={confirmDelete} isWarning isSmall>
        Delete Cohort
      </Button>
    </div>
  );
};

DeleteCohortButton.propTypes = {
  isDeleteConfirmed: PropTypes.bool.isRequired,
  confirmDelete: PropTypes.func.isRequired,
  cancelDelete: PropTypes.func.isRequired,
  completeDelete: PropTypes.func.isRequired,
};

export default DeleteCohortButton;
