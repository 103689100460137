import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ToggleSwitch = props => {
  const toggleClasses = classNames({
    'pointer-events-none disabled:before:bg-gray-50 disabled:bg-gray-100': props.disabled,
    'flex items-center px-1 !bg-none w-14 h-8 bg-gray-100 hover:bg-gray-200 rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:shadow-input ring-1 ring-inset border-none ring-transparent before:inline-block before:w-6 before:h-6 before:bg-white before:translate-x-0 before:shadow before:rounded-full before:transform before:transition before:ease-in-out before:duration-200 focus:ring-inset focus:ring-rust-600 focus:shadow-input focus:ring-opacity-0': true,
    'checked:bg-rust-600 checked:hover:bg-rust-600 checked:focus:bg-rust-600 checked:before:translate-x-full': props.checked,
  });

  return (
    <div className="flex items-center justify-center">
      <label htmlFor={props.id} className="font-sans text-lg">{props.labelText}</label>
      <input
        id={props.id}
        type="checkbox"
        name={props.name}
        checked={props.checked}
        onChange={props.onChange}
        className={toggleClasses}
      />

    </div>
  );
};

ToggleSwitch.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  checked: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  labelText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ToggleSwitch.defaultProps = {
  name: '',
  labelText: '',
  checked: false,
  disabled: false,
};

export default ToggleSwitch;
