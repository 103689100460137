import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';

import { COOKIE_NOTIFICATION } from '../../lib/constants';
import Notification from './Notification';

const Notifications = props => {
  const [notices, setNotices] = useState([]);
  const [cookies, removeCookie] = useCookies();

  useEffect(() => {
    if (cookies[COOKIE_NOTIFICATION]) {
      setNotices([
        ...notices,
        cookies[COOKIE_NOTIFICATION],
      ]);
    }

    if (props.location?.query?.success) {
      setNotices([
        ...notices,
        {
          type: 'success',
          message: props.location.query.success,
        }]);
    }

    if (props.location?.query?.failure) {
      setNotices([
        ...notices,
        {
          type: 'failure',
          message: props.location.query.failure,
        }]);
    }

    return (() => (removeCookie(COOKIE_NOTIFICATION, { path: '/' })));
  }, []);

  return (
    <div>
      {notices
        .filter(notice => !!notice)
        .map(notice => <Notification key={Math.random()} {...notice} />)}
    </div>
  );
};

Notifications.propTypes = {
  type: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  message: PropTypes.string,
  location: PropTypes.shape({
    query: PropTypes.shape({
      success: PropTypes.string,
      failure: PropTypes.string,
    }),
  }),
};
Notifications.defaultProps = {
  type: null,
  message: null,
  location: null,
};

export default Notifications;
