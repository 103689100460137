export default async function emailReportAction(
  apiService,
  assessment,
  memberUserId = null,
  memberId = null,
) {
  if (memberId) {
    return apiService.get(`assessments/${assessment}/report/${memberId}/${memberUserId}`);
  }
  return apiService.get(`assessments/${assessment}/report`);
}
