import React from 'react';
import PropTypes from 'prop-types';

import {
  PremiumDescription,
  IBIDescription,
  StarterDescription,
  GrowthDescription,
} from '../Descriptions';

const PaymentHeader = ({ planInfo }) => (
  <div>
    <h2 className="font-serif">Buy {planInfo.name}</h2>
    {(planInfo.id.includes('premium-')) && (<PremiumDescription />)}
    {(planInfo.id.includes('a-ibi-')) && (<IBIDescription />)}
    {(planInfo.id.includes('starter-')) && (<StarterDescription />)}
    {(planInfo.id.includes('growth-')) && (<GrowthDescription />)}
    <p className="mb-8 text-sm"><a href="https://aperian.zendesk.com/hc/en-us/articles/360036880593-Purchasing-Aperian-Seats-for-Others" target="_blank" rel="noopener noreferrer">Learn more about purchasing seats for others.</a></p>
    <hr className="h-[0.05em] text-green-500 bg-green-500" />
  </div>
);

PaymentHeader.propTypes = {
  planInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default PaymentHeader;
