import PropTypes from 'prop-types';

const propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  avatarPosition: PropTypes.number.isRequired,
  traitLinePosition: PropTypes.number.isRequired,
  svgContainer: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    current: PropTypes.object,
  }),
};

export { propTypes };
