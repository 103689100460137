import React from 'react';
import PropTypes from 'prop-types';

const PrintMessage = ({ compareProfile }) => {
  if (!compareProfile) {
    return null;
  }

  return (
    <div className="text-center print-message-hidden">
      If you are not able to view all of your comparisons,
      please use the screenshot feature underneath your chart.
    </div>
  );
};

PrintMessage.propTypes = {
  compareProfile: PropTypes.bool,
};

PrintMessage.defaultProps = {
  compareProfile: false,
};

export default PrintMessage;
