import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../Tooltip';

const getPercentRange = percent => {
  if (percent < 1) {
    return 1;
  } if (percent > 100) {
    return 100;
  }
  return percent;
};

const progressColors = {
  rust: 'bg-rust-600',
};

const ProgressBar = ({ percent, color, showPercentColor, displayToolTip, position }) => {
  const setPercentColorClass = () => {
    if (showPercentColor) {
      if (percent > 90 && percent < 100) {
        return 'bg-yellow-500';
      }

      if (percent >= 100) {
        return 'bg-red-500';
      }

      return 'bg-blue-600';
    }

    return progressColors[color];
  };

  return (
    <div className={position === 'right' ? 'flex items-center gap-2' : ''}>
      <div className="w-full h-4 rounded-full bg-gray-50">
        <div
          className={`relative h-4 rounded-full ${setPercentColorClass()}`}
          style={{ width: `${getPercentRange(percent)}%` }}
        >
          {displayToolTip
            ? (
              <Tooltip
                content={`${percent}%`}
                position={position}
                theme="light"
                className="absolute h-4 left-1/2"
              />
            ) : null}
        </div>
      </div>
      {!displayToolTip && position
        ? <div className="font-medium text-right text-gray-700">{percent}%</div>
        : null}
    </div>
  );
};

ProgressBar.propTypes = {
  percent: PropTypes.number.isRequired,
  color: PropTypes.string,
  showPercentColor: PropTypes.bool,
  displayToolTip: PropTypes.bool,
  position: PropTypes.string,
};

ProgressBar.defaultProps = {
  color: 'rust',
  showPercentColor: false,
  displayToolTip: false,
  position: null,
};

export default ProgressBar;
