import { brazeCreateTeamSuccess } from '../../brazeTracker/customEvents';
import tracker from '../index';

const {
  eventNames, peopleProps, resolveAfterTimeout,
} = tracker;

const trackViewMyTeam = () => {
  tracker.track(eventNames.VIEW_MY_TEAM);

  return resolveAfterTimeout;
};

const trackCreateTeamSuccess = ({
  name: teamName,
  teamSize,
  memberEmails,
}) => {
  // Track appcues 'Create Team' event
  window?.Appcues?.track(eventNames.CREATE_TEAM);
  brazeCreateTeamSuccess({ teamName, teamMembers: memberEmails, teamSize });
  return resolveAfterTimeout;
};

const trackRemoveSelfFromTeam = userId => {
  tracker.identify(userId);
  tracker.people.increment({
    [peopleProps.NUMBER_TEAMS_AS_MEMBER]: -1,
  });
  return resolveAfterTimeout;
};

export {
  trackCreateTeamSuccess,
  trackRemoveSelfFromTeam,
  trackViewMyTeam,
};
